import { LabelledWindowLevels, WindowLevels } from './reducers/window/types';

export const THEME = {
  colors: {
    base: {
      bluedianne: '#1d3748', // --color-blue-dianne
    },
    focus: {
      shadowblue: 'rgba(0, 179, 255, 0.3)', // --color-shadow-blue
      shadowgray: 'rgba(107, 107, 107, 0.2)', //--color-shadow-gray
      buttonblue: '#bcebff', //--color-button-blue
    },
    // Reskin colour palettes
    stenosis: {
      severe: '#FF2391',
      moderate: '#FE6100',
      mildMinimal: '#FFB000',
      unknown: '#BDBFC0',
      none: '#43474A',
    },
    vessels: {
      lm: '#0083F2',
      lad: '#1DF5EA',
      lcx: '#B0D0ff',
      rca: '#E34084',
      other: '#DBA8AC',
    },
    background: {
      100: '#4A4A4A',
      200: '#2A2A2A',
      300: '#212121',
      400: '#1A1A1A',
      500: '#151515',
    },
    gray: {
      100: '#E3E3E3',
      200: '#919191',
      300: '#747474',
    },
    global: {
      black: '#000000',
      white: '#FFFFFF',
      transparent: 'transparent',
      blue: '#00B3FF',
      red: '#EB5757',
      orange: '#FFAD0D',
      green: '#49A147',
    },
    messages: {
      error: '#EB5757',
      warning: '#FFAD0D',
      success: '#49A147',
      info: '#00B3FF',
    },
  },
  fonts: {
    accuminPro: '"acumin-pro", sans-serif',
  },
};

/**
 * Set the app version number to be displayed in the dropdown menu
 */
export const APP_VERSION_NUMBER = '1.6.3';

// Filenames
export const FILENAME_DASHBOARD_DATA = 'dashboard_data.json';
export const FILENAME_PATIENT_DATA = 'web_data.json';
export const FILENAME_VESSEL_INFO = 'vessel_info.h5';
export const FILENAME_STENOSIS = 'stenosis.h5';
export const FILENAME_MPR_POLYGONS = 'mpr_polygons.json';

// Keys
export const KEY_DASHBOARD_DATA = 'DASHBOARD_DATA';
export const KEY_PATIENT_DATA = 'PATIENT_DATA';
export const KEY_3D_MODEL = '3D_MODEL';
export const BASE_KEY_3D_MODEL = 'BASE_3D_MODEL';
export const KEY_CPR = 'CPR';
export const KEY_MPR_LONG_AXIS = 'MPR_LONG_AXIS';
export const KEY_MPR_SHORT_AXIS = 'MPR_SHORT_AXIS';
export const KEY_CT_CONTRAST = 'CONTRASTSCAN';
export const KEY_CT_NON_CONTRAST = 'NON_CONTRASTSCAN';

// Colors
export const COLOR_CPR_CENTRELINE = '#04B3FF';
export const COLOR_CPR_CENTRELINE_AUX = THEME.colors.vessels.other;
export const COLOR_CPR_CENTRELINE_NODE = THEME.colors.global.white;
export const COLOR_CPR_CENTRELINE_NODE_HIGHLIGHT = THEME.colors.global.blue;
export const COLOR_CPR_SLICE_INDICATOR = THEME.colors.global.white;
export const COLOR_CPR_LOW_FFR = THEME.colors.messages.error;
export const COLOR_MPR_SHORT_AXIS_INNER_WALL = '#e8f34e';
export const COLOR_MPR_SHORT_AXIS_OUTER_WALL = '#00f0ff';
export const COLOR_MODEL_AORTA = '#2f3234';
export const COLOR_MODEL_DEFAULT = THEME.colors.global.black;
export const COLOR_MODEL_PLAQUE = '#a4a4a4';
export const COLOR_MODEL_SLICER = THEME.colors.global.white;
export const COLOR_MODEL_LIGHT_AMBIENT = THEME.colors.global.white;
export const COLOR_MODEL_LIGHT_DIRECTIONAL = THEME.colors.global.white;
export const COLOR_MODEL_LIGHT_SPECULAR = '#080808'; // (Only applied if renderSpecular is true)
export const COLOR_MODEL_BACK_LIGHT_DIRECTIONAL = '#80C0ff';
export const COLOR_CPR_MARKER_COLOR_INDICATOR = THEME.colors.background[200];

// Dates
export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'h:mm a';
export const DATETIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;

export const MOMENT_DATE_FORMAT = 'YYYYMMDD';
export const MOMENT_DATETIME_FORMAT = 'YYYYMMDDhhmmss';

// Window controls
export const WINDOW_LEVEL_DEFAULT = 240;
export const WINDOW_LEVEL_MIN = -600;
export const WINDOW_LEVEL_MAX = 1000;
export const WINDOW_WIDTH_DEFAULT = 720;
export const WINDOW_WIDTH_MIN = 100;
export const WINDOW_WIDTH_MAX = 2000;

export const DEFAULT_WINDOW: WindowLevels = {
  windowWidth: WINDOW_WIDTH_DEFAULT,
  windowCenter: WINDOW_LEVEL_DEFAULT,
};
export const CTA_WINDOW: WindowLevels = { windowWidth: 740, windowCenter: 220 };
export const SOFT_TISSUE_WINDOW: WindowLevels = {
  windowWidth: 350,
  windowCenter: 50,
};
export const LUNG_WINDOW: WindowLevels = {
  windowWidth: 1500,
  windowCenter: -600,
};
export const ABDOMEN_WINDOW: WindowLevels = {
  windowWidth: 400,
  windowCenter: 80,
};
export const BONE_WINDOW: WindowLevels = {
  windowWidth: 2000,
  windowCenter: 350,
};

export const PRESET_MAP: { [x: string]: LabelledWindowLevels } = {
  Digit0: { label: 'Default', window: DEFAULT_WINDOW },
  Digit1: { label: 'CTA', window: CTA_WINDOW },
  Digit2: { label: 'Soft Tissue', window: SOFT_TISSUE_WINDOW },
  Digit3: { label: 'Lung', window: LUNG_WINDOW },
  Digit4: { label: 'Abdomen', window: ABDOMEN_WINDOW },
  Digit5: { label: 'Bone', window: BONE_WINDOW },
};

// Vessels
export const PRIORITY_VESSEL_DEFAULT = 'lad';
export const SELECTED_VESSEL_COLOR = '#da22d2';
export const VESSEL_DEFAULT_COLOR = THEME.colors.vessels.other;
export const PRIMARY_VESSEL_COLOR_MAPPING: { [key: string]: string } = {
  lad: THEME.colors.vessels.lad,
  lcx: THEME.colors.vessels.lcx,
  lm: THEME.colors.vessels.lm,
  rca: THEME.colors.vessels.rca,
  other: VESSEL_DEFAULT_COLOR,
  selected: SELECTED_VESSEL_COLOR,
  multiple: '#cccccc',
};

// Dashboard
export const DASHBOARD_ITEMS_PER_PAGE = 12;
export const APPROVAL_WAIT_MINUTES = 5;

// Fix the lighting direction calculation (vs leaving it pointing at the wrong position like it did before).
// The only reason for this flag is so we can render the model in the exact same old broken way that version 1.2
// is doing for the 3D animated demo.
export const MODEL_USE_LIGHTING_FIX: boolean = true;
// Use specular highlighting on the 3D model?
export const MODEL_RENDER_SPECULAR: boolean = true;
// Use fog (fade to black) as a depth cue on the 3D model?
export const MODEL_RENDER_FOG: boolean = false;
// Render shadows on the 3D model? NOTE: This will require significantly more GPU processing per frame.
export const MODEL_RENDER_SHADOWS: boolean = true;
// Render an additional unshadowed backlight?
export const MODEL_RENDER_BACK_LIGHT: boolean = true;
// Use a logarithmic depth buffer for the 3D model? Slower performance but fixes the z-fighting issues where vessels intersect and improves shadow rendering.
export const MODEL_USE_LOGARITHMIC_DEPTH_BUFFER: boolean = true;
// Slowly rotate the 3D model (for demo purposes).
export const MODEL_RENDER_ROTATION_ANIMATION: boolean = false;
// Render the 3D model in fullscreen: this is just for demoing the 3D model.
export const MODEL_FULLSCREEN_DEMO: boolean = false;

// 3d model options
export const MODEL_DEBUG_MODE = false;
export const MODEL_ENABLE_SCROLL_ZOOM = false;
export const MODEL_CAMERA_MAX_DISTANCE = 800;
export const MODEL_CAMERA_MIN_DISTANCE = 75;
export const MODEL_CAMERA_MAX_ZOOM = 3;
export const MODEL_CAMERA_MIN_ZOOM = 0.5;
export const MODEL_TIME_TARGET_TWEEN = 2000;
export const MODEL_TIME_POSITION_TWEEN = 1500;
export const MODEL_LIGHT_INTENSITY_AMBIENT = 0.75;
export const MODEL_LIGHT_INTENSITY_DIRECTIONAL = MODEL_RENDER_FOG ? 2 : 1.5;
export const MODEL_BACK_LIGHT_INTENSITY_DIRECTIONAL = 1.0;
export const MODEL_LIGHT_SPECULAR_SHININESS = 20; // Higher = tighter highlights (only applied if renderSpecular is true)
export const MODEL_LM_CAMERA = {
  x: 245,
  y: 220,
  z: -130,
};
export const MODEL_LAD_CAMERA = {
  x: 215,
  y: 200,
  z: -40,
};
export const MODEL_LCX_CAMERA = {
  x: 200,
  y: 200,
  z: -110,
};
export const MODEL_RCA_CAMERA = {
  x: 165,
  y: 90,
  z: 110,
};
export const MODEL_LM_GROUP = ['lm', 'im1'];
export const MODEL_LAD_GROUP = ['lad', 'd1', 'd2', 'd3', 'd4', 's1', 's2'];
export const MODEL_LCX_GROUP = ['lcx', 'al1', 'al2', 'om1', 'om2', 'l-pl1', 'l-pl2', 'l-pda1', 'l-pda2'];
export const MODEL_RCA_GROUP = [
  'rca',
  'ca1',
  'ca2',
  'san1',
  'san2',
  'rv1',
  'rv2',
  'am1',
  'am2',
  'avn1',
  'avn2',
  'r-pl1',
  'r-pl2',
  'r-pda1',
  'r-pda2',
];

export const MAJOR_VESSELS = ['LAD', 'LCX', 'LM', 'RCA'] as const;
export type VesselGroupings = typeof MAJOR_VESSELS[number];

// Alerts
export const ALERT_AUTO_DISMISS_TIMEOUT = 10000; // ms

// AUTH PERMISSION
export const PERMISSION_CHECK_INTERVAL = 1000 * 30;

// DASHBOARD RELOAD
export const DASHBOARD_RELOAD_INTERVAL = 1000 * 3;

// FFR DATA REFETCH
export const FFR_DATA_REFETCH_INTERVAL = 1000 * 3;

// FFR Value tolerance
export const FFR_TOLERANCE = 0.8;

export const API = {
  dashboard: '/dashboard/datasets',
};

// String representation of stenosis ranges
export const STENOSIS = {
  none: '0%',
  min: '1-24%',
  mild: '25-49%',
  moderate: '50-69%',
  severe: '70-99%',
  occluded: '100%',
};

export const CADRADS = ['0', '1', '2', '3', '4A', '4B', '5'];

export const VULNERABLE_PLAQUE_OPTIONS = ['Absent', 'Present'];

export const ETHNICITIES = ['Black', 'Hispanic', 'Chinese', 'White'];

// Mappings
export const STATUSES = {
  approved: 'Approved',
  edited: 'Edited',
  analysisFailed: 'Analysis Failed',
  ready: 'Ready',
  inReview: 'In Review',
};
export const STENOSIS_TEXT_MAPPING = {
  [STENOSIS.none]: 'No stenosis',
  [STENOSIS.min]: 'Minimal',
  [STENOSIS.mild]: 'Mild',
  [STENOSIS.moderate]: 'Moderate',
  [STENOSIS.severe]: 'Severe',
  [STENOSIS.occluded]: 'Occluded',
};

export const STENOSIS_COLORS_MAPPING = {
  [STENOSIS.none]: THEME.colors.stenosis.none,
  [STENOSIS.min]: THEME.colors.stenosis.mildMinimal,
  [STENOSIS.mild]: THEME.colors.stenosis.mildMinimal,
  [STENOSIS.moderate]: THEME.colors.stenosis.moderate,
  [STENOSIS.severe]: THEME.colors.stenosis.severe,
  [STENOSIS.occluded]: THEME.colors.stenosis.severe,
};

export const STENOSIS_RISK_LEVEL_MAPPING = {
  [STENOSIS.none]: 'none',
  [STENOSIS.min]: 'low',
  [STENOSIS.mild]: 'low',
  [STENOSIS.moderate]: 'medium',
  [STENOSIS.severe]: 'high',
  [STENOSIS.occluded]: 'high',
};

export const NAV_TABS = {
  patientTab: 0,
  ctVolumeTab: 1,
  reportTab: 2,
  reportReviewTab: 3,
};

// Values
export const RISK_LEVELS = ['none', 'low', 'medium', 'high'];
export const STENOSIS_VALUES = Object.keys(STENOSIS_TEXT_MAPPING);
export const STENOSIS_COLOR_MAP = [
  { value: 0, color: THEME.colors.stenosis.none, label: STENOSIS.none },
  { value: 0.1, color: THEME.colors.stenosis.mildMinimal, label: STENOSIS.min },
  { value: 0.25, color: THEME.colors.stenosis.mildMinimal, label: STENOSIS.mild },
  {
    value: 0.5,
    color: THEME.colors.stenosis.moderate,
    label: STENOSIS.moderate,
  },
  { value: 0.7, color: THEME.colors.stenosis.severe, label: STENOSIS.severe },
  { value: 1, color: THEME.colors.stenosis.severe, label: STENOSIS.occluded },
];
export const VESSEL_STENOSIS_COLOR = [
  THEME.colors.stenosis.none,
  THEME.colors.stenosis.mildMinimal,
  THEME.colors.stenosis.moderate,
  THEME.colors.stenosis.severe,
];
export const CPR_SLICE_INDICATOR_BUFFER = 10;

// IndexedDB Expiry Times
export const EXPIRY_HOURS_HDF5_DATA = 1;
export const EXPIRY_HOURS_JSON_DATA = 1;

export const IDLE_CONFIG = {
  // time (in minutes to display modal)
  idle_timeout: process.env.REACT_APP_IDLE_TIMEOUT_MINUTES,
  // countdown (in seconds) starter to redirect to dashboard
  idle_countdown: process.env.REACT_APP_IDLE_COUNTDOWN_SECONDS,
  // lock expiry time (in seconds) configured on API server
  lock_timeout: process.env.REACT_APP_LOCK_TIMEOUT_SECONDS,
};

// assets storage for user manual
export const ASSET_STORAGE = process.env.REACT_APP_S3_ASSETS;
export const USER_MANUAL_FILENAME = 'user-manual.pdf';
export const FFR_MANUAL_FILENAME = 'ffr-manual.pdf';
export const MEASUREMENT_MANUAL_FILENAME = 'measurement-manual.pdf';

// This is a list of substrings that identify low performance GPUs (ie the string is contained in the WebGL renderer parameter UNMASKED_RENDERER_WEBGL).
// So far we have only identified one low performanace GPU (as per Apex crash AP-2634) which when tested returned the string:
// ANGLE (Intel, Intel(R) UHD Graphics 630 Direct3D11 vs_5_0 ps_5_0, D3D11)
export const LOW_PERFORMANCE_GPUS: string[] = ['UHD Graphics 630'];

// Amplify
export const AMPLIFY_CONFIG = {
  Auth: {
    /** REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID */
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    /** REQUIRED - Amazon Cognito Region */
    region: process.env.REACT_APP_REGION,
    /** OPTIONAL - Amazon Cognito User Pool ID */
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    /** OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string) */
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    /** OPTIONAL - Enforce user authentication prior to accessing AWS resources or not */
    mandatorySignIn: true,
  },
  Storage: {
    AWSS3: {
      /** REQUIRED - Amazon S3 Bucket Name for training data */
      bucket: process.env.REACT_APP_S3_BUCKET,
      /** REQUIRED - Amazon S3 Bucket Region */
      region: process.env.REACT_APP_REGION,
    },
  },
};

export interface User {
  username: string | null;
  userId: string | null;
  email: string | null;
  name: string | null;
  email_verified: boolean | null;
  groups: string[] | null;
}

// Auth
export const AUTH_BASE_USER: User = Object.freeze({
  username: null,
  userId: null,
  email: null,
  name: null,
  email_verified: null,
  groups: null,
});

export const AUTH_USER_GROUP = {
  artrya: 'artrya',
  auditors: 'auditors',
};

export const PLAQUE = {
  none: 'None',
  nca: 'Non-calcified',
  m: 'Mixed',
  ca: 'Calcified',
};

export const VULNERABLE_PLAQUE = {
  lap: 'Low-attenuation',
  pr: 'Positive remodelling',
  sc: 'Spotty calcification',
  // nrs: 'napkin-ring sign' @currently not required
};

export const CAD_RADS = {
  N: 'Non-diagnostic',
  S: 'Stent',
  G: 'Graft',
  V: 'Vulnerable plaque',
};

// Report
export const REPORT_OPTIONS = {
  aorta_cal: ['normal', 'abnormal'],
  aorta_disease: [
    'with evidence of moderate disease',
    'with evidence of significant disease',
    'with no evidence of disease',
  ],
  disease: [
    'No evidence of stenosis or plaque',
    'Evidence of plaque',
    'Evidence of stenosis',
    'Evidence of stenosis and plaque',
  ],
  cadrads: ['0', '1', '2', '3', '4A', '4B', '5'],
  plaque: ['no evidence of calcified or non-calcified', 'calcified', 'non-calcified', 'mixed'],
  stenosis: STENOSIS_VALUES,
};

export const PATIENT_STATUS_MAPPING = [
  { status: 'none', label: 'Normal' },
  { status: 'low', label: 'Plaque with stenosis' },
  { status: 'medium', label: 'Stenosis' },
  { status: 'high', label: 'Occlusion' },
];

export const FINDINGS_LAYOUT = ['lm', 'lad', 'lcx', 'rca'];

const VIEWER_SCROLL_RATIO: { [key: string]: number } = {};
VIEWER_SCROLL_RATIO[KEY_CPR] = 5.0;
VIEWER_SCROLL_RATIO[KEY_CT_CONTRAST] = 5.0;
VIEWER_SCROLL_RATIO[KEY_CT_NON_CONTRAST] = 5.0;
VIEWER_SCROLL_RATIO[KEY_MPR_LONG_AXIS] = 5.0;
VIEWER_SCROLL_RATIO[KEY_MPR_SHORT_AXIS] = 1.0;

export { VIEWER_SCROLL_RATIO };

export const STUDY_LOAD_ERROR_MSG =
  'There was an error loading the study, please try again. If this error persists, please contact support@artrya.com';

export const LOG_LEVEL: number = parseInt(process.env.REACT_APP_LOG_LEVEL || '0');

export const MOUSE_BUTTONS = {
  NONE: 0,
  LEFT: 1,
  RIGHT: 2,
  BOTH: 3,
  MIDDLE: 4,
};

export const MPR_SLICE_SPACING: number = 0.5;
export const MPR_SLICE_SPACING_UNIT: string = 'mm';
