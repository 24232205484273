import * as api from '../../utils/api';
import type { AppDispatch } from '../../store';
import { useAppSelector } from '../../hooks';
import {
  initialLoadActions,
  LoadState,
  InitialLoader,
  initialLoaderArray,
  UnifiedId,
} from '../initialLoad/initialLoadSlice';

/**
 * A generic function to handle the updating of the loadState for any promise type.
 * If 'initialLoad' is true then the store 'initialLoad.loadState' will be updated with the current loading state.
 */
export function loadWithPromise<DataType>(
  promise: Promise<DataType>,
  dispatch: AppDispatch,
  initialLoad: boolean,
  loaderName: InitialLoader,
  onSuccess: (data: DataType) => void
) {
  if (initialLoad) {
    dispatch(initialLoadActions.setLoadState({ loaderName, loadState: LoadState.LOADING }));
  }
  promise
    .then((data: DataType) => {
      onSuccess(data);
      if (initialLoad) {
        dispatch(initialLoadActions.setLoadState({ loaderName, loadState: LoadState.LOADED }));
      }
    })
    .catch((error) => {
      console.error(loaderName, error);
      if (initialLoad) {
        dispatch(initialLoadActions.setLoadState({ loaderName, loadState: LoadState.FAILED }));
      }
    });
}

/**
 * Get the req properties object required for a patient data-fetch.
 */
export function getReqProperties(unifiedId: Omit<UnifiedId, 'version'>) {
  const patientDataGroup = 'patient-data';
  const reqProperties = {
    patient_id: unifiedId.patientId,
    run_id: unifiedId.runId,
    group_type: patientDataGroup,
  };
  return reqProperties;
}

/**
 * A generic loader for simplistic data fetching.
 * It loads the specified data from the backend and saves it in the store using 'dispatch' 'action'.
 * If 'initialLoad' is true then the store 'initialLoad.loadState' will be updated with the current loading state.
 */
export function fetchWebData<DataType>(
  unifiedId: UnifiedId,
  dispatch: AppDispatch,
  initialLoad: boolean,
  loaderName: InitialLoader,
  onSuccess: (data: DataType) => void
) {
  const reqProperties = getReqProperties(unifiedId);
  const promise = api.getJSON(
    `/data/${unifiedId.patientId}/${unifiedId.runId}/web-data/${loaderName}?version=${unifiedId.version}`,
    false,
    reqProperties
  );

  loadWithPromise(promise, dispatch, initialLoad, loaderName, onSuccess);
}

/**
 * This is true if ALL loaders have loaded their data.
 */
export function useLoadersLoaded(): boolean {
  const loadState = useAppSelector((state) => state.initialLoad.loadState);
  return initialLoaderArray.reduce((acc: boolean, loaderName: string) => {
    if (loadState[loaderName] !== LoadState.LOADED) return false;
    return acc;
  }, true);
}

/**
 * This is true if ANY loaders failed to load their data.
 */
export function useLoadersFailed(): boolean {
  const loadState = useAppSelector((state) => state.initialLoad.loadState);
  return initialLoaderArray.find((loaderName) => loadState[loaderName] === LoadState.FAILED) !== undefined;
}

/**
 * This is true if the specified loader has not attempted to load any data yet.
 */
export function useNeedsLoading(loaderName: InitialLoader): boolean {
  return useAppSelector((state) => state.initialLoad.loadState[loaderName] === undefined);
}
