import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import showToast from '../../../components/Toast/showToast';
import { StudyData } from '../../../context/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useUpdateBackendVersionHead, useUpdateVersionHead } from '../../../hooks/use-version-head';
import { lesionActions } from '../../../reducers/lesion/lesionSlice';
import { ContrastLesionData, ContrastLesionDataResponse } from '../../../reducers/lesion/types';
import { reportActions } from '../../../reducers/report/reportSlice';
import { studyActions } from '../../../reducers/study/studySlice';
import { VesselData, VesselDataResponse } from '../../../reducers/vesselData/types';
import { vesselDataActions } from '../../../reducers/vesselData/vesselDataSlice';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { GenericObject } from '../../../types/common';
import * as api from '../../../utils/api';

export interface EditStenosisRequest {
  edit_stenosis_max: string;
  type: 'stenosis';
}
export interface EditPlaqueRequest {
  edit_plaque_composition: string;
  type: 'plaque';
}
export interface ToggleNRSRequest {
  toggle_nrs: {} | null;
  type: 'vulnerable plaque';
}
export interface ToggleSCRequest {
  toggle_sc: {} | null;
  type: 'vulnerable plaque';
}
export interface TogglePRRequest {
  toggle_pr: {} | null;
  type: 'vulnerable plaque';
}
export interface ToggleLAPRequest {
  toggle_lap: {} | null;
  type: 'vulnerable plaque';
}

const FRESH_CONTRAST_LESION_DATA: ContrastLesionData = {
  added_slices: [],
  plaque_composition: 'none',
  priority_slice: 0,
  segment: 'm',
  slices: [], // slices may exist
  stenosis_max: '0%',
  vp_biomarker_counts: {},
  vulnerability: false,
};

let editCount = 0;
let highestCompletedEditNumber = 0;

export function useSavePerLesionData(
  setSavingLesion: (saving: React.SetStateAction<boolean>) => void,
  currentLesionId: string | null,
  currentLesionData: ContrastLesionDataResponse | null
) {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const updateVersionHead = useUpdateVersionHead();
  const updateBackendVersionHead = useUpdateBackendVersionHead();
  const versionHead = useAppSelector((state) => state.store.versionHead);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);

  const patientID = useAppSelector((state) => state.patient.patientID);

  const { midSliceIdx: sliceidx, selectedVesselName } = useVesselStateSelector();

  const updateLesionData = useUpdateLesionData(currentLesionData);

  return useCallback(
    (
      command:
        | EditStenosisRequest
        | EditPlaqueRequest
        | ToggleNRSRequest
        | ToggleSCRequest
        | TogglePRRequest
        | ToggleLAPRequest
    ) => {
      editCount++;
      const currentEditNumber = editCount;
      setSavingLesion(true);
      api.websocket(
        '/ws/tasks/disease/edit',
        currentLesionId
          ? {
              study_id: id,
              run_id: runID,
              vessel_id: selectedVesselName,
              version_id: versionHead,
              slice_idx: sliceidx,
              contrast_lesion_id: currentLesionId,
              command,
            }
          : {
              study_id: id,
              run_id: runID,
              vessel_id: selectedVesselName,
              version_id: versionHead,
              slice_idx: sliceidx,
              command,
            },
        // The request expects the two following responses
        // Only once `result_save_disease_edit` has completed do we call `setSavingLesion(false)`
        // `savingLesion` is used to disable per-lesion editing while the request is in flight
        ['result_disease_edit', 'result_save_disease_edit'],
        (json: GenericObject, responseType?: string) => {
          if (responseType === 'result_disease_edit') {
            updateVersionHead(json.data.new_version_id);
            updateLesionData(json.data.result);
            if (json.data.can_edit) {
              setSavingLesion(false);
              showToast.success(`${selectedVesselName?.toUpperCase()} ${command.type} change saved.`);
            }
          } else if (responseType === 'result_save_disease_edit') {
            json.data.version_id &&
              updateBackendVersionHead(json.data.version_id).then(() => {
                // TODO: vulnerable plaque for report content will need to be return as par of 'disease_edit'
                //       rather then calling the API again
                if (patientID && runID) {
                  dispatch(reportActions.setFetching(true));
                  api
                    .fetchReport(patientID, runID, json.data.version_id)
                    .then((response) => {
                      // Only use result if this is more recent than the last result shown
                      if (currentEditNumber < highestCompletedEditNumber) {
                        return;
                      }
                      highestCompletedEditNumber = currentEditNumber;

                      // Only call this in this message response
                      dispatch(reportActions.setCurrentReport(response));

                      setSavingLesion((savingLesion) => {
                        // Only show feedback if not already shown
                        if (savingLesion) {
                          showToast.success(`${selectedVesselName?.toUpperCase()} ${command.type} change saved.`);
                        }
                        return false;
                      });
                    })
                    .finally(() => dispatch(reportActions.setFetching(false)));
                }
              });
          }
        },
        (error: Error) => {
          console.error(error);
          setSavingLesion(false);

          showToast.error(`Failed to save ${command.type} for ${selectedVesselName?.toUpperCase()}.`);
        }
      );
    },
    [
      setSavingLesion,
      currentLesionId,
      id,
      runID,
      selectedVesselName,
      versionHead,
      sliceidx,
      updateVersionHead,
      updateLesionData,
      updateBackendVersionHead,
      patientID,
      dispatch,
    ]
  );
}

function useUpdateLesionData(currentLesionData: ContrastLesionDataResponse | null) {
  const dispatch = useAppDispatch();

  const studyData = useAppSelector((state) => state.study.studyData);

  const { vesselData, selectedVesselName } = useVesselStateSelector();

  return useCallback(
    (newLesionData: any) => {
      if (!currentLesionData) return;

      const lesionData = cloneDeep(currentLesionData);
      let newStudyData: StudyData | undefined = studyData ? cloneDeep(studyData) : undefined;
      let newVesselData: VesselDataResponse | undefined = vesselData ? cloneDeep(vesselData) : undefined;

      const vesselLesionIdsKey = `vessel-data.${selectedVesselName}.contrast_lesion_ids`;
      const activeVesselLesions = newLesionData[vesselLesionIdsKey];

      Object.entries(newLesionData).forEach(([key, value]: [string, any]) => {
        const keyData = key.split('.');

        if (keyData[0] === 'contrast-lesion-data') {
          const vesselToUpdate = keyData[1];
          const lesionIdToUpdate = keyData[2];
          const propertyToUpdate = keyData[3];

          // check if the lesion id exists in the contrast lesion ids array. If not its stale and we need to
          // create a new lesion
          const lesionActive = Array.isArray(activeVesselLesions) && activeVesselLesions.includes(lesionIdToUpdate);

          // if the vessel does not have any lesion data or the lesion is new, then create one

          if (
            Object.keys(lesionData[vesselToUpdate]).length === 0 ||
            !lesionData[vesselToUpdate][lesionIdToUpdate] ||
            !lesionActive
          ) {
            // Copy original data to overwrite fresh data if it exists
            const originalLesionData = lesionData[vesselToUpdate][lesionIdToUpdate] ?? {};

            // Overwrite the fresh data if it exists
            lesionData[vesselToUpdate][lesionIdToUpdate] = {
              ...FRESH_CONTRAST_LESION_DATA,
              ...originalLesionData,
            };
          }

          (lesionData[vesselToUpdate][lesionIdToUpdate] as any)[propertyToUpdate] = value;
        } else if (keyData[0] === 'study-data' && newStudyData) {
          const attrToUpdate = keyData[1];
          const thisValue = value as keyof StudyData;

          (newStudyData as any)[attrToUpdate] = thisValue;
        } else if (keyData[0] === 'vessel-data' && newVesselData) {
          const vesselToUpdate = keyData[1];
          const attrToUpdate = keyData[2] as keyof VesselData;
          const thisValue = value as keyof VesselData;

          (newVesselData[vesselToUpdate] as any)[attrToUpdate] = thisValue;
        }
      });

      lesionData && dispatch(lesionActions.setContrastLesionData({ ...currentLesionData, ...lesionData }));
      if (newStudyData != null && studyData != null) {
        const study: StudyData = { ...studyData, ...newStudyData };
        dispatch(studyActions.setStudyData(study));
      }
      if (newVesselData) {
        dispatch(vesselDataActions.setVesselData({ ...vesselData, ...newVesselData }));
      }
    },
    [currentLesionData, studyData, vesselData, selectedVesselName, dispatch]
  );
}
