import { useAppDispatch, useAppSelector } from '../../hooks';
import * as api from '../../utils/api';
import { useEffect } from 'react';
import { AppDispatch } from '../../store';
import { reportActions } from './reportSlice';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, useNeedsLoading } from '../initialLoad/utils';

const loaderName = InitialLoader.REPORT;

/**
 * Load the report from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function loadReport(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  const promise = Promise.all([
    api.fetchReport(unifiedId.patientId, unifiedId.runId),
    api.fetchReportHistory(unifiedId.patientId, unifiedId.runId),
  ]);
  loadWithPromise(promise, dispatch, initialLoad, loaderName, ([report, history]) => {
    dispatch(reportActions.setCurrentReport(report));
    dispatch(reportActions.setReportHistory(history));
  });
}

/**
 * A component to load the initial report data for a study and add it to the store.
 */
export const ReportLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useNeedsLoading(loaderName);

  useEffect(() => {
    if (unifiedId && needsLoading) {
      loadReport(unifiedId, dispatch, true);
    }
  }, [unifiedId, needsLoading, dispatch]);

  return null;
};
