import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume';
import { vtkApi } from '../views/CTVolume/ReactVTKJS/ReactVTKJSTypes';
import { vec3 } from 'gl-matrix';
export interface CrosshairValues {
  // The current ct volume crosshair position in model space [X, Y, Z].
  crosshairPos?: number[];
  // The huValue of the ct volume at the current crosshair position.
  huValue?: number;
}

// The contrast volume projection methods.
export enum BlendMode {
  COMPOSITE_BLEND,
  MAXIMUM_INTENSITY_BLEND,
  MINIMUM_INTENSITY_BLEND,
  AVERAGE_INTENSITY_BLEND,
}

// Define the contrast volume view types.
export enum ContrastViewType {
  Axial = 0,
  Sagittal = 1,
  Coronal = 2,
  MPR = 3,
  Empty = 4,
}

// Define the status of the contrast volume.
export enum ContrastVolumeStatus {
  // The contrast volume is loading the volume slices.
  LOADING = 'VOLUME LOADING',
  // The contrast volume failed to load and has terminated the loading process.
  LOAD_FAILED = 'VOLUME LOAD FAILED',
  // The contrast volume has loaded the volume and initialized the initial parameters.
  // NOTE: This occurs after the 'volume' value has been set and the onViewCreated callback has set up initial state.
  LOADED = 'VOLUME LOADED',
}

export enum CTVolumeOverlay {
  INFO = 'INFO',
  REPORT = 'REPORT',
  HIDE = 'HIDE',
}

// The cyclical modes that define what overlays are shown on the contrast volume view.
export enum ContrastVolumeOverlayMode {
  CROSSHAIRS_AND_CENTERLINE,
  NONE,
  COUNT, // The number of overlay modes.
}

/**
 * This stores the information required to know when the contrast views associated with an MPR view need
 * to be synchronized to it. We can't just syncronize when the vessel is adjusted because the contrast
 * view may not have loaded yet etc.
 */
export interface VesselSyncInfo {
  // The name of the vessel that was last synchronized.
  vesselName: string;
  // The index of the slice that was last synchronized.
  sliceIndex: number;
}

// The details of what is showing in a view on the contrast volume page.
export interface ContrastViewContent {
  // The name of the series the volume belongs to (or undefined if the viewType is Empty).
  seriesName?: string;
  // The view type being shown.
  viewType: ContrastViewType;
}

// The information describing the way to render a view of a ContrastVolume.
export interface ContrastVolumeViewProps {
  // The type of projection used when casting rays on this volume (ie MIP, MinIP, or AvgIP).
  blendMode: BlendMode;
  // The thickness through which the ray will be cast when rendering the volume (using MIP, MinIP, or AvgIP).
  renderThickness: number;
  // The cyclical modes that define what overlays are shown on the contrast volume view.
  overlayMode: ContrastVolumeOverlayMode;
}

// The information describing a loaded contrast CT volume.
export interface ContrastVolume {
  // The name of the series the volume belongs to.
  seriesName: string;
  // Flag if the image load failed and can't be continued.
  status: ContrastVolumeStatus;
  // The loaded volume data.
  volume: vtkVolume | undefined;
  // The array of vtkApis (one for each view showing this volume) (this also needs to be consistent between renders).
  apis: vtkApi[];
  // The crosshair position and HU value.
  crosshairValues: CrosshairValues;
  // The position of the crosshairs [X, Y, Z] (this value gets mutated by vtk, it's also shared between views).
  crosshairWorldPosition: vec3;
  // The directional unit vector for each of the crosshair axes [ Axial, Sagittal, Coronal ] (this value gets mutated by vtk, it's also shared between views).
  crosshairWorldAxes: vec3[];
  // The information describing the way to render each view of the ContrastVolume.
  viewProps: ContrastVolumeViewProps[];
  // A helper function to get the api that is used on the specified viewIndex.
  getApi: (viewIndex: number) => vtkApi | undefined;
}

export type ContrastVolumeMap = Map<string, ContrastVolume>;
export enum ContrastVolumeActions {
  // Initialize the ContrastVolume.
  LOAD = 'LOAD',
  // Update the volume's status, if LOAD_FAILED is set then the ContrastVolume will abort loading.
  SET_STATUS = 'SET_STATUS',
  // Set the ContrastVolume volume, spacing and default window levels. This is the final step for loading.
  SET_VOLUME_SPACING_AND_WINDOW_LEVELS = 'SET_VOLUME_SPACING_AND_DEFAULT_WINDOW_LEVELS',
  // Set the ContrastVolume viewProps value for the specified ContrastViewType of the type
  // of projection used when casting rays on this volume (ie MIP, MinIP, or AvgIP).
  SET_BLEND_MODE = 'SET_BLEND_MODE',
  // Set the ContrastVolume viewProps value for the specified ContrastViewType of the thickness
  // through which the ray will be cast when rendering the volume (using MIP, MinIP, or AvgIP).
  SET_RENDER_THICKNESS = 'SET_RENDER_THICKNESS',
  // Set which overlays are currently visible on the volume.
  SET_OVERLAY_MODE = 'SET_OVERLAY_MODE',
  // Reset the renderThickness and blendMode for all views on all volumes.
  RESET_VIEWS = 'RESET_VIEWS',
  // Update the ContrastVolume crosshair values for the given viewIndex.
  UPDATE_CROSSHAIR_VALUES = 'UPDATE_CROSSHAIR_VALUES',
  // Update the ContrastVolume crosshair values for the given ContrastVolume.
  UPDATE_CROSSHAIR_VALUES_FOR_VOLUME = 'UPDATE_CROSSHAIR_VALUES_FOR_VOLUME',
  // Remove the specified ContrastVolume from the store.
  REMOVE = 'REMOVE',
}
export type ContrastVolumeBasicAction = { seriesName: string };
export type ContrastVolumeLoadAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.LOAD;
};
export type ContrastVolumeSetStatusAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_STATUS;
  status: ContrastVolumeStatus;
};
export type ContrastVolumeSetVolumeSpacingWindowAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_VOLUME_SPACING_AND_WINDOW_LEVELS;
  volume: vtkVolume;
  spacing: vec3;
};
export type ContrastVolumeSetBlendModeAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_BLEND_MODE;
  viewType: ContrastViewType;
  blendMode: BlendMode;
};
export type ContrastVolumeSetRenderThicknessAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_RENDER_THICKNESS;
  viewType: ContrastViewType;
  renderThickness: number;
};
export type ContrastVolumeSetOverlayModeAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_OVERLAY_MODE;
  viewType: ContrastViewType;
  overlayMode: ContrastVolumeOverlayMode;
};
export type ContrastVolumeRemoveAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.REMOVE;
};
export type ContrastVolumeResetViewsAction = {
  type: ContrastVolumeActions.RESET_VIEWS;
};
export type ContrastVolumeUpdateCrosshairValuesAction = {
  type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES;
  viewIndex: number;
};
export type ContrastVolumeUpdateCrosshairValuesForVolumeAction = {
  type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES_FOR_VOLUME;
  contrastVolume: ContrastVolume;
};
export type ContrastVolumeAction =
  | ContrastVolumeLoadAction
  | ContrastVolumeSetStatusAction
  | ContrastVolumeSetVolumeSpacingWindowAction
  | ContrastVolumeSetBlendModeAction
  | ContrastVolumeSetRenderThicknessAction
  | ContrastVolumeSetOverlayModeAction
  | ContrastVolumeResetViewsAction
  | ContrastVolumeUpdateCrosshairValuesAction
  | ContrastVolumeUpdateCrosshairValuesForVolumeAction
  | ContrastVolumeRemoveAction;

export interface ContrastPendingScreenshot {
  // The index of the view that is generating a screenshot.
  viewIndex: number;
  // The function to call when the view has been set to full size (to take the screenshot).
  onTakeScreenshotCallback: (imageData: string) => void;
  // The visibleViews array before the screenshot was taken.
  lastVisibleViews: number[];
}

export interface ContrastContextValue {
  // The map of seriesName to ContrastVolume.
  contrastVolumeMap: ContrastVolumeMap;
  // Fire off an action that changes a ContrastVolume.
  dispatchContrastVolumeAction: (action: ContrastVolumeAction) => void;
}
