import React from 'react';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { workflowActions } from '../../reducers/workflow/workflowSlice';
import { useCancelReviewRequest } from '../../hooks/use-review-status-hooks';
import { useDashboardDispatch, useDashboardSelector } from '../../dashboardHooks';

export const WorkflowConfirmModal: React.FunctionComponent = () => {
  const cancelReviewRequest = useCancelReviewRequest();
  const dashboardDispatch = useDashboardDispatch();
  const { studyToAction, showCancelReviewConfirm } = useDashboardSelector((state) => state.workflow);

  const closeCancelReview = () => {
    dashboardDispatch(workflowActions.setShowCancelReviewConfirm(false));
    dashboardDispatch(workflowActions.setStudyToAction(null));
  };

  /** Unmount when not showing so buttons aren't still clickable */
  if (showCancelReviewConfirm === false) {
    return null;
  }

  return (
    <ActionModal
      closeText={'Cancel'}
      onClose={() => closeCancelReview()}
      confirmText={'Confirm'}
      onConfirm={() => cancelReviewRequest({ study: studyToAction })}
      visible={showCancelReviewConfirm}
      headerContent={<>Cancel Review Request</>}
    >
      <p>Please confirm you wish to cancel this review request.</p>
    </ActionModal>
  );
};
