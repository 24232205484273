import { ContrastViewType } from '../../context/contrast-types';

export enum DraggableType {
  PARENT = 'parent',
  CHILD = 'child',
}

export enum DraggableCustomType {
  CONTRAST = 'contrast',
  AXIAL = 'axial',
  SAGITTAL = 'sagittal',
  CORONAL = 'coronal',
  MPR = 'mpr',
  NON_CONTRAST = 'noncontrast',
}

// A thumbnail can be specified as a URL or one of the pre-defined thumbnail images.
export type ThumbnailType =
  | { url: string }
  | DraggableCustomType.CORONAL
  | DraggableCustomType.SAGITTAL
  | DraggableCustomType.MPR;

export type SelectedDraggable =
  | { id: string; itemType: DraggableType.PARENT }
  | {
      id: string;
      childIndex: number;
      itemType: DraggableType.CHILD;
    };

export interface DraggableItem {
  // The text to display describing this dragable item.
  title: string;
  // Is this the PARENT item in a DraggableGroup or a CHILD item?
  type: DraggableType;
  // The series identifier (eg 'contrast' or 'noncontrast').
  id: string;
  // The type of the draggable view.
  customType: DraggableCustomType;
  // Additional text to display with extra information about the draggable (eg 'AI ASSESSED').
  extraInfo?: string;
  // Is this item currently being dragged by the mouse?
  isDragging: boolean;
  // The URL or static image type to use for this draggable's thumbnail.
  thumbnail?: ThumbnailType;
}

// The DraggableGroup will contain a parent (eg a contrast series) and zero or more children (eg the Axial, Sagittal, Coronal, and MPR views).
export interface DraggableGroup {
  parent: DraggableItem;
  children: DraggableItem[];
}

/**
 * Provides info about a draggable item that should be highlighted.
 */
export interface DraggableHighlight {
  // The name of the series.
  key: string;
  // The ContrastViewType.
  type: ContrastViewType;
  // True if the view is currently visible, false otherwise.
  visible: boolean;
}
