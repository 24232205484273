import { PRIMARY_VESSEL_COLOR_MAPPING, VESSEL_DEFAULT_COLOR } from '../../config';
import { getVesselBaseName } from '../../utils/vessel';

export const getVesselColor = (vessel: string) => {
  if (!vessel) return VESSEL_DEFAULT_COLOR;

  const baseName = getVesselBaseName(vessel)?.toLowerCase();
  let col = baseName && PRIMARY_VESSEL_COLOR_MAPPING[baseName as keyof typeof PRIMARY_VESSEL_COLOR_MAPPING];

  return col || VESSEL_DEFAULT_COLOR;
};
