// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import {
  DEFAULT_WINDOW,
  PRESET_MAP,
  WINDOW_LEVEL_DEFAULT,
  WINDOW_LEVEL_MAX,
  WINDOW_LEVEL_MIN,
  WINDOW_WIDTH_DEFAULT,
  WINDOW_WIDTH_MAX,
  WINDOW_WIDTH_MIN,
} from '../../config';
import { LabelledWindowLevels, WindowLevels } from './types';
import { clampNumber } from '../../utils/shared';

export type windowState = {
  contrastWindowLabel: string | null;
  contrastWindowLevels: WindowLevels;
  nonContrastWindowLabel: string | null;
  nonContrastWindowLevels: WindowLevels;
};

export const initialWindowState = (): windowState => {
  return {
    contrastWindowLabel: null,
    contrastWindowLevels: DEFAULT_WINDOW,
    nonContrastWindowLabel: null,
    nonContrastWindowLevels: DEFAULT_WINDOW,
  };
};

export const windowSlice = createSlice({
  name: 'window',
  initialState: initialWindowState(),
  reducers: {
    setContrastWindowLabel: (state, action: PayloadAction<string | null>) => {
      state.contrastWindowLabel = action.payload;
    },
    setContrastWindowLevels: (state, action: PayloadAction<WindowLevels>) => {
      const x = action.payload;
      const newWindowLevels: WindowLevels = {
        windowCenter: clampNumber(x ? x.windowCenter : WINDOW_LEVEL_DEFAULT, WINDOW_LEVEL_MIN, WINDOW_LEVEL_MAX),
        windowWidth: clampNumber(x ? x.windowWidth : WINDOW_WIDTH_DEFAULT, WINDOW_WIDTH_MIN, WINDOW_WIDTH_MAX),
      };
      state.contrastWindowLevels = newWindowLevels;

      // update contrastWindowLabel
      let newLabel = null;
      const matchingWindowLevels = Object.values(PRESET_MAP).find((windowLevelsConfig: LabelledWindowLevels) => {
        return isEqual(newWindowLevels, windowLevelsConfig.window);
      });
      if (matchingWindowLevels) {
        newLabel = matchingWindowLevels.label;
      }
      state.contrastWindowLabel = newLabel;
    },
    setNonContrastWindowLevels: (state, action: PayloadAction<WindowLevels>) => {
      const x = action.payload;
      const newWindowLevels: WindowLevels = {
        windowCenter: clampNumber(x ? x.windowCenter : WINDOW_LEVEL_DEFAULT, WINDOW_LEVEL_MIN, WINDOW_LEVEL_MAX),
        windowWidth: clampNumber(x ? x.windowWidth : WINDOW_WIDTH_DEFAULT, WINDOW_WIDTH_MIN, WINDOW_WIDTH_MAX),
      };
      state.nonContrastWindowLevels = newWindowLevels;

      // update nonContrastWindowLabel
      let newLabel = null;
      const matchingWindowLevels = Object.values(PRESET_MAP).find((windowLevelsConfig: LabelledWindowLevels) => {
        return isEqual(newWindowLevels, windowLevelsConfig.window);
      });
      if (matchingWindowLevels) {
        newLabel = matchingWindowLevels.label;
      }
      state.nonContrastWindowLabel = newLabel;
    },
    setNonContrastWindowLabel: (state, action: PayloadAction<string | null>) => {
      state.nonContrastWindowLabel = action.payload;
    },
    resetWindowLevels: (state) => {
      state.contrastWindowLevels = DEFAULT_WINDOW;
      state.nonContrastWindowLevels = DEFAULT_WINDOW;
    },
  },
});

export const windowAction = windowSlice.actions;
