import React from 'react';
import { customClassCheck } from '../../utils/shared';
import { ReactComponent as AccountIcon } from '../../assets/icons/reskin/icon-account.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/reskin/icon-arrow-down.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/reskin/icon-arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/reskin/icon-arrow-right.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/reskin/icon-arrow-up.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/reskin/icon-cross.svg';
import { ReactComponent as CTVolumeIcon } from '../../assets/icons/reskin/icon-CT-Volume.svg';
import { ReactComponent as DashIcon } from '../../assets/icons/reskin/icon-dash.svg';
import { ReactComponent as FourViewsIcon } from '../../assets/icons/reskin/icon-fourviews.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/reskin/icon-home.svg';
import { ReactComponent as LassoIcon } from '../../assets/icons/reskin/icon-lasso.svg';
import { ReactComponent as LineToolIcon } from '../../assets/icons/reskin/icon-linetool.svg';
import { ReactComponent as LoadingIcon } from '../../assets/icons/reskin/icon-loading.svg';
import { ReactComponent as MoveIcon } from '../../assets/icons/reskin/icon-move.svg';
import { ReactComponent as PatientOverviewIcon } from '../../assets/icons/reskin/icon-patient-overview.svg';
import { ReactComponent as RecentreIcon } from '../../assets/icons/reskin/icon-recentre.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/reskin/icon-report.svg';
import { ReactComponent as ResetIcon } from '../../assets/icons/reskin/icon-reset.svg';
import { ReactComponent as ScreenshotIcon } from '../../assets/icons/reskin/icon-screenshot.svg';
import { ReactComponent as SingleViewIcon } from '../../assets/icons/reskin/icon-singleview.svg';
import { ReactComponent as TickIcon } from '../../assets/icons/reskin/icon-tick.svg';

// All the icons for Salix are contained here. Usage instructions:
// 1. Import your SVG files first, be sure to follow the naming convention
// 2. Update the ICONS object with your new SVG icon and give it a name
// 3. You can now use your icon throughout Salix, using props to set the icon, type and an optional custom class

interface IconProps {
  icon: string;
  type: string;
  customClass?: string;
}

// SVG Icon components mapped to a label
const ICONS: { [key: string]: JSX.Element } = {
  account: <AccountIcon />,
  arrowdown: <ArrowDownIcon />,
  arrowleft: <ArrowLeftIcon />,
  arrowright: <ArrowRightIcon />,
  arrowup: <ArrowUpIcon />,
  cross: <CrossIcon />,
  ctvolume: <CTVolumeIcon />,
  dash: <DashIcon />,
  fourviews: <FourViewsIcon />,
  home: <HomeIcon />,
  lasso: <LassoIcon />,
  line: <LineToolIcon />,
  loading: <LoadingIcon />,
  move: <MoveIcon />,
  patientoverview: <PatientOverviewIcon />,
  recentre: <RecentreIcon />,
  report: <ReportIcon />,
  reset: <ResetIcon />,
  screenshot: <ScreenshotIcon />,
  singleview: <SingleViewIcon />,
  tick: <TickIcon />,
};

export const Icon: React.FunctionComponent<IconProps> = ({ icon, type, customClass }) => {
  return <span className={`icon-wrapper icon--${type}${customClassCheck(customClass)}`}>{ICONS[icon]}</span>;
};
