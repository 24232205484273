import Moment from 'moment';
import React, { useState, useCallback } from 'react';
import { APPROVAL_WAIT_MINUTES, User } from '../../config';
import { Study } from '../../context/types';
import { workflowActions } from '../../reducers/workflow/workflowSlice';
import cn from 'classnames';
import { StudiesForReview } from './Studies/StudiesForReview';
import { RecentlyApprovedStudies } from './Studies/StudiesRecentlyApproved';
import { StudiesAwaitingReview } from './Studies/StudiesAwaitingReview';
import { StudiesAwaitingApproval } from './Studies/StudiesAwaitingApproval';
import { WorkflowConfirmModal } from './WorkflowConfirmModal';
import { useEffectCustomDeps } from '../../hooks/useEffectCustomDeps';
import { useDashboardDispatch } from '../../dashboardHooks';

export function formatDate(value: string, format: string) {
  if (!value || !Moment(value).isValid()) return '-';
  return Moment(value).format(format);
}

interface WorkflowActionsProps {
  user: User;
  studies: Study[];
  onViewReport: (params: any) => void;
}

export const WorkflowActions: React.FunctionComponent<WorkflowActionsProps> = ({ studies, onViewReport, user }) => {
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [forReviewList, setForReviewList] = useState<Study[]>([]);
  const [recentlyApprovedList, setRecentlyApprovedList] = useState<Study[]>([]);
  const [awaitingApproveList, setAwaitingApproveList] = useState<Study[]>([]);
  const [awaitingReviewList, setAwaitingReviewList] = useState<Study[]>([]);

  const dashboardDispatch = useDashboardDispatch();

  useEffectCustomDeps(() => {
    const forReview: Study[] = [];
    const awaitingReview: Study[] = [];
    const awaitingApproval: Study[] = [];
    const recentlyApproved: Study[] = [];

    studies.forEach((study) => {
      if (study.report_active_review != null) {
        if (study.report_active_review.assignee.email === user.email) {
          forReview.push(study);
          setTotalListCount(totalListCount + 1);
        } else if (study.report_active_review.assigner.email === user.email) {
          if (study.report_active_review.review_type === 'review') {
            awaitingReview.push(study);
            setTotalListCount(totalListCount + 1);
          } else {
            awaitingApproval.push(study);
            setTotalListCount(totalListCount + 1);
          }
        }
      }

      if (study.report_approval != null && study.report_approval.user.email === user.email) {
        const approvalTime = Moment(study.report_approval.timestamp);
        const current = Moment(new Date());
        const difference = current.diff(approvalTime, 'minutes');
        if (difference < APPROVAL_WAIT_MINUTES) {
          recentlyApproved.push(study);
          setTotalListCount(totalListCount + 1);
        }
      }
    });

    setForReviewList(forReview);
    setAwaitingReviewList(awaitingReview);
    setAwaitingApproveList(awaitingApproval);
    setRecentlyApprovedList(recentlyApproved);
  }, [studies, user]);

  const openStudyReport = (study: Study) => {
    const studyParams = {
      study_id: study.study_id,
      status: study.workflow_status || study.status,
      user: study.workflow_user,
      client_id: study.client_id,
      can_be_viewed: study.can_be_viewed,
    };
    onViewReport(studyParams);
  };

  /**
   * Callback for when the user chooses to cancel a request for review or approval.
   */
  const onCancel = useCallback(
    (study: Study) => {
      dashboardDispatch(workflowActions.setStudyToAction(study));
      dashboardDispatch(workflowActions.setShowCancelReviewConfirm(true));
    },
    [dashboardDispatch]
  );

  return (
    <div
      className={cn('dashboard__actions', {
        'dashboard__actions--hidden': totalListCount <= 0,
      })}
    >
      {totalListCount > 0 && (
        <div className="workflow-actions">
          <div className="workflow-actions__list">
            <RecentlyApprovedStudies studies={recentlyApprovedList} openStudyReport={openStudyReport} />
            <StudiesForReview studies={forReviewList} openStudyReport={openStudyReport} />
            <StudiesAwaitingReview studies={awaitingReviewList} openStudyReport={openStudyReport} onCancel={onCancel} />
            <StudiesAwaitingApproval
              studies={awaitingApproveList}
              openStudyReport={openStudyReport}
              onCancel={onCancel}
            />
          </div>
        </div>
      )}
      <WorkflowConfirmModal />
    </div>
  );
};
