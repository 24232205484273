import { delay } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CPRViewer } from '../../../components/CPRViewer/CPRViewer';
import { Loader } from '../../../components/Loader/Loader';
import { useContrastOverlaySelector, useOnDoubleClickView } from '../../../hooks/useContrastViewHelpers';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useContrastWindowLevelsInputs } from '../../../hooks/useWindow';
import { windowAction } from '../../../reducers/window/windowSlice';
import { cprVersionSelector } from '../../../selectors/study';
import { useVesselStateSelector } from '../../../selectors/vessels';
import MetaDetails from '../MetaDetails/MetaDetails';
import { WindowLevels } from '../../../reducers/window/types';

interface MPRViewProps {
  viewIndex: number;
}

/**
 * The wrapper for the MPR view.
 */
export const MPRView: React.FunctionComponent<MPRViewProps> = ({ viewIndex }) => {
  const { pendingScreenshot, visibleViews } = useAppSelector((state) => state.contrast);
  const onDoubleClickView = useOnDoubleClickView();
  const dispatch = useAppDispatch();
  const cprVersion = useAppSelector(cprVersionSelector);
  const triggerResetPanAndZoom = useAppSelector((state) => state.cpr.triggerResetPanAndZoom);
  const { contrastWindowLevels, contrastWindowLabel } = useAppSelector((state) => state.window);
  const [lastClickTime, setLastClickTime] = useState<number | null>(null);
  const { midSliceIdx: sliceidx, savingSelectedVessel, selectedVesselViewerData } = useVesselStateSelector();
  const [showAnnos, setShowAnnos] = useState(false);

  useContrastWindowLevelsInputs(contrastWindowLevels);

  // If we were waiting for the view to resize so we could take a screenshot we can now take the screenshot.
  useEffect(() => {
    if (pendingScreenshot && pendingScreenshot.viewIndex === viewIndex && pendingScreenshot.onTakeScreenshotCallback) {
      const screenshotTimerId = delay(() => {
        // Handle the MPR screenshot.
        const element = document.querySelector(`#contrast-mpr-${viewIndex}`);
        const canvas = element && element.querySelector('canvas');
        if (canvas && pendingScreenshot.onTakeScreenshotCallback) {
          pendingScreenshot.onTakeScreenshotCallback(canvas.toDataURL());
        }
      }, 50);

      return () => {
        clearTimeout(screenshotTimerId);
      };
    }
    return () => {};
  }, [pendingScreenshot, viewIndex]);

  const overlayToShow = useContrastOverlaySelector(viewIndex);

  /**
   * Respond to a double click event on the window.
   * @param button The index of the button that was double clicked.
   * @param viewIndex The index of the view that was double clicked on.
   */
  const onDoubleClick = (button: number, viewIndex: number) => {
    // Switch to / from fullscreen on a left button double click.
    if (button === 1) {
      onDoubleClickView(viewIndex);
    }
  };

  // We handle onDoubleClick ourselves as the default version got confussed when both
  // mouse buttons were used for a drag event (to change the zoom) and fired a double click event.
  const onClick = () => {
    // Check if this is a double click.
    const time = new Date().getTime();
    if (lastClickTime !== null && time - lastClickTime < 400) {
      // Reset the click button and time.
      setLastClickTime(null);
      // Fire the onDoubleClick callback.
      onDoubleClick(1, viewIndex);
    }
    // Otherwise rememeber the click button and time.
    else {
      setLastClickTime(time);
    }
  };

  const onWindowLevelsChange = (windowLevels: WindowLevels) => {
    // Set the window levels for the WebGLViewer views.

    dispatch(windowAction.setContrastWindowLevels(windowLevels));
  };

  // If the selected vessel is currently saving on the backend we can't fetch its data.
  // TODO: When we nicely cache data this shouldn't be an issue.
  if (savingSelectedVessel) {
    return <Loader />;
  }

  // The CPRViewer is bad at detecting when its size has changed so we have to help it: This triggerResize value
  // will change whenever it needs to be forced to resize.
  const triggerResize = (visibleViews.includes(viewIndex) ? 1 : 0) * visibleViews.length;
  return (
    <>
      <div
        style={{ height: '100%', margin: '0 auto', overflow: 'hidden' }}
        id={`contrast-mpr-${viewIndex}`}
        onClick={onClick}
      >
        <CPRViewer
          triggerResize={triggerResize}
          activeLine={'mid'}
          requireMouseOverForKeyboardInput
          windowLevels={contrastWindowLevels}
          windowLabel={contrastWindowLabel}
          onWindowLevelsChange={onWindowLevelsChange}
          showAnnos={showAnnos}
          setShowAnnos={setShowAnnos}
          triggerResetPanAndZoom={triggerResetPanAndZoom}
          cprVersion={cprVersion}
          cprVesselData={selectedVesselViewerData?.cprVesselData}
          centerlineEdit={{ editing: false }}
        />
      </div>
      <MetaDetails
        isCprView
        overlayToShow={overlayToShow}
        slice={sliceidx}
        windowLevels={contrastWindowLevels}
        windowLabel={contrastWindowLabel}
      />
    </>
  );
};
