import { isEqual } from 'lodash';
import Moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { getScanDate } from '../../utils/shared';
import Card from '../../components/Card/Card';

import { ImpressionField } from '../../components/ImpressionField/ImpressionField';
import { ClinicalIndicationField } from '../../components/ClinicalIndicationField/ClinicalIndicationField';
import { KeyVesselFindings } from '../../components/KeyVesselFindings/KeyVesselFindings';
import { Loader } from '../../components/Loader/Loader';
import { OtherFindings } from '../../components/OtherFindings/OtherFindings';
import PageTitle from '../../components/PageTitle/PageTitle';
import { PatientDetails } from '../../components/PatientDetails/PatientDetails';
import { ReportStatus } from '../../components/ReportStatus/ReportStatus';
import { Screenshots } from '../../components/Screenshots/Screenshots';
import showToast from '../../components/Toast/showToast';
import { BASE_KEY_3D_MODEL, NAV_TABS, VesselGroupings } from '../../config';
import { VesselToReportNameMapping } from '../../reducers/inlineReporting/inlineReportingSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useDeleteScreenshot } from '../../hooks/use-delete-screenshot';
import useStudyTabVisibility from '../../hooks/use-study-tab-visibility';
import { useFetchReviewUsers } from '../../hooks/use-workflow-helpers';
import { useSaveCallBack } from '../../hooks/useSaveCallBack';
import {
  useClinicalIndications,
  useCoronaryFindingsSelector,
  useDraftClinicalIndications,
  useDraftCoronaryFindingsSelector,
  useDraftHeartDominanceSelector,
  useDraftImpressionsSelector,
  useDraftProcedureDetails,
  useExtraCardiacFindings,
  useHeartDominanceSelector,
  useImpressionIsAiAssessedSelector,
  useImpressionsSelector,
  useProcedureDetails,
  useStudyStats,
} from '../../selectors/reporting';
import { currentStudySelector } from '../../selectors/study';
import * as api from '../../utils/api';
import { reportActions } from '../../reducers/report/reportSlice';
import { reportStateActions } from '../../reducers/ReportState/ReportStateSlice';
import { globalFlagsActions } from '../../reducers/global-flags/globalFlagsSlice';
import { storeActions } from '../../reducers/store/storeSlice';
import { PriorCardiacProceduresField } from '../../components/PriorCardiacProceduresField/PriorCardiacProceduresField';
import { UseFetchStudyById } from '../../hooks/UsefetchStudy';
import { studyActions } from '../../reducers/study/studySlice';
import SummaryField from '../../components/SummaryField/SummaryField';
import ProcedureDetailsCard from '../../components/ProcedureDetails/ProcedureDetails';
import { ReportCommandBar } from '../../components/CommandBar/PageCommandBars/ReportCommandBar';
import { NavBar } from '../../components/NavBar/NavBar';

const NON_CORONARY_FINDINGS_QUESTION =
  'Any significant non-coronary abnormality identified within the scanned field of view?';
const NON_CORONARY_FINDINGS_DEFAULT_ANSWER =
  'No significant non-coronary abnormality was identified within the scanned field of view';
const EXTRA_CARDIAC_FINDINGS_QUESTION =
  'Any significant extra-cardiac abnormality identified within the scanned field of view?';
const EXTRA_CARDIAC_FINDINGS_DEFAULT_ANSWER =
  'No significant extra-cardiac abnormality was identified within the scanned field of view';

const Report: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const studyData = useAppSelector((state) => state.study.studyData);
  const currentReport = useAppSelector((state) => state.report.current);
  const postingReport = useAppSelector((state) => state.report.postingReport);
  const editingReport = useAppSelector((state) => state.report.editing);
  const fetchingReport = useAppSelector((state) => state.report.fetching);
  const modelImage = useAppSelector((state) => state.store.modelImage);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const patientID = useAppSelector((state) => state.patient.patientID);
  const patientStats = useAppSelector((state) => state.patient.patientStats);
  const draftReport = useAppSelector((state) => state.report.draft);
  const { screenshots = [], decryptedStudy, deletingScreenshot } = useAppSelector((state) => state.reportState);

  const selectedStudy = useAppSelector(currentStudySelector);
  const editingImpressions = useAppSelector((state) => state.globalFlags.editingImpressions);
  const editingCoronaryFindings = useAppSelector((state) => state.globalFlags.editingCoronaryFindings);

  const updateDraftImpression = useCallback(
    (value: string) => {
      dispatch(
        reportActions.updateDraftReport({
          impression: value.trim().length === 0 ? [] : value.split('\n'),
        })
      );
    },
    [dispatch]
  );

  const updateDraftPriorCardiacProcedures = useCallback(
    (value: string) => {
      dispatch(
        reportActions.updateDraftReport({
          prior_cardiac_procedures: value,
        })
      );
    },
    [dispatch]
  );

  const updateDraftClinicalIndication = useCallback(
    (value: string) => {
      dispatch(reportActions.updateDraftReport({ clinical_indication: value }));
    },
    [dispatch]
  );

  const procedureDetails = useProcedureDetails();
  const draftProcedureDetails = useDraftProcedureDetails();

  const extraCardiacFindings = useExtraCardiacFindings();

  const { getStyles } = useStudyTabVisibility(NAV_TABS.reportReviewTab);

  const [canApproveReport, setCanApproveReport] = useState(true);

  const coronaryFindings = useCoronaryFindingsSelector();
  const heartDominance = useHeartDominanceSelector();
  const [pdfData, setPdfData] = useState<any | null>(null);

  const [approving, setApproving] = useState(false);
  const [generating, setGenerating] = useState(false);

  const impressionAiAssessed = useImpressionIsAiAssessedSelector();
  const impressions = useImpressionsSelector();
  const clinicalIndication = useClinicalIndications();
  const draftClinicalIndication = useDraftClinicalIndications();
  const draftImpressions = useDraftImpressionsSelector();
  const draftCoronaryFindings = useDraftCoronaryFindingsSelector();
  const draftHeartDominance = useDraftHeartDominanceSelector();
  const studyStats = useStudyStats();
  // Highlight summary and impression area when one of the findings has been edited and is in focus
  const [highlightKeyVesselRelatedFields, setHighlightKeyVesselRelatedFields] = useState(false);

  const gender = useMemo(() => {
    switch (studyData?.patient_data.sex) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      case 'O':
        return 'Other';
      default:
        return 'Unknown';
    }
  }, [studyData?.patient_data.sex]);

  const referringDoctor = useMemo(() => {
    if (draftReport?.referring_doctor && draftReport.referring_doctor.length > 0) {
      return draftReport.referring_doctor;
    }

    if (currentReport?.referring_doctor && currentReport.referring_doctor.length > 0) {
      return currentReport.referring_doctor;
    }

    return selectedStudy?.referring_doctor;
  }, [currentReport?.referring_doctor, draftReport?.referring_doctor, selectedStudy?.referring_doctor]);

  const fetchReviewUsers = useFetchReviewUsers();

  useEffect(() => {
    fetchReviewUsers();
  }, [fetchReviewUsers]);

  useEffect(() => {
    const screenshotsUrls =
      screenshots &&
      screenshots
        .filter((s: any) => !s.title.includes(BASE_KEY_3D_MODEL))
        .map(({ title, path }: any) => {
          return {
            path: path,
            title: title,
          };
        });
    setPdfData({
      patient_name: decryptedStudy ? decryptedStudy['patient_name'] : '',
      patient_id: decryptedStudy ? decryptedStudy['patient_id'] : '',
      referring_physician: referringDoctor,
      birth_date: decryptedStudy ? decryptedStudy['date_of_birth'] : '',
      gender,
      heart_dominance: heartDominance.heart_dominance,
      requested_procedure: 'CT Coronary Angiogram',
      calcium_score: currentReport?.calcium_score ?? 'N/A',
      maximum_stenosis: currentReport?.maximum_stenosis ?? '',
      vulnerable_plaque: currentReport?.vulnerable_plaque ?? '',
      cad_rads: currentReport?.cad_rads ?? '',
      impressions,
      vessel_model_image: modelImage,
      coronary_findings: coronaryFindings,
      other_findings: currentReport?.extra_cardiac_findings ?? EXTRA_CARDIAC_FINDINGS_DEFAULT_ANSWER,
      screenshots: screenshotsUrls,
    });
  }, [
    coronaryFindings,
    decryptedStudy,
    impressions,
    modelImage,
    currentReport?.extra_cardiac_findings,
    currentReport?.cad_rads,
    currentReport?.calcium_score,
    currentReport?.maximum_stenosis,
    currentReport?.referring_doctor,
    currentReport?.vulnerable_plaque,
    heartDominance.heart_dominance,
    screenshots,
    studyStats,
    patientStats,
    gender,
    referringDoctor,
  ]);

  const handleUpdateKeyFindings = useCallback(
    (newValue: string, vessel: VesselGroupings) => {
      const reportNaming = VesselToReportNameMapping[vessel];
      dispatch(
        reportActions.updateDraftReport({
          [reportNaming]: newValue,
        })
      );
    },
    [dispatch]
  );
  const handleUpdateHeartDominance = useCallback(
    (value: string | null) => {
      dispatch(
        reportActions.updateDraftReport({
          heart_dominance: value ? value.replace(' dominant', '') : value,
        })
      );
    },
    [dispatch]
  );
  const handleEditReport = useCallback(() => {
    dispatch(reportActions.setEditing(true));
    dispatch(reportActions.updateDraftReport({ ...currentReport }));
  }, [dispatch, currentReport]);

  const handleRevertImpressions = useCallback(() => {
    dispatch(reportActions.deleteDraftReport());
  }, [dispatch]);

  const cancelReportEditing = useCallback(() => {
    dispatch(reportActions.setEditing(false));
    if (editingCoronaryFindings) {
      dispatch(globalFlagsActions.setEditingCoronaryFindings(false));
    }
    if (editingImpressions) {
      dispatch(globalFlagsActions.setEditingImpressions(false));
    }
    dispatch(reportActions.deleteDraftReport());
  }, [dispatch, editingCoronaryFindings, editingImpressions]);

  const hasChanges = useMemo(() => {
    if (draftReport === undefined) return false;
    return !isEqual(draftReport, currentReport);
  }, [currentReport, draftReport]);

  const handleCancelReport = useCallback(() => {
    if (hasChanges) {
      dispatch(
        reportStateActions.setEditingModalDetails({
          showModal: true,
          navigationAction: cancelReportEditing,
        })
      );
    } else {
      cancelReportEditing();
    }
  }, [hasChanges, dispatch, cancelReportEditing]);

  const handleSaveReport = useSaveCallBack();

  const handleDeleteScreenshot = useDeleteScreenshot();

  // Generate report PDF
  const handleGenerateREport = async () => {
    setGenerating(true);
    try {
      const date = Moment().format('DDMMyyyyHHmmss');
      const fileName = patientID + '-report-' + date + '.pdf';
      const pdfSuccess = await api.generatePDF(`/data/${patientID}/${runID}/report/generate`, pdfData, true, fileName);

      if (!pdfSuccess) {
        showToast.error('Failed to generate report');
      } else {
        showToast.success('Report generated successfully');
      }
      setGenerating(false);
    } catch (err) {
      setGenerating(false);
      showToast.error('Failed to generate report');
    }
  };

  // Approving report and generating PDF
  const handleApprove = useCallback(async () => {
    if (!runID || !id) return;

    setApproving(true);
    try {
      const response = await api.postJSON(`/data/${patientID}/${runID}/report/approve`, {});
      if (decryptedStudy && response && response.success) {
        const promises = Promise.all([
          UseFetchStudyById(id),
          api.fetchReportHistory(decryptedStudy.study_id, runID),
          api.fetchReviewList(decryptedStudy.study_id, runID),
        ]);
        const [study, history, reviewList] = await promises;
        dispatch(studyActions.setCurrentStudy(study));
        dispatch(reportActions.setReportHistory(history));
        dispatch(storeActions.setReviewList(reviewList));

        dispatch(reportStateActions.setApproved(true));

        showToast.success(`Analysis approved for patient '${patientID}'`);
      } else {
        showToast.error('Failed to approve analysis');
      }
      setApproving(false);
    } catch (err) {
      setApproving(false);
      showToast.error('Failed to approve analysis');
    }
  }, [id, patientID, runID, dispatch, decryptedStudy]);

  return (
    <>
      {postingReport && <Loader fullScreen large text="Saving Report" />}
      <PageTitle title={`Report for ${id}`} />
      <div
        className={cn('page-report', {
          'page-report--edit-mode': editingReport,
        })}
        id="page-review-report"
        style={getStyles()}
      >
        <div className="topbar">
          <NavBar />
          <ReportCommandBar />
        </div>
        <div className="page-report__container">
          <div className="panel panel--left page-report__column page-report__column--first-column">
            <div className="page-report__patient-details">
              <PatientDetails
                id={selectedStudy?.patient_id ?? patientID}
                name={selectedStudy?.patient_name ?? patientID}
                birthDate={selectedStudy?.date_of_birth}
                scanDate={getScanDate(selectedStudy)}
                doctor={referringDoctor}
                age={studyData?.patient_data.age}
                gender={gender}
                editMode={editingReport}
              />
            </div>
          </div>

          <div className="panel panel--transparent page-report__column page-report__column--second-column">
            <div className="page-report__second-column-panel">
              <h1 className="page-report__heading">Cardiac CT Angiogram</h1>
              <ClinicalIndicationField
                editingReport={editingReport}
                text={[clinicalIndication]}
                updateDraftText={updateDraftClinicalIndication}
                draftText={draftClinicalIndication}
                autoFocus={true}
              />
              {procedureDetails && (
                <PriorCardiacProceduresField
                  editingReport={editingReport}
                  text={[procedureDetails.prior_cardiac_procedures ?? '']}
                  updateDraftText={updateDraftPriorCardiacProcedures}
                  draftText={draftProcedureDetails?.prior_cardiac_procedures}
                />
              )}
              <ImpressionField
                editingReport={editingReport}
                highlightKeyVesselRelatedFields={highlightKeyVesselRelatedFields}
                text={extraCardiacFindings.length > 0 ? [...impressions, extraCardiacFindings] : impressions}
                updateDraftText={updateDraftImpression}
                draftText={draftImpressions}
                statusVersion={!impressionAiAssessed}
                onRevertFindings={handleRevertImpressions}
              />
              <SummaryField
                editingReport={editingReport}
                highlightKeyVesselRelatedFields={highlightKeyVesselRelatedFields}
              />
            </div>

            <div className="page-report__second-column-panel">
              <h1 className="page-report__heading">Key Coronary Findings</h1>
              <KeyVesselFindings
                draftHeartDominance={draftHeartDominance}
                heartDominance={heartDominance}
                onUpdateHeartDominance={handleUpdateHeartDominance}
                coronaryFindings={coronaryFindings}
                draftCoronaryFindings={draftCoronaryFindings}
                editFindings={editingReport}
                onUpdateKeyFindings={handleUpdateKeyFindings}
                onActiveDirtyField={setHighlightKeyVesselRelatedFields}
              />
            </div>

            <div className="page-report__second-column-panel">
              <h1 className="page-report__heading">Other Cardiac Findings</h1>
              <OtherFindings
                heading="Extra-Cardiac Findings"
                question={EXTRA_CARDIAC_FINDINGS_QUESTION}
                defaultAnswer={EXTRA_CARDIAC_FINDINGS_DEFAULT_ANSWER}
                answer={currentReport?.extra_cardiac_findings ?? ''}
                draftAnswer={draftReport?.extra_cardiac_findings ?? ''}
                onAnswered={setCanApproveReport}
                onUpdateOtherFindings={(value: string) => {
                  dispatch(reportActions.updateDraftReport({ extra_cardiac_findings: value }));
                }}
                editMode={editingReport}
              />
              <OtherFindings
                heading="Non-Coronary Cardiac Findings"
                question={NON_CORONARY_FINDINGS_QUESTION}
                defaultAnswer={NON_CORONARY_FINDINGS_DEFAULT_ANSWER}
                answer={currentReport?.non_coronary_findings ?? ''}
                draftAnswer={draftReport?.non_coronary_findings ?? ''}
                onAnswered={setCanApproveReport}
                onUpdateOtherFindings={(value: string) => {
                  dispatch(reportActions.updateDraftReport({ non_coronary_findings: value }));
                }}
                editMode={editingReport}
              />
            </div>
            <div className="page-report__second-column-panel page-report__procedure-details">
              <h1 className="page-report__heading">Procedure Details</h1>
              {procedureDetails && (
                <ProcedureDetailsCard
                  editMode={editingReport}
                  procedureDetails={procedureDetails}
                  draftProcedureDetails={draftProcedureDetails}
                />
              )}
            </div>
          </div>

          <div className="panel panel--right page-report__column page-report__column--third-column">
            <Card>
              <ReportStatus
                canApprove={canApproveReport}
                editMode={editingReport}
                onEditReport={handleEditReport}
                onCancelEdit={handleCancelReport}
                onSaveFindings={handleSaveReport}
                onApproving={handleApprove}
                onGenerate={handleGenerateREport}
                isApproving={approving}
                isGenerating={generating}
              />
            </Card>

            <div className="page-report__screenshots">
              <h1 className="page-report__heading">Screenshots</h1>
              <Screenshots screenshots={screenshots} onDelete={handleDeleteScreenshot} deleting={deletingScreenshot} />
            </div>
          </div>
        </div>
        {fetchingReport && !postingReport && <Loader large background text="Fetching report" />}
      </div>
    </>
  );
};

export default Report;
