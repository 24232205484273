import { ObjectArray } from '../../types/common';
import * as PIXI from 'pixi.js-legacy';
import { XYCoords } from '../../reducers/vesselData/types';
export type ImageDataObj = ObjectArray<ArrayBuffer>;

export interface ImageSize {
  width: number;
  height: number;
}

export interface OnReadyInf {
  sliceNo: number;
  app: PIXI.Application;
  container: PIXI.Container;
  imageSize: ImageSize;
  defaultScale: number;
  holder: HTMLDivElement;
  getContainerMousePos: (holderPoint?: XYCoords) => XYCoords;
  mouseIsOverStage: (x: Event) => XYCoords;
  sliceContainer: PIXI.Container;
}

export interface ZoomInf {
  containerX: number;
  containerY: number;
  mouseX: number;
  mouseY: number;
  scale: number;
}

export interface Dataset {
  // The image dimensions [X, Y].
  shape: [number, number];
  // The raw buffer of image values packed into a 1D array row by row.
  value: number[];
}

export function isWebGLRenderer(renderer: PIXI.Renderer | PIXI.AbstractRenderer): renderer is PIXI.Renderer {
  const webGLContextId = (renderer as PIXI.Renderer).CONTEXT_UID;
  if (webGLContextId == null) {
    return false;
  }

  return true;
}

export enum RESIZE_MODE {
  // Resize the image so it fills the entire view, clipping the image if the aspect ratio doesn't match.
  COVER = 'cover',
  // Fit the image inside the view, leaving empty space if the aspect ratio doesn't match.
  FIT = 'fit',
  // Resize the image to fill the entire height of the view, clipping the image sides or leaving blank space as required.
  FIT_VERTICAL = 'fit-vertical',
}
