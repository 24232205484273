import { useMemo } from 'react';
import { useAppSelector } from '../hooks';
import { ELLIPSE_STATE, Measurement, RULER_STATE } from '../utils/measurementTools/types';

export function useMeasurementTools(viewId: string): Measurement[] {
  const measurements = useAppSelector((state) => state.measurementTools.measurements)[viewId];
  return useMemo(() => {
    return measurements || [];
  }, [measurements]);
}

export function useActiveMeasurement(viewId: string | undefined): Measurement | undefined {
  const measurements = useAppSelector((state) => state.measurementTools.measurements);
  return viewId
    ? measurements[viewId]?.find(
        (measurement: Measurement) =>
          measurement.viewId === viewId &&
          measurement.state !== RULER_STATE.Inactive &&
          measurement.state !== ELLIPSE_STATE.Inactive &&
          measurement.state !== RULER_STATE.Active &&
          measurement.state !== ELLIPSE_STATE.Active
      )
    : undefined;
}

export function useSelectedMeasurementId(viewId: string): string | undefined {
  return useMeasurementTools(viewId).find(
    (measurement: Measurement) =>
      measurement.viewId === viewId &&
      measurement.state !== RULER_STATE.Inactive &&
      measurement.state !== ELLIPSE_STATE.Inactive
  )?.measurementId;
}
