import React from 'react';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

interface TextareaProps {
  name?: string;
  className?: string;
  maxRows?: number;
  minRows?: number;
  autoFocus?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  labelText?: string;
}

export const Textarea: React.FunctionComponent<TextareaProps> = ({
  name,
  className,
  maxRows,
  minRows,
  autoFocus = false,
  value,
  onChange,
  onFocus,
  onBlur,
  readonly = false,
  disabled = false,
  placeholder = 'Type here...',
  labelText,
}) => {
  return (
    <div className="textarea">
      {labelText && (
        <label
          className={cn('textarea__label', {
            'textarea__label--disabled': disabled,
          })}
          htmlFor={name}
        >
          {labelText}
        </label>
      )}
      <TextareaAutosize
        id={name}
        className={className}
        maxRows={maxRows}
        minRows={minRows}
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readonly}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};
