import { ContrastViewType } from '../../context/contrast-types';
import { DraggableCustomType } from '../../reducers/dragAndDrop/types';

export function getViewContrastType(customType: DraggableCustomType): ContrastViewType | undefined {
  switch (customType) {
    case DraggableCustomType.AXIAL:
      return ContrastViewType.Axial;
    case DraggableCustomType.SAGITTAL:
      return ContrastViewType.Sagittal;
    case DraggableCustomType.CORONAL:
      return ContrastViewType.Coronal;
    case DraggableCustomType.MPR:
      return ContrastViewType.MPR;
    default:
      return undefined;
  }
}

export function getNamedTypeFromContrastType(contrastViewType: ContrastViewType): string | undefined {
  switch (contrastViewType) {
    case ContrastViewType.Axial:
      return 'Axial';
    case ContrastViewType.Sagittal:
      return 'Sagittal';
    case ContrastViewType.Coronal:
      return 'Coronal';
    case ContrastViewType.MPR:
      return 'MPR';
    case ContrastViewType.Empty:
      return 'Empty';
    default:
      return undefined;
  }
}
