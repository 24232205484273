import cn from 'classnames';
import uniq from 'lodash/uniq';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Vec2 } from 'three';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { CalciumContextMenu } from '../../components/CalciumContextMenu/CalciumContextMenu';
import { CTVolumeNonContrastViewer } from '../../components/CTVolumeNonContrastViewer/CTVolumeNonContrastViewer';
import { getViewContrastType } from '../../components/DropZone/helpers';

import { Loader } from '../../components/Loader/Loader';
import ViewConfig from '../../components/ViewConfig';
import { MAJOR_VESSELS, NAV_TABS } from '../../config';
import { EMPTY_VIEW_ARRAY } from '../../context/contrast-constants';
import { ContrastViewContent, ContrastViewType, CTVolumeOverlay } from '../../context/contrast-types';
import {
  DraggableGroup,
  DraggableHighlight,
  DraggableItem,
  DraggableType,
  DraggableCustomType,
} from '../../reducers/dragAndDrop/types';
import { isContrastSeries, isNonContrastSeries } from '../../reducers/dragAndDrop/helpers';
import { AIAssessed } from '../../context/types';
import { WindowLevels } from '../../reducers/window/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useOnDraggedContrastSeries } from '../../hooks/useContrastViewHelpers';
import { Bound, contrastActions } from '../../reducers/contrast/contrastSlice';
import useMousePositionRef from '../../hooks/use-mouse-position-ref';
import useStudyTabVisibility from '../../hooks/use-study-tab-visibility';
import { globalFlagsActions } from '../../reducers/global-flags/globalFlagsSlice';
import { windowAction } from '../../reducers/window/windowSlice';
import { currentStudySelector, SeriesStatus, useStudySeriesInfo } from '../../selectors/study';
import { useVesselStateSelector } from '../../selectors/vessels';
import ContrastViewer from './ContrastViewer/ContrastViewer';
import { thumbnail } from './default-thumbnail.js';
import MetaDetails from './MetaDetails/MetaDetails';
import { dragAndDropActions } from '../../reducers/dragAndDrop/dragAndDropSlice';
import { calciumScoreActions } from '../../reducers/calciumScore/calciumScoreSlice';
import { useChangeCalciumCategoryCallback } from './use-callbacks';
import { viewConfigActions } from '../../reducers/viewConfig/viewConfigSlice';
import ListOfDraggableItems from '../../components/ListOfDraggableItems/ListOfDraggableItems';
import { CTVolumeCommandBar } from '../../components/CommandBar/PageCommandBars/CTVolumeCommandBar';
import { NavBar } from '../../components/NavBar/NavBar';

interface SeriesInfo {
  type: DraggableCustomType;
  id: string;
  title: string;
  thumbnail?: string;
}

function createDraggableGroups(ai_assessed: AIAssessed | undefined, seriesList: SeriesInfo[], showMPRView: boolean) {
  return seriesList.map(
    ({ type, title, thumbnail, id }): DraggableGroup => {
      // Show if this study was AI assessed or not.
      let extraInfo = undefined;
      if (ai_assessed) {
        if (id === ai_assessed.contrast_id || id === ai_assessed.noncontrast_id) {
          extraInfo = 'AI ASSESSED';
        }
      }

      // MPR is only required for the first ai_assessed.contrast_id series
      // Don't show it for the extra contrast series
      // Also discard it if analysis failed (which would set `showMPRView` to false)
      const mprView: DraggableItem[] =
        ai_assessed && id === ai_assessed.contrast_id && showMPRView
          ? [
              {
                title: 'MPR',
                type: DraggableType.CHILD,
                id,
                customType: DraggableCustomType.MPR,
                extraInfo,
                isDragging: false,
                thumbnail: DraggableCustomType.MPR,
              },
            ]
          : [];

      return {
        parent: {
          title,
          type: DraggableType.PARENT,
          id,
          customType: type,
          extraInfo,
          isDragging: false,
        },
        children:
          type !== DraggableCustomType.NON_CONTRAST
            ? [
                {
                  title: 'Axial',
                  type: DraggableType.CHILD,
                  id,
                  customType: DraggableCustomType.AXIAL,
                  extraInfo,
                  isDragging: false,
                  thumbnail: { url: thumbnail ?? '' },
                },
                {
                  title: 'Sagittal',
                  type: DraggableType.CHILD,
                  id,
                  customType: DraggableCustomType.SAGITTAL,
                  extraInfo,
                  isDragging: false,
                  thumbnail: DraggableCustomType.SAGITTAL,
                },
                {
                  title: 'Coronal',
                  type: DraggableType.CHILD,
                  id,
                  customType: DraggableCustomType.CORONAL,
                  extraInfo,
                  isDragging: false,
                  thumbnail: DraggableCustomType.CORONAL,
                },
                ...mprView,
              ]
            : [],
      };
    }
  );
}

export default function CTVolumeWrapper(): ReactElement {
  return <CTVolume />;
}

function CTVolume(): ReactElement {
  const dispatch = useAppDispatch();
  const selectedStudy = useAppSelector(currentStudySelector);
  const ctVolume = useAppSelector((state) => state.ctVolume.ctVolume);
  const visibleTab = useAppSelector((state) => state.globalFlags.visibleTab);
  const { seriesId } = useAppSelector((state) => state.viewConfig);
  const initialDataLoaded = useAppSelector((state) => state.globalFlags.initialDataLoaded);

  const nonContrastSlice = useAppSelector((state) => state.globalFlags.nonContrastSlice);

  const calciumScoreData = useAppSelector((state) => state.calciumScore.calciumScoreData);

  const { ctNonContrastViewerData } = useVesselStateSelector();
  const [chosenVessel, setChosenVessel] = useState<string | null>(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const [showNonContrastViewer, setShowNonContrastViewer] = useState(false);
  const [showContrastViewer, setShowContrastViewer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updatedLesions, setUpdatedLesions] = useState<any[]>([]);
  const [fullLesionData, setFullLesionData] = useState<any[]>([]);
  const [selectedLesions, setSelectedLesions] = useState<any[]>([]);
  const [nonContrastViewOverlay, setNonContrastViewOverlay] = useState<CTVolumeOverlay>(CTVolumeOverlay.INFO);
  const [showCalciumClear, setShowCalciumClear] = useState(false);
  const [vesselToClear, setVesselToClear] = useState<string>('');
  const [disableCalciumConfirm, setDisableCalciumConfirm] = useState<boolean>(false);
  const { nonContrastWindowLevels, nonContrastWindowLabel } = useAppSelector((state) => state.window);
  const scanModalRef = useRef<HTMLDivElement>(null);
  const screenshotRef = useRef<any | undefined>();
  const ctHURef = useRef<any | undefined>();
  const windowWidthRef = useRef<any | undefined>();
  const windowLevelRef = useRef<any | undefined>();
  const deselectLesionsRef = useRef<any | null>(null);
  const { getStyles } = useStudyTabVisibility(NAV_TABS.ctVolumeTab);

  // TODO: switch out defaut thumbnail with series thumbnail when available
  const thumbnailDefault = thumbnail.data;

  const [seriesList, setSeriesList] = useState<any[]>([]);
  const [seriesScreenshot, setSeriesScreenshot] = useState(null);

  const [calciumContextMenuPosition, setCalciumContextMenuPosition] = useState<Vec2 | null>(null);

  const [showCalciumContextSubMenu, setShowCalciumContextSubMenu] = useState(false);

  const hideCalciumContextMenu = useCallback(() => {
    setCalciumContextMenuPosition(null);

    setShowCalciumContextSubMenu(false);
  }, [setCalciumContextMenuPosition, setShowCalciumContextSubMenu]);

  const [vesselCalciumVisible, setVesselCalciumVisible] = useState<{
    [key: string]: boolean;
  }>();
  const calciumVisible = useRef<{ [key: string]: boolean }>();

  useEffect(() => {
    if (initialLoad) setInitialLoad(true);
  }, [initialLoad, setInitialLoad]);

  useEffect(() => {
    if (seriesList.length > 0) {
      setLoading(false);
    }
  }, [seriesList]);

  const dispatchNonContrastSlice = (value: number) => {
    dispatch(globalFlagsActions.setNonContrastSlice(value));
  };

  useEffect(() => {
    dispatch(calciumScoreActions.setCalciumScoreData(calciumScoreData ? calciumScoreData : null));
    // Setup the vessel calcium score toggles
    if (!vesselCalciumVisible && calciumScoreData) {
      const keyVisible: { [key: string]: boolean } = {};
      Object.keys(calciumScoreData).forEach((key) => {
        if (key === 'total') {
          keyVisible['other'] = true;
        } else {
          keyVisible[key] = true;
        }
      });
      keyVisible['all'] = true;
      setVesselCalciumVisible(keyVisible);
      calciumVisible.current = keyVisible;
    }
  }, [dispatch, calciumScoreData, vesselCalciumVisible]);

  useEffect(() => {
    if (selectedStudy?.series != null && ctVolume?.content != null) {
      setSeriesList(
        Object.entries(selectedStudy.series)
          .map(([label, value]: any[]) => {
            // Try to get the path to the thumbnail from the ctVolume data.
            const thumbnailPath = ctVolume.content.find((seriesData) => seriesData.key === label)
              ?.presigned_thumbnail_s3_path;
            return {
              value: label,
              label: value.series_description,
              thickness: value.thickness ? `${value.thickness}mm` : '',
              studyDate: value.date,
              windowLevels:
                value.ww && value.wl
                  ? {
                      windowWidth: parseFloat(value.ww),
                      windowCenter: parseFloat(value.wl),
                    }
                  : undefined,
              thumbnail: thumbnailPath ?? thumbnailDefault,
            };
          })
          .sort((a, b) => {
            if (
              a.value === selectedStudy.ai_assessed.contrast_id ||
              a.value === selectedStudy.ai_assessed.noncontrast_id
            )
              return -1;
            return 1;
          })
      );
    }
  }, [selectedStudy, ctVolume, thumbnailDefault]);

  const onViewerReady = ({ deselectLesions }: any) => {
    setLoading(false);
    deselectLesionsRef.current = deselectLesions;
  };

  const updateVesselToggle = (toggle: { [key: string]: boolean }) => {
    if (!vesselCalciumVisible) return;
    if (Object.keys(toggle)[0] === 'all') {
      // Toggling all calcium vessels
      const newVisible: { [key: string]: boolean } = {};
      Object.keys(vesselCalciumVisible).forEach((key) => {
        newVisible[key] = toggle['all'];
      });
      calciumVisible.current = { ...newVisible, ...toggle };
    } else {
      const allToggle: { [key: string]: boolean } = {};
      if (!Object.values(toggle)[0]) {
        // if selecting any vessel off, set the show all to be off also
        allToggle['all'] = false;
      } else {
        // toggling on so check if all the other are on and check the 'show all' if so
        let allOn = true;
        Object.entries(vesselCalciumVisible).forEach(([key, val]) => {
          if (key !== Object.keys(toggle)[0] && key !== 'all' && !val) {
            allOn = false;
          }
        });
        allToggle['all'] = allOn;
      }
      calciumVisible.current = {
        ...vesselCalciumVisible,
        ...toggle,
        ...allToggle,
      };
    }
    setVesselCalciumVisible(calciumVisible.current);
  };

  const onTakeScreenshot = () => {
    setSeriesScreenshot(null);
  };

  const onCloseScreenshot = () => {
    setSeriesScreenshot(null);
  };

  const onLesionEnter = useCallback(
    (lesion: any, event: React.MouseEvent) => {
      if (calciumVisible.current && calciumVisible.current[lesion.category]) {
        setSelectedLesions([lesion]);
        setCalciumContextMenuPosition({
          x: event.clientX,
          y: event.clientY,
        });
      }
    },
    [setSelectedLesions, setCalciumContextMenuPosition]
  );

  const onLesionLeave = useCallback(() => {
    hideCalciumContextMenu();
  }, [hideCalciumContextMenu]);

  const mousePositionRef = useMousePositionRef();

  const onLesionLasso = (lesions: any, event: Event) => {
    const visibleVessels: string[] = [];
    // Check if the lesion vessels are visible (i.e. toggled on or off)
    if (calciumVisible.current) {
      Object.entries(calciumVisible.current).forEach(([key, value]) => {
        if (calciumVisible.current && value === true && key !== 'all') {
          visibleVessels.push(key);
        }
      });
    }
    const filteredLesions = lesions.filter((l: any) => visibleVessels.includes(l.category));
    setSelectedLesions(filteredLesions);

    const xPos = mousePositionRef.current.x;
    const yPos = mousePositionRef.current.y;
    lesions.length &&
      xPos !== null &&
      yPos !== null &&
      setCalciumContextMenuPosition({
        x: xPos,
        y: yPos,
      });
  };

  const onChangeCalciumCategory = useChangeCalciumCategoryCallback(
    selectedLesions,
    setUpdatedLesions,
    deselectLesionsRef,
    () => hideCalciumContextMenu(),
    setChosenVessel
  );

  const clearVesselCalcium = (vessel: string) => {
    if (fullLesionData.length <= 0) return;
    // Gether list of lesion data for the selected vessel to clear
    const selected: React.SetStateAction<any[]> = [];
    Object.values(fullLesionData).forEach((l: any[]) => {
      l.forEach((l: any) => {
        if (l.category === vessel) {
          selected.push(l);
        }
      });
    });
    setSelectedLesions(selected);
    setShowCalciumClear(true); // Show confirmation dialog
    setVesselToClear(vessel);
  };

  const confirmClearVessel = () => {
    setDisableCalciumConfirm(true);
    // Clearing the lesions within the vessel (i.e. setting to 'other')
    onChangeCalciumCategory({
      old: vesselToClear,
      new: 'other',
    }).then(() => {
      // Hide the confirmation modal
      setShowCalciumClear(false);
      setDisableCalciumConfirm(false);
    });
  };

  const getSelectedVesselType = () => {
    if (!selectedLesions.length) {
      return 'Other';
    } else {
      const cats = uniq(selectedLesions.map((l) => l.category));
      if (cats.length > 1) {
        return 'Multiple';
      } else {
        return cats[0] !== 'other' ? cats[0].toUpperCase() : 'Other';
      }
    }
  };

  const draggableGroup = useAppSelector((state) => state.dragAndDrop.draggableGroup);

  const volumeViewerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (volumeViewerRef.current) {
      const bound: Bound = {
        left: volumeViewerRef.current?.getBoundingClientRect().left,
        top: volumeViewerRef.current?.getBoundingClientRect().top,
        right: volumeViewerRef.current?.getBoundingClientRect().right,
        bottom: volumeViewerRef.current?.getBoundingClientRect().bottom,
      };
      dispatch(contrastActions.setVolumeViewer(bound));
    }
  }, [dispatch, volumeViewerRef]);

  const { contrastViews, visibleViews } = useAppSelector((state) => state.contrast);
  const onDraggedContrastSeries = useOnDraggedContrastSeries();

  const studySeriesInfo = useStudySeriesInfo();
  const showMprView = useMemo(() => {
    return studySeriesInfo?.contrast !== SeriesStatus.Fail && studySeriesInfo?.contrast !== SeriesStatus.NotAvailable;
  }, [studySeriesInfo]);

  useEffect(() => {
    if (visibleTab === NAV_TABS.ctVolumeTab) {
      const seriesInfos: SeriesInfo[] = seriesList.map((x: any) => ({
        id: x.value,
        type: isNonContrastSeries(x.value) ? DraggableCustomType.NON_CONTRAST : DraggableCustomType.CONTRAST,
        title: x.label,
        thumbnail: x.thumbnail,
      }));

      const draggableGroups = createDraggableGroups(selectedStudy?.ai_assessed, seriesInfos, showMprView);
      if (Object.keys(draggableGroup).length === 0 && draggableGroups.length > 0 && selectedStudy) {
        dispatch(
          dragAndDropActions.createDraggableGroup({
            draggableGroups,
            ai_assessed: selectedStudy.ai_assessed,
          })
        );

        dispatch(viewConfigActions.setSeriesId(draggableGroups[0].parent.id));

        if (draggableGroups[0].parent.customType !== DraggableCustomType.NON_CONTRAST && selectedStudy) {
          const newConfig: ContrastViewContent[] = draggableGroups[0].children.map((x) => {
            const viewtype = getViewContrastType(x.customType);
            const config: ContrastViewContent = {
              seriesName: draggableGroups[0].parent.id,
              viewType: viewtype ?? ContrastViewType.Empty,
            };
            return config;
          });

          // In case there are missing views, make 4 empty views, add after the actual number of views then limit to 4.
          const contrastViews = [...newConfig, ...EMPTY_VIEW_ARRAY].slice(0, 4);
          onDraggedContrastSeries(contrastViews);
        }
      }
    }
  }, [visibleTab, seriesList, dispatch, draggableGroup, selectedStudy, showMprView, onDraggedContrastSeries]);

  const keysToGroupsItemsToHighlight: string[] = useMemo(() => {
    if (seriesId !== undefined && isNonContrastSeries(seriesId)) {
      return [seriesId];
    } else {
      const allNonEmptyViews = contrastViews.filter((x) => x.viewType !== ContrastViewType.Empty);

      if (seriesId && allNonEmptyViews.every((x) => x.seriesName === seriesId)) {
        return [seriesId];
      } else {
        return [];
      }
    }
  }, [contrastViews, seriesId]);

  const keysAndTypesToChildItemsToHighlight: DraggableHighlight[] = useMemo(() => {
    if (isNonContrastSeries(seriesId)) {
      return [];
    } else {
      return contrastViews.map((contrastView: ContrastViewContent, viewIndex: number) => {
        return {
          key: contrastView.seriesName!,
          type: contrastView.viewType,
          visible: visibleViews.includes(viewIndex),
        };
      });
    }
  }, [contrastViews, visibleViews, seriesId]);

  // Decide if we should now be showing the NonContrast and Contrast viewers (ie have them mounted).
  // They are initially unmounted but should stay mounted for a patient after they are first shown.
  useEffect(() => {
    if (visibleTab === NAV_TABS.ctVolumeTab) {
      if (isContrastSeries(seriesId)) {
        setShowContrastViewer(true);
      } else if (isNonContrastSeries(seriesId)) {
        setShowNonContrastViewer(true);
      }
    }
  }, [visibleTab, seriesId, setShowNonContrastViewer, setShowContrastViewer]);

  return (
    <div className="page-ct-volume" id="page-ct-volume" style={getStyles()}>
      <div className="topbar">
        <NavBar />
        <CTVolumeCommandBar />
      </div>
      <div className="page-ct-volume__container">
        <div className="panel panel--left page-ct-volume__column">
          <ViewConfig />
          <div className="draggable-list">
            <ListOfDraggableItems
              keysToGroupsItemsToHighlight={keysToGroupsItemsToHighlight}
              keysAndTypesToChildItemsToHighlight={keysAndTypesToChildItemsToHighlight}
            />
          </div>
        </div>

        <div className="volume-viewer" ref={volumeViewerRef}>
          {loading && !initialDataLoaded && visibleTab === NAV_TABS.ctVolumeTab ? (
            <Loader text={`Loading CT Volume`} large fullScreen />
          ) : (
            <div className="ct-volume">
              <div
                className="ct-volume__view-wrap"
                ref={scanModalRef}
                style={{
                  maxWidth: '100%',
                }}
              >
                <div className="scanModal__content">
                  <div className="ct-volume__view-wrap" ref={scanModalRef}>
                    {showContrastViewer && (
                      <div
                        id={`view_contrast`}
                        key={`view_contrast`}
                        className={cn('ct-volume__view', {
                          'ct-volume__view--show': !seriesScreenshot && isContrastSeries(seriesId),
                        })}
                      >
                        <ContrastViewer />
                      </div>
                    )}
                    {showNonContrastViewer && (
                      <div
                        id={`view_noncontrast`}
                        key={`view_noncontrast`}
                        ref={screenshotRef}
                        className={cn('ct-volume__view', {
                          'ct-volume__view--show': !seriesScreenshot && isNonContrastSeries(seriesId),
                        })}
                      >
                        <>
                          <div className="ct-volume__non-contrast-meta-details">
                            <MetaDetails
                              windowLevels={nonContrastWindowLevels}
                              windowLabel={nonContrastWindowLabel}
                              studyDetails={selectedStudy}
                              calciumScoreData={calciumScoreData}
                              HURef={ctHURef}
                              windowWidthRef={windowWidthRef}
                              windowLevelRef={windowLevelRef}
                              slice={nonContrastSlice}
                              overlayToShow={nonContrastViewOverlay}
                              vesselCalciumVisible={calciumVisible.current}
                              onClearCalciumScore={clearVesselCalcium}
                              onToggleVesselCalcium={updateVesselToggle}
                            />
                          </div>
                          {calciumContextMenuPosition && (
                            <CalciumContextMenu
                              onChange={onChangeCalciumCategory}
                              vessels={MAJOR_VESSELS}
                              selectedVessel={getSelectedVesselType()}
                              setChosenVessel={setChosenVessel}
                              chosenVessel={chosenVessel}
                              menuPosition={calciumContextMenuPosition}
                              showCalciumContextSubMenu={showCalciumContextSubMenu}
                              setShowCalciumContextSubMenu={setShowCalciumContextSubMenu}
                            />
                          )}
                          <CTVolumeNonContrastViewer
                            onHueChange={(hue: any) => ctHURef.current && ctHURef.current.setValue(hue)}
                            onWindowLevelsChange={(wl: WindowLevels) => {
                              windowWidthRef.current && windowWidthRef.current.setValue(wl.windowWidth);
                              windowLevelRef.current && windowLevelRef.current.setValue(wl.windowCenter);
                              dispatch(windowAction.setNonContrastWindowLevels(wl));
                            }}
                            slice={nonContrastSlice}
                            onSliceChange={dispatchNonContrastSlice}
                            onLesionEnter={onLesionEnter}
                            onLesionLeave={onLesionLeave}
                            onLesionLasso={onLesionLasso}
                            onReady={onViewerReady}
                            updatedLesions={updatedLesions}
                            onUpdateLesions={() => setUpdatedLesions([])}
                            onZoom={() => hideCalciumContextMenu()}
                            onDrag={() => hideCalciumContextMenu()}
                            onDraw={() => hideCalciumContextMenu()}
                            label={seriesList.find((series) => series.value === seriesId)?.label}
                            nonContrastViewOverlay={nonContrastViewOverlay}
                            setNonContrastViewOverlay={setNonContrastViewOverlay}
                            screenshotRef={screenshotRef}
                            onTakeScreenshot={onTakeScreenshot}
                            onCloseScreenshot={onCloseScreenshot}
                            onSetFullLesionData={setFullLesionData}
                            vesselCalciumVisible={calciumVisible.current}
                            viewerData={ctNonContrastViewerData}
                          />
                          <ActionModal
                            confirmText={'Delete'}
                            onClose={() => setShowCalciumClear(false)}
                            onConfirm={confirmClearVessel}
                            visible={showCalciumClear}
                            headerContent={<>Delete</>}
                            disabled={disableCalciumConfirm}
                          >
                            <p>Are you sure you want to remove all {vesselToClear.toUpperCase()} lesions?</p>
                          </ActionModal>
                          {loading && <Loader />}
                        </>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
