import cn from 'classnames';
import React from 'react';

interface SelectProps {
  id?: string;
  topLabel?: string | undefined;
  leftLabel?: string | undefined;
  rightLabel?: string | undefined;
  small?: boolean;
  type?: string;
  placeholder?: string;
  value?: string | number;
  min?: number;
  max?: number;
  toolbar?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
}
const parseKey = (key: string = '') => {
  return key.replace(/ /g, '_').toLocaleLowerCase();
};

export const TextInput: React.FunctionComponent<SelectProps> = ({
  id,
  topLabel,
  leftLabel,
  rightLabel,
  small = false,
  value = '',
  type = 'text',
  placeholder = '',
  max,
  min,
  toolbar = false,
  disabled = false,
  onChange,
  onBlur,
  className = '',
}) => {
  // generate random id
  const randomId = Math.random().toString(36).substring(7);
  // parse label
  const parsedID = parseKey(id ?? randomId);
  return (
    <>
      <div className="text-input">
        {topLabel && (
          <label
            htmlFor={parsedID}
            className={cn('text-input__top-label', {
              'text-input__top-label--disabled': disabled,
            })}
          >
            {topLabel}
          </label>
        )}
        <div className={cn(`text-input__control ${className}`)}>
          {leftLabel && (
            <label
              htmlFor={parsedID}
              className={cn('text-input__left-label', {
                'text-input__left-label--disabled': disabled,
              })}
            >
              {leftLabel}
            </label>
          )}
          <input
            id={parsedID}
            disabled={disabled}
            className={cn('text-input__input', {
              'text-input__input--small': rightLabel || small,
              'text-input__input--toolbar': toolbar,
            })}
            type={type}
            min={min}
            max={max}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          {rightLabel && <abbr className="text-input__right-label value-list__label">{rightLabel}</abbr>}
        </div>
      </div>
    </>
  );
};
