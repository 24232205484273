import { useEffect } from 'react';
import { PRESET_MAP } from '../config';
import { WindowLevels } from '../reducers/window/types';
import { useAppDispatch } from '../hooks';
import { windowAction } from '../reducers/window/windowSlice';

export const useContrastWindowLevelsInputs = (contrastWindowLevels: WindowLevels) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const keypressCallback = (event: KeyboardEvent) => {
      // Check if the user focused on a text input field, if so do not set window levels with PRESETS
      if (
        event.target instanceof HTMLTextAreaElement ||
        event.target instanceof HTMLSelectElement ||
        (event.target as HTMLInputElement).type === 'text' ||
        (event.target as HTMLInputElement).type === 'number'
      )
        return;

      const newLevel = PRESET_MAP[event.code]?.window || null;
      if (newLevel !== null) {
        dispatch(windowAction.setContrastWindowLevels(newLevel));
      }
    };
    document.addEventListener('keypress', keypressCallback);
    return () => document.removeEventListener('keypress', keypressCallback);
  }, [contrastWindowLevels, dispatch]);
};
