import cn from 'classnames';
import React, { useMemo } from 'react';

interface Props {
  // If a bullet point per new line is desirable, pass in an array of strings
  // for each line. If a single block/paragraph is desirable, pass in an array with
  // all text in a single element.
  text: string[];
  inline?: boolean;
}

export const TextBlock: React.FunctionComponent<Props> = ({ text, inline = false }) => {
  const textList = useMemo(() => {
    return (
      text &&
      text
        .filter((t) => t.length > 0)
        .map((i, key) => {
          return <li key={`impression__${key}`}>{i}</li>;
        })
    );
  }, [text]);

  return (
    <div className={cn('textblock', { 'no-padding': inline })}>
      <div className="textblock__description">
        {textList.length > 1 ? <ul className="textblock__list">{textList}</ul> : <span>{text[0] ?? ''}</span>}
      </div>
    </div>
  );
};
