import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { useContrastContext } from '../../../context/contrast-context';
import { ContrastVolumeActions, ContrastVolumeStatus } from '../../../context/contrast-types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { contrastActions } from '../../../reducers/contrast/contrastSlice';
import { currentStudySelector } from '../../../selectors/study';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { centerViewOnCrosshairs } from './Utils';

/**
 * This provides the link between the MPR and the loaded contrast views for syncing the sliceidx when
 * it is adjusted on the MPR and also for loading the MPR centerline when the selected vessel is changed.
 */
export function useMPRLink() {
  const dispatch = useAppDispatch();
  const selectedStudy = useAppSelector(currentStudySelector);
  const { vesselSync, vesselSyncInfo } = useAppSelector((state) => state.contrast);
  const { dispatchContrastVolumeAction, contrastVolumeMap } = useContrastContext();

  const { midSliceIdx: sliceidx, vesselData, selectedVesselName } = useVesselStateSelector();

  // Centerlines are only ever loaded for the ai_assessed.contrast_id series.
  const seriesName = selectedStudy?.ai_assessed?.contrast_id || 'contrast';
  // Get the ai_assessed.contrast_id ContrastVolume for the selectedStudy.
  const contrastVolume = selectedStudy ? contrastVolumeMap.get(seriesName) : undefined;

  /**
   * Respond to changes in the MPR slice index and the selected vessel (ie, move the associated volume
   * crosshairs and refresh all views associated with that volume). Only do this if the vesselSync is
   * currently turned on.
   *
   * Initial CT volume load = no sync
   * Change vessel = no sync
   * Turn vesselSync on = Snap to current vessel and slice
   * Change vessel slice (when vesselSync is on) = Snap to current vessel and slice
   */
  useEffect(() => {
    if (
      selectedVesselName &&
      contrastVolume &&
      contrastVolume.volume &&
      contrastVolume.status === ContrastVolumeStatus.LOADED &&
      vesselData?.[selectedVesselName]
    ) {
      const centerline = vesselData?.[selectedVesselName]?.centerline;
      if (centerline && sliceidx >= 0 && sliceidx < centerline.length) {
        // Only sync the views to the desired study + vessel + slice index these were changed from
        // the last values the views were synchronized with.
        const desiredVesselSyncInfo = {
          vesselName: selectedVesselName,
          // If vesselSync is currently off we should clear the desired sliceIndex because Artrya want it to pop to the
          // current vessel if vesselSync is turned back on.
          sliceIndex: vesselSync ? sliceidx : -1,
        };

        if (vesselSync && !isEqual(vesselSyncInfo, desiredVesselSyncInfo)) {
          // Only actually center the contrast views on the vessel position if the slice index was
          // changed on the currently synchronized study and vessel or vesselSync was just turned on.
          if (
            vesselSyncInfo?.vesselName === desiredVesselSyncInfo.vesselName &&
            vesselSyncInfo?.sliceIndex !== desiredVesselSyncInfo.sliceIndex
          ) {
            // Get the world position of the vessel at this slice index.
            const point = centerline[sliceidx];
            // Update the crosshairs position to the vessel position.
            contrastVolume.crosshairWorldPosition[0] = point[0];
            contrastVolume.crosshairWorldPosition[1] = point[1];
            contrastVolume.crosshairWorldPosition[2] = point[2];

            // Move the camera to be centered on the crosshairs position and refresh the view.
            for (let i = 0; i < 4; i++) {
              const api = contrastVolume.getApi(i);
              if (api) {
                centerViewOnCrosshairs(api, contrastVolume);
              }
            }

            // Update the crosshair values (huValue and crosshair position) for the volume.
            dispatchContrastVolumeAction({
              type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES_FOR_VOLUME,
              contrastVolume,
            });
          }
        }

        // Remember what we synchronized with.
        dispatch(contrastActions.setVesselSyncInfo(desiredVesselSyncInfo));
      }
    }
  }, [vesselSync, contrastVolume, sliceidx, selectedVesselName, vesselData]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This simple effect clears the vesselSyncInfo if we clean up the contrastVolume (normally because we have changed
   * the entire series to another contrast series without the MPR etc).
   */
  useEffect(() => {
    if (vesselSync && !contrastVolume) {
      dispatch(contrastActions.setVesselSyncInfo(undefined));
    }
  }, [vesselSync, contrastVolume]); // eslint-disable-line react-hooks/exhaustive-deps
}
