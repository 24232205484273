import Auth from '@aws-amplify/auth';
import * as auth from '../../../utils/auth';
import React, { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ASSET_STORAGE,
  USER_MANUAL_FILENAME,
  FFR_MANUAL_FILENAME,
  MEASUREMENT_MANUAL_FILENAME,
  APP_VERSION_NUMBER,
} from '../../../config';
import { Icon } from '../../../objects/Icon/Icon';
import { DropdownMenu } from '../../reusable/DropdownMenu/DropdownMenu';
import { DropdownMenuItem } from '../../reusable/DropdownMenu/DropdownMenuItem';
import { useDashboardSelector } from '../../../dashboardHooks';
import { useIsArtryaUserSelector } from '../../../selectors/user';

export interface AdminMenuProps {
  menuToggle?: ReactNode;
  menuToggleText?: string;
  position?: 'left' | 'right' | 'up' | 'down';
}

export const AdminMenu: React.FunctionComponent<AdminMenuProps> = ({
  menuToggle = <Icon icon="account" type="large" />,
  menuToggleText,
  position,
}) => {
  const navigate = useNavigate();
  const { clientConfig } = useDashboardSelector((state) => state.user);
  const versionList = useDashboardSelector((state) => state.releaseVersion.content);
  const UDIVersion = useDashboardSelector((state) => state.releaseVersion.udiVersion);
  const releaseVersion = useDashboardSelector((state) => state.releaseVersion.releaseVersion);
  const isArtryaUser = useIsArtryaUserSelector();

  const ACCOUNT_OPTIONS = {
    settings: 'settings',
    auditlog: 'auditlog',
    userManual: 'usermanual',
    ffrManual: 'ffrmanual',
    measurementManual: 'measurementmanual',
    signout: 'signout',
    disabled: 'disabled',
  };

  const handleAccountChange = useCallback(
    async (value) => {
      if (value === ACCOUNT_OPTIONS.userManual) window.open(`${ASSET_STORAGE}/${USER_MANUAL_FILENAME}`, '_blank');
      if (value === ACCOUNT_OPTIONS.ffrManual) window.open(`${ASSET_STORAGE}/${FFR_MANUAL_FILENAME}`, '_blank');
      if (value === ACCOUNT_OPTIONS.measurementManual)
        window.open(`${ASSET_STORAGE}/${MEASUREMENT_MANUAL_FILENAME}`, '_blank');
      if (value === ACCOUNT_OPTIONS.signout) {
        await Auth.signOut();
        auth.clearTokens();
        auth.clearNewLoginTokens();
        navigate('/');
      }
    },
    [
      navigate,
      ACCOUNT_OPTIONS.userManual,
      ACCOUNT_OPTIONS.ffrManual,
      ACCOUNT_OPTIONS.measurementManual,
      ACCOUNT_OPTIONS.signout,
    ]
  );

  return (
    <DropdownMenu
      id="nav-bar-admin-menu"
      menuToggle={menuToggle}
      menuToggleText={menuToggleText}
      title="Admin Menu"
      toggleClassName="nav-bar__button"
      position={position}
      children={
        <>
          <DropdownMenuItem
            id="nav-bar-user-manual"
            text="User Manual"
            title="User Manual"
            onClick={() => handleAccountChange(ACCOUNT_OPTIONS.userManual)}
          />
          {clientConfig?.ffr_enabled && (
            <DropdownMenuItem
              id="nav-bar-ffr-manual"
              text="FFR Manual"
              title="FFR Manual"
              onClick={() => handleAccountChange(ACCOUNT_OPTIONS.ffrManual)}
            />
          )}
          {clientConfig?.measurement_enabled && (
            <DropdownMenuItem
              id="nav-bar-measurement-manual"
              text="Measurement Manual"
              title="Measurement Manual"
              onClick={() => handleAccountChange(ACCOUNT_OPTIONS.measurementManual)}
            />
          )}
          <DropdownMenuItem
            id="nav-bar-sign-out"
            text="Sign Out"
            title="Sign Out"
            onClick={() => handleAccountChange(ACCOUNT_OPTIONS.signout)}
          />
        </>
      }
      footer={
        <>
          {releaseVersion !== null ? (
            <div>Salix version {releaseVersion}</div>
          ) : (
            <div>Salix version {APP_VERSION_NUMBER}</div>
          )}
          {isArtryaUser && versionList !== null ? (
            <>
              <div>Web app version {APP_VERSION_NUMBER}</div>
              {versionList !== null &&
                Object.entries(versionList).map(([key, serviceVersionList]) => {
                  return (
                    <div>
                      <span className="version-name"> {serviceVersionList.serviceName}</span>{' '}
                      {serviceVersionList.serviceVersion}
                    </div>
                  );
                })}
            </>
          ) : (
            UDIVersion !== null && <div>UDI {UDIVersion}</div>
          )}
          <div>Copyright &copy; {new Date().getFullYear()} Artrya</div>
        </>
      }
    />
  );
};
