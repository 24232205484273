import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DatasetItem, ReviewUser } from '../../context/types';

interface screenshot {
  filename: string;
  path: string;
  thumbnail: string;
  title: string;
  url: string;
}

export interface ReportState {
  valid: boolean;
  summary: string;
  screenshots: screenshot[];
  deletingScreenshot: string | undefined;
  notes: string;
  decryptedStudy: DatasetItem | undefined;
  approved: boolean;
  showEmailForm: boolean;
  emailAddresses: string;
  fetchingMesaPercentile: boolean;
  reviewUsers: ReviewUser[] | undefined;
  amendmentModalPassthrough: { passthrough?: (reason: string) => void };
  editingModalDetails: { showModal: boolean; navigationAction: () => void } | undefined;
}

export const createDefaultReportStatetSliceState = (): ReportState => {
  return {
    valid: false,
    summary: '',
    screenshots: [],
    deletingScreenshot: undefined,
    notes: '',
    decryptedStudy: undefined,
    approved: false,
    showEmailForm: false,
    emailAddresses: '',
    fetchingMesaPercentile: false,
    reviewUsers: undefined,
    amendmentModalPassthrough: {},
    editingModalDetails: undefined,
  };
};

export const reportStateSlice = createSlice({
  name: 'ReportState',
  initialState: createDefaultReportStatetSliceState(),
  reducers: {
    setValid: (state, action: PayloadAction<boolean>) => {
      state.valid = action.payload;
    },
    setSummary: (state, action: PayloadAction<string>) => {
      state.summary = action.payload;
    },
    setScreenshots: (state, action: PayloadAction<any[]>) => {
      state.screenshots = action.payload;
    },
    setDeletingScreenshot: (state, action: PayloadAction<string | undefined>) => {
      state.deletingScreenshot = action.payload;
    },
    setNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload;
    },
    setDecryptedStudy: (state, action: PayloadAction<any | undefined>) => {
      state.decryptedStudy = action.payload;
    },
    setApproved: (state, action: PayloadAction<boolean>) => {
      state.approved = action.payload;
    },
    setShowEmailForm: (state, action: PayloadAction<boolean>) => {
      state.showEmailForm = action.payload;
    },
    setEmailAddresses: (state, action: PayloadAction<string>) => {
      state.emailAddresses = action.payload;
    },
    setFetchingMesaPercentile: (state, action: PayloadAction<boolean>) => {
      state.fetchingMesaPercentile = action.payload;
    },
    setReviewUsers: (state, action: PayloadAction<ReviewUser[] | undefined>) => {
      state.reviewUsers = action.payload;
    },
    setAmendmentModalPassthrough: (state, action: PayloadAction<{ passthrough?: (reason: string) => void }>) => {
      state.amendmentModalPassthrough = action.payload;
    },
    setEditingModalDetails: (
      state,
      action: PayloadAction<{ showModal: boolean; navigationAction: () => void } | undefined>
    ) => {
      state.editingModalDetails = action.payload;
    },
    clearReportState: (state) => {
      state.valid = false;
      state.summary = '';
      state.screenshots = [];
      state.notes = '';
      state.showEmailForm = false;
      state.emailAddresses = '';
    },
  },
});

export const reportStateActions = reportStateSlice.actions;
