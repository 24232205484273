import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import * as api from '../../utils/api';
import { loadWithPromise, useNeedsLoading, getReqProperties } from '../initialLoad/utils';
import { storeActions } from './storeSlice';

const loaderName = InitialLoader.STORE;

const fetchReviewList = (unifiedId: UnifiedId) => {
  const promises: Promise<any>[] = [api.fetchReviewList(unifiedId.patientId, unifiedId.runId)];
  return Promise.all(promises);
};

const fetchNonContrastSpacing = (unifiedId: UnifiedId) => {
  const reqProperties = getReqProperties(unifiedId);
  return api.getJSON(
    `/data/${unifiedId.patientId}/${unifiedId.runId}/volume-non-contrast/spacing?version=${unifiedId.version}`,
    false,
    reqProperties
  );
};

/**
 * Load the store data from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function loadStore(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  const promise = Promise.all([fetchReviewList(unifiedId), fetchNonContrastSpacing(unifiedId)]);
  loadWithPromise(promise, dispatch, initialLoad, loaderName, ([reviewListList, nonContrastSpacing]) => {
    const [reviewList] = reviewListList;
    dispatch(storeActions.setReviewList(reviewList));
    if (nonContrastSpacing?.length === 3) {
      dispatch(
        storeActions.setNonContrastSpacing({
          z: nonContrastSpacing[0],
          y: nonContrastSpacing[1],
          x: nonContrastSpacing[2],
        })
      );
    }
  });
}

/**
 * A component to load the initial store data for a study and add it to the store.
 */
export const StoreLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useNeedsLoading(loaderName);

  useEffect(() => {
    if (unifiedId && needsLoading) {
      loadStore(unifiedId, dispatch, true);
    }
  }, [unifiedId, needsLoading, dispatch]);

  return null;
};
