const style = {
  container: () => ({}),
  valueContainer: () => ({}),
  menu: (provided: any) => {
    return { ...provided, zIndex: 2, borderRadius: 45, marginBottom: 5, marginTop: 5, boxShadow: 'none' };
  },
  menuList: () => ({}),
  indicatorSeparator: () => ({}),
  control: () => ({}),
  singleValue: () => ({}),
  dropdownIndicator: () => ({}),
  option: (styles: any, { data }: any) => {
    // stenosis select Style
    if (data.stenosisColor) {
      return {
        cursor: 'pointer',
        '::before': {
          content: '" "',
          backgroundColor: data.stenosisColor,
          height: 8,
          width: 8,
          borderRadius: 50,
          marginRight: 8,
        },
      };
    }
    return {
      cursor: 'pointer',
    };
  },
};

export default style;
