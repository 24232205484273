import { useMemo } from 'react';
import { createSelector } from 'reselect';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';

export enum SeriesStatus {
  // Series was not ingested
  NotAvailable,
  // Series analysis was successful
  Pass,
  // Series analysis failed
  Fail,
}

export interface StudySeriesInfo {
  contrast: SeriesStatus;
  noncontrast: SeriesStatus;
}

/**
 * Get the status of the contrast and non-contrast series of the selected study. A series can be NotAvailable (meaning
 * it was never ingested), Pass (meaning analysis was successful) or Fail (meaning analysis failed).
 */
export function useStudySeriesInfo(): StudySeriesInfo | undefined {
  const selectedStudy = useAppSelector(currentStudySelector);

  return useMemo(() => {
    if (selectedStudy === undefined) {
      return undefined;
    }

    const status: StudySeriesInfo = {
      contrast: SeriesStatus.NotAvailable,
      noncontrast: SeriesStatus.NotAvailable,
    };

    const contrastSeries = selectedStudy.series[selectedStudy.ai_assessed.contrast_id];
    if (contrastSeries !== undefined) {
      if (contrastSeries.processing_status.code === 'Ready') {
        status.contrast = SeriesStatus.Pass;
      } else if (contrastSeries.processing_status.code === 'Analysis Failed') {
        status.contrast = SeriesStatus.Fail;
      }
    }

    const noncontrastSeries = selectedStudy.series[selectedStudy.ai_assessed.noncontrast_id];
    if (noncontrastSeries !== undefined) {
      if (noncontrastSeries.processing_status.code === 'Ready') {
        status.noncontrast = SeriesStatus.Pass;
      } else if (noncontrastSeries.processing_status.code === 'Analysis Failed') {
        status.noncontrast = SeriesStatus.Fail;
      }
    }

    return status;
  }, [selectedStudy]);
}

export const studySelector = createSelector(
  (state: RootState) => state.study,
  (study) => study
);

export const currentStudySelector = createSelector([studySelector], (study) => study.currentStudy);
export const runIdSelector = createSelector(
  (state: RootState) => state.study,
  (study) => study.currentStudy?.active_run
);

/**
 * Get the version of the CPR data to load for the current study (based on its analysis_version).
 * CPR uses version 2 if the analysis version is 2 or greater and 1 otherwise.
 */
export const cprVersionSelector = createSelector([currentStudySelector], (currentStudy) => {
  if (currentStudy?.analysis_version !== undefined) {
    // The analysis_version is expected to be in the form of "0.2.0".
    const analysisVersion = currentStudy.analysis_version.split('.');
    const analysisVersionNumber = parseInt(analysisVersion[1]);
    return analysisVersionNumber >= 2 ? 2 : 1;
  }
  return 1;
});
