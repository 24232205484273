// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KEY_CPR } from '../../config';

export type cprState = {
  sliceidx: number;
  sliceCount: number;
  selectedMPRView: string;
  triggerResetPanAndZoom: number;
};

export const initialcprState = (): cprState => {
  return {
    sliceidx: 0, // The current CPR slice index for the selected vessel (rotational view index, each rotation having its own image, centerline etc).
    sliceCount: 0, // The total number CPR slices for the selected vessel (rotational views).
    selectedMPRView: KEY_CPR, // Choose which MPR view is shown on the patient overview tab.
    triggerResetPanAndZoom: 0, // used to trigger Cpr viewer reset
  };
};

export const cprSlice = createSlice({
  name: 'cpr',
  initialState: initialcprState(),
  reducers: {
    setCprSliceidx: (state, action: PayloadAction<number>) => {
      state.sliceidx = action.payload;
    },
    setCprSliceCount: (state, action: PayloadAction<number>) => {
      state.sliceCount = action.payload;
    },
    setSelectedMPRView: (state, action: PayloadAction<string>) => {
      state.selectedMPRView = action.payload;
    },
    setTriggerResetPanAndZoom: (state, action: PayloadAction<number>) => {
      state.triggerResetPanAndZoom = action.payload;
    },
  },
});

export const cprActions = cprSlice.actions;
