import React, { ReactElement } from 'react';
import { StatsDataType } from '../../context/types';

interface Props {
  stats: StatsDataType;
  editingReport: boolean;
  handleUpdateReportStats: (stat: string, newValue: string) => void;
}

export function VulnerablePlaque({ stats }: Props): ReactElement<Props> {
  return (
    <div className="value-list__item">
      <dt className="value-list__label">{stats.label}</dt>
      <dd className="value-list__value">{stats.value ? stats.value : '-'}</dd>
    </div>
  );
}
