import { useEffect } from 'react';
import { useDashboardSelector } from '../../dashboardHooks';

export enum DashboardSessionStorage {
  DASH_CURRENT_PAGE = 'dash-current-page',
  DASH_SORT_KEY = 'dash-sort-key',
  DASH_ASCENDING = 'dash-ascending',
  DASH_SEARCH_STRING = 'dash-search-string',
}

/**
 * Persist dashboard values for this session whenever they change.
 */
export const useDashboardSessionStorage = () => {
  const { dashboardCurrentPage, dashboardSortKey, dashboardAscending, dashboardSearchStr } = useDashboardSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    sessionStorage.setItem(DashboardSessionStorage.DASH_CURRENT_PAGE, String(dashboardCurrentPage));
  }, [dashboardCurrentPage]);

  useEffect(() => {
    sessionStorage.setItem(DashboardSessionStorage.DASH_SORT_KEY, dashboardSortKey);
  }, [dashboardSortKey]);

  useEffect(() => {
    sessionStorage.setItem(DashboardSessionStorage.DASH_ASCENDING, String(dashboardAscending));
  }, [dashboardAscending]);

  useEffect(() => {
    sessionStorage.setItem(DashboardSessionStorage.DASH_SEARCH_STRING, dashboardSearchStr);
  }, [dashboardSearchStr]);
};
