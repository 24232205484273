import { DatasetItem, Study } from '../context/types';
import * as phi from '../utils/phi';
export async function decryptDatasetsResponse(
  studies: Record<string, Study>,
  currentStudies: Record<string, Study> | null = null
): Promise<Record<string, Study>> {
  let decryptedStudies: Record<string, Study> = {};

  const decryptPromises: Promise<Study>[] = [];

  for (let studyId in studies) {
    if (currentStudies && currentStudies[studyId] && currentStudies[studyId]['patient_plaintext']) {
      // Apply decrypted data from studies already loaded
      decryptedStudies[studyId] = phi.applyDecryptedData(currentStudies[studyId], studies[studyId]);
    } else {
      decryptPromises.push(phi.decryptDashboardItem(studies[studyId], false));
    }
  }

  const decryptedStudiesPromises = await Promise.all(decryptPromises);
  Object.values(decryptedStudiesPromises).forEach((value) => {
    decryptedStudies[value.study_id] = value;
  });

  return decryptedStudies;
}

/**
 * Converts a map of DatasetItems fetched from the API to Study objects.
 * Mutates the values in the passed map and returns them in a new object.
 * @param response A map of DatasetItem objects to be processed
 * @returns A map of Study objects
 */
export async function processDatasetsResponse(
  response: Record<string, DatasetItem>,
  currentStudies: Record<string, Study> | null = null
): Promise<Record<string, Study>> {
  const studies: Record<string, Study> = {};
  Object.keys(response).forEach((studyId) => {
    const s: Study = {
      ...response[studyId],
      workflow_user: response[studyId].workflow_status.user,
    };
    studies[studyId] = s;
  });

  await phi.checkCredentials();

  for (let studyId in studies) {
    if (currentStudies && currentStudies[studyId] && currentStudies[studyId]['patient_plaintext']) {
      // Apply decrypted data from studies already loaded
      studies[studyId] = phi.applyDecryptedData(currentStudies[studyId], studies[studyId]);
    }
  }

  return studies;
}
