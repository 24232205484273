import React, { useCallback, useMemo } from 'react';
import { useDraftStudyStats, useStudyStats } from '../../selectors/reporting';
import { formatDOB } from '../../utils/shared';
import { EthnicField } from '../SummaryField/EthnicField';

interface PatientDetailsProps {
  id?: string;
  name?: string;
  birthDate?: string;
  scanDate?: string;
  doctor?: string;
  age?: string | number;
  gender?: string;
  editMode: boolean;
}

export const PatientDetails: React.FunctionComponent<PatientDetailsProps> = ({
  id: patientID,
  name: patientName,
  birthDate: patientDOB,
  scanDate,
  doctor: patientDoctor,
  age,
  gender,
  editMode,
}) => {
  const studyStats = useStudyStats();
  const draftStudyStats = useDraftStudyStats();

  const birthDate = useMemo(() => formatDOB(patientDOB || null), [patientDOB]);

  const doctor = useMemo(() => (patientDoctor || editMode ? patientDoctor : '-'), [patientDoctor, editMode]);

  const renderNonEditableRow = useCallback((title: string, value: string | number | undefined) => {
    return (
      <div className="value-list__item">
        <dt className="value-list__label">{title}</dt>
        <dd className="value-list__value">{value}</dd>
      </div>
    );
  }, []);

  const reportObject = useMemo(() => {
    return editMode ? draftStudyStats : studyStats;
  }, [editMode, studyStats, draftStudyStats]);

  return (
    <div className="patient-details">
      <h3 className="patient-details__header">{patientName}</h3>
      <dl className="value-list value-list--large patient-details__value-list">
        {renderNonEditableRow('Patient ID', patientID)}
        {scanDate && renderNonEditableRow('Scan Date', scanDate)}
        {birthDate && renderNonEditableRow('Date of Birth', birthDate)}
        {age && renderNonEditableRow('Age', age)}
        {renderNonEditableRow('Gender', gender)}
        {reportObject?.calcium_score && (
          <EthnicField calciumStat={reportObject.calcium_score} editingReport={editMode} />
        )}
        {renderNonEditableRow('Referring Physician', doctor)}
      </dl>
    </div>
  );
};
