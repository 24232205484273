// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VesselGroupings } from '../../config';

export const VesselToReportNameMapping: {
  [key: string]: 'lad_coronary_findings' | 'lcx_coronary_findings' | 'lm_coronary_findings' | 'rca_coronary_findings';
} = {
  LAD: 'lad_coronary_findings',
  LCX: 'lcx_coronary_findings',
  LM: 'lm_coronary_findings',
  RCA: 'rca_coronary_findings',
};

export enum UnsavedChangesToInlineReport {
  LeavingStudy = 0,
  EditingReport,
  CancelEditingImpressions,
  CancelEditingCoronary,
  ApprovingReport,
  GeneratingReport,
  SendingStudyForApproval,
}

export type inlineReportingState = {
  lm_coronary_findings?: string;
  lad_coronary_findings?: string;
  lcx_coronary_findings?: string;
  rca_coronary_findings?: string;
  impressions?: string[];
  showWarningType?: UnsavedChangesToInlineReport;
  inlineReportingWarningAction?: () => void;
};

export const initialInlineReportingState = (): inlineReportingState => {
  return {};
};

export const inlineReportingSlice = createSlice({
  name: 'inlineReporting',
  initialState: initialInlineReportingState(),
  reducers: {
    updateCoronaryFinding: (draftState, action: PayloadAction<{ vessel: VesselGroupings; finding?: string }>) => {
      const { vessel, finding } = action.payload;
      const mapped = VesselToReportNameMapping[vessel];
      draftState[mapped] = finding;
    },
    clearCoronaryFindings: (draftState) => {
      draftState.lad_coronary_findings = undefined;
      draftState.lcx_coronary_findings = undefined;
      draftState.lm_coronary_findings = undefined;
      draftState.rca_coronary_findings = undefined;
    },
    updateImpressions: (draftState, action: PayloadAction<string[] | undefined>) => {
      draftState.impressions = action.payload;
    },
    clearImpressions: (draftState) => {
      draftState.impressions = undefined;
    },
    setUnsavedChangesWarning: (draftState, action: PayloadAction<UnsavedChangesToInlineReport | undefined>) => {
      draftState.showWarningType = action.payload;
    },
    clearInlineReportingDraftState: () => {
      // With Immer we need to mutate the draftState or return a fully new state, we can't assign a fully new state to the draftState.
      return initialInlineReportingState();
    },
    setInlineReportingWarningAction: (draftState, action: PayloadAction<() => void | undefined>) => {
      draftState.inlineReportingWarningAction = action.payload;
    },
    clearInlineReportingWarningAction: (draftState) => {
      draftState.inlineReportingWarningAction = undefined;
    },
  },
});

export const inlineReportingAction = inlineReportingSlice.actions;
