import { PointObject } from '../../types/common';
import * as PIXI from 'pixi.js-legacy';
import { XYCoords } from '../../reducers/vesselData/types';

export const MM_TO_PX = 0.26458333333333333;

export interface Measurement {
  // The unique identifier for the view this measurement is associated with.
  viewId: string;
  // A unique identifier for this measurement.
  measurementId: string;
  // The type of the measurement.
  type: TOOL_TYPES;
  // The starting point of the ruler or ellipse.
  startPoint: XYCoords;
  // The ending point of the ruler or ellipse.
  endPoint: XYCoords;
  // The slice number the measurement belongs to.
  slice: number;
  // The current measurement state.
  state: RULER_STATE | ELLIPSE_STATE;
  // The length in mm (if a ruler measurement).
  length?: number;
  // The area in mm^2 (if an ellipse measurement).
  area?: number;
  // The HuData (if an ellipse measurement).
  huData?: HuData;
}

export interface MeasurementGraphics extends PIXI.Graphics {
  // A unique identifier for this measurement.
  measurementId: string;
  // The action to perform when this measurement is clicked.
  action: RULER_STATE | ELLIPSE_STATE;
}
// Return the provided object as a MeasurmentGraphics object if it has the required MesaurementGraphics members, otherwise return undefined.
export const asMeasurementGraphics = (value: any): MeasurementGraphics | undefined => {
  if ('measurementId' in value && 'action' in value) return value as MeasurementGraphics;
  return undefined;
};

export enum TOOL_TYPES {
  Ruler = 'ruler',
  Ellipse = 'ellipse',
}

export enum RULER_STATE {
  New = 'new',
  Moving = 'moving',
  HandleStart = 'startPoint',
  HandleEnd = 'endPoint',
  Active = 'active',
  Inactive = 'inactive',
}

export enum ELLIPSE_STATE {
  New = 'ellipseNew',
  Moving = 'ellipseMoving',
  HandleTopLeft = 'ellipseHandleTopLeft',
  HandleTopRight = 'ellipseHandleTopRight',
  HandleBottomLeft = 'ellipseHandleBottomLeft',
  HandleBottomRight = 'ellipseHandleBottomRight',
  Active = 'ellipseActive',
  Inactive = 'ellipseInactive',
}

export interface CreateNewRulerSpriteInterface {
  measurement: Measurement;
  scale: number;
  // Callback for when the mouse is pressed on a ruler or one of its handles (or undefined if the measurement is not currently interactive).
  onMouseDown?: (event: React.MouseEvent) => void;
}

export interface CreateNewEllipseSpriteInterface {
  measurement: Measurement;
  scale: number;
  // Callback for when the mouse is pressed on a elipse or one of its handles (or undefined if the measurement is not currently interactive).
  onMouseDown?: (event: React.MouseEvent) => void;
}

export interface CreateMarkerSpriteInterface {
  type: TOOL_TYPES;
  label: string;
  points: PointObject[];
  bounds: Rectangle;
  scale: number;
  showLine: boolean;
  huData?: HuData;
}

export interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface CreateNewMarkerInterface {
  measurement: Measurement;
  scale: number;
  // The bounding rectangle which the marker should be positioned inside.
  bounds: Rectangle;
}

export interface DrawDashedLineInterface {
  line: PIXI.Graphics;
  start: XYCoords;
  end: XYCoords;
  dashLength: number;
  lineWidth: number;
  alpha?: number;
}

export type HuData = {
  mean?: number;
  stdDev?: number;
};
