import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { vesselDataActions } from './vesselDataSlice';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, useNeedsLoading } from '../initialLoad/utils';
import { globalFlagsActions } from '../global-flags/globalFlagsSlice';
import { fetchViewerShape } from './VesselViewerDataLoader';

const loaderName = InitialLoader.NON_CONTRAST_VIEWER_SHAPE_DATA;

/**
 * Load the vessel viewer data from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function loadNonContrastViewerShapeData(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  // We don't need the VesselData to load the NonContrastShapeData (and we may never have the VesselData in some scenarios - eg if all analysis failed).
  const promise = fetchViewerShape(
    unifiedId.version,
    unifiedId.patientId,
    unifiedId.runId,
    'undefined',
    'ct-noncontrast'
  ).then((shape) => {
    if (shape.length > 0) {
      // Default to showing the last slice; set this before setting the shape so the first image loaded will be the slice we want to show.
      dispatch(globalFlagsActions.setNonContrastSlice(shape.length - 1));
      dispatch(vesselDataActions.updateCTNonContrastShapeData({ shape }));
    } else {
      console.warn('NonContrast shape has zero length');
    }
  });

  // Wait for all the fetches to finish.
  loadWithPromise(promise, dispatch, initialLoad, loaderName, () => {});
}

/**
 * A component to load the initial non contrast viewer shape data for a study and add it to the store.
 */
export const NonContrastViewerShapeLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useNeedsLoading(loaderName);

  useEffect(() => {
    // We need several bits of other data before the vessel viewer data can be loaded.
    if (unifiedId && needsLoading) {
      loadNonContrastViewerShapeData(unifiedId, dispatch, true);
    }
  }, [unifiedId, needsLoading, dispatch]);

  return null;
};
