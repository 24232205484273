// to move contrastVolumeMapReducer will require us to make big changes to VtkContrastView.tsx and how the three contrast views interact.
// one of the current problem I saw in VtkContrastView.tsx is that this component allow to mutate props however once we move
// contrastVolumeMapReducer to redux using a plain js object we’ll need to update it
// There a PR#1543 where the contrastVolumeMapReducer is moved to redux using a plain js object.
import React, { ReactNode, useContext, useReducer } from 'react';
import {
  ContrastContextValue,
  ContrastVolumeOverlayMode,
  ContrastVolume,
  ContrastVolumeMap,
  ContrastVolumeActions,
  ContrastVolumeAction,
  BlendMode,
  ContrastVolumeStatus,
} from './contrast-types';
import { vtkApi } from '../views/CTVolume/ReactVTKJS/ReactVTKJSTypes';
import { getCrosshairValues } from '../views/CTVolume/ContrastViewer/Utils';

// The initial type of slab blend mode to use when rendering a contrast CT volume with a render thickness > 0.0.
const initialSlabBlendMode: BlendMode = BlendMode.MAXIMUM_INTENSITY_BLEND;

/**
 * Create a new ContrastVolume object.
 */
function createContrastVolume(seriesName: string): ContrastVolume {
  const apis: vtkApi[] = [];
  return {
    seriesName,
    status: ContrastVolumeStatus.LOADING,
    volume: undefined,
    apis,
    crosshairValues: {
      crosshairPos: undefined,
      huValue: undefined,
    },
    crosshairWorldPosition: [0, 0, 0],
    crosshairWorldAxes: [
      [0, 0, 1],
      [1, 0, 0],
      [0, 1, 0],
    ],
    viewProps: [
      {
        blendMode: initialSlabBlendMode,
        renderThickness: 0.0,
        overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
      },
      {
        blendMode: initialSlabBlendMode,
        renderThickness: 0.0,
        overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
      },
      {
        blendMode: initialSlabBlendMode,
        renderThickness: 0.0,
        overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
      },
    ],
    getApi: (viewIndex: number) => {
      let result: vtkApi | undefined = undefined;
      apis.forEach((api) => {
        const apiViewIndex = api.getViewIndex();
        if (apiViewIndex === viewIndex) {
          result = api;
        }
      });
      return result;
    },
  };
}

function contrastVolumeMapReducer(state: ContrastVolumeMap, action: ContrastVolumeAction) {
  let contrastVolume: ContrastVolume | undefined = undefined;

  if (
    action.type !== ContrastVolumeActions.RESET_VIEWS &&
    action.type !== ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES &&
    action.type !== ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES_FOR_VOLUME
  ) {
    // Get the contrast volume for the specifed seriesName (or undefined if it doesn't exist).
    contrastVolume = state.get(action.seriesName);
  }

  switch (action.type) {
    case ContrastVolumeActions.LOAD:
      // If the volume is in the map we don't need to do anything.
      // Otherwise we need to initialise a new volume object.
      if (contrastVolume === undefined) {
        const newState = new Map(state);
        newState.set(action.seriesName, createContrastVolume(action.seriesName));
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_STATUS:
      if (contrastVolume && contrastVolume.status !== action.status) {
        const newState = new Map(state);
        newState.set(action.seriesName, {
          ...contrastVolume,
          status: action.status,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_VOLUME_SPACING_AND_WINDOW_LEVELS:
      if (contrastVolume) {
        const newState = new Map(state);
        newState.set(action.seriesName, {
          ...contrastVolume,
          status: ContrastVolumeStatus.LOADED,
          volume: action.volume,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_BLEND_MODE:
      if (contrastVolume && contrastVolume.viewProps[action.viewType].blendMode !== action.blendMode) {
        const newState = new Map(state);
        const newViewProps = contrastVolume.viewProps.slice();
        newViewProps[action.viewType].blendMode = action.blendMode;
        newState.set(action.seriesName, {
          ...contrastVolume,
          viewProps: newViewProps,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_RENDER_THICKNESS:
      if (contrastVolume && contrastVolume.viewProps[action.viewType].renderThickness !== action.renderThickness) {
        const newState = new Map(state);
        const newViewProps = contrastVolume.viewProps.slice();
        newViewProps[action.viewType].renderThickness = action.renderThickness;
        newState.set(action.seriesName, {
          ...contrastVolume,
          viewProps: newViewProps,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_OVERLAY_MODE:
      if (contrastVolume) {
        const newState = new Map(state);
        const newViewProps = contrastVolume.viewProps.slice();
        newViewProps[action.viewType].overlayMode = action.overlayMode;
        newState.set(action.seriesName, {
          ...contrastVolume,
          viewProps: newViewProps,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.RESET_VIEWS: {
      const newState = new Map();
      state.forEach((contrastVolume, key) => {
        // Reset the crosshairs position and orientation.
        if (contrastVolume.apis.length) {
          contrastVolume.apis[0].resetCrosshairs();
        }

        // Reset each api on the contrastVolume.
        contrastVolume.apis.forEach((api) => {
          // Reset the camera position, focal point, viewUp, slab thickness.
          // Reset the istyle.
          // Reset the crosshairWorldPosition.
          // Reset the crosshairWorldAxes.
          api.resetView();
        });

        newState.set(key, {
          ...contrastVolume,
          crosshairValues: getCrosshairValues(contrastVolume),
          viewProps: [
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
          ],
        });
      });
      return newState;
    }

    case ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES: {
      let newState = undefined;
      state.forEach((contrastVolume, key) => {
        const api = contrastVolume.getApi(action.viewIndex);
        if (api) {
          const crosshairValues = getCrosshairValues(contrastVolume);
          // Initialize the newState and set the new crosshairValues.
          newState = new Map(state);
          newState.set(key, {
            ...contrastVolume,
            crosshairValues,
          });
        }
      });
      return newState || state;
    }

    case ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES_FOR_VOLUME: {
      const crosshairValues = getCrosshairValues(action.contrastVolume);
      // Initialize the newState and set the new crosshairValues.
      const newState = new Map(state);
      newState.set(action.contrastVolume.seriesName, {
        ...action.contrastVolume,
        crosshairValues,
      });
      return newState;
    }

    case ContrastVolumeActions.REMOVE:
      if (contrastVolume) {
        const newState = new Map(state);
        newState.delete(action.seriesName);
        return newState;
      }
      break;

    default:
      // throw new Error();
      break;
  }
  // We get here if nothing has changed.
  return state;
}

const ContrastContext = React.createContext<ContrastContextValue | undefined>(undefined);
ContrastContext.displayName = 'ContrastContext';

interface Props {
  children: ReactNode;
}

export const ContrastProvider: React.FunctionComponent<Props> = (props) => {
  const [contrastVolumeMap, dispatchContrastVolumeAction] = useReducer(contrastVolumeMapReducer, new Map());

  const contrastContext: ContrastContextValue = {
    contrastVolumeMap,
    dispatchContrastVolumeAction,
  };

  return <ContrastContext.Provider value={contrastContext}>{props.children}</ContrastContext.Provider>;
};

/**
 * Hook to access the ContrastContext
 * @returns {ContrastContextValue} ContrastContextValue
 */
export function useContrastContext(): ContrastContextValue {
  const context = useContext(ContrastContext);
  if (context === undefined) {
    throw new Error('useContrastContext must be used within a ContrastProvider.');
  }
  return context;
}
