import React from 'react';
import cn from 'classnames';
import EditableTextField from '../../components/EditableTextField/EditableTextField';
import { FindingsStatus } from '../../components/FindingsStatus/FindingsStatus';

interface ImpressionFieldProps {
  editingReport: boolean;
  highlightKeyVesselRelatedFields: boolean;
  text: string[];
  updateDraftText: (report: string) => void;
  draftText: string | undefined;
  autoFocus?: boolean;
  statusVersion?: boolean;
  onRevertFindings?: () => void;
}

export const ImpressionField: React.FunctionComponent<ImpressionFieldProps> = ({
  editingReport,
  highlightKeyVesselRelatedFields,
  text,
  updateDraftText,
  draftText,
  autoFocus = false,
  statusVersion,
  onRevertFindings,
}) => {
  return (
    <div
      className={cn('report-field impressions-field', {
        'report-field--highlighted': highlightKeyVesselRelatedFields,
      })}
    >
      {!editingReport && statusVersion !== undefined && (
        <FindingsStatus versions={statusVersion} onRevertFindings={onRevertFindings} />
      )}
      <div className="report-field__container">
        <h3 className="report-field__heading">Impression</h3>
        <div className="report-field__field">
          <EditableTextField
            editMode={editingReport}
            text={text}
            updateDraftText={updateDraftText}
            draftText={draftText}
            autoFocus={autoFocus}
          />
        </div>
      </div>
    </div>
  );
};
