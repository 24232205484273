import React, { ChangeEvent, useCallback, useState } from 'react';
import Select from '../reusable/SelectNew/SelectNew';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { FINDINGS_LAYOUT, VesselGroupings } from '../../config';
import { CoronaryFindings, heartDominance } from '../../context/types';
import { useCoronaryFindingIsAiAssessedSelector } from '../../selectors/reporting';
import { getMajorVesselGrouping } from '../../utils/vessel';
import KeyVesselFinding from '../KeyVesselFinding/KeyVesselFinding';

const HEART_DOMINANCES = ['right dominant', 'left dominant', 'co dominant'];

interface KeyVesselFindingsProps {
  coronaryFindings: CoronaryFindings | undefined;
  editFindings: boolean;
  draftCoronaryFindings: CoronaryFindings | undefined;
  onUpdateKeyFindings: (newValue: string, vessel: VesselGroupings) => void;
  onUpdateHeartDominance: (newValue: string | null) => void;
  onActiveDirtyField: (isActive: boolean) => void;
  heartDominance?: heartDominance | undefined;
  draftHeartDominance?: heartDominance | undefined;
}

interface ActiveField {
  vessel: string;
  isFocused: boolean;
  isDirty: boolean;
}

export const KeyVesselFindings: React.FunctionComponent<KeyVesselFindingsProps> = ({
  coronaryFindings,
  editFindings,
  onUpdateKeyFindings,
  onActiveDirtyField,
  onUpdateHeartDominance,
  draftCoronaryFindings,
  heartDominance,
  draftHeartDominance,
}) => {
  const [activeField, setActiveField] = useState<ActiveField>({
    vessel: '',
    isFocused: false,
    isDirty: false,
  });

  const coronaryFindingsAiAssessed = useCoronaryFindingIsAiAssessedSelector();

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>, vessel: VesselGroupings) => {
      const initialValue = coronaryFindings?.[vessel];
      const value = event.target.value;
      const isDirty = initialValue !== value;

      setActiveField((prev) => ({ ...prev, isDirty, vessel }));

      onUpdateKeyFindings(value, vessel);

      if (isDirty) {
        onActiveDirtyField(true);
      }
    },
    [coronaryFindings, setActiveField, onUpdateKeyFindings, onActiveDirtyField]
  );
  const handleHeartDominance = useCallback(
    (heartDominance: string | null) => {
      if (heartDominance === '') {
        heartDominance = null;
      }
      onUpdateHeartDominance(heartDominance);
    },
    [onUpdateHeartDominance]
  );
  const handleFocus = useCallback(() => {
    setActiveField((prev) => ({ ...prev, isFocused: true }));
  }, [setActiveField]);

  const handleBlur = useCallback(() => {
    setActiveField({
      vessel: '',
      isDirty: false,
      isFocused: false,
    });
    onActiveDirtyField(false);
  }, [setActiveField, onActiveDirtyField]);

  return (
    <div className="key-findings">
      <div className="key-findings__heart-dominance">
        {heartDominance?.heart_dominance != null && (
          <p className="key-findings__data-name">Coronary system exhibits as</p>
        )}
        {editFindings && heartDominance?.heart_dominance === null && (
          <p className="key-findings__data-name">Coronary system exhibits as</p>
        )}

        {!editFindings && heartDominance?.heart_dominance === null && (
          <p className="key-findings__data-name key-findings__indicate-heart-dominance">
            <span className="icon-section">
              <span className="icon-section-icon">
                <Info />
              </span>
              Indicate heart dominance
            </span>
          </p>
        )}

        {editFindings && (
          <Select
            addPlaceholderAsOption={true}
            options={HEART_DOMINANCES}
            value={
              editFindings
                ? draftHeartDominance?.heart_dominance
                  ? draftHeartDominance?.heart_dominance + ' dominant'
                  : draftHeartDominance?.heart_dominance || ''
                : heartDominance?.heart_dominance || ''
            }
            onChange={handleHeartDominance}
          />
        )}
        {!editFindings && heartDominance?.heart_dominance != null && <p>{heartDominance?.heart_dominance} dominant</p>}
      </div>

      <div className="key-findings__fields">
        {coronaryFindings &&
          FINDINGS_LAYOUT.map((vessel) => {
            const vesselName = getMajorVesselGrouping(vessel);

            if (!vesselName) {
              return undefined;
            }

            return (
              <KeyVesselFinding
                key={`vessel-findings-${vessel}`}
                showVesselName={true}
                vesselGroup={vesselName}
                editMode={editFindings}
                coronaryFindings={coronaryFindings}
                draftCoronaryFindings={draftCoronaryFindings?.[vesselName] ?? ''}
                handleInputChange={handleInputChange}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                activeField={activeField}
                statusVersion={!coronaryFindingsAiAssessed[vesselName]}
              />
            );
          })}
      </div>
    </div>
  );
};
