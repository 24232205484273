import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum LoadState {
  // We haven't started to load this data yet.
  // undefined
  // We have requested this data but not yet acquired it.
  LOADING = 'LOADING',
  // The data is loaded and in the store now.
  LOADED = 'LOADED',
  // We failed to get this data.
  FAILED = 'FAILED',
}

// The endpoint names for the initial loaders.
export enum InitialLoader {
  CURVED_MPR = 'curved-mpr',
  CURRENT_STUDY = 'current-study',
  NEW_STUDY = 'new-study-data',
  STUDY = 'study-data',
  VESSEL_DATA = 'vessel-data',
  VESSEL_VIEWER_DATA = 'vessel-viewer-data',
  NON_CONTRAST_VIEWER_SHAPE_DATA = 'non-contrast-viewer-shape-data',
  STORE = 'store',
  REPORT = 'report',
  REPORT_STATE = 'report-state',
  MODEL = 'model',
  LESION = 'lesions',
  CONTRAST_LESION_DATA = 'contrast-lesion-data',
  CONTRAST = 'contrast',
  CONTRAST_VOLUME = 'contrast-volume',
}
export const initialLoaderArray = Object.values(InitialLoader);

// The combined information required to identify a specific study.
export interface UnifiedId {
  patientId: string;
  runId: string;
  version: string;
}

export interface initialLoadState {
  // The combined information required to identify a specific study.
  unifiedId?: UnifiedId;
  // Information of what state each loader is in (keyed by the loader name).
  loadState: Record<string, LoadState>;
}

const getInitialState = (): initialLoadState => {
  return {
    loadState: {},
  };
};

/**
 * Data in this slice should only be set once and never re-set.
 * Once new data is set we should be able to trigger loading new data that depends on it.
 */
export const initialLoadSlice = createSlice({
  name: 'initialLoad',
  initialState: getInitialState(),
  reducers: {
    setUnifiedID: (state, action: PayloadAction<UnifiedId>) => {
      state.unifiedId = action.payload;
    },
    setLoadState: (state, action: PayloadAction<{ loaderName: InitialLoader; loadState: LoadState }>) => {
      state.loadState[action.payload.loaderName] = action.payload.loadState;
    },
  },
});

export const initialLoadActions = initialLoadSlice.actions;
