import React, { ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Draggable, { DraggableBounds } from 'react-draggable';
import { CoronaryFindingsSection } from '../../../components/CoronaryFindingsSection/CoronaryFindingsSection';
import { VesselGroupings } from '../../../config';
import { useAppSelector } from '../../../hooks';
import { useCoronaryFindingIsAiAssessedSelector, useCoronaryFindingsSelector } from '../../../selectors/reporting';

interface Props {
  selectedVesselGroup: VesselGroupings;
}

export default function ReportSection({ selectedVesselGroup }: Props): ReactElement<Props> {
  const containerRef = useRef<any>();

  const { volumeViewer } = useAppSelector((state) => state.contrast);

  const coronaryFindingAiAssessed = useCoronaryFindingIsAiAssessedSelector();

  const coronaryFindings = useCoronaryFindingsSelector();

  const [dragBounds, setDragBounds] = useState<DraggableBounds>({});

  // Calculate the bounds that will restrict dragging the window to within the volume viewer
  const calculateDraggableBounds = useCallback(() => {
    const ancestor = volumeViewer;
    const container = containerRef.current?.getBoundingClientRect();
    if (ancestor && container) {
      setDragBounds({
        top: ancestor.top - container.top,
        left: ancestor.left - container.left,
        right: ancestor.right - container.right,
        bottom: ancestor.bottom - container.bottom,
      });
    }
  }, [volumeViewer]);

  useLayoutEffect(calculateDraggableBounds, [calculateDraggableBounds]);

  useEffect(() => {
    window.addEventListener('resize', calculateDraggableBounds);

    return () => window.removeEventListener('resize', calculateDraggableBounds);
  }, [calculateDraggableBounds]);

  return (
    <Draggable bounds={dragBounds}>
      <div className="meta-details__coronary" ref={containerRef}>
        {selectedVesselGroup && (
          <CoronaryFindingsSection
            selectedVesselGroup={selectedVesselGroup}
            headerType="detailed"
            coronaryFindings={coronaryFindings}
            aiAssessed={coronaryFindingAiAssessed[selectedVesselGroup]}
          />
        )}
      </div>
    </Draggable>
  );
}
