import React, { useState } from 'react';
import cn from 'classnames';
import { customClassCheck } from '../../utils/shared';
import { Icon } from '../Icon/Icon';

interface IconButtonProps {
  toggle?: boolean;
  disabled?: boolean;
  customClass?: string;
  onClick: () => void;
  icon: string;
  iconType: string;
  customIconClass?: string;
  title?: string;
}

export const IconButton: React.FunctionComponent<IconButtonProps> = ({
  toggle = false,
  disabled = false,
  customClass,
  onClick,
  icon,
  iconType,
  customIconClass,
  title,
}) => {
  const [active, isActive] = useState(false);

  const handleButtonClick = () => {
    onClick();
    if (toggle === true) {
      isActive(!active);
    }
  };

  return (
    <button
      type="button"
      className={cn(
        'icon-button-new',
        {
          'icon-button-new--toggle': toggle,
          'icon--taskbar': iconType === 'taskbar',
          active: active === true,
        },
        `${customClassCheck(customClass)}`
      )}
      disabled={disabled}
      onClick={handleButtonClick}
      title={title}
    >
      <Icon icon={icon} type={iconType} customClass={customIconClass} />
    </button>
  );
};
