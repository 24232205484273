import React, { ReactElement, useCallback } from 'react';
import { IconButton } from '../../objects/IconButton/IconButton';
import { isCanvas } from '../../utils/shared';

interface Props {
  screenshotRef: any;
  onRequestScreenshot: any;
  onTakeScreenshot: any;
  headerStyle?: boolean;
  disabled?: boolean;
}

export default function AnnotationButton({
  screenshotRef,
  onRequestScreenshot,
  onTakeScreenshot,
  headerStyle = false,
  disabled = false,
}: Props): ReactElement<Props> {
  const takeScreenshot = useCallback(() => {
    if (!screenshotRef || !screenshotRef.current) {
      if (onRequestScreenshot) {
        onRequestScreenshot(onTakeScreenshot);
      }
      return;
    }

    const canvas = isCanvas(screenshotRef.current)
      ? screenshotRef.current
      : screenshotRef.current.querySelector('canvas');

    if (!canvas || !canvas.toDataURL) {
      console.error(
        'Error saving screenshot. The provided ref is not an instance of konva stage, nor is it a canvas or an element that contains a canvas.'
      );
      return;
    }

    const imageData = canvas.toDataURL();
    onTakeScreenshot && onTakeScreenshot(imageData);
  }, [onTakeScreenshot, onRequestScreenshot, screenshotRef]);

  return (
    <IconButton
      icon="screenshot"
      iconType="small"
      onClick={() => takeScreenshot()}
      title="Show screenshot to annotate"
      disabled={disabled}
    />
  );
}
