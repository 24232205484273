import React, { ReactElement, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  inlineReportingAction,
  UnsavedChangesToInlineReport,
} from '../../reducers/inlineReporting/inlineReportingSlice';
import { ActionModal } from '../ActionModal/ActionModal';

export default function InlineReportingWarning(): ReactElement {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.inlineReporting);

  const onConfirmModal = useCallback(() => {
    if (state.inlineReportingWarningAction) {
      state.inlineReportingWarningAction();
    }
    dispatch(inlineReportingAction.clearInlineReportingWarningAction());
    dispatch(inlineReportingAction.setUnsavedChangesWarning(undefined));
  }, [dispatch, state]);

  const onCloseModal = useCallback(() => {
    dispatch(inlineReportingAction.clearInlineReportingWarningAction());
    dispatch(inlineReportingAction.setUnsavedChangesWarning(undefined));
  }, [dispatch]);

  const headerContent = useMemo(() => {
    switch (state.showWarningType) {
      case UnsavedChangesToInlineReport.EditingReport:
        return 'Edit Report';
      case UnsavedChangesToInlineReport.ApprovingReport:
        return 'Approve Report';
      case UnsavedChangesToInlineReport.GeneratingReport:
        return 'Generate Report';

      case UnsavedChangesToInlineReport.SendingStudyForApproval:
        return 'Send report for review';
      case UnsavedChangesToInlineReport.CancelEditingImpressions:
      case UnsavedChangesToInlineReport.CancelEditingCoronary:
        return 'Discard Changes';
      case UnsavedChangesToInlineReport.LeavingStudy:
      default:
        return 'Leaving Report';
    }
  }, [state.showWarningType]);

  const actionType = useMemo(() => {
    switch (state.showWarningType) {
      case UnsavedChangesToInlineReport.EditingReport:
        return 'edit';
      case UnsavedChangesToInlineReport.ApprovingReport:
        return 'approve';
      case UnsavedChangesToInlineReport.GeneratingReport:
        return 'generate';

      case UnsavedChangesToInlineReport.SendingStudyForApproval:
        return 'send';
      case UnsavedChangesToInlineReport.CancelEditingImpressions:
      case UnsavedChangesToInlineReport.CancelEditingCoronary:
        return 'discard changes to';
      case UnsavedChangesToInlineReport.LeavingStudy:
      default:
        return 'leave';
    }
  }, [state.showWarningType]);

  const coronaryFieldsWithEdits = useMemo(() => {
    return [
      state.lad_coronary_findings ? 'LAD' : undefined,
      state.lcx_coronary_findings ? 'LCX' : undefined,
      state.lm_coronary_findings ? 'LM' : undefined,
      state.rca_coronary_findings ? 'RCA' : undefined,
    ].filter((field): field is string => !!field);
  }, [state]);

  const warning = useMemo(() => {
    const msg = `Are you sure you want to ${actionType} the report? `;
    const msgUnsavedImpressions = `Unsaved impression changes will be discarded. `;
    const msgUnsavedCoronary =
      'Unsaved coronary findings will be discarded on vessels ' + coronaryFieldsWithEdits.join(', ') + '.';

    switch (state.showWarningType) {
      case UnsavedChangesToInlineReport.CancelEditingCoronary:
        return msg + msgUnsavedCoronary;
      case UnsavedChangesToInlineReport.CancelEditingImpressions:
        return msg + msgUnsavedImpressions;
      default: {
        if (coronaryFieldsWithEdits.length > 0 && state.impressions) {
          return msg + msgUnsavedCoronary + ` Unsaved impression changes will also be discarded.`;
        } else if (coronaryFieldsWithEdits.length > 0) {
          return msg + msgUnsavedCoronary;
        } else if (state.impressions) {
          return msg + msgUnsavedImpressions;
        }
        return undefined;
      }
    }
  }, [actionType, state.showWarningType, state.impressions, coronaryFieldsWithEdits]);

  return (
    <ActionModal
      confirmText="Discard Changes"
      closeText="Cancel"
      onConfirm={onConfirmModal}
      onClose={onCloseModal}
      visible={state.showWarningType !== undefined}
      headerContent={<>{headerContent}</>}
    >
      <p>{warning}</p>
    </ActionModal>
  );
}
