import React, { ReactElement, useMemo, useCallback, ChangeEventHandler } from 'react';

interface Props {
  label: string;
  value: string | undefined;
  editingReport: boolean;
  handleUpdateReportStats: (stat: string, newValue: string) => void;
  editable?: boolean;
}

export function SisDataStat({
  label,
  value,
  editingReport,
  handleUpdateReportStats,
  editable,
}: Props): ReactElement<Props> {
  const v = useMemo(() => {
    if (editingReport) {
      return value;
    }

    return value && value.length > 0 ? value : '-';
  }, [value, editingReport]);

  const updateValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      handleUpdateReportStats('sis_data', event.target.value);
    },
    [handleUpdateReportStats]
  );

  return (
    <div className="value-list__item">
      <dt className="value-list__label">{label}</dt>
      {(!editingReport || !editable) && <dd className="value-list__value">{v}</dd>}
      {editingReport && editable && (
        <dd className="value-list__value">
          <input type="text" value={v} onChange={updateValue} />
        </dd>
      )}
    </div>
  );
}
