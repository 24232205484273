import { AuthPiece } from 'aws-amplify-react';
import React from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import { GlobalLoader } from '../components/GlobalLoader';
import IdleTimer from '../components/IdleTimer/IdleTimer';
import ReportUnsavedModal from '../components/ReportEditingModal/ReportEditingModal';
import InlineReportWarning from '../components/InlineReportingWarning/InlineReportingWarning';
import ReportAmendmentModal from '../components/ReportAmendmentModal/ReportAmendmentModal';
import CTVolume from '../views/CTVolume/CTVolume';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import NotFound from './404';
import Dashboard from './Dashboard/Dashboard';
import Error from './Error/Error';
import Patient from './Patient/Patient';
import ReviewReport from './Report/Report';
import Testing from './Testing/Testing';
import LoginNew from './LoginNew/LoginNew';
import { StoreProvider } from '../StoreProvider';
import { AssessmentLoader } from '../AssessmentLoader';
import { UserDataLoader } from '../reducers/user/UserDataLoader';
import { AuthData } from '../reducers/user/userSlice';
import Settings from './Settings/Settings';

interface Props {
  authData: AuthData;
}

class Pages extends AuthPiece<Props, {}> {
  constructor(props: Props) {
    super(props);
    /**
     * Only show this component for the following Auth states.
     * State options are:
     *  - signIn
     *  - signUp
     *  - confirmSignIn
     *  - confirmSignUp
     *  - forgotPassword
     *  - requireNewPassword
     *  - verifyContact
     *  - signedIn
     */
    this._validAuthStates = ['signedIn'];
  }
  showComponent() {
    return (
      <UserDataLoader authData={this.props.authData}>
        <GlobalLoader />
        <BrowserRouter>
          <Routes>
            {/* The assessment route uses the assessment store */}
            <Route
              path="/study/:id"
              element={
                <StoreProvider>
                  <ReportUnsavedModal />
                  <InlineReportWarning />
                  <ReportAmendmentModal />
                  <IdleTimer>
                    <AssessmentLoader>
                      <Patient />
                      <ErrorBoundary>
                        <CTVolume />
                      </ErrorBoundary>
                      <ReviewReport />
                    </AssessmentLoader>
                  </IdleTimer>
                </StoreProvider>
              }
            ></Route>

            {/* All other routes do not require the assessment store */}
            <Route
              element={
                <>
                  <Outlet />
                </>
              }
            >
              <Route path="/" element={<Dashboard />} />
              <Route path="/loginnew" element={<LoginNew />} />
              <Route path="/testing" element={<Testing />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/error" element={<Error />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </UserDataLoader>
    );
  }
}

export default Pages;
