import { useMemo } from 'react';
import { AUTH_USER_GROUP } from '../config';
import { useDashboardSelector } from '../dashboardHooks';

export function useIsAuditorUserSelector() {
  const { user: loggedInUser } = useDashboardSelector((state) => state.user);

  return useMemo(() => loggedInUser.groups?.includes(AUTH_USER_GROUP.auditors), [loggedInUser.groups]);
}

export function useIsArtryaUserSelector() {
  const { user: loggedInUser } = useDashboardSelector((state) => state.user);

  return useMemo(() => loggedInUser.groups?.includes(AUTH_USER_GROUP.artrya), [loggedInUser.groups]);
}
