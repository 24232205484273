import React, { CSSProperties } from 'react';
import { vec2, vec3 } from 'gl-matrix';
import { liangBarksyClip } from './liangBarksyClip';
import { worldToDisplay, getScreenScale } from './Utils';
import { ReferenceLine, vtkApi } from '../ReactVTKJSTypes';

interface CrosshairsProps {
  // The colours of the crosshair axes [Axial, Sagittal, Coronal].
  axisColours: [string, string, string];
  // The width of the crosshairs.
  strokeWidth: number;
  selectedStrokeWidth: number;
  strokeDashArray: number[];
  rotateHandleDistance: number;
  rotateHandleRadius: number;
  centerRadius: number;
  centerCircleRadius: number;
}

interface CrosshairsState {
  // The width of the VtkContrastView.
  width?: number;
  // The height of the VtkContrastView.
  height?: number;
  // The main crosshairs line info.
  referenceLines?: [ReferenceLine, ReferenceLine];
  // The crosshairs position in display coordinates.
  point?: vec2;
  // 0 = Axial, 1 = Sagittal, 2 = Coronal
  viewType: number;
}

export class Crosshairs extends React.Component<CrosshairsProps, CrosshairsState> {
  static defaultProps = {
    axisColours: ['#00ecf1', '#fc22d5', '#d7fa03'],
    strokeWidth: 2,
    rotateHandleDistance: 3 / 8,
    rotateHandleRadius: 8,
    selectedStrokeWidth: 4,
    centerRadius: 25,
    centerCircleRadius: 12.5,
    strokeDashArray: [4, 4],
  };

  constructor(props: CrosshairsProps) {
    super(props);
    this.state = {
      // The width of the VtkContrastView.
      width: undefined,
      // The height of the VtkContrastView.
      height: undefined,
      // The main crosshairs line info.
      referenceLines: undefined,
      // The crosshairs position in display coordinates.
      point: undefined,
      // The view type: 0 = Axial, 1 = Sagittal, 2 = Coronal.
      viewType: 0,
    };
  }

  /**
   * Calculate the two crosshairs reference lines that will be drawn on the this view.
   * That is, the points were the crosshairs intersect the viewport edges, the positions of the
   * crosshair handles, and some colour and selection info.
   * @param point is [X, Y] is viewport coordinates.
   */
  calculateReferenceLines = (
    width: number,
    height: number,
    point: vec2,
    api: vtkApi,
    viewType: number
  ): [ReferenceLine, ReferenceLine] | undefined => {
    const oldReferenceLines = this.state.referenceLines;

    if (point[0] === null || point[1] === null) {
      return undefined;
    }

    // Get the scaled viewport coordinates.
    const center: vec2 = [point[0], height - point[1]];

    // Get the distance away from the crosshair center to place the rotation handles (based on the shortest view dimension).
    const handleDistance = Math.min(width, height) * this.props.rotateHandleDistance;

    // Choose a length for our crosshair lines (make it effectively infinite).
    const lineLength = 1000000;

    /**
     * Take a [X, Y, Z] vector in world coordinates and create a reference line using it.
     */
    const calculateReferenceLine = (worldAxisVector: vec3, axisViewType: number): ReferenceLine => {
      const renderer = api.genericRenderWindow.getRenderer();

      // Project the 3D vector to a 2D position in screen space.
      const displayPosition: vec2 = worldToDisplay(renderer, worldAxisVector) as vec2;
      // Also calculate the display position for the world origin.
      const displayOrigin: vec2 = worldToDisplay(renderer, [0, 0, 0]) as vec2;
      // Get the display vector by subtracting the origin from the position.
      const displayVector: vec2 = [0, 0];
      vec2.subtract(displayVector, displayPosition, displayOrigin);
      // Flip the Y.
      displayVector[1] = -displayVector[1];

      // Now get the unit vector (in display coordinates) that this axis is pointing in.
      const unitVector: vec2 = [0, 0];
      vec2.normalize(unitVector, displayVector);

      // Now scale it to the desired length.
      const farVector: vec2 = [0, 0];
      vec2.scale(farVector, unitVector, lineLength);

      // Get one end of the line.
      const firstPoint: vec2 = [0, 0];
      vec2.add(firstPoint, center, farVector);

      // Get the other end of the line.
      const secondPoint: vec2 = [0, 0];
      vec2.subtract(secondPoint, center, farVector);

      // Clip the line end points to the viewport's SVG coordinate space.
      // This might seem like a waste of time but apparently SVG rendering is pretty dumb and now that we're using
      // a dashed pattern for the line it will create every line segment for the entire line length even if only a
      // fraction of it is actually rendered.
      liangBarksyClip(firstPoint, secondPoint, [0, 0, width, height]);

      // Use the old reference line to carry across it's old props.
      let lineSelected = false;
      let lineActive = false;
      let rotateSelected = false;
      let rotateActive = false;
      const oldReferenceLine = oldReferenceLines?.find((refLine) => refLine && refLine.viewType === axisViewType);
      if (oldReferenceLine) {
        lineSelected = oldReferenceLine.selected;
        lineActive = oldReferenceLine.active;
        rotateSelected = oldReferenceLine.rotateHandles.selected;
        rotateActive = oldReferenceLine.rotateHandles.active;
      }

      // Calculate the first rotate handle position.
      const firstRotateHandle = {
        x: center[0] + handleDistance * unitVector[0],
        y: center[1] + handleDistance * unitVector[1],
      };

      // Calculate the second rotate handle position.
      const secondRotateHandle = {
        x: center[0] - handleDistance * unitVector[0],
        y: center[1] - handleDistance * unitVector[1],
      };

      // Create the reference line.
      const referenceLine = {
        points: [
          { x: firstPoint[0], y: firstPoint[1] },
          { x: secondPoint[0], y: secondPoint[1] },
        ],
        rotateHandles: {
          selected: rotateSelected,
          active: rotateActive,
          points: [firstRotateHandle, secondRotateHandle],
        },
        viewType: axisViewType,
        selected: lineSelected,
        active: lineActive,
      };

      // Push the line onto the reference lines array.
      return referenceLine;
    };

    // Create the two reference lines.
    const referenceLines: ReferenceLine[] = [];

    // Get the reference lines for the other two axes.
    const axes = api.crosshairWorldAxes;
    for (let i = 0; i < 3; i++) {
      if (i !== viewType) {
        referenceLines.push(calculateReferenceLine(axes[i], i));
      }
    }

    return referenceLines as [ReferenceLine, ReferenceLine];
  };

  /**
   * Refresh the crosshairs lines, and trigger a re-render
   */
  refresh(width: number, height: number, api: vtkApi, viewType: number) {
    if (!api) {
      console.error('api must be defined in order to refresh Crosshairs');
      return;
    }

    const worldPos = api.crosshairWorldPosition;
    if (worldPos == null) {
      console.error('crosshairWorldPosition must be defined in order to refresh Crosshairs');
      return;
    }

    // Convert worldPos into display coordinates [X, Y].
    const point: vec2 = worldToDisplay(api.genericRenderWindow.getRenderer(), worldPos) as vec2;
    const referenceLines = this.calculateReferenceLines(width, height, point, api, viewType);

    // Set the crosshairs info required by the vtkInteractorStyleRotatableMPRCrosshairs in the api.
    if (referenceLines != null) {
      api.crosshairs = {
        point,
        referenceLines,
        centerRadius: this.props.centerRadius,
        viewWidth: width,
        viewHeight: height,
      };
    }

    // Update the state and force a re-render.
    this.setState({ width, height, point, viewType, referenceLines });
  }

  splitReferenceLine = (referenceLine: ReferenceLine, center: vec2, centerRadius: number) => {
    const lineDirection: vec2 = [0, 0];
    vec2.subtract(
      lineDirection,
      [referenceLine.points[1].x, referenceLine.points[1].y],
      [referenceLine.points[0].x, referenceLine.points[0].y]
    );
    vec2.normalize(lineDirection, lineDirection);

    const linePart1 = [
      {
        x: center[0] + lineDirection[0] * centerRadius,
        y: center[1] + lineDirection[1] * centerRadius,
      },
      referenceLine.points[1],
    ];
    const linePart2 = [
      {
        x: center[0] - lineDirection[0] * centerRadius,
        y: center[1] - lineDirection[1] * centerRadius,
      },
      referenceLine.points[0],
    ];

    return [linePart1, linePart2];
  };

  render() {
    const { width, height, point, referenceLines } = this.state;
    const {
      axisColours,
      strokeWidth,
      selectedStrokeWidth,
      strokeDashArray,
      rotateHandleRadius,
      centerRadius,
    } = this.props;
    if (!width || !height || !referenceLines || !point) return null;

    // Get the crosshair center.
    const center: vec2 = [point[0], height - point[1]];

    // Scale the stroke widths to look correct on the given display.
    const scale = getScreenScale();
    const scaledStrokeWidth = strokeWidth * scale;
    const scaledSelectedStrokeWidth = selectedStrokeWidth * scale;
    const scaledStrokeDashArray = `${(strokeDashArray[0] * scale, strokeDashArray[1] * scale)}`;
    const scaledRotateHandleRadius = rotateHandleRadius * scale;
    const scaledCenterRadius = centerRadius * scale;

    const [firstLine, secondLine] = referenceLines;

    const { points: firstLineRotateHandles, selected: firstLineRotateSelected } = firstLine.rotateHandles;
    const { points: secondLineRotateHandles, selected: secondLineRotateSelected } = secondLine.rotateHandles;

    const [firstLinePart1, firstLinePart2] = this.splitReferenceLine(firstLine, center, scaledCenterRadius);
    const [secondLinePart1, secondLinePart2] = this.splitReferenceLine(secondLine, center, scaledCenterRadius);

    const firstLineStrokeColor = axisColours[firstLine.viewType];
    const secondLineStrokeColor = axisColours[secondLine.viewType];

    const firstLineStrokeWidth = firstLine.selected || firstLine.active ? scaledSelectedStrokeWidth : scaledStrokeWidth;
    const secondLineStrokeWidth =
      secondLine.selected || secondLine.active ? scaledSelectedStrokeWidth : scaledStrokeWidth;

    const firstLineRotateWidth = firstLineRotateSelected ? scaledSelectedStrokeWidth : scaledStrokeWidth;
    const secondLineRotateWidth = secondLineRotateSelected ? scaledSelectedStrokeWidth : scaledStrokeWidth;

    const firstLineShowCrosshairs = firstLine.selected || firstLineRotateSelected;
    const secondLineShowCrosshairs = secondLine.selected || secondLineRotateSelected;

    const firstLineRotateHandleRadius = firstLineShowCrosshairs ? scaledRotateHandleRadius : 0;
    const secondLineRotateHandleRadius = secondLineShowCrosshairs ? scaledRotateHandleRadius : 0;

    const firstLineRotateHandleFill = firstLineRotateSelected ? firstLineStrokeColor : 'none';
    const secondLineRotateHandleFill = secondLineRotateSelected ? secondLineStrokeColor : 'none';

    const style: CSSProperties = {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
    };

    return (
      <svg version="1.1" viewBox={`0 0 ${width} ${height}`} width={width} height={height} style={style}>
        <g stroke={firstLineStrokeColor} strokeDasharray={scaledStrokeDashArray} strokeWidth={firstLineStrokeWidth}>
          {/* First line part A */}
          <line x1={firstLinePart1[0].x} y1={firstLinePart1[0].y} x2={firstLinePart1[1].x} y2={firstLinePart1[1].y} />
          {/* First line part B */}
          <line x1={firstLinePart2[0].x} y1={firstLinePart2[0].y} x2={firstLinePart2[1].x} y2={firstLinePart2[1].y} />
        </g>
        <g stroke={firstLineStrokeColor} strokeWidth={firstLineRotateWidth} fill={firstLineRotateHandleFill}>
          {/* First line rotate handle A */}
          <circle cx={firstLineRotateHandles[0].x} cy={firstLineRotateHandles[0].y} r={firstLineRotateHandleRadius} />
          {/* First line rotate handle B */}
          <circle cx={firstLineRotateHandles[1].x} cy={firstLineRotateHandles[1].y} r={firstLineRotateHandleRadius} />
        </g>

        <g stroke={secondLineStrokeColor} strokeDasharray={scaledStrokeDashArray} strokeWidth={secondLineStrokeWidth}>
          {/* Second line part A */}
          <line
            x1={secondLinePart1[0].x}
            y1={secondLinePart1[0].y}
            x2={secondLinePart1[1].x}
            y2={secondLinePart1[1].y}
          />
          {/* Second line part B */}
          <line
            x1={secondLinePart2[0].x}
            y1={secondLinePart2[0].y}
            x2={secondLinePart2[1].x}
            y2={secondLinePart2[1].y}
          />
        </g>
        <g stroke={secondLineStrokeColor} strokeWidth={secondLineRotateWidth} fill={secondLineRotateHandleFill}>
          {/* Second line rotate handle A */}
          <circle
            cx={secondLineRotateHandles[0].x}
            cy={secondLineRotateHandles[0].y}
            r={secondLineRotateHandleRadius}
          />
          {/* Second line rotate handle B */}
          <circle
            cx={secondLineRotateHandles[1].x}
            cy={secondLineRotateHandles[1].y}
            r={secondLineRotateHandleRadius}
          />
        </g>
      </svg>
    );
  }
}
