import cn from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import { VesselGroupings } from '../../config';
import { getVesselColor } from '../../utils/vessel';
import { ReactComponent as EditReportIcon } from '../../assets/icons/edit.svg';
import Button from '../Button/Button';
import { Loader } from '../Loader/Loader';
import { useAppSelector } from '../../hooks';

export type PatientOverviewSectionHeaderType = 'detailed' | 'non-detailed';

interface Props {
  editMode: boolean;
  onClick: () => void;
  children: ReactNode;
  onCancel: () => void;
  onSave: () => void;
  saveEnabled: boolean;
  aiAssessed: boolean;
  title: string;
  headerType: PatientOverviewSectionHeaderType;
  // Used to display a vessel legend next to the card title
  vesselGroup?: VesselGroupings;
  loading?: boolean;
}

export function PatientOverviewSection({
  editMode,
  onClick,
  onCancel,
  onSave,
  saveEnabled,
  aiAssessed,
  title,
  headerType,
  children,
  vesselGroup,
  loading = false,
}: Props): React.ReactElement<Props> {
  const postingPartialReport = useAppSelector((state) => state.report.postingPartialReport);
  const editingReport = useAppSelector((state) => state.report.editing);

  const showLoader = useMemo(() => loading || postingPartialReport, [loading, postingPartialReport]);

  return (
    <div className="patient-overview-section">
      <div className="patient-overview-section__header">
        <h3>
          {vesselGroup && (
            <div
              style={{
                background: getVesselColor(vesselGroup),
              }}
              className="key-findings__legend"
            />
          )}
          {title}
        </h3>

        {!editMode && (
          <span
            className={cn('patient-overview-section__label', {
              'patient-overview-section__label--edited': !aiAssessed,
            })}
          >
            {aiAssessed ? 'AI Assessed' : 'Edited'}
          </span>
        )}

        {showLoader && <Loader inline />}
        {!showLoader && (
          <>
            <div className="patient-overview-section__edit-toggle">
              <button className="patient-overview-section__edit-button" onClick={onClick} disabled={editMode}>
                <EditReportIcon />
              </button>
            </div>
            {headerType === 'detailed' && <></>}
          </>
        )}
      </div>

      {children}

      {editMode && (
        <div className="patient-overview-section__edit-actions">
          <Button inline theme="secondary" onClick={onCancel}>
            <span className="patient-overview-section__cancel-button">Cancel</span>
          </Button>
          <Button inline theme="primary" disabled={!saveEnabled} onClick={onSave}>
            <span className="patient-overview-section__save-button"> {editingReport ? 'Save All' : 'Save'}</span>
          </Button>
        </div>
      )}
    </div>
  );
}
