import React, { useEffect, useState } from 'react';
import { fetchPlaqueMeasurements } from '../../utils/api';
import { PlaqueCategories, PlaqueMeasurements } from '../../context/types';
import { forEach } from 'lodash';
import { Loader } from '../Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { globalFlagsActions } from '../../reducers/global-flags/globalFlagsSlice';

const DEFAULT_PLAQUE_VALUES: PlaqueCategories = {
  total: undefined,
  lap: undefined,
  ca: undefined,
  nca: undefined,
};

const DEFAULT_PLAQUE_MEASUREMENT_VALUES: PlaqueMeasurements = {
  volume: DEFAULT_PLAQUE_VALUES,
  burden: DEFAULT_PLAQUE_VALUES,
};

export const OverallMeasurements: React.FunctionComponent = () => {
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const fetchingOverallMeasurements = useAppSelector((state) => state.globalFlags.fetchingOverallMeasurements);
  const dispatch = useAppDispatch();
  const patientID = useAppSelector((state) => state.patient.patientID);
  const [plaqueMeasurements, setPlaqueMeasurements] = useState<PlaqueMeasurements>(DEFAULT_PLAQUE_MEASUREMENT_VALUES);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!patientID || !runID) {
      return;
    }
    setLoading(true);
    dispatch(globalFlagsActions.setFetchingOverallMeasurements(true));

    fetchPlaqueMeasurements(patientID, runID)
      .then((res: PlaqueMeasurements) => {
        let plaqueBurden: PlaqueCategories = { ...DEFAULT_PLAQUE_VALUES };
        let plaqueVolume: PlaqueCategories = { ...DEFAULT_PLAQUE_VALUES };

        forEach(res.burden, (value, key) => {
          if (Number(value) === 0) return;
          let percentageBurden = Number(value) * 100;
          plaqueBurden[key as keyof PlaqueCategories] = percentageBurden.toFixed(2);
        });
        forEach(res.volume, (value, key) => {
          if (Number(value) === 0) return;
          plaqueVolume[key as keyof PlaqueCategories] = Number(value).toFixed(2);
        });

        res &&
          setPlaqueMeasurements({
            volume: plaqueVolume,
            burden: plaqueBurden,
          });

        setLoading(false);
        dispatch(globalFlagsActions.setFetchingOverallMeasurements(false));
      })
      .catch(() => {
        setPlaqueMeasurements(DEFAULT_PLAQUE_MEASUREMENT_VALUES);
        setLoading(false);
        dispatch(globalFlagsActions.setFetchingOverallMeasurements(false));
      });
  }, [patientID, runID, dispatch]);

  return (
    <>
      {loading && (
        <div className="loading-measurement">
          <Loader inline />
        </div>
      )}
      {!fetchingOverallMeasurements && (
        <div className="measurements-burden">
          <h3 className="measurements-burden__header">Volume (Burden)</h3>

          <dl className="value-list measurements-burden__value-list">
            <div className="value-list__item">
              <dt className="value-list__label">Total Plaque</dt>
              <dd className="value-list__value">
                {plaqueMeasurements.volume.total && !plaqueMeasurements.burden.total && (
                  <span>
                    {plaqueMeasurements.volume.total} mm<sup>2</sup> (-%)
                  </span>
                )}

                {!plaqueMeasurements.volume.total && plaqueMeasurements.burden.total && (
                  <span>
                    -mm<sup>2</sup> ({plaqueMeasurements.burden.total}%)
                  </span>
                )}

                {plaqueMeasurements.volume.total && plaqueMeasurements.burden.total ? (
                  <span>
                    {plaqueMeasurements.volume.total} mm<sup>2</sup> ({plaqueMeasurements.burden.total}%)
                  </span>
                ) : (
                  <span>-</span>
                )}
              </dd>
            </div>

            <div className="value-list__item">
              <dt className="value-list__label">Calcified Plaque</dt>
              <dd className="value-list__value">
                {plaqueMeasurements.volume.ca && !plaqueMeasurements.burden.ca && (
                  <span>
                    {plaqueMeasurements.volume.ca} mm<sup>2</sup> (-%)
                  </span>
                )}

                {!plaqueMeasurements.volume.ca && plaqueMeasurements.burden.ca && (
                  <span>
                    -mm<sup>2</sup> ({plaqueMeasurements.burden.ca}%)
                  </span>
                )}

                {plaqueMeasurements.volume.ca && plaqueMeasurements.burden.ca ? (
                  <span>
                    {plaqueMeasurements.volume.ca} mm<sup>2</sup> ({plaqueMeasurements.burden.ca}%)
                  </span>
                ) : (
                  <span>-</span>
                )}
              </dd>
            </div>

            <div className="value-list__item">
              <dt className="value-list__label">Non-calcified Plaque</dt>
              <dd className="value-list__value">
                {plaqueMeasurements.volume.nca && !plaqueMeasurements.burden.nca && (
                  <span>
                    {plaqueMeasurements.volume.nca} mm<sup>2</sup> (-%)
                  </span>
                )}

                {!plaqueMeasurements.volume.nca && plaqueMeasurements.burden.nca && (
                  <span>
                    -mm<sup>2</sup> ({plaqueMeasurements.burden.nca}%)
                  </span>
                )}

                {plaqueMeasurements.volume.nca && plaqueMeasurements.burden.nca ? (
                  <span>
                    {plaqueMeasurements.volume.nca} mm<sup>2</sup> ({plaqueMeasurements.burden.nca}%)
                  </span>
                ) : (
                  <span>-</span>
                )}
              </dd>
            </div>

            <div className="value-list__item">
              <dt className="value-list__label">LAP</dt>
              <dd className="value-list__value">
                {plaqueMeasurements.volume.lap && !plaqueMeasurements.burden.lap && (
                  <span>
                    {plaqueMeasurements.volume.lap} mm<sup>2</sup> (-%)
                  </span>
                )}

                {!plaqueMeasurements.volume.lap && plaqueMeasurements.burden.lap && (
                  <span>
                    -mm<sup>2</sup> ({plaqueMeasurements.burden.lap}%)
                  </span>
                )}

                {plaqueMeasurements.volume.lap && plaqueMeasurements.burden.lap ? (
                  <span>
                    {plaqueMeasurements.volume.lap} mm<sup>2</sup> ({plaqueMeasurements.burden.lap}%)
                  </span>
                ) : (
                  <span>-</span>
                )}
              </dd>
            </div>
          </dl>
        </div>
      )}
    </>
  );
};

export default OverallMeasurements;
