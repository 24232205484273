import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Dropdown, { OptionsOrGroups, SingleValue } from 'react-select';
import usePreviousValue from '../../../hooks/use-previous-value';
import Styles from './SelectStyle';
import { isEqual } from 'lodash';

function isStringArray(selectorOptions: SelectorOptions): selectorOptions is string[] {
  if (selectorOptions.length > 0 && typeof selectorOptions[0] == 'string') {
    return true;
  }

  return false;
}

function isOptionTupleArray(selectorOptions: SelectorOptions): selectorOptions is OptionTuple[] {
  if (selectorOptions.length < 0) {
    return false;
  }

  const potentialOptionTuple = selectorOptions[0] as OptionTuple;

  if (potentialOptionTuple.label !== undefined && potentialOptionTuple.value !== undefined) {
    return true;
  }

  return false;
}

type OptionTuple = {
  label: string;
  value: string;
};

export type SelectorOptions = OptionTuple[] | string[];

interface SelectProps {
  options: SelectorOptions;
  value?: string;
  label?: string;
  leftLabel?: boolean;
  stenosisLevel?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  inline?: boolean;
  scrollable?: boolean;
  disabled?: boolean;
  hideFirstOption?: boolean;
  addPlaceholderAsOption?: boolean;
  isSearchable?: boolean;
  small?: boolean;
  bgDark?: boolean;
  menuIsOpen?: boolean;
}

const Select: React.FunctionComponent<SelectProps> = ({
  options: dropdownOptions = [],
  value,
  label,
  leftLabel = false,
  stenosisLevel,
  onChange,
  placeholder = 'Please select',
  inline,
  scrollable,
  disabled,
  hideFirstOption,
  addPlaceholderAsOption = false,
  isSearchable = false,
  small = false,
  bgDark = false,
  menuIsOpen = undefined,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const prevDropdownOptions = usePreviousValue(dropdownOptions);
  const [options, setOptions] = useState<OptionsOrGroups<any, never>>([]);

  useEffect(() => {
    // if the dropdown options have ACTUALLY changed THEN parse the options...
    if (!isEqual(dropdownOptions, prevDropdownOptions)) {
      let parsedOptions = dropdownOptions;

      if (isStringArray(dropdownOptions)) {
        parsedOptions = dropdownOptions.map((option: string) => ({
          value: option,
          label: option,
        }));
      }

      if (isOptionTupleArray(parsedOptions)) {
        // if for some reason we need the placeholder to be an actual option in the dropdown...
        const displayValue = value === '' || value === undefined ? placeholder : value;
        if (addPlaceholderAsOption && displayValue !== placeholder) {
          const defaultOption: OptionTuple = { label: placeholder, value: placeholder };

          parsedOptions.push(defaultOption);
          parsedOptions = [{ label: placeholder, value: placeholder }, ...parsedOptions];
        }
      }

      setOptions(parsedOptions);
    }
  }, [dropdownOptions, prevDropdownOptions, options, placeholder, value, addPlaceholderAsOption]);

  const selectOnChange = (optionValue: string) => {
    if (!onChange) return;
    if (optionValue === placeholder) onChange('');
    onChange(optionValue);
  };

  const getValue = useCallback(() => {
    let val: SingleValue<string | number | undefined>;
    // if no value prop is passed in then use the placeholder as the value
    const defaultValue = { value: placeholder, label: placeholder };
    // as the value is passed in as a string we need to look for the value in the current
    // options array and use that matching object as the value
    if (value != null) {
      val = options.find((option: any) => option.value === value);
    }
    return val ?? defaultValue;
  }, [options, value, placeholder]);

  return (
    <div
      className={cn('select-new-container', {
        'select-new-container--left-label': leftLabel,
      })}
    >
      {label && (
        <label
          className={cn('select-new__label', {
            'select-new__label--disabled': disabled,
            'select-new-label--left-label': leftLabel,
          })}
        >
          {label}
        </label>
      )}
      <Dropdown
        isSearchable={isSearchable}
        className={cn('select-new', stenosisLevel ? `select-new__stenosis${stenosisLevel}` : '', {
          'select-new--focus': isFocused,
          'select-new--inline': inline,
          'select-new--scrollable': scrollable,
          'select-new--disabled': disabled,
          'select-new--hide-first-option': hideFirstOption,
          'select-new--small': small,
          'select-new--bg-400': bgDark,
        })}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        tabSelectsValue={false}
        isDisabled={disabled}
        menuIsOpen={menuIsOpen}
        styles={Styles}
        classNamePrefix="select-new"
        value={getValue()}
        options={options}
        menuPlacement="auto"
        onChange={(event) => {
          selectOnChange(event.value);
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Select;
