import cn from 'classnames';
import React, { ReactElement } from 'react';
import { RISK_LEVELS } from '../../config';
import { StatsDataType } from '../../context/types';

interface Props {
  stats: StatsDataType;
  editingReport: boolean;
  handleUpdateReportStats: (field: string, newValue: string) => void;
}

export function MaximumStenosisStat({ stats }: Props): ReactElement<Props> {
  return (
    <div className="value-list__item">
      <dt className="value-list__label">{stats.label}</dt>
      <dd
        className={cn(
          'value-list__value',
          RISK_LEVELS.includes(stats.riskLevel ?? '') ? `disease-risk--${stats.riskLevel}` : ''
        )}
      >
        {stats.value ? stats.value : '-'}
      </dd>
    </div>
  );
}
