import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { storeActions } from '../reducers/store/storeSlice';
import { useAbortController } from '../utils/use-abort-controller';
import VersionHeadUpdater from '../utils/versionUpdater';

export const versionHeadUpdater = new VersionHeadUpdater();

export const useUpdateVersionHead = () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (versionNumber?: string) => {
      if (!versionNumber) return;
      versionHeadUpdater.updateVersionCountMap(versionNumber);
      dispatch(storeActions.setVersionHead(versionNumber));
    },
    [dispatch]
  );
};

export const useUpdateBackendVersionHead = () => {
  const dispatch = useAppDispatch();
  const signal = useAbortController();
  const patientID = useAppSelector((state) => state.patient.patientID);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  return useCallback(
    async (versionNumber: string, force: boolean = false): Promise<void> => {
      if (force) {
        dispatch(storeActions.setBackendVersionHead(versionNumber));
        return;
      }
      versionHeadUpdater
        .updateBackendVersionHead(versionNumber, patientID, runID)
        .then((response) => {
          if (!signal().aborted && response) {
            dispatch(storeActions.setBackendVersionHead(response));
          }
        })
        .catch((error) => console.warn('An error occurred while updating the version head', error));
    },
    [dispatch, patientID, runID, signal]
  );
};
