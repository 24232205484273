import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatientStats } from '../../context/types';

export interface Stenosis {
  [key: string]: number[];
}

export type patientState = {
  patientStats: PatientStats | null;
  patientID: string | undefined;
  patientDataReloadCount: number | undefined;
  stenosis: Stenosis | undefined;
};

export const initialPatientState = (): patientState => {
  return {
    patientStats: null,
    patientID: undefined,
    patientDataReloadCount: 0,
    stenosis: undefined,
  };
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState: initialPatientState(),
  reducers: {
    setPatientStats: (state, action: PayloadAction<PatientStats>) => {
      state.patientStats = action.payload;
    },
    setPatientID: (state, action: PayloadAction<string | undefined>) => {
      state.patientID = action.payload;
    },
    setPatientDataReloadCount: (state, action: PayloadAction<number | undefined>) => {
      state.patientDataReloadCount = action.payload;
    },
    setStenosis: (state, action: PayloadAction<Stenosis | undefined>) => {
      state.stenosis = action.payload;
    },
  },
});

export const patientActions = patientSlice.actions;
