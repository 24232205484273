import React, { ChangeEventHandler, ReactElement, useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { reportActions } from '../../reducers/report/reportSlice';
import { reportStateActions } from '../../reducers/ReportState/ReportStateSlice';
import { ActionModal } from '../ActionModal/ActionModal';

export default function ReportAmendmentModal(): ReactElement {
  const dispatch = useAppDispatch();
  const draftReport = useAppSelector((state) => state.report.draft);
  const amendmentModalPassthrough = useAppSelector((state) => state.reportState.amendmentModalPassthrough);

  const onClose = useCallback(() => {
    dispatch(reportStateActions.setAmendmentModalPassthrough({}));
  }, [dispatch]);

  const onMessageChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      dispatch(reportActions.updateDraftReport({ update_message: event.target.value }));
    },
    [dispatch]
  );

  const onConfirm = useCallback(() => {
    if (amendmentModalPassthrough?.passthrough && draftReport?.update_message) {
      amendmentModalPassthrough.passthrough(draftReport?.update_message);
      dispatch(reportActions.deleteDraftReport());
    }
  }, [amendmentModalPassthrough, draftReport?.update_message, dispatch]);

  return (
    <>
      {amendmentModalPassthrough?.passthrough && (
        <ActionModal
          confirmText={'Continue'}
          closeText={'Cancel'}
          onClose={onClose}
          onConfirm={onConfirm}
          visible={true}
          headerContent={<>REASON FOR AMENDMENT</>}
          disabled={!draftReport?.update_message}
        >
          <div className="report-review-modal__comment findings">
            <TextareaAutosize
              maxRows={7}
              minRows={5}
              className="findings__enter"
              placeholder="Provide a reason for why it was necessary to amend the patient report."
              value={draftReport?.update_message ?? ''}
              onChange={onMessageChange}
            />
          </div>
        </ActionModal>
      )}
    </>
  );
}
