import cn from 'classnames';
import React, { useEffect } from 'react';
import { IconButton } from '../IconButton/IconButton';
import { NAV_TABS } from '../../config';
import { TOOL_TYPES } from '../../utils/measurementTools/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { measurementToolsActions } from '../../reducers/measurement-tools/measurementToolsSlice';

const MeasurementToolbar: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const { selectedToolType, isMeasurementMode } = useAppSelector((state) => state.measurementTools);

  const visibleTab = useAppSelector((state) => state.globalFlags.visibleTab);
  // Exit measurement mode when the tab is changed.
  useEffect(() => {
    dispatch(measurementToolsActions.setSelectedToolType(undefined));
    // NOTE: We only want this to fire when visibleTab has changed; setSelectedToolType should be essentially static
  }, [visibleTab, dispatch]);

  // Clear all measurements when exiting measurement mode.
  useEffect(() => {
    if (selectedToolType === undefined) {
      dispatch(measurementToolsActions.clearMeasurements());
    }
    // NOTE: We only want this to fire when selectedToolType has changed; clearMeasurements should be essentially static
  }, [selectedToolType, dispatch]);

  return (
    <div
      className={cn('measurement-toolbar', {
        'measurement-toolbar__ct-volume': visibleTab === NAV_TABS.ctVolumeTab,
        'measurement-mode-active': !!isMeasurementMode,
      })}
    >
      <div
        className={cn('measurement-toolbar-selected', {})}
        onClick={() => {
          // If in any measurement mode drop out of it, otherwise switch to ruler.
          dispatch(measurementToolsActions.setSelectedToolType(isMeasurementMode ? undefined : TOOL_TYPES.Ruler));
        }}
        title=""
      >
        <span className="measurement-toolbar-selected_button__icon">
          <IconButton
            selected={isMeasurementMode}
            color="toggle"
            icon={
              selectedToolType === TOOL_TYPES.Ruler
                ? 'straightRuler'
                : selectedToolType === TOOL_TYPES.Ellipse
                ? 'ellipseRuler'
                : 'ruler'
            }
            onClick={() => {}}
          />
        </span>
      </div>
      <div className="measurement-toolbar__popup">
        <div className="measurement-toolbar__popup-items">
          <div
            className={cn('straight-ruler-button measurement-toolbar__item', {
              'measurement-toolbar__item-selected': selectedToolType === TOOL_TYPES.Ruler,
            })}
            onClick={() => {
              dispatch(measurementToolsActions.setSelectedToolType(TOOL_TYPES.Ruler));
            }}
          >
            <span className="straight-ruler-button__icon ">
              <IconButton
                selected={selectedToolType === TOOL_TYPES.Ruler}
                color="toggle"
                icon="straightRuler"
                onClick={() => {}}
              />
            </span>
            <span className="measurement-toolbar__item-label">Ruler</span>
          </div>

          <div
            className={cn('ellipse-ruler-button measurement-toolbar__item', {
              'measurement-toolbar__item-selected': selectedToolType === TOOL_TYPES.Ellipse,
            })}
            onClick={() => {
              dispatch(measurementToolsActions.setSelectedToolType(TOOL_TYPES.Ellipse));
            }}
          >
            <span className="ellipse-ruler-button__icon">
              <IconButton
                selected={selectedToolType === TOOL_TYPES.Ellipse}
                color="toggle"
                icon="ellipseRuler"
                onClick={() => {}}
              />
            </span>
            <span className="measurement-toolbar__item-label">Ellipse</span>
          </div>

          <div
            className={cn('visibility-off-button measurement-toolbar__item')}
            onClick={() => dispatch(measurementToolsActions.clearMeasurements())}
          >
            <span className="visibility-off-button__icon">
              <IconButton selected={false} color="toggle" icon="visibilityOffIcon" onClick={() => {}} />
            </span>
            <span className="measurement-toolbar__item-label">Clear all</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeasurementToolbar;
