import { forEach } from 'lodash';
import React, { useState, useRef } from 'react';
import { PlaqueCategories, PlaqueMeasurements } from '../../context/types';
import {
  fetchPlaqueMeasurementPerVessel,
  fetchSignificantLesions,
  fetchVesselMaximumStenosis,
  fetchVulnerablePlaqueVessel,
} from '../../utils/api';
import { useVesselStateSelector } from '../../selectors/vessels';
import cn from 'classnames';
import { STENOSIS } from '../../config';
import { Loader } from '../Loader/Loader';
import { useEffectCustomDeps } from '../../hooks/useEffectCustomDeps';
import { useAppSelector } from '../../hooks';

const DEFAULT_PLAQUE_VALUES: PlaqueCategories = {
  total: undefined,
  lap: undefined,
  ca: undefined,
  nca: undefined,
};

const DEFAULT_PLAQUE_MEASUREMENT_VALUES: PlaqueMeasurements = {
  volume: DEFAULT_PLAQUE_VALUES,
  burden: DEFAULT_PLAQUE_VALUES,
};

export const VesselMeasurement: React.FunctionComponent = () => {
  const versionHead = useAppSelector((state) => state.store.versionHead);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const patientID = useAppSelector((state) => state.patient.patientID);
  const { vesselData, selectedVesselName } = useVesselStateSelector();

  const [plaqueMeasurementsVessel, setPlaqueMeasurementsVessel] = useState<PlaqueMeasurements>(
    DEFAULT_PLAQUE_MEASUREMENT_VALUES
  );

  const [loading, setLoading] = useState(false);
  const debounceEventRef = useRef<any>(0);

  const [maxStenosis, setMaxStenosis] = useState<string | undefined>(undefined);
  const [vulnerablePlaque, setVulnerablePlaque] = useState<string | undefined>(undefined);
  const [significantLesions, setSignificantLesions] = useState<number | undefined>(undefined);

  useEffectCustomDeps(() => {
    if (!patientID || !runID || !selectedVesselName) {
      return;
    }
    setLoading(true);
    clearTimeout(debounceEventRef.current);
    debounceEventRef.current = setTimeout(() => {
      fetchVesselMaximumStenosis(patientID, runID, versionHead)
        .then((res) => {
          //timeout to ensure that the query gets the
          //current version data while the value of stenosis is saved.
          forEach(res, (value, key) => {
            if (key === selectedVesselName) {
              setMaxStenosis(value);
            }
          });
        })
        .catch(() => {
          setMaxStenosis(undefined);
        });
    }, 1000);
    debounceEventRef.current = setTimeout(() => {
      fetchPlaqueMeasurementPerVessel(patientID, runID, selectedVesselName)
        .then((res: PlaqueMeasurements) => {
          let plaqueVolume: PlaqueCategories = { ...DEFAULT_PLAQUE_VALUES };
          let plaqueBurden: PlaqueCategories = { ...DEFAULT_PLAQUE_VALUES };
          forEach(res.volume, (value, key) => {
            if (Number(value) === 0) return;
            plaqueVolume[key as keyof PlaqueCategories] = Number(value).toFixed(2);
          });
          forEach(res.burden, (value, key) => {
            if (Number(value) === 0) return;
            plaqueBurden[key as keyof PlaqueCategories] = (Number(value) * 100).toFixed(2);
          });
          res &&
            setPlaqueMeasurementsVessel({
              volume: plaqueVolume,
              burden: plaqueBurden,
            });
          setLoading(false);
        })
        .catch(() => {
          setPlaqueMeasurementsVessel(DEFAULT_PLAQUE_MEASUREMENT_VALUES);
          setLoading(false);
        });
    }, 1000);
    debounceEventRef.current = setTimeout(() => {
      fetchVulnerablePlaqueVessel(patientID, runID, selectedVesselName, versionHead)
        .then((res) => {
          setVulnerablePlaque(res);
        })
        .catch(() => {
          setVulnerablePlaque(undefined);
        });
    }, 1000);
    debounceEventRef.current = setTimeout(() => {
      fetchSignificantLesions(patientID, runID, selectedVesselName, versionHead)
        .then((res) => {
          if (res > 0) setSignificantLesions(res);
        })
        .catch(() => {
          setSignificantLesions(undefined);
        });
    }, 1000);
  }, [patientID, runID, selectedVesselName, vesselData]);

  return (
    <>
      <div className="vessel-measurements">
        <div className="vessel-measurement__title">
          <span>
            {selectedVesselName ? (
              <span className="vessel-measurement-vessel-name">{selectedVesselName}</span>
            ) : (
              <span>-</span>
            )}{' '}
            Summary
          </span>

          {loading && <Loader inline />}
        </div>
        <div
          className={cn(
            'vessel-measurements__section',
            maxStenosis === STENOSIS.severe || maxStenosis === STENOSIS.occluded
              ? 'vessel-measurements__section--risk-high'
              : '',
            maxStenosis === STENOSIS.moderate ? 'vessel-measurements__section--risk-medium' : '',
            maxStenosis === STENOSIS.mild || maxStenosis === STENOSIS.min
              ? 'vessel-measurements__section--risk-low'
              : ''
          )}
        >
          <div className="vessel-measurement__section-value">
            {maxStenosis ? <span>{maxStenosis}</span> : <span>-</span>}
          </div>
          <div className="vessel-measurement__section-label">Maximum Stenosis</div>
        </div>
        <div className="vessel-measurements__section">
          <div className="vessel-measurement__section-value">
            {significantLesions !== undefined ? <span>{significantLesions}</span> : <span>-</span>}
          </div>
          <div className="vessel-measurement__section-label">Significant Lesions</div>
        </div>
        <div className="vessel-measurements__section">
          <div className="vessel-measurement__section-value">
            {vulnerablePlaque ? <span>{vulnerablePlaque}</span> : <span>-</span>}
          </div>
          <div className="vessel-measurement__section-label">Vulnerable Plaque</div>
        </div>
        <div className="vessel-measurements__section">
          <div className="vessel-measurement__section-value">
            {plaqueMeasurementsVessel.volume.total && !plaqueMeasurementsVessel.burden.total && (
              <span>{plaqueMeasurementsVessel.volume.total} mm² (-%)</span>
            )}
            {!plaqueMeasurementsVessel.volume.total && plaqueMeasurementsVessel.burden.total && (
              <span>-mm² ({plaqueMeasurementsVessel.burden.total}%)</span>
            )}
            {plaqueMeasurementsVessel.volume.total && plaqueMeasurementsVessel.burden.total ? (
              <span>
                {plaqueMeasurementsVessel.volume.total} mm² ({plaqueMeasurementsVessel.burden.total}%)
              </span>
            ) : (
              <span>-</span>
            )}
          </div>
          <div className="vessel-measurement__section-label">Total Plaque Volume (Burden)</div>
        </div>
        <div className="vessel-measurements__section">
          <div className="vessel-measurement__section-value">
            {plaqueMeasurementsVessel.volume.ca && !plaqueMeasurementsVessel.burden.ca && (
              <span>{plaqueMeasurementsVessel.volume.ca} mm² (-%)</span>
            )}
            {!plaqueMeasurementsVessel.volume.ca && plaqueMeasurementsVessel.burden.ca && (
              <span>-mm² ({plaqueMeasurementsVessel.burden.ca}%)</span>
            )}
            {plaqueMeasurementsVessel.volume.ca && plaqueMeasurementsVessel.burden.ca ? (
              <span>
                {plaqueMeasurementsVessel.volume.ca} mm² ({plaqueMeasurementsVessel.burden.ca}%)
              </span>
            ) : (
              <span>-</span>
            )}
          </div>
          <div className="vessel-measurement__section-label">Calcified Plaque Volume (Burden)</div>
        </div>
        <div className="vessel-measurements__section">
          <div className="vessel-measurement__section-value">
            {plaqueMeasurementsVessel.volume.nca && !plaqueMeasurementsVessel.burden.nca && (
              <span>{plaqueMeasurementsVessel.volume.nca} mm² (-%)</span>
            )}
            {!plaqueMeasurementsVessel.volume.nca && plaqueMeasurementsVessel.burden.nca && (
              <span>-mm² ({plaqueMeasurementsVessel.burden.nca}%)</span>
            )}
            {plaqueMeasurementsVessel.volume.nca && plaqueMeasurementsVessel.burden.nca ? (
              <span>
                {plaqueMeasurementsVessel.volume.nca} mm² ({plaqueMeasurementsVessel.burden.nca}
                %)
              </span>
            ) : (
              <span>-</span>
            )}
          </div>
          <div className="vessel-measurement__section-label">Non-calcified Plaque Volume (Burden)</div>
        </div>
        <div className="vessel-measurements__section">
          <div className="vessel-measurement__section-value">
            {plaqueMeasurementsVessel.volume.lap && !plaqueMeasurementsVessel.burden.lap && (
              <span>{plaqueMeasurementsVessel.volume.lap} mm² (-%)</span>
            )}
            {!plaqueMeasurementsVessel.volume.lap && plaqueMeasurementsVessel.burden.lap && (
              <span>-mm² ({plaqueMeasurementsVessel.burden.lap}%)</span>
            )}
            {plaqueMeasurementsVessel.volume.lap && plaqueMeasurementsVessel.burden.lap ? (
              <span>
                {plaqueMeasurementsVessel.volume.lap} mm² ({plaqueMeasurementsVessel.burden.lap}
                %)
              </span>
            ) : (
              <span>-</span>
            )}
          </div>
          <div className="vessel-measurement__section-label">LAP Volume (Burden)</div>
        </div>
      </div>
    </>
  );
};
export default VesselMeasurement;
