import {
  LesionInterface,
  PatientSummaryInterface,
  StudyInterface,
  VesselSegmentSummariesInterface,
  VesselSegmentSummaryInterface,
  VesselsInterface,
  VesselSliceInterface,
  VesselSlicesInterface,
} from './types';

/**
 * Validate the study data from the new study endpoint.
 */
export const validateStudyData = (study: StudyInterface) => {
  const { patientAge } = study;
  let warning: string[] = [];

  ['key', 'patientSex', 'patientCipher', 'studyDate'].forEach((key) => {
    const k = key as keyof typeof study;
    if (study[k] === null || study[k] === undefined || study[k] === '') {
      warning.push('new study endpoint ', `missing or invalid ${key} in study data`);
    }
  });

  if (!patientAge || patientAge === null || patientAge === '' || parseInt(patientAge) <= 0) {
    warning.push('new study endpoint ', 'missing or invalid patientAge in study data');
  }

  return warning;
};

export const validatePatientSummary = (patientSummary: PatientSummaryInterface) => {
  let warning: string[] = [];

  const requiredKeys = [
    'vulnerability',
    'sis',
    'stent',
    'nonDiagnostic',
    'graft',
    'cadRadsCategory',
    'cadRadsCategory',
    'cadRadsModifiers',
    'lesionCount',
    'stenosisMaxSegment',
    'priority',
    'vulnerabilityCount',
    'lowAttenuationPlaqueBiomarkerCount',
    'napkinRingSignBiomarkerCount',
    'positiveRemodellingIndexBiomarkerCount',
    'spottyCalcificationBiomarkerCount',
    'plaqueCompositionNoneCount',
    'plaqueCompositionNCACount',
    'plaqueCompositionMixedCount',
    'plaqueCompositionCACount',
    'calcifiedPlaqueVolume',
    'lowAttenuationPlaqueVolume',
    'nonCalcifiedPlaqueVolume',
    'calcifiedPlaqueBurden',
    'lowAttenuationPlaqueBurden',
    'nonCalcifiedPlaqueBurden',
  ];
  validateRequiredFields(requiredKeys, patientSummary, 'Patient summary', warning);

  const numberFieldKeys = [
    'sis',
    'priority',
    'vulnerabilityCount',
    'lowAttenuationPlaqueBiomarkerCount',
    'napkinRingSignBiomarkerCount',
    'positiveRemodellingIndexBiomarkerCount',
    'spottyCalcificationBiomarkerCount',
    'plaqueCompositionNoneCount',
    'plaqueCompositionNCACount',
    'plaqueCompositionMixedCount',
    'plaqueCompositionCACount',
    'calcifiedPlaqueVolume',
    'lowAttenuationPlaqueVolume',
    'nonCalcifiedPlaqueVolume',
    'calcifiedPlaqueBurden',
    'lowAttenuationPlaqueBurden',
    'nonCalcifiedPlaqueBurden',
  ];
  validateNumberFields(numberFieldKeys, patientSummary, 'Patient summary', warning);

  const booleanFieldKeys = ['vulnerability', 'stent', 'nonDiagnostic', 'graft'];
  validateBooleanFields(booleanFieldKeys, patientSummary, 'Patient summary', warning);

  const stringFieldKeys = ['cadRadsCategory', 'cadRadsModifiers', 'stenosisMaxSegment'];
  validateStringFields(stringFieldKeys, patientSummary, 'Patient summary', warning);

  return warning;
};

/**
 * Validate the vessel data from the new study endpoint.
 */
export const validateVesselData = (vessel: VesselsInterface) => {
  let warning: string[] = [];

  const requiredKeys = [
    'vesselSlicesNo',
    'vulnerability',
    'stent',
    'nonDiagnostic',
    'graft',
    'lowAttenuationPlaqueBiomarkerCount',
    'positiveRemodellingIndexBiomarkerCount',
    'spottyCalcificationBiomarkerCount',
    'napkinRingSignBiomarkerCount',
    'lesionCount',
    'vulnerabilityCount',
    'plaqueCompositionNoneCount',
    'plaqueCompositionCACount',
    'plaqueCompositionNCACount',
    'plaqueCompositionMixedCount',
    'stenosisMax',
    'diseaseRange',
    'vertexToSliceMapping',
  ];

  validateRequiredFields(requiredKeys, vessel, 'Vessel data', warning);

  const numberFieldKeys = [
    'vesselSlicesNo',
    'lowAttenuationPlaqueBiomarkerCount',
    'positiveRemodellingIndexBiomarkerCount',
    'spottyCalcificationBiomarkerCount',
    'napkinRingSignBiomarkerCount',
    'lesionCount',
    'vulnerabilityCount',
    'plaqueCompositionNoneCount',
    'plaqueCompositionCACount',
    'plaqueCompositionNCACount',
    'plaqueCompositionMixedCount',
  ];
  validateNumberFields(numberFieldKeys, vessel, 'Vessel data', warning);

  // const stringFieldKeys = ['stenosisMax', 'priorityLesionId'];
  // validateStringFields(stringFieldKeys, vessel, 'Vessel data', warning);

  const booleanFieldKeys = ['vulnerability', 'stent', 'nonDiagnostic', 'graft'];
  validateBooleanFields(booleanFieldKeys, vessel, 'Vessel data', warning);

  const arrayFieldKeys = ['diseaseRange', 'vertexToSliceMapping'];
  validateArrayFields(arrayFieldKeys, vessel, 'Vessel data', warning);

  return warning;
};

/**
 * Validate the vessel slice data from the new study endpoint.
 */
export const validateVesselSliceData = (vesselSlice: VesselSlicesInterface) => {
  let warning: string[] = [];

  //Centerline is not required until we have that value in the new endpoint

  const requiredKeys = ['severityIndicator', 'relatedLesions', 'stenosis'];
  validateRequiredFields(requiredKeys, vesselSlice.vesselSlices[0], 'Vessel Slice data', warning);

  const numberFieldKeys = ['severityIndicator', 'stenosis'];
  validateNumberFields(numberFieldKeys, vesselSlice.vesselSlices[0], 'Vessel Slice data', warning);

  const arrayFieldKeys = ['relatedLesions'];
  validateArrayFields(arrayFieldKeys, vesselSlice.vesselSlices[0], 'Vessel Slice data', warning);

  return warning;
};

/**
 * Validate the vessel slice data from the new study endpoint.
 */
export const validateVesselSegmentSummary = (vesselSegmentSummaries: VesselSegmentSummariesInterface) => {
  let warning: string[] = [];
  const requiredKeys = ['key', 'vesselSlicesRange'];
  validateRequiredFields(
    requiredKeys,
    vesselSegmentSummaries.vesselSegmentSummaries[0],
    'Vessel Segment Summary data',
    warning
  );

  const arrayFieldKeys = ['vesselSlicesRange'];
  validateArrayFields(
    arrayFieldKeys,
    vesselSegmentSummaries.vesselSegmentSummaries[0],
    'Vessel Segment Summary data',
    warning
  );

  const stringFieldKeys = ['key'];
  validateStringFields(
    stringFieldKeys,
    vesselSegmentSummaries.vesselSegmentSummaries[0],
    'Vessel Segment Summary data',
    warning
  );

  return warning;
};

export const validateLesionData = (lesions: LesionInterface[]) => {
  let warning: string[] = [];

  if (lesions.length === 0) {
    warning.push('new study endpoint ', `missing or invalid lesions in study data`);
    return warning;
  }

  const lesion = lesions[0].lesions[0];
  const keys = Object.keys(lesion);

  const requiredKeys = ['vulnerability', 'priorityPosition', 'plaqueComposition', 'vesselSlicesRange', 'stenosisMax'];

  requiredKeys.forEach((key) => {
    if (!keys.includes(key)) {
      warning.push('new study endpoint ', `missing or invalid ${key} in lesion data`);
    }
  });

  const numberFieldKeys = ['priorityPosition', 'stenosisMax'];

  numberFieldKeys.forEach((key) => {
    if (typeof lesion[key as keyof typeof lesion] !== 'number') {
      warning.push('new study endpoint ', `invalid ${key} data type in lesion data`);
    }
  });

  const booleanFieldKeys = ['vulnerability'];

  booleanFieldKeys.forEach((key) => {
    if (typeof lesion[key as keyof typeof lesion] !== 'boolean') {
      warning.push('new study endpoint ', `invalid ${key} data type in lesion data`);
    }
  });

  const stringFieldKeys = ['plaqueComposition'];

  stringFieldKeys.forEach((key) => {
    if (typeof lesion[key as keyof typeof lesion] !== 'string') {
      warning.push('new study endpoint ', `invalid ${key} data type in lesion data`);
    }
  });

  return warning;
};

const validateRequiredFields = (
  requiredKeys: string[],
  data: PatientSummaryInterface | VesselsInterface | VesselSliceInterface | VesselSegmentSummaryInterface,
  type: string,
  warning: string[] = []
) => {
  const keys = Object.keys(data);
  requiredKeys.forEach((key) => {
    if (!keys.includes(key)) {
      warning.push('new study endpoint ', `missing or invalid ${key} in ${type}`);
    }
  });

  return warning;
};

const validateNumberFields = (
  numberFieldKeys: string[],
  data: PatientSummaryInterface | VesselsInterface | VesselSliceInterface,
  type: string,
  warning: string[] = []
) => {
  numberFieldKeys.forEach((key) => {
    if (typeof data[key as keyof typeof data] !== 'number') {
      warning.push('new study endpoint ', `invalid ${key} data type in ${type}`);
    }
  });

  return warning;
};

const validateBooleanFields = (
  booleanFieldKeys: string[],
  data: PatientSummaryInterface | VesselsInterface | VesselSliceInterface,
  type: string,
  warning: string[] = []
) => {
  booleanFieldKeys.forEach((key) => {
    if (typeof data[key as keyof typeof data] !== 'boolean') {
      warning.push('new study endpoint ', `invalid ${key} data type in ${type}`);
    }
  });
  return warning;
};

const validateStringFields = (
  stringFieldKeys: string[],
  data: PatientSummaryInterface | VesselsInterface | VesselSliceInterface | VesselSegmentSummaryInterface,
  type: string,
  warning: string[] = []
) => {
  stringFieldKeys.forEach((key) => {
    if (typeof data[key as keyof typeof data] !== 'string') {
      warning.push('new study endpoint ', `invalid ${key} data type in ${type}`);
    }
  });
  return warning;
};

const validateArrayFields = (
  arrayFieldKeys: string[],
  data: PatientSummaryInterface | VesselsInterface | VesselSliceInterface | VesselSegmentSummaryInterface,
  type: string,
  warning: string[] = []
) => {
  arrayFieldKeys.forEach((key) => {
    if (!Array.isArray(data[key as keyof typeof data])) {
      warning.push('new study endpoint ', `invalid ${key} data type in ${type}`);
    }
  });
  return warning;
};
