import macro from '../SafeMacro';
import vtkInteractorStyleMPRSlice from './vtkInteractorStyleMPRSlice';
import vtkCoordinate from 'vtk.js/Sources/Rendering/Core/Coordinate';
import vtkMatrixBuilder from 'vtk.js/Sources/Common/Core/MatrixBuilder';
import CustomBase, { InteractionOperations } from './Manipulators/customBase';
import CustomZoom from './Manipulators/customZoom';
import CustomPan from './Manipulators/customPan';
import CustomWindowLevel from './Manipulators/customWindowLevel';
import CustomSlice from './Manipulators/customSlice';
import { displayToWorldFromXY } from './Utils';
// Force the loading of HttpDataAccessHelper to support gzip decompression
import 'vtk.js/Sources/IO/Core/DataAccessHelper/HttpDataAccessHelper';
import { vtkApi, vtkPublicAPI, vtkModel, vtkEvent, ReferenceLine, Crosshairs } from '../ReactVTKJSTypes';
import { vec2, vec3 } from 'gl-matrix';
import { XYCoords } from '../../../../reducers/vesselData/types';
import * as PIXI from 'pixi.js-legacy';

export const operations = {
  ROTATE_CROSSHAIRS: 0,
  MOVE_CROSSHAIRS: 1,
  MOVE_REFERENCE_LINE: 2,
  PAN: 3,
};

// ----------------------------------------------------------------------------
// Global methods
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// vtkInteractorStyleRotatableMPRCrosshairs methods
// ----------------------------------------------------------------------------

function addCustomInteractor(publicAPI: vtkPublicAPI, model: vtkModel) {
  // This api is initially enabled.
  publicAPI.disabled = false;

  /**
   * Get if the crosshair position is being changed; note rotating the crosshairs doesn't actually change it's position.
   */
  publicAPI.movingCrosshairs = () => {
    return (
      model.operation &&
      (model.operation.type === operations.MOVE_CROSSHAIRS || model.operation.type === operations.MOVE_REFERENCE_LINE)
    );
  };

  /**
   * Our custom handler for mouse interactions. This is called when a button is first pressed
   * with the button number (or 0 when the mouse is being moved). Examine the button states
   * in the model to identify which buttons are currently pressed. isDoubleClick is true if
   * the same mouse button is being clicked a second time in quick succession.
   */
  publicAPI.customHandleMouse = (callData: vtkEvent, button: number, isDoubleClick: boolean) => {
    // Block all custom interactions if the crosshair is being adjusted.
    if (model.operation && model.operation.type !== null) {
      return;
    }

    // Fire the onDoubleClick callback?
    if (isDoubleClick && model.onDoubleClick) {
      model.onDoubleClick(button);
      return;
    }

    // Start zoom when both left and right are down.
    if ((button === 1 && model.rightMouse) || (button === 3 && model.leftMouse)) {
      CustomZoom.onStart(publicAPI, model, callData);
    }
    // Start window level change.
    else if (button === 2) {
      CustomWindowLevel.onStart(publicAPI, model, callData);
    }
    // Start pan.
    else if (button === 3 && !model.leftMouse) {
      CustomPan.onStart(publicAPI, model, callData);
    }
    // Start slice change.
    else if (button === 1 && !model.rightMouse) {
      if (model.interactionOperation === InteractionOperations.NONE) {
        CustomSlice.onStart(publicAPI, model, callData);
      }
    }

    // Perform the current interaction operation (if not InteractionOperations.NONE).
    switch (model.interactionOperation) {
      case InteractionOperations.ZOOM:
        CustomZoom.onMove(publicAPI, model, callData);
        break;
      case InteractionOperations.PAN:
        CustomPan.onMove(publicAPI, model, callData);
        break;
      case InteractionOperations.WINDOW_LEVEL:
        if (model.measurementToggleActive) break;
        CustomWindowLevel.onMove(publicAPI, model, callData);
        break;
      case InteractionOperations.SLICE:
        if (model.measurementToggleActive) break;
        CustomSlice.onMove(publicAPI, model, callData);
        break;
      default:
        break;
    }
  };
  // Inherit from the slice interactor to enable the scroll wheel to change the slice value.
  vtkInteractorStyleMPRSlice.extend(publicAPI, model, {});

  // Initialize the custom manipulators.
  CustomBase.initialize(publicAPI, model);
  CustomZoom.initialize(publicAPI, model);
  CustomPan.initialize(publicAPI, model);
  CustomSlice.initialize(publicAPI, model);

  // Object specific methods
  vtkInteractorStyleRotatableMPRCrosshairs(publicAPI, model);

  // Finalize the custom manipulators.
  CustomBase.finalize(publicAPI, model);
}

function vtkInteractorStyleRotatableMPRCrosshairs(publicAPI: vtkPublicAPI, model: vtkModel) {
  // Set our className
  model.classHierarchy.push('vtkInteractorStyleRotatableMPRCrosshairs');

  function selectOperation(callData: vtkEvent) {
    const { apis, apiIndex, lineGrabDistance } = model;
    if (!apis || apiIndex === undefined) return;
    const thisApi = apis[apiIndex];
    let { position } = callData;

    // If the api is currently disabled we don't want to allow any interaction with it.
    if (thisApi.disabled) return;

    // Block mouse and keyboard input to the other views.
    disableOtherApis(true);

    const crosshairs: Crosshairs | undefined = thisApi.crosshairs;
    if (crosshairs?.referenceLines == null || crosshairs?.point == null) {
      console.error('operation requires valid crosshairs info');
      return;
    }

    const distanceFromCenter = vec2.distance(crosshairs.point, [position.x, position.y]);
    if (distanceFromCenter < crosshairs.centerRadius) {
      // Click on center -> move the crosshairs.
      model.operation = { type: operations.MOVE_CROSSHAIRS };
      crosshairs.referenceLines?.forEach((line: ReferenceLine) => {
        line.selected = true;
      });
      return;
    }

    // Map to the click point to the same coords as the SVG.
    const p = { x: position.x, y: crosshairs.viewHeight - position.y };

    // Check each rotation handle
    for (let lineIndex = 0; lineIndex < crosshairs.referenceLines.length; lineIndex++) {
      const line = crosshairs.referenceLines[lineIndex];
      const lineRotateHandles = line.rotateHandles;
      const { points } = lineRotateHandles;

      for (let i = 0; i < points.length; i++) {
        const distance = vec2.distance([points[i].x, points[i].y], [p.x, p.y]);
        if (distance < lineGrabDistance) {
          model.operation = {
            type: operations.ROTATE_CROSSHAIRS,
            prevPosition: position,
          };
          lineRotateHandles.selected = true;
          return;
        }
      }
    }

    const distanceFromFirstLine = distanceFromLine(crosshairs.referenceLines[0], p);
    const distanceFromSecondLine = distanceFromLine(crosshairs.referenceLines[1], p);

    if (distanceFromFirstLine <= lineGrabDistance || distanceFromSecondLine <= lineGrabDistance) {
      // Click on line -> start a rotate of the other planes.
      const selectedLineIndex = distanceFromFirstLine < distanceFromSecondLine ? 0 : 1;
      crosshairs.referenceLines[selectedLineIndex].selected = true;
      crosshairs.referenceLines[selectedLineIndex].active = true;

      // Deactivate other line if active
      const otherLineIndex = selectedLineIndex === 0 ? 1 : 0;
      crosshairs.referenceLines[otherLineIndex].active = false;

      // Set operation data.
      model.operation = {
        type: operations.MOVE_REFERENCE_LINE,
        snapToLineIndex: selectedLineIndex === 0 ? 1 : 0,
      };
      return;
    }

    crosshairs.referenceLines?.forEach((line: ReferenceLine) => {
      line.active = false;
    });
    // Permit mouse and keyboard input on the other views.
    disableOtherApis(false);

    // What is the fallback? Pan? Do nothing for now.
    model.operation = { type: null };
  }

  /**
   * Disable (or enable) mouse and keyboard actions from affecting the other apis (views).
   */
  function disableOtherApis(disable: boolean) {
    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;

    apis.forEach((api: vtkApi, index: number) => {
      if (index !== apiIndex) {
        // Work out if we need to redraw the crosshairs on this view.
        var redraw = false;

        // Ensure all the crosshair controls on the other line are shown as inactive..
        const lines = api.crosshairs?.referenceLines;
        lines?.forEach((line: ReferenceLine) => {
          // The line is not being manipulated.
          if (line.active) {
            line.active = false;
            redraw = true;
          }
          // The mouse is not over the line.
          if (line.selected) {
            line.selected = false;
            redraw = true;
          }
          // The rotate handle is not being manipulated.
          if (line.rotateHandles.active) {
            line.rotateHandles.active = false;
            redraw = true;
          }
          // The mouse is not over the rotate handle.
          if (line.rotateHandles.selected) {
            line.rotateHandles.selected = false;
            redraw = true;
          }
        });

        // Block mouse and keyboard input to the other api.
        api.disabled = disable;

        // Redraw the crosshairs?
        if (redraw) {
          api.refreshView(false, true);
        }
      }
    });
  }

  function distanceFromLine(line: ReferenceLine, point: XYCoords) {
    const [a, b] = line.points;
    const c = point;

    // Get area from all 3 points...
    const areaOfTriangle = Math.abs((a.x * (b.y - c.y) + b.x * (c.y - a.y) + c.x * (a.y - b.y)) / 2);

    // And area also equals 1/2 base * height, where height is the distance!
    // So:
    const base = vec2.distance([a.x, a.y], [b.x, b.y]);
    const height = (2.0 * areaOfTriangle) / base;

    // Note we don't have to worry about finite line length
    // As the lines go across the whole canvas.
    return height;
  }

  function performOperation(callData: vtkEvent) {
    const { operation } = model;
    const { type } = model.operation;

    switch (type) {
      case operations.MOVE_CROSSHAIRS:
        interactorMoveCrosshairs(callData.position);
        break;
      case operations.MOVE_REFERENCE_LINE:
        {
          const snapToLineIndex = operation.snapToLineIndex;
          if (snapToLineIndex != null) {
            const pos = snapPosToLine(callData.position, snapToLineIndex);
            if (pos != null) {
              interactorMoveCrosshairs(pos);
            }
          }
        }
        break;
      case operations.ROTATE_CROSSHAIRS:
        rotateCrosshairs(callData);
        break;
      default:
        break;
    }
  }

  /**
   * Rotate the crosshairs about the current crosshair center. Update the other two views.
   */
  function rotateCrosshairs(callData: vtkEvent) {
    if (model.measurementToggleActive) return;

    const { apis, apiIndex, operation } = model;
    // Get the last mouse position.
    const { prevPosition } = operation;

    // Get the new mouse position.
    const newPosition = callData.position;
    if (!prevPosition || (newPosition.x === prevPosition.x && newPosition.y === prevPosition.y)) {
      // No change, exit early.
      return;
    }

    if (!apis || apiIndex === undefined) return;

    const thisApi = apis[apiIndex];
    // Get the display position [X, Y] of the center of the crosshairs on this view.
    const crosshairs: Crosshairs | undefined = thisApi.crosshairs;
    if (crosshairs == null) return;

    // Get vector from the crosshairs display position to the previous display position.
    const pointToPreviousPosition: vec2 = [0, 0];
    vec2.subtract(pointToPreviousPosition, [prevPosition.x, prevPosition.y], crosshairs.point);

    // Get vector from the crosshairs display position to the new display position.
    const pointToNewPosition: vec2 = [0, 0];
    vec2.subtract(pointToNewPosition, [newPosition.x, newPosition.y], crosshairs.point);

    // Get angle of rotation from previous reference position to the new position.
    let angle = vec2.angle(pointToPreviousPosition, pointToNewPosition);

    // Use the determinant to find the sign of the angle.
    const determinant =
      pointToNewPosition[0] * pointToPreviousPosition[1] - pointToNewPosition[1] * pointToPreviousPosition[0];
    if (determinant < 0) {
      angle *= -1;
    }

    // Axis is the opposite direction of the camera direction for this API.
    const axis = thisApi.getCameraDirection();

    // Rotate the other views.
    apis.forEach((api: vtkApi, index: number) => {
      if (index !== apiIndex) {
        const cameraForApi = api.genericRenderWindow?.getRenderer()?.getActiveCamera();
        if (cameraForApi) {
          const crosshairWorldPosition = api.crosshairWorldPosition;
          const matrix = vtkMatrixBuilder
            .buildFromRadian()
            .translate(crosshairWorldPosition[0], crosshairWorldPosition[1], crosshairWorldPosition[2])
            .rotate(angle, axis as any)
            .translate(-crosshairWorldPosition[0], -crosshairWorldPosition[1], -crosshairWorldPosition[2])
            .getMatrix();
          cameraForApi.applyTransform(matrix);
        }
      }
    });

    // Rotate the crosshair axes (note this is shared between the views so we only need to update it the once).
    const crosshairWorldAxes = thisApi.crosshairWorldAxes;
    const transform = vtkMatrixBuilder.buildFromRadian().rotate(angle, axis as any);
    for (let i = 0; i < 3; i++) {
      transform.apply(crosshairWorldAxes[i]);
    }

    // This view just needs to redraw the crosshairs and the other views just need to redraw the view.
    apis.forEach((api: vtkApi, index: number) => {
      // Render the view and the crosshairs.
      api.refreshView(apiIndex !== index, apiIndex === index);
    });

    operation.prevPosition = newPosition;

    // Fire the callback after the crosshairs are adjusted.
    if (model.onCrosshairsAdjusted) {
      model.onCrosshairsAdjusted();
    }
  }

  function snapPosToLine(position: XYCoords, lineIndex: number) {
    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;
    const thisApi = apis[apiIndex];

    const crosshairs = thisApi.crosshairs;
    if (crosshairs == null || crosshairs.referenceLines == null) return;
    const line = crosshairs.referenceLines[lineIndex];
    const points = line.points;

    // Map to the click point to the same coords as the SVG.
    const p = { x: position.x, y: crosshairs.viewHeight - position.y };

    // Project p onto line to get new crosshair position
    const line0toP: vec2 = [0, 0];
    const line0toline1: vec2 = [0, 0];

    vec2.sub(line0toP, [p.x, p.y], [points[0].x, points[0].y]);
    vec2.sub(line0toline1, [points[1].x, points[1].y], [points[0].x, points[0].y]);

    const magnitudeOfLine = vec2.distance([points[0].x, points[0].y], [points[1].x, points[1].y]);
    const unitVectorAlongLine = [line0toline1[0] / magnitudeOfLine, line0toline1[1] / magnitudeOfLine];
    const dotProduct = vec2.dot(line0toP, line0toline1);
    const distanceAlongLine = dotProduct / magnitudeOfLine;

    const newCenterSVG = [
      points[0].x + unitVectorAlongLine[0] * distanceAlongLine,
      points[0].y + unitVectorAlongLine[1] * distanceAlongLine,
    ];

    // Convert back to display coords.
    return {
      x: newCenterSVG[0],
      y: crosshairs.viewHeight - newCenterSVG[1],
    };
  }

  /**
   * Move the crosshairs (on every view) based on the display coordinates provided for this view.
   * This will:
   * 1) Update the cachedCrosshairsWorldPos value for all views.
   * 2) Refresh the crosshairs SVG for all views and redraw it.
   * 3) Change the slice on the other views (ie move the camera forwards or backwards so that the focal point is
   *    on the same plane as the crosshairs position) and render the view.
   * @param pos { x: number, y: number } The display coordinates
   */
  function interactorMoveCrosshairs(pos: XYCoords) {
    if (model.measurementToggleActive) return;

    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;
    const api = apis[apiIndex];

    // Convert the display position into a world poisition.
    const worldPos = displayToWorldFromXY(api.genericRenderWindow.getRenderer(), pos);
    publicAPI.moveCrosshairs(worldPos, true);

    // Fire the callback if it exists.
    if (model.onCrosshairsMoved) {
      model.onCrosshairsMoved();
    }

    // Fire the callback after the crosshairs are adjusted.
    if (model.onCrosshairsAdjusted) {
      model.onCrosshairsAdjusted();
    }
  }

  function scrollCrosshairs(lineIndex: number, direction: string) {
    if (model.measurementToggleActive) return;

    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;
    const thisApi = apis[apiIndex];

    const crosshairs = thisApi.crosshairs;
    if (crosshairs == null || crosshairs.referenceLines == null) return;
    const otherLineIndex = lineIndex === 0 ? 1 : 0;
    // Transform point to SVG coordinates
    const p = [crosshairs.point[0], crosshairs.viewHeight - crosshairs.point[1]];

    // Get the unit vector to move the line in.

    const linePoints = crosshairs.referenceLines[otherLineIndex].points;
    let lowToHighPoints;

    // If line is horizontal (<1 pix difference in height), move right when scroll forward.
    if (Math.abs(linePoints[0].y - linePoints[1].y) < 1.0) {
      if (linePoints[0].x < linePoints[1].x) {
        lowToHighPoints = [linePoints[0], linePoints[1]];
      } else {
        lowToHighPoints = [linePoints[1], linePoints[0]];
      }
    }
    // If end is higher on screen, scroll moves crosshairs that way.
    else if (linePoints[0].y < linePoints[1].y) {
      lowToHighPoints = [linePoints[0], linePoints[1]];
    } else {
      lowToHighPoints = [linePoints[1], linePoints[0]];
    }

    const unitVector: vec2 = [0, 0];
    vec2.subtract(
      unitVector,
      [lowToHighPoints[1].x, lowToHighPoints[1].y],
      [lowToHighPoints[0].x, lowToHighPoints[0].y]
    );
    vec2.normalize(unitVector, unitVector);

    if (direction === 'forwards') {
      unitVector[0] *= -1;
      unitVector[1] *= -1;
    }

    const displayCoordintateScrollIncrement = getDisplayCoordinateScrollIncrement(crosshairs.point) || 0;
    const newCenterPointSVG = [
      p[0] + unitVector[0] * displayCoordintateScrollIncrement,
      p[1] + unitVector[1] * displayCoordintateScrollIncrement,
    ];

    // Clip to box defined by the crosshairs extent
    let lowX;
    let highX;
    let lowY;
    let highY;

    if (lowToHighPoints[0].x < lowToHighPoints[1].x) {
      lowX = lowToHighPoints[0].x;
      highX = lowToHighPoints[1].x;
    } else {
      lowX = lowToHighPoints[1].x;
      highX = lowToHighPoints[0].x;
    }

    if (lowToHighPoints[0].y < lowToHighPoints[1].y) {
      lowY = lowToHighPoints[0].y;
      highY = lowToHighPoints[1].y;
    } else {
      lowY = lowToHighPoints[1].y;
      highY = lowToHighPoints[0].y;
    }

    newCenterPointSVG[0] = Math.min(Math.max(newCenterPointSVG[0], lowX), highX);
    newCenterPointSVG[1] = Math.min(Math.max(newCenterPointSVG[1], lowY), highY);

    // translate to the display coordinates.
    const displayCoordinate = {
      x: newCenterPointSVG[0],
      y: crosshairs.viewHeight - newCenterPointSVG[1],
    };

    // Move point.
    interactorMoveCrosshairs(displayCoordinate);
  }

  function getDisplayCoordinateScrollIncrement(point: vec2) {
    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;
    const thisApi = apis[apiIndex];
    const { volume, genericRenderWindow } = thisApi;
    const renderer = genericRenderWindow.getRenderer();
    const diagonalWorldLength = volume
      ?.getMapper()
      .getInputData()
      .getSpacing()
      .map((v: number) => v * v)
      .reduce((a: number, b: number) => a + b, 0);

    const dPos = vtkCoordinate.newInstance();
    dPos.setCoordinateSystemToDisplay();
    dPos.setValue([point[0], point[1], 0]);

    const worldPosCenter: vec3 = dPos.getComputedWorldValue(renderer) as vec3;
    dPos.setValue([point[0] + 1, point[1], 0]);

    const worldPosOnePixelOver: vec3 = dPos.getComputedWorldValue(renderer) as vec3;

    const distanceOfOnePixelInWorld = vec3.distance(worldPosCenter, worldPosOnePixelOver);

    return diagonalWorldLength / distanceOfOnePixelInWorld;
  }

  function handlePassiveMouseMove(callData: vtkEvent) {
    if (model.measurementToggleActive) return;

    const { apis, apiIndex, lineGrabDistance } = model;
    if (!apis || apiIndex === undefined) return;
    const thisApi = apis[apiIndex];
    let { position } = callData;

    // If the api is currently disabled we don't want to show any passive interaction.
    if (thisApi.disabled) return;

    // Note: If rotate selected, don't select line.
    const selectedRotateHandles = [false, false];
    const selectedLines = [false, false];

    let shouldUpdate;

    // Check the lines have been initialized (we need to have rendered at least once for this to happen).
    const crosshairs = thisApi.crosshairs;
    if (
      crosshairs == null ||
      crosshairs.referenceLines.length < 2 ||
      !crosshairs.referenceLines[0] ||
      !crosshairs.referenceLines[1]
    ) {
      return;
    }

    const distanceFromCenter = vec2.distance(crosshairs.point, [position.x, position.y]);
    if (distanceFromCenter > crosshairs.centerRadius) {
      // Map to the click point to the same coords as the SVG.
      const p = { x: position.x, y: crosshairs.viewHeight - position.y };

      let selectedRotationHandle = false;
      // Check each rotation handle.
      crosshairs.referenceLines?.forEach((line: ReferenceLine, lineIndex: number) => {
        const { points } = line.rotateHandles;
        for (let i = 0; i < points.length; i++) {
          const distance = vec2.distance([points[i].x, points[i].y], [p.x, p.y]);
          if (distance < lineGrabDistance) {
            selectedRotateHandles[lineIndex] = true;
            selectedRotationHandle = true;
            // Don't need to check both points if one is found to be valid.
            break;
          }
        }
      });

      // If a rotation handle isn't selected, see if we should select lines.
      if (!selectedRotationHandle) {
        const distanceFromFirstLine = distanceFromLine(crosshairs.referenceLines[0], p);
        const distanceFromSecondLine = distanceFromLine(crosshairs.referenceLines[1], p);
        if (distanceFromFirstLine <= lineGrabDistance || distanceFromSecondLine <= lineGrabDistance) {
          const selectedLineIndex = distanceFromFirstLine < distanceFromSecondLine ? 0 : 1;
          selectedLines[selectedLineIndex] = true;
        }
      }
    } else {
      // Highlight both lines.
      selectedLines[0] = true;
      selectedLines[1] = true;
    }

    crosshairs.referenceLines?.forEach((line: ReferenceLine, index: number) => {
      const selected = selectedLines[index];
      const rotateSelected = selectedRotateHandles[index];
      const rotateHandles = line.rotateHandles;

      // If changed, update and flag should update.
      if (line.selected !== selected) {
        line.selected = selected;
        shouldUpdate = true;
      }

      if (rotateHandles.selected !== rotateSelected) {
        rotateHandles.selected = rotateSelected;
        shouldUpdate = true;
      }
    });

    // Render the crosshairs.
    if (shouldUpdate) {
      thisApi.refreshView(false, true);
      // Pass on the new interaction operation via the callback.
      if (model.onInteractionOperation) {
        model.onInteractionOperation(model.interactionOperation, model.operation);
      }
    }
  }

  const superHandleMouseMove = publicAPI.handleMouseMove;
  publicAPI.handleMouseMove = (callData: vtkEvent) => {
    if (model.interactionOperation !== InteractionOperations.NONE) {
      performOperation(callData);
    } else {
      handlePassiveMouseMove(callData);
    }

    if (superHandleMouseMove) {
      superHandleMouseMove(callData);
    }
  };

  // const superHandleLeftButtonPress = publicAPI.handleLeftButtonPress;
  publicAPI.handleLeftButtonPress = (callData: vtkEvent) => {
    if (model.measurementToggleActive) return;
    if (model.volumeActor) {
      selectOperation(callData);
      performOperation(callData);
    }
  };

  publicAPI.superHandleLeftButtonRelease = publicAPI.handleLeftButtonRelease;
  publicAPI.handleLeftButtonRelease = (callData: vtkEvent) => {
    if (model.interactionOperation !== InteractionOperations.NONE) {
      mouseUp(callData);
    } else {
      publicAPI.superHandleLeftButtonRelease(callData);
    }
  };

  publicAPI.setMeasurementToggleActive = (measurementToggleActive: boolean) => {
    model.measurementToggleActive = measurementToggleActive;
  };
  publicAPI.setContainerPIXI = (containerPIXI: PIXI.Container) => {
    model.containerPIXI = containerPIXI;
  };

  function mouseUp(callData: vtkEvent) {
    if (!model.measurementToggleActive) {
      model.operation = { type: null };

      // Unselect lines
      const { apis, apiIndex } = model;
      if (!apis || apiIndex === undefined) return;
      const thisApi = apis[apiIndex];
      const crosshairs = thisApi.crosshairs;
      if (crosshairs == null) return;

      crosshairs.referenceLines?.forEach((line: ReferenceLine) => {
        line.selected = false;
        line.rotateHandles.selected = false;
        line.active = false;
      });

      // Allow mouse and keyboard interaction with the other APIs.
      disableOtherApis(false);

      // Render the crosshairs.
      thisApi.refreshView(false, true);
    }
  }

  const superScrollToSlice = publicAPI.scrollToSlice;
  publicAPI.scrollToSlice = (slice: number) => {
    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;
    const thisApi = apis[apiIndex];
    const crosshairs = thisApi.crosshairs;
    if (crosshairs == null) return;

    let activeLineIndex;
    crosshairs.referenceLines?.forEach((line: ReferenceLine, lineIndex: number) => {
      if (line.active) {
        activeLineIndex = lineIndex;
      }
    });

    if (activeLineIndex === undefined) {
      if (!model.disableNormalMPRScroll) {
        superScrollToSlice(slice);
      }
    } else {
      const direction = publicAPI.getSlice() - slice;
      const scrollDirection = direction > 0 ? 'forwards' : 'backwards';
      scrollCrosshairs(activeLineIndex, scrollDirection);
    }
  };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  operation: { type: null },
  lineGrabDistance: 20,
  disableNormalMPRScroll: false,
  // Optional callback for when the crosshairs are moved: (viewIndex: number) => void
  onCrosshairsMoved: undefined,
  // Optional callback for when the crosshairs have been adjusted on this view (moved or rotated): (viewIndex: number) => void
  onCrosshairsAdjusted: undefined,
  // Optional callback for when the window levels have changed: (viewIndex: number) => void
  onWindowLevelsChanged: undefined,
  // The double click callback: (button: number, viewIndex: number) => void
  onDoubleClick: null,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI: vtkPublicAPI, model: vtkModel, initialValues = {}) {
  // Don't override these values with initialValues, they are just used internally.
  const INTERNAL_VALUES = {
    // Base data.
    ...CustomBase.DEFAULT_VALUES,
    // Zoom data.
    ...CustomZoom.DEFAULT_VALUES,
    // Pan data.
    ...CustomPan.DEFAULT_VALUES,
    // Slice data.
    ...CustomSlice.DEFAULT_VALUES,
  };
  Object.assign(model, { ...DEFAULT_VALUES, ...INTERNAL_VALUES }, initialValues);

  macro.get(publicAPI, model, ['interactionOperation']);
  macro.setGet(publicAPI, model, [
    'callback',
    'apis',
    'apiIndex',
    'viewIndex',
    'onScroll',
    'flipDirection',
    'operation',
    'lineGrabDistance',
    'disableNormalMPRScroll',
  ]);
  macro.set(publicAPI, model, [
    'onCrosshairsMoved',
    'onCrosshairsAdjusted',
    'onWindowLevelsChanged',
    'onDoubleClick',
    'onInteractionOperation',
  ]);
  addCustomInteractor(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkInteractorStyleRotatableMPRCrosshairs');

// ----------------------------------------------------------------------------

export default Object.assign({ newInstance, extend });
