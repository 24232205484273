import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoginState {
  isLogin: boolean;
  refreshToken?: string;
  accessToken?: string;
  error?: string;
}

export const initialLoginState: LoginState = {
  isLogin: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialLoginState,
  reducers: {
    isLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string | undefined>) => {
      state.refreshToken = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
});

export const loginActions = loginSlice.actions;
