import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { studyActions } from './studySlice';
import { StudyData } from '../../context/types';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { fetchWebData, useNeedsLoading } from '../initialLoad/utils';

const loaderName = InitialLoader.STUDY;

/**
 * Load the study from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function loadStudy(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  return fetchWebData<StudyData>(unifiedId, dispatch, initialLoad, loaderName, (data) => {
    dispatch(studyActions.setStudyData(data));
  });
}

/**
 * A component to load the initial study data for a study and add it to the store.
 */
export const StudyLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useNeedsLoading(loaderName);

  useEffect(() => {
    if (unifiedId && needsLoading) {
      loadStudy(unifiedId, dispatch, true);
    }
  }, [unifiedId, needsLoading, dispatch]);

  return null;
};
