import React from 'react';
import { customClassCheck } from '../../../utils/shared';

interface DropdownMenuItemProps {
  id?: string;
  text: string;
  title?: string;
  menuItemClassName?: string;
  onClick: () => void;
}

export const DropdownMenuItem: React.FunctionComponent<DropdownMenuItemProps> = ({
  id,
  text,
  title,
  menuItemClassName,
  onClick,
}) => {
  return (
    <li className={`dropdown-menu__item${customClassCheck(menuItemClassName)}`}>
      <button id={id} type="button" title={title} onClick={onClick} className="dropdown-menu__item-button">
        {text}
      </button>
    </li>
  );
};
