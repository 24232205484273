// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GlobalLoaderState {
  show: boolean;
  message?: string;
}

export const getInitialGlobalLoaderState = (): GlobalLoaderState => {
  return {
    show: false,
  };
};

export const globalLoaderSlice = createSlice({
  name: 'globalLoader',
  initialState: getInitialGlobalLoaderState(),
  reducers: {
    show: (state, action: PayloadAction<string | undefined>) => {
      state.show = true;
      state.message = action.payload;
    },
    hide: (state) => {
      state.show = false;
      state.message = undefined;
    },
  },
});

export const globalLoaderActions = globalLoaderSlice.actions;
