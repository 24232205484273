import React, { ChangeEvent, useCallback } from 'react';
import Button from '../../components/Button/Button';
import PageTitle from '../../components/PageTitle/PageTitle';
import { TextInput } from '../../components/reusable/TextInput/TextInput';
import { useDashboardDispatch, useDashboardSelector } from '../../dashboardHooks';
import { NavBar } from '../../components/NavBar/NavBar';
import { ReleaseVersionLoader, updateReleaseVersion } from '../../reducers/ReleaseVersion/releaseVersionLoader';
import { releaseVersionActions } from '../../reducers/ReleaseVersion/releaseVersionSlice';

const Settings: React.FunctionComponent = () => {
  const dispatch = useDashboardDispatch();
  const serviceVersionList = useDashboardSelector((state) => state.releaseVersion.content);

  const handleUpdate = useCallback((serviceName, serviceVersion) => {
    if (!serviceName || !serviceVersion) {
      return;
    }
    updateReleaseVersion(serviceName, serviceVersion);
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, serviceName) => {
      const value = event.target.value;

      const newValue =
        serviceVersionList &&
        serviceVersionList.map((service) => {
          if (service.serviceName === serviceName) {
            return { ...service, serviceVersion: value };
          }
          return service;
        });

      dispatch(releaseVersionActions.setReleaseVersion({ content: newValue, udiVersion: '', releaseVersion: '' }));
    },

    [dispatch, serviceVersionList]
  );

  return (
    <>
      <ReleaseVersionLoader />
      <PageTitle title="Settings" />
      <div className="settings" id="page-settings">
        <div className="topbar topbar--single-col">
          <NavBar />
        </div>
        <div className="settings__content">
          <h1 className="settings__header">Settings</h1>
          <div className="settings__versions-section">
            <h2>Service versions list</h2>
            {serviceVersionList !== null &&
              Object.entries(serviceVersionList).map(([key, serviceVersionList]) => {
                return (
                  <div className="versions-section__content" key={serviceVersionList.serviceName}>
                    <h3 className="versions-section__header">{serviceVersionList.serviceName}</h3>
                    <TextInput
                      type="text"
                      value={serviceVersionList.serviceVersion}
                      onChange={(event) => handleInputChange(event, serviceVersionList.serviceName)}
                      key={'version-input-' + serviceVersionList.serviceName}
                    />
                    <Button
                      theme="primary"
                      onClick={() => {
                        handleUpdate(serviceVersionList.serviceName, serviceVersionList.serviceVersion);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
