import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';

const Testing: React.FunctionComponent = () => {
  // TODO: Do we still need this page?

  // const callback = useCallback(() => {
  //   console.log('clicked');
  // }, []);
  // const filePromise = new Promise(async (res, rej) => {
  //   res(await getH5('ENV_005994/20201018_2/LAD/cpr/image/0'));
  // }).then((data) => {
  //   console.log(data.get('data').value);
  // });

  return (
    <>
      <PageTitle title="testing" />
    </>
  );
};

export default Testing;
