import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as ArtryaLogo } from '../../assets/logos/SalixLogoReverse.svg';
import { DashboardDataSetResponse } from '../../context/types';
import { useDashboardSelector } from '../../dashboardHooks';
import { AssessmentNav } from './PageNavBars/AssessmentNav';
import { AdminMenu } from './PageNavBars/AdminMenu';
import { BasicNav } from './PageNavBars/BasicNav';

export const NavBar: React.FunctionComponent = () => {
  const { user } = useDashboardSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const onDashboardPage = location.pathname === '/';
  const onAssessmentPage = location.pathname.includes('/study');
  const onSettingsPage = location.pathname.includes('/settings');
  const dashboardData = useDashboardSelector((state) => state.dashboard.studies);

  // Update the studyReviewCount based on the current dashboard studies and user info.
  const studyReviewCount = useMemo(() => {
    if (!dashboardData) return 0;
    const newReviewCount = Object.entries(dashboardData as DashboardDataSetResponse).filter(
      ([key, s]) => !!s.report_active_review && s.report_active_review.assignee.email === user.email
    ).length;
    return newReviewCount;
  }, [dashboardData, user]);

  const returnToDashboard = useCallback(async () => {
    navigate('/');
  }, [navigate]);

  const salixLogo = <ArtryaLogo />;
  const salixLogoLink = (
    <button type="button" onClick={() => returnToDashboard()}>
      <ArtryaLogo />
    </button>
  );

  return (
    <div
      className={cn('nav-bar', {
        'nav-bar--no-radius': onDashboardPage || onSettingsPage,
      })}
    >
      <div className="nav-bar__left">{onDashboardPage ? salixLogo : salixLogoLink}</div>
      <div className="nav-bar__right">
        {onDashboardPage && <AdminMenu menuToggleText={user.name || 'Account'} position="left" />}
        {onAssessmentPage && (
          <AssessmentNav studyReviewCount={studyReviewCount} returnToDashboard={returnToDashboard} />
        )}
        {onSettingsPage && <BasicNav studyReviewCount={studyReviewCount} returnToDashboard={returnToDashboard} />}
      </div>
    </div>
  );
};
