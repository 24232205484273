import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { initialLoadActions } from '../initialLoad/initialLoadSlice';
import * as api from '../../utils/api';

/**
 * Get the current versionHead from the backend, we need this before we know what version of the data to load.
 * Set the unifiedId that will trigger all further loading of data.
 */
function fetchVersionHead(patientId: string, runId: string, dispatch: AppDispatch) {
  // Get the current head version which we will need to fetch the other data.
  api
    .getJSON(`/data/${patientId}/${runId}/version/head`, true)
    .then((res) => {
      // Setting the UnifiedID will enable the loaders to start loading.
      dispatch(initialLoadActions.setUnifiedID({ runId: runId, patientId: patientId, version: res.head_version }));
    })
    .catch((error) => {
      console.error('UnifiedIdLoader', error);
    });
}

/**
 * A component to set the UnifiedId with the patientId, runId, and version required to load the initial study data.
 */
export const UnifiedIdLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const patientId = useAppSelector((state) => state.patient.patientID);
  const runId = useAppSelector((state) => state.study.currentStudy?.active_run);
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);

  // Set the unifiedId if it is not already set and we have the patientId and runId available.
  useEffect(() => {
    if (!unifiedId && patientId && runId) {
      fetchVersionHead(patientId, runId, dispatch);
    }
  }, [patientId, runId, unifiedId, dispatch]);

  return null;
};
