// @ts-ignore
import { pointInPolygon } from 'geometric';
import * as PIXI from 'pixi.js-legacy';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  KEY_CT_NON_CONTRAST,
  LOG_LEVEL,
  MOUSE_BUTTONS,
  NAV_TABS,
  PRIMARY_VESSEL_COLOR_MAPPING,
  PRESET_MAP,
} from '../../config';
import { CTVolumeOverlay } from '../../context/contrast-types';

import { isNonContrastSeries } from '../../reducers/dragAndDrop/helpers';

import { ProcessedLesionData } from '../../context/types';
import { WindowLevels } from '../../reducers/window/types';
import { ObjectArray } from '../../types/common';
import NonContrastViewHeader from '../../views/CTVolume/NonContrastViewHeader/NonContrastViewHeader';
import DropZone, { NON_CONTRAST_DROP_ZONE } from '../DropZone/DropZone';
import { OnReadyInf, RESIZE_MODE } from '../WebGLViewer/types';
import WebGLViewer from '../WebGLViewer/WebGLViewer';
// @ts-ignore
import worker from './CTVolumeNonContrastViewer.worker';
import { TessGraphics } from './TessGraphics';
import { MM_TO_PX } from '../../utils/measurementTools/types';
import { MeasurementTool } from '../MeasurementTool/MeasurementTool';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { windowAction } from '../../reducers/window/windowSlice';
import { CTNonContrastViewerData, XYCoords } from '../../reducers/vesselData/types';

const LESION_SETTINGS = {
  MAX_AMOUNT: 50000,
  COLORS: PRIMARY_VESSEL_COLOR_MAPPING,
  MARKER_DEFAULT_WIDTH: 80,
  MARKER_MIN_WIDTH: 10,
};

// The size of each lesion sprite texture dimension.
const lesionSpriteSize = 1;

/**
 * Create a super simple lesionSpriteSize x lesionSpriteSize white rectangular lesion texture.
 */
const createLesionTexture = () => {
  const buffer = new Uint8Array(4 * lesionSpriteSize * lesionSpriteSize);
  for (let i = 0; i < 4 * lesionSpriteSize * lesionSpriteSize; i++) {
    buffer[i] = 255;
  }
  return PIXI.Texture.fromBuffer(buffer, lesionSpriteSize, lesionSpriteSize);
};

const bringToFront = (sprite: PIXI.Sprite) => {
  if (sprite.parent) {
    const parent = sprite.parent;
    parent.removeChild(sprite);
    parent.addChild(sprite);
  }
};

const polygonToArray = (polygon: XYCoords[]) => {
  return polygon.map((point) => [point.x, point.y]);
};

const getLesionColor = (category: string): number => {
  if (LESION_SETTINGS.COLORS[category]) {
    return Number(LESION_SETTINGS.COLORS[category].replace('#', '0x'));
  }
  return Number('0xff0000');
};

interface SpriteLesion {
  sprite: PIXI.Sprite;
  lesion: ProcessedLesionData;
}

interface Props {
  updatedLesions: any[];
  onLesionEnter: (lesion: ProcessedLesionData, event: React.MouseEvent) => void;
  onLesionLeave: () => void;
  onLesionLasso: (selected: any[], event: Event) => void;
  onWindowLevelsChange: (windowLevels: WindowLevels) => void;
  slice: number;
  onSliceChange: (sliceIndex: number) => void;
  onHueChange: (hue: number | null) => void;
  onUpdateLesions: () => void;
  onZoom: () => void;
  onDrag: () => void;
  onDraw: () => void;
  onReady: (params: any) => void;
  label: string;
  nonContrastViewOverlay: CTVolumeOverlay;
  setNonContrastViewOverlay: (overlay: CTVolumeOverlay) => void;
  screenshotRef: any;
  onTakeScreenshot: (image: any) => void;
  onCloseScreenshot: () => void;
  onSetFullLesionData: (lesionData: any[]) => void;
  vesselCalciumVisible: { [key: string]: boolean } | undefined;
  viewerData?: CTNonContrastViewerData;
}

export const CTVolumeNonContrastViewer: React.FunctionComponent<Props> = ({
  updatedLesions,
  onLesionEnter,
  onLesionLeave,
  onLesionLasso,
  onWindowLevelsChange: _onWindowLevelsChange,
  slice,
  onSliceChange,
  onHueChange,
  onUpdateLesions,
  onZoom: onZoomCTVolume,
  onDrag,
  onDraw,
  onReady,
  label,
  nonContrastViewOverlay,
  setNonContrastViewOverlay,
  screenshotRef,
  onTakeScreenshot,
  onCloseScreenshot,
  onSetFullLesionData,
  vesselCalciumVisible,
  viewerData,
}) => {
  const nonContrastSpacing = useAppSelector((state) => state.store.nonContrastSpacing);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const visibleTab = useAppSelector((state) => state.globalFlags.visibleTab);
  const lesionData = useAppSelector((state) => state.lesion.lesionData);
  const dispatch = useAppDispatch();

  const patientID = useAppSelector((state) => state.patient.patientID);

  const { seriesId } = useAppSelector((state) => state.viewConfig);

  const triggerResetPanAndZoom = useAppSelector((state) => state.cpr.triggerResetPanAndZoom);

  // Measurement tools store
  const isMeasurementMode = useAppSelector((state) => state.measurementTools.isMeasurementMode);

  // Measurement tools
  const [scale, setScale] = useState<number>(1);

  // Is this component performing its initial loading sequence?
  const [loading, setLoading] = useState(true);
  // True if the user is currently holding down the lasso modifier keyboard key.
  const lassoKeyDownRef = useRef<boolean>(false);
  const [disableControls, setDisableControls] = useState(false);
  const [lesionMarkers, setLesionMarkers] = useState<any>();
  const { nonContrastWindowLevels } = useAppSelector((state) => state.window);
  const containerRef = useRef<PIXI.Container | undefined>(undefined);
  const holderRef = useRef<HTMLDivElement | undefined>(undefined);
  const lassoSpriteRef = useRef<TessGraphics | undefined>();
  const getContainerMousePosRef = useRef<(holderPoint: XYCoords | undefined) => XYCoords>();
  const appRef = useRef<PIXI.Application | undefined>();
  const lesionContainerRef = useRef<PIXI.ParticleContainer>();
  const lesionSpritesRef = useRef<PIXI.Sprite[]>([]);
  // Are we currently processing the lesionData to make it fit the structure this component wants.
  const [processingLesionData, setProcessingLesionData] = useState(true);
  // The array of processed lesionData.
  const processedLesionDataRef = useRef<ProcessedLesionData[][]>([]);
  // The array of all currently selected lesions. If hovering the huse this will have at most one entry, if a lasso was drawn then multiple lesions will be in this array.
  const selectedLesionsRef = useRef<SpriteLesion[]>([]);
  const lassoPointsRef = useRef<XYCoords[]>([]);
  const mountedRef = useRef(false);
  const currentMousePosRef = useRef({ x: 0, y: 0 });
  const primaryColorRef = useRef(0xff9900);
  const visibleVesselRef = useRef<ObjectArray<boolean> | undefined>();

  /**
   * Cleanup any PIXI WebGL objects that we will recreate once a reset finishes or we need to cleanup on unmount.
   */
  const cleanup = () => {
    // TODO: Ideally the selected lesions and the context menu could persist over a reset but because the
    // selectedLesions resefernce the sprites we're going to recreate this makes it a fair bit of work to
    // support a small edge case.

    // Hide the context menu etc.
    if (onDraw) {
      onDraw();
    }
    // Clear the selected lesions.
    selectedLesionsRef.current = [];

    // Clear references to avoid cyclic references and help the garbage collector out.
    appRef.current = undefined;
    containerRef.current = undefined;
    holderRef.current = undefined;
    getContainerMousePosRef.current = undefined;

    // Remove any lesion sprites from the lesionContainerRef and free the lesionContainerRef.
    lesionContainerRef.current && lesionContainerRef.current.removeChildren();
    if (lesionContainerRef.current) {
      lesionContainerRef.current.destroy({
        children: true,
        texture: true,
        baseTexture: true,
      });
      lesionContainerRef.current = undefined;
    }

    // Destroy up the lesion sprites.
    lesionSpritesRef.current.forEach((sprite) => {
      sprite.destroy();
    });
    lesionSpritesRef.current = [];

    // Clear the lasso sprite.
    if (lassoSpriteRef.current) {
      lassoSpriteRef.current.destroy({
        children: true,
        texture: true,
        baseTexture: true,
      });
      lassoSpriteRef.current = undefined;
    }

    // Only clean up the processed lesion data if we have unmounted.
    if (!mountedRef.current) {
      processedLesionDataRef.current = [];
    }
  };

  // WARNING below matches windowing context, however that context
  // is not expected to be used for the non-contrast
  const KEYBOARD_BUTTONS = useMemo(
    () => ({
      LASSO: ['Space', 'ShiftLeft', 'ShiftRight'],
      WINDOWING: Object.keys(PRESET_MAP),
    }),
    []
  );

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * If the conponent and the lesionData have loaded and we have yet to process the lesionData
   * then process it now.
   */
  useEffect(() => {
    if (lesionData && !loading && processingLesionData) {
      processLesionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesionData, loading]);

  /**
   * When the lesion data processing has finished we can show the results.
   */
  useEffect(() => {
    visibleVesselRef.current = vesselCalciumVisible;
    if (!processingLesionData) {
      drawLesionDataLayer(slice);
      createLesionMarkers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingLesionData, vesselCalciumVisible]);

  const init = ({ app, container, holder, getContainerMousePos }: OnReadyInf) => {
    appRef.current = app;
    containerRef.current = container;
    lassoSpriteRef.current = new TessGraphics();
    appRef.current.stage.addChild(lassoSpriteRef.current);
    holderRef.current = holder;
    getContainerMousePosRef.current = getContainerMousePos;
    createLesionSprites();
    setScale(containerRef.current?.scale.y ?? 1);
    setLoading(false);
    // Pass the deselectLesions function up to the CTVolume component.
    onReady && onReady({ deselectLesions });
    // If the lesion data has already been processed (like if we reset due to a lost context) then we should draw the lesion layer now.
    if (lesionData && !processingLesionData) {
      drawLesionDataLayer(slice);
      createLesionMarkers();
      // Draw the lasso (as required). Tthe user may have been drawing it when the reset happened.
      drawLasso();
    }
  };

  let keysPressed: any = useMemo(() => ({}), []);

  const getMarkerComponent = (width: number, vessel: string) => {
    // If the calculated with is less than the min width
    // Set marker width to the min width value
    const markerWidth = width > LESION_SETTINGS.MARKER_MIN_WIDTH ? width : LESION_SETTINGS.MARKER_MIN_WIDTH;

    return (
      <span
        title={vessel}
        className="lesion-marker"
        style={{
          width: `${markerWidth}px`,
          background: `${PRIMARY_VESSEL_COLOR_MAPPING[vessel]}`,
        }}
      />
    );
  };

  const createLesionMarkers = useCallback(() => {
    let highestLesionCount = 0;
    let lesionPerSlice: { [key: number]: any } = {};
    if (!processedLesionDataRef.current) return;
    const lesionSliceCount = processedLesionDataRef.current.length;

    for (var i = 0; i < lesionSliceCount; i++) {
      const sliceLesions = processedLesionDataRef.current[i];
      if (sliceLesions === null) {
        console.warn(`CTVolumeNonContrastViewer: Missing lesion data at position ${i}.`);
        continue;
      }
      const filteredSliceLesions = sliceLesions.filter((l) => l['category'] !== 'other');
      // Group lesions on each slice and the total number of point
      // This will determine what colour and the width of the marker
      if (filteredSliceLesions.length > 0) {
        const reduced = filteredSliceLesions.reduce((acc: any, obj: any) => {
          let key = obj['category'];
          if (acc[key]) {
            acc[key]++;
          } else {
            acc[key] = 1;
          }
          return acc;
        }, {});
        lesionPerSlice[i] = reduced;
        highestLesionCount =
          filteredSliceLesions.length > highestLesionCount ? filteredSliceLesions.length : highestLesionCount;
      }
    }

    // Scan through each slice and only choose the vessel with the largest lesion
    // create marker element based on the vessel and the size of that lession
    const markers = Object.entries(lesionPerSlice).reduce((prev, current) => {
      const currentValue = current[1];
      const highestVessel = Object.keys(currentValue).reduce((a, b) => (currentValue[a] > currentValue[b] ? a : b));
      const markerWidth = Math.ceil(
        (currentValue[highestVessel] / highestLesionCount) * LESION_SETTINGS.MARKER_DEFAULT_WIDTH
      );
      return {
        ...prev,
        [current[0]]: getMarkerComponent(markerWidth, highestVessel),
      };
    }, {});

    setLesionMarkers(markers);
  }, [setLesionMarkers]);

  /**
   * Create an array of lesion sprites that can be positioned where the lesions are on the active slice.
   */
  const createLesionSprites = () => {
    // Check we haven't already created the lesion sprites.
    if (!lesionContainerRef.current && lesionSpritesRef.current.length === 0) {
      // Create a PIXI container for the lesions and add it to the containerRef.
      const lesionContainer = new PIXI.ParticleContainer();
      lesionContainer.autoResize = true;
      // @ts-ignore maxSize?
      lesionContainer.maxSize = LESION_SETTINGS.MAX_AMOUNT;
      lesionContainer.setProperties({
        tint: true,
      });
      lesionContainerRef.current = lesionContainer;
      containerRef.current && containerRef.current.addChild(lesionContainer);

      // Create a new lesion texture.
      const texture = createLesionTexture();
      // Create the maximum number of lesion sprites.
      for (let i = 0; i <= LESION_SETTINGS.MAX_AMOUNT; i++) {
        const sprite = new PIXI.Sprite(texture);
        // Set the sprite to be one (scaled) pixel big.
        sprite.width = 1;
        sprite.height = 1;
        lesionSpritesRef.current.push(sprite);
      }
    }
  };

  /**
   * Process the lesionData so that it fits the structure used by this component.
   */
  const processLesionData = () => {
    if (!lesionData) {
      return;
    }
    // Create a new web worker.
    const localWorker = new worker();
    localWorker.onerror = (err: Error) => {
      console.error('Non Contrast WebWorker error', err);
    };
    localWorker.onmessage = (e: any) => {
      if (mountedRef.current) {
        processedLesionDataRef.current = e.data;
        setProcessingLesionData(false);
        onSetFullLesionData && onSetFullLesionData(e.data);
      }
      // Clean up the web worker - we only wanted it to do a single task.
      localWorker.terminate();
    };
    localWorker.postMessage(lesionData);
  };

  /**
   * Draw the lesions for the specified slice index (index) by using our pool of lesion sprites (in lesionSpritesRef)
   * and adding the ones we want to the lesionContainerRef after updating their position and tint.
   */
  const drawLesionDataLayer = useCallback((index) => {
    // Check we have initialized the lesion container.
    if (lesionContainerRef.current) {
      // Remove all lesion sprites from the lesion container.
      lesionContainerRef.current.removeChildren();

      // Check we have lesion data for this slice.
      if (processedLesionDataRef.current && processedLesionDataRef.current[index]) {
        // Loop through every lesion position on this slice.
        processedLesionDataRef.current[index].forEach((lesion, i) => {
          // Do not draw if the lesion's vessel is toggled off in info section.
          if (!visibleVesselRef.current?.[lesion.category]) return;
          // Position the lesion sprite where the lesion is positioned and tint it the colour of this lesion's category.
          if (lesionSpritesRef.current[i]) {
            const sprite = lesionSpritesRef.current[i];
            sprite.x = lesion.x;
            sprite.y = lesion.y;
            sprite.tint = getLesionColor(lesion.category);
            if (lesionContainerRef.current) {
              lesionContainerRef.current.addChild(sprite);
            }
          } else {
            console.error(`Error: not enough pre rendered sprites to draw lesion '${i}'`);
          }
        });
      }
    } else {
      if (LOG_LEVEL > 0) {
        console.warn('Warning: Lesion container not initialized, lesion layer was not drawn for slice', index);
      }
    }
  }, []);

  /**
   * One or more lesions have changed their category; reassign the lesions, redraw all lesions, and
   * call the callback to notify the parent object.
   */
  const updateLesions = useCallback(() => {
    if (processedLesionDataRef.current) {
      // Loop through every slice of lesion data.
      processedLesionDataRef.current.forEach((slice) => {
        // Loop through every lesion in this slice.
        slice.forEach((lesion) => {
          // Update this slice with the new category if it was found in the list of lesions that have updated.
          updatedLesions.forEach((updated) => {
            if (updated.lesion_id === lesion.lesion_id) {
              lesion.category = updated.category;
            }
          });
        });
      });
      drawLesionDataLayer(slice);
      createLesionMarkers();
      onUpdateLesions && onUpdateLesions();
    }
  }, [onUpdateLesions, slice, updatedLesions, createLesionMarkers, drawLesionDataLayer]);

  useEffect(() => {
    if (updatedLesions && updatedLesions.length) {
      updateLesions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedLesions, updateLesions]);

  /**
   * Redraw the slices when the active slice has changed.
   */
  useEffect(() => {
    drawLesionDataLayer(slice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slice]);

  /**
   * Recolour the selected lesions with their lesion colour and clear the selected lesions list.
   */
  const deselectLesions = useCallback(() => {
    if (selectedLesionsRef.current.length) {
      selectedLesionsRef.current.forEach((selected) => {
        selected.sprite.tint = getLesionColor(selected.lesion.category);
      });
    }
    selectedLesionsRef.current = [];
  }, []);

  const selectLesionWithHover = (event: React.MouseEvent) => {
    if (selectedLesionsRef.current.length > 1 || lassoKeyDownRef.current || !getContainerMousePosRef.current) {
      return false;
    }
    const point = getContainerMousePosRef.current(currentMousePosRef.current);
    let over = undefined;
    if (processedLesionDataRef.current && processedLesionDataRef.current[slice] && point) {
      processedLesionDataRef.current[slice].forEach((lesion, i) => {
        const sprite = lesionSpritesRef.current[i];
        if (sprite) {
          const hitArea = [
            [sprite.x, sprite.y],
            [sprite.x + sprite.width, sprite.y],
            [sprite.x + sprite.width, sprite.y + sprite.height],
            [sprite.x, sprite.y + sprite.height],
          ];
          const hit = pointInPolygon([point.x, point.y], hitArea);
          if (hit) {
            over = lesion;
            bringToFront(sprite);
            sprite.tint = getLesionColor('selected');
            let enter = false;
            if (selectedLesionsRef.current.length === 0) {
              enter = true;
            } else if (selectedLesionsRef.current.length === 1) {
              if (JSON.stringify(lesion) !== JSON.stringify(selectedLesionsRef.current[0].lesion)) {
                enter = true;
              }
            }
            enter && onLesionEnter && onLesionEnter(lesion, event);
            selectedLesionsRef.current = [{ sprite, lesion }];
          } else if (sprite.tint === getLesionColor('selected')) {
            sprite.tint = getLesionColor(lesion.category);
          }
        }
      });
    }
    if (!over) {
      unselectLesions();
    }
    return true;
  };

  const clearLasso = useCallback(() => {
    if (lassoSpriteRef.current) {
      lassoSpriteRef.current.clear();
      lassoSpriteRef.current.lineStyle(1, primaryColorRef.current);
      lassoSpriteRef.current.beginFill(primaryColorRef.current, 0.2);
    }
  }, []);

  const resetLasso = useCallback(() => {
    clearLasso();
    lassoPointsRef.current = [];
  }, [clearLasso]);

  const selectLesionsWithLasso = useCallback(
    (event: Event) => {
      if (!lassoPointsRef.current.length || !getContainerMousePosRef.current) {
        return false;
      }
      const selected: XYCoords[] = [];
      if (lesionSpritesRef.current[slice] && processedLesionDataRef.current && processedLesionDataRef.current[slice]) {
        const lasso = lassoPointsRef.current.map((point) => {
          return getContainerMousePosRef.current ? getContainerMousePosRef.current(point) : point;
        });
        // We precalculate these values outside the inner loop for speed.
        const polygonArray: number[][] = polygonToArray(lasso);
        const selectedLesionColor: number = getLesionColor('selected');
        // Loop through every lesion in the slice and apply the lasso selection.
        processedLesionDataRef.current[slice].forEach((lesion, i) => {
          if (lesionSpritesRef.current[i]) {
            const sprite = lesionSpritesRef.current[i];
            if (pointInPolygon([sprite.x, sprite.y], polygonArray)) {
              // The lesion is inside the lasso: select it.
              sprite.tint = selectedLesionColor;
              bringToFront(sprite);
              selected.push(lesion);
              selectedLesionsRef.current.push({ sprite, lesion });
            } else if (sprite.tint === selectedLesionColor) {
              // The lesion is currently selected but outside the lasso: unselect it.
              sprite.tint = getLesionColor(lesion.category);
            }
          }
        });
      }
      resetLasso();
      onLesionLasso && onLesionLasso(selected, event);
      return true;
    },
    [resetLasso, slice, onLesionLasso]
  );

  const drawLasso = useCallback(() => {
    if (lassoKeyDownRef.current && lassoPointsRef.current && lassoPointsRef.current.length && lassoSpriteRef.current) {
      bringToFront(lassoSpriteRef.current as any);
      clearLasso();
      lassoSpriteRef.current.drawPolygon(lassoPointsRef.current as any);
      lassoSpriteRef.current.endFill();
    }
  }, [clearLasso]);

  const onLassoDraw = () => {
    // Add the next point to the lasso.
    lassoPointsRef.current.push(currentMousePosRef.current);
    // Draw the lasso (as required).
    drawLasso();
    // Hide the context menu etc.
    onDraw && onDraw();
  };

  const getHolderMousePos = useCallback((point): XYCoords => {
    const holder = holderRef.current;
    if (!holder) return point;
    const offset = holder.getBoundingClientRect();
    return {
      x: point.x - offset.x,
      y: point.y - offset.y,
    };
  }, []);

  /**
   * Unselect any selected lesions, restore their colours to their correct colours, close the context menu.
   */
  const unselectLesions = useCallback(() => {
    if (selectedLesionsRef.current.length) {
      onLesionLeave && onLesionLeave();
      deselectLesions();
    }
  }, [deselectLesions, onLesionLeave]);

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      // Clear any currently selected lesions.
      unselectLesions();
    },
    [unselectLesions]
  );

  const onMouseMove = (event: React.MouseEvent) => {
    // We won't have a holderRef when initially loading or when resetting.
    if (holderRef.current) {
      currentMousePosRef.current = getHolderMousePos({
        x: event.pageX,
        y: event.pageY,
      });

      if (!isMeasurementMode) {
        if (event.buttons === MOUSE_BUTTONS.LEFT && lassoKeyDownRef.current) {
          // To be lassoing we need to have the left button down and one of the lasso keyboard modifier keys down.
          onLassoDraw();
        } else {
          selectLesionWithHover(event);
        }
      }
    }
  };

  const onMouseUp = (event: React.MouseEvent) => {
    if (lassoKeyDownRef.current && lassoPointsRef.current.length) {
      selectLesionsWithLasso(event.nativeEvent);
    } else {
      unselectLesions();
    }
  };

  const onZoom = useCallback(() => {
    onZoomCTVolume();
    setScale(containerRef.current?.scale.y ?? 1);
  }, [onZoomCTVolume, setScale]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      keysPressed[event.code] = true;

      if (KEYBOARD_BUTTONS.LASSO.includes(event.code)) {
        // NOTE: the shift key is also used with control + tab to switch browser tabs.
        // Given this, we record previous keypresses and check if either control keys have been pressed
        // If so, we can assume the shift key has been used in a hotkey combo, and ignore the Lasso tool trigger
        if (keysPressed['ControlLeft'] || keysPressed['ControlRight']) {
          return;
        }

        // NOTE: The 'spacebar' key will auto repeat and we only want to clear the lesions on the first "keyDown' event.
        // This detects if the event is a repeat and ignore it if so.
        if (!event.repeat) {
          unselectLesions();
          setDisableControls(true);
          lassoKeyDownRef.current = true;
        }
      } else if (KEYBOARD_BUTTONS.WINDOWING.includes(event.code)) {
        // Check if the user is focused on an input field, if so do not set window levels with PRESETS
        if (
          event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement ||
          event.target instanceof HTMLSelectElement
        ) {
          return;
        }
        dispatch(windowAction.setNonContrastWindowLevels(PRESET_MAP[event.code].window));
        _onWindowLevelsChange && _onWindowLevelsChange(PRESET_MAP[event.code].window);
        event.preventDefault();
      }
    },
    [keysPressed, KEYBOARD_BUTTONS.LASSO, KEYBOARD_BUTTONS.WINDOWING, unselectLesions, dispatch, _onWindowLevelsChange]
  );

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (KEYBOARD_BUTTONS.LASSO.includes(event.code)) {
        selectLesionsWithLasso(event);
        setDisableControls(false);
        lassoKeyDownRef.current = false;
      }

      // This clears out the record of keys pressed
      delete keysPressed[event.code];
    },
    [setDisableControls, selectLesionsWithLasso, KEYBOARD_BUTTONS, keysPressed]
  );

  // We should only listen to the keyboard if the user is on the CT Volume tab and viewing the non-contrast viewer (NOTE: This stays mounted even if viewing the contrast views).
  const listenToKeyboard = visibleTab === NAV_TABS.ctVolumeTab && isNonContrastSeries(seriesId);
  useEffect(() => {
    // If we aren't listening to the keyboard we shouldn't have any lesions selected, the lasso key down can be cleared, and controls should be re-enabled.
    if (!listenToKeyboard) {
      unselectLesions();
      lassoKeyDownRef.current = false;
      setDisableControls(false);
    }
  }, [listenToKeyboard, unselectLesions]);

  const onWindowLevelsChange = useCallback(
    (x) => {
      dispatch(windowAction.setNonContrastWindowLevels(x));
      _onWindowLevelsChange && _onWindowLevelsChange(x);
    },
    [_onWindowLevelsChange, dispatch]
  );

  /**
   * Setup keyboard listeners.
   */
  useEffect(() => {
    if (listenToKeyboard) {
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
    }
    return () => {
      if (listenToKeyboard) {
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
      }
    };
  }, [onKeyDown, onKeyUp, listenToKeyboard]);

  const viewId = `NonContrastView`;
  return (
    <div className="non-contrast-viewer">
      {patientID && runID && (
        <>
          <DropZone viewIndex={NON_CONTRAST_DROP_ZONE} />
          <NonContrastViewHeader
            seriesDescription={label}
            nonContrastViewOverlay={nonContrastViewOverlay}
            setNonContrastViewOverlay={setNonContrastViewOverlay}
            screenshotRef={screenshotRef}
            onTakeScreenshot={onTakeScreenshot}
            onCloseScreenshot={onCloseScreenshot}
          />
          <div className="non-contrast-viewer__inner-view">
            <MeasurementTool
              viewId={viewId}
              holder={holderRef.current}
              app={appRef.current}
              container={containerRef.current}
              slice={slice}
              millimeterSpacing={nonContrastSpacing?.x || MM_TO_PX}
              scale={scale}
              onMouseDownHandler={onMouseDown}
              onMouseMoveHandler={onMouseMove}
              onMouseUpHandler={onMouseUp}
            >
              <WebGLViewer
                viewId={viewId}
                loadingCTvolume={loading}
                reverseSlider={false}
                viewType={KEY_CT_NON_CONTRAST}
                showSlider={true}
                slice={slice}
                onSliceChange={onSliceChange}
                windowLevels={nonContrastWindowLevels}
                resizeMode={RESIZE_MODE.FIT}
                onReady={init}
                onCleanup={cleanup}
                onHueChange={onHueChange}
                onZoom={onZoom}
                onDrag={onDrag}
                onWindowLevelsChange={onWindowLevelsChange}
                loadingInfo={processingLesionData ? 'Processing lesion data' : undefined}
                disableControls={disableControls}
                sliderMarkers={lesionMarkers}
                triggerResetPanAndZoom={triggerResetPanAndZoom}
                shapeData={viewerData?.shape}
                imageBufferData={viewerData?.imageBufferData}
              />
            </MeasurementTool>
          </div>
        </>
      )}
    </div>
  );
};

CTVolumeNonContrastViewer.defaultProps = {
  updatedLesions: [],
};
