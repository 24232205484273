import cn from 'classnames';
import { uniq } from 'lodash';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import Moment from 'moment';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Icon } from '../../objects/Icon/Icon';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { Loader } from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';
import { NavBar } from '../../components/NavBar/NavBar';
import { Pagination } from '../../components/Pagination/Pagination';
import { Search } from '../../components/Search/Search';
import {
  DASHBOARD_ITEMS_PER_PAGE,
  PERMISSION_CHECK_INTERVAL,
  RISK_LEVELS,
  STENOSIS_RISK_LEVEL_MAPPING,
  THEME,
  DATETIME_FORMAT,
  MOMENT_DATETIME_FORMAT,
} from '../../config';
import { Study, WorkflowStatus } from '../../context/types';
import { decryptDatasetsResponse } from '../../hooks/use-dashboard-data';
import usePreviousValue from '../../hooks/use-previous-value';
import { useIsArtryaUserSelector, useIsAuditorUserSelector } from '../../selectors/user';
import { performPermissionCheck } from '../../utils/auth';
import * as indexedDB from '../../utils/indexedDB';
import { unlockAllStudies } from '../../utils/studyLocking';
import { WorkflowActions } from '../../views/WorkflowActions/WorkflowActions';
import { formatDOB, getValidTimestamp, customClassCheck } from '../../utils/shared';
import { useEffectCustomDeps } from '../../hooks/useEffectCustomDeps';
import { useDashboardDispatch, useDashboardSelector } from '../../dashboardHooks';
import { dashboardActions } from '../../reducers/dashboard/dashboardSlice';
import { useDashboardSessionStorage } from '../../reducers/dashboard/utils';
import { DashboardLoader } from '../../reducers/dashboard/dashboardLoader';
import { ReleaseVersionLoader } from '../../reducers/ReleaseVersion/releaseVersionLoader';

enum CannotBeViewedReasons {
  NOT_PROCESSED = 'NOT_PROCESSED',
  EXISTING_LOCK = 'EXISTING_LOCK',
  NOT_SUCCESSFULLY_FINISHED_PROCESSING = 'NOT_SUCCESSFULLY_FINISHED_PROCESSING',
}

const STUDY_PROCESSING_MESSAGE =
  'This study is currently being processed.  You can review it once the processing has completed.';

const STUDY_NOT_PROCESSED_MESSAGE =
  'This study is currently being analysed.  You can review it once the analysis and processing has completed.';

function formatValue(value: number) {
  if (!value && value !== 0) return '-'; // Check if value is falsy except 0
  return value;
}

function formatNumber(value: string) {
  return !Number.isNaN(Math.round(parseFloat(value))) ? Math.round(parseFloat(value)) : '-';
}

function formatDate(value: string) {
  const timeStamp = getValidTimestamp(value);
  return timeStamp ? timeStamp.format(DATETIME_FORMAT) : '-';
}

function formatStudyDateTime(value: string) {
  // Function to handle formatting the combined Study Date and Study Time value
  if (value) {
    if (Moment(value, MOMENT_DATETIME_FORMAT).isValid()) {
      return Moment(value, MOMENT_DATETIME_FORMAT).format(DATETIME_FORMAT);
    } else {
      return Moment(value).format(DATETIME_FORMAT);
    }
  }
  return null;
}

function formatWorkflowStatus(value: WorkflowStatus) {
  if (!value) return '';
  return value.short_description ? value.short_description : value.state;
}

const RIS_ICON_MAP: { [key: string]: ReactElement } = {
  Success: <Icon icon="tick" type="small" customClass="dashboard__ris-icon" />,
  'Send Failure': <Icon icon="cross" type="small" customClass="dashboard__ris-icon" />,
  Sending: <Icon icon="dash" type="small" customClass="dashboard__ris-icon" />,
};

const TABLE_INFO = {
  patient_name: {
    headerText: 'Patient Name',
    formatFn: formatValue,
    customClass: 'dashboard__patientname',
  },
  patient_id: { headerText: 'Patient ID' },
  date_of_birth: {
    headerText: 'Date of Birth',
    formatFn: formatDOB,
    customClass: 'dashboard__dob',
  },
  last_scan: { headerText: 'Scan Date', formatFn: formatStudyDateTime, customClass: 'dashboard__scandate' },
  calcium_score: {
    headerText: 'Calcium Score',
    formatFn: formatNumber,
    borderLeft: true,
    customClass: 'dashboard__calcium',
  },
  stenosis: {
    headerText: 'Stenosis',
    formatFn: formatValue,
    riskLevelMap: STENOSIS_RISK_LEVEL_MAPPING,
  },
  cad_rads: { headerText: 'CAD RADS', formatFn: formatValue, customClass: 'dashboard__cadrads' },
  vulnerable_plaque: {
    headerText: 'Vulnerable Plaque',
    formatFn: formatValue,
    columnWidth: 'wide',
  },
  workflow_status: {
    headerText: 'Status',
  },
  ris: {
    headerText: 'RIS',
    borderLeft: true,
    customClass: 'dashboard__ris',
  },
};

// field name that can be searched ('study_id' included even though it is not included on table columns)
const SEARCH_BY = {
  patient_name: {},
  patient_id: {},
  date_of_birth: { formatFn: formatDOB },
  last_scan: { formatFn: formatDate },
  vulnerable_plaque: {},
  stenosis: {},
  calcium_score: { formatFn: formatNumber },
  cad_rads: {},
  workflow_status: { formatFn: formatWorkflowStatus },
  study_id: {},
};

export default function Dashboard() {
  const dashboardDispatch = useDashboardDispatch();
  const dashboardData = useDashboardSelector((state) => state.dashboard.studies);
  const { dashboardCurrentPage, dashboardSortKey, dashboardAscending, dashboardSearchStr } = useDashboardSelector(
    (state) => state.dashboard
  );
  const navigate = useNavigate();

  // Persist dashboard values for this session.
  useDashboardSessionStorage();

  const user = useDashboardSelector((state) => state.user.user);
  const decryptedStudies = useDashboardSelector((state) => state.dashboard.decryptedStudies);

  const [processedData, setProcessedData] = useState([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [pageData, setPageData] = useState(new Map());
  const [numPages, setNumPages] = useState(1);
  const [decryptRemaining, setDecryptRemaining] = useState<'decrypt' | 'complete' | null>(null);
  // The message to show the user warning them that the study is locked by another user, or null if no message should be shown.
  const [showLockedMessage, setShowLockedMessage] = useState<string | null>(null);
  const [showDataNotAvailableReason, setShowDataNotAvailableReason] = useState('');
  const [pageBeforeSearch, setPageBeforeSearch] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [prevDecryptedStudies, setPrevDecryptedStudies] = useState<string[]>([]);
  const isArtryaUser = useIsArtryaUserSelector();

  const memoizedDashboardData = useMemo<Study[]>(() => Object.values(dashboardData ?? {}), [dashboardData]);

  const permissionInterval = useRef<any | undefined>(null);

  const prevDashboardString = usePreviousValue(dashboardSearchStr);

  const lockedMessage = useCallback((status: string, user: string) => {
    if (status === 'Processing' || status === 'Ingested') {
      return STUDY_PROCESSING_MESSAGE;
    } else if (status === 'Locked') {
      return `This study is currently being reviewed.  You can review it once ${user} has completed their review.`;
    } else if (status === 'Analysis Failed') {
      return `This study has failed analysis in the Artrya system, please contact support@artrya.com.`;
    }
    // Shouldn't happen as there aren't any other locked status
    return '';
  }, []);

  const handlePatientClick = useCallback(
    (params: {
      study_id: string | null | undefined;
      status: WorkflowStatus | null | undefined;
      user: string;
      client_id: string;
      can_be_viewed: {
        reason: string;
        viewable: boolean;
      };
    }) => {
      const { study_id, can_be_viewed, user, status } = params;

      if (!study_id) return;

      if (can_be_viewed.viewable || (isArtryaUser && status?.state === 'Locked')) {
        navigate(`/study/${study_id}`);
      } else {
        if (can_be_viewed.reason === CannotBeViewedReasons.NOT_PROCESSED) {
          setShowDataNotAvailableReason(STUDY_NOT_PROCESSED_MESSAGE);
        } else if (can_be_viewed.reason === CannotBeViewedReasons.NOT_SUCCESSFULLY_FINISHED_PROCESSING) {
          setShowDataNotAvailableReason(STUDY_PROCESSING_MESSAGE);
        } else if (can_be_viewed.reason === CannotBeViewedReasons.EXISTING_LOCK) {
          setShowLockedMessage(lockedMessage('Locked', user));
        }
      }
    },
    [isArtryaUser, lockedMessage, navigate]
  );

  useEffectCustomDeps(() => {
    // Ensure the decrypted studies list is unique
    if (decryptedStudies.length > memoizedDashboardData.length) {
      dashboardDispatch(dashboardActions.setDecryptedStudies(uniq(decryptedStudies)));
      return;
    }

    // Once all studies have been decrypted and if the sort field is a decrypted field
    // Sort results again
    if (
      decryptedStudies.length === memoizedDashboardData.length &&
      (dashboardSortKey === 'patient_name' || dashboardSortKey === 'date_of_birth')
    ) {
      const newFilteredData = [...filteredData];
      const sortedData: any = orderBy(
        newFilteredData,
        (scan: any) => {
          let data: string;
          data = scan[dashboardSortKey] ? scan[dashboardSortKey].toLowerCase() : '';

          return data;
        },
        dashboardAscending ? 'asc' : 'desc'
      );

      setFilteredData(sortedData);
    }
  }, [decryptedStudies]);

  const handleHeaderClick = useCallback(
    (key: string) => {
      const asc = key === dashboardSortKey ? !dashboardAscending : false;
      // Reset to page 1 when sort column/order changes
      dashboardDispatch(dashboardActions.setDashboardCurrentPage(1));
      dashboardDispatch(dashboardActions.setDashboardSortKey(key));
      dashboardDispatch(dashboardActions.setDashboardAscending(asc));
    },
    [dashboardAscending, dashboardSortKey, dashboardDispatch]
  );

  const isAuditorUser = useIsAuditorUserSelector();

  useEffect(() => {
    // if user is an auditor then redirect to audit logs
    if (isAuditorUser) {
      return navigate('/auditlog');
    }
  }, [navigate, isAuditorUser]);

  useEffect(() => {
    // Catch-all to ensure studies are not stuck in a locked state
    unlockAllStudies();

    (async function checkUserPermission() {
      const hasPermissions = await performPermissionCheck(user.groups);
      if (!hasPermissions) {
        console.warn('You do not have permission to access the Dashboard');
        indexedDB.clearDb();
        navigate('/error', { state: 'permissionError' });
      }
    })();
  }, []); // eslint-disable-line

  // Filter and sort processed data
  useEffect(() => {
    if (!memoizedDashboardData || memoizedDashboardData.length === 0) return;

    const dashboardSearchStrLowerCase = String(dashboardSearchStr || '').toLowerCase();

    // Keeping track of where you were if there is a search string
    if (!pageBeforeSearch && dashboardCurrentPage > 1 && dashboardSearchStr !== '' && !prevDashboardString) {
      setPageBeforeSearch(dashboardCurrentPage);
    }

    let newFilteredData: any = memoizedDashboardData;
    // Filter by the on screen fields only
    if (dashboardSearchStrLowerCase) {
      newFilteredData = pickBy(memoizedDashboardData, function (scan: any) {
        return Object.entries(SEARCH_BY).some(([key, { formatFn }]: any[]) => {
          const scanValue = formatFn ? formatFn(scan[key]) : scan[key];
          return scanValue && String(scanValue).toLowerCase().includes(dashboardSearchStrLowerCase);
        });
      });
    }

    // Sort the data by selected field
    const sortedData: any = orderBy(
      newFilteredData,
      (scan: any) => {
        let data: string | Moment.Moment | number = '';
        switch (dashboardSortKey) {
          case 'last_scan':
            // Checks if the entry has a "last_scan" field, if not fall back to the "study_date" field
            const study_date = formatDate(scan.study_date);
            data = scan.last_scan ? formatDate(scan.last_scan) : study_date;

            break;
          case 'calcium_score':
            data = isNaN(scan[dashboardSortKey]) ? -1 : Math.round(scan[dashboardSortKey]);
            break;
          case 'workflow_status':
            data = scan[dashboardSortKey] && formatWorkflowStatus(scan[dashboardSortKey]).toLowerCase();
            break;
          case 'stenosis':
            const splitValue = scan[dashboardSortKey].split(' ')[0];
            data = parseFloat(splitValue === '-' ? '-1' : splitValue);
            break;
          case 'patient_name':
            data = scan[dashboardSortKey]
              ? scan[dashboardSortKey].toLowerCase()
              : scan['study_id']
              ? scan['study_id']
              : '';
            break;
          default:
            data = scan[dashboardSortKey] ? scan[dashboardSortKey].toLowerCase() : '';
            break;
        }

        return data;
      },
      dashboardAscending ? 'asc' : 'desc'
    );

    setFilteredData(sortedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedDashboardData, dashboardSearchStr, dashboardSortKey, dashboardAscending]);

  useEffectCustomDeps(() => {
    if (pageBeforeSearch && prevDashboardString && !dashboardSearchStr) {
      dashboardDispatch(dashboardActions.setDashboardCurrentPage(pageBeforeSearch));
      setPageBeforeSearch(null);
      return;
    }
    // Redirect to first page if user enters search string
    if (!dashboardSearchStr) {
      setPageBeforeSearch(null);
      return;
    }
    if (dashboardCurrentPage != null) dashboardDispatch(dashboardActions.setDashboardCurrentPage(1));
  }, [dashboardSearchStr, pageBeforeSearch, prevDashboardString, dashboardDispatch]);

  // Extract items for current page only
  useEffectCustomDeps(() => {
    if (!filteredData) return;

    const startIndex = DASHBOARD_ITEMS_PER_PAGE * (dashboardCurrentPage - 1);
    const endIndex = DASHBOARD_ITEMS_PER_PAGE * dashboardCurrentPage;
    const newPageData = Object.keys(filteredData)
      .slice(startIndex, endIndex)
      .reduce((result: any, key: any) => {
        result[key] = filteredData[key];
        return result;
      }, {});

    // If logged in with an Artrya account, we don't decrypt the current page's data to improve performance
    if (!isArtryaUser && ((filteredData.length && decryptRemaining === null) || prevDashboardString)) {
      (async function decryptData() {
        const decPageData = await decryptDatasetsResponse(newPageData, dashboardData);
        Object.keys(decPageData).forEach((key) => {
          if (!decryptedStudies.includes(key)) {
            setPrevDecryptedStudies((oldArray) => [...oldArray, key]);
          }
        });
        dashboardDispatch(dashboardActions.setDecryptDashboardData(decPageData));
        setPageData(newPageData);
        setDecryptRemaining('decrypt');
      })();
    } else {
      setPageData(newPageData);
    }
  }, [filteredData, dashboardCurrentPage]);

  useEffect(() => {
    dashboardDispatch(dashboardActions.setDecryptedStudies(prevDecryptedStudies));
  }, [dashboardDispatch, prevDecryptedStudies]);

  useEffectCustomDeps(() => {
    if (decryptRemaining !== 'decrypt' || !filteredData.length) return;

    const numItems = Object.values(filteredData || {}).length;
    const pages = Math.ceil(numItems / DASHBOARD_ITEMS_PER_PAGE);
    let x = {};

    for (let i = 1; i <= pages; i++) {
      x = { ...x, [i]: false };
    }

    for (let i = 1; i <= pages; i++) {
      const startIndex = DASHBOARD_ITEMS_PER_PAGE * (i - 1);
      const endIndex = DASHBOARD_ITEMS_PER_PAGE * i;
      const thisPageData = Object.keys(filteredData)
        .slice(startIndex, endIndex)
        .reduce((result: any, key: any) => {
          result[key] = filteredData[key];
          return result;
        }, {});

      // If logged in with an Artrya account, we don't decrypt the remaining data to improve performance
      if (!isArtryaUser) {
        (async function decryptData() {
          const decPageData = await decryptDatasetsResponse(thisPageData, dashboardData);
          Object.keys(decPageData).forEach((key) => {
            if (!decryptedStudies.includes(key)) {
              setPrevDecryptedStudies((oldArray) => [...oldArray, key]);
            }
          });
          dashboardDispatch(dashboardActions.setDecryptDashboardData(decPageData));
        })();
      }

      setDecryptRemaining('complete');
    }
  }, [decryptRemaining, filteredData]);

  // Process the fields, removing any we don't want
  useEffect(() => {
    if (!pageData || !memoizedDashboardData.length) return;
    // Only use columns displayed in dashboard (filter out others, except the study_id)
    const newProcessedData: any = Object.values(pageData).map((item = {}) => {
      let values: any = Object.entries(TABLE_INFO).reduce((acc, [key, { formatFn }]: any[]) => {
        const value = item[key];
        const formattedValue = formatFn ? formatFn(value) : value;
        return { ...acc, [key]: formattedValue };
      }, {});
      // Check to assign the actual scan date to study_date if it exists for the logged in user
      if (!values.last_scan) {
        values.last_scan = item.study_date ? formatDate(item.study_date) : '-';
      }
      values.study_id = item.study_id;
      values.status = item.workflow_status;
      values.user = item.workflow_user;
      values.can_be_viewed = item.can_be_viewed;
      values.client_id = item.client_id;
      values.mesa_percentile = item.mesa_percentile;
      values.ris_status = item.ris_status;
      return values;
    });
    setProcessedData(newProcessedData);
    setLoading(false);
  }, [pageData, memoizedDashboardData]);

  // Calculate number of pages from filtered data
  useEffect(() => {
    if (!filteredData) return;
    const numItems = Object.values(filteredData || {}).length;
    setNumPages(Math.ceil(numItems / DASHBOARD_ITEMS_PER_PAGE));
  }, [filteredData]);

  useEffect(() => {
    permissionInterval.current = setInterval(() => {
      performPermissionCheck(user.groups);
    }, PERMISSION_CHECK_INTERVAL);

    return () => clearInterval(permissionInterval.current);
  }, [permissionInterval, user]);

  return (
    <>
      <DashboardLoader />
      <ReleaseVersionLoader />
      <PageTitle title="Dashboard" />
      {loading || !dashboardData || Object.keys(dashboardData).length === 0 ? (
        <Loader text="Loading dashboard data" large fullScreen />
      ) : (
        <div className="dashboard" id="page-dashboard">
          <div className="topbar topbar--single-col">
            <NavBar />
          </div>
          <WorkflowActions user={user} studies={memoizedDashboardData} onViewReport={handlePatientClick} />
          <div className="dashboard__data">
            <div className="dashboard__header">
              <h1 className="dashboard__title">Patient Dashboard</h1>
              <div className="dashboard__header-actions">
                <Search
                  searchStr={dashboardSearchStr}
                  onChange={(value) => {
                    dashboardDispatch(dashboardActions.setDashboardSearchStr(value));
                  }}
                />
              </div>
            </div>

            <div className="dashboard__table-wrap">
              <table className="dashboard__table">
                <thead>
                  <tr className="dashboard__table-row">
                    {Object.entries(TABLE_INFO).map(
                      ([key, { headerText, borderLeft, borderRight, columnWidth, customClass }]: any[]) => (
                        <th
                          className={cn(
                            'dashboard__table-col dashboard__table-col--header',
                            {
                              'border-left': borderLeft === true,
                              'border-right': borderRight === true,
                              'wide-col': columnWidth === 'wide',
                              'narrow-col': columnWidth === 'narrow',
                            },
                            `${customClassCheck(customClass)}`
                          )}
                          onClick={() => handleHeaderClick(key)}
                          key={key}
                          title={headerText}
                        >
                          <div className="dashboard__sort">
                            <span className="dashboard__sort-text">{headerText}</span>
                            <span
                              className={cn('dashboard__sort-icon', {
                                'dashboard__sort-icon--active': dashboardSortKey === key,
                                'dashboard__sort-icon--ascending': dashboardSortKey === key && dashboardAscending,
                                'dashboard__sort-icon--descending': dashboardSortKey !== key || !dashboardAscending,
                              })}
                            >
                              <Icon icon="arrowup" type="large" />
                            </span>
                          </div>
                        </th>
                      )
                    )}
                  </tr>
                </thead>

                <tbody>
                  {processedData.map((data: any = {}, index) => {
                    if (!data.study_id) return null;
                    return (
                      <tr
                        className="dashboard__table-row"
                        key={index}
                        onClick={() =>
                          handlePatientClick({
                            study_id: data.study_id,
                            status: data.status,
                            user: data.user,
                            client_id: data.client_id,
                            can_be_viewed: data.can_be_viewed,
                          })
                        }
                      >
                        {Object.entries(TABLE_INFO).map(
                          ([key, { riskLevelMap, borderLeft, borderRight, columnWidth, customClass }]: any[]) => {
                            let value = data[key];
                            let analysisFailedDetailedDescription: string = '';
                            if (key === 'patient_name' && value === '-') {
                              value = data.study_id;
                            }

                            // Append MESA percentile to calcium score if it's available
                            // TODO: Add this back in, temporarily removed mesa percentile due to inconsistent results (SALIX-1059)
                            // if (
                            //   key === 'calcium_score' &&
                            //   data['mesa_percentile']
                            // ) {
                            //   value = `${value} (${data['mesa_percentile']}%)`;
                            // }

                            // Chris B: quick fixes for 1.3
                            if (key === 'workflow_status') {
                              if (value.state === 'Analysis Failed') {
                                analysisFailedDetailedDescription = value.detailed_description
                                  ? value.detailed_description.trim()
                                  : '';
                                value = value.short_description ? value.short_description : value.state;
                              } else {
                                value = value.state;
                              }
                            }

                            // RIS Icon
                            let risIcon: string | undefined = data.ris_status?.status;

                            if (key === 'ris' && risIcon) {
                              value = RIS_ICON_MAP[risIcon];
                            } else if (key === 'ris' && data.ris === undefined) {
                              value = <Icon icon="dash" type="small" customClass="dashboard__ris-icon" />;
                            }

                            return (
                              <td
                                title={value !== '-' ? value : null}
                                className={cn(
                                  'dashboard__table-col',
                                  riskLevelMap && RISK_LEVELS.includes(riskLevelMap[value.split(' ')[0]])
                                    ? `dashboard__table-col--risk-${riskLevelMap[value.split(' ')[0]]}`
                                    : '',
                                  {
                                    'dashboard__table-col--bold': !!riskLevelMap,
                                    'border-left': borderLeft === true,
                                    'border-right': borderRight === true,
                                    'wide-col': columnWidth === 'wide',
                                    'narrow-col': columnWidth === 'narrow',
                                  },
                                  `${customClassCheck(customClass)}`
                                )}
                                key={key}
                              >
                                {analysisFailedDetailedDescription ? (
                                  <div className="dashboard__cell">
                                    <div data-tip data-for="detailedAnalysis" className="dashboard__cell-value">
                                      {value}
                                    </div>
                                    <ReactTooltip
                                      getContent={() => <div>{analysisFailedDetailedDescription}</div>}
                                      place="top"
                                      type="dark"
                                      border
                                      borderColor={THEME.colors.global.white}
                                      effect="solid"
                                      id="detailedAnalysis"
                                    />
                                  </div>
                                ) : (
                                  <div className="dashboard__cell">
                                    <div className="dashboard__cell-value">{value}</div>
                                  </div>
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {!processedData.length && (
              <div className="dashboard__no-results">
                {decryptedStudies.length ? (
                  'No results'
                ) : (
                  <>
                    <Loader small inline />
                    &nbsp;&nbsp;Searching
                  </>
                )}
              </div>
            )}
            <div className="dashboard__footer">
              <div className="dashboard__decrypting">
                {!!processedData.length && decryptedStudies.length < memoizedDashboardData.length && !isArtryaUser && (
                  <>
                    <Loader small inline />
                    <span>Loading dashboard data</span>
                  </>
                )}
              </div>
              <Pagination
                numPages={numPages}
                currentPage={dashboardCurrentPage}
                onPageChange={(value) => {
                  dashboardDispatch(dashboardActions.setDashboardCurrentPage(value));
                }}
              />
            </div>
          </div>
        </div>
      )}

      <ActionModal
        confirmText={'OK'}
        onConfirm={() => {
          setShowLockedMessage(null);
        }}
        visible={showLockedMessage != null && showLockedMessage.length > 0}
        headerContent={<>LOCKED FOR REVIEW</>}
      >
        <p>{showLockedMessage}</p>
      </ActionModal>
      <ActionModal
        confirmText={'OK'}
        onConfirm={() => setShowDataNotAvailableReason('')}
        visible={showDataNotAvailableReason.length > 0}
        headerContent={<>DATA UNAVAILABLE</>}
      >
        <p>{showDataNotAvailableReason}</p>
      </ActionModal>
    </>
  );
}
