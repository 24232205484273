import { useEffect, useState } from 'react';
import { ClientConfig } from '../context/types';
import { showToast } from '../components/Toast/showToast';
import * as api from '../utils/api';
import { updatePhiServiceUrl } from '../utils/phi';

/**
 * Fetch the client config object from the API. If the PHI API URL is set, this function will also check if the API is
 * reachable. If the API can't be reached, the config object's `phi_service_url` value will be set to `null` before it
 * is returnd.
 */
async function fetchClientConfig(): Promise<ClientConfig> {
  const clientConfig = (await api.getJSON('/client/details')) as ClientConfig;

  if (clientConfig.phi_service_url) {
    const reachable = await checkURLReachable(clientConfig.phi_service_url);
    if (!reachable) {
      console.warn('Configured PHI API URL not reachable, discarding');
      clientConfig.phi_service_url = null;
    }
  }

  updatePhiServiceUrl(clientConfig.phi_service_url);
  return clientConfig;
}

/**
 * Check if the given URL is reachable, using fetch. This function only checks reachability, and ignores any response
 * codes.
 */
async function checkURLReachable(url: string): Promise<boolean> {
  try {
    await fetch(url, {
      mode: 'no-cors',
    });

    return true;
  } catch {
    return false;
  }
}

/**
 * Hook for fetching the client config object from the API. The config is re-fetched any time the given fetchDep
 * parameter changes and is non-null.
 *
 * @param fetchDep Parameter that is passed as a dependency to a useEffect hook that fetches the client config object
 * @returns The client config object, or undefined if it is yet to be fetched
 */
export function useClientConfig(fetchDep: any | null, isAuditor: boolean = false): ClientConfig | undefined {
  const [clientConfig, setClientConfig] = useState<ClientConfig | undefined>(undefined);

  // Fetch client config from the API when auth data changes
  useEffect(() => {
    let isCancelled = false;

    if (fetchDep !== null && !isAuditor) {
      async function fetchConfig() {
        try {
          const clientConfig = await fetchClientConfig();
          setClientConfig(clientConfig);
        } catch {
          if (!isCancelled) {
            showToast.error('Failed to fetch client config');
            setClientConfig(undefined);
          }
        }
      }

      fetchConfig();
    }

    return () => {
      isCancelled = true;
    };
  }, [fetchDep, isAuditor]);

  return clientConfig;
}
