// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ELLIPSE_STATE, Measurement, RULER_STATE, TOOL_TYPES } from '../../utils/measurementTools/types';

export type measurementToolsState = {
  selectedToolType: TOOL_TYPES | undefined;
  isMeasurementMode: boolean;
  measurements: MeasurementMap;
};

type MeasurementMap = Record<string, Measurement[]>;

export const initialMeasurementToolsState = (): measurementToolsState => {
  return {
    selectedToolType: undefined,
    isMeasurementMode: false,
    measurements: {},
  };
};

export const measurementToolsSlice = createSlice({
  name: 'measurementTools',
  initialState: initialMeasurementToolsState(),
  reducers: {
    setSelectedToolType: (state, action: PayloadAction<TOOL_TYPES | undefined>) => {
      state.selectedToolType = action.payload;
      state.isMeasurementMode = action.payload !== undefined;
    },
    addMeasurement: (state, action: PayloadAction<Measurement>) => {
      // Shallow copy the measurements for all views.
      const result: MeasurementMap = state.measurements;
      // Add the new measurement to the view it belongs to, updating the data for that entire view.
      const measurement = action.payload;
      result[measurement.viewId] = result[measurement.viewId]
        ? [...result[measurement.viewId], measurement]
        : [measurement];
    },
    updateMeasurement: (state, action: PayloadAction<Partial<Measurement>>) => {
      const measurement = action.payload;
      // Shallow copy the measurements for all views.
      const result: MeasurementMap = state.measurements;
      // Look through every view.
      Object.keys(result).forEach((viewId: string) => {
        const index = result[viewId].findIndex(
          (existingMeasurement: Measurement) => existingMeasurement.measurementId === measurement.measurementId
        );
        // If this view contains the measurement then update the data for that entire view.
        if (index >= 0) {
          result[viewId] = [...result[viewId]];
          result[viewId][index] = {
            ...result[viewId][index],
            ...measurement,
          };
        }
      });
    },
    deleteMeasurement: (state, action: PayloadAction<string>) => {
      const measurementId = action.payload;
      // Look through every view.
      state.measurements = Object.keys(state.measurements).reduce((result, viewId) => {
        const measurements = state.measurements[viewId];
        // If this view contains the measurement then update the data for that entire view.
        const filteredMeasurements = measurements.filter(
          (existingMeasurement: Measurement) => existingMeasurement.measurementId !== measurementId
        );
        return { ...result, [viewId]: filteredMeasurements };
      }, {});
    },
    inactivateMeasurements: (state) => {
      // Shallow copy the measurements for all views.
      const result: MeasurementMap = state.measurements;
      // Look through every view.
      Object.keys(result).forEach((viewId: string) => {
        // If the view has any active measurements then the data for that entire view needs to be updated.
        if (
          result[viewId].find(
            (measurement: Measurement) =>
              measurement.state !== RULER_STATE.Inactive && measurement.state !== ELLIPSE_STATE.Inactive
          )
        ) {
          result[viewId] = result[viewId].map((measurement: Measurement) => {
            // Only update the measurement object if it needs to be changed.
            if (measurement.state !== RULER_STATE.Inactive && measurement.state !== ELLIPSE_STATE.Inactive) {
              return {
                ...measurement,
                state: measurement.type === TOOL_TYPES.Ruler ? RULER_STATE.Inactive : ELLIPSE_STATE.Inactive,
              };
            }
            return measurement;
          });
        }
      });
    },
    clearMeasurements: (state) => {
      // Remove all measurements for all views.
      state.measurements = {};
    },
  },
});

export const measurementToolsActions = measurementToolsSlice.actions;
