import React from 'react';
import { ReviewCard } from '../../../components/ReviewCard/ReviewCard';
import { DATETIME_FORMAT } from '../../../config';
import { Study } from '../../../context/types';
import { formatDate } from '../WorkflowActions';

interface RecentlyApprovedStudiesProps {
  studies: Study[];
  openStudyReport: (study: Study) => void;
}

export const RecentlyApprovedStudies: React.FunctionComponent<RecentlyApprovedStudiesProps> = (props) => {
  if (props.studies.length < 1) {
    return null;
  }

  return (
    <>
      {props.studies.map((study, idx) => {
        return (
          study.report_approval && (
            <ReviewCard
              idx={idx}
              category="recently approved"
              key={`recently-approved__${idx}`}
              patient={study.patient_name || ''}
              assignee={''}
              assigner={''}
              assignedDate={formatDate(study.report_approval.timestamp, DATETIME_FORMAT)}
              reviewStatus={'approved'}
              onConfirm={() => props.openStudyReport(study)}
            />
          )
        );
      })}
    </>
  );
};
