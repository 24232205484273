import Slider from 'rc-slider';
import React from 'react';

interface SliceSliderProps {
  min?: number;
  max?: number;
  reverse?: boolean;
  marks?: { [key: number]: any };
  setValue?: number;
  onChange?: (value: number) => void;
  onBeforeChange?: () => void;
  onAfterChange?: () => void;
}

export const SliceSlider: React.FunctionComponent<SliceSliderProps> = ({
  setValue = 0,
  reverse = true,
  min = 0,
  max = 100,
  marks,
  onChange, // called as dragging slider
  onBeforeChange, // called on mouse down (or touchstart)
  onAfterChange, // called on mouse up
}) => {
  //there is an offset when the slider is reversed, between slider-step and slider-mark
  //the css slice-slider-reverse is used to avoid the offset when the slider is reversed
  return (
    // This little wrapper prevents mouse down events on the slider from propagating through
    // to the view underneath. We were getting slice changes create measurements on the
    // NonContrastViewer which is not desirable.
    <div onMouseDown={(event: React.MouseEvent) => event.stopPropagation()}>
      <Slider
        className={reverse === false ? 'slice-slider-reverse' : ''}
        vertical
        reverse={reverse}
        value={setValue}
        marks={marks}
        max={max}
        min={min}
        onChange={onChange}
        onBeforeChange={onBeforeChange}
        onAfterChange={onAfterChange}
      />
    </div>
  );
};
