import { useAppSelector } from '../hooks';

/**
 * Returns styles that control page visibility based on the active tab index passed in
 * @param {number} index
 */
export default function useStudyTabVisibility(index: number) {
  const visibleTab = useAppSelector((state) => state.globalFlags.visibleTab);
  const getStyles = (): React.CSSProperties | undefined => {
    if (visibleTab !== index) {
      return {
        position: 'absolute',
        top: '-200vh',
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        opacity: 0,
        pointerEvents: 'none',
      };
    }

    return undefined;
  };

  return {
    getStyles,
  };
}
