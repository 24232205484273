import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReleaseVersionResponse } from './releaseVersionLoader';

export const initialReleaseVersionState = (): ReleaseVersionResponse => {
  return {
    content: null,
    udiVersion: null,
    releaseVersion: null,
  };
};

export const releaseVersionSlice = createSlice({
  name: 'releaseVersion',
  initialState: initialReleaseVersionState(),
  reducers: {
    setReleaseVersion: (state, action: PayloadAction<ReleaseVersionResponse>) => {
      state.content = action.payload.content;
      action.payload.content !== null &&
        Object.entries(action.payload.content).map(([key, serviceVersionList]) => {
          if (serviceVersionList.serviceName === 'UDI') {
            state.udiVersion = serviceVersionList.serviceVersion;
          }
          if (serviceVersionList.serviceName === 'release') {
            state.releaseVersion = serviceVersionList.serviceVersion;
          }
          return null;
        });
    },
  },
});

export const releaseVersionActions = releaseVersionSlice.actions;
