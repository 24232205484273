import cn from 'classnames';
import React, { ReactElement } from 'react';
import ResetViewsButton from '../../views/CTVolume/ResetViewsButton/ResetViewsButton';
import Button from '../Button/Button';
import MeasurementToolbar from '../../components/measurementToolbar/MeasurementToolbar';
import { useIsNonContrastSelector } from '../../hooks/useViewConfig';
import { useDashboardSelector } from '../../dashboardHooks';
import { useAppSelector } from '../../hooks';
import { useOnVisibleViewCountButton } from '../../hooks/useContrastViewHelpers';

const VIEW_CONFIG_BUTTONS = [1, 2, 4];

export default function ViewConfig(): ReactElement {
  const { visibleViews } = useAppSelector((state) => state.contrast);
  const isNonContrast = useIsNonContrastSelector();
  const { clientConfig } = useDashboardSelector((state) => state.user);
  const onVisibleViewCountButton = useOnVisibleViewCountButton();

  return (
    <>
      <div className="viewconfig-header">
        {clientConfig?.measurement_enabled && (
          <span className="disclaimer-text viewconfig-measurements">Ruler tools for research purposes only.</span>
        )}
      </div>
      <span className="viewconfig-subtitle">Views</span>
      <div className="viewport-config-buttons">
        {VIEW_CONFIG_BUTTONS.map((targetNumberOfViews, index) => {
          const selected: boolean = isNonContrast
            ? targetNumberOfViews === 1
            : visibleViews.length === targetNumberOfViews;
          const disabled = isNonContrast && targetNumberOfViews > 1;

          return (
            <div
              key={`button-${targetNumberOfViews}-${index}`}
              className={cn('button-container', {
                'button-container__selected': selected,
              })}
            >
              <Button
                onClick={() => {
                  onVisibleViewCountButton(targetNumberOfViews);
                }}
                disabled={disabled}
                theme="borderonly"
                selected={selected}
              >
                <div
                  className={cn('viewport-button', {
                    'viewport-button__selected': selected,
                    'viewport-button__disabled': disabled,
                  })}
                >
                  <div
                    className={cn('viewport-row viewport-row__top', {
                      'viewport-row__top__selected': selected,
                      'viewport-row__top__disabled': targetNumberOfViews >= 3 && disabled,
                      'viewport-row__top_hide_bottom_border': targetNumberOfViews < 3,
                    })}
                  >
                    <div
                      className={cn('viewport-cell viewport-cell__left', {
                        'viewport-cell__left__selected': selected,
                        'viewport-cell__left__disabled': disabled,
                        'viewport-cell__left_hide_right_border': targetNumberOfViews === 1,
                      })}
                    />
                    <div className="viewport-cell viewport-cell__right" />
                  </div>
                  <div className="viewport-row viewport-row__bottom">
                    <div
                      className={cn('viewport-cell viewport-cell__left', {
                        'viewport-cell__left__selected': selected,
                        'viewport-cell__left__disabled': disabled,
                        'viewport-cell__left_hide_right_border': targetNumberOfViews === 1,
                      })}
                    />
                    <div className="viewport-cell viewport-cell__right" />
                  </div>
                </div>
              </Button>
            </div>
          );
        })}
        <div className="config">
          {clientConfig?.measurement_enabled && <MeasurementToolbar />}
          <ResetViewsButton />
        </div>
      </div>
    </>
  );
}
