import vtkMatrixBuilder from 'vtk.js/Sources/Common/Core/MatrixBuilder';
import vtkCoordinate from 'vtk.js/Sources/Rendering/Core/Coordinate';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkCamera from 'vtk.js/Sources/Rendering/Core/Camera';
import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume';
import { vec2, vec3, mat4 } from 'gl-matrix';
import { Point2 } from '../ReactVTKJSTypes';
import { WindowLevels } from '../../../../reducers/window/types';

/**
 * A helper function to convert from display coordinates [X, Y] to world coordinates [X, Y, Z].
 * vtkjs provides the vtkCoordinate.getComputedWorldValue() which sadly doesn't set the effective Z
 * the way we want it. The Z is assumed to be sitting on the near clipping plane and we want it to
 * be set on the same plane as the focal point of the camera. The near clipping plane is set to
 * the distance to the focal point - 1/2 the current thickness and the far plane is the distance to
 * the focal point + 1/2 the current thickness. FYI we set the thickness via
 * camera.setThicknessFromFocalPoint().
 * @param renderer The renderer belonging to the view to make this calculation on.
 * @param pos [X, Y] The display coordinates.
 * @return [X, Y, Z] world coordinates set at the same distance from the camera as the focal point.
 */
export function displayToWorld(renderer: vtkRenderer, pos: vec2) {
  // Get the camera from the renderer.
  const camera = renderer.getActiveCamera();

  // Set the display position.
  const displayPos = vtkCoordinate.newInstance();
  displayPos.setCoordinateSystemToDisplay();
  displayPos.setValue([pos[0], pos[1], 0]);
  // Convert the display position to a world position (with a distance from the camera equal to the distance
  // to the near focal plane from the camera (ie The distance from the camera to the focal point - 1/2 the thickness)
  const worldPos: vec3 = displayPos.getComputedWorldValue(renderer) as vec3;

  // Now calculate a vector aligned with the camera's direction of projection and with the length of the distance
  // from the near clipping plane to the focal point's plane.
  const cameraVector: vec3 = camera.getDirectionOfProjection() as vec3;
  vec3.normalize(cameraVector, cameraVector);
  vec3.scale(cameraVector, cameraVector, camera.getThickness() / 2);

  // Add this vector to our computed worldPos to get the position on the focal plane.
  vec3.add(worldPos, worldPos, cameraVector);
  return worldPos;
}

/**
 * An implementation of displayToWorld that expects the pos as { x: number, y: number } vs [X, Y].
 */
export function displayToWorldFromXY(renderer: vtkRenderer, pos: Point2) {
  return displayToWorld(renderer, [pos.x, pos.y]);
}

/**
 * A helper function to convert from world coordinates [X, Y, Z] to display coordinates [X, Y].
 * @param renderer The renderer belonging to the view to make this calculation on.
 * @param pos [X, Y, Z] world coordinates.
 * @return [X, Y] The display coordinates.
 */
export function worldToDisplay(renderer: vtkRenderer, pos: vec3): vec2 {
  const worldPos = vtkCoordinate.newInstance();
  worldPos.setCoordinateSystemToWorld();
  worldPos.setValue([...pos]);
  return worldPos.getComputedDoubleDisplayValue(renderer) as vec2;
}

/**
 * A helper function to convert an array of world points into an array of display points.
 * @param renderer The renderer belonging to the view to make this calculation on.
 * @param width The width of the view in pixels.
 * @param height The height of the view in pixels.
 * @param points [][X, Y, Z] world coordinates.
 * @return [][X, Y, Z] display coordinates.
 */
export function worldArrayToDisplayArray(renderer: vtkRenderer, width: number, height: number, points: vec3[]): vec3[] {
  const camera = renderer.getActiveCamera();
  const aspect = width / height;

  // Get the view transformation from the camera.
  const viewMatrix = camera.getViewMatrix();
  if (!viewMatrix) {
    return [];
  }
  mat4.transpose(viewMatrix, viewMatrix);

  // Get the projection transformation from the camera.
  const projMatrix = camera.getProjectionMatrix(aspect, -1.0, 1.0);
  if (!projMatrix) {
    return [];
  }
  mat4.transpose(projMatrix, projMatrix);

  return points.map((point) => {
    const result: vec3 = [0, 0, 0];
    // Apply the view transformation.
    vec3.transformMat4(result, point, viewMatrix);
    // Remember the z coordinate.
    const z = result[2];
    // Apply the projection transformation.
    vec3.transformMat4(result, result, projMatrix);
    // Transform to display coordinates (NOTE we flip the Y coordinate).
    result[0] = (0.5 * result[0] + 0.5) * (width - 1.0) + 0.5;
    result[1] = (0.5 - 0.5 * result[1]) * (height - 1.0) + 0.5;
    // Restore the z coordinate (we want the distance from the camera along the axis of the camera direction).
    result[2] = -z;
    return result;
  });
}

/**
 * A helper function to convert from and array of world coordinates [][X, Y, Z] to an SVG path of
 * display coordinates [X, Y] -> MX,Y,LX,Y...
 * @param renderer The renderer belonging to the view to make this calculation on.
 * @param width The width of the view in pixels.
 * @param height The height of the view in pixels.
 * @param points [][X, Y, Z] world coordinates.
 * @return The string representation of the SVG path.
 */
export function worldArrayToDisplayPath(renderer: vtkRenderer, width: number, height: number, points: vec3[]) {
  let result = undefined;
  // Check we have at least two points to add.
  if (points && points.length >= 2) {
    const views = renderer?.getRenderWindow()?.getViews();
    if (views) {
      // Transform all the points from world space to display space.
      const displayPoints = worldArrayToDisplayArray(renderer, width, height, points);

      // Add the initial point.
      result = `M${displayPoints[0][0]},${displayPoints[0][1]}`;
      // Add all the other points.
      for (let index = 1; index < points.length; index++) {
        result += `L${displayPoints[index][0]},${displayPoints[index][1]}`;
      }
    }
  }

  return result;
}

/**
 * Mutate the array of points [X, Y, Z] in display coordinated that defines a line path to add points where the plane parallel
 * to the screen and of the specified distance into the scene is intersected.
 */
function addPlaneIntersections(displayPoints: vec3[], planeDistance: number) {
  // Iterate through each line segment and create additional points where the line segment intersects the near clipping plane.
  for (let index = 0; index < displayPoints.length - 1; index++) {
    const z0 = displayPoints[index][2];
    const z1 = displayPoints[index + 1][2];
    // It's silly having these set as constants for a single comparison but prettier keeps removing the formatting that lint
    // needs to avoid a warning so ... constants it is.
    const z0LessEqual = z0 <= planeDistance;
    const z1LessEqual = z1 <= planeDistance;
    if (z0LessEqual !== z1LessEqual) {
      const vector: vec3 = [0, 0, 0];
      vec3.subtract(vector, displayPoints[index + 1], displayPoints[index]);
      const prop1 = (planeDistance - z0) / vector[2];
      vec3.scale(vector, vector, prop1);
      vec3.add(vector, vector, displayPoints[index]);
      displayPoints.splice(index + 1, 0, vector);
      index++;
    }
  }
}

/**
 * As per worldArrayToDisplayPath but returns three seperate paths { near: string, active: string, far: string }.
 * These are for the path in front of the slab, inside the slab, and behind the slab.
 * @param thickness The thickness of the active slab which is parallel to the screen and centered on the focal point of the camera.
 * @param generateNearAndFar If false the near and far paths will not be calculated.
 */
export function worldArrayToDisplayPathsByThickness(
  renderer: vtkRenderer,
  width: number,
  height: number,
  points: vec3[],
  thickness: number,
  generateNearAndFar: boolean
) {
  let result = {
    near: '',
    active: '',
    far: '',
  };

  // Check we have at least two points to add.
  if (points && points.length >= 2) {
    const views = renderer?.getRenderWindow()?.getViews();
    if (views) {
      const camera = renderer.getActiveCamera();

      // Calculate the distance to the focal plane.
      const focalDistance = vec3.distance(camera.getPosition() as vec3, camera.getFocalPoint() as vec3);
      const nearPlane = focalDistance - 0.5 * thickness;
      const farPlane = focalDistance + 0.5 * thickness;

      // Transform all the points from world space to display space.
      const displayPoints = worldArrayToDisplayArray(renderer, width, height, points);

      // Iterate through each line segment and create additional points where the line segment intersects the near clipping plane.
      addPlaneIntersections(displayPoints, nearPlane);
      // Iterate through each line segment and create additional points where the line segment intersects the far clipping plane.
      addPlaneIntersections(displayPoints, farPlane);

      // Iterate through each point and add a line segment if this is point is inside the active volume.
      let drawing = false; // Are we currently drawing the path?
      for (let index = 0; index < displayPoints.length; index++) {
        // Is this point active?
        if (displayPoints[index][2] >= nearPlane && displayPoints[index][2] <= farPlane) {
          if (!drawing) {
            result.active += `M${displayPoints[index][0]},${displayPoints[index][1]}`;
            drawing = true;
          } else {
            result.active += `L${displayPoints[index][0]},${displayPoints[index][1]}`;
          }
        } else {
          drawing = false;
        }
      }

      if (generateNearAndFar) {
        // Iterate through each point and add a line segment if this is point is nearer than the active volume.
        drawing = false;
        for (let index = 0; index < displayPoints.length; index++) {
          // Is this point near?
          if (displayPoints[index][2] <= nearPlane) {
            if (!drawing) {
              result.near += `M${displayPoints[index][0]},${displayPoints[index][1]}`;
              drawing = true;
            } else {
              result.near += `L${displayPoints[index][0]},${displayPoints[index][1]}`;
            }
          } else {
            drawing = false;
          }
        }

        // Iterate through each point and add a line segment if this is point is further than the active volume.
        drawing = false;
        for (let index = 0; index < displayPoints.length; index++) {
          // Is this point far?
          if (displayPoints[index][2] >= farPlane) {
            if (!drawing) {
              result.far += `M${displayPoints[index][0]},${displayPoints[index][1]}`;
              drawing = true;
            } else {
              result.far += `L${displayPoints[index][0]},${displayPoints[index][1]}`;
            }
          } else {
            drawing = false;
          }
        }
      }
    }
  }

  return result;
}

/**
 * Get the slice this point belongs to in relation to the camera position and direction of projection.
 * @param camera The camera object to determine the slice number in relation to.
 * @param worldPos [X, Y, Z] in world coordinates.
 */
export function getSliceFromPoint(camera: vtkCamera, worldPos: vec3) {
  const cameraDirection = camera.getDirectionOfProjection();
  const transform = vtkMatrixBuilder.buildFromDegree().identity().rotateFromDirections(cameraDirection, [1, 0, 0]);
  const mutatedWorldPos = worldPos.slice();
  transform.apply(mutatedWorldPos);
  return mutatedWorldPos[0];
}

/**
 * Move the camera forward to backwards to position its focal point on the specified slice.
 * NOTE: This does no re-render the view or the crosshairs.
 * @param camera The camera object to adjust.
 * @param slice The slice to move to (in relation to the current camera orientation).
 */
export function moveCameraToSlice(camera: vtkCamera, slice: number) {
  // Get the camera's current slice (distance of the focal point along the camera's direction of projection.
  const currentSlice = getSliceFromPoint(camera, camera.getFocalPoint() as vec3);

  // Get the translation vector (that is aligned with the camera direction) that will move the camera
  // to the correct position.
  const cameraVector: vec3 = [0, 0, 0];
  vec3.normalize(cameraVector, camera.getDirectionOfProjection() as vec3);
  vec3.scale(cameraVector, cameraVector, slice - currentSlice);

  // Calculate the new camera position and focal point.
  const newCameraFocalPoint: vec3 = [0, 0, 0];
  vec3.add(newCameraFocalPoint, camera.getFocalPoint() as vec3, cameraVector);
  const newCameraPos: vec3 = [0, 0, 0];
  vec3.add(newCameraPos, camera.getPosition() as vec3, cameraVector);

  // Move the camera focal point and position.
  camera.setFocalPoint(...newCameraFocalPoint);
  camera.setPosition(...newCameraPos);
}

/**
 * Move the camera forward to backwards to position its focal point on the same plane as the point provided.
 * NOTE: This does no re-render the view or the crosshairs.
 * @param camera The camera object to adjust.
 * @param worldPos [X, Y, Z] in world coordinates of the point who's plane we want to align with.
 */
export function moveCameraToSliceFromPoint(camera: vtkCamera, worldPos: vec3) {
  // Get the desired slice (distance of the world point along the camera's direction of projection.
  const slice = getSliceFromPoint(camera, worldPos);
  // Move the camera to this slice.
  moveCameraToSlice(camera, slice);
}

/**
 * Calculate the rgbTransferFunction range from the window levels.
 */
export function windowLevelsToRange(windowLevels: WindowLevels): [number, number] {
  return [
    windowLevels.windowCenter - windowLevels.windowWidth / 2,
    windowLevels.windowCenter + windowLevels.windowWidth / 2,
  ];
}

/**
 * Calculate the window levels from a rgbTransferFunction range.
 */
export function rangeToWindowLevels(range: [number, number]): WindowLevels {
  if (!range) return { windowWidth: 0, windowCenter: 0 };
  const windowWidth = Math.abs(range[1] - range[0]);
  const windowCenter = range[0] + (range[1] - range[0]) / 2;
  return { windowWidth, windowCenter };
}

/**
 * Set the windowLevels on a vtkVolume.
 * @param windowLevels: { windowWidth: number, windowCenter: number } | undefined
 */
export function setWindowLevels(windowLevels: WindowLevels, volume: vtkVolume) {
  if (windowLevels) {
    const defaultRange = windowLevelsToRange(windowLevels);
    const property = volume.getProperty() as any;
    property.getRGBTransferFunction(0).setRange(defaultRange[0], defaultRange[1]);
  }
}

/**
 * Get the scaling to apply to SVG stroke widths based on the screen resolution.
 */
export function getScreenScale() {
  if (window && window.screen && window.screen.height && window.devicePixelRatio) {
    return (window.screen.height * window.devicePixelRatio) / 2880;
  }
  return 1;
}
