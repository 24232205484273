import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import * as api from '../../utils/api';
import { InitialLoader, LoadState } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, useNeedsLoading } from '../initialLoad/utils';
import {
  updateCalciumScore,
  updateContrastLesionData,
  updateCurrentStudy,
  updatePatientStats,
  updateStudyData,
  updateVesselData,
} from './utils';
import {
  validateStudyData,
  validatePatientSummary,
  validateVesselData,
  validateVesselSliceData,
  validateVesselSegmentSummary,
  validateLesionData,
} from './validations';
import { studyActions } from '../study/studySlice';
import { Study, StudyData } from '../../context/types';
import { patientActions } from '../patient/patientSlice';
import {
  ContrastSummaryInterface,
  LesionInterface,
  NewDataResponse,
  nonContrastInterface,
  PatientSummaryInterface,
  RunInterface,
  StudyInterface,
  VesselSegmentSummariesInterface,
  VesselsInterface,
  VesselSlicesInterface,
} from './types';
import { vesselDataActions } from '../vesselData/vesselDataSlice';
import { lesionActions } from '../lesion/lesionSlice';
import { VesselDataResponse } from '../vesselData/types';
import { ContrastLesionDataResponse } from '../lesion/types';
import { calciumScoreActions } from '../calciumScore/calciumScoreSlice';
import { useNavigate } from 'react-router-dom';
import showToast from '../../components/Toast/showToast';

const API_PLATFORM_BASE_URL = process.env.REACT_APP_PLATFORM_BASE_URL;
const loaderName = InitialLoader.NEW_STUDY;

/**
 * Load the study data from new endpoint and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export interface NewStudyResponseContent {
  run: RunInterface;
  study: StudyInterface;
  patientSummary: PatientSummaryInterface;
  contrastSummary: ContrastSummaryInterface;
  aortaGeometry: { [key: string]: number };
  vessels: VesselsInterface[];
  vesselSlices: VesselSlicesInterface[];
  lesions: LesionInterface[];
  vesselSegmentSummaries: VesselSegmentSummariesInterface[];
  nonContrastResult: nonContrastInterface;
}

export function loadStudy(
  studyData: StudyData,
  currentStudy: Study,
  contrastLesionData: ContrastLesionDataResponse,
  vesselData: VesselDataResponse,
  studyId: string,
  dispatch: AppDispatch,
  initialLoad: boolean,
  navigate: (path: string) => void
) {
  const promise = api.getJSON(`${API_PLATFORM_BASE_URL}/webapp/study/${studyId}`).catch((error) => {
    // NOTE: We currently want to catch this error as failing to load the new study should
    // not prevent the study from being opened. This may change in the future though...
  });
  loadWithPromise(
    promise,
    dispatch,
    initialLoad,
    loaderName,
    async ({ successful, display_message, content }: NewDataResponse<NewStudyResponseContent>) => {
      // if a study is not found return to dashboard
      if (!successful) {
        // return to dashboard if the study is not found in platform
        showToast.error(display_message);
        navigate(`/`);
        return;
      }
      const {
        study,
        patientSummary,
        vessels,
        vesselSegmentSummaries,
        lesions,
        vesselSlices,
        nonContrastResult,
      } = content;
      // validate that the study has the required fields
      const warnings = [
        ...validateStudyData(study),
        ...validatePatientSummary(patientSummary),
        ...validateVesselData(vessels[0]),
        ...validateVesselSliceData(vesselSlices[0]),
        ...validateLesionData(lesions),
        ...validateVesselSegmentSummary(vesselSegmentSummaries[0]),
      ];

      // if the study is not valid then return to dashboard
      if (warnings.length > 0) {
        console.warn(...warnings);
        // if the study is not valid then the current study needs to be decryped at this point.
        navigate(`/`);
        return;
      }

      // update calcium score
      let newCalciumScore = null;
      if (nonContrastResult) {
        newCalciumScore = updateCalciumScore(nonContrastResult);
      }
      dispatch(calciumScoreActions.setCalciumScoreData(newCalciumScore));

      // update patient stats
      const newPatientStats = updatePatientStats(patientSummary, newCalciumScore?.total);
      dispatch(patientActions.setPatientStats(newPatientStats));

      // loop through vesselSlices and get the stenosis value.
      const vesselStenosis = vesselSlices.reduce((acc, { vesselKey, vesselSlices }) => {
        const stenosisValues = vesselSlices.map((slice: { stenosis: number }) => slice.stenosis);
        return { ...acc, [vesselKey.toLowerCase()]: stenosisValues };
      }, {});

      dispatch(patientActions.setStenosis(vesselStenosis));

      //update vessel data
      const newVesselData = updateVesselData(
        vesselData,
        vessels,
        vesselSlices,
        vesselSegmentSummaries,
        lesions,
        newCalciumScore
      );
      dispatch(vesselDataActions.setVesselData(newVesselData));

      // update study data
      const vesselKeys = vessels.map(({ key }) => key);
      const newStudyData = updateStudyData(studyData, study, patientSummary, vesselKeys, newCalciumScore?.total);
      dispatch(studyActions.setStudyData(newStudyData));

      // update current study
      const newCurrentStudy = await updateCurrentStudy(currentStudy, study, patientSummary, newCalciumScore?.total);

      dispatch(studyActions.setCurrentStudy(newCurrentStudy));

      // update contrast Lesion Data
      const newContrastLesion = updateContrastLesionData(lesions, contrastLesionData);

      dispatch(lesionActions.setContrastLesionData(newContrastLesion));
    }
  );
}

/**
 * A component to load the initial study data for a study and add it to the store.
 */
interface StudyLoaderProps {
  studyId?: string;
}
export const NewStudyLoader: React.FunctionComponent<StudyLoaderProps> = ({ studyId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const needsLoading = useNeedsLoading(loaderName);
  const vesselData = useAppSelector((state) => state.vesselData.vesselData);
  // For studies that failed AI analysis the study  may be null, we need to check if the attempt to load them has finished vs the data itself.
  const studyDataLoaded = useAppSelector(
    (state) => state.initialLoad.loadState[InitialLoader.STUDY] === LoadState.LOADED
  );

  const contrastLesionDataLoaded = useAppSelector(
    (state) => state.initialLoad.loadState[InitialLoader.CONTRAST_LESION_DATA] === LoadState.LOADED
  );

  const { studyData, currentStudy } = useAppSelector((state) => state.study);
  const { contrastLesionData } = useAppSelector((state) => state.lesion);

  useEffect(() => {
    if (needsLoading && studyId && studyDataLoaded && currentStudy && contrastLesionDataLoaded) {
      if (studyData != null && contrastLesionData != null && vesselData != null) {
        loadStudy(studyData, currentStudy, contrastLesionData, vesselData, studyId, dispatch, true, navigate);
      }
    }
  }, [
    needsLoading,
    dispatch,
    studyId,
    studyData,
    currentStudy,
    studyDataLoaded,
    contrastLesionData,
    contrastLesionDataLoaded,
    vesselData,
    navigate,
  ]);

  return null;
};
