import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { lesionActions } from './lesionSlice';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { fetchWebData, useNeedsLoading } from '../initialLoad/utils';
import { ContrastLesionDataResponse, LesionDataResponse } from './types';

const lesionloaderName = InitialLoader.LESION;
const contrastLesionloaderName = InitialLoader.CONTRAST_LESION_DATA;

/**
 * Load the lesion from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function LesionData(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  return fetchWebData<LesionDataResponse>(unifiedId, dispatch, initialLoad, lesionloaderName, (data) => {
    dispatch(lesionActions.setLesionData(data));
  });
}

/**
 * Load the lesion from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function ContrastLesionData(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  return fetchWebData<ContrastLesionDataResponse>(
    unifiedId,
    dispatch,
    initialLoad,
    contrastLesionloaderName,
    (data) => {
      dispatch(lesionActions.setContrastLesionData(data));
    }
  );
}

/**
 * A component to load the lesion data for a study and add it to the store.
 */
export const LesionLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useNeedsLoading(lesionloaderName);
  const contrastLesionNeedsLoading = useNeedsLoading(contrastLesionloaderName);

  useEffect(() => {
    if (unifiedId && needsLoading) {
      LesionData(unifiedId, dispatch, true);
    }
    if (unifiedId && contrastLesionNeedsLoading) {
      ContrastLesionData(unifiedId, dispatch, true);
    }
  }, [unifiedId, needsLoading, dispatch, contrastLesionNeedsLoading]);

  return null;
};
