import { useEffect, useRef } from 'react';

export default function usePreviousValue<Type>(value: Type) {
  const valueRef = useRef<Type>();

  useEffect(() => {
    valueRef.current = value;
  });
  return valueRef.current;
}
