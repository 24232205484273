import cn from 'classnames';
import React, { useState } from 'react';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import Button from '../../components/Button/Button';
import { IconButton } from '../../components/IconButton/IconButton';
import { ReviewType } from '../../context/types';

interface ReviewCardProps {
  idx?: number;
  category: 'for review' | 'awaiting review' | 'awaiting approval' | 'recently approved';
  comment?: { message: string; action: () => void };
  patient: string;
  assigner: string;
  assignee: string;
  assignedDate: string;
  reviewStatus?: ReviewType | 'approved';
  onConfirm: () => void;
}

export const ReviewCard: React.FunctionComponent<ReviewCardProps> = ({
  idx,
  comment,
  category,
  reviewStatus,
  patient,
  assigner,
  assignee,
  assignedDate,
  onConfirm,
}) => {
  const [visible, setVisible] = useState(false);
  const [isAwaiting] = useState(category?.includes('awaiting'));

  const getConfirmText = () => {
    if (isAwaiting) return 'Open';
    return reviewStatus === 'review' ? 'Review' : 'Review and approve';
  };

  return (
    <div className={cn('review-card', category)} aria-label={idx === 0 ? category : ''}>
      <h2 className="review-card__heading">{patient}</h2>
      <dl className="value-list">
        {category !== 'recently approved' && (
          <div className="value-list__item">
            <dt className="value-list__label">{category === 'for review' ? 'Assigned by' : 'Assigned to'}</dt>
            <dd className="value-list__value">{category === 'for review' ? assigner : assignee}</dd>
          </div>
        )}
        <div className="value-list__item">
          <dt className="value-list__label">Date</dt>
          <dd className="value-list__value">{assignedDate}</dd>
        </div>
      </dl>
      <div className="review-card__actions">
        {category === 'for review' ? (
          <Button onClick={onConfirm}>{getConfirmText()}</Button>
        ) : (
          <Button theme="secondary" onClick={onConfirm}>
            {category === 'recently approved' ? 'Amend' : 'Cancel'}
          </Button>
        )}
      </div>
      {comment && (
        <span className="review-card__icon">
          <IconButton onClick={() => setVisible(true)} icon="speech" color="white" />
        </span>
      )}
      {comment && (
        <ActionModal
          confirmText={`${getConfirmText()} patient study`}
          closeText={'Cancel'}
          onClose={() => setVisible(false)}
          onConfirm={() => {
            comment.action();
            setVisible(false);
          }}
          visible={visible}
          headerContent={<>MESSAGE</>}
        >
          <div className="review-card__modal">
            {isAwaiting ? (
              <div className="review-card__modal-title">
                Message sent to <span className="review-card__highlight">{assignee}</span>
              </div>
            ) : (
              <div className="review-card__modal-title">
                Message from <span className="review-card__highlight">{assigner}</span>
              </div>
            )}
            <div className="review-card__comment">{comment.message}</div>
          </div>
        </ActionModal>
      )}
    </div>
  );
};
