import React, { useEffect } from 'react';
import * as api from '../../utils/api';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { vesselDataActions } from './vesselDataSlice';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, useNeedsLoading, getReqProperties } from '../initialLoad/utils';
import { VesselDataResponse } from './types';

const loaderName = InitialLoader.VESSEL_DATA;

interface VesselDataFetchInfo {
  // The endpoint identifier the vessel data is fetched from.
  endpointID: string;
  // The name of the VesselData field where the data will be stored.
  fieldID: string;
  // Is the endpoint a calculated "web-data" one vs a direct "vessel/*" one?
  webData: boolean;
}

/**
 * Get all the vesselData in piecewise fashion for speed.
 */
export async function fetchVesselData(
  unifiedId: UnifiedId,
  dispatch: AppDispatch,
  initialLoad: boolean,
  onSuccess: (data: VesselDataResponse) => void
) {
  const reqProperties = getReqProperties(unifiedId);
  const fetchInfoArray: VesselDataFetchInfo[] = [];

  // Helper function to make building the list of data to fetch easier.
  const addFetchInfo = (endpointID: string, fieldID: string, webData: boolean = false) => {
    fetchInfoArray.push({
      endpointID,
      fieldID,
      webData,
    });
  };

  // Build the list of data to fetch.
  addFetchInfo('calcium-score', 'calcium_score');
  addFetchInfo('centerline', 'centerline');
  addFetchInfo('disease-range', 'disease_range');
  addFetchInfo('graft', 'graft');
  addFetchInfo('lesion-count', 'contrast_lesion_count');
  addFetchInfo('contrast-lesion-ids', 'contrast_lesion_ids');
  addFetchInfo('non-diagnostic', 'non_diagnostic');
  addFetchInfo('plaque', 'plaque');
  addFetchInfo('plaque-composition-counts', 'plaque_composition_counts');
  addFetchInfo('plaque-volume', 'plaque_volume');
  addFetchInfo('positive-remodelling', 'positive_remodelling');
  addFetchInfo('priority-lesion-id', 'priority_lesion_id');
  addFetchInfo('segments', 'segments');
  addFetchInfo('slice-cmap', 'slice_cmap');
  addFetchInfo('stenosis', 'stenosis');
  addFetchInfo('stent', 'stent');
  addFetchInfo('vertex-to-slice-mapping', 'vertex_to_slice_mapping');
  addFetchInfo('vp-biomarker-counts', 'vp_biomarker_counts');
  addFetchInfo('vulnerability', 'vulnerability');
  addFetchInfo('vulnerable-count', 'vulnerable_count');
  addFetchInfo('slice-to-lesion-mapping', 'slice_to_lesion_mapping');
  addFetchInfo('vessel-n-slices', 'n_slices', true);

  // Create promises for all the required fetches.
  const promises: Promise<any>[] = [];
  fetchInfoArray.forEach((fetchInfo) => {
    promises.push(
      api.getJSON(
        `/data/${unifiedId.patientId}/${unifiedId.runId}/${fetchInfo.webData ? 'web-data' : 'vessel/*'}/${
          fetchInfo.endpointID
        }?version=${unifiedId.version}`,
        false,
        reqProperties
      )
    );
  });

  const promise = Promise.all(promises).then((results) => {
    const vesselData: Record<string, any> = {};

    // Loop through each result and combine them into vesselData.
    fetchInfoArray.forEach((fetchInfo, index) => {
      // The vessel endpoints return their results under vessel_id, the webData endpoints don't.
      let result = results[index];
      if (!fetchInfo.webData && result) {
        result = result.vessel_id;
      }

      if (result) {
        // Loop through every vessel in the result.
        const vessels = Object.keys(result);
        vessels.forEach((vesselId) => {
          // Check this vessel exists in the vesselData.
          if (!vesselData[vesselId]) {
            vesselData[vesselId] = {};
          }
          // Set the bit of the vessel data this fetch retrieved.
          vesselData[vesselId][fetchInfo.fieldID] = result[vesselId];
        });
      }
    });
    return vesselData;
  });

  // Wait for all the fetches to finish.
  loadWithPromise(promise, dispatch, initialLoad, loaderName, onSuccess);
}

/**
 * Load the vessel data from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function loadVesselData(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  fetchVesselData(unifiedId, dispatch, initialLoad, (data) => {
    dispatch(vesselDataActions.setVesselData(data));
  });
}

/**
 * A component to load the initial vessel data for a study and add it to the store.
 */
export const VesselDataLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useNeedsLoading(loaderName);

  useEffect(() => {
    if (unifiedId && needsLoading) {
      loadVesselData(unifiedId, dispatch, true);
    }
  }, [unifiedId, needsLoading, dispatch]);

  return null;
};
