// This expects the publicAPI function customHandleMouse(callData, button)
import { operations } from '../vtkInteractorStyleRotatableMPRCrosshairs';
import { vec2 } from 'gl-matrix';
import { vtkEvent, vtkPublicAPI, vtkModel } from '../../ReactVTKJSTypes';
export const InteractionOperations = {
  NONE: 0,
  MOVE_CROSSHAIRS: 1,
  PAN: 2,
  ZOOM: 3,
  WINDOW_LEVEL: 4,
  SLICE: 5,
  SCROLL_SLICE: 6,
};
/**
 * Initialize any additional publicAPI functions we may need.
 */
function initialize(publicAPI: vtkPublicAPI, model: vtkModel) {
  /**
   * Perform so additional procession on the mouse event to detect double clicks.
   */
  publicAPI.handleMouseButton = (callData: vtkEvent, button: number) => {
    let isDoubleClick = false;
    // Double click detection.
    if (button > 0) {
      const time = new Date().getTime();
      // Check if this is a double click.
      if (model.lastClickButton === button && model.lastClickTime !== null && time - model.lastClickTime < 400) {
        isDoubleClick = true;
        // Reset the click button and time.
        model.lastClickButton = 0;
        model.lastClickTime = null;
        model.lastClickPos = [0, 0];
      }
      // Otherwise rememeber the click button and time.
      else {
        model.lastClickButton = button;
        model.lastClickTime = time;
        model.lastClickPos = [callData.position.x, callData.position.y];
      }
    }
    // Process as a normal mouse down event.
    publicAPI.customHandleMouse(callData, button, isDoubleClick);
  };
  /**
   * Clear all mouse state information and stop the current interaction operation.
   */
  publicAPI.clearMouseInteractionOperation = (model: vtkModel) => {
    // Clear the interaction operation NONE.
    model.interactionOperation = InteractionOperations.NONE;
    // Release all buttons.
    model.leftMouse = false;
    model.middleMouse = false;
    model.rightMouse = false;
  };
}
/**
 * Initialize any additional publicAPI functions we may need AFTER applying
 * and standard interactor adjustments. In particular apply any mouse handler
 * changes here that should happen AFTER the interactor has had a chance to
 * apply the interaction.
 */
function finalize(publicAPI: vtkPublicAPI, model: vtkModel) {
  const superHandleMouseMove = publicAPI.handleMouseMove;
  publicAPI.handleMouseMove = (callData: vtkEvent) => {
    // Clear the double click detection if the mouse has moved (too far).
    if (
      model.lastClickTime !== null &&
      vec2.distance(model.lastClickPos, [callData.position.x, callData.position.y]) > 50
    ) {
      model.lastClickButton = 0;
      model.lastClickTime = null;
      model.lastClickPos = [0, 0];
    }
    if (model.interactionOperation !== InteractionOperations.NONE) {
      // Process the mouse move.
      publicAPI.customHandleMouse(callData, 0, false);
    }
    if (superHandleMouseMove) {
      superHandleMouseMove(callData);
    }
  };
  const superHandleLeftButtonPress = publicAPI.handleLeftButtonPress;
  publicAPI.handleLeftButtonPress = (callData: vtkEvent) => {
    // Record that the button is down.
    model.leftMouse = true;
    // A left click with the shift button down will initiate a move crosshair operation
    // ... even if the click is outside the crosshairs center.
    if ((!model.operation || model.operation.type === null) && callData.shiftKey) {
      // Manually start the MOVE_CROSSHAIRS operation.
      model.operation = { type: operations.MOVE_CROSSHAIRS };
      model.interactionOperation = InteractionOperations.MOVE_CROSSHAIRS;
      // Use the handleMouseMove function to move the crosshairs.
      publicAPI.handleMouseMove(callData);
    }
    // Otherwise; call the original handler.
    else if (superHandleLeftButtonPress) {
      superHandleLeftButtonPress(callData);
    }
    // If the user is adjusting the crosshairs set the interaction operation.
    if (model.operation && model.operation.type !== null) {
      model.interactionOperation = InteractionOperations.MOVE_CROSSHAIRS;
    } else if (!callData.shiftKey && !callData.controlKey && !callData.altKey) {
      publicAPI.handleMouseButton(callData, 1);
    }

    // Pass on the new interaction operation via the callback.
    if (model.onInteractionOperation) {
      model.onInteractionOperation(model.interactionOperation, model.operation);
    }
  };

  const superHandleMiddleButtonPress = publicAPI.handleMiddleButtonPress;
  publicAPI.handleMiddleButtonPress = (callData: vtkEvent) => {
    // Record that the button is down.
    model.middleMouse = true;
    if (!callData.shiftKey && !callData.controlKey && !callData.altKey) {
      publicAPI.handleMouseButton(callData, 2);
    }
    // Otherwise call the original handler.
    else if (superHandleMiddleButtonPress) {
      superHandleMiddleButtonPress(callData);
    }

    // Pass on the new interaction operation via the callback.
    if (model.onInteractionOperation) {
      model.onInteractionOperation(model.interactionOperation, model.operation);
    }
  };

  const superHandleRightButtonPress = publicAPI.handleRightButtonPress;
  publicAPI.handleRightButtonPress = (callData: vtkEvent) => {
    // Record that the button is down.
    model.rightMouse = true;
    if (!callData.shiftKey && !callData.controlKey && !callData.altKey) {
      publicAPI.handleMouseButton(callData, 3);
    }
    // Otherwise call the original handler.
    else if (superHandleRightButtonPress) {
      superHandleRightButtonPress(callData);
    }

    // Pass on the new interaction operation via the callback.
    if (model.onInteractionOperation) {
      model.onInteractionOperation(model.interactionOperation, model.operation);
    }
  };

  const superHandleLeftButtonRelease = publicAPI.handleLeftButtonRelease;
  publicAPI.handleLeftButtonRelease = (callData: vtkEvent) => {
    // Always let vtk perform its actions on the release first.
    if (superHandleLeftButtonRelease) {
      superHandleLeftButtonRelease(callData);
    }
    // Release all buttons and stop the current interaction operation.
    publicAPI.clearMouseInteractionOperation(model);

    // Pass on the new interaction operation via the callback.
    if (model.onInteractionOperation) {
      model.onInteractionOperation(model.interactionOperation, model.operation);
    }
  };

  const superHandleMiddleButtonRelease = publicAPI.handleMiddleButtonRelease;
  publicAPI.handleMiddleButtonRelease = () => {
    // Always let vtk perform its actions on the release first.
    if (superHandleMiddleButtonRelease) {
      superHandleMiddleButtonRelease();
    }
    // Release all buttons and stop the current interaction operation.
    publicAPI.clearMouseInteractionOperation(model);

    // Pass on the new interaction operation via the callback.
    if (model.onInteractionOperation) {
      model.onInteractionOperation(model.interactionOperation, model.operation);
    }
  };

  const superHandleRightButtonRelease = publicAPI.handleRightButtonRelease;
  publicAPI.handleRightButtonRelease = () => {
    // Always let vtk perform its actions on the release first.
    if (superHandleRightButtonRelease) {
      superHandleRightButtonRelease();
    }
    // Release all buttons and stop the current interaction operation.
    publicAPI.clearMouseInteractionOperation(model);

    // Pass on the new interaction operation via the callback.
    if (model.onInteractionOperation) {
      model.onInteractionOperation(model.interactionOperation, model.operation);
    }
  };
}

const DEFAULT_VALUES = {
  // The current interaction operation.
  interactionOperation: InteractionOperations.NONE,
  // Mouse button states.
  leftMouse: false, // Is the left mouse button currently down?
  middleMouse: false, // Is the middle mouse button currently down?
  rightMouse: false, // Is the right mouse button currently down?
  lastClickButton: 0, // The last mouse button pressed.
  lastClickTime: null, // The date the last button was pressed.
  lastClickPos: [0, 0], // The mouse position when the button was last pressed.
};
const defaults = {
  initialize,
  finalize,
  DEFAULT_VALUES,
};
export default defaults;
