import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ShortAxisMPRViewer } from '../../components/ShortAxisMPRViewer/ShortAxisMPRViewer';
import { CPR_SLICE_INDICATOR_BUFFER } from '../../config';
import { WindowLevels } from '../../reducers/window/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useContrastWindowLevelsInputs } from '../../hooks/useWindow';
import { windowAction } from '../../reducers/window/windowSlice';
import { useSetSliceIndicesSelector, useVesselStateSelector } from '../../selectors/vessels';
import { timeFuncFactory } from '../../utils/shared';
import { LesionInfo } from '../VesselViewer/LesionInfo/LesionInfo';

export const AxialViewer: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { contrastLesionData } = useAppSelector((state) => state.lesion);

  const contrastWindowLevels = useAppSelector((state) => state.window.contrastWindowLevels);

  useContrastWindowLevelsInputs(contrastWindowLevels);

  const {
    midSliceIdx: sliceidx,
    highSliceIdx: highSliceidx,
    lowSliceIdx: lowSliceidx,
    selectedVesselName,
    selectedVesselViewerData,
  } = useVesselStateSelector();

  const {
    setMidSliceIndex: setSliceidx,
    setHighSliceIndex: setHighSliceidx,
    setLowSliceIndex: setLowSliceidx,
  } = useSetSliceIndicesSelector();

  const [currentLesionId, setCurrentLesionId] = useState<string | null>(null);

  const [lesionidx, setLesionidx] = useState(0);
  const [lesionLength, setLesionLength] = useState(0);
  const [activeLine] = useState('');
  const axialScreenshotRef = useRef(null);

  const highShortAxisRef = useRef<HTMLDivElement | null>(null);
  const midShortAxisRef = useRef<HTMLDivElement | null>(null);
  const lowShortAxisRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    timeFuncFactory(() => {
      if (!contrastLesionData || !selectedVesselName || !sliceidx) return;
      const lesionData = contrastLesionData[selectedVesselName];
      if (lesionData) {
        const filteredData = Object.keys(lesionData).filter((key) => {
          const lesion = lesionData[key];
          return lesion.slices.indexOf(sliceidx) >= 0;
        });
        // NOTE: There could be muliple lesions across a slice
        //       For now, retrieve the first lesion
        setLesionLength(filteredData.length);
        const slicelesionData = lesionData[filteredData[lesionidx]];

        setCurrentLesionId(slicelesionData ? `Lesion ${filteredData[lesionidx]}` : 'None');
      } else {
        setCurrentLesionId('none');
      }
    }, 'vessel switch sliceidx vesselviewer lesionidx')();
  }, [contrastLesionData, selectedVesselName, sliceidx, lesionidx]);

  // Reset lesionidx if the slice change
  useEffect(() => {
    setLesionidx(0);
  }, [sliceidx]);

  const handleLesionidxChange = useCallback(
    (dx: number) => {
      setLesionidx((value) => {
        const nextValue = value + dx;
        if (nextValue > lesionLength) return value;
        if (nextValue >= 0) return nextValue;
        return value;
      });
    },
    [lesionLength]
  );

  if (!selectedVesselName) return null;

  const onWindowLevelsChange = (windowLevels: WindowLevels) => {
    // Set the window levels for the WebGLViewer views and contrast views.
    dispatch(windowAction.setContrastWindowLevels(windowLevels));
  };

  return (
    <>
      <div className="axial-viewer">
        <div className="axial-viewer__axial-rows">
          <div
            ref={highShortAxisRef}
            className={cn('axial-viewer__wrapper', {
              active: activeLine === 'high',
            })}
          >
            <div className={cn('axial-viewer__axial card-new')} ref={axialScreenshotRef}>
              <ShortAxisMPRViewer
                windowLevels={contrastWindowLevels}
                onWindowLevelsChange={onWindowLevelsChange}
                viewIdx={highSliceidx}
                maxThreshold={sliceidx > CPR_SLICE_INDICATOR_BUFFER ? sliceidx - CPR_SLICE_INDICATOR_BUFFER : 0}
                viewerData={selectedVesselViewerData?.shortAxisViewerData}
                onChangeViewIdx={setHighSliceidx}
                type="prox"
              />
            </div>
          </div>
          <div
            ref={midShortAxisRef}
            className={cn('axial-viewer__wrapper', {
              active: activeLine === 'mid',
            })}
          >
            <div className={cn('axial-viewer__axial card-new')} ref={axialScreenshotRef}>
              <ShortAxisMPRViewer
                windowLevels={contrastWindowLevels}
                onWindowLevelsChange={onWindowLevelsChange}
                viewIdx={sliceidx}
                viewerData={selectedVesselViewerData?.shortAxisViewerData}
                onChangeViewIdx={setSliceidx}
                lesionId={currentLesionId}
                type="mid"
              />
            </div>
          </div>
          <div
            ref={lowShortAxisRef}
            className={cn('axial-viewer__wrapper', {
              active: activeLine === 'low',
            })}
          >
            <div className={cn('axial-viewer__axial card-new', 'last')} ref={axialScreenshotRef}>
              <ShortAxisMPRViewer
                windowLevels={contrastWindowLevels}
                onWindowLevelsChange={onWindowLevelsChange}
                viewIdx={lowSliceidx}
                minThreshold={
                  lowSliceidx < sliceidx + CPR_SLICE_INDICATOR_BUFFER ? sliceidx + CPR_SLICE_INDICATOR_BUFFER : sliceidx
                }
                viewerData={selectedVesselViewerData?.shortAxisViewerData}
                onChangeViewIdx={setLowSliceidx}
                type="dist"
              />
            </div>
          </div>

          <LesionInfo
            handleLesionidxChange={handleLesionidxChange}
            lesionidx={lesionidx}
            lesionId={currentLesionId}
            lesionLength={lesionLength}
          />
        </div>
      </div>
    </>
  );
};

export default AxialViewer;
