import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import type { AppDispatch } from '../../store';
import { reportStateActions } from '../../reducers/ReportState/ReportStateSlice';
import * as api from '../../utils/api';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, getReqProperties, useNeedsLoading } from '../initialLoad/utils';

const loaderName = InitialLoader.REPORT_STATE;

/**
 * Load the report state from the backend and save it in the store.
 */
export function loadReportState(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  const reqProperties = getReqProperties(unifiedId);
  const promise = api.getJSON(
    `/data/${unifiedId.patientId}/${unifiedId.runId}/report/screenshot/list`,
    false,
    reqProperties
  );
  loadWithPromise(promise, dispatch, initialLoad, loaderName, (screenshotList) => {
    dispatch(reportStateActions.setScreenshots(screenshotList));
  });
}

/**
 * A component to load the initial report state data for a study and add it to the store.
 */
export const ReportStateLoader: React.FunctionComponent = () => {
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const dispatch = useAppDispatch();
  const needsLoading = useNeedsLoading(loaderName);

  useEffect(() => {
    if (unifiedId && needsLoading) {
      loadReportState(unifiedId, dispatch, true);
    }
  }, [dispatch, unifiedId, needsLoading]);

  return null;
};
