import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch } from '../../hooks';
import * as api from '../../utils/api';
import { InitialLoader } from '../initialLoad/initialLoadSlice';
import { loadWithPromise } from '../initialLoad/utils';
import { CurvedMprInterface, NewDataResponse } from './types';

const API_PLATFORM_BASE_URL = process.env.REACT_APP_PLATFORM_BASE_URL;
const loaderName = InitialLoader.CURVED_MPR;

/**
 * Load the study data from new endpoint and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export interface CurveMprResponseContent {
  curvedMpr: CurvedMprInterface;
}

export function loadCurvedMprData(studyId: string, dispatch: AppDispatch, initialLoad: boolean) {
  const promise = api.getJSON(`${API_PLATFORM_BASE_URL}/webapp/study/${studyId}/curvedmpr`).catch((error) => {
    // NOTE: We currently want to catch this error as failing to load the new study should
    // not prevent the study from being opened. This may change in the future though...
  });
  loadWithPromise(
    promise,
    dispatch,
    initialLoad,
    loaderName,
    async ({ successful, display_message, content }: NewDataResponse<CurveMprResponseContent>) => {
      // if a study is not found then continue with the old api
      if (!successful) {
        console.warn('new study endpoint MPR ', display_message);

        return;
      }
      const { curvedMpr } = content;
      // validate that the study has the required fields
      const warnings = [!curvedMpr && 'curvedMpr'];

      // if the study is not valid then continue with the old api
      if (warnings.length > 0) {
        console.warn(...warnings);

        return;
      }
    }
  );
}

/**
 * A component to load the initial study data for a study and add it to the store.
 */
interface StudyLoaderProps {
  studyId?: string;
}
export const NewStudyMprLoader: React.FunctionComponent<StudyLoaderProps> = ({ studyId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (studyId != null) {
      loadCurvedMprData(studyId, dispatch, true);
    }
  }, [dispatch, studyId]);

  return null;
};
