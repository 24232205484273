import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Confirm from '../../components/Confirm/Confirm';
import { CPRViewer } from '../../components/CPRViewer/CPRViewer';
import { onEditCenterlineFetchImages, onEditCenterlineV2Result } from '../../components/CPRViewer/Utils';
import LoadingOverlay, { LoadingOverlayProps } from '../../components/LoadingOverlay/LoadingOverlay';
import { LongAxisMPRViewer } from '../../components/LongAxisMPRViewer/LongAxisMPRViewer';
import { ReformationTypeSelector } from './ReformationTypeSelector/ReformationTypeSelector';
import { showToast } from '../../components/Toast/showToast';
import { KEY_CPR, KEY_MPR_LONG_AXIS, MOUSE_BUTTONS } from '../../config';
import { cprActions } from '../../reducers/cpr/cprSlice';
import { WindowLevels } from '../../reducers/window/types';
import { useDashboardSelector } from '../../dashboardHooks';
import { useAppSelector, useAppDispatch } from '../../hooks';
import useFfrData from '../../hooks/use-ffr-data';
import { cprVersionSelector } from '../../selectors/study';
import { useVesselStateSelector } from '../../selectors/vessels';
import { LineArray } from '../../types/common';
import * as api from '../../utils/api';
import { EditCenterlineV2_request, EditCenterlineV2_result, EditCenterlineV2_result_save } from './types';
import { windowAction } from '../../reducers/window/windowSlice';
import { useContrastWindowLevelsInputs } from '../../hooks/useWindow';
import { vesselDataActions } from '../../reducers/vesselData/vesselDataSlice';
import { createImageBufferFromImageData } from '../../components/WebGLViewer/Utils';
import { centerlineEditActions } from '../../reducers/centerline-edit/centerlineEditSlice';
import { useUpdateBackendVersionHead, useUpdateVersionHead } from '../../hooks/use-version-head';

interface ConfirmingDialog {
  // We can show the confirm dialog for two reasons:
  // false: The user double clicked to stop editing the centerline.
  // true: The user tried to hide the centerline, if they accept we thus also need to hide the centerline.
  hideAnnosAfterConfirming: boolean;
}

export const VesselViewer: React.FunctionComponent = () => {
  const updateVersionHead = useUpdateVersionHead();
  const updateBackendVersionHead = useUpdateBackendVersionHead();
  const versionHead = useAppSelector((state) => state.store.versionHead);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const centerlineEdit = useAppSelector((state) => state.centerlineEdit);
  const dispatch = useAppDispatch();

  const patientID = useAppSelector((state) => state.patient.patientID);

  const { selectedVesselName, selectedVesselViewerData, selectedVesselData } = useVesselStateSelector();

  const cprVersion = useAppSelector(cprVersionSelector);

  const { contrastWindowLevels, contrastWindowLabel } = useAppSelector((state) => state.window);
  useContrastWindowLevelsInputs(contrastWindowLevels);

  const { clientConfig } = useDashboardSelector((state) => state.user);
  const [confirming, setConfirming] = useState<ConfirmingDialog | undefined>(undefined);
  const [loadingOverlayProps, setLoadingOverlayProps] = useState<LoadingOverlayProps | undefined>();
  const [showFFRLabels, setShowFFRLabels] = useState(false);
  const [ffrenabled, setFfrenabled] = useState(true);
  const [initalLoadCPR, setInitialLoadCPR] = useState(true);
  const [initalLoadMPRLongAxis, setInitialLoadMPRLongAxis] = useState(false);
  const [activeLine, setActiveLine] = useState('');
  const mprScreenshotRef = useRef(null);
  const longAxialScreenshotRef = useRef(null);
  const { sliceidx: cprSliceidx, selectedMPRView } = useAppSelector((state) => state.cpr);
  const addEditCentrelineCount = useRef(0);
  const [showAnnos, setShowAnnos] = useState(false);

  const { data: ffrData, isError: ffrError } = useFfrData(
    patientID || '',
    ffrenabled && (clientConfig?.ffr_enabled || false)
  );

  useEffect(() => {
    // In the event of FFR fetch erroring, reset the enable flag to prevent retry
    if (ffrError) {
      setFfrenabled(false);
    }
  }, [ffrError]);

  useEffect(() => {
    if (ffrData && selectedVesselName && ffrData[selectedVesselName].status !== 'Processing') {
      setFfrenabled(false);
    } else {
      setFfrenabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ffrData]);

  const resetInitialLoad = useCallback(() => {
    if (selectedMPRView === KEY_MPR_LONG_AXIS) {
      setInitialLoadCPR(false);
      setInitialLoadMPRLongAxis(true);
    } else {
      setInitialLoadCPR(true);
      setInitialLoadMPRLongAxis(false);
    }
  }, [selectedMPRView]);

  const screenShotsEnabled = useMemo(() => !showFFRLabels, [showFFRLabels]);

  useEffect(() => {
    if (!initalLoadCPR && selectedMPRView === KEY_CPR) {
      setInitialLoadCPR(true);
      setFfrenabled(false);
    }
    if (!initalLoadMPRLongAxis && selectedMPRView === KEY_MPR_LONG_AXIS) {
      setInitialLoadMPRLongAxis(true);
      setFfrenabled(false);
    }
  }, [selectedMPRView, initalLoadCPR, initalLoadMPRLongAxis]);

  useEffect(() => {
    resetInitialLoad();
  });

  const onMouseDownScrollBlocker = useCallback((e: React.MouseEvent) => {
    if (e.buttons === MOUSE_BUTTONS.MIDDLE) {
      e.stopPropagation();
      e.preventDefault();
    }
  }, []);

  const saveCenterlineEditsV2 = (editedPoints: LineArray) => {
    return new Promise(async (resolve, reject) => {
      if (editedPoints.length) {
        const errorMsg = `Failed to save ${selectedVesselName?.toUpperCase()} centreline changes`;
        const appliedMsg = `${selectedVesselName?.toUpperCase()} centreline changes applied`;
        const successMsg = `${selectedVesselName?.toUpperCase()} centreline changes saved`;
        const request: EditCenterlineV2_request = {
          study_id: patientID!,
          run_id: runID!,
          vessel_id: selectedVesselName!,
          payload: {
            view_idx: cprSliceidx,
            data: editedPoints,
            version_id: versionHead!,
          },
        };

        let allowSubsequentEdit = false;
        let allUpdatedImagesLoaded = false;
        let finalMessageReceived = false;
        const closeSocketIfDone = (socket: WebSocket) => {
          if (allUpdatedImagesLoaded && finalMessageReceived) {
            socket.close();
          }
        };

        try {
          const socket = await api.getWebsocket('/ws/tasks/v2/edit-centreline');

          socket.addEventListener('open', (_event) => {
            socket.send(JSON.stringify(request));
          });

          socket.addEventListener('error', (error) => {
            console.error(error);
            socket.close();
            reject(errorMsg);
          });

          socket.addEventListener('message', (event) => {
            const json = JSON.parse(event.data);

            switch (json.type) {
              // Something went terribly wrong.
              case 'error':
                socket.close();
                reject(json.data.message);
                break;

              // The data has been updated (but not yet saved on the backend). Update the data for the CPRViewer.
              case 'result':
                {
                  const result: EditCenterlineV2_result = json.data;
                  updateVersionHead(result.new_version_id);

                  setLoadingOverlayProps({
                    open: true,
                    text: 'Fetching new imagery',
                  });
                  showToast.success(appliedMsg);
                  allowSubsequentEdit = result.can_edit;

                  // Fetch the aux annos and first image slice and get the resulting CPRVesselData.
                  if (!selectedVesselData) {
                    console.error('Editing centerline V2 without valid selectedVesselData');
                  }
                  onEditCenterlineV2Result(
                    patientID!,
                    selectedVesselName!,
                    result.result,
                    cprSliceidx,
                    selectedVesselData ? selectedVesselData.n_slices : 0
                  )
                    .then((cprVesselData) => {
                      // Exit edit mode. This seems to be the optimal point.
                      if (allowSubsequentEdit) {
                        dispatch(centerlineEditActions.stopEditing());
                      }

                      // Set the vessel viewer data with the first image slice loaded.
                      dispatch(
                        vesselDataActions.addVesselViewerDataCPRViewerData({
                          vesselID: selectedVesselName,
                          cprVesselData,
                        })
                      );

                      // Load the remaining images.
                      onEditCenterlineFetchImages(
                        result.result.image_keys.path,
                        cprVesselData.shape.length,
                        cprSliceidx,
                        (sliceIndex: number, image: Buffer) => {
                          const imageBuffer = createImageBufferFromImageData(image, true);
                          // Add the image to the selectedVesselViewerData.cprVesselData.
                          dispatch(
                            vesselDataActions.addVesselViewerDataCPRViewerDataImage({
                              vesselID: selectedVesselName,
                              sliceIndex,
                              imageBuffer: imageBuffer,
                            })
                          );
                        }
                      ).finally(() => {
                        allUpdatedImagesLoaded = true;
                        closeSocketIfDone(socket);
                      });
                    })
                    .catch((e) => {
                      showToast.error('Error fetching centreline imagery');
                      setLoadingOverlayProps(undefined);
                    });
                }
                break;

              // The backend has now saved the new centerline.
              case 'result_save':
                {
                  const result: EditCenterlineV2_result_save = json.data;
                  // The data has been saved on the backend, close the socket if done, and update the version id.
                  finalMessageReceived = true;
                  closeSocketIfDone(socket);
                  if (!allowSubsequentEdit) {
                    dispatch(centerlineEditActions.stopEditing());
                  }
                  if (result.result === 'Success') {
                    updateBackendVersionHead(result.version_id);
                    setLoadingOverlayProps(undefined);
                    resolve(successMsg);
                  } else {
                    reject(errorMsg);
                  }
                }
                break;

              // We don't expect to get here.
              default:
                break;
            }
          });
        } catch (error) {
          console.error(error);
          reject(errorMsg);
        }
      } else {
        reject('No points selected to track');
      }
    });
  };

  /**
   * The user has confirmed they want to save the centerline changes, try to save then and exit edit mode.
   */
  const onConfirmReproject = () => {
    setLoadingOverlayProps({ open: true, text: 'Re-projecting Vessel' });
    onSaveCentrelineEdits();
    if (confirming?.hideAnnosAfterConfirming) {
      setShowAnnos(false);
    }
    setConfirming(undefined);
  };

  /**
   * The user doesn't want to exit edit mode just yet, we can just hide the confirm dialog and carry on.
   */
  const onDismissReproject = () => {
    setConfirming(undefined);
  };

  /**
   * Try saving the centerline edits, on success we can exit edit mode.
   */
  const onSaveCentrelineEdits = () => {
    if (!centerlineEdit.centerline) {
      return;
    }
    addEditCentrelineCount.current++;
    const addEditCount = addEditCentrelineCount.current;
    // Disable the vessel selector
    dispatch(vesselDataActions.savingVessel(selectedVesselName));

    // Use the correct edit centerline function for the CPR version we are working with.
    if (cprVersion === 2) {
      saveCenterlineEditsV2(centerlineEdit.centerline)
        .then((msg) => {
          showToast.success(String(msg));
        })
        .catch((e) => {
          showToast.error(e);
          console.error(e);
        })
        .finally(() => {
          setLoadingOverlayProps(undefined);
          // Flag that we are no longer saving the newly edited vessel on the backend, or editing.
          // To avoid race conditions, only do this if we were the last submitted add or edit.
          if (addEditCount === addEditCentrelineCount.current) {
            dispatch(vesselDataActions.clearSavingVessel());
          }
        });
    } else {
      console.warn('Editing the centreline on a V1 CPR is invalid');
    }
  };

  /**
   * handle the cprSlice store
   */
  const handleSelectedMPRView = (view: string) => {
    dispatch(cprActions.setSelectedMPRView(view));
  };

  const onStartEditing = (showAuxAnnos: boolean) => {
    if (centerlineEdit.editing) {
      return;
    }
    setShowFFRLabels(false);
    dispatch(centerlineEditActions.startEditing({ showAnnos, showAuxAnnos }));
  };

  /**
   * Confirm that the centerline edits should be saved and remember if the annos should be hidden after saving.
   */
  const onSaveEdits = (hideAnnosAfterConfirming: boolean) => {
    setConfirming({ hideAnnosAfterConfirming });
  };

  const onDiscardEdits = () => {};

  if (!selectedVesselName) return null;

  const onWindowLevelsChange = (windowLevels: WindowLevels) => {
    // Set the window levels for the WebGLViewer views and contrast views.
    dispatch(windowAction.setContrastWindowLevels(windowLevels));
  };

  return (
    <>
      <div className="vessel-viewer" onMouseDown={onMouseDownScrollBlocker}>
        <div className="vessel-viewer__column card-new">
          <div className="vessel-viewer__view-wrap">
            {!centerlineEdit.editing && (
              <ReformationTypeSelector
                options={[
                  {
                    value: KEY_CPR,
                    label: 'Curved',
                  },
                  { value: KEY_MPR_LONG_AXIS, label: 'Straightened' },
                ]}
                value={selectedMPRView}
                onChange={handleSelectedMPRView}
              />
            )}
            <div
              ref={mprScreenshotRef}
              className={cn('vessel-viewer__view', {
                'vessel-viewer__view--show': selectedMPRView === KEY_CPR,
              })}
            >
              {initalLoadCPR && (
                <>
                  <CPRViewer
                    windowLevels={contrastWindowLevels}
                    windowLabel={contrastWindowLabel}
                    onWindowLevelsChange={onWindowLevelsChange}
                    onStartEditing={onStartEditing}
                    onSaveEdits={onSaveEdits}
                    onDiscardEdits={onDiscardEdits}
                    activeLine={activeLine}
                    ffrData={ffrData}
                    showFFRLabels={showFFRLabels}
                    setFfrenabled={setFfrenabled}
                    onSetActiveLine={setActiveLine}
                    showHighLowIndicators
                    cprVesselData={selectedVesselViewerData?.cprVesselData}
                    showAnnos={showAnnos}
                    setShowAnnos={setShowAnnos}
                    cprVersion={cprVersion}
                    centerlineEdit={centerlineEdit}
                    dispatchEditModeAction={true}
                    screenshotDisabled={!screenShotsEnabled}
                    screenshotRef={mprScreenshotRef}
                    viewName={selectedMPRView}
                  />
                  <Confirm
                    onSuccess={onConfirmReproject}
                    open={confirming !== undefined}
                    title="Are you sure?"
                    onDismiss={onDismissReproject}
                    dontShowAgainCheckbox
                  >
                    You will not be able to edit the vessel until re-projection is completed.
                  </Confirm>
                  <LoadingOverlay text={loadingOverlayProps?.text} open={loadingOverlayProps?.open} />
                </>
              )}
            </div>
            <div
              ref={longAxialScreenshotRef}
              className={cn('vessel-viewer__view', {
                'vessel-viewer__view--show': selectedMPRView === KEY_MPR_LONG_AXIS,
              })}
            >
              {initalLoadMPRLongAxis && (
                <LongAxisMPRViewer
                  showHighLowIndicators={true}
                  onSetActiveLine={setActiveLine}
                  activeLine={activeLine}
                  windowLevels={contrastWindowLevels}
                  windowLabel={contrastWindowLabel}
                  onWindowLevelsChange={onWindowLevelsChange}
                  viewerData={selectedVesselViewerData?.longAxisViewerData}
                  ffrData={ffrData}
                  showFFRLabels={showFFRLabels}
                  setFfrenabled={setFfrenabled}
                  screenshotDisabled={!screenShotsEnabled}
                  screenshotRef={longAxialScreenshotRef}
                  viewName={selectedMPRView}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VesselViewer;
