import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import { ReviewUser } from '../context/types';
import { useDashboardDispatch, useDashboardSelector } from '../dashboardHooks';
import { useAppDispatch, useAppSelector } from '../hooks';
import { reportStateActions } from '../reducers/ReportState/ReportStateSlice';
import { storeActions } from '../reducers/store/storeSlice';
import { globalLoaderActions } from '../reducers/globalLoader/globalLoaderSlice';
import { dashboardActions } from '../reducers/dashboard/dashboardSlice';
import { currentStudySelector } from '../selectors/study';
import { useIsAuditorUserSelector } from '../selectors/user';
import * as api from '../utils/api';
import { studyActions } from '../reducers/study/studySlice';
import { UseFetchStudyById, UseFetchStudyByIdPromise } from './UsefetchStudy';

export function useFetchReviewUsers() {
  const dispatch = useAppDispatch();
  const { user: loggedInUser } = useDashboardSelector((state) => state.user);
  const isAuditorUser = useIsAuditorUserSelector();

  return useCallback(async () => {
    // Don't fetch reviewers for auditor
    if (isAuditorUser) return;

    // Use undefined to mean that the request is in progress
    dispatch(reportStateActions.setReviewUsers(undefined));
    try {
      const users = await api.fetchReviewUsers();
      dispatch(reportStateActions.setReviewUsers(users.filter((u) => u.email !== loggedInUser.email)));
    } catch (err) {
      console.warn('Failed to fetch workflow users:', err);
      dispatch(reportStateActions.setReviewUsers([]));
      showToast.error('Failed to get list of users');
    }
  }, [dispatch, loggedInUser.email, isAuditorUser]);
}

export function useRequestReview() {
  const selectedStudy = useAppSelector(currentStudySelector);

  const dashboardDispatch = useDashboardDispatch();
  const dispatch = useAppDispatch();

  return useCallback(
    async (assignee: ReviewUser, reviewType: string, message: string) => {
      if (!selectedStudy) {
        return;
      }

      const { active_run: activeRun, study_id: studyId } = selectedStudy;

      if (!activeRun || !studyId) {
        showToast.error('Failed to get study information');
        return;
      }

      dashboardDispatch(globalLoaderActions.show('Sending for review'));

      try {
        await api.requestReview(studyId, activeRun, assignee, reviewType, message);
        showToast.success(`Report sent to ${assignee.name} for review`);

        const promises = Promise.all([
          UseFetchStudyById(selectedStudy.study_id),
          api.fetchReviewList(studyId, activeRun),
        ]);

        const [study, reviewList] = await promises;
        dispatch(studyActions.setCurrentStudy(study));
        dispatch(storeActions.setReviewList(reviewList));
        dashboardDispatch(globalLoaderActions.hide());
      } catch (err) {
        console.warn('Failed to request review:', err);
        if (err instanceof api.APIError && err.response.status !== 500) {
          showToast.error(`Failed to request review: ${err.message}`);
        } else {
          showToast.error('Failed to request review');
        }
      } finally {
        dashboardDispatch(globalLoaderActions.hide());
      }
    },
    [dashboardDispatch, dispatch, selectedStudy]
  );
}

export function useCompleteReview() {
  const selectedStudy = useAppSelector(currentStudySelector);

  const dashboardDispatch = useDashboardDispatch();
  const dispatch = useAppDispatch();

  return useCallback(
    async (reviewId: string, reviewType: 'review' | 'approve') => {
      if (!selectedStudy) {
        return;
      }

      const { active_run: activeRun, study_id: studyId } = selectedStudy;

      if (!activeRun || !studyId) {
        showToast.error('Failed to get study information');
        return;
      }

      try {
        await api.completeReview(studyId, activeRun, reviewId);
        showToast.success(reviewType === 'review' ? 'Review completed' : 'Analysis approved');

        const promises = Promise.all([
          UseFetchStudyById(studyId),
          api.fetchReviewList(studyId, activeRun),
          UseFetchStudyByIdPromise(studyId),
        ]);

        const [study, reviewList, updatedStudy] = await promises;
        dispatch(studyActions.setCurrentStudy(study));
        // Set the updatedStudy for the dashboard data.
        dashboardDispatch(dashboardActions.updateStudy({ studyId: studyId, changes: updatedStudy }));

        dispatch(storeActions.setReviewList(reviewList));
      } catch (err) {
        // Fetch and update current report so the 'complete review' button no longer shows.
        // Most likely the review was cancelled if we get here.
        const response = await UseFetchStudyById(studyId);
        dispatch(studyActions.setCurrentStudy(response));

        console.warn('Failed to complete review:', err);
        if (err instanceof api.APIError && err.response.status !== 500) {
          showToast.error(`Failed to complete review: ${err.message}`);
        } else {
          showToast.error('Failed to complete review');
        }
      }
    },
    [selectedStudy, dashboardDispatch, dispatch]
  );
}
