import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import { Study } from '../context/types';
import { workflowActions } from '../reducers/workflow/workflowSlice';
import { dashboardActions } from '../reducers/dashboard/dashboardSlice';
import { useDashboardDispatch } from '../dashboardHooks';
import { globalLoaderActions } from '../reducers/globalLoader/globalLoaderSlice';
import * as api from '../utils/api';
import { UseFetchStudyByIdPromise } from './UsefetchStudy';

export function useCancelReviewRequest() {
  const dashboardDispatch = useDashboardDispatch();

  return useCallback(
    async ({ study, afterCancel }: { study: Study | null; afterCancel?: () => void }) => {
      if (!study) return;

      const { active_run: activeRun, study_id: studyId, report_active_review: review } = study;

      if (!activeRun || !studyId) {
        showToast.error('Failed to get study information');
        dashboardDispatch(workflowActions.setStudyToAction(null));
        return;
      }

      dashboardDispatch(globalLoaderActions.show('Cancelling review'));
      dashboardDispatch(workflowActions.setShowCancelReviewConfirm(false));

      try {
        const response = await api.postJSON(`data/${studyId}/${activeRun}/report/review/cancel`, {
          review_id: review?.review_id,
        });

        if (!response) throw new Error('No response');

        // If defined this callback is probably to call the fetchStudyById.
        if (afterCancel) {
          afterCancel();
        }

        showToast.success('Review cancelled');

        // Update the study for the dashboard too.
        const updatedStudy = await UseFetchStudyByIdPromise(study.study_id);
        dashboardDispatch(dashboardActions.updateStudy({ studyId: study.study_id, changes: updatedStudy }));

        dashboardDispatch(workflowActions.setShowCancelReviewConfirm(false)); // hide modal window
        dashboardDispatch(workflowActions.setStudyToAction(null));
      } catch (err) {
        dashboardDispatch(workflowActions.setStudyToAction(null));
        dashboardDispatch(workflowActions.setShowCancelReviewConfirm(false)); // hide modal window
        console.error(err);
        showToast.error('Failed to cancel review request');
      } finally {
        dashboardDispatch(globalLoaderActions.hide());
      }
    },
    [dashboardDispatch]
  );
}
