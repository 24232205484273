import macro from '../SafeMacro';
import vtkMatrixBuilder from 'vtk.js/Sources/Common/Core/MatrixBuilder';
import {
  worldToDisplay,
  displayToWorld,
  getSliceFromPoint,
  moveCameraToSlice,
  moveCameraToSliceFromPoint,
} from './Utils';
import { InteractionOperations } from './Manipulators/customBase';
import { vtkApi, vtkVolume, vtkPublicAPI, vtkModel, vtkEvent, vtkInteractorStyleManipulator } from '../ReactVTKJSTypes';
import { vec3 } from 'gl-matrix';

// ----------------------------------------------------------------------------
// Global methods
// ----------------------------------------------------------------------------
function boundsToCorners(bounds: vec3) {
  return [
    [bounds[0], bounds[2], bounds[4]],
    [bounds[0], bounds[2], bounds[5]],
    [bounds[0], bounds[3], bounds[4]],
    [bounds[0], bounds[3], bounds[5]],
    [bounds[1], bounds[2], bounds[4]],
    [bounds[1], bounds[2], bounds[5]],
    [bounds[1], bounds[3], bounds[4]],
    [bounds[1], bounds[3], bounds[5]],
  ];
}

// ----------------------------------------------------------------------------
// vtkInteractorStyleMPRSlice methods
// ----------------------------------------------------------------------------
function vtkInteractorStyleMPRSlice(publicAPI: vtkPublicAPI, model: vtkModel) {
  // Set our className
  model.classHierarchy.push('vtkInteractorStyleMPRSlice');

  // cache for sliceRange
  const cache = {
    sliceNormal: [0, 0, 0],
    sliceRange: [0, 0],
    sliceCenter: [],
  };

  const superHandleMouseMove = publicAPI.handleMouseMove;
  publicAPI.handleMouseMove = (callData: vtkEvent) => {
    if (model.measurementToggleActive) return;
    if (superHandleMouseMove) {
      superHandleMouseMove(callData);
    }
  };

  publicAPI.setVolumeActor = (actor: vtkVolume) => {
    model.volumeActor = actor;
    const renderer = model.interactor.getCurrentRenderer();
    const camera = renderer.getActiveCamera();
    if (actor) {
      // prevent zoom manipulator from messing with our focal point
      camera.setFreezeFocalPoint(true);
    } else {
      camera.setFreezeFocalPoint(false);
    }
  };

  /**
   * Get the camera's current slice (distance of the focal point along the camera's direction of projection.
   */
  publicAPI.getSlice = () => {
    const camera = model.interactor.getCurrentRenderer().getActiveCamera();
    return getSliceFromPoint(camera, camera.getFocalPoint());
  };

  // Only run the onScroll callback if called from scrolling,
  // preventing manual setSlice calls from triggering the CB.
  publicAPI.scrollToSlice = (slice: number) => {
    // Set the slice on this view (ie move the camera forwards or backwards as required).
    const slicePoint = publicAPI.setSlice(slice);

    // Run callback
    const onScroll = publicAPI.getOnScroll();
    if (onScroll) {
      onScroll({ slicePoint });
    }

    // Fire the callback after the crosshairs are moved.
    if (model.onCrosshairsMoved) {
      model.onCrosshairsMoved();
    }

    // Fire the callback after the crosshairs are adjusted.
    if (model.onCrosshairsAdjusted) {
      model.onCrosshairsAdjusted();
    }
  };

  /**
   * Our internal handler to respond to the mouse wheel. It will update the slice, propagate this to the crosshairs
   * on each view and refresh the views.
   */
  const handleWheel = (spinY: number) => {
    if (model.interactionOperation === InteractionOperations.SCROLL_SLICE) {
      const range = publicAPI.getSliceRange();
      const scrollStep = 1;
      const slice = Math.min(Math.max(publicAPI.getSlice() + spinY * scrollStep, range[0]), range[1]);
      publicAPI.scrollToSlice(slice);
    }
  };

  /**
   * The user has started turning the mouse wheel.
   */
  publicAPI.handleStartMouseWheel = (callData: vtkEvent) => {
    if (model.measurementToggleActive) return;
    if (model.interactionOperation === InteractionOperations.NONE) {
      model.interactionOperation = InteractionOperations.SCROLL_SLICE;
      handleWheel(callData.spinY);
      // Pass on the new interaction operation via the callback.
      if (model.onInteractionOperation) {
        model.onInteractionOperation(model.interactionOperation, model.operation);
      }
    }
  };

  /**
   * The user is still turning the mouse wheel.
   */
  publicAPI.handleMouseWheel = (callData: vtkEvent) => {
    if (model.interactionOperation === InteractionOperations.SCROLL_SLICE) {
      handleWheel(callData.spinY);
    }
  };

  /**
   * The user has stopped turning the mouse wheel.
   */
  publicAPI.handleEndMouseWheel = () => {
    if (model.interactionOperation === InteractionOperations.SCROLL_SLICE) {
      model.interactionOperation = InteractionOperations.NONE;
      // Pass on the new interaction operation via the callback.
      if (model.onInteractionOperation) {
        model.onInteractionOperation(model.interactionOperation, model.operation);
      }
    }
  };

  /**
   * Move the crosshairs to the new position for all views.
   * @param wasMove If true the crosshairs were translated on this view, so the view will not need to be redrawn.
   *                If false the slice on this view was changed, so its view will need to be rendered.
   */
  publicAPI.moveCrosshairs = (worldPos: vec3, wasMove: boolean) => {
    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;

    if (worldPos === undefined || apis === undefined) {
      console.error('worldPos, apis must be defined in order to update crosshairs.');
    }

    // Update the shared crosshairs world position by mutation.
    const crosshairWorldPosition = apis[apiIndex].crosshairWorldPosition;
    crosshairWorldPosition[0] = worldPos[0];
    crosshairWorldPosition[1] = worldPos[1];
    crosshairWorldPosition[2] = worldPos[2];

    // Set camera focal point to world coordinate for linked views
    apis.forEach((api: vtkApi, index: number) => {
      // Move the camera forward of backwards as required to center its focal point on the same plane as the
      // crosshairs position.
      const camera = api.genericRenderWindow?.getRenderer?.()?.getActiveCamera?.();
      if (camera) {
        moveCameraToSliceFromPoint(camera, worldPos);
      }

      // Render the view and crosshairs.
      // NOTE: If this was a move then we don't need to render the view on which the crosshairs were moved because it won't change.
      if (wasMove) {
        api.refreshView(apiIndex !== index, true);
      }
      // NOTE: If this was a slice change then we don't need to render the other views because they won't have changed.
      else {
        api.refreshView(apiIndex === index, true);
      }
    });
  };

  /**
   * This is automatically called after a scroll event occurs. We use it to update
   * and render the view and the crosshairs for all views.
   */
  model.onScroll = () => {
    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;
    if (apis && apis[apiIndex] && apis[apiIndex].type === 'VtkContrastView') {
      const api = apis[apiIndex];

      // Check the crosshairs have a position to adjust.
      const renderer = api.genericRenderWindow.getRenderer();
      let crosshairWorldPosition = api.crosshairWorldPosition;
      if (crosshairWorldPosition === undefined) {
        // Crosshairs not initilised.
        return;
      }

      // Convert from the cached crosshairs world position to a display position.
      const displayPos = worldToDisplay(renderer, crosshairWorldPosition);
      // ... and then back to a world position?!
      // I'm presuming this was just to align it with the focal plane of the camera...
      const worldPos = displayToWorld(renderer, displayPos);

      // Now move the crosshairs for all views, rendering the view and the crosshairs for each.
      publicAPI.moveCrosshairs(worldPos, false);
    }
  };

  /**
   * Set the slice by moving the camera forwards or backwards.
   * NOTE: Does not redraw anything.
   */
  publicAPI.setSlice = (slice: number) => {
    const camera = model.interactor.getCurrentRenderer().getActiveCamera();
    moveCameraToSlice(camera, slice);
  };

  /**
   * Based on the current camera position and direction get the range of slice values
   * that bound the volume.
   * NOTE: Does not redraw anything.
   */
  publicAPI.getSliceRange = () => {
    const { apis, apiIndex } = model;
    if (!apis || apiIndex === undefined) return;
    const thisApi = apis[apiIndex];
    if (model.volumeActor) {
      const sliceNormal = thisApi.getCameraDirection();
      if (
        sliceNormal[0] === cache.sliceNormal[0] &&
        sliceNormal[1] === cache.sliceNormal[1] &&
        sliceNormal[2] === cache.sliceNormal[2]
      ) {
        return cache.sliceRange;
      }

      // Get the volume bounds [X0, X1, Y0, Y1, Z0, Z1].
      const bounds = model.volumeActor.getMapper().getBounds();
      // Convert the volume bounds into the bounding corner positions, presumably [TLB, TRB, TLF, TRF, BLB, BRB, BLF, BRF].
      const points = boundsToCorners(bounds);

      // Get rotation matrix from normal to +X (since bounds is aligned to XYZ)
      const transform = vtkMatrixBuilder
        .buildFromDegree()
        .identity()
        .rotateFromDirections(sliceNormal as number[], [1, 0, 0]);
      points.forEach((pt) => transform.apply(pt));

      // Range is now maximum X distance.
      let minX = Infinity;
      let maxX = -Infinity;
      for (let i = 0; i < 8; i++) {
        const x = points[i][0];
        if (x > maxX) {
          maxX = x;
        }
        if (x < minX) {
          minX = x;
        }
      }

      cache.sliceNormal = sliceNormal as number[];
      cache.sliceRange = [minX, maxX];
      return cache.sliceRange;
    }
    return [0, 0];
  };

  publicAPI.setUid = (uid: string) => {
    model.uid = uid;
  };

  publicAPI.getUid = () => {
    return model.uid;
  };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  uid: '',
};

// ----------------------------------------------------------------------------

export function extend(publicAPI: vtkPublicAPI, model: vtkModel, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance
  vtkInteractorStyleManipulator.extend(publicAPI, model, initialValues);

  macro.setGet(publicAPI, model, ['onScroll', 'apiIndex', 'viewType']);
  macro.get(publicAPI, model, ['volumeActor', 'apis', 'viewIndex']);

  // Object specific methods
  vtkInteractorStyleMPRSlice(publicAPI, model);
}

// ----------------------------------------------------------------------------

// Returns new instance factory, takes initial values object
export const newInstance = macro.newInstance(extend, 'vtkInteractorStyleMPRSlice');

// ----------------------------------------------------------------------------

export default Object.assign({ newInstance, extend });
