import { THEME } from '../../config';

/**
 * This file contains style settings exclusively for the Salix authentication UI
 * Where possible, import settings from config.ts to avoid duplication of common settings
 */

export const Container = {};
export const FormContainer = {};
export const FormSection = {
  borderRadius: '4px',
  boxShadow: '10px 10px 60px rgba(0, 0, 0, 0.8)',
  backgroundColor: THEME.colors.background[200],
  marginBottom: '0',
};
export const FormField = {};
export const SectionHeader = {
  color: THEME.colors.global.white,
};
export const SectionBody = {};
export const SectionFooter = {};
export const SectionFooterPrimaryContent = {};
export const SectionFooterSecondaryContent = {};
export const Input = {
  padding: '1rem',
  backgroundColor: THEME.colors.background[400],
  color: THEME.colors.global.white,
  border: `1px solid ${THEME.colors.background[100]}`,
  borderRadius: '4px',
};
export const Button = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  padding: '0 16px',
  fontSize: '16px',
  lineHeight: '1em',
  letterSpacing: '0.02em',
  background: THEME.colors.global.blue,
  border: `1px solid ${THEME.colors.global.blue}`,
  borderRadius: '4px',
  color: THEME.colors.background[200],
  textTransform: 'none',
  fontWeight: '600',
};
export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const SignInButton = {};
export const SignInButtonIcon = {};
export const SignInButtonContent = {};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {
  color: THEME.colors.global.blue,
};
export const Hint = {
  color: THEME.colors.global.white,
  fontSize: '13px',
};
export const Radio = {};
export const Label = {};
export const InputLabel = {
  color: THEME.colors.global.white,
};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {
  backgroundColor: THEME.colors.background[300],
  borderLeft: `5px solid ${THEME.colors.global.red}`,
};
export const NavBar = {
  backgroundColor: THEME.colors.background[400],
  border: 0,
};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {
  ...Button,
  backgroundColor: THEME.colors.global.transparent,
  color: THEME.colors.global.blue,
  minWidth: 100,
  padding: '5px 0',
};

export const ArtryaTheme = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,

  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,

  input: Input,
  button: Button,
  photoPickerButton: PhotoPickerButton,
  photoPlaceholder: PhotoPlaceholder,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  amazonSignInButton: AmazonSignInButton,
  facebookSignInButton: FacebookSignInButton,
  googleSignInButton: GoogleSignInButton,
  oAuthSignInButton: OAuthSignInButton,

  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,

  hint: Hint,
  radio: Radio,
  label: Label,
  inputLabel: InputLabel,
  toast: Toast,

  navBar: NavBar,
  nav: Nav,
  navRight: NavRight,
  navItem: NavItem,
  navButton: NavButton,
};
