import * as api from './api';

export default class VersionHeadUpdater {
  // incrementing to track version recency
  _mostRecentHeadCount: number | null;
  _headCount: number;
  _versionCountMap: { [key: string]: number };
  _pendingRequest: Promise<void | string> | null;

  constructor() {
    this._mostRecentHeadCount = null;
    this._headCount = 0;
    this._versionCountMap = {};
    this._pendingRequest = null;
  }

  public reset() {
    this._mostRecentHeadCount = null;
    this._headCount = 0;
    this._versionCountMap = {};
    this._pendingRequest = null;
  }

  private async _update(versionNumber?: string, patientID?: string, runID?: string): Promise<void | string> {
    if (versionNumber === undefined) {
      return;
    }

    if (!(versionNumber in this._versionCountMap)) {
      console.warn(`could not find edit ${versionNumber} in history`);
      return;
    }

    if (this._mostRecentHeadCount !== null && this._versionCountMap[versionNumber] < this._mostRecentHeadCount) {
      // cleanup map
      delete this._versionCountMap[versionNumber];
      return;
    }

    try {
      await api.postJSON(`data/${patientID}/${runID}/version/head?version_id=${versionNumber}`);
    } catch (err) {
      // TODO give feedback to the user that version update failed
      // potentially add retry logic
      console.error(err);
      return;
    }

    this._mostRecentHeadCount = this._versionCountMap[versionNumber];
    delete this._versionCountMap[versionNumber];
    return versionNumber;
  }

  public updateVersionCountMap(versionNumber?: string) {
    if (!versionNumber) return;

    this._versionCountMap[versionNumber] = this._headCount++;
  }

  public async updateBackendVersionHead(
    versionNumber?: string,
    patientID?: string,
    runID?: string
  ): Promise<void | string> {
    // If a previous request is still on the go. Let it finish first. Then clean it up in the finally
    // block. The front end only cares about the latest version, so we'll allow this response to go
    // into the void.
    if (this._pendingRequest !== null) {
      try {
        await this._pendingRequest;
      } catch (err) {
        console.error('Pending backend version head update failed:', err);
      } finally {
        this._pendingRequest = null;
      }
    }

    this._pendingRequest = this._update(versionNumber, patientID, runID);
    return this._pendingRequest;
  }
}
