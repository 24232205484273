import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { TextInput } from '../../components/reusable/TextInput/TextInput';
import { useDashboardDispatch, useDashboardSelector } from '../../dashboardHooks';
import { loginActions } from '../../reducers/login/loginSlice';
import { useLoginFetch } from '../../reducers/login/useLoginFetch';
import { getNewToken } from '../../utils/auth';

const LoginNew: React.FunctionComponent = () => {
  const dashboardDispatch = useDashboardDispatch();
  const loginError = useDashboardSelector((state) => state.login.error);
  const login = useLoginFetch();

  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  // get new token in localstorage
  useEffect(() => {
    if (getNewToken()) navigate('/');
  }, [navigate]);

  const emailValidate = useCallback(() => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (email != null && emailRegex.test(email)) {
      return false;
    }
    return true;
  }, [email]);

  const passwordValidate = useCallback(() => {
    if (password != null && password.trim().length <= 5) {
      setPassword(password);
      return true;
    }
    return false;
  }, [password, setPassword]);

  const loginHandler = useCallback(
    async (event) => {
      event.preventDefault();
      const emailError = emailValidate();
      const passwordError = passwordValidate();
      setEmailError(emailError);
      setPasswordError(passwordError);

      dashboardDispatch(loginActions.setError(undefined));
      if (passwordError === false && emailError === false) {
        const response = await login(email, password);
        // redirect to home page if login success
        if (response) navigate('/');
      }
    },
    [email, password, dashboardDispatch, emailValidate, passwordValidate, navigate, login]
  );

  return (
    <div className="login-new" id="page-login-new">
      <h1 className="login-new__heading">Sign in to Salix</h1>
      <form id="login-form" onSubmit={(event) => loginHandler(event)}>
        <ul className="form-items">
          <li className="form-item">
            <TextInput
              id="login-email-address"
              topLabel="Email Address"
              type="email"
              placeholder="Please enter your email address"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onBlur={() => emailValidate()}
            />
            {emailError && (
              <p id="email-error" className="form-error">
                A valid email address is required
              </p>
            )}
          </li>
          <li className="form-item">
            <TextInput
              id="login-password"
              topLabel="Password"
              type="password"
              placeholder="Please enter your password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onBlur={() => passwordValidate()}
            />
            {passwordError && (
              <p id="password-error" className="form-error">
                A password is required
              </p>
            )}
          </li>
          <li className="form-item form-item--actions">
            <Button id="login-submit-button" type="submit">
              Log in
            </Button>
            {loginError && <p className="form-error">{loginError}</p>}
          </li>
        </ul>
      </form>
    </div>
  );
};

export default LoginNew;
