import React from 'react';
import { CommandBar } from '../CommandBar';

export const ReportCommandBar = () => {
  return (
    <CommandBar
      children={
        <>
          <div className="command-bar__column command-bar__column--left"></div>
          <div className="command-bar__column command-bar__column--center"></div>
          <div className="command-bar__column command-bar__column--right"></div>
        </>
      }
    />
  );
};
