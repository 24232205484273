import * as intersects from 'intersects';
import * as PIXI from 'pixi.js-legacy';
import {
  CreateMarkerSpriteInterface,
  DrawDashedLineInterface,
  TOOL_TYPES,
  RULER_STATE,
  ELLIPSE_STATE,
  MM_TO_PX,
  Measurement,
  Rectangle,
} from './types';
import straightRulerIcon from '../../assets/icons/straight-ruler-blue.svg';
import ellipseRulerIcon from '../../assets/icons/ellipse-ruler-blue.svg';
import { PointObject } from '../../types/common';
import { MeasurementGraphics } from './types';
import { COLOR_CPR_MARKER_COLOR_INDICATOR, THEME } from '../../config';
import { MINIMUM_ELLIPSE_AREA, MINIMUM_RULER_LENGTH } from './measurementTools.consts';
import { Line, XYCoords } from '../../reducers/vesselData/types';

export const MEASUREMENT_SETTINGS = {
  COLOR: 0xffffff,
  WIDTH: 1,
  SHADOW_OPACITY: 0.7,
  SHADOW_SIZE: 3,
  SHADOW_COLOR: 0x000000,
  BORDER_RADIUS: 3,
  CROSSHAIRS_SIZE: {
    active: 9,
    shadowActive: 10,
    inactive: 6,
    shadowInactive: 7,
  },
  HIT_AREA_BUFFER_PX: 2,
  MARKER_COLOR: COLOR_CPR_MARKER_COLOR_INDICATOR,
  DELETE_KEYS: ['Backspace'],
  DASHED_LINE: ['new', 'moving', 'active'],
  DRAW_CIRCLE: ['ShiftLeft'],
};

// load the icons for the ruler
let loader = new PIXI.Loader();
loader.add('ellipse', ellipseRulerIcon).add('ruler', straightRulerIcon).load();

export const drawLine = (sprite: PIXI.Graphics, startPoint: PointObject, endPoint: PointObject) => {
  sprite.moveTo(startPoint.x, startPoint.y);
  sprite.lineTo(endPoint.x, endPoint.y);
};

export const getPerpendicularLine = (line: PointObject[], width = 50, angle: number = 90) => {
  angle = getLineAngle(line[0], line[line.length - 1]) + angle;
  // If the line has three points we want the center to be the second point.
  const center = line[Math.round((line.length - 1) / 2)];
  const distance = width;
  const p1 = {
    x: center.x + distance * Math.cos((angle * Math.PI) / 180),
    y: center.y + distance * Math.sin((angle * Math.PI) / 180),
  };
  const p2 = {
    x: center.x - distance * Math.cos((angle * Math.PI) / 180),
    y: center.y - distance * Math.sin((angle * Math.PI) / 180),
  };
  return [p1, center, p2];
};

const getLineAngle = (point1: XYCoords, point2: XYCoords) => {
  return (Math.atan2(point2.y - point1.y, point2.x - point1.x) * 180) / Math.PI;
};

interface MarkerPosition {
  // The position of the marker's top left corner.
  markerPoint: XYCoords;
  // The line from the measurement to the marker.
  line: Line;
}

/**
 * Return a marker position that is fully within the view bounds.
 * @param points The ruler end points, the first point in the list will have priority.
 * @param bounds The view bounds relative to the container this marker will be placed in.
 * @param markerSize The width and height of the rectangular marker text box.
 * @param markerDistance The normal distance away from an end point that the marker should be placed at.
 */
const getMarkerPositionRuler = (
  points: XYCoords[],
  bounds: Rectangle,
  markerSize: PointObject,
  markerDistance: number
): MarkerPosition => {
  // We can use the line gradient to decide if the marker should be positioned slightly up or slightly down from the end point.
  const offsetY: number[] = points[1].y > points[0].y ? [-markerSize.y, 0] : [0, -markerSize.y];

  // Define all the possible marker positions.
  const markerPoints: XYCoords[] = [];
  points.forEach((point, index) => {
    // Right position.
    markerPoints.push({ x: point.x + markerDistance, y: point.y + offsetY[index] });
    // Left position.
    markerPoints.push({ x: point.x - markerSize.x - markerDistance, y: point.y + offsetY[index] });
  });

  // Find the best marker position (ie one that is mostly inside the view).
  let bestMarkerPoint: XYCoords = markerPoints[0];
  let bestMarkerArea = getRectangleIntersectionArea(bestMarkerPoint, markerSize, bounds);
  markerPoints.forEach((markerPoint) => {
    let markerArea = getRectangleIntersectionArea(markerPoint, markerSize, bounds);
    // Add 1 to the best area to avoid jumping due to precision errors.
    if (markerArea > bestMarkerArea + 1) {
      bestMarkerArea = markerArea;
      bestMarkerPoint = markerPoint;
    }
  });

  // Restrict the marker position so the marker is fully within the bounds.
  forceRectangleInsideRectangle(bestMarkerPoint, markerSize, bounds);

  // Define all the possible lines from the marker to the endpoints.
  const lines: Line[] = [];
  points.forEach((point) => {
    lines.push({
      start: point,
      end: { x: bestMarkerPoint.x, y: bestMarkerPoint.y + markerSize.y / 2 },
    });
    lines.push({
      start: point,
      end: { x: bestMarkerPoint.x + markerSize.x, y: bestMarkerPoint.y + markerSize.y / 2 },
    });
  });

  // Find the best line (ie shortest).
  let bestLine: Line = lines[0];
  let bestLength = getLineLength(bestLine);
  lines.forEach((line) => {
    const length = getLineLength(line);
    if (length < bestLength) {
      bestLine = line;
      bestLength = length;
    }
  });

  return {
    markerPoint: bestMarkerPoint,
    line: bestLine,
  };
};

/**
 * Return a marker position that is fully within the view bounds.
 * @param points The ellipse end points.
 * @param bounds The view bounds relative to the container this marker will be placed in.
 * @param markerSize The width and height of the rectangular marker text box.
 * @param markerDistance The normal distance away from an end point that the marker should be placed at.
 */
const getMarkerPositionEllipse = (
  points: XYCoords[],
  bounds: Rectangle,
  markerSize: PointObject,
  markerDistance: number
): MarkerPosition => {
  // Get the center of the ellipse.
  const center = { x: 0.5 * (points[0].x + points[1].x), y: 0.5 * (points[0].y + points[1].y) };
  // Get the top left and bottom right corners of the ellipses bounding rectangle.
  const topLeft = { x: Math.min(points[0].x, points[1].x), y: Math.min(points[0].y, points[1].y) };
  const bottomRight = { x: Math.max(points[0].x, points[1].x), y: Math.max(points[0].y, points[1].y) };

  // Define all the possible marker positions.
  const markerPoints: XYCoords[] = [
    // Right position.
    { x: bottomRight.x + markerDistance, y: center.y - markerSize.y },
    // Left position.
    { x: topLeft.x - markerSize.x - markerDistance, y: center.y - markerSize.y },
    // Top position.
    { x: center.x - markerSize.x / 2, y: topLeft.y - markerSize.y - markerDistance },
    // Bottom position.
    { x: center.x - markerSize.x / 2, y: bottomRight.y + markerDistance },
  ];

  // Find the best marker position (ie one that is mostly inside the view).
  let bestMarkerPoint: XYCoords = markerPoints[0];
  let bestMarkerArea = getRectangleIntersectionArea(bestMarkerPoint, markerSize, bounds);
  let bestIndex: number = 0;
  markerPoints.forEach((markerPoint, index) => {
    let markerArea = getRectangleIntersectionArea(markerPoint, markerSize, bounds);
    // Add 1 to the best area to avoid jumping due to precision errors.
    if (markerArea > bestMarkerArea + 1) {
      bestMarkerArea = markerArea;
      bestMarkerPoint = markerPoint;
      bestIndex = index;
    }
  });

  // Restrict the marker position so the marker is fully within the bounds.
  forceRectangleInsideRectangle(bestMarkerPoint, markerSize, bounds);

  // Define the shortest line from the ellipse to the marker point.
  let bestLine: Line;
  switch (bestIndex) {
    // Right position.
    default:
    case 0:
      bestLine = {
        start: { x: bottomRight.x, y: center.y },
        end: { x: bestMarkerPoint.x, y: bestMarkerPoint.y + markerSize.y / 2 },
      };
      break;
    // Left position.
    case 1:
      bestLine = {
        start: { x: topLeft.x, y: center.y },
        end: { x: bestMarkerPoint.x + markerSize.x, y: bestMarkerPoint.y + markerSize.y / 2 },
      };
      break;
    // Top position.
    case 2:
      bestLine = {
        start: { x: center.x, y: topLeft.y },
        end: { x: bestMarkerPoint.x + markerSize.x / 2, y: bestMarkerPoint.y + markerSize.y },
      };
      break;
    // Bottom position.
    case 3:
      bestLine = {
        start: { x: center.x, y: bottomRight.y },
        end: { x: bestMarkerPoint.x + markerSize.x / 2, y: bestMarkerPoint.y },
      };
      break;
  }

  return {
    markerPoint: bestMarkerPoint,
    line: bestLine,
  };
};

/**
 * Create and return a new marker sprite.
 */
export const createMarkerSprite = ({
  type,
  label,
  points,
  bounds,
  scale,
  showLine,
  huData,
}: CreateMarkerSpriteInterface): PIXI.Graphics => {
  const sprite = new PIXI.Graphics();
  const style = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 12,
    fill: THEME.colors.global.white,
    align: 'center',
  });
  let text_HUMean, text_HUSD;
  const text = new PIXI.Text(label, style);
  let rectangleHeight = text.height + 8;

  if (type === TOOL_TYPES.Ellipse) {
    text_HUMean = new PIXI.Text(
      // Checking for undefined as zero is still a valid value.
      `HU Mean ${huData?.mean !== undefined ? Number(huData.mean).toFixed(2) : '-'}`,
      style
    );
    rectangleHeight += text_HUMean.height + 7;
    text_HUSD = new PIXI.Text(
      // Checking for undefined as zero is still a valid value.
      `HU SD ${huData?.stdDev !== undefined ? Number(huData.stdDev).toFixed(2) : '-'}`,
      style
    );
    rectangleHeight += 7;
  }

  // Draw the rounded grey backing rect.
  let rectangleWidth = 70;

  const texture = type === TOOL_TYPES.Ruler ? loader.resources.ruler.texture : loader.resources.ellipse.texture;
  const spriteIcon = new PIXI.Sprite(texture);
  spriteIcon.anchor.set(0.1);

  const rectangle = new PIXI.Graphics();
  rectangle.beginFill(Number(MEASUREMENT_SETTINGS.MARKER_COLOR.replace('#', '0x')));
  rectangle.drawRoundedRect(0, 0, rectangleWidth, rectangleHeight, 4);
  rectangle.endFill();

  const markerBox = new PIXI.Container();
  markerBox.addChild(rectangle, spriteIcon, text);
  const padding = 15;
  rectangleWidth = text.width + spriteIcon.width + padding;
  // Center the text in the middle of the rectangle.
  text.x = rectangleWidth / 2 + padding / 2;
  text.y = rectangleHeight / 2;
  text.anchor.set(0.5, 0.5);

  spriteIcon.x = 5;
  spriteIcon.y = 5;
  rectangle.width = rectangleWidth;
  if (type === TOOL_TYPES.Ellipse && text_HUMean && text_HUSD) {
    text.y = (text.height + 7) / 2;
    text_HUMean.x = text.x - text.width / 2;
    text_HUMean.y = text.y + text.height / 2;
    text_HUSD.x = text.x - text.width / 2;
    text_HUSD.y = text_HUMean.y + text_HUMean.height;

    rectangle.width = text_HUMean.width + spriteIcon.width + padding;
    markerBox.addChild(text_HUMean, text_HUSD);
  }

  // Scale the marker.
  markerBox.scale.x = 1.0 / scale;
  markerBox.scale.y = 1.0 / scale;

  // The marker is positioned this far away from the start or end point.
  const distance = 25;

  // Get the best marker position that is fully within the view bounds.
  let markerPosition: MarkerPosition | undefined;
  if (type === TOOL_TYPES.Ruler && points && bounds) {
    markerPosition = getMarkerPositionRuler(
      points,
      bounds,
      { x: markerBox.width, y: markerBox.height },
      distance / scale
    );
  } else if (type === TOOL_TYPES.Ellipse && points && bounds) {
    markerPosition = getMarkerPositionEllipse(
      points,
      bounds,
      { x: markerBox.width, y: markerBox.height },
      distance / scale
    );
  }

  // line btw marker and ruler
  if (markerPosition) {
    // Set the marker positon.
    markerBox.x = markerPosition.markerPoint.x;
    markerBox.y = markerPosition.markerPoint.y;

    // Draw the dashed line to the maker?
    if (showLine) {
      drawDashedLine({
        line: sprite,
        start: markerPosition.line.start,
        end: markerPosition.line.end,
        dashLength: 3 / scale,
        lineWidth: MEASUREMENT_SETTINGS.WIDTH / scale,
        alpha: 0.001,
      });
    }
  }
  // Add the markerBox to the sprite.
  sprite.addChild(markerBox);
  return sprite;
};

//split line into equal parts and draw it
export const drawDashedLine = ({
  line,
  start,
  end,
  dashLength,
  lineWidth,
  alpha = MEASUREMENT_SETTINGS.SHADOW_OPACITY,
}: DrawDashedLineInterface) => {
  const distance = Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
  const angle = getLineAngle(start, end);
  const numDashes = Math.floor(distance / dashLength);
  let x = start.x;
  let y = start.y;
  for (let i = 0; i < numDashes; i++) {
    line.lineStyle(lineWidth, 0x000000, alpha);
    if (i % 2 === 0) {
      line.lineStyle(lineWidth, 0xffffff, MEASUREMENT_SETTINGS.SHADOW_OPACITY);
    }
    line.moveTo(x, y);
    x += Math.cos((angle * Math.PI) / 180) * dashLength;
    y += Math.sin((angle * Math.PI) / 180) * dashLength;
    line.lineTo(x, y);
  }
  line.lineStyle(lineWidth, 0xffffff, alpha);
  line.moveTo(x, y);
  line.lineTo(end.x, end.y);
};

/**
 * Get the sprite to draw an inactive crosshair at the specified position and scale.
 */
export const drawInactiveCrosshair = (sprite: PIXI.Graphics, point: XYCoords, scale: number) => {
  // Draw the crosshair shadow.
  sprite.lineStyle(
    MEASUREMENT_SETTINGS.SHADOW_SIZE / scale,
    MEASUREMENT_SETTINGS.SHADOW_COLOR,
    MEASUREMENT_SETTINGS.SHADOW_OPACITY
  );
  let lineLength = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.shadowInactive / scale;
  drawLine(sprite, { x: point.x - lineLength, y: point.y }, { x: point.x + lineLength, y: point.y });
  drawLine(sprite, { x: point.x, y: point.y - lineLength }, { x: point.x, y: point.y + lineLength });

  // Drawing the crosshair.
  sprite.lineStyle(MEASUREMENT_SETTINGS.WIDTH / scale, MEASUREMENT_SETTINGS.COLOR);
  lineLength = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale;
  drawLine(sprite, { x: point.x - lineLength, y: point.y }, { x: point.x + lineLength, y: point.y });
  drawLine(sprite, { x: point.x, y: point.y - lineLength }, { x: point.x, y: point.y + lineLength });
};

/**
 * Get the sprite to draw an active crosshair at the specified position and scale.
 */
export const drawActiveCrosshair = (sprite: PIXI.Graphics, point: XYCoords, scale: number) => {
  // Draw the crosshair shadow.
  sprite.lineStyle(
    MEASUREMENT_SETTINGS.SHADOW_SIZE / scale,
    MEASUREMENT_SETTINGS.SHADOW_COLOR,
    MEASUREMENT_SETTINGS.SHADOW_OPACITY
  );
  let maxLineLength = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.shadowActive / scale;
  let minLineLength = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.shadowActive / (3 * scale);
  drawLine(sprite, { x: point.x - maxLineLength, y: point.y }, { x: point.x - minLineLength, y: point.y });
  drawLine(sprite, { x: point.x + minLineLength, y: point.y }, { x: point.x + maxLineLength, y: point.y });
  drawLine(sprite, { x: point.x, y: point.y - maxLineLength }, { x: point.x, y: point.y - minLineLength });
  drawLine(sprite, { x: point.x, y: point.y + minLineLength }, { x: point.x, y: point.y + maxLineLength });

  // Drawing the crosshair.
  sprite.lineStyle(MEASUREMENT_SETTINGS.WIDTH / scale, MEASUREMENT_SETTINGS.COLOR);
  maxLineLength = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.active / scale;
  minLineLength = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.active / (3 * scale);
  drawLine(sprite, { x: point.x - maxLineLength, y: point.y }, { x: point.x - minLineLength, y: point.y });
  drawLine(sprite, { x: point.x + minLineLength, y: point.y }, { x: point.x + maxLineLength, y: point.y });
  drawLine(sprite, { x: point.x, y: point.y - maxLineLength }, { x: point.x, y: point.y - minLineLength });
  drawLine(sprite, { x: point.x, y: point.y + minLineLength }, { x: point.x, y: point.y + maxLineLength });
};

export const getDistanceBetweenPoints = (point1: XYCoords, point2: XYCoords) => {
  return Math.hypot(point2.x - point1.x, point2.y - point1.y);
};

export const getLineLength = (line: Line) => {
  return Math.hypot(line.end.x - line.start.x, line.end.y - line.start.y);
};

/**
 * Get the area of intersection of two rectangles.
 * @param point The top left corner of the first rectangle.
 * @param size The width and height of the first rectangle.
 * @param bounds The second rectangle.
 */
export const getRectangleIntersectionArea = (point: XYCoords, size: XYCoords, bounds: Rectangle): number => {
  const minX = Math.max(point.x, bounds.x);
  const maxX = Math.min(point.x + size.x, bounds.x + bounds.width);
  const minY = Math.max(point.y, bounds.y);
  const maxY = Math.min(point.y + size.y, bounds.y + bounds.height);
  if (minX >= maxX || minY >= maxY) return 0;
  return (maxX - minX) * (maxY - minY);
};

/**
 * Force the first rectangle to be inside the second rectangle.
 * @param point The top left corner of the first rectangle (this value is mutated).
 * @param size The width and height of the first rectangle.
 * @param bounds The second rectangle.
 */
export const forceRectangleInsideRectangle = (point: XYCoords, size: XYCoords, bounds: Rectangle) => {
  if (point.x < bounds.x) {
    point.x = bounds.x;
  }
  if (point.y < bounds.y) {
    point.y = bounds.y;
  }
  if (point.x + size.x > bounds.x + bounds.width) {
    point.x = bounds.x + bounds.width - size.x;
  }
  if (point.y + size.y > bounds.y + bounds.height) {
    point.y = bounds.y + bounds.height - size.y;
  }
};

//copied from CPRViewer
export const intersectsNodes = (nodeList: PointObject[], node: PointObject, tolerance: number = 1) => {
  let indexes: number[] = [];
  nodeList.forEach((_, i) => {
    const prevPoint = nodeList[i - 1] || nodeList[0];
    const thisPoint = nodeList[i];
    const intersect = intersects.linePoint(
      prevPoint.x,
      prevPoint.y,
      thisPoint.x,
      thisPoint.y,
      node.x,
      node.y,
      tolerance
    );
    if (intersect) {
      indexes.push(i);
    }
  });
  return indexes;
};

export const setLineStyle = (sprite: PIXI.Graphics, isShadow: boolean, isInactive: boolean, scale: number) => {
  const width =
    isShadow && isInactive ? MEASUREMENT_SETTINGS.WIDTH + MEASUREMENT_SETTINGS.SHADOW_SIZE : MEASUREMENT_SETTINGS.WIDTH;
  const color = isShadow ? MEASUREMENT_SETTINGS.SHADOW_COLOR : MEASUREMENT_SETTINGS.COLOR;
  const opacity = isShadow ? MEASUREMENT_SETTINGS.SHADOW_OPACITY : 1;
  sprite.lineStyle(width / scale, color, opacity);
};

export const getAxisMPRViewPixelPerMm = (): number => {
  return MM_TO_PX;
};

export const getNonContrastViewPixelPerMm = (): number => {
  return MM_TO_PX;
};

export const getAreaOfEllipse = (radiusX: number, radiusY: number): number => {
  return Math.PI * radiusX * radiusY;
};

/**
 * Get the minimum distance from the point to the line segment defined by start and end.
 */
export const distanceFromPointToLineSegment = (point: PointObject, start: PointObject, end: PointObject): number => {
  const a = point.x - start.x;
  const b = point.y - start.y;
  const c = end.x - start.x;
  const d = end.y - start.y;

  const dot = a * c + b * d;
  const len_sq = c * c + d * d;
  var param = -1;
  // In case of 0 length line
  if (len_sq !== 0) param = dot / len_sq;

  var xx, yy;
  if (param < 0) {
    xx = start.x;
    yy = start.y;
  } else if (param > 1) {
    xx = end.x;
    yy = end.y;
  } else {
    xx = start.x + param * c;
    yy = start.y + param * d;
  }

  const dx = point.x - xx;
  const dy = point.y - yy;
  return Math.sqrt(dx * dx + dy * dy);
};

/**
 * Create a hit test object for the specified line and callback.
 * @param start: The starting point of the line.
 * @param end: The ending point of the line.
 * @param action: The MEASUREMENT_SETTINGS.RULER_STATE or MEASUREMENT_SETTINGS.ELLIPSE_STATE to set on the hit object. This will be used in the callback.
 * @param callback: The callback to call on a mouse down event.
 */
export const createHitLine = (
  start: PointObject,
  end: PointObject,
  scale: number,
  measurementId: string,
  action: RULER_STATE | ELLIPSE_STATE,
  callback: (event: React.MouseEvent) => void
) => {
  // Create an invisible hit sprite to respond to the mouse.
  const hitSprite = new PIXI.Graphics() as MeasurementGraphics;
  hitSprite.interactive = true;
  hitSprite.action = action;
  hitSprite.measurementId = measurementId;
  hitSprite.cursor = 'pointer';
  hitSprite.on('mousedown', callback);
  const maxDistance = (0.5 * MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.active) / scale;
  const minBound = { x: Math.min(start.x, end.x) - maxDistance, y: Math.min(start.y, end.y) - maxDistance };
  const maxBound = { x: Math.max(start.x, end.x) + maxDistance, y: Math.max(start.y, end.y) + maxDistance };
  hitSprite.hitArea = {
    contains: (x: number, y: number): boolean => {
      // Do a simple bounding box test first as this is much faster to reject points.
      if (x < minBound.x || x > maxBound.x || y < minBound.y || y > maxBound.y) return false;
      return distanceFromPointToLineSegment({ x, y }, start, end) < maxDistance;
    },
  };
  return hitSprite;
};

/**
 * Create a hit test object for the specified circle and callback.
 * @param position: The center of the hit circle.
 * @param radius: The radius of the hit circle.
 * @param action: The MEASUREMENT_SETTINGS.RULER_STATE or MEASUREMENT_SETTINGS.ELLIPSE_STATE to set on the hit object. This will be used in the callback.
 * @param callback: The callback to call on a mouse down event.
 */
export const createHitCircle = (
  position: XYCoords,
  radius: number,
  measurementId: string,
  action: RULER_STATE | ELLIPSE_STATE,
  callback: (event: React.MouseEvent) => void
): MeasurementGraphics => {
  // Create an invisible hit sprite to respond to the mouse.
  const hitSprite = new PIXI.Graphics() as MeasurementGraphics;
  hitSprite.interactive = true;
  hitSprite.action = action;
  hitSprite.measurementId = measurementId;
  hitSprite.cursor = 'move';
  hitSprite.on('mousedown', callback);
  hitSprite.hitArea = {
    contains: (x: number, y: number): boolean => {
      return Math.hypot(position.x - x, position.y - y) < radius;
    },
  };
  return hitSprite;
};

/**
 * Create a hit test object for the specified ellipse and callback.
 * @param midPoint: The center of the ellipse.
 * @param radius: The radius of the ellipse in x and y.
 * @param lineThickness: The width of line that is the the hit area.
 * @param measurementId: The measurement identifier.
 * @param action: The MEASUREMENT_SETTINGS.ELLIPSE_STATE to set on the hit object. This will be used in the callback.
 * @param callback: The callback to call on a mouse down event.
 */
export const createHitEllipse = (
  midPoint: XYCoords,
  radius: XYCoords,
  lineThickness: number,
  measurementId: string,
  action: ELLIPSE_STATE,
  callback: (event: React.MouseEvent) => void
): MeasurementGraphics => {
  // Create an invisible hit sprite to respond to the mouse.
  const hitSprite = new PIXI.Graphics() as MeasurementGraphics;
  hitSprite.interactive = true;
  hitSprite.measurementId = measurementId;
  hitSprite.action = action;
  hitSprite.cursor = 'move';
  hitSprite.on('mousedown', callback);
  hitSprite.hitArea = {
    contains: (x: number, y: number): boolean => {
      // Because we are calculating a distance to an ellipse vs a circle we need to scale the x axis.
      // Because we don't want the hitRadius to stretch either we need to include it in the scaling calculation.
      const outerRadius = { x: radius.x + lineThickness, y: radius.y + lineThickness };
      const outerDistance = Math.hypot((outerRadius.y / outerRadius.x) * (midPoint.x - x), midPoint.y - y);
      // Fail the test if the point is outside the outer radius.
      if (outerDistance > radius.y + lineThickness) return false;
      // Check if the inner radius is required (if the ellipse is tiny all points inside it will be legitimate hit points).
      const innerRadius = { x: radius.x - lineThickness, y: radius.y - lineThickness };
      if (innerRadius.x > 0 && innerRadius.y > 0) {
        // We need the inner test.
        const innerDistance = Math.hypot((innerRadius.y / innerRadius.x) * (midPoint.x - x), midPoint.y - y);
        if (innerDistance < radius.y - lineThickness) return false;
      }
      // All tests passed, the point is inside the ellipse hit area.
      return true;
    },
  };
  return hitSprite;
};

export const getClosestLine = (currentPoint: XYCoords, lastPoint: XYCoords) => {
  // lock angle 45, 125, 225 and 315.
  var x = currentPoint.x - lastPoint.x;
  var y = currentPoint.y - lastPoint.y;
  var r = Math.sqrt(x * x + y * y);

  var angle = (Math.atan2(y, x) / Math.PI) * 180;
  angle = (angle % 360) + 180;

  if (angle <= 90) {
    angle = 45;
  } else if (angle <= 180) {
    angle = 135;
  } else if (angle <= 270) {
    angle = 225;
  } else if (angle <= 360) {
    angle = 315;
  }
  angle -= 180;

  var x1 = r * Math.cos((angle * Math.PI) / 180);
  var y1 = r * Math.sin((angle * Math.PI) / 180);

  return {
    x: x1 + lastPoint.x,
    y: y1 + lastPoint.y,
  };
};

/**
 * Small measurements are removed, check if the given measurement meets the minimum size requirement.
 * @param useThresholds If true check the measurement size against the minimum thresholds.
 *                      If false just check the measurement size is defined (it will be undefined when first created, until the mouse is moved).
 */
export const isMeasurementUndersized = (measurement: Measurement, useThresholds: boolean): boolean => {
  if (measurement.type === TOOL_TYPES.Ruler) {
    return useThresholds ? (measurement.length || 0) < MINIMUM_RULER_LENGTH : measurement.length === undefined;
  }
  if (measurement.type === TOOL_TYPES.Ellipse) {
    return useThresholds ? (measurement.area || 0) < MINIMUM_ELLIPSE_AREA : measurement.area === undefined;
  }
  return true;
};
