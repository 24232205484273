import { useCallback } from 'react';
import { useDashboardDispatch } from '../../dashboardHooks';
import { loginActions } from './loginSlice';

const API_PLATFORM_BASE_URL = process.env.REACT_APP_PLATFORM_BASE_URL;

export function useLoginFetch() {
  const dispatch = useDashboardDispatch();
  return useCallback(
    async (email: string, password: string) => {
      try {
        const response = await fetch(`${API_PLATFORM_BASE_URL}/security/obtain`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
          }),
        });
        const login = await response.json();
        if (!login.successful || login.successful === false) {
          console.log('Login failed');
          dispatch(loginActions.setError(login.display_message));
          return false;
        }
        // save tokens to loginSlice if login is successful
        dispatch(loginActions.isLogin(login.successful));
        dispatch(loginActions.setRefreshToken(login.content.refresh));
        dispatch(loginActions.setAccessToken(login.content.access));
        // save tokens on local storage
        localStorage.setItem('refresh', login.content.refresh);
        localStorage.setItem('access', login.content.access);
        console.log('login successful');
        return true;
      } catch (error) {
        console.log('Login failed');
        dispatch(loginActions.setError('Login failed'));
        return false;
      }
    },
    [dispatch]
  );
}
