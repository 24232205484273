import React, { ReactElement } from 'react';
import cn from 'classnames';
interface Props {
  labelText: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

export default function Toggle({ labelText, name, checked, disabled, onChange }: Props): ReactElement<Props> {
  return (
    <div className="toggle">
      {labelText && (
        <label
          htmlFor={name}
          className={cn('toggle__label', {
            'toggle__label--active': checked,
            'toggle__label--disabled': disabled,
          })}
        >
          {labelText}
        </label>
      )}
      <input
        id={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="toggle__checkbox"
        disabled={disabled}
      />
    </div>
  );
}
