import React from 'react';
import Amplify from '@aws-amplify/core';
import { Auth } from './components/Auth/Auth';
import Pages from './pages';
import { AMPLIFY_CONFIG } from './config';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ToastContainer } from './components/Toast/ToastContainer';

// https://docs.amplify.aws/lib/utilities/logger/q/platform/js#working-with-the-api
// Supported log levels: ERROR, WARN, INFO, DEBUG, VERBOSE
Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_ENABLE_AMPLIFY_DEBUG === 'true' ? 'DEBUG' : null;

Amplify.configure(AMPLIFY_CONFIG);

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      retry: false,
    },
  },
});

export default function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Auth>
        <Pages />
        <ToastContainer />
      </Auth>
    </ReactQueryCacheProvider>
  );
}
