export const getMeanValue = (array: number[]): number | undefined => {
  if (!array || !array.length) return;
  return array.reduce((a: number, b: number) => a + b, 0) / array.length;
};

export const getStandardDeviation = (array: number[]): number | undefined => {
  if (!array || !array.length) return;
  const n = array.length;
  const mean = getMeanValue(array);
  return mean && Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
};
