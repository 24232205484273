import cn from 'classnames';
import React, { ChangeEvent, ReactElement } from 'react';
import { Textarea } from '../reusable/Textarea/Textarea';
import { FindingsStatus } from '../FindingsStatus/FindingsStatus';
import { VesselGroupings } from '../../config';
import { CoronaryFindings } from '../../context/types';
import { getVesselColor } from '../../utils/vessel';
import Alert from '../Alert/Alert';

interface ActiveField {
  vessel: string;
  isFocused: boolean;
  isDirty: boolean;
}

interface Props {
  vesselGroup: VesselGroupings;
  coronaryFindings?: CoronaryFindings;
  draftCoronaryFindings?: string;
  editMode: boolean;
  handleFocus?: () => void;
  handleBlur?: () => void;
  handleInputChange?: (event: ChangeEvent<HTMLTextAreaElement>, vessel: VesselGroupings) => void;
  activeField?: ActiveField;
  showVesselName: boolean;
  statusVersion?: boolean;
  autoFocus?: boolean;
}

export function CoronaryFindingsField({
  vesselGroup,
  coronaryFindings,
  draftCoronaryFindings,
  editMode,
  handleFocus,
  handleBlur,
  handleInputChange,
  activeField,
  showVesselName,
  statusVersion,
  autoFocus = false,
}: Props): ReactElement<Props> {
  return (
    <div
      className={cn('coronary-findings__vessel', {
        'coronary-findings__vessel__show_vessel_name': !showVesselName,
      })}
      key={`coronary-findings-vessel__${vesselGroup}`}
    >
      {showVesselName && (
        <div className="coronary-findings__findings_container">
          <div className="coronary-findings__cell">
            <span
              style={{
                background: getVesselColor(vesselGroup),
              }}
              className="coronary-findings__legend"
            />
            <h3 className="coronary-findings__title">
              <span className="coronary-findings__title-vessel-group">{vesselGroup}</span>
              <span className="coronary-findings__title-branches">{vesselGroup !== 'LM' && ' including branches'}</span>
            </h3>
          </div>
          <div className="coronary-findings__cell coronary-findings__cell__justify_end">
            {!editMode && statusVersion !== undefined && <FindingsStatus versions={statusVersion} />}
          </div>
        </div>
      )}
      <div className="coronary-findings__text">
        <div className="findings">
          {editMode ? (
            <>
              {activeField?.vessel === vesselGroup && activeField.isDirty && activeField.isFocused && (
                <Alert>Ensure that the summary information above and the Impression reflects any changes.</Alert>
              )}
              <Textarea
                maxRows={7}
                className="findings__enter"
                value={draftCoronaryFindings}
                onChange={(ev) => {
                  handleInputChange?.(ev, vesselGroup);
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                autoFocus={autoFocus}
              />
            </>
          ) : (
            <p>{coronaryFindings?.[vesselGroup]}</p>
          )}
        </div>
      </div>
    </div>
  );
}
