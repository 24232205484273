import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import { Ethnicity } from '../types/common';
import * as api from '../utils/api';
import { useAppDispatch } from '../hooks';
import { reportActions } from '../reducers/report/reportSlice';
import { reportStateActions } from '../reducers/ReportState/ReportStateSlice';

/**
 * Returns a function to fetch the MESA percentile using the standalone API
 * endpoint (as opposed to getting it from the report content). Sets the
 * fetchingMesaPercentile flag in ReportContext while the network request is in
 * progress. Shows a toast on error. Called when changing the ethnicity while
 * editing the report.
 *
 * Since the value only needs to be shown while editing the report, update the
 * draft report with the response. If the user saves the report, the MESA score
 * will be recalculated by the API and returned in the report content response.
 */
export function useFetchMesaPercentile() {
  const dispatch = useAppDispatch();

  return useCallback(
    async (sex: string, age: string, ethnicity: Ethnicity, calciumScore: number) => {
      dispatch(reportStateActions.setFetchingMesaPercentile(true));

      try {
        const response = await api.fetchMesaPercentile(sex, age, ethnicity, calciumScore);
        // Including ethnicity here as a workaround for this callback capturing
        // the old ethnicity value.
        dispatch(
          reportActions.updateDraftReport({
            ethnicity: ethnicity,
            mesa_percentile: response.percentile,
          })
        );
      } catch (err) {
        console.warn('Failed to update MESA score:', err);
        showToast.error('Failed to update MESA score');
      } finally {
        dispatch(reportStateActions.setFetchingMesaPercentile(false));
      }
    },
    [dispatch]
  );
}
