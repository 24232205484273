import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import * as api from '../utils/api';
import { useAppDispatch, useAppSelector } from '../hooks';
import { reportStateActions } from '../reducers/ReportState/ReportStateSlice';

/**
 * Returns a function to make an API call to delete a screenshot, setting a flag in the ReportContext to indicate which
 * screenshot is being deleted. Shows a toast if the API call fails.
 */
export function useDeleteScreenshot() {
  const dispatch = useAppDispatch();
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const patientID = useAppSelector((state) => state.patient.patientID);

  return useCallback(
    (filename: string) => {
      if (patientID && runID) {
        dispatch(reportStateActions.setDeletingScreenshot(filename));

        api
          .deleteScreenShot(filename, patientID, runID)
          .then((response) => dispatch(reportStateActions.setScreenshots(response)))
          .catch((err) => {
            showToast.error('Failed to remove screenshot');
          })
          .finally(() => dispatch(reportStateActions.setDeletingScreenshot(undefined)));
      }
    },
    [patientID, runID, dispatch]
  );
}
