// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContrastViewContent } from '../../context/contrast-types';

export interface ViewConfigState {
  seriesId?: string | undefined;
  viewportsToShowBorder: number[];
  recentlySelected: ContrastViewContent[];
}

export const initialViewConfigState: ViewConfigState = {
  seriesId: undefined,
  viewportsToShowBorder: [],
  recentlySelected: [],
};

export const viewConfigSlice = createSlice({
  name: 'viewConfig',
  initialState: initialViewConfigState,
  reducers: {
    setSeriesId: (state, action: PayloadAction<string>) => {
      state.seriesId = action.payload;
    },
    setViewportsToShowBorder: (state, action: PayloadAction<number[]>) => {
      state.viewportsToShowBorder = action.payload;
    },
    setRecentlySelectedViews: (state, action: PayloadAction<ContrastViewContent[]>) => {
      state.recentlySelected = action.payload;
    },
    clearRecentlySelectedViews: (state) => {
      state.recentlySelected = [];
    },
  },
});

export const viewConfigActions = viewConfigSlice.actions;
