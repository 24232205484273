import { isEqual } from 'lodash';
import * as PIXI from 'pixi.js-legacy';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import {
  CPR_SLICE_INDICATOR_BUFFER,
  FFR_TOLERANCE,
  KEY_MPR_LONG_AXIS,
  MPR_SLICE_SPACING,
  MPR_SLICE_SPACING_UNIT,
} from '../../config';
import { useDashboardSelector } from '../../dashboardHooks';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { cprActions } from '../../reducers/cpr/cprSlice';
import { FFRStatus, FFRVesselData, FFRVesselDataResponse, ImageSize, Marker } from '../../context/types';
import { WindowLevels } from '../../reducers/window/types';
import usePreviousValue from '../../hooks/use-previous-value';
import { LongAxisViewerData, XYCoords } from '../../reducers/vesselData/types';
import { useVesselStateSelector } from '../../selectors/vessels';
import { LineObject, PointObject } from '../../types/common';
import { pixiCaptureMouse, PIXIPointerEvent } from '../../utils/captureMouse';
import { timeFuncFactory } from '../../utils/shared';
import {
  calculateAdjacentPoints,
  createMeasurementMarkerSprite,
  createSliceIndicatorSprite,
  getPerpendicularLine,
  INDICATOR_SETTINGS,
  MEASUREMENT_MARKER_SETTINGS,
} from '../CPRViewer/Utils';
import { ToolBar } from '../ToolBar/ToolBar';
import { OnReadyInf, RESIZE_MODE } from '../WebGLViewer/types';
import WebGLViewer from '../WebGLViewer/WebGLViewer';
import { AdjacentPoints } from '../CPRViewer/types';
import { vesselDataActions } from '../../reducers/vesselData/vesselDataSlice';

interface Props {
  windowLevels: WindowLevels;
  windowLabel: string | null;
  onWindowLevelsChange?: (windowLevels: WindowLevels) => void;
  viewerData?: LongAxisViewerData;
  showHighLowIndicators?: boolean;
  onSetActiveLine?: (lineName: string) => void;
  activeLine: string | null;
  ffrData?: FFRVesselDataResponse;
  showFFRLabels?: boolean;
  setFfrenabled?: (ffrEnabled: boolean) => void;
  setFfrStatus?: (ffrStatus: FFRStatus) => void;
  setFfrDetail?: (ffrDetail: string) => void;
  screenshotDisabled?: boolean;
  screenshotRef?: any;
  viewName?: string;
}

export const LongAxisMPRViewer: React.FunctionComponent<Props> = ({
  windowLevels,
  windowLabel,
  onWindowLevelsChange,
  onSetActiveLine,
  activeLine,
  showHighLowIndicators = false,
  viewerData,
  ffrData,
  showFFRLabels = false,
  setFfrenabled,
  setFfrStatus,
  setFfrDetail,
  screenshotDisabled,
  screenshotRef,
  viewName,
}) => {
  const displayMeasurements = useAppSelector((state) => state.globalFlags.displayMeasurements);

  const {
    midSliceIdx: sliceidx,
    highSliceIdx: highSliceidx,
    lowSliceIdx: lowSliceidx,
    selectedVesselData,
    selectedVesselName,
  } = useVesselStateSelector();

  const dispatch = useAppDispatch();
  const { sliceidx: cprSliceidx, sliceCount: cprSliceCount } = useAppSelector((state) => state.cpr);

  const { clientConfig } = useDashboardSelector((state) => state.user);

  const [ready, setReady] = useState<boolean>(true);
  // const [nSlices, setNSlices] = useState<number>(0);
  const [draggingIndicator, setDraggingIndicator] = useState<boolean>(false);
  const hueRef = useRef<any>(null);
  const appRef = useRef<PIXI.Application | null>(null);
  const containerRef = useRef<PIXI.Container | null>(null);
  const imageSizeRef = useRef<ImageSize>({
    width: 0,
    height: 0,
  });
  // The default scaling to apply to the image so that it will fit snugly inside the view.
  const defaultScaleRef = useRef<number>(1);
  const holderRef = useRef<HTMLDivElement | null>(null);
  const nSlicesRef = useRef<number>(0);
  const sliceNoRef = useRef<number>(0);
  const centerLinesDataRef = useRef<LineObject[]>([]);

  // Indicator states
  const midSliceIndicatorSpriteRef = useRef<PIXI.Graphics | null>(null);
  const highSliceIndicatorSpriteRef = useRef<PIXI.Graphics | null>(null);
  const lowSliceIndicatorSpriteRef = useRef<PIXI.Graphics | null>(null);
  const ffrValueContainerRef = useRef<PIXI.Container | null>(null);
  const measurementValueContainerRef = useRef<PIXI.Container | null>(null);
  const annoRef = useRef<PIXI.Container | null>(null);

  const midSliceIndexRef = useRef<number>(sliceidx);
  const lowSliceIndexRef = useRef<number>(sliceidx);
  const highSliceIndexRef = useRef<number>(sliceidx);

  const lineLengthRef = useRef<number>(0);

  const prevSelectedVesselData = usePreviousValue(selectedVesselData);
  const prevSelectedVesselName = usePreviousValue(selectedVesselName);

  const cprSliceIndexRef = useRef<number>(cprSliceidx);
  const mousePosRef = useRef<PointObject>({ x: 0, y: 0 });

  // FFR marker states
  const proximalFFRRef = useRef<Marker | null>(null);
  const distalFFRRef = useRef<Marker | null>(null);
  const ffrDataRef = useRef<FFRVesselDataResponse | undefined>();
  const showFFRLabelsRef = useRef(false);

  const proximalMeasurementRef = useRef<Marker | null>(null);
  const distalMeasurementRef = useRef<Marker | null>(null);

  useEffect(() => {
    // checking ffr data status to determine if we need to continue polling endpoint
    if (!ffrData || !selectedVesselName) {
      setFfrStatus && setFfrStatus('Failed');
      return;
    }
    setFfrStatus && setFfrStatus(ffrData[selectedVesselName].status);
    if (ffrData[selectedVesselName].details) {
      setFfrDetail && setFfrDetail(ffrData[selectedVesselName].details || '');
    }
    ffrDataRef.current = ffrData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ffrData, selectedVesselName]);

  useEffect(() => {
    // On switching vessel set ffrenabled to trigger api call
    if (prevSelectedVesselName && !isEqual(selectedVesselName, prevSelectedVesselName)) {
      setFfrenabled && setFfrenabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevSelectedVesselName, selectedVesselName]);

  /**
   * Correctly position the given measurement marker
   * Position is calculated based on centerline, relevant slice indeces and container scaling
   * @param marker - measurement marker
   */
  const transformMeasurementMarker = useCallback((marker: Marker | null): void => {
    if (marker) {
      const centerline = centerLinesDataRef.current[cprSliceIndexRef.current];
      const lineIndex = getLineIndex(marker.sliceIndex);

      if (centerline && lineIndex !== null && centerline[lineIndex] && containerRef.current) {
        // Find an offset position for marker from perpendicular indicator line.
        const adjacentPoints: AdjacentPoints = calculateAdjacentPoints(centerline, lineIndex);

        // The indicator width is fixed in terms of image pixels so we need to divide it by the defaultScale, then we add the extra offset we want in screen pixels.
        const indicatorWidth =
          (INDICATOR_SETTINGS.INDICATOR_WIDTH + 2) / (defaultScaleRef.current ?? 1) +
          MEASUREMENT_MARKER_SETTINGS.OFFSET / containerRef.current.scale.x;

        //flip ordering of points because we want a perpendicular line projecting to the left
        const indicatorLine = getPerpendicularLine(
          [adjacentPoints.nextPoint, adjacentPoints.slicePoint, adjacentPoints.prevPoint],
          indicatorWidth
        );

        // Scale and offset the marker position based on the current container position and scale.
        marker.x = containerRef.current.x + indicatorLine[2].x * containerRef.current.scale.x;
        marker.y = containerRef.current.y + indicatorLine[2].y * containerRef.current.scale.y - marker.height / 2;
      }
    }
  }, []);

  const createMeasurementSliceMarkerText = useCallback(
    (selectedSliceIndex: number, midSliceIndex: number): Marker | null => {
      const measurement: number = (selectedSliceIndex - midSliceIndex) * MPR_SLICE_SPACING;

      const marker = createMeasurementMarkerSprite(measurement.toFixed(1) + ' ' + MPR_SLICE_SPACING_UNIT);
      marker.zIndex = MEASUREMENT_MARKER_SETTINGS.Z_INDEX;
      marker.sliceIndex = selectedSliceIndex;

      if (measurementValueContainerRef.current) {
        measurementValueContainerRef.current.addChild(marker);
      }

      transformMeasurementMarker(marker);
      return marker;
    },
    [transformMeasurementMarker]
  );

  useEffect(() => {
    if (!ffrDataRef.current) return;
    if (ffrValueContainerRef.current) {
      ffrValueContainerRef.current.visible = showFFRLabels;
      showFFRLabelsRef.current = showFFRLabels;
      // Create the FFR markers.
      if (showFFRLabels) {
        const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
        vesselFFRData.value && createFfrMarkers(Object.entries(ffrDataRef.current)[0][1]);
      }
      // Recreate the indicators so they can have the correct colour set (they may turn red when FFR is active).
      createIndicatorSprites(cprSliceIndexRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ffrDataRef.current, showFFRLabels]);

  useEffect(() => {
    if (displayMeasurements) {
      clearSpriteRef(proximalMeasurementRef);
      clearSpriteRef(distalMeasurementRef);
      proximalMeasurementRef.current = createMeasurementSliceMarkerText(highSliceidx, sliceidx);
      distalMeasurementRef.current = createMeasurementSliceMarkerText(lowSliceidx, sliceidx);

      if (measurementValueContainerRef.current) {
        measurementValueContainerRef.current.visible = displayMeasurements;
      }
    } else {
      clearSpriteRef(proximalMeasurementRef);
      clearSpriteRef(distalMeasurementRef);
    }
  }, [displayMeasurements, lowSliceidx, highSliceidx, sliceidx, createMeasurementSliceMarkerText]);

  useEffect(() => {
    setReady(false);
    setTimeout(() => setReady(true), 0);
  }, [selectedVesselName]);

  useEffect(() => {
    if (
      !isEqual(selectedVesselData?.segments, prevSelectedVesselData?.segments) ||
      !isEqual(selectedVesselData?.n_slices, prevSelectedVesselData?.n_slices) ||
      !isEqual(selectedVesselName, prevSelectedVesselName)
    ) {
      nSlicesRef.current = selectedVesselData?.n_slices || 0;
    }
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVesselName, selectedVesselData]);

  useEffect(() => {
    // Remember the current slice.
    midSliceIndexRef.current = sliceidx;

    // Move high slice indicator
    if (sliceidx - highSliceidx <= CPR_SLICE_INDICATOR_BUFFER) {
      const high = Math.max(sliceidx - CPR_SLICE_INDICATOR_BUFFER, 0);
      dispatch(vesselDataActions.updateSliceIndices({ high }));
    }
    // Move low slice indicator
    if (lowSliceidx - sliceidx <= CPR_SLICE_INDICATOR_BUFFER) {
      const low = Math.min(sliceidx + CPR_SLICE_INDICATOR_BUFFER, nSlicesRef.current - 1);
      dispatch(vesselDataActions.updateSliceIndices({ low }));
    }
    // }
    // Make the current slice visible (if possible).
    !isNaN(sliceidx) &&
      lineLengthRef.current > 0 &&
      createIndicatorSprite(cprSliceIndexRef.current, midSliceIndexRef.current, midSliceIndicatorSpriteRef, 'mid');
    // NOTE: we are depending on selectedVesselData as this effect is being called BEFORE nSlicesRef has a chance to
    // update to the new selected vessel. Ideally this would all happen at once

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliceidx, selectedVesselData, lineLengthRef.current > 0]);

  useEffect(() => {
    highSliceIndexRef.current = highSliceidx;
    if (!isNaN(highSliceidx) && lineLengthRef.current > 0 && showHighLowIndicators) {
      createIndicatorSprite(cprSliceIndexRef.current, highSliceIndexRef.current, highSliceIndicatorSpriteRef, 'high');

      if (ffrDataRef.current) {
        const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
        vesselFFRData.value && createFfrMarkers(vesselFFRData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highSliceidx, lineLengthRef.current > 0]);

  useEffect(() => {
    lowSliceIndexRef.current = lowSliceidx;
    if (!isNaN(lowSliceidx) && lineLengthRef.current > 0 && showHighLowIndicators) {
      createIndicatorSprite(cprSliceIndexRef.current, lowSliceIndexRef.current, lowSliceIndicatorSpriteRef, 'low');

      if (ffrDataRef.current) {
        const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
        vesselFFRData.value && createFfrMarkers(vesselFFRData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowSliceidx, lineLengthRef.current > 0]);

  // Code coming from CPRViwer
  // When only a single CPRViewer is loaded then onSliceChange is called as a callback from WebGLViewer.
  // This then updates the cprSliceIndexRef.current to the new slice and ultimately calls setCprSliceidx.
  // A problem arises when we have a CprViewer on the Patient Overview screen and the CT Volume screen:
  // All other values (eg sliceidx) progagate ok but the cprSliceidx doesn't. This effect should only
  // trigger on other CprViewers (ie, not the one you are manipulating) and ensure it's showing the
  // correct slice.
  useEffect(() => {
    if (cprSliceidx !== cprSliceIndexRef.current) {
      onSliceChange(cprSliceidx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cprSliceidx]);

  useEffect(() => {
    if (selectedVesselData && appRef.current) {
      getCenterLinesData(cprSliceidx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVesselData]);

  const getCenterLinesData = (sliceIndex: number) => {
    if (
      selectedVesselData &&
      selectedVesselData.segments &&
      imageSizeRef.current.height &&
      selectedVesselData.n_slices > 0
    ) {
      // Get nSlices of the centerline.
      const nSlices = selectedVesselData.n_slices;
      // Length of the centerline.
      const length = imageSizeRef.current.height;
      // Divide the length of the centerLine into number of cuts.
      let splitLength = length / (nSlices - 1);
      // Create points.
      const centerline: XYCoords[] = [];
      for (let i = 0; i < nSlices; i++) {
        centerline.push({
          x: imageSizeRef.current.width / 2,
          y: i * splitLength,
        });
      }
      centerLinesDataRef.current[sliceIndex] = centerline;
      if (sliceIndex === cprSliceIndexRef.current) {
        lineLengthRef.current = centerline.length;
        // Recreate the high, mid, low slice indicator handles.
        createIndicatorSprites(sliceIndex);

        if (ffrDataRef.current && showFFRLabels) {
          // Recreate the ffr markers
          const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
          vesselFFRData.value && createFfrMarkers(Object.entries(ffrDataRef.current)[0][1]);
        }
      }
    }
  };

  const createFFRMarkerSprite = (type: string, belowTolerance: boolean): Marker => {
    const style = new PIXI.TextStyle({
      fontFamily: 'Arial',
      fontSize: 12,
      fill: belowTolerance ? INDICATOR_SETTINGS.FFR_LOW_COLOR : INDICATOR_SETTINGS.INDICATOR_COLOR,
      align: 'center',
    });
    const text = new PIXI.Text(type, style);
    text.roundPixels = true;

    // Draw the rounded grey backing rect.
    const width = 60;
    const height = text.height + 7;
    const rectangle = new PIXI.Graphics();
    rectangle.beginFill(Number(INDICATOR_SETTINGS.MARKER_COLOR.replace('#', '0x')));
    rectangle.drawRoundedRect(0, 0, width, height, 4);
    rectangle.endFill();

    const wrapper = new PIXI.Container() as Marker;
    wrapper.addChild(rectangle, text);
    // Center the text in the middle of the rectangle.
    text.x = width / 2;
    text.y = height / 2;
    text.anchor.set(0.5, 0.5);
    return wrapper;
  };

  const ffrBelowTolerance = (index: number) => {
    if (!ffrDataRef.current || !Object.entries(ffrDataRef.current)[0][1].value) return false;
    const value = Object.entries(ffrDataRef.current)[0][1].value[index] || 0;
    return value < FFR_TOLERANCE;
  };

  const createFFRSliceMarkerText = (sliceIndex: number, text: string, belowTolerance = false) => {
    const centerline = centerLinesDataRef.current[cprSliceIndexRef.current];
    const lineIndex = getLineIndex(sliceIndex);
    if (centerline && lineIndex !== null && centerline[lineIndex] && containerRef.current) {
      const marker = createFFRMarkerSprite(text, belowTolerance);
      marker.zIndex = 30;
      marker.sliceIndex = sliceIndex;

      if (ffrValueContainerRef.current) {
        ffrValueContainerRef.current.addChild(marker);
      }

      transformFFRMarker(marker);
      return marker;
    }
    return null;
  };

  const transformFFRMarker = useCallback((marker: Marker | null) => {
    if (marker) {
      const centerline = centerLinesDataRef.current[cprSliceIndexRef.current];
      const lineIndex = getLineIndex(marker.sliceIndex);

      if (centerline && lineIndex !== null && centerline[lineIndex] && containerRef.current) {
        // Find an offset position for marker from perpendicular indicator line.
        const adjacentPoints: AdjacentPoints = calculateAdjacentPoints(centerline, lineIndex);

        // The indicator width is fixed in terms of image pixels so we need to divide it by the defaultScale, then we add the extra offset we want in screen pixels.
        const indicatorWidth =
          (INDICATOR_SETTINGS.INDICATOR_WIDTH + 2) / (defaultScaleRef.current ?? 1) +
          INDICATOR_SETTINGS.FFR_MARKER_OFFSET / containerRef.current.scale.x;
        const indicatorLine = getPerpendicularLine(
          [adjacentPoints.prevPoint, adjacentPoints.slicePoint, adjacentPoints.nextPoint],
          indicatorWidth
        );

        // Scale and offset the marker position based on the current container position and scale.
        marker.x = containerRef.current.x + indicatorLine[2].x * containerRef.current.scale.x;
        marker.y = containerRef.current.y + indicatorLine[2].y * containerRef.current.scale.y - marker.height / 2;
      }
    }
  }, []);

  /**
   * Clear and create the FFR text markers for the specified data.
   */
  const createFfrMarkers = (data: FFRVesselData) => {
    if (!clientConfig?.ffr_enabled || !selectedVesselName || !selectedVesselData || !selectedVesselData.segments) {
      return;
    }

    // Proximal FFR marker
    if (highSliceIndexRef.current) {
      clearSpriteRef(proximalFFRRef);
      proximalFFRRef.current = createFFRSliceMarkerText(
        highSliceIndexRef.current,
        `FFR ${
          data && !isNaN(data.value[highSliceIndexRef.current]) ? data.value[highSliceIndexRef.current].toFixed(2) : '-'
        }`,
        ffrBelowTolerance(highSliceIndexRef.current)
      );
    }

    // Distal FFR marker
    if (lowSliceIndexRef.current) {
      clearSpriteRef(distalFFRRef);
      distalFFRRef.current = createFFRSliceMarkerText(
        lowSliceIndexRef.current,
        `FFR ${
          data && !isNaN(data.value[lowSliceIndexRef.current]) ? data.value[lowSliceIndexRef.current].toFixed(2) : '-'
        }`,
        ffrBelowTolerance(lowSliceIndexRef.current)
      );
    }
  };

  const transformMarkers = useCallback(() => {
    // Transform the FFR markers.
    transformFFRMarker(proximalFFRRef.current);
    transformFFRMarker(distalFFRRef.current);

    transformMeasurementMarker(proximalMeasurementRef.current);
    transformMeasurementMarker(distalMeasurementRef.current);
  }, [transformFFRMarker, transformMeasurementMarker]);

  const onDrag = (pos: PointObject) => {
    transformMarkers();
  };

  const onResize = useCallback(() => {
    transformMarkers();
  }, [transformMarkers]);

  const onZoom = () => {
    transformMarkers();
    createIndicatorSprites(cprSliceIndexRef.current);
    // Clear and re-create the centerline sprite.
  };

  const onHueChange = useCallback((hue) => {
    if (hueRef.current && hueRef.current.setValue) hueRef.current.setValue(hue || '-');
  }, []);

  /**
   * Respond to the user changing the (rotational) slice.
   */
  const onSliceChange = (index: number) => {
    if (draggingIndicator) {
      return false;
    }
    // Set the new slice number (rotational slice).
    cprSliceIndexRef.current = index;
    // Clear and recreate the handles for the new slice and current index down the centerline's length.
    createIndicatorSprites(index);
    getCenterLinesData(index);
    // Create the prox, dist, and mid markers.

    if (ffrDataRef.current) {
      const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
      vesselFFRData.value && createFfrMarkers(vesselFFRData);
    }

    dispatch(cprActions.setCprSliceidx(index));

    return index;
  };

  const onMouseMove = (event: React.MouseEvent) => {
    mousePosRef.current = getMousePos(event);
    if (draggingIndicator) {
      const shapeHeight = imageSizeRef.current.height;
      const y = mousePosRef.current.y;
      if (nSlicesRef.current) {
        // Limit the new slice index to the actual slice range.
        const index = Math.max(
          Math.min(Math.round((nSlicesRef.current - 1) * (y / shapeHeight)), nSlicesRef.current - 1),
          0
        );
        onSliceIndicatorChange(index);
      }
    }
  };

  const onMouseLeave = useCallback(() => {
    if (hueRef.current && hueRef.current.setValue) hueRef.current.setValue(null);
  }, []);

  const getMousePos = (event: React.MouseEvent): PointObject => {
    const pos = { x: 0, y: 0 };
    if (holderRef.current && containerRef.current) {
      // Get the position and size of the component on the page.
      const holderOffset = holderRef.current.getBoundingClientRect();
      pos.x = (event.pageX - holderOffset.x - containerRef.current.x) / containerRef.current.scale.x;
      pos.y = (event.pageY - holderOffset.y - containerRef.current.y) / containerRef.current.scale.y;
    }
    return pos;
  };

  const onReady = ({ sliceNo, container, imageSize, defaultScale, holder, app }: OnReadyInf) => {
    cleanupAnnoRef();

    holderRef.current = holder;
    annoRef.current = new PIXI.Container();
    ffrValueContainerRef.current = new PIXI.Container();
    ffrValueContainerRef.current.visible = showFFRLabels;
    measurementValueContainerRef.current = new PIXI.Container();
    measurementValueContainerRef.current.visible = displayMeasurements;
    containerRef.current = container;
    imageSizeRef.current = imageSize;
    defaultScaleRef.current = defaultScale;
    sliceNoRef.current = sliceNo;
    container.addChild(annoRef.current);
    app.stage.addChild(ffrValueContainerRef.current);
    app.stage.addChild(measurementValueContainerRef.current);
    appRef.current = app;
    getCenterLinesData(cprSliceidx);
    createAnnotations();
  };
  const reset = () => {
    timeFuncFactory(() => {
      cleanup();
      getCenterLinesData(cprSliceidx);
    }, 'reset LongAxisMPRViewer')();
  };

  const createAnnotations = () => {
    if (displayMeasurements) {
      clearSpriteRef(proximalMeasurementRef);
      clearSpriteRef(distalMeasurementRef);
      proximalMeasurementRef.current = createMeasurementSliceMarkerText(highSliceidx, sliceidx);
      distalMeasurementRef.current = createMeasurementSliceMarkerText(lowSliceidx, sliceidx);
    }
  };

  /**
   * Clean up all allocated PIXI buffers etc and ensure we have exited add and editing modes.
   */
  const cleanup = () => {
    // Abort any edits in progress.

    cleanupAnnoRef();

    // Cleanup refs to avoid cycles and help the garbage collector do its thing.
    holderRef.current = null;
    containerRef.current = null;
    appRef.current = null;
    imageSizeRef.current = {
      width: 0,
      height: 0,
    };
    midSliceIndicatorSpriteRef.current = null;
    highSliceIndicatorSpriteRef.current = null;
    lowSliceIndicatorSpriteRef.current = null;
    annoRef.current = null;
    centerLinesDataRef.current = [];
    mousePosRef.current = { x: 0, y: 0 };
  };

  /**
   * Destroy all PIX graphics, indicators.
   */
  const cleanupAnnoRef = () => {
    timeFuncFactory(() => {
      destroySliceIndicators();
      if (annoRef.current) {
        try {
          annoRef.current.destroy({
            children: true,
            texture: true,
            baseTexture: true,
          });
        } catch (err) {
          console.warn(err);
        }
        annoRef.current = null;
      }
    }, 'cleanupAnnoRef LongAxisMPRViewer')();
  };

  /**
   * Destroy the slice indicators (ie dragable handles).
   */
  const destroySliceIndicators = () => {
    clearSpriteRef(midSliceIndicatorSpriteRef);
    clearSpriteRef(highSliceIndicatorSpriteRef);
    clearSpriteRef(lowSliceIndicatorSpriteRef);
  };

  const onSliceIndicatorChange = useCallback(
    (index: number) => {
      switch (activeLine) {
        case 'mid':
          dispatch(vesselDataActions.updateSliceIndices({ mid: index }));
          break;
        case 'low':
          // Limit the low slice indicator to the valid range: sliceidx + CPR_SLICE_INDICATOR_BUFFER to nSlices - 1.
          // Note the 'low' slice indicator is the one at the bottom, so will actually have higher index values than sliceidx.
          const low = Math.min(Math.max(index, sliceidx + CPR_SLICE_INDICATOR_BUFFER), nSlicesRef.current - 1);
          dispatch(vesselDataActions.updateSliceIndices({ low }));
          break;
        case 'high':
          // Limit the high slice indicator to the valid range: 0 to sliceidx - CPR_SLICE_INDICATOR_BUFFER.
          // Note the 'high' slice indicator is the one at the top, so will actually have lower index values than sliceidx.
          const high = Math.max(Math.min(index, sliceidx - CPR_SLICE_INDICATOR_BUFFER), 0);
          dispatch(vesselDataActions.updateSliceIndices({ high }));
          break;
        default:
          dispatch(vesselDataActions.updateSliceIndices({ mid: index }));
          break;
      }
    },
    [sliceidx, activeLine, dispatch]
  );

  const onMouseDownIndicator = (event: PIXIPointerEvent) => {
    document.body.style.cursor = 'ns-resize';
    setDraggingIndicator(true);
    // Capture the mouse pointer.
    pixiCaptureMouse(event);
  };

  const onMouseUp = () => {
    document.body.style.cursor = '';
    setDraggingIndicator(false);
    onSetActiveLine && onSetActiveLine('');
  };

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // Up and down adjust the mid indicator position (ie sliceidx).
      if (event.nativeEvent.code === 'ArrowUp' || event.nativeEvent.code === 'ArrowDown') {
        event.nativeEvent.preventDefault();
        const deltaSliceIndex = event.nativeEvent.code === 'ArrowUp' ? -1 : 1;

        const currSlice = midSliceIndexRef.current + deltaSliceIndex;
        if (currSlice >= 0 && currSlice < nSlicesRef.current) {
          // Force the adjustment to apply to the middle indicator.
          onSetActiveLine && onSetActiveLine('mid');
          onSliceIndicatorChange(currSlice);
          // Clear the currently set indicator.
          onSetActiveLine && onSetActiveLine('');
        }
      }
      // Left and right arrow keys adjust the cprSliceidx (index of rotation) by -/+ 1.
      if ((event.nativeEvent.code === 'ArrowLeft' || event.nativeEvent.code === 'ArrowRight') && cprSliceCount > 0) {
        event.nativeEvent.preventDefault();
        const deltaCprSliceIndex = event.nativeEvent.code === 'ArrowLeft' ? -1 : 1;
        // Allow the slice index to loop around.
        dispatch(cprActions.setCprSliceidx((cprSliceidx + deltaCprSliceIndex + cprSliceCount) % cprSliceCount));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cprSliceCount, onSliceIndicatorChange, onSetActiveLine]
  );

  // Convert from a slice index into a line index by scaling.
  const getLineIndex = (index: number) => {
    // scale index from nSlices to lineLength
    if (nSlicesRef.current && lineLengthRef.current) {
      return Math.round((index / nSlicesRef.current) * lineLengthRef.current);
    }
    return null;
  };

  /**
   * Clear the inticator sprites for the high, mid, and low indicator slice positions and then recreate if able.
   */
  const createIndicatorSprites = (index: number) => {
    // Delete the current indicators.
    clearSpriteRef(highSliceIndicatorSpriteRef);
    clearSpriteRef(midSliceIndicatorSpriteRef);
    clearSpriteRef(lowSliceIndicatorSpriteRef);

    // Recreate the slice indicatorts as required / able.
    if (showHighLowIndicators) {
      createIndicatorSprite(index, highSliceIndexRef.current, highSliceIndicatorSpriteRef, 'high');

      createIndicatorSprite(index, lowSliceIndexRef.current, lowSliceIndicatorSpriteRef, 'low');
    }

    createIndicatorSprite(index, midSliceIndexRef.current, midSliceIndicatorSpriteRef, 'mid');
  };

  /**
   * Clear and recreate the indicator sprite for the given line name.
   * @param index The (rotational) slice index.
   * @param sliceIndex The (along the centerline) slice index.
   * @param sliceIndicatorLine The sprite ref to modify.
   * @param lineName 'mid', 'low, 'high'
   */
  const createIndicatorSprite = (
    index: number,
    sliceIndex: number,
    sliceIndicatorLine: MutableRefObject<PIXI.Graphics | null>,
    lineName: string
  ) => {
    // Delete the current indicator.
    clearSpriteRef(sliceIndicatorLine);
    const lineIndex = getLineIndex(sliceIndex);
    if (lineIndex !== null) {
      // We have to split the scale into two parts to separate the default scaling and the current zoom.
      const defaultScale = defaultScaleRef.current ?? 1;
      const zoom = calculateScaledLineThickness() / defaultScale;

      // Create the slice indicator.
      sliceIndicatorLine.current = createSliceIndicatorSprite({
        centerline: centerLinesDataRef.current[index],
        lineIndex,
        fillColour:
          lineName === 'mid'
            ? INDICATOR_SETTINGS.INDICATOR_COLOR_MAIN
            : ffrBelowTolerance(sliceIndex) && showFFRLabelsRef.current
            ? INDICATOR_SETTINGS.FFR_LOW_COLOR
            : INDICATOR_SETTINGS.INDICATOR_COLOR,
        visible: true,
        zIndex: lineName === 'mid' ? 10 : 1, // The mid indicator needs to render on top.
        defaultScale,
        zoom,
        onPointerDown: (event: PIXIPointerEvent) => {
          onSetActiveLine && onSetActiveLine(lineName);
          onMouseDownIndicator(event);
        },
      });

      // Add the slice indicator to the anno container.
      if (annoRef.current) {
        annoRef.current.addChild(sliceIndicatorLine.current);
      }
    }
  };

  const calculateScaledLineThickness = () => {
    // We use `y` here, but it could've just as easily have been `x`. We just need a scalar.
    return containerRef.current?.scale.y ?? 1;
  };

  /**
   * Clear a sprite reference.
   * @param spriteRef The sprite ref to clear.
   */
  const clearSpriteRef = (spriteRef: MutableRefObject<PIXI.Graphics | Marker | null>) => {
    // Delete the current indicator.
    if (spriteRef.current) {
      spriteRef.current.destroy({
        children: true,
        baseTexture: true,
        texture: true,
      });
      spriteRef.current = null;
    }
  };

  return (
    <div
      className="longAxisMPRViewer"
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onKeyDown={onKeyDown}
      onMouseUp={onMouseUp}
    >
      {ready && (
        <div className="longAxisMPRViewer-view">
          <WebGLViewer
            viewType={KEY_MPR_LONG_AXIS}
            resizeMode={RESIZE_MODE.FIT_VERTICAL}
            onReady={onReady}
            onZoom={onZoom}
            onDrag={onDrag}
            onResize={onResize}
            windowLevels={windowLevels}
            onWindowLevelsChange={onWindowLevelsChange}
            slice={cprSliceidx}
            onSliceChange={onSliceChange}
            shapeData={viewerData?.shape}
            imageBufferData={viewerData?.imageBufferData}
            onHueChange={onHueChange}
            disableKeyboardControls
            disableControls={draggingIndicator ? true : false}
            onCleanup={cleanup}
          />
        </div>
      )}
      <ToolBar
        showWindowLevels={!!windowLevels}
        windowLevels={windowLevels}
        windowLabel={windowLabel}
        vessel={selectedVesselName}
        slice={String(cprSliceidx)}
        HURef={hueRef}
        showVisibilityIcon={false}
        screenshotDisabled={screenshotDisabled}
        screenshotRef={screenshotRef}
        viewName={viewName}
      />
    </div>
  );
};
