// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Study } from '../../context/types';

export interface WorkflowState {
  studyToAction: Study | null;
  showCancelReviewConfirm: boolean;
}

export const getInitialWorkflowState = (): WorkflowState => {
  return {
    studyToAction: null,
    showCancelReviewConfirm: false,
  };
};

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState: getInitialWorkflowState(),
  reducers: {
    setStudyToAction: (draftState, action: PayloadAction<Study | null>) => {
      draftState.studyToAction = action.payload;
    },
    setShowCancelReviewConfirm: (draftState, action: PayloadAction<boolean>) => {
      draftState.showCancelReviewConfirm = action.payload;
    },
  },
});

export const workflowActions = workflowSlice.actions;
