import React, { ReactElement, useMemo } from 'react';
import { CalciumStat } from '../../context/types';

interface Props {
  calciumStat: CalciumStat;
}

export function CalciumScoreStats({ calciumStat }: Props): ReactElement<Props> {
  const mesaStr = useMemo(() => (calciumStat.mesaPercentile.length > 0 ? `(${calciumStat.mesaPercentile}%)` : ''), [
    calciumStat.mesaPercentile,
  ]);

  return (
    <>
      <div className="value-list__item">
        <dt className="value-list__label">{calciumStat.label}</dt>
        <dd className="value-list__value">
          {`${calciumStat.value} `}
          {mesaStr}
        </dd>
      </div>
    </>
  );
}
