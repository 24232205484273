import cn from 'classnames';
import React, { ReactElement } from 'react';
import coronalIcon from '../../assets/contrast-series-icons/coronal-square.png';
import mprIcon from '../../assets/contrast-series-icons/mpr-square.png';
import sagittalIcon from '../../assets/contrast-series-icons/sagittal-square.png';
import { ReactComponent as SuccessIcon } from '../../assets/icons/check.svg';
import { DraggableCustomType, ThumbnailType } from '../../reducers/dragAndDrop/types';

const ICON_MAP = {
  coronal: coronalIcon,
  sagittal: sagittalIcon,
  mpr: mprIcon,
};

export enum BorderType {
  // Render no border.
  NONE,
  // Render a border for a view that is loaded and visible.
  ACTIVE,
  // Render a border for a view that is loaded but not currently visible.
  INACTIVE,
}

interface Props {
  borderType: BorderType;
  imageUrlOrType: ThumbnailType;
}

export default function ContrastSeriesIcon({ imageUrlOrType, borderType }: Props): ReactElement<Props> {
  if (
    imageUrlOrType !== DraggableCustomType.CORONAL &&
    imageUrlOrType !== DraggableCustomType.SAGITTAL &&
    imageUrlOrType !== DraggableCustomType.MPR
  ) {
    return (
      <div
        className={cn('series-select__thumbnail', {
          'series-select__thumbnail_activeBorder': borderType === BorderType.ACTIVE,
          'series-select__thumbnail_inactiveBorder': borderType === BorderType.INACTIVE,
        })}
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url('${imageUrlOrType.url}')`,
        }}
      >
        <SuccessIcon visibility="hidden" className={'series-select__select-icon icon'} />
      </div>
    );
  }

  return (
    <img
      src={ICON_MAP[imageUrlOrType]}
      alt={imageUrlOrType}
      className={cn('series-select__thumbnail', {
        'series-select__thumbnail_activeBorder': borderType === BorderType.ACTIVE,
        'series-select__thumbnail_inactiveBorder': borderType === BorderType.INACTIVE,
      })}
      style={{
        backgroundSize: 'cover',
        pointerEvents: 'none',
      }}
    />
  );
}
