import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import { VesselGroupings } from '../config';
import { VesselToReportNameMapping } from '../reducers/inlineReporting/inlineReportingSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { globalFlagsActions } from '../reducers/global-flags/globalFlagsSlice';
import { reportActions } from '../reducers/report/reportSlice';
import { reportStateActions } from '../reducers/ReportState/ReportStateSlice';
import { storeActions } from '../reducers/store/storeSlice';
import { useShouldShowAmendmentModal } from '../selectors/reporting';
import { currentStudySelector } from '../selectors/study';
import * as api from '../utils/api';
import { UseFetchStudyById } from './UsefetchStudy';
import { studyActions } from '../reducers/study/studySlice';

export function useSaveCallBack(isPartialUpdate: boolean = false) {
  const dispatch = useAppDispatch();
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const currentReport = useAppSelector((state) => state.report.current);
  const draftReport = useAppSelector((state) => state.report.draft);
  const amendmentModalPassthrough = useAppSelector((state) => state.reportState.amendmentModalPassthrough);
  const selectedStudy = useAppSelector(currentStudySelector);

  const showAmendmentModal = useShouldShowAmendmentModal();

  const setLoadingSpinner = useCallback(
    (newValue: boolean) => {
      if (isPartialUpdate === true) {
        dispatch(reportActions.setPostingPartialReport(newValue));
      }

      dispatch(reportActions.setPostingReport(newValue));
    },
    [dispatch, isPartialUpdate]
  );

  const actualEndpoint = useCallback(
    async (studyId: string, runID: string, draftReport, reason?: string) => {
      let success = false;
      try {
        // Save the report
        const saveResponse = await api.saveReport(studyId, runID, {
          ...draftReport,
          update_message: reason,
        });
        if (saveResponse.detail === 'success' && saveResponse.changes) {
          success = true;
          dispatch(reportActions.setCurrentReport(saveResponse.content));
        }

        // Reload the other report data
        const promises = Promise.all([
          api.fetchReport(studyId, runID),
          api.fetchReportHistory(studyId, runID),
          api.fetchReviewList(studyId, runID),
          selectedStudy?.is_report_approved ? UseFetchStudyById(studyId) : Promise.resolve(null),
        ]);

        const [report, history, reviewList, study] = await promises;
        dispatch(reportActions.setCurrentReport(report));
        dispatch(reportActions.setReportHistory(history));
        dispatch(storeActions.setReviewList(reviewList));
        study && dispatch(studyActions.setCurrentStudy(study));
      } catch (err) {
        // Revert to original version
        dispatch(reportActions.setCurrentReport(currentReport));
        console.error(err);
        showToast.error('Error Saving Report');
      } finally {
        setLoadingSpinner(false);
        dispatch(reportActions.setFetching(false));
        dispatch(globalFlagsActions.setEditingImpressions(false));
        dispatch(globalFlagsActions.setEditingCoronaryFindings(false));
        dispatch(reportActions.deleteDraftReport());
        if (success) {
          showToast.success('Report saved');
        }
      }
    },
    [currentReport, setLoadingSpinner, selectedStudy?.is_report_approved, dispatch]
  );

  const hitEndPoint = useCallback(
    (reason?: string) => {
      const studyId = selectedStudy?.study_id;
      if (studyId && currentReport && draftReport && runID) {
        dispatch(reportStateActions.setAmendmentModalPassthrough({}));
        setLoadingSpinner(true);
        dispatch(reportActions.setCurrentReport({ ...currentReport, ...draftReport }));

        actualEndpoint(studyId, runID, draftReport, reason);
      }
    },
    [selectedStudy?.study_id, currentReport, draftReport, runID, setLoadingSpinner, dispatch, actualEndpoint]
  );

  return useCallback(async () => {
    // If the report has been approved, show the amendment modal first
    if (showAmendmentModal && !amendmentModalPassthrough?.passthrough) {
      dispatch(reportStateActions.setAmendmentModalPassthrough({ passthrough: hitEndPoint }));
    } else {
      hitEndPoint();
    }
  }, [hitEndPoint, showAmendmentModal, amendmentModalPassthrough, dispatch]);
}

export function useSaveImpressionsCallback(studyId: string | undefined, impressions: string[] | undefined) {
  const dispatch = useAppDispatch();
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const currentReport = useAppSelector((state) => state.report.current);
  const amendmentModalPassthrough = useAppSelector((state) => state.reportState.amendmentModalPassthrough);
  const selectedStudy = useAppSelector(currentStudySelector);

  const showAmendmentModal = useShouldShowAmendmentModal();

  const hitEndPoint = useCallback(
    (reason?: string) => {
      if (currentReport && runID !== undefined && impressions !== undefined && studyId !== undefined) {
        let success = false;
        dispatch(reportStateActions.setAmendmentModalPassthrough({}));

        dispatch(reportActions.setPostingPartialReport(true));
        dispatch(reportActions.setCurrentReport({ ...currentReport, impression: impressions }));
        api
          .saveFieldInReport(studyId, runID, {
            impression: impressions,
            update_message: reason,
          })
          .then((response) => {
            if (response.detail === 'success' && response.changes) {
              success = true;
              dispatch(reportActions.setCurrentReport(response.content));
              return;
            }
          })
          .then(() => api.fetchReportHistory(studyId, runID))
          .then((response) => {
            dispatch(reportActions.setPostingPartialReport(false));
            dispatch(reportActions.setReportHistory(response));
            if (success) {
              showToast.success('Report saved');
            }
          })
          .then(() => {
            // TODO: Find a better way to reload study data
            // Re-fetch study data if updating an approved report
            if (selectedStudy?.is_report_approved) {
              const study = UseFetchStudyById(studyId);
              dispatch(studyActions.setCurrentStudy(study));
            }

            return;
          })
          .catch((err) => {
            dispatch(reportActions.setPostingPartialReport(false));
            // Revert to original version
            dispatch(reportActions.setCurrentReport(currentReport));
            console.error(err);
            showToast.error('Error Saving Report');
          });
      }
    },
    [impressions, studyId, runID, currentReport, selectedStudy?.is_report_approved, dispatch]
  );

  return useCallback(async () => {
    if (showAmendmentModal && !amendmentModalPassthrough?.passthrough) {
      dispatch(reportStateActions.setAmendmentModalPassthrough({ passthrough: hitEndPoint }));
    } else {
      hitEndPoint();
    }
  }, [hitEndPoint, showAmendmentModal, amendmentModalPassthrough, dispatch]);
}

export function useSaveCoronaryFindingCallback(studyId: string | undefined, selectedVessel: VesselGroupings) {
  const dispatch = useAppDispatch();
  const currentReport = useAppSelector((state) => state.report.current);
  const amendmentModalPassthrough = useAppSelector((state) => state.reportState.amendmentModalPassthrough);

  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const selectedStudy = useAppSelector(currentStudySelector);

  const showAmendmentModal = useShouldShowAmendmentModal();

  const state = useAppSelector((state) => state.inlineReporting);

  const hitEndPoint = useCallback(
    (reason?: string) => {
      const mapped = VesselToReportNameMapping[selectedVessel];

      const findings = state[mapped];

      if (currentReport && findings && runID && studyId) {
        let success = false;
        dispatch(reportStateActions.setAmendmentModalPassthrough({}));
        dispatch(reportActions.setPostingPartialReport(true));
        dispatch(reportActions.setCurrentReport({ ...currentReport, [mapped]: findings }));
        api
          .saveFieldInReport(studyId, runID, {
            [mapped]: findings,
            update_message: reason,
          })
          .then((response) => {
            if (response.detail === 'success' && response.changes) {
              success = true;
              dispatch(reportActions.setCurrentReport(response.content));
              return;
            }
          })
          .then(() => api.fetchReportHistory(studyId, runID))
          .then((response) => {
            dispatch(reportActions.setPostingPartialReport(false));
            dispatch(reportActions.setReportHistory(response));
            if (success) {
              showToast.success('Report saved');
            }
          })
          .then(() => {
            // TODO: Find a better way to reload study data
            // Re-fetch study data if updating an approved report
            if (selectedStudy?.is_report_approved) {
              const study = UseFetchStudyById(studyId);
              dispatch(studyActions.setCurrentStudy(study));
            }

            return;
          })
          .catch((err) => {
            dispatch(reportActions.setPostingPartialReport(false));
            // Revert to original version
            dispatch(reportActions.setCurrentReport(currentReport));
            console.error(err);
            showToast.error('Error Saving Report');
          });
      }
    },
    [selectedVessel, state, studyId, runID, currentReport, selectedStudy?.is_report_approved, dispatch]
  );

  return useCallback(async () => {
    if (showAmendmentModal && !amendmentModalPassthrough?.passthrough) {
      dispatch(reportStateActions.setAmendmentModalPassthrough({ passthrough: hitEndPoint }));
    } else {
      hitEndPoint();
    }
  }, [hitEndPoint, showAmendmentModal, amendmentModalPassthrough, dispatch]);
}

export function useRevertVersionCallBack(studyId: string | undefined) {
  const dispatch = useAppDispatch();
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  return useCallback(
    (version: 'initial' | 'previous') => {
      if (runID && studyId) {
        dispatch(reportActions.setPostingReport(true));
        api
          .revertReportVersion(studyId, runID, version)
          .then((response) => dispatch(reportActions.setCurrentReport(response)))
          .then(() => api.fetchReportHistory(studyId, runID))
          .then((response) => {
            dispatch(reportActions.setReportHistory(response));
            dispatch(reportActions.setPostingReport(false));
            showToast.success('Report reverted');
          })
          .then(() => {
            const study = UseFetchStudyById(studyId);
            dispatch(studyActions.setCurrentStudy(study));
          })
          .catch((error) => {
            dispatch(reportActions.setPostingReport(false));
            console.error('Error reverting version', error);
            showToast.error('Error reverting version');
          });
      }
    },
    [studyId, runID, dispatch]
  );
}
