import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ctVolumeActions, CtVolumeResponse } from './ctVolumeSlice';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, useNeedsLoading } from '../initialLoad/utils';
import { fetchJSON } from '../../utils/webApp';

const API_PLATFORM_BASE_URL = process.env.REACT_APP_PLATFORM_BASE_URL;
const loaderName = InitialLoader.CONTRAST;

/**
 * Load the contrast study details from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function loadCtVolume(unifiedId: UnifiedId, dispatch: AppDispatch, initialLoad: boolean) {
  const promise = fetchJSON<CtVolumeResponse>(
    `${API_PLATFORM_BASE_URL}/webapp/study/${unifiedId.patientId}/ct_volume`,
    false
  );
  return loadWithPromise<CtVolumeResponse>(promise, dispatch, initialLoad, loaderName, (data) => {
    dispatch(ctVolumeActions.setCtVolume(data));
  });
}

/**
 * A component to load the initial CT volume data for a study and add it to the store.
 */
export const CtVolumeLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useNeedsLoading(loaderName);

  useEffect(() => {
    if (unifiedId && needsLoading) {
      loadCtVolume(unifiedId, dispatch, true);
    }
  }, [unifiedId, needsLoading, dispatch]);

  return null;
};
