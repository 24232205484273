import { useEffect, useState } from 'react';
import './initCornerstone.js';
import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume';
import vtkVolumeMapper from 'vtk.js/Sources/Rendering/Core/VolumeMapper';
import {
  ContrastVolumeActions,
  ContrastVolumeAction,
  BlendMode,
  ContrastViewType,
  ContrastVolumeStatus,
} from '../../../context/contrast-types';
import { WindowLevels } from '../../../reducers/window/types';
import { windowLevelsToRange } from './Utils';
import { useContrastContext } from '../../../context/contrast-context';
import showToast from '../../../components/Toast/showToast';
import * as Sentry from '@sentry/react';
import { VtkImageData } from '../ReactVTKJS/ReactVTKJSTypes.js';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { VolumeData } from '../../../reducers/contrast/contrastSlice';
import { loadVolumeData } from '../../../reducers/contrast/VolumeDataLoader';
import { LoadState } from '../../../reducers/initialLoad/initialLoadSlice';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';

/**
 * Create a vtkImageData from VolumeData.
 */
function createVtkImageData(volumeData: VolumeData): vtkImageData {
  // Create a new VtkDataArray.
  const dataArray = vtkDataArray.newInstance({
    name: 'Pixels',
    numberOfComponents: 1,
    values: volumeData.data,
  });

  // Create the vtkImageData object.
  const imageData: vtkImageData = vtkImageData.newInstance();
  imageData.setDimensions(volumeData.dimensions as number[]);
  imageData.setSpacing(volumeData.spacing as number[]);
  imageData.getPointData().setScalars(dataArray);

  // NOTE: It's possible to set an origin and orientation for the vtk volume.
  // We may want to re-introduce this if we plan to show vessels on any volume (for example).

  return imageData;
}

/**
 * Load the specifed series name from the specified study.
 */
export async function loadContrastVolumeFromVolumeData(
  seriesName: string,
  dispatchContrastVolumeAction: (action: ContrastVolumeAction) => void,
  windowLevels: WindowLevels,
  volumeData: VolumeData
) {
  // Tell the store that this volume is now loading.
  dispatchContrastVolumeAction({
    seriesName,
    type: ContrastVolumeActions.LOAD,
  });

  // Switch to an error state and show an alert for the user.
  const onLoadError = (userMessage: string, errorMessage?: string | undefined) => {
    // Console log the error.
    console.error(errorMessage || userMessage);
    // Set the error state on the contrast CT volume in the store.
    dispatchContrastVolumeAction({
      seriesName,
      type: ContrastVolumeActions.SET_STATUS,
      status: ContrastVolumeStatus.LOAD_FAILED,
    });
    // Show an alert.
    showToast.error(userMessage);
  };

  // Set the volume data (the second param identifies the dataset id; it must be unique for this study and series).
  const vtkImageData: VtkImageData | undefined = createVtkImageData(volumeData);
  if (vtkImageData == null) {
    onLoadError('Failed to create the volume data');
    return;
  }

  try {
    // Create the volume.
    const volume = vtkVolume.newInstance();

    // Determine the best sample distance for the volume render.
    // That is the distance between each sample taken along the ray.
    // TODO This following commented out calculation is the sort of default the ReactVTKJS code was using but it seems like it will potentially miss slices.
    //      eg a study with spacing of [0.43, 0.43, 0.3] would get a sampleDistance of 0.47
    // const minSampleDistance = 0.7 * vec3.length(spacing);
    const minSampleDistance = 0.25;
    // Make the sample distance equal to the minimum spacing between voxels in any of the 3 directions (but with a sensible minimum sampleDistance).
    const sampleDistance = Math.max(
      Math.min(Math.min(volumeData.spacing[0], volumeData.spacing[1]), volumeData.spacing[2]),
      minSampleDistance
    );

    // TODO: Use nearest neighbour rendering for speed?
    const property = volume.getProperty() as any;
    // property.setInterpolationTypeToNearest();
    property.setInterpolationTypeToLinear();

    // Set up the volume mapper (step size for each ray etc).
    const mapper = vtkVolumeMapper.newInstance();
    // Tell the mapper the data it is mapping.
    mapper.setInputData(vtkImageData);
    // The maximum number of samples that can be taken along a ray.
    mapper.setMaximumSamplesPerRay(2048);
    // Set the distance between each sample taken along the ray.
    mapper.setSampleDistance(sampleDistance);
    // Set the distance between each ray that is cast through the view (in pixels).
    // ie 1.0 = 1 x 1 (or 1) ray per pixel
    //    0.5 = 2 x 2 (or 4) rays per pixel
    mapper.setImageSampleDistance(1.0);
    // If set to true this tries to reduce render quality (ie temporarily adjusts the values of mapper.setSampleDistance()
    // and mapper.setImageDistance()) dynamically to ensure a smooth frame rate.
    // The target frame rate is determined by interactor.setDesiredUpdateRate().
    mapper.setAutoAdjustSampleDistances(false); // TODO: Enable this?
    // Set the slab blend mode to MIP, MinIP, or AvgIP.
    mapper.setBlendMode(BlendMode.MAXIMUM_INTENSITY_BLEND);
    // Tell the volume to use this mapper.
    volume.setMapper(mapper);
    // Set the window levels for the rgbTransferFunction.
    const defaultRange = windowLevelsToRange(windowLevels);
    property.getRGBTransferFunction(0).setRange(defaultRange[0], defaultRange[1]);

    // Set the spacing and working volume in the store.
    dispatchContrastVolumeAction({
      seriesName,
      type: ContrastVolumeActions.SET_VOLUME_SPACING_AND_WINDOW_LEVELS,
      volume,
      spacing: volumeData.spacing,
    });
  } catch (error) {
    onLoadError('Failed to create volume');
    Sentry.captureException(error);
    return;
  }
}

type SeriesSet = Set<string>;

/**
 * Examine the views being shown and ensure the required series name from the specified studies are loaded.
 */
export function useContrastLoader() {
  const dispatch = useAppDispatch();
  let { contrastViews } = useAppSelector((state) => state.contrast);
  const [activeStudySeries] = useState<SeriesSet>(new Set());
  const { contrastVolumeMap, dispatchContrastVolumeAction } = useContrastContext();
  const { contrastWindowLevels } = useAppSelector((state) => state.window);
  const ctVolume = useAppSelector((state) => state.ctVolume.ctVolume);
  const seriesVolumeData = useAppSelector((state) => state.contrast.seriesVolumeData);
  const seriesVolumeDataLoadState = useAppSelector((state) => state.contrast.seriesVolumeDataLoadState);

  // Whenever the set of contrast views changes we need to update which study series are active, start loading (or continue loading) any
  // that need to be loaded and stop loading and free any that are no longer required.
  useEffect(() => {
    // Build a set of all the study series that should now be active.
    const nextActiveStudySeries: SeriesSet = new Set();
    contrastViews.forEach((contrastView) => {
      // Empty views have no associated volume.
      if (contrastView.viewType !== ContrastViewType.Empty && contrastView.seriesName) {
        nextActiveStudySeries.add(contrastView.seriesName);
      }
    });

    // Build a set of all the study series that are newly active.
    const newStudySeries: SeriesSet = new Set(nextActiveStudySeries);
    activeStudySeries.forEach((seriesName) => newStudySeries.delete(seriesName));

    // Build a set of all the study series that are no longer active.
    const inactiveStudySeries: SeriesSet = new Set(activeStudySeries);
    nextActiveStudySeries.forEach((seriesName) => {
      inactiveStudySeries.delete(seriesName);
    });

    // Mutate the store's active study series.
    newStudySeries.forEach((seriesName) => {
      activeStudySeries.add(seriesName);
    });
    inactiveStudySeries.forEach((seriesName) => {
      activeStudySeries.delete(seriesName);
    });

    // Start loading any study series that are newly active.
    newStudySeries.forEach((seriesName) => {
      // Get the actual series so we know the number of image slices to load.
      const loadState = seriesVolumeDataLoadState[seriesName];
      const seriesData = ctVolume?.content.find((seriesData) => seriesData.key === seriesName);
      if (loadState == null && seriesData != null) {
        loadVolumeData(seriesName, seriesData.presigned_slice_urls, dispatch, false);
      }
    });

    // Remove the newly inactive study series from the store's ContrastVolumeMap.
    inactiveStudySeries.forEach((seriesName) => {
      dispatchContrastVolumeAction({
        seriesName: seriesName,
        type: ContrastVolumeActions.REMOVE,
      });
    });
  }, [contrastViews]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      // Remove all study series as we can't be interested in any of them.
      const inactiveStudySeries: SeriesSet = new Set(activeStudySeries);
      inactiveStudySeries.forEach((seriesName) => {
        dispatchContrastVolumeAction({
          seriesName: seriesName,
          type: ContrastVolumeActions.REMOVE,
        });
        activeStudySeries.delete(seriesName);
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * The effect will load the ContrastVolume from the VolumeData once it has fully loaded.
   */
  useEffect(() => {
    const seriesNames = Object.keys(seriesVolumeDataLoadState);
    seriesNames.forEach((seriesName) => {
      const loadState = seriesVolumeDataLoadState[seriesName];
      if (loadState === LoadState.LOADED && !contrastVolumeMap.has(seriesName)) {
        const volumeData = seriesVolumeData[seriesName];
        loadContrastVolumeFromVolumeData(seriesName, dispatchContrastVolumeAction, contrastWindowLevels, volumeData);
      }
    });
  }, [
    seriesVolumeDataLoadState,
    contrastVolumeMap,
    dispatchContrastVolumeAction,
    contrastWindowLevels,
    seriesVolumeData,
  ]);
}
