import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CalciumScorePerMajorVessel,
  CPRVesselData,
  SelectedVesselData,
  Shape,
  SliceImageData,
  UpdateSliceIndicesPayload,
  VesselDataResponse,
  VesselDataState,
  WallPolygons,
} from './types';
import { ImageBuffer } from '../../components/WebGLViewer/Utils';
import {
  DEFAULT_CT_NONCONTRAST_VIEWER_DATA,
  DEFAULT_LONG_AXIS_VIEWER_DATA,
  DEFAULT_SELECTED_VESSEL_DATA,
  DEFAULT_SHORT_AXIS_VIEWER_DATA,
  DEFAULT_VESSEL_VIEWER_DATA,
} from './constants';

export const initialVesselDataState = (): VesselDataState => {
  return {
    vesselData: undefined,
    vesselViewerData: {},
    ctNonContrastViewerData: undefined,
    savingVesselId: undefined,
    selectedVesselData: { ...DEFAULT_SELECTED_VESSEL_DATA },
  };
};

export const initialSelectedVesselData = (): SelectedVesselData => {
  return {
    highSliceIdx: 0,
    midSliceIdx: 10, // TODO: This may not be a safe value.
    lowSliceIdx: 20, // TODO: This may not be a safe value.
    name: undefined,
    priorityVessel: undefined,
    highestPriorityIdx: 0,
  };
};

export const vesselDataSlice = createSlice({
  name: 'vesselData',
  initialState: initialVesselDataState(),
  reducers: {
    setVesselData: (state, action: PayloadAction<VesselDataResponse>) => {
      state.vesselData = action.payload;
    },

    selectVessel: (state, action: PayloadAction<string | undefined>) => {
      state.selectedVesselData.name = action.payload;
    },

    updateSliceIndices: (state, action: PayloadAction<UpdateSliceIndicesPayload>) => {
      const { high, mid, low } = action.payload;

      if (high !== undefined) {
        state.selectedVesselData.highSliceIdx = high;
      }
      if (mid !== undefined) {
        state.selectedVesselData.midSliceIdx = mid;
      }
      if (low !== undefined) {
        state.selectedVesselData.lowSliceIdx = low;
      }
    },

    updateCalciumScores: (state, action: PayloadAction<CalciumScorePerMajorVessel>) => {
      Object.entries(action.payload).forEach(([majorVessel, calciumScore]) => {
        if (state.vesselData?.[majorVessel]) {
          state.vesselData[majorVessel].calcium_score = calciumScore;
        }
      });
    },

    updatePriorityVessel: (state, action: PayloadAction<string | undefined>) => {
      state.selectedVesselData.priorityVessel = action.payload;
    },

    updateHighestPriorityIdx: (state, action: PayloadAction<number>) => {
      state.selectedVesselData.highestPriorityIdx = action.payload;
    },

    addVesselViewerDataCPRViewerData: (
      state,
      action: PayloadAction<{
        vesselID: string | undefined;
        cprVesselData: CPRVesselData;
      }>
    ) => {
      const { vesselID, cprVesselData } = action.payload;
      if (vesselID != null && !state.vesselViewerData[vesselID]) {
        state.vesselViewerData[vesselID] = {};
      }
      if (vesselID != null) {
        state.vesselViewerData[vesselID].cprVesselData = cprVesselData;
      }
    },

    addVesselViewerDataCPRViewerDataImage: (
      state,
      action: PayloadAction<{
        vesselID: string | undefined;
        sliceIndex: number;
        imageBuffer: ImageBuffer;
      }>
    ) => {
      const { vesselID, sliceIndex, imageBuffer } = action.payload;

      // To update we MUST have existing cprVesselData and the studyId and vesselID must match.
      if (
        state.selectedVesselData.name === vesselID &&
        vesselID != null &&
        state.vesselViewerData[vesselID]?.cprVesselData
      ) {
        const cprVesselData = state.vesselViewerData[vesselID].cprVesselData!;
        if (!cprVesselData.imageBufferData) {
          cprVesselData.imageBufferData = [];
        }
        cprVesselData.imageBufferData[sliceIndex] = imageBuffer;
      }
    },
    updateCPRVesselDataForVessel: (
      state,
      action: PayloadAction<{ vesselID: string; cprVesselData: CPRVesselData }>
    ) => {
      const { vesselID, cprVesselData } = action.payload;

      // Check we are setting data for this patient and run, if not we should abort saving the changes.
      if (!state.vesselViewerData) {
        state.vesselViewerData = {};
      }

      if (!state.vesselViewerData[vesselID]) {
        state.vesselViewerData[vesselID] = {
          ...DEFAULT_VESSEL_VIEWER_DATA,
        };
      }

      state.vesselViewerData[vesselID].cprVesselData = cprVesselData;
    },

    clearSavingVessel: (state) => {
      state.savingVesselId = undefined;
    },

    savingVessel: (state, action: PayloadAction<string | undefined>) => {
      state.savingVesselId = action.payload;
    },

    updateShortAxisVesselData: (
      state,
      action: PayloadAction<{
        vesselID: string;
        shape: Shape;
        lumen: WallPolygons;
        outer: WallPolygons;
      }>
    ) => {
      const { vesselID, shape, lumen, outer } = action.payload;
      if (!state.vesselViewerData[vesselID]) {
        state.vesselViewerData[vesselID] = {
          ...DEFAULT_VESSEL_VIEWER_DATA,
        };
      }

      if (!state.vesselViewerData[vesselID].shortAxisViewerData) {
        state.vesselViewerData[vesselID].shortAxisViewerData = {
          ...DEFAULT_SHORT_AXIS_VIEWER_DATA,
        };
      }

      state.vesselViewerData[vesselID].shortAxisViewerData!.shape = shape;
      state.vesselViewerData[vesselID].shortAxisViewerData!.polygonsLumen = lumen;
      state.vesselViewerData[vesselID].shortAxisViewerData!.polygonsOuter = outer;
    },
    updateLongAxisShapeData: (state, action: PayloadAction<{ vesselID: string; shape: Shape }>) => {
      const { vesselID, shape } = action.payload;

      if (!state.vesselViewerData[vesselID]) {
        state.vesselViewerData[vesselID] = {
          ...DEFAULT_VESSEL_VIEWER_DATA,
        };
      }

      if (!state.vesselViewerData[vesselID].longAxisViewerData) {
        state.vesselViewerData[vesselID].longAxisViewerData = {
          ...DEFAULT_LONG_AXIS_VIEWER_DATA,
        };
      }

      state.vesselViewerData[vesselID].longAxisViewerData!.shape = shape;
    },
    updateCTNonContrastShapeData: (state, action: PayloadAction<{ shape: Shape }>) => {
      const { shape } = action.payload;

      if (!state.ctNonContrastViewerData) {
        state.ctNonContrastViewerData = {
          ...DEFAULT_CT_NONCONTRAST_VIEWER_DATA,
        };
      }
      state.ctNonContrastViewerData!.shape = shape;
    },
    updateCPRVesselSliceImageData: (state, action: PayloadAction<SliceImageData>) => {
      const { vesselID, sliceIndex, image } = action.payload;

      const cprVesselData = state.vesselViewerData[vesselID]?.cprVesselData;
      if (cprVesselData) {
        if (!cprVesselData.imageBufferData) {
          cprVesselData.imageBufferData = [];
        }
        cprVesselData.imageBufferData[sliceIndex] = image;
      }
    },

    updateShortAxisVesselSliceImageData: (state, action: PayloadAction<SliceImageData>) => {
      const { vesselID, sliceIndex, image } = action.payload;

      const shortAxisData = state.vesselViewerData[vesselID]?.shortAxisViewerData;

      if (shortAxisData) {
        if (!shortAxisData.imageBufferData) {
          shortAxisData.imageBufferData = [];
        }
        shortAxisData.imageBufferData[sliceIndex] = image;
      }
    },

    updateLongAxisVesselSliceImageData: (state, action: PayloadAction<SliceImageData>) => {
      const { vesselID, sliceIndex, image } = action.payload;

      const longAxisData = state.vesselViewerData[vesselID]?.longAxisViewerData;

      if (longAxisData) {
        if (!longAxisData.imageBufferData) {
          longAxisData.imageBufferData = [];
        }
        longAxisData.imageBufferData[sliceIndex] = image;
      }
    },

    updateCTNonContrastVesselSliceImageData: (
      state,
      action: PayloadAction<{
        sliceIndex: number;
        image: ImageBuffer;
      }>
    ) => {
      const { sliceIndex, image } = action.payload;

      const ctNonContrastViewerData = state.ctNonContrastViewerData;

      if (ctNonContrastViewerData) {
        if (!ctNonContrastViewerData.imageBufferData) {
          ctNonContrastViewerData.imageBufferData = [];
        }
        ctNonContrastViewerData.imageBufferData[sliceIndex] = image;
      }
    },
  },
});

export const vesselDataActions = vesselDataSlice.actions;
