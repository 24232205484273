import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { ContrastViewContent, ContrastViewType } from '../context/contrast-types';
import { MAX_RECENTLY_SELECTED_VIEWS } from '../reducers/viewConfig/constants';
import { useAppDispatch, useAppSelector } from '../hooks';
import { viewConfigActions } from '../reducers/viewConfig/viewConfigSlice';
import { isNonContrastSeries } from '../reducers/dragAndDrop/helpers';

export const usePushRecentlySelectedViews = () => {
  const dispatch = useAppDispatch();

  const { recentlySelected } = useAppSelector((state) => state.viewConfig);

  const pushRecentlySelectedViews = useCallback(
    (contrastViews: ContrastViewContent[]) => {
      // In case we somehow push an empty view
      const newViews = contrastViews.filter((x) => x.viewType !== ContrastViewType.Empty);

      // Filter out views in current view which are contained in the new views. Unique views only please!
      const currentViews = [
        ...recentlySelected.filter(
          (recentView) => newViews.find((newView) => isEqual(newView, recentView)) === undefined
        ),
      ];

      // Stop this thing from growing too large, it's unlikely we'll ever need more than MAX_RECENTLY_SELECTED_VIEWS
      const mashWithPreviousViews = [...newViews, ...currentViews].slice(
        0,
        // If we've dragged in series (newViews > 1) we'll allow the list to grow to double
        newViews.length > 1 ? MAX_RECENTLY_SELECTED_VIEWS * 2 : MAX_RECENTLY_SELECTED_VIEWS
      );
      dispatch(viewConfigActions.setRecentlySelectedViews(mashWithPreviousViews));
    },
    [dispatch, recentlySelected]
  );

  return pushRecentlySelectedViews;
};

export const useRemoveRecentlySelectedView = () => {
  const dispatch = useAppDispatch();
  const { recentlySelected } = useAppSelector((state) => state.viewConfig);

  const removeRecentlySelectedView = useCallback(
    (contrastView: ContrastViewContent) => {
      const newViews = recentlySelected.filter((x) => x !== contrastView);
      dispatch(viewConfigActions.setRecentlySelectedViews(newViews));
    },
    [dispatch, recentlySelected]
  );

  return removeRecentlySelectedView;
};

// Returns true if the currently visible CT Volume is a non-contrast series.
export function useIsNonContrastSelector() {
  const { seriesId } = useAppSelector((state) => state.viewConfig);
  return isNonContrastSeries(seriesId);
}
