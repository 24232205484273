import React, { useEffect } from 'react';
import { Study } from '../../context/types';
import { useDashboardDispatch, useDashboardSelector } from '../../dashboardHooks';
import { processDatasetsResponse } from '../../hooks/use-dashboard-data';
import { DashboardDispatch } from '../../dashboardStore';

import { dashboardActions } from './dashboardSlice';
import * as api from '../../utils/api';
import { API } from '../../config';
import { isEqualWith } from 'lodash';
import { useQuery } from 'react-query';
import { useIsAuditorUserSelector } from '../../selectors/user';

/**
 * Compares two studies to determine if any changes have been made that require the dashboard item to be reloaded.
 */
export function studyIsEqual(a: Study, b: Study): boolean {
  const result =
    a.patient_cipher === b.patient_cipher &&
    a.calcium_score === b.calcium_score &&
    a.status === b.status &&
    a.stenosis === b.stenosis &&
    a.study_id === b.study_id &&
    a.study_date === b.study_date &&
    a.vulnerable_plaque === b.vulnerable_plaque &&
    a.workflow_status?.state === b.workflow_status?.state &&
    a.workflow_user === b.workflow_user &&
    a.can_be_viewed.reason === b.can_be_viewed.reason &&
    a.can_be_viewed.viewable === b.can_be_viewed.viewable &&
    a.report_active_review?.review_id === b.report_active_review?.review_id &&
    a.report_active_review?.assignee.email === b.report_active_review?.assignee.email &&
    a.report_active_review?.assigner.email === b.report_active_review?.assigner.email &&
    a.client_id === b.client_id &&
    a.patient_name === b.patient_name;
  return result;
}

export function updateData(
  studies: Record<string, Study>,
  dashboardData: Record<string, Study> | null,
  dashboardDispatch: DashboardDispatch
) {
  if (!dashboardData) {
    dashboardDispatch(dashboardActions.setStudies(studies));
  } else {
    let newDashboardData: Record<string, Study> = {};

    // Check for updated records
    Object.keys(studies).forEach((key) => {
      if (dashboardData[key]) {
        const first = studies[key];
        const second = dashboardData[key];
        const areEqual = isEqualWith(first, second, studyIsEqual);
        if (areEqual === false) {
          newDashboardData[key] = studies[key];
        }
      }
    });

    if (Object.keys(newDashboardData).length > 0) {
      dashboardDispatch(dashboardActions.setStudies({ ...dashboardData, ...newDashboardData }));
    }
  }
}

/**
 * A component to load the initial dashboard data for a studies and add it to the store.
 */
export const DashboardLoader: React.FunctionComponent = () => {
  const dashboardDispatch = useDashboardDispatch();
  const dashboardData = useDashboardSelector((state) => state.dashboard.studies);
  const decryptDashboardData = useDashboardSelector((state) => state.dashboard.decryptDashboardData);

  const isAuditorUser = useIsAuditorUserSelector();

  useQuery('dashboard_data', async () => {
    // No need to fetch dashboard data as an auditor
    if (isAuditorUser) return;

    const json = await api.getJSON(API.dashboard, true);
    const studies = await processDatasetsResponse(json, dashboardData);
    updateData(studies, dashboardData, dashboardDispatch);
  });

  // Update main dashboard data with the decrypted page record
  useEffect(() => {
    if (decryptDashboardData) {
      updateData(decryptDashboardData, dashboardData, dashboardDispatch);
      dashboardDispatch(dashboardActions.setDecryptDashboardData(null));
    }

    return () => {
      dashboardDispatch(dashboardActions.setDecryptDashboardData(null));
    };
  }, [dashboardData, decryptDashboardData, dashboardDispatch]);

  return null;
};
