import { IDBPDatabase, openDB } from 'idb/with-async-ittr.js';

const stores = ['hdf5_data', 'json_data'];
const INDEXED_DB_VERSION = 1;

async function clearByExpiry(db: IDBPDatabase<any>) {
  await Promise.allSettled(
    stores.map(async (store) => {
      const tx = await db.transaction(store, 'readwrite');
      const range = IDBKeyRange.upperBound(new Date());
      let cursor = await tx.store.index('expiry').openCursor(range);
      while (cursor) {
        if (!cursor) return;
        cursor.delete();
        cursor = await cursor.continue();
      }
      await tx.done;
    })
  );
}

const indexedDB = (async () => {
  const db = await openDB('artrya', INDEXED_DB_VERSION, {
    upgrade(db, oldVersion, newVersion, transaction) {
      const createStore = (store: any) => {
        const instance = db.createObjectStore(store, {
          keyPath: 'id',
          autoIncrement: true,
        });
        instance.createIndex('expiry', 'expiry', { unique: false });
        instance.createIndex('patient_run_group', ['patient_id', 'run_id', 'group_type'], { unique: false });
      };

      switch (oldVersion) {
        // New install
        case 0: {
          stores.forEach(createStore);
          break;
        }

        // Latest
        default:
          break;
      }
    },
  });

  await clearByExpiry(db);

  return db;
})();

export default indexedDB;

export const getOne = async (store: string, key: IDBKeyRange | IDBValidKey) => {
  const db = await indexedDB;
  return await db.transaction(store, 'readwrite').objectStore(store).get(key);
};

export const addOne = async (store: string, data: { id: any; expiry: any; body: any; group_type?: string }) => {
  const db = await indexedDB;
  await db.put(store, data);
};

export const clearDb = async () => {
  const db = await indexedDB;
  await Promise.allSettled(stores.map((store) => db.clear(store)));
};

/**
 * @example
 *
 *   deleteAllBy('hdf5_data', {
 *     patient_id: 'ENV_000001',
 *     run_id: '20200910_0',
 *     group_type: '3D_MODEL'
 *   })
 */
export const deleteAllBy = async (
  store: string,
  { patient_id, run_id, group_type }: { patient_id: any; run_id: any; group_type: never }
) => {
  if (!patient_id || !run_id || !group_type) {
    throw new Error('deleteAllBy() required all 3 indexes be provided');
  }

  const db = await indexedDB;
  const tx = await db.transaction(store, 'readwrite');
  const range = IDBKeyRange.only([patient_id, run_id, group_type]);
  let cursor = await tx.store.index('patient_run_group').openCursor(range);
  while (cursor) {
    if (!cursor) return;
    cursor.delete();
    cursor = await cursor.continue();
  }
  await tx.done;
};

export const deleteAllByGroup = async (patientID: any, runID: any, groupTypes = []) => {
  await Promise.allSettled(
    groupTypes
      .map((groupType) => [
        deleteAllBy('json_data', {
          patient_id: patientID,
          run_id: runID,
          group_type: groupType,
        }),
        deleteAllBy('hdf5_data', {
          patient_id: patientID,
          run_id: runID,
          group_type: groupType,
        }),
      ])
      .flat()
  );
};
