import { useEffect, useRef, useMemo } from 'react';
import { ImageBuffer } from '../../components/WebGLViewer/Utils';
import { KEY_CPR, KEY_MPR_LONG_AXIS, MAJOR_VESSELS, NAV_TABS } from '../../config';
import { PatientStats } from '../../context/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { cprVersionSelector } from '../../selectors/study';
import { useVesselStateSelector } from '../../selectors/vessels';
import { CalciumScorePerMajorVessel, VesselViewerData } from './types';
import { lowPerformanceGPU } from '../../components/WebGLViewer/WebGLViewer';
import { vesselDataActions } from './vesselDataSlice';
import { VIEWER_TYPES, ViewerTypes, getVolumeDir, fetchImageBuffer } from './VesselViewerDataLoader';
import { AppDispatch } from '../../store';
import { DEFAULT_SELECTED_VESSEL_DATA } from './constants';
import { ObjectArray } from '../../types/common';

// The maximum number of images we permit to be fetched at the same time.
const MAX_IMAGE_FETCH_COUNT: number = 20;
// The priority adjustment to apply to an image that belongs to a viewer which is currently hidden to the user.
const PRIORITY_VIEWER_HIDDEN: number = 500;
// The priority adjustment to apply to an image that belongs to a vessel which is not currently selected but is a major vessel.
const PRIORITY_MAJOR_VESSEL: number = 500;
// The priority adjustment to apply to an image that belongs to a vessel which is not currently selected and is not a major vessel.
const PRIORITY_OTHER_VESSEL: number = 1000;
// The maximum priority number of an image considered worth preloading (5000 should allow all images to preload).
// With 1000 set we will:
//  Pre-load all images for the curent vessel for all viewers.
//  Pre-load all major vessel images for all viewers.
//  Pre-load the first image of each minor vessel for the currently visible viewers and none for hidden viewers.
const PRIORITY_THRESHOLD: number = lowPerformanceGPU ? 505 : 1000;

// Convert the major vessels to lower case as that's how the vessel names are defined.
const majorVessels: string[] = MAJOR_VESSELS.map((vesselID) => vesselID.toLowerCase());

export function useCalciumScoreUpdate() {
  const patientStats = useAppSelector((state) => state.patient.patientStats);
  const calciumScoreData = useAppSelector((state) => state.calciumScore.calciumScoreData);
  const dispatch = useAppDispatch();
  const vesselData = useAppSelector((state) => state.vesselData.vesselData);
  const selectedVesselName = useAppSelector((state) => state.vesselData.selectedVesselData.name);

  useEffect(() => {
    if (!(calciumScoreData && typeof calciumScoreData.total !== 'undefined')) {
      return;
    }

    // Update patient stats
    // NOTE: JSON.stringify(null) returns the string 'null', JSCON.stringify(undefined) returns
    // the non-string undefined. patientStats may be null.
    let newPatientStats: PatientStats = JSON.parse(JSON.stringify(patientStats ?? undefined) ?? '{}');
    newPatientStats.calcium_score = calciumScoreData.total;

    // update vesselData
    // editing parts of vesselData not to trigger an update for all
    // additionally update vesselData
    Object.entries(calciumScoreData).map(([key, calciumScore]) => {
      return {};
    });

    const calciumScorePerMajorVessel: CalciumScorePerMajorVessel = {
      lm: calciumScoreData['lm'],
      rca: calciumScoreData['rca'],
      lad: calciumScoreData['lad'],
      lcx: calciumScoreData['lcx'],
    };

    selectedVesselName &&
      vesselData &&
      vesselData[selectedVesselName] &&
      dispatch(vesselDataActions.updateCalciumScores(calciumScorePerMajorVessel));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calciumScoreData]);
}

/**
 * Get the viewerData for the specified viewer type from the vesselViewerData.
 */
function getViewerData(viewerData: VesselViewerData, viewerType: ViewerTypes) {
  switch (viewerType) {
    case 'cpr':
      return viewerData.cprVesselData;
    case 'short-axis':
      return viewerData.shortAxisViewerData;
    case 'long-axis':
      return viewerData.longAxisViewerData;
    default:
      return undefined;
  }
}

/**
 * Save a sucessfully loaded image in the store context.
 */
const storeImageBuffer = (
  dispatch: AppDispatch,
  viewerType: ViewerTypes,
  vesselID: string,
  sliceIndex: number,
  image: ImageBuffer
) => {
  switch (viewerType) {
    case 'cpr':
      dispatch(vesselDataActions.updateCPRVesselSliceImageData({ vesselID, sliceIndex, image }));
      break;
    case 'long-axis':
      dispatch(vesselDataActions.updateLongAxisVesselSliceImageData({ vesselID, sliceIndex, image }));
      break;
    case 'short-axis':
      dispatch(vesselDataActions.updateShortAxisVesselSliceImageData({ vesselID, sliceIndex, image }));
      break;
    case 'ct-noncontrast':
      dispatch(vesselDataActions.updateCTNonContrastVesselSliceImageData({ sliceIndex, image }));
      break;
  }
};

// The data we need to identify an image that needs to be loaded.
interface PendingImage {
  // The type of viewer that the image belongs to.
  viewerType: ViewerTypes;
  // The name of the vessel the image belongs to.
  vesselID: string;
  // The number of the slice to load.
  sliceIndex: number;
  // A measure of how urgent it is to load this image (0 means we want to show it right now, larger numbers denote lower priorities).
  priority: number;
  // A unique tag we can use to identify this image.
  tag: string;
}

/**
 * Add this loader to automatically load the images for the cMPR, sMPR, short-axis and non-contrast viewers in the
 * background. Images will be loaded for all vessels. Image priority is determined so that the most important images are loaded first.
 */
export function useImageLoader() {
  const versionHead = useAppSelector((state) => state.store.versionHead);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const nonContrastSlice = useAppSelector((state) => state.globalFlags.nonContrastSlice);
  const visibleTab = useAppSelector((state) => state.globalFlags.visibleTab);
  const patientID = useAppSelector((state) => state.patient.patientID);
  const { dispatch } = useVesselStateSelector();
  const cprVersion = useAppSelector(cprVersionSelector);
  const { vessels, vesselViewerData } = useVesselStateSelector();
  const selectedVesselData = useAppSelector((state) => state.vesselData.selectedVesselData);
  const ctNonContrastViewerData = useAppSelector((state) => state.vesselData.ctNonContrastViewerData);
  const { sliceidx: cprSliceidx, selectedMPRView } = useAppSelector((state) => state.cpr);

  // Remember which images we have fetched (or started to fetch) based on their image tag.
  const fetchedImageTags = useRef<ObjectArray<boolean>>({});
  // The number of images currently being fetched.
  const fetchingCount = useRef<number>(0);

  // This will store the list of all pending images without any consideration for their ordering.
  // We will prioritise and sort them in a useEffect that operates on this list.
  const pendingImagesWithoutPriority: PendingImage[] = useMemo(() => {
    const result: PendingImage[] = [];
    // Loop through each vessel, then each viewer type, then each slice, and add them to the pending image list.
    vessels.forEach((vesselID) => {
      if (vesselViewerData[vesselID]) {
        // Loop through each viewer type.
        // NonContrast images are not per vessel, we add them to the pending list separately below.
        VIEWER_TYPES.filter((viewerType) => viewerType !== 'ct-noncontrast').forEach((viewerType) => {
          const data = getViewerData(vesselViewerData[vesselID], viewerType);
          // If we can fetch the images for the vessel and haven't done so yet then do so now.
          const sliceCount = data?.shape?.length ?? 0;
          // Add the slice images we need to load to the list.
          for (let sliceIndex = 0; sliceIndex < sliceCount; sliceIndex++) {
            const tag = `${viewerType}-${vesselID}-${sliceIndex}`;
            result.push({
              viewerType,
              vesselID,
              sliceIndex,
              priority: PRIORITY_THRESHOLD + 1,
              tag,
            });
          }
        });
      }
    });

    // Loop through each non-contrast slice and add them to the pending image list.
    // Add the slice images we need to load to the list.
    const contrastViewerSliceCount: number = ctNonContrastViewerData?.shape?.length ?? 0;
    for (let sliceIndex = 0; sliceIndex < contrastViewerSliceCount; sliceIndex++) {
      const tag = `ct-noncontrast-${sliceIndex}`;
      result.push({
        viewerType: 'ct-noncontrast',
        vesselID: 'undefined',
        sliceIndex,
        priority: PRIORITY_THRESHOLD + 1,
        tag,
      });
    }
    return result;
  }, [vessels, vesselViewerData, ctNonContrastViewerData]);

  useEffect(() => {
    // Abort doing anything early if we know we aren't going to do anything this pass.
    if (fetchingCount.current >= MAX_IMAGE_FETCH_COUNT) {
      return;
    }

    // Now we take the list of pending images, give them priorities, sort them, and load them.
    const pendingImages: PendingImage[] = [];

    // Loop through each pending image and give it a priority.
    pendingImagesWithoutPriority.forEach((pendingImage) => {
      if (!fetchedImageTags.current[pendingImage.tag]) {
        // Set the initial priority based on if the vessel is the currently selected vessel, a main vessel, or an other vessel.
        let priority =
          pendingImage.vesselID === selectedVesselData.name
            ? 0
            : majorVessels.includes(pendingImage.vesselID)
            ? PRIORITY_MAJOR_VESSEL
            : PRIORITY_OTHER_VESSEL;
        const isPatientTab = visibleTab === NAV_TABS.patientTab;
        // Get the cpr slice index for this vessel, this will be the default unless this is the current vessel.
        const cprSliceIndex = pendingImage.vesselID === selectedVesselData.name ? cprSliceidx : 0;
        // Get the three indicator positions for this vessel, these will be the default unless this is the current vessel.
        const highSliceIdx =
          pendingImage.vesselID === selectedVesselData.name
            ? selectedVesselData.highSliceIdx
            : DEFAULT_SELECTED_VESSEL_DATA.highSliceIdx;
        const midSliceIdx =
          pendingImage.vesselID === selectedVesselData.name
            ? selectedVesselData.midSliceIdx
            : DEFAULT_SELECTED_VESSEL_DATA.midSliceIdx;
        const lowSliceIdx =
          pendingImage.vesselID === selectedVesselData.name
            ? selectedVesselData.lowSliceIdx
            : DEFAULT_SELECTED_VESSEL_DATA.lowSliceIdx;

        switch (pendingImage.viewerType) {
          case 'short-axis':
            // Reduce the priority if the image belongs to a viewer which is currently hidden.
            if (!isPatientTab) {
              priority += PRIORITY_VIEWER_HIDDEN;
            }
            // The current slices have the highest priority, this decreases the further away the slice index is from them.
            priority += Math.min(
              Math.abs(pendingImage.sliceIndex - highSliceIdx),
              Math.abs(pendingImage.sliceIndex - midSliceIdx),
              Math.abs(pendingImage.sliceIndex - lowSliceIdx)
            );
            break;

          case 'cpr':
            // Reduce the priority if the image belongs to a viewer which is currently hidden.
            if (!isPatientTab ?? selectedMPRView !== KEY_CPR) {
              priority += PRIORITY_VIEWER_HIDDEN;
            }
            // The current slice has the highest priority, this decreases the further away the slice index is from it.
            priority += Math.abs(pendingImage.sliceIndex - cprSliceIndex);
            break;

          case 'long-axis':
            // Reduce the priority if the image belongs to a viewer which is currently hidden.
            if (!isPatientTab ?? selectedMPRView !== KEY_MPR_LONG_AXIS) {
              priority += PRIORITY_VIEWER_HIDDEN;
            }
            // The current slice has the highest priority, this decreases the further away the slice index is from it.
            priority += Math.abs(pendingImage.sliceIndex - cprSliceIndex);
            break;

          case 'ct-noncontrast':
            // The default priority for a non-contrast slice is zero (it doesn't matter what vessel we're on).
            priority = 0;
            // Reduce the priority if the image belongs to a viewer which is currently hidden.
            if (visibleTab !== NAV_TABS.ctVolumeTab) {
              priority += PRIORITY_VIEWER_HIDDEN;
            }
            // The current slice has the highest priority, this decreases the further away the slice index is from it.
            priority += Math.abs(nonContrastSlice - pendingImage.sliceIndex);
            break;
        }
        // If the priority of the image isn't urgent enough to justify preloading it, don't preload it.
        if (priority <= PRIORITY_THRESHOLD) {
          pendingImages.push({
            ...pendingImage,
            priority,
          });
        }
      }
    });

    // Sort the pending images based on their priority (lower priority numbers load first).
    pendingImages.sort((a: PendingImage, b: PendingImage) => {
      return a.priority - b.priority;
    });

    // Load the pending images.
    pendingImages.forEach((pendingImage) => {
      if (fetchingCount.current < MAX_IMAGE_FETCH_COUNT) {
        // Remember that we have tried to fetch the image.
        fetchedImageTags.current[pendingImage.tag] = true;
        fetchingCount.current++;

        const volumeDir = getVolumeDir(
          pendingImage.vesselID,
          pendingImage.viewerType,
          pendingImage.viewerType === 'cpr' ? cprVersion : undefined
        );
        const endPoint = `data/${patientID}/${runID}/${volumeDir}`;

        // Fetch the image and update the store with it.
        fetchImageBuffer(
          pendingImage.sliceIndex,
          endPoint,
          versionHead,
          pendingImage.viewerType,
          pendingImage.viewerType === 'cpr'
        )
          .then((imageBuffer) => {
            storeImageBuffer(
              dispatch,
              pendingImage.viewerType,
              pendingImage.vesselID,
              pendingImage.sliceIndex,
              imageBuffer
            );
          })
          .catch((error) => {
            console.warn(
              'Failed to fetch slice',
              pendingImage.viewerType,
              pendingImage.vesselID,
              pendingImage.sliceIndex
            );
          })
          .finally(() => {
            fetchingCount.current--;
          });
      }
    });
  }, [
    versionHead,
    patientID,
    runID,
    pendingImagesWithoutPriority,
    selectedVesselData,
    cprSliceidx,
    nonContrastSlice,
    selectedMPRView,
    visibleTab,
    cprVersion,
    dispatch,
  ]);
}
