import { useEffect } from 'react';

// This hook works exactly the same as useEffect but will not trigger any
// eslint exhaustive-deps warnings.
// Use it in situations where the eslint suggestions are incorrect and adding
// the dep eslint suggested would cause unwanted renders or side effects.

// very simple example
// const [value, setValue] = useState(0);
// useEffect(() => {
//   setValue(value + 1);
// }, [value]);

// In the above example eslint would want value to be added as a dep
// BUT when it is added an infinate loop would occur.

// This hook can also be used to run code ONLY on mount of a component

export const useEffectCustomDeps = (func: () => void, vars: any[] = []) => {
  useEffect(() => {
    return func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, vars);
};
