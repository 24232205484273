import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTH_BASE_USER, User } from '../../config';
import { ClientConfig } from '../../context/types';

export interface AuthData {
  [key: string]: any;
}

export type userState = {
  user: User;
  authData: AuthData | null;
  clientConfig: ClientConfig | undefined;
};

export const initialUserState: userState = {
  user: AUTH_BASE_USER,
  authData: null,
  clientConfig: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setAuthData: (state, action: PayloadAction<AuthData>) => {
      state.authData = action.payload;
    },
    setClientConfig: (state, action: PayloadAction<ClientConfig | undefined>) => {
      state.clientConfig = action.payload;
    },
  },
});

export const userActions = userSlice.actions;
