import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import * as api from '../../utils/api';
import { releaseVersionActions } from './releaseVersionSlice';
import { useDashboardDispatch } from '../../dashboardHooks';
import showToast from '../../components/Toast/showToast';

const API_PLATFORM_BASE_URL = process.env.REACT_APP_PLATFORM_BASE_URL;

export interface ReleaseVersionResponse {
  content: Array<{ serviceName: string; serviceVersion: string }> | null;
  udiVersion: string | null;
  releaseVersion: string | null;
}

/**
 * Fetch the Release Version from the backend and save it in the Dashboard store.
 */

export async function updateReleaseVersion(serviceName: string, serviceVersion: string) {
  await api
    .postJSON(`${API_PLATFORM_BASE_URL}/support/serviceversionslist`, {
      serviceName: serviceName,
      serviceVersion: serviceVersion,
    })
    .then(() => {
      showToast.success('Release Version updated successfully');
    })
    .catch((error) => {
      showToast.success('Could not update Release Version');
      console.warn('Could not fetch Release Version', error);
    });
}

const fetchReleaseVersion = (onSuccess: (data: ReleaseVersionResponse) => void) => {
  api
    .getJSON(`${API_PLATFORM_BASE_URL}/support/serviceversionslist`, false)
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      console.warn('Could not fetch Release Version', error);
    });
};

/**
 * Load the release version state from the backend and save it in the dashboard store.
 */

export function loadReleaseVersion(dispatch: AppDispatch) {
  return fetchReleaseVersion((data) => {
    dispatch(releaseVersionActions.setReleaseVersion(data));
  });
}

/**
 * A component to load the Release Version data for a study and add it to the dashboard store.
 */
export const ReleaseVersionLoader: React.FunctionComponent = () => {
  const dashboardDispatch = useDashboardDispatch();

  useEffect(() => {
    loadReleaseVersion(dashboardDispatch);
  }, [dashboardDispatch]);

  return null;
};
