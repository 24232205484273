import React, { ReactNode, useMemo } from 'react';
import { AUTH_USER_GROUP } from '../../config';
import { useDashboardDispatch } from '../../dashboardHooks';
import { AuthData, userActions } from './userSlice';
import { createUserFromCognitoData } from '../../utils/auth';
import { useClientConfig } from '../../hooks/use-client-config';

interface Props {
  authData: AuthData;
  children: ReactNode;
}

export const UserDataLoader: React.FunctionComponent<Props> = ({ children, authData }) => {
  const dashboardDispatch = useDashboardDispatch();
  // We create and use our own user object with extracted CognitoUser properties
  const user = useMemo(() => createUserFromCognitoData(authData), [authData]);

  // Will fetch the client config whenever authData changes and is non-null
  // Don't call endpoint if user is auditor
  const isAuditor = user.groups?.includes(AUTH_USER_GROUP.auditors);
  const clientConfig = useClientConfig(authData, isAuditor);

  dashboardDispatch(userActions.setAuthData(authData));
  dashboardDispatch(userActions.setUser(user));
  dashboardDispatch(userActions.setClientConfig(clientConfig));

  return <>{children}</>;
};
