import React, { ReactElement, useCallback } from 'react';
import { ActionModal } from '../ActionModal/ActionModal';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { reportStateActions } from '../../reducers/ReportState/ReportStateSlice';
import { reportActions } from '../../reducers/report/reportSlice';
import { globalFlagsActions } from '../../reducers/global-flags/globalFlagsSlice';

export default function ReportUnsavedModal(): ReactElement {
  const dispatch = useAppDispatch();
  const { editingModalDetails } = useAppSelector((state) => state.reportState);

  const onConfirmModal = useCallback(() => {
    editingModalDetails?.navigationAction();
    dispatch(reportStateActions.setEditingModalDetails(undefined));
    dispatch(reportActions.setEditing(false));
    dispatch(globalFlagsActions.setEditingImpressions(false));
    dispatch(globalFlagsActions.setEditingCoronaryFindings(false));
    dispatch(reportActions.deleteDraftReport());
  }, [editingModalDetails, dispatch]);

  const onCloseModal = useCallback(() => {
    dispatch(reportStateActions.setEditingModalDetails(undefined));
  }, [dispatch]);

  return (
    <ActionModal
      confirmText="Discard Changes"
      closeText="Cancel"
      onConfirm={onConfirmModal}
      onClose={onCloseModal}
      visible={!!editingModalDetails?.showModal}
      headerContent={<>Discard Changes</>}
    >
      <p>Are you sure you want to discard unsaved changes to the report?</p>
    </ActionModal>
  );
}
