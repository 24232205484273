import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { NAV_TABS } from '../../../config';
import { globalFlagsActions } from '../../../reducers/global-flags/globalFlagsSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useStudySeriesInfo, SeriesStatus } from '../../../selectors/study';
import {
  inlineReportingAction,
  UnsavedChangesToInlineReport,
} from '../../../reducers/inlineReporting/inlineReportingSlice';
import { reportStateActions } from '../../../reducers/ReportState/ReportStateSlice';
import { useAnyInlineReportingChangesMade } from '../../../selectors/reporting';
import { Icon } from '../../../objects/Icon/Icon';
import { AdminMenu } from './AdminMenu';

export interface AssessmentNavProps {
  studyReviewCount: number;
  returnToDashboard: () => void;
}

export const AssessmentNav: React.FunctionComponent<AssessmentNavProps> = ({ studyReviewCount, returnToDashboard }) => {
  const dispatch = useAppDispatch();
  const initialDataLoaded = useAppSelector((state) => state.globalFlags.initialDataLoaded);
  const visibleTab = useAppSelector((state) => state.globalFlags.visibleTab);
  const editingReport = useAppSelector((state) => state.report.editing);
  // The review report screen is now always available.
  const showReviewReport = true;
  const studySeriesInfo = useStudySeriesInfo();
  const showPatientOverview = useMemo(() => {
    return studySeriesInfo?.contrast !== SeriesStatus.Fail && studySeriesInfo?.contrast !== SeriesStatus.NotAvailable;
  }, [studySeriesInfo]);

  const anyInlineReportingChangesMade = useAnyInlineReportingChangesMade();

  const checkBeforeNavigatingAway = useCallback(
    (navigationAction: () => void, shouldCheckInlineReporting: boolean = false) => {
      // Only raise modal if editing report and navigating away from the report overview
      if (editingReport) {
        dispatch(reportStateActions.setEditingModalDetails({ showModal: true, navigationAction }));
      } else if (shouldCheckInlineReporting && anyInlineReportingChangesMade) {
        dispatch(inlineReportingAction.setInlineReportingWarningAction(navigationAction));
        dispatch(inlineReportingAction.setUnsavedChangesWarning(UnsavedChangesToInlineReport.LeavingStudy));
      } else {
        navigationAction();
      }
    },
    [editingReport, anyInlineReportingChangesMade, dispatch]
  );

  return (
    <>
      <button
        className="nav-bar__button"
        type="button"
        title="Dashboard"
        onClick={() => checkBeforeNavigatingAway(returnToDashboard, true)}
      >
        <div id="nav-bar-dashboard"></div>
        {studyReviewCount > 0 && <span className="nav-bar__notification">{studyReviewCount}</span>}
        <Icon icon="home" type="large" />
      </button>
      {initialDataLoaded && (
        <>
          {showPatientOverview && (
            <button
              className={cn('nav-bar__button', {
                'nav-bar__button--active': visibleTab === NAV_TABS.patientTab,
              })}
              type="button"
              title="Patient Overview"
              onClick={() =>
                checkBeforeNavigatingAway(() => dispatch(globalFlagsActions.setVisibleTab(NAV_TABS.patientTab)))
              }
            >
              <div id="nav-bar-patient-overview"></div>
              <Icon icon="patientoverview" type="large" />
            </button>
          )}

          <button
            className={cn('nav-bar__button', {
              'nav-bar__button--active': visibleTab === NAV_TABS.ctVolumeTab,
            })}
            type="button"
            title="CT Volume"
            onClick={() =>
              checkBeforeNavigatingAway(() => dispatch(globalFlagsActions.setVisibleTab(NAV_TABS.ctVolumeTab)))
            }
          >
            <div id="nav-bar-ct-volume"></div>
            <Icon icon="ctvolume" type="large" />
          </button>

          {showReviewReport && (
            <button
              className={cn('nav-bar__button', {
                'nav-bar__button--active': visibleTab === NAV_TABS.reportReviewTab,
              })}
              type="button"
              title="Review Report"
              onClick={() =>
                checkBeforeNavigatingAway(() => dispatch(globalFlagsActions.setVisibleTab(NAV_TABS.reportReviewTab)))
              }
            >
              <div id="nav-bar-report"></div>
              <Icon icon="report" type="large" />
            </button>
          )}

          <AdminMenu />
        </>
      )}
    </>
  );
};
