// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditMode } from '../../context/types';
import { LineArray } from '../../types/common';

export const initialCenterlineEditState = (): EditMode => {
  return {
    editing: false,
    showAnnos: false,
    showAuxAnnos: false,
    modified: false,
    centerline: [],
  };
};

export const centerlineEditSlice = createSlice({
  name: 'centerlineEdit',
  initialState: initialCenterlineEditState(),
  reducers: {
    startEditing: (state, action: PayloadAction<{ showAnnos: boolean; showAuxAnnos: boolean }>) => {
      if (state.editing === false) {
        state.editing = true;
        state.showAnnos = action.payload.showAnnos;
        state.showAuxAnnos = action.payload.showAuxAnnos;
      }
    },

    modifiedCenterline: (state, action: PayloadAction) => {
      if (state.editing && state.modified === false) {
        state.modified = true;
      }
    },

    updateCenterline: (state, action: PayloadAction<LineArray>) => {
      state.modified = true;
      state.centerline = action.payload;
    },

    stopEditing: (state, action: PayloadAction) => {
      if (state.editing) {
        state.editing = false;
      }
    },
  },
});
export const centerlineEditActions = centerlineEditSlice.actions;
