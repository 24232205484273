import React, { useState, ReactNode, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { customClassCheck } from '../../../utils/shared';

interface DropdownMenuProps {
  id?: string;
  title?: string;
  toggleClassName?: string;
  menuParentClassName?: string;
  menuClassName?: string;
  menuToggle: ReactNode;
  menuToggleText?: string;
  children?: ReactNode;
  footer?: ReactNode;
  position?: string;
}

export const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = ({
  id,
  title,
  toggleClassName,
  menuParentClassName,
  menuClassName,
  menuToggle,
  menuToggleText,
  children,
  footer,
  position,
}) => {
  const [showMenuItems, setShowMenuItems] = useState(false);

  const dropdownToggleHandler = () => {
    if (showMenuItems === true) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const openMenu = () => {
    setShowMenuItems(true);
  };

  const closeMenu = () => {
    setShowMenuItems(false);
  };

  const escKeydownHandler = useCallback((event) => {
    if (event.key === 'Escape') {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escKeydownHandler);

    return () => {
      document.removeEventListener('keydown', escKeydownHandler);
    };
  }, [escKeydownHandler]);

  return (
    <div className={`dropdown-menu${customClassCheck(menuParentClassName)}`}>
      <button
        id={id}
        type="button"
        title={title}
        className={cn(
          'dropdown-menu__toggle',
          {
            'dropdown-menu__toggle--has-text': menuToggleText,
            'dropdown-menu__toggle--active': showMenuItems === true,
          },
          `${customClassCheck(toggleClassName)}`
        )}
        onClick={dropdownToggleHandler}
      >
        {menuToggleText && <span className="dropdown-menu__toggle-text">{menuToggleText}</span>}
        {menuToggle}
      </button>
      {showMenuItems && (
        <div
          className={cn(
            'dropdown-menu__contents',
            {
              'dropdown-menu__contents--left': position === 'left',
            },
            `${customClassCheck(menuClassName)}`
          )}
        >
          <ul className="dropdown-menu__items">{children}</ul>
          {footer && <div className="dropdown-menu__footer">{footer}</div>}
        </div>
      )}
    </div>
  );
};
