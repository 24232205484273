import * as PIXI from 'pixi.js-legacy';
import { XYCoords } from '../../reducers/vesselData/types';
import {
  TOOL_TYPES,
  ELLIPSE_STATE,
  Measurement,
  CreateNewEllipseSpriteInterface,
  CreateNewMarkerInterface,
} from './types';
import {
  createMarkerSprite,
  drawInactiveCrosshair,
  drawActiveCrosshair,
  MEASUREMENT_SETTINGS,
  getAreaOfEllipse,
  createHitCircle,
  createHitEllipse,
  setLineStyle,
} from './utils';
const TWO_HANDLE_STATES = [
  ELLIPSE_STATE.New,
  ELLIPSE_STATE.HandleTopLeft,
  ELLIPSE_STATE.HandleTopRight,
  ELLIPSE_STATE.HandleBottomLeft,
  ELLIPSE_STATE.HandleBottomRight,
];

/**
 * Create and return a new ellipse sprite.
 */
export const createNewEllipseSprite = ({ measurement, scale, onMouseDown }: CreateNewEllipseSpriteInterface) => {
  const sprite = new PIXI.Graphics() as PIXI.Graphics;

  // Calculate the center of the ellipse.
  const midPoint = {
    x: (measurement.startPoint.x + measurement.endPoint.x) / 2,
    y: (measurement.startPoint.y + measurement.endPoint.y) / 2,
  };
  // Calculate the horizontal and vertical radius of the ellipse.
  const radius: XYCoords = {
    x: 0.5 * Math.abs(measurement.endPoint.x - measurement.startPoint.x),
    y: 0.5 * Math.abs(measurement.endPoint.y - measurement.startPoint.y),
  };

  // Draw handles (if not inactive).
  if (measurement.state !== ELLIPSE_STATE.Inactive) {
    let handlePoints = [measurement.startPoint, measurement.endPoint];
    const twoHandles = TWO_HANDLE_STATES.includes(measurement.state as ELLIPSE_STATE);
    if (!twoHandles) {
      const topLeft: XYCoords = {
        x: Math.min(measurement.startPoint.x, measurement.endPoint.x),
        y: Math.min(measurement.startPoint.y, measurement.endPoint.y),
      };
      const bottomRight: XYCoords = {
        x: Math.max(measurement.startPoint.x, measurement.endPoint.x),
        y: Math.max(measurement.startPoint.y, measurement.endPoint.y),
      };
      handlePoints = [topLeft, { x: bottomRight.x, y: topLeft.y }, { x: topLeft.x, y: bottomRight.y }, bottomRight];
    }
    for (let i = 0; i < handlePoints.length; i++) {
      if (twoHandles && i === 1) {
        drawActiveCrosshair(sprite, handlePoints[i], scale);
      } else {
        drawInactiveCrosshair(sprite, handlePoints[i], scale);
      }
    }

    // Respond to mouse events on the handles?
    if (measurement.state === ELLIPSE_STATE.Active && onMouseDown) {
      const ellipseHandles = [
        ELLIPSE_STATE.HandleTopLeft,
        ELLIPSE_STATE.HandleTopRight,
        ELLIPSE_STATE.HandleBottomLeft,
        ELLIPSE_STATE.HandleBottomRight,
      ];
      for (let i = 0; i < handlePoints.length; i++) {
        sprite.addChild(
          createHitCircle(
            handlePoints[i],
            MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale,
            measurement.measurementId,
            ellipseHandles[i],
            onMouseDown
          )
        );
      }
    }
  }

  // Draw the ellipse.
  const ellipseSprite = new PIXI.Graphics() as PIXI.Graphics;
  const shadowSprite = new PIXI.Graphics() as PIXI.Graphics;

  // Solid line.
  if (measurement.state === ELLIPSE_STATE.Inactive) {
    setLineStyle(ellipseSprite, false, true, scale);
    setLineStyle(shadowSprite, true, true, scale);
  }

  // We want an even integer number of nodes.
  const nodeCount = Math.ceil(Math.max(radius.x, radius.y) * scale) & ~1;
  const anglePerNode = (2 * Math.PI) / nodeCount;
  for (let i = 0; i <= nodeCount; i++) {
    const angle = i * anglePerNode;
    const pos: XYCoords = {
      x: midPoint.x + radius.x * Math.sin(angle),
      y: midPoint.y + radius.y * Math.cos(angle),
    };

    // Dashed line.
    if (measurement.state !== ELLIPSE_STATE.Inactive) {
      setLineStyle(ellipseSprite, i % 2 === 0, false, scale);

      if (i === 0) {
        ellipseSprite.moveTo(pos.x, pos.y);
      } else {
        ellipseSprite.lineTo(pos.x, pos.y);
      }
    }
    // Solid line with shadow.
    else {
      if (i === 0) {
        ellipseSprite.moveTo(pos.x, pos.y);
        shadowSprite.moveTo(pos.x, pos.y);
      } else {
        ellipseSprite.lineTo(pos.x, pos.y);
        shadowSprite.lineTo(pos.x, pos.y);
      }
    }
  }
  if (measurement.state === ELLIPSE_STATE.Inactive) {
    sprite.addChild(shadowSprite);
  }
  sprite.addChild(ellipseSprite);

  // Respond to mouse events on the ellipse?
  if ((measurement.state === ELLIPSE_STATE.Active || measurement.state === ELLIPSE_STATE.Inactive) && onMouseDown) {
    sprite.addChild(
      createHitEllipse(
        midPoint,
        radius,
        MEASUREMENT_SETTINGS.HIT_AREA_BUFFER_PX / scale,
        measurement.measurementId,
        ELLIPSE_STATE.Moving,
        onMouseDown
      )
    );
  }

  return sprite;
};

/**
 * Change the active handle on the ellipse to the one specified and return any changes that are required.
 */
export const setEllipseActiveHandle = (measurement: Measurement, handle: ELLIPSE_STATE): Partial<Measurement> => {
  const result: Partial<Measurement> = {};

  const topLeft: XYCoords = {
    x: Math.min(measurement.startPoint.x, measurement.endPoint.x),
    y: Math.min(measurement.startPoint.y, measurement.endPoint.y),
  };
  const bottomRight: XYCoords = {
    x: Math.max(measurement.startPoint.x, measurement.endPoint.x),
    y: Math.max(measurement.startPoint.y, measurement.endPoint.y),
  };
  // Swicth the sprite startPoint and endPoint so that the start is the anchor and the end is the handle position.
  switch (handle) {
    case ELLIPSE_STATE.HandleTopLeft:
      result.startPoint = bottomRight;
      result.endPoint = topLeft;
      break;
    case ELLIPSE_STATE.HandleTopRight:
      result.startPoint = { x: topLeft.x, y: bottomRight.y };
      result.endPoint = { x: bottomRight.x, y: topLeft.y };
      break;
    case ELLIPSE_STATE.HandleBottomLeft:
      result.startPoint = { x: bottomRight.x, y: topLeft.y };
      result.endPoint = { x: topLeft.x, y: bottomRight.y };
      break;
    case ELLIPSE_STATE.HandleBottomRight:
      result.startPoint = topLeft;
      result.endPoint = bottomRight;
      break;
  }
  return result;
};

/**
 * Create a new PIXI sprite to show the details of the the ellipse measurement.
 */
export const createNewEllipseMarker = ({ measurement, scale, bounds }: CreateNewMarkerInterface): PIXI.Graphics => {
  // Draw the info label attached 1/2 way down the right side of the ellipse.
  return createMarkerSprite({
    type: TOOL_TYPES.Ellipse,
    label: `${measurement.area?.toFixed(2)} mm²`,
    points: [{ ...measurement.startPoint }, { ...measurement.endPoint }],
    bounds,
    scale,
    showLine: [ELLIPSE_STATE.Active, ELLIPSE_STATE.Inactive].includes(measurement.state as ELLIPSE_STATE),
    huData: measurement.huData,
  });
};

/**
 * Calculate the area (in mm^2) for an ellipse measurement.
 */
export const calculateEllipseArea = (measurement: Measurement, millimeterSpacing: number): number => {
  const radiusX: number = 0.5 * Math.abs(measurement.endPoint.x - measurement.startPoint.x) * millimeterSpacing;
  const radiusY: number = 0.5 * Math.abs(measurement.endPoint.y - measurement.startPoint.y) * millimeterSpacing;
  return getAreaOfEllipse(radiusX, radiusY);
};
