import React, { ReactElement, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useAppDispatch } from '../../hooks';
import { reportActions } from '../../reducers/report/reportSlice';
import { useDraftStudyStats, useStudyStats } from '../../selectors/reporting';
import { useStudySeriesInfo, SeriesStatus } from '../../selectors/study';
import { CadRadsStat } from './CadRadsStat';
import { CalciumScoreStats } from './CalciumScoreStats';
import { MaximumStenosisStat } from './MaximumStenosisStat';
import { SisDataStat } from './SisDataStat';
import { VulnerablePlaque } from './VulnerablePlaque';

interface Props {
  editingReport: boolean;
  highlightKeyVesselRelatedFields: boolean;
}

export default function SummaryField({ highlightKeyVesselRelatedFields, editingReport }: Props): ReactElement<Props> {
  const dispatch = useAppDispatch();
  const studyStats = useStudyStats();
  const draftStudyStats = useDraftStudyStats();

  const studySeriesInfo = useStudySeriesInfo();
  const cadradsAndSisEditable = useMemo(() => {
    return studySeriesInfo?.contrast === SeriesStatus.Fail;
  }, [studySeriesInfo?.contrast]);

  const handleUpdateReportStats = useCallback(
    (stat: string, newValue: string) => {
      dispatch(reportActions.updateDraftReport({ [stat]: newValue }));
    },
    [dispatch]
  );

  const reportObject = useMemo(() => {
    return editingReport ? draftStudyStats : studyStats;
  }, [editingReport, studyStats, draftStudyStats]);

  return (
    <div
      className={cn('report-field summary-field', {
        'report-field--highlighted': highlightKeyVesselRelatedFields,
      })}
    >
      <div className="report-field__container">
        <h3 className="report-field__heading">Summary</h3>
        <div className="report-field__field">
          <dl className="value-list summary-field__value-list">
            {reportObject?.calcium_score && <CalciumScoreStats calciumStat={reportObject.calcium_score} />}
            {reportObject?.maximum_stenosis && (
              <MaximumStenosisStat
                stats={reportObject.maximum_stenosis}
                editingReport={editingReport}
                handleUpdateReportStats={handleUpdateReportStats}
              />
            )}
            {reportObject?.vulnerable_plaque && (
              <VulnerablePlaque
                stats={reportObject.vulnerable_plaque}
                editingReport={editingReport}
                handleUpdateReportStats={handleUpdateReportStats}
              />
            )}
            {reportObject?.cad_rads && (
              <CadRadsStat
                stats={reportObject.cad_rads}
                editingReport={editingReport}
                handleUpdateReportStats={handleUpdateReportStats}
                editable={cadradsAndSisEditable}
              />
            )}
            {reportObject?.sis_data && (
              <SisDataStat
                label={reportObject.sis_data.label}
                value={`${reportObject.sis_data.value}`}
                editingReport={editingReport}
                handleUpdateReportStats={handleUpdateReportStats}
                editable={cadradsAndSisEditable}
              />
            )}
          </dl>
        </div>
      </div>
    </div>
  );
}
