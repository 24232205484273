import React, { ReactElement, useCallback } from 'react';
import { EditFindings } from '../EditFindings/EditFindings';
import { TextBlock } from '../TextBlock/TextBlock';

interface Props {
  editMode: boolean;
  // If a bullet point per new line is desirable, pass in an array of strings
  // for each line. If a single block/paragraph is desirable, pass in an array with
  // all text in a single element.
  text: string[];
  updateDraftText: (report: string) => void;
  draftText: string | undefined;
  autoFocus?: boolean;
}

export default function EditableTextField({
  editMode,
  text,
  updateDraftText,
  draftText,
  autoFocus = false,
}: Props): ReactElement<Props> {
  const updateText = useCallback(
    (value: string) => {
      updateDraftText(value);
    },
    [updateDraftText]
  );

  return (
    <>
      {editMode ? (
        <EditFindings
          forceEdit={true}
          savedValue={draftText ?? ''}
          onUpdateFindings={updateText}
          autoFocus={autoFocus}
        />
      ) : (
        <TextBlock text={text} inline />
      )}
    </>
  );
}
