import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Study } from '../../context/types';
import { DashboardSessionStorage } from './utils';

export interface DashboardState {
  studies: Record<string, Study> | null;
  decryptDashboardData: Record<string, Study> | null;
  dashboardCurrentPage: number;
  dashboardSortKey: string;
  dashboardAscending: boolean;
  dashboardSearchStr: string;
  decryptedStudies: string[];
}

// Some of the initial state is hydrated from sessionStorage.
// It it written to sessionStoage in useDashboardSessionStorage().
export const initialDashboardState = (): DashboardState => {
  return {
    studies: null,
    decryptDashboardData: null,
    dashboardCurrentPage: Number(sessionStorage.getItem(DashboardSessionStorage.DASH_CURRENT_PAGE) || 1),
    dashboardSortKey: sessionStorage.getItem(DashboardSessionStorage.DASH_SORT_KEY) || 'last_scan',
    dashboardAscending: sessionStorage.getItem(DashboardSessionStorage.DASH_ASCENDING) === 'true' ? true : false,
    dashboardSearchStr: sessionStorage.getItem(DashboardSessionStorage.DASH_SEARCH_STRING) || '',
    decryptedStudies: [],
  };
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState(),
  reducers: {
    setStudies: (state, action: PayloadAction<Record<string, Study>>) => {
      state.studies = action.payload;
    },
    // Update a single study (by studyId) with one or more changes (provided as a partial Study).
    updateStudy: (state, action: PayloadAction<{ studyId: string; changes: Partial<Study> }>) => {
      const study = state.studies?.[action.payload.studyId];
      if (study) {
        Object.assign(study, action.payload.changes);
      }
    },
    setDecryptDashboardData: (state, action: PayloadAction<Record<string, Study> | null>) => {
      state.decryptDashboardData = action.payload;
    },
    setDashboardCurrentPage: (state, action: PayloadAction<number>) => {
      state.dashboardCurrentPage = action.payload;
    },
    setDashboardSortKey: (state, action: PayloadAction<string>) => {
      state.dashboardSortKey = action.payload;
    },
    setDashboardAscending: (state, action: PayloadAction<boolean>) => {
      state.dashboardAscending = action.payload;
    },
    setDashboardSearchStr: (state, action: PayloadAction<string>) => {
      state.dashboardSearchStr = action.payload;
    },
    setDecryptedStudies: (state, action: PayloadAction<string[]>) => {
      state.decryptedStudies = action.payload;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;
