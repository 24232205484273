import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { STENOSIS_RISK_LEVEL_MAPPING } from '../config';
import { CoronaryFindings, heartDominance, PatientInlineStats, ProcedureDetails } from '../context/types';
import { useAppSelector } from '../hooks';
import { currentStudySelector } from './study';

/**
 * Whether or not the calcium score should be editable in the report.
 */
export function useCalciumScoreEditable() {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(() => {
    if (!currentReport) {
      return false;
    }

    const isAIGenerated = currentReport.ai_generated_fields.includes('calcium_score');

    // We want the field to be editable if there's no calcium score currently set, or if it has been edited before.
    return (currentReport.calcium_score === null && isAIGenerated) || !isAIGenerated;
  }, [currentReport]);
}

export function useImpressionsSelector() {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(() => currentReport?.impression ?? [], [currentReport]);
}

export function useExtraCardiacFindings() {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(() => currentReport?.extra_cardiac_findings ?? '', [currentReport]);
}

export function useImpressionIsAiAssessedSelector() {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(() => !!currentReport?.ai_generated_fields?.find((x) => x === 'impression'), [currentReport]);
}

interface CoronaryFindingIsAiAssessed {
  LAD: boolean;
  LM: boolean;
  LCX: boolean;
  RCA: boolean;
}

export function useCoronaryFindingIsAiAssessedSelector(): CoronaryFindingIsAiAssessed {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(
    (): CoronaryFindingIsAiAssessed => ({
      LAD: !!currentReport?.ai_generated_fields.find((x) => x === 'lad_coronary_findings'),
      LM: !!currentReport?.ai_generated_fields.find((x) => x === 'lm_coronary_findings'),
      LCX: !!currentReport?.ai_generated_fields.find((x) => x === 'lcx_coronary_findings'),
      RCA: !!currentReport?.ai_generated_fields.find((x) => x === 'rca_coronary_findings'),
    }),
    [currentReport]
  );
}

export function useDraftImpressionsSelector() {
  const draftReport = useAppSelector((state) => state.report.draft);

  return useMemo(() => {
    return draftReport?.impression?.length === 0
      ? ''
      : draftReport?.impression?.reduce((x, y) => x + '\n' + y) ?? undefined;
  }, [draftReport]);
}

export function useInlineReportingDraftImpressionsSelector() {
  const impressions = useAppSelector((state) => state.inlineReporting.impressions);
  return useMemo(() => {
    return impressions?.length === 0 ? '' : impressions?.reduce((x, y) => x + '\n' + y) ?? undefined;
  }, [impressions]);
}

export function useClinicalIndications() {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(() => currentReport?.clinical_indication ?? '', [currentReport]);
}

export function useDraftClinicalIndications() {
  const draftReport = useAppSelector((state) => state.report.draft);

  return useMemo(() => draftReport?.clinical_indication ?? '', [draftReport]);
}

export function useCoronaryFindingsSelector() {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(
    (): CoronaryFindings => ({
      LAD: currentReport?.lad_coronary_findings,
      LCX: currentReport?.lcx_coronary_findings,
      LM: currentReport?.lm_coronary_findings,
      RCA: currentReport?.rca_coronary_findings,
    }),
    [
      currentReport?.lad_coronary_findings,
      currentReport?.lcx_coronary_findings,
      currentReport?.lm_coronary_findings,
      currentReport?.rca_coronary_findings,
    ]
  );
}

export function useDraftCoronaryFindingsSelector() {
  const draftReport = useAppSelector((state) => state.report.draft);

  return useMemo(
    (): CoronaryFindings => ({
      LAD: draftReport?.lad_coronary_findings,
      LCX: draftReport?.lcx_coronary_findings,
      LM: draftReport?.lm_coronary_findings,
      RCA: draftReport?.rca_coronary_findings,
    }),
    [draftReport]
  );
}

export function useHeartDominanceSelector() {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(
    (): heartDominance => ({
      heart_dominance: currentReport?.heart_dominance || null,
    }),
    [currentReport?.heart_dominance]
  );
}

export function useDraftHeartDominanceSelector() {
  const draftReport = useAppSelector((state) => state.report.draft);

  return useMemo(
    (): heartDominance => ({
      heart_dominance: draftReport?.heart_dominance || null,
    }),
    [draftReport]
  );
}

export const STATS_LABEL_MAPPING = {
  calcium_score: 'Calcium Score\n(MESA)',
  maximum_stenosis: 'Maximum\nStenosis',
  vulnerable_plaque: 'Vulnerable\nPlaque',
  cad_rads: 'CAD-RADS',
  sis_data: 'Segment Involvement Score',
};

export function useStudyStats(): PatientInlineStats | undefined {
  const studyData = useAppSelector((state) => state.study.studyData);
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo(() => {
    if (currentReport) {
      const {
        calcium_score: calciumScore,
        maximum_stenosis,
        vulnerable_plaque,
        cad_rads,
        sis_data,
        ethnicity,
        mesa_percentile,
      } = currentReport;

      return {
        calcium_score: {
          value:
            calciumScore == null || calciumScore.total === null ? '-' : Math.round(parseFloat(`${calciumScore.total}`)),
          label: STATS_LABEL_MAPPING['calcium_score'],
          sex: studyData?.patient_data?.sex ?? 'O',
          age: studyData?.patient_data?.age ?? '',
          ethnicity,
          mesaPercentile: mesa_percentile,
        },
        maximum_stenosis: {
          value: maximum_stenosis,
          label: STATS_LABEL_MAPPING['maximum_stenosis'],
          riskLevel: STENOSIS_RISK_LEVEL_MAPPING[maximum_stenosis.split(' ')[0]],
        },
        vulnerable_plaque: {
          value: vulnerable_plaque,
          label: STATS_LABEL_MAPPING['vulnerable_plaque'],
        },
        cad_rads: {
          value: cad_rads,
          label: STATS_LABEL_MAPPING['cad_rads'],
        },
        sis_data: { value: sis_data, label: STATS_LABEL_MAPPING['sis_data'] },
      };
    }

    return undefined;
  }, [currentReport, studyData]);
}

export function useDraftStudyStats(): PatientInlineStats | undefined {
  const studyData = useAppSelector((state) => state.study.studyData);
  const draftReport = useAppSelector((state) => state.report.draft);

  return useMemo(() => {
    if (draftReport) {
      const {
        calcium_score = null,
        maximum_stenosis = '',
        vulnerable_plaque = '',
        cad_rads = '',
        sis_data = '',
        ethnicity = 'hispanic',
        mesa_percentile,
      } = draftReport;

      return {
        calcium_score: {
          value:
            calcium_score === null || calcium_score.total === null
              ? '-'
              : Math.round(parseFloat(`${calcium_score.total}`)),
          label: STATS_LABEL_MAPPING['calcium_score'],
          sex: studyData?.patient_data?.sex ?? 'O',
          age: studyData?.patient_data?.age ?? '',
          ethnicity,
          mesaPercentile: mesa_percentile ?? '',
        },
        maximum_stenosis: {
          value: maximum_stenosis,
          label: STATS_LABEL_MAPPING['maximum_stenosis'],
          riskLevel: STENOSIS_RISK_LEVEL_MAPPING[maximum_stenosis.split(' ')[0]],
        },
        vulnerable_plaque: {
          value: vulnerable_plaque,
          label: STATS_LABEL_MAPPING['vulnerable_plaque'],
        },
        cad_rads: {
          value: cad_rads,
          label: STATS_LABEL_MAPPING['cad_rads'],
        },
        sis_data: { value: sis_data, label: STATS_LABEL_MAPPING['sis_data'] },
      };
    }

    return undefined;
  }, [draftReport, studyData]);
}

export function useProcedureDetails() {
  const currentReport = useAppSelector((state) => state.report.current);

  return useMemo((): ProcedureDetails | undefined => {
    if (currentReport) {
      const {
        scan_quality,
        heart_rate,
        medications,
        prior_cardiac_procedures,
        kvp,
        contrast_volume,
        dlp,
        contrast_media,
        acquisition_mode,
      } = currentReport;
      return {
        scan_quality,
        heart_rate,
        medications,
        prior_cardiac_procedures,
        kvp,
        contrast_volume,
        dlp,
        contrast_media,
        acquisition_mode,
      };
    }
    return undefined;
  }, [currentReport]);
}

export function useDraftProcedureDetails() {
  const draftReport = useAppSelector((state) => state.report.draft);

  return useMemo((): ProcedureDetails | undefined => {
    if (draftReport) {
      const {
        scan_quality = '',
        heart_rate = 0,
        medications = null,
        prior_cardiac_procedures = '',
        kvp = 0,
        contrast_volume = 0,
        dlp = 0,
        contrast_media = '',
        acquisition_mode = '',
      } = draftReport;
      return {
        scan_quality,
        heart_rate,
        medications,
        prior_cardiac_procedures,
        kvp,
        contrast_volume,
        dlp,
        contrast_media,
        acquisition_mode,
      };
    }
    return undefined;
  }, [draftReport]);
}

/**
 * Check if we have inline report unsaved impressions
 */
export function useInlineReportingImpressionsHasChanges() {
  const state = useAppSelector((state) => state.inlineReporting);
  const impressions = useImpressionsSelector();

  return useMemo(() => {
    if (state.impressions === undefined) return false;
    return !isEqual(impressions, state.impressions);
  }, [state.impressions, impressions]);
}

/**
 * Check if we have inline report unsaved coronary findings changes on any of the vessels
 */
export function useInlineReportingCoronanaryHasAnyChanges() {
  const coronaryFindings = useCoronaryFindingsSelector();
  const state = useAppSelector((state) => state.inlineReporting);

  return useMemo(() => {
    const { lad_coronary_findings, lcx_coronary_findings, lm_coronary_findings, rca_coronary_findings } = state;

    return (
      (lad_coronary_findings !== undefined && lad_coronary_findings !== coronaryFindings.LAD) ||
      (lcx_coronary_findings !== undefined && lcx_coronary_findings !== coronaryFindings.LCX) ||
      (lm_coronary_findings !== undefined && lm_coronary_findings !== coronaryFindings.LM) ||
      (rca_coronary_findings !== undefined && rca_coronary_findings !== coronaryFindings.RCA)
    );
  }, [coronaryFindings.LAD, coronaryFindings.LCX, coronaryFindings.LM, coronaryFindings.RCA, state]);
}

/**
 * Check if we have any inline reporting unsaved changes
 */
export function useAnyInlineReportingChangesMade() {
  const coronaryHasChanges = useInlineReportingCoronanaryHasAnyChanges();
  const impressionsHasChanges = useInlineReportingImpressionsHasChanges();
  return useMemo(() => coronaryHasChanges || impressionsHasChanges, [coronaryHasChanges, impressionsHasChanges]);
}

const FIVE_MINUTES_IN_MS = 300000;

export function useShouldShowAmendmentModal() {
  const selectedStudy = useAppSelector(currentStudySelector);

  if (selectedStudy) {
    const { is_report_approved, report_approval } = selectedStudy;

    if (is_report_approved && report_approval) {
      const approveDate = new Date(report_approval.timestamp);

      const difference = new Date().getTime() - approveDate.getTime();
      return difference >= FIVE_MINUTES_IN_MS;
    }
  }

  return false;
}
