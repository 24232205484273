import React from 'react';
import { CommandBar } from '../CommandBar';
import Toggle from '../../../components/Toggle/Toggle';
import Select from '../../../components/reusable/SelectNew/SelectNew';
import MeasurementToolbar from '../../../components/measurementToolbar/MeasurementToolbar';
import { useSetSelectedVesselSelector, useVesselStateSelector } from '../../../selectors/vessels';
import { useDashboardSelector } from '../../../dashboardHooks';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { globalFlagsActions } from '../../../reducers/global-flags/globalFlagsSlice';

export const PatientCommandBar = () => {
  const dispatch = useAppDispatch();
  const { clientConfig } = useDashboardSelector((state) => state.user);
  const displayMeasurements = useAppSelector((state) => state.globalFlags.displayMeasurements);

  const { vessels, savingSelectedVessel, selectedVesselName } = useVesselStateSelector();

  const setSelectedVesselName = useSetSelectedVesselSelector();

  if (!selectedVesselName) return null;

  return (
    <CommandBar
      children={
        <>
          <div className="command-bar__column command-bar__column--left">
            {clientConfig?.measurement_enabled && (
              <div className="command-bar__measurement">
                <Toggle
                  name="measurement"
                  labelText="Measurements"
                  checked={displayMeasurements}
                  onChange={() => {
                    dispatch(globalFlagsActions.setDisplayMeasurements(!displayMeasurements));
                  }}
                ></Toggle>
                <MeasurementToolbar />
              </div>
            )}
          </div>
          <div className="command-bar__column command-bar__column--center">
            <div className="command-bar__vessel-selector">
              <Select
                options={vessels.map((vessel: string) => ({
                  value: vessel,
                  label: vessel.toUpperCase(),
                }))}
                label="Select a Vessel"
                leftLabel={true}
                value={vessels.includes(selectedVesselName) ? selectedVesselName : undefined}
                onChange={setSelectedVesselName}
                scrollable
                small
                // Block changing the vessel while an add or edit is still saving on the backend.
                // Swapping to another vessel would be fine but without per vessel data being cached locally we wouldn't be able
                // to go back to the newly added / edited vessel until the save operation is finished.
                disabled={savingSelectedVessel}
              />
            </div>
          </div>
          <div className="command-bar__column command-bar__column--right"></div>
        </>
      }
    />
  );
};
