import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { vesselDataActions } from './vesselDataSlice';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, useNeedsLoading } from '../initialLoad/utils';
import { cprVersionSelector } from '../../selectors/study';
import { KEY_CPR } from '../../config';
import { Shape, VesselDataResponse, AuxAnnos, CPRVesselData, TRange, WallPolygons } from './types';
import {
  shapeV1ToShape,
  getCPRVersion,
  auxAnnosToXYCoordsAuxAnnos,
  fetchShape,
  fiterAuxAnnos,
  getCenterlineMapping,
  lineArrayToXYCoords,
} from '../../components/CPRViewer/Utils';
import { LineArray, PointArray } from '../../types/common';
import { createImageBufferFromDataset, ImageBuffer } from '../../components/WebGLViewer/Utils';
import * as api from '../../utils/api';

const loaderName = InitialLoader.VESSEL_VIEWER_DATA;

export const VIEWER_TYPES = ['short-axis', 'long-axis', 'ct-noncontrast', 'cpr'];
export type ViewerTypes = typeof VIEWER_TYPES[number];

/**
 * Get the uri path name for the specified viewer type.
 */
export function getVolumeDir(vessel: string, dataType: ViewerTypes, cprVersion: number = 1) {
  switch (dataType) {
    case 'ct-noncontrast':
      return 'volume-non-contrast';
    case 'short-axis':
    case 'long-axis':
      return `vessel/${vessel}/mpr/${dataType}`;
    case 'cpr':
      return `vessel/${vessel}${getCPRVersion(cprVersion)}`;
    default:
      return '';
  }
}

/**
 * Fetch the shape (ie image count and size info for the specified vessel and viewerType.
 */
export function fetchViewerShape(
  versionHead: string | undefined,
  patientID: string,
  runID: string,
  vesselID: string,
  viewerType: ViewerTypes
): Promise<Shape> {
  const endPoint = `data/${patientID}/${runID}/${getVolumeDir(vesselID, viewerType)}`;
  return api
    .getJSON(`${endPoint}/shape?version=${versionHead}`, false, {
      group_type: viewerType,
    })
    .then((result) => {
      return shapeV1ToShape(result);
    });
}

/**
 * Fetch the specified dataName CPR data for the specifed slice number.
 * "cl-2d" returns a LineArray
 * "aux-annos" returns AuxAnnos
 * "cl-t-vals" returns PointArray
 */
function fetchSliceData(
  patientID: string,
  runID: string,
  vesselID: string,
  cprVersion: number,
  versionHead: string,
  key: string,
  sliceIndex: number,
  dataName: 'cl-2d' | 'aux-annos' | 'cl-t-vals'
): Promise<LineArray | AuxAnnos | PointArray> {
  return new Promise((resolve, reject) => {
    const idPrefix = `data/${patientID}/${runID}/vessel/${vesselID}`;
    const endpoint = `${idPrefix}${getCPRVersion(cprVersion)}/${dataName}/${sliceIndex}?version=${versionHead}`;
    api
      .getJSON(endpoint, false, {
        patient_id: patientID,
        run_id: runID,
        group_type: key,
      })
      .then((data: LineArray | AuxAnnos | PointArray) => {
        if (data) {
          resolve(data);
        } else {
          console.error(data);
          reject(data);
        }
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

/**
 * Fetch the slice data from the backend for the specifed slice number.
 * @return [centerline LineArray, AuxAnnos lines, tArray number[]]
 */
function fetchCPRSliceData(
  patientID: string,
  runID: string,
  vesselID: string,
  cprVersion: number,
  versionHead: string,
  key: string,
  sliceIndex: number
): Promise<[LineArray, AuxAnnos, number[]]> {
  return Promise.all([
    fetchSliceData(patientID, runID, vesselID, cprVersion, versionHead, key, sliceIndex, 'cl-2d') as Promise<LineArray>,
    fetchSliceData(patientID, runID, vesselID, cprVersion, versionHead, key, sliceIndex, 'aux-annos') as Promise<
      AuxAnnos
    >,
    fetchSliceData(patientID, runID, vesselID, cprVersion, versionHead, key, sliceIndex, 'cl-t-vals') as Promise<
      number[]
    >,
  ]);
}

/**
 * Fetch the specified dataName tRange data for the vessel.
 */
function fetchTRange(
  dataName: 'cl-t-range-vessel',
  cprVersion: number,
  patientID: string,
  runID: string,
  versionHead: string,
  vesselID: string,
  key: string
): Promise<TRange> {
  return new Promise((resolve, reject) => {
    if (cprVersion === 1) {
      const cprV1Data: TRange = [0, 1];
      resolve(cprV1Data);
    }
    const idPrefix = `data/${patientID}/${runID}/vessel/${vesselID}`;
    const endpoint = `${idPrefix}${getCPRVersion(cprVersion)}/${dataName}?version=${versionHead}`;
    api
      .getJSON(endpoint, false, {
        patient_id: patientID,
        run_id: runID,
        group_type: key,
      })
      .then((data: TRange) => {
        if (data) {
          resolve(data);
        } else {
          // If AI analysis failed this may return a null result, it's not a fatal error.
          console.warn('fetchTRange returned an empty result');
          resolve([0, 1]);
        }
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

function fetchCPRVesselData(
  patientID: string,
  runID: string,
  vesselID: string,
  cprVersion: number,
  versionHead: string,
  viewKey: string,
  // The number of slices along the the vessel - which we need to calculate the centerlineMapping.
  sliceCount: number,
  dispatch: AppDispatch
): Promise<any> {
  // Fetch the number of slice images and their size.
  const shapePromise = fetchShape(
    `data/${patientID}/${runID}/vessel/${vesselID}${getCPRVersion(cprVersion)}`,
    cprVersion,
    versionHead,
    viewKey
  );

  // Fetch the T-range for this vessel
  const tRangePromise = fetchTRange('cl-t-range-vessel', cprVersion, patientID, runID, versionHead, vesselID, viewKey);

  // Fetch the number of slices and tRange first.
  return Promise.all([shapePromise, tRangePromise]).then(([shape, tRange]) => {
    const promises: Promise<[LineArray, AuxAnnos, number[]]>[] = [];
    // Then load the centerline and aux centerline data for all the slices.
    for (let sliceIndex = 0; sliceIndex < shape.length; sliceIndex++) {
      promises.push(fetchCPRSliceData(patientID, runID, vesselID, cprVersion, versionHead, viewKey, sliceIndex));
    }
    return Promise.all(promises).then((results) => {
      // Set the annotation data for the vessel.
      const cprVesselData: CPRVesselData = {
        tRange,
        shape,
        sliceData: results.map((cprSliceData) => {
          return {
            anno: lineArrayToXYCoords(cprSliceData[0]),
            auxAnno: fiterAuxAnnos(auxAnnosToXYCoordsAuxAnnos(cprSliceData[1]), vesselID),
            tArray: cprSliceData[2],
            // Create a usable centerline mapping from the tArray.
            centerlineMapping: getCenterlineMapping(sliceCount, cprSliceData[2], tRange, cprSliceData[0].length),
          };
        }),
        imageBufferData: undefined,
      };
      dispatch(vesselDataActions.updateCPRVesselDataForVessel({ vesselID, cprVesselData: cprVesselData }));
    });
  });
}

/**
 * Fetch the short axis annotations for the specified vessel and return the lumen and outer wall data in the results.
 */
function fetchShortAxisAnnotations(
  versionHead: string | undefined,
  patientID: string,
  runID: string,
  vesselID: string
): Promise<{ lumen: WallPolygons; outer: WallPolygons }> {
  const idPrefix = `data/${patientID}/${runID}/vessel/${vesselID}`;
  const requests = ['mpr/polygons/lumen', 'mpr/polygons/outer'];
  const promises = requests.map((request) => api.getJSON(`${idPrefix}/${request}/all?version=${versionHead}`));
  return Promise.all(promises).then((results) => {
    return {
      lumen: results[0]?.view_idx || [],
      outer: results[1]?.view_idx || [],
    };
  });
}

/**
 * Request the image dataset for the specified image and convert it into an ImageBuffer.
 */
export function fetchImageBuffer(
  sliceIndex: number,
  endPoint: string,
  versionHead: string | undefined,
  viewType: string,
  transpose?: boolean
): Promise<ImageBuffer> {
  return new Promise((resolve, reject) => {
    api
      .getH5(`${endPoint}/image/${sliceIndex}?version=${versionHead}`, false, {
        group_type: viewType,
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
      .then((file) => {
        const sliceData = file ? file.get('data') : undefined;
        if (!sliceData) {
          const error = `Error: no slice data found in file.`;
          console.error(error);
          reject(error);
        } else {
          const imageBuffer = createImageBufferFromDataset(sliceData, transpose || false);
          if (!imageBuffer) return reject('No slice data available');
          resolve(imageBuffer);
        }
      });
  });
}

/**
 * Load the vessel viewer data from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function loadVesselViewerData(
  unifiedId: UnifiedId,
  cprVersion: number,
  vesselData: VesselDataResponse,
  dispatch: AppDispatch,
  initialLoad: boolean
) {
  const promises: Promise<any>[] = [];

  // Load the vessel viewer data for each vessel.
  Object.keys(vesselData).forEach((vesselId) => {
    // Fetch the CPR data.
    promises.push(
      fetchCPRVesselData(
        unifiedId.patientId,
        unifiedId.runId,
        vesselId,
        cprVersion,
        unifiedId.version,
        KEY_CPR,
        vesselData[vesselId].n_slices ?? 0,
        dispatch
      )
    );

    // Fetch the short axis shape and wall data.
    promises.push(
      Promise.all([
        fetchViewerShape(unifiedId.version, unifiedId.patientId, unifiedId.runId, vesselId, 'short-axis'),
        fetchShortAxisAnnotations(unifiedId.version, unifiedId.patientId, unifiedId.runId, vesselId),
      ]).then((result) => {
        dispatch(
          vesselDataActions.updateShortAxisVesselData({
            vesselID: vesselId,
            shape: result[0],
            lumen: result[1].lumen,
            outer: result[1].outer,
          })
        );
      })
    );

    // Fetch the long axis shape data.
    promises.push(
      fetchViewerShape(unifiedId.version, unifiedId.patientId, unifiedId.runId, vesselId, 'long-axis').then((shape) => {
        dispatch(vesselDataActions.updateLongAxisShapeData({ vesselID: vesselId, shape }));
      })
    );
  });

  // Wait for all the fetches to finish.
  loadWithPromise(Promise.all(promises), dispatch, initialLoad, loaderName, () => {});
}

/**
 * A component to load the initial vessel viewer data for a study and add it to the store.
 */
export const VesselViewerDataLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useNeedsLoading(loaderName);
  const cprVersion = useAppSelector(cprVersionSelector);
  const vesselData = useAppSelector((state) => state.vesselData.vesselData);

  useEffect(() => {
    // We need several bits of other data before the vessel viewer data can be loaded.
    if (unifiedId && needsLoading && vesselData != null) {
      loadVesselViewerData(unifiedId, cprVersion, vesselData, dispatch, true);
    }
  }, [unifiedId, needsLoading, cprVersion, vesselData, dispatch]);

  return null;
};
