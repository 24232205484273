import { LVMassIndex } from '../../components/LVMassIndex/LVMassIndex';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Loader } from '../../components/Loader/Loader';
import { Stats } from '../../components/Stats/Stats';
import { STENOSIS_RISK_LEVEL_MAPPING } from '../../config';
import { getScanDate } from '../../utils/shared';
import OverallMeasurements from '../../components/OverallMeasurements/OverallMeasurements';
import { useAppSelector } from '../../hooks';
import { currentStudySelector } from '../../selectors/study';

export interface PatientSummaryProps {
  loading?: boolean;
}

export const PatientSummary: React.FunctionComponent<PatientSummaryProps> = ({ loading = false }) => {
  const selectedStudy = useAppSelector(currentStudySelector);
  const displayMeasurements = useAppSelector((state) => state.globalFlags.displayMeasurements);
  const patientStats = useAppSelector((state) => state.patient.patientStats);
  const currentReport = useAppSelector((state) => state.report.current);

  const [stats, setStats] = useState([]) as any;

  useEffect(() => {
    const {
      calcium_score: calciumScore,
      maximum_stenosis: maxStenosis,
      priority_vessel: priorityVessel,
      cad_rads: cadRads,
      sis,
    } = patientStats || {};

    let riskLevel = null;
    if (maxStenosis) {
      riskLevel = STENOSIS_RISK_LEVEL_MAPPING[maxStenosis.split(' ')[0]];
    }

    let priorityVesselVal = priorityVessel;
    // check for no disease, where there should be no priorityVessel
    if (!calciumScore && cadRads?.match(/^0(\/.*|$)/)) {
      priorityVesselVal = undefined;
    }

    setStats([
      {
        label: 'Calcium Score (MESA)',
        value: Math.round(parseFloat(`${calciumScore}`)),
        subtext: currentReport?.mesa_percentile ? `(${currentReport?.mesa_percentile}%)` : '',
        show: true,
      },
      {
        label: 'Maximum Stenosis',
        value: maxStenosis,
        riskLevel,
        show: true,
      },
      {
        label: 'Priority Vessel',
        value: priorityVesselVal ?? 'N/A',
        show: true,
      },
      {
        label: 'Vulnerable Plaque',
        value: (currentReport?.vulnerable_plaque ?? '') || 'None',
        show: true,
      },
      {
        label: 'Segment Involvement Score',
        value: sis,
        show: true,
      },
      {
        label: 'CAD-RADS',
        value: cadRads,
        show: true,
      },
    ]);
  }, [displayMeasurements, patientStats, currentReport?.vulnerable_plaque, currentReport?.mesa_percentile]);

  return (
    <>
      {selectedStudy && (
        <div className="patient-overview-section patient-summary">
          <h3
            className={cn('patient-summary__header', {
              'patient-summary__header--loading': loading,
            })}
          >
            {selectedStudy.patient_plaintext ? `${selectedStudy.patient_name}` : `${selectedStudy.study_id}`}

            {loading && <Loader inline />}
          </h3>

          <Stats data={stats} birthDate={selectedStudy?.date_of_birth} scanDate={getScanDate(selectedStudy)} />
          {displayMeasurements && (
            <>
              <OverallMeasurements />
              <LVMassIndex />
            </>
          )}
        </div>
      )}
    </>
  );
};
