import React, { ReactElement } from 'react';
import { ContrastViewContent, ContrastViewType, ContrastVolumeViewProps } from '../../../context/contrast-types';
import { useAppSelector } from '../../../hooks';
import { useContrastAllViewPropsSelector } from '../../../hooks/useContrastViewHelpers';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { useContrastLoader } from './ContrastLoader';
import { ContrastView } from './ContrastView';
import { EmptyView } from './EmptyView';
import './initCornerstone.js';
import { useMPRLink } from './MPRLink';
import { MPRView } from './MPRView';
import { OuterView } from './OuterView';

interface ContrastViewerProps {
  viewProps: (ContrastVolumeViewProps | undefined)[];
  contrastViews: ContrastViewContent[];
  showMprView: boolean;
}

class ContrastViewer extends React.PureComponent<ContrastViewerProps> {
  /**
   * Helper function to get the ContrastViewType for the given view index.
   */
  getViewType = (viewIndex: number): ContrastViewType | undefined => {
    if (viewIndex >= 0 && viewIndex <= this.props.contrastViews.length) {
      return this.props.contrastViews[viewIndex]?.viewType;
    }
    return undefined;
  };

  renderView = (viewIndex: number) => {
    const { showMprView } = this.props;
    const viewType = this.getViewType(viewIndex);

    if (viewType === ContrastViewType.Empty) {
      return (
        <OuterView viewIndex={viewIndex}>
          <EmptyView viewport={viewIndex} />
        </OuterView>
      );
    } else if (viewType !== ContrastViewType.MPR) {
      return (
        <OuterView viewIndex={viewIndex}>
          <ContrastView viewIndex={viewIndex} />
        </OuterView>
      );
    } else {
      return (
        <OuterView
          viewIndex={viewIndex}
          // Allow overflow for dragging the inline report editing window
          overflowVisible
        >
          {showMprView && <MPRView viewIndex={viewIndex} />}
        </OuterView>
      );
    }
  };

  render() {
    const { contrastViews } = this.props;
    return (
      <div className="contrast-viewer">
        {contrastViews.length >= 1 && this.renderView(0)}
        {contrastViews.length >= 2 && this.renderView(1)}
        {contrastViews.length >= 3 && this.renderView(2)}
        {contrastViews.length >= 4 && this.renderView(3)}
      </div>
    );
  }
}

// Wraps the class in a functional component.
export default function ContrastViewerWrapper(): ReactElement | null {
  const { contrastViews } = useAppSelector((state) => state.contrast);
  const { vesselData } = useVesselStateSelector();

  const viewProps = useContrastAllViewPropsSelector();

  useContrastLoader();
  useMPRLink();

  return <ContrastViewer viewProps={viewProps} contrastViews={contrastViews} showMprView={vesselData ? true : false} />;
}
