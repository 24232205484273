import cn from 'classnames';
import * as intersects from 'intersects';
import { isEqual } from 'lodash';
import * as PIXI from 'pixi.js-legacy';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
/* Add centerline functionality has been intentionally removed from the current version.
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
*/
import { ReactComponent as VesselsIcon } from '../../assets/icons/vessels.svg';
import Confirm from '../../components/Confirm/Confirm';
import { THEME } from '../../config';
import {
  COLOR_CPR_CENTRELINE,
  COLOR_CPR_CENTRELINE_AUX,
  COLOR_CPR_CENTRELINE_NODE,
  COLOR_CPR_CENTRELINE_NODE_HIGHLIGHT,
  COLOR_CPR_LOW_FFR,
  COLOR_CPR_MARKER_COLOR_INDICATOR,
  CPR_SLICE_INDICATOR_BUFFER,
  FFR_TOLERANCE,
  KEY_CPR,
  MPR_SLICE_SPACING,
  MPR_SLICE_SPACING_UNIT,
} from '../../config';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { useDashboardSelector } from '../../dashboardHooks';
import { cprActions } from '../../reducers/cpr/cprSlice';
import { EditMode, FFRStatus, FFRVesselData, FFRVesselDataResponse, Marker } from '../../context/types';
import { WindowLevels } from '../../reducers/window/types';
import usePreviousValue from '../../hooks/use-previous-value';
import { CPRSliceData, CPRVesselData, XYCoords } from '../../reducers/vesselData/types';
import { useSetSelectedVesselSelector, useVesselStateSelector } from '../../selectors/vessels';
import { pixiCaptureMouse, PIXIPointerEvent } from '../../utils/captureMouse';
import { clampNumber, isCanvas, timeFuncFactory } from '../../utils/shared';
import Button from '../Button/Button';
import { ToolBar } from '../ToolBar/ToolBar';
import WebGLViewer from '../WebGLViewer/WebGLViewer';
import { OnReadyInf, RESIZE_MODE } from '../WebGLViewer/types';
import { AdjacentPoints } from './types';
import {
  calculateInterpolatedAdjacentPoints,
  createMeasurementMarkerSprite,
  createSliceIndicatorSprite,
  getDistanceBetweenPoints,
  getLineAngle,
  getInterpolatedCenterlinePoint,
  getNearestSliceIndex,
  getPerpendicularLine,
  INDICATOR_SETTINGS,
  MEASUREMENT_MARKER_SETTINGS,
} from './Utils';
import { vesselDataActions } from '../../reducers/vesselData/vesselDataSlice';
import { centerlineEditActions } from '../../reducers/centerline-edit/centerlineEditSlice';
import { LineArray, LineObject, ObjectArray, PointObject } from '../../types/common';

const CENTRELINE_SETTINGS = {
  COLOR: COLOR_CPR_CENTRELINE,
  AUX_COLOR: COLOR_CPR_CENTRELINE_AUX,
  WIDTH: 2,
  TENSION: 1,
  FFR_MARKER_OFFSET: 15,
  FFR_LOW_COLOR: COLOR_CPR_LOW_FFR,
  NODE_COLOR: COLOR_CPR_CENTRELINE_NODE,
  NODE_SELECTED_COLOR: COLOR_CPR_CENTRELINE_NODE_HIGHLIGHT,
  NODE_SIZE: 3,
  MARKER_COLOR: COLOR_CPR_MARKER_COLOR_INDICATOR,
  HIT_AREA_BUFFER_PX: 10,
  MIN_DISTANCE_BETWEEN_NODES: 20,
  NODE_ADDED_IF_ANGLE_GREATER_THAN: 0.3,
  DELETE_NODE_KEYS: ['Backspace'],
  SHADOW_OPACITY: 0.8,
  SHADOW_SIZE: 1,
  SHADOW_COLOR: THEME.colors.global.black,
};

/**
 * Clear a sprite reference.
 * @param spriteRef The sprite ref to clear.
 */
const clearSpriteRef = (spriteRef: MutableRefObject<PIXI.Graphics | Marker | null>) => {
  // Delete the current indicator.
  if (spriteRef.current != null) {
    spriteRef.current.destroy({
      children: true,
      baseTexture: true,
      texture: true,
    });
    spriteRef.current = null;
  }
};

/**
 * Clear a sprite array reference.
 * @param spriteArrayRef The sprite array ref to clear.
 */
const clearSpriteArrayRef = (spriteArrayRef: MutableRefObject<PIXI.Graphics[] | null>) => {
  if (spriteArrayRef.current && spriteArrayRef.current.length > 0) {
    spriteArrayRef.current.forEach((sprite, i) => {
      if (sprite && sprite.destroy && sprite.geometry) {
        sprite.destroy({
          children: true,
          baseTexture: true,
          texture: true,
        });
      }
    });
  }
  spriteArrayRef.current = [];
};

export interface Node extends PIXI.Graphics {
  lineIndex: number;
  nodeIndex: number;
}

interface Props {
  centerlineEdit: EditMode;
  dispatchEditModeAction?: boolean;
  onStartEditing?: (showAuxAnnos: boolean) => void;
  onSetActiveLine?: (lineName: string) => void;
  activeLine: string | null;
  // Whenever this value is changed the image buffers will resize.
  triggerResize?: any;
  windowLevels: WindowLevels;
  windowLabel: string | null;
  onWindowLevelsChange?: (windowLevels: WindowLevels) => void;
  showHighLowIndicators?: boolean;
  ffrData?: FFRVesselDataResponse;
  cprVesselData?: CPRVesselData;
  showFFRLabels?: boolean;
  showAnnos: boolean;
  onLoad?: () => void;
  setFfrenabled?: (ffrEnabled: boolean) => void;
  setFfrStatus?: (ffrStatus: FFRStatus) => void;
  setFfrDetail?: (ffrDetail: string) => void;
  setShowAnnos: (showAnnos: boolean) => void;
  onSaveEdits?: (hideAnnosAfterConfirming: boolean) => void;
  onDiscardEdits?: () => void;
  triggerResetPanAndZoom?: number;
  cprVersion: number;
  // By default the view will respond to the keyboard whereever the mouse is, if this is
  // true then the mouse must be over the view at the time.
  requireMouseOverForKeyboardInput?: boolean;
  screenshotDisabled?: boolean;
  screenshotRef?: any;
  viewName?: string;
}

export const CPRViewer: React.FunctionComponent<Props> = ({
  onStartEditing,
  centerlineEdit,
  triggerResize = false,
  onSetActiveLine,
  activeLine,
  windowLevels,
  windowLabel,
  onWindowLevelsChange,
  showHighLowIndicators = false,
  ffrData,
  showFFRLabels = false,
  setFfrenabled,
  setFfrStatus,
  setFfrDetail,
  cprVesselData,
  showAnnos,
  onLoad,
  setShowAnnos,
  onSaveEdits,
  onDiscardEdits,
  triggerResetPanAndZoom,
  cprVersion,
  requireMouseOverForKeyboardInput,
  dispatchEditModeAction = false,
  screenshotDisabled,
  screenshotRef,
  viewName,
}) => {
  const displayMeasurements = useAppSelector((state) => state.globalFlags.displayMeasurements);
  const { midSliceIdx: sliceidx, highSliceIdx: highSliceidx, lowSliceIdx: lowSliceidx } = useVesselStateSelector();

  // Measurement tools store
  const isMeasurementMode = useAppSelector((state) => state.measurementTools.isMeasurementMode);
  const { selectedVesselData, selectedVesselName } = useVesselStateSelector();
  const setSelectedVesselName = useSetSelectedVesselSelector();

  const dispatch = useAppDispatch();
  const { sliceidx: cprSliceidx, sliceCount: cprSliceCount } = useAppSelector((state) => state.cpr);
  const { clientConfig } = useDashboardSelector((state) => state.user);

  const [draggingIndicator, setDraggingIndicator] = useState<boolean>(false);
  const [draggingNode, setDraggingNode] = useState<boolean>(false);
  const [mouseIntersectsNodes, setMouseIntersectsNodes] = useState<number[]>([]);
  const [showAuxAnnos, setShowAuxAnnos] = useState(false);
  const mountedRef = useRef(true);
  const showFFRLabelsRef = useRef(false);
  const [confirmingDiscard, setConfirmingDiscard] = useState<boolean>(false);
  const hueRef = useRef<any>(null);
  // The outer WebGLViewer div element.
  const holderRef = useRef<HTMLDivElement | null>(null);
  // The PIXI container that the images, annotations etc are rendered on.
  const containerRef = useRef<PIXI.Container | null>(null);
  const appRef = useRef<PIXI.Application | null>(null);
  // The default scaling to apply to the image so that it will fit snugly inside the view.
  const defaultScaleRef = useRef<number>(1);

  // The centerline sprite.
  const centerlineSpriteRef = useRef<PIXI.Graphics | null>(null);
  // The aux centreline sprites.
  const auxCenterLinesRef = useRef<{
    [vesselId: string]: PIXI.Graphics;
  } | null>(null);

  // Indicator sprites.
  const midSliceIndicatorSpriteRef = useRef<PIXI.Graphics | null>(null);
  const highSliceIndicatorSpriteRef = useRef<PIXI.Graphics | null>(null);
  const lowSliceIndicatorSpriteRef = useRef<PIXI.Graphics | null>(null);

  const cprSliceIndexRef = useRef<number>(cprSliceidx);
  const annoRef = useRef<PIXI.Container | null>(null);
  const centerLinesContainerRef = useRef<PIXI.Container | null>(null);
  const auxCenterLinesContainerRef = useRef<PIXI.Container | null>(null);
  const ffrValueContainerRef = useRef<PIXI.Container | null>(null);
  const measurementValueContainerRef = useRef<PIXI.Container | null>(null);
  const draggingNodeRef = useRef<Node | null>(null);
  const hoveredNodeRef = useRef<Node | null>(null);
  const editNodesRef = useRef<Node[]>([]);
  const editNodesDataRef = useRef<LineObject | null>(null);
  const mousePosRef = useRef<PointObject>({ x: 0, y: 0 });

  // Marker states
  const proximalAnnotationRef = useRef<Marker | null>(null);
  const midAnnotationRef = useRef<Marker | null>(null);
  const distalAnnotationRef = useRef<Marker | null>(null);
  const auxMarkerRef = useRef<ObjectArray<PIXI.Container>>({});
  // FFR marker states
  const proximalFFRRef = useRef<Marker | null>(null);
  const distalFFRRef = useRef<Marker | null>(null);
  const proximalMeasurementRef = useRef<Marker | null>(null);
  const distalMeasurementRef = useRef<Marker | null>(null);
  const mouseOverAuxCenterlineRef = useRef<string | null>(null);
  const prevSelectedVesselName = usePreviousValue(selectedVesselName);

  const getCPRSliceData = (sliceIndex: number): CPRSliceData | undefined => {
    return cprVesselData?.sliceData?.[sliceIndex];
  };

  // Get the length of the centerline for the currently visible rotational slice.
  const lineLength = getCPRSliceData(cprSliceidx)?.anno?.length || 0;
  // Get the number of slices along the centerline (ie the number of short axis views).
  const sliceCount = selectedVesselData?.n_slices || 0;

  const ffrDataRef = useRef<FFRVesselDataResponse | undefined>();
  // Is the mouse currently over this view?
  const mouseOver = useRef<boolean>(false);

  useEffect(() => {
    // checking ffr data status to determine if we need to continue polling endpoint
    if (!ffrData || !selectedVesselName) {
      setFfrStatus && setFfrStatus('Failed');
      return;
    }
    setFfrStatus && setFfrStatus(ffrData[selectedVesselName].status);
    if (ffrData[selectedVesselName].details) {
      setFfrDetail && setFfrDetail(ffrData[selectedVesselName].details || '');
    }
    ffrDataRef.current = ffrData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ffrData, selectedVesselName]);

  useEffect(() => {
    // On switching vessel set ffrenabled to trigger api call
    // TODO: Add trigger FFR if centerline has been edited?
    if (prevSelectedVesselName && !isEqual(selectedVesselName, prevSelectedVesselName)) {
      setFfrenabled && setFfrenabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevSelectedVesselName, selectedVesselName]);

  useEffect(() => {
    mountedRef.current = true;
    document.addEventListener('keydown', onDocumentKeyDown);
    return () => {
      mountedRef.current = false;
      document.removeEventListener('keydown', onDocumentKeyDown);
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get the size of the image for the specified slice (or default if this isn't available).
   */
  const getImageSize = (sliceIndex: number): XYCoords => {
    return cprVesselData?.shape?.[sliceIndex] || { x: 1, y: 1 };
  };

  /**
   * As the container scales, we want to keep the annotation sizes the same. Divide any thicknesses
   * by the result of this function.
   */
  const calculateScaledLineThickness = () => {
    // We use `y` here, but it could've just as easily have been `x`. We just need a scalar.
    return containerRef.current?.scale.y ?? 1;
  };

  // Switch the annotations to the slice when the slice index changes.
  useEffect(() => {
    if (cprSliceidx !== cprSliceIndexRef.current) {
      onSliceChange(cprSliceidx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cprSliceidx]);

  useEffect(() => {
    // Move high slice indicator
    if (sliceidx - highSliceidx <= CPR_SLICE_INDICATOR_BUFFER) {
      const high = Math.max(sliceidx - CPR_SLICE_INDICATOR_BUFFER, 0);
      dispatch(vesselDataActions.updateSliceIndices({ high }));
    }
    // Move low slice indicator
    if (lowSliceidx - sliceidx <= CPR_SLICE_INDICATOR_BUFFER) {
      const low = Math.min(sliceidx + CPR_SLICE_INDICATOR_BUFFER, sliceCount - 1);
      dispatch(vesselDataActions.updateSliceIndices({ low }));
    }
    // Move the slice indicator to the new position.
    createIndicatorSprite(sliceidx, midSliceIndicatorSpriteRef, 'mid');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliceidx, dispatch]);

  useEffect(() => {
    // Move the slice indicator to the new position.
    createIndicatorSprite(highSliceidx, highSliceIndicatorSpriteRef, 'high');

    if (ffrDataRef.current) {
      const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
      vesselFFRData.value && createFfrMarkers(vesselFFRData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highSliceidx]);

  useEffect(() => {
    // Move the slice indicator to the new position.
    createIndicatorSprite(lowSliceidx, lowSliceIndicatorSpriteRef, 'low');

    if (ffrDataRef.current) {
      const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
      vesselFFRData.value && createFfrMarkers(vesselFFRData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowSliceidx]);

  useEffect(() => {
    if (displayMeasurements) {
      clearSpriteRef(proximalMeasurementRef);
      clearSpriteRef(distalMeasurementRef);
      proximalMeasurementRef.current = createMeasurementSliceMarkerText(highSliceidx, sliceidx);
      distalMeasurementRef.current = createMeasurementSliceMarkerText(lowSliceidx, sliceidx);

      if (measurementValueContainerRef.current) {
        measurementValueContainerRef.current.visible = displayMeasurements;
      }
    } else {
      clearSpriteRef(proximalMeasurementRef);
      clearSpriteRef(distalMeasurementRef);
    }
    // 'createMeasurementSliceMarkerText()' is not required as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMeasurements, lowSliceidx, highSliceidx, sliceidx]);

  /**
   * React to the user starting or stopping the edit mode.
   */
  useEffect(() => {
    if (centerlineEdit.editing) {
      onStartEditCenterLine();
    } else {
      onStopEditCenterLine();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerlineEdit.editing]);

  useEffect(() => {
    if (auxCenterLinesContainerRef.current) {
      auxCenterLinesContainerRef.current.visible = showAuxAnnos;
    }
  }, [showAuxAnnos]);

  useEffect(() => {
    if (!ffrDataRef.current) return;
    if (ffrValueContainerRef.current) {
      ffrValueContainerRef.current.visible = showFFRLabels;
      showFFRLabelsRef.current = showFFRLabels;
      // Create the FFR markers.
      if (showFFRLabels) {
        const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
        vesselFFRData.value && createFfrMarkers(vesselFFRData);
      }
      if (!centerlineEdit.editing) {
        // Recreate the indicators so they can have the correct colour set (they may turn red when FFR is active).
        clearAndCreateIndicatorSprites();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ffrDataRef.current, showFFRLabels]);

  useEffect(() => {
    if (centerLinesContainerRef.current) {
      centerLinesContainerRef.current.visible = showAnnos;
    }
    if (!showAnnos) {
      // Abort any edits in progress.
      dispatchEditModeAction && dispatch(centerlineEditActions.stopEditing());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnnos]);

  const onReady = ({ container, holder, app, defaultScale }: OnReadyInf) => {
    // Ensure we are initializing (or re-initializing) from a clean starting point.
    cleanup();
    // Create the annotations ref, it will be added to the container.
    annoRef.current = new PIXI.Container();
    // Create the centerlines container ref and add it to the annoRef.
    centerLinesContainerRef.current = new PIXI.Container();
    centerLinesContainerRef.current.visible = showAnnos;
    centerLinesContainerRef.current.sortableChildren = true;
    annoRef.current.addChild(centerLinesContainerRef.current);
    // Create the aux centerlines container ref and add it to the centerlines container ref.
    auxCenterLinesContainerRef.current = new PIXI.Container();
    auxCenterLinesContainerRef.current.visible = showAuxAnnos;
    centerLinesContainerRef.current.addChild(auxCenterLinesContainerRef.current);
    ffrValueContainerRef.current = new PIXI.Container();
    ffrValueContainerRef.current.visible = showFFRLabels;
    measurementValueContainerRef.current = new PIXI.Container();
    measurementValueContainerRef.current.visible = displayMeasurements;
    annoRef.current.sortableChildren = true;
    holderRef.current = holder;
    containerRef.current = container;
    appRef.current = app;
    defaultScaleRef.current = defaultScale;
    container.addChild(annoRef.current);
    if (appRef.current?.stage) {
      appRef.current.stage.addChild(ffrValueContainerRef.current);
      appRef.current.stage.addChild(measurementValueContainerRef.current);
    }

    // Create the annotation for the current slice (which is hopefully the one we preloaded).
    createAnnotation(cprSliceIndexRef.current);
  };

  /**
   * Destroy the centerline PIXI graphics element.
   */
  const destroyCenterLine = () => {
    try {
      centerlineSpriteRef.current?.destroy({
        children: true,
        texture: true,
        baseTexture: true,
      });
    } catch (err) {
      console.warn(err);
    }
    centerlineSpriteRef.current = null;
  };

  /**
   * Destroy all the aux centerline PIXI graphics elements (ie other vessel centerlines).
   */
  const destroyAuxCenterLines = () => {
    if (auxCenterLinesRef.current) {
      Object.values(auxCenterLinesRef.current).forEach((centerlines: PIXI.Graphics) => {
        try {
          centerlines.destroy({
            children: true,
            baseTexture: true,
            texture: true,
          });
        } catch (err) {
          console.warn(err);
        }
      });
    }
    auxCenterLinesRef.current = null;
  };

  /**
   * Destroy the slice indicators (ie dragable handles).
   */
  const destroySliceIndicators = () => {
    clearSpriteRef(midSliceIndicatorSpriteRef);
    clearSpriteRef(highSliceIndicatorSpriteRef);
    clearSpriteRef(lowSliceIndicatorSpriteRef);
  };

  /**
   * Destroy all the aux labels (ie text labels for the vessels).
   */
  const destroyAuxAnnotationLabels = () => {
    if (auxMarkerRef.current) {
      Object.keys(auxMarkerRef.current).forEach((key) => {
        try {
          auxMarkerRef.current[key].destroy({
            children: true,
            texture: true,
            baseTexture: true,
          });
        } catch (err) {
          console.warn(err);
        }
      });
    }
    auxMarkerRef.current = {};
  };

  /**
   * Destroy all the markers (ie text prox, mid, dist labels).
   */
  const destroyMarkers = () => {
    clearSpriteRef(proximalAnnotationRef);
    clearSpriteRef(midAnnotationRef);
    clearSpriteRef(distalAnnotationRef);
  };

  const destroyMeasurements = () => {
    clearSpriteRef(proximalMeasurementRef);
    clearSpriteRef(distalMeasurementRef);
  };

  const destroyFFRMarkers = () => {
    clearSpriteRef(distalFFRRef);
    clearSpriteRef(proximalFFRRef);
  };

  /**
   * Destroy all PIX graphics annotations, markers, indicators.
   */
  const cleanupAnnotations = () => {
    timeFuncFactory(() => {
      destroyCenterLine();
      destroyAuxCenterLines();
      destroyAuxAnnotationLabels();
      destroySliceIndicators();
      destroyMarkers();
      destroyMeasurements();
      destroyFFRMarkers();
      if (annoRef.current) {
        try {
          annoRef.current.destroy({
            children: true,
            texture: true,
            baseTexture: true,
          });
        } catch (err) {
          console.warn(err);
        }
        annoRef.current = null;
      }
      mouseOverAuxCenterlineRef.current = null;
    }, 'cleanupAnnotations CPRViewer')();
  };

  /**
   * Clean up all allocated PIXI buffers etc and ensure we have exited add and editing modes.
   */
  const cleanup = () => {
    // Abort any edits in progress.
    dispatchEditModeAction && dispatch(centerlineEditActions.stopEditing());
    cleanupAnnotations();

    // Cleanup refs to avoid cycles and help the garbage collector do its thing.
    if (!mountedRef.current) {
      holderRef.current = null;
      containerRef.current = null;
      appRef.current = null;
      centerlineSpriteRef.current = null;
      auxCenterLinesRef.current = null;
      midSliceIndicatorSpriteRef.current = null;
      highSliceIndicatorSpriteRef.current = null;
      lowSliceIndicatorSpriteRef.current = null;
      annoRef.current = null;
      centerLinesContainerRef.current = null;
      auxCenterLinesContainerRef.current = null;
      draggingNodeRef.current = null;
      hoveredNodeRef.current = null;
      editNodesRef.current = [];
      editNodesDataRef.current = null;
      mousePosRef.current = { x: 0, y: 0 };
      proximalAnnotationRef.current = null;
      midAnnotationRef.current = null;
      distalAnnotationRef.current = null;
      auxMarkerRef.current = {};
      mouseOverAuxCenterlineRef.current = null;
      ffrValueContainerRef.current = null;
      proximalFFRRef.current = null;
      distalFFRRef.current = null;
      ffrDataRef.current = undefined;
      ffrValueContainerRef.current = null;
    }
  };

  /**
   * We need to create the annotation if the user has moved to this slice while it was loading, but only if the CPRViewer has initialized.
   */
  useEffect(() => {
    if (mountedRef.current && appRef.current) {
      createAnnotation(cprSliceIndexRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cprVesselData]);

  const clearAndCreateCentreLineSprite = (index: number) => {
    // If the centreline already had a sprite, remove it
    clearSpriteRef(centerlineSpriteRef);

    // Create the new sprite
    const centerline = getCPRSliceData(index)?.anno;
    if (centerline) {
      centerlineSpriteRef.current = createCentreLineSprite(centerline);
    }
  };

  const clearAndCreateAuxCentreLineSprites = (sliceIndex: number) => {
    const auxAnno = getCPRSliceData(sliceIndex)?.auxAnno;

    // Clear any existing aux annotation sprites.
    if (auxCenterLinesRef.current) {
      Object.values(auxCenterLinesRef.current).forEach((graphics) => {
        graphics.destroy({
          children: true,
          baseTexture: true,
          texture: true,
        });
      });
    }
    auxCenterLinesRef.current = {};

    if (auxAnno) {
      Object.keys(auxAnno).forEach((id) => {
        // Create the marker (ie text label for the vessel) if it doesn't exist yet.
        // NOTE: The auxMarkerRef markers exist once for all slices vs per slice.
        if (annoRef.current && !auxMarkerRef.current[id]) {
          const marker = createMarkerSprite(id, 30, 5, 8);
          marker.zIndex = 99;
          marker.visible = false;
          auxMarkerRef.current[id] = marker;
          annoRef.current.addChild(marker);
        }

        if (auxCenterLinesRef.current && auxAnno[id]) {
          auxCenterLinesRef.current[id] = createCentreLineSprite(auxAnno[id], CENTRELINE_SETTINGS.AUX_COLOR, true);
        }
      });
    }
  };

  /**
   * Create the centerline annotation for the current (rotational) slice.
   */
  const createAnnotation = (sliceIndex: number) => {
    if (sliceIndex === cprSliceIndexRef.current) {
      // Recreate the high, mid, low slice indicator handles.
      clearAndCreateIndicatorSprites();
      // Recreate the centerline sprites.
      clearAndCreateCentreLineSprite(sliceIndex);

      if (ffrDataRef.current && showFFRLabels) {
        // Recreate the ffr markers
        const vesselFFRData = Object.entries(ffrDataRef.current)[0][1];
        vesselFFRData.value && createFfrMarkers(vesselFFRData);
      }

      if (displayMeasurements) {
        clearSpriteRef(proximalMeasurementRef);
        clearSpriteRef(distalMeasurementRef);
        proximalMeasurementRef.current = createMeasurementSliceMarkerText(highSliceidx, sliceidx);
        distalMeasurementRef.current = createMeasurementSliceMarkerText(lowSliceidx, sliceidx);
      }
      // Recreate the aux centerline sprites.
      clearAndCreateAuxCentreLineSprites(sliceIndex);
      // Create the prox, mid, and dis markers.
      clearAndCreateMarkers();
      // Jump into editing mode?
      centerlineEdit.editing && onStartEditCenterLine();
    }
  };

  const ffrBelowTolerance = (index: number, type = 'low') => {
    if (
      !ffrDataRef.current ||
      !Object.entries(ffrDataRef.current)[0][1].value ||
      !Object.entries(ffrDataRef.current)[0][1].value[index]
    )
      return false;

    const value = parseFloat(Object.entries(ffrDataRef.current)[0][1].value[index].toFixed(2)) || 0;

    return value < FFR_TOLERANCE;
  };

  /**
   * Clear and create the FFR text markers for the specified data.
   */
  const createFfrMarkers = (data: FFRVesselData) => {
    if (!clientConfig?.ffr_enabled || !selectedVesselName) {
      return;
    }

    // Proximal FFR marker
    if (highSliceidx !== null) {
      clearSpriteRef(proximalFFRRef);
      proximalFFRRef.current = createFFRSliceMarkerText(
        highSliceidx,
        `FFR ${data && !isNaN(data.value[highSliceidx]) ? data.value[highSliceidx].toFixed(2) : '-'}`,
        ffrBelowTolerance(highSliceidx, 'high')
      );
    }

    // Distal FFR marker
    if (lowSliceidx !== null) {
      clearSpriteRef(distalFFRRef);
      distalFFRRef.current = createFFRSliceMarkerText(
        lowSliceidx,
        `FFR ${data && !isNaN(data.value[lowSliceidx]) ? data.value[lowSliceidx].toFixed(2) : '-'}`,
        ffrBelowTolerance(lowSliceidx)
      );
    }
  };

  const createMeasurementSliceMarkerText = (selectedSliceIndex: number, midSliceIndex: number): Marker | null => {
    const measurement: number = (selectedSliceIndex - midSliceIndex) * MPR_SLICE_SPACING;

    const marker = createMeasurementMarkerSprite(measurement.toFixed(1) + ' ' + MPR_SLICE_SPACING_UNIT);
    marker.zIndex = MEASUREMENT_MARKER_SETTINGS.Z_INDEX;
    marker.sliceIndex = selectedSliceIndex;

    if (measurementValueContainerRef.current) {
      measurementValueContainerRef.current.addChild(marker);
    }

    transformMeasurementMarker(marker);
    return marker;
  };

  /**
   * Correctly position the given measurement marker
   * Position is calculated based on centerline, relevant slice indices and container scaling
   * @param marker - measurement marker
   */
  const transformMeasurementMarker = (marker: Marker | null): void => {
    if (marker) {
      marker.visible = !centerlineEdit.editing;

      const centerline = getCPRSliceData(cprSliceIndexRef.current)?.anno;
      const lineIndex = getInterpolatedLineIndex(marker.sliceIndex, cprSliceIndexRef.current);

      if (centerline && lineIndex !== null && containerRef.current) {
        // Find an offset position for marker from perpendicular indicator line.
        const adjacentPoints: AdjacentPoints = calculateInterpolatedAdjacentPoints(centerline, lineIndex);

        // The indicator width is fixed in terms of image pixels so we need to divide it by the defaultScale, then we add the extra offset we want in screen pixels.
        const indicatorWidth =
          (INDICATOR_SETTINGS.INDICATOR_WIDTH + 2) / (defaultScaleRef.current ?? 1) +
          MEASUREMENT_MARKER_SETTINGS.OFFSET / containerRef.current.scale.x;

        // Flip ordering of points because we want a perpendicular line projecting to the left
        const indicatorLine = getPerpendicularLine(
          [adjacentPoints.nextPoint, adjacentPoints.slicePoint, adjacentPoints.prevPoint],
          indicatorWidth
        );

        // Scale and offset the marker position based on the current container position and scale.
        marker.x = containerRef.current.x + indicatorLine[2].x * containerRef.current.scale.x;
        marker.y = containerRef.current.y + indicatorLine[2].y * containerRef.current.scale.y - marker.height / 2;
      }
    }
  };

  /**
   * Recreate the prox, mid, and dis markers for the current (rotational) slice.
   */
  const clearAndCreateMarkers = () => {
    if (selectedVesselData && selectedVesselData.segments) {
      if (selectedVesselData.segments.p) {
        clearSpriteRef(proximalAnnotationRef);
        proximalAnnotationRef.current = createSliceMarkerText(selectedVesselData.segments.p[0], 'PROX');
      }

      if (selectedVesselData.segments.m) {
        clearSpriteRef(midAnnotationRef);
        midAnnotationRef.current = createSliceMarkerText(selectedVesselData.segments.m[0], 'MID');
      }

      if (selectedVesselData.segments.d) {
        clearSpriteRef(distalAnnotationRef);
        distalAnnotationRef.current = createSliceMarkerText(selectedVesselData.segments.d[0], 'DIS');
      }
    }
  };

  /**
   * Clear and recreate the indicator sprite for the given line name.
   * @param sliceIndex The (along the centerline) slice index.
   * @param sliceIndicatorLine The sprite ref to modify.
   * @param lineName 'mid', 'low, 'high'
   */
  const createIndicatorSprite = (
    sliceIndex: number,
    sliceIndicatorLine: MutableRefObject<PIXI.Graphics | null>,
    lineName: string
  ) => {
    // Delete the current indicator.
    clearSpriteRef(sliceIndicatorLine);

    const showIndicator = showHighLowIndicators || lineName === 'mid';
    if (!isNaN(sliceIndex) && showIndicator) {
      const lineIndex = getInterpolatedLineIndex(sliceIndex, cprSliceIndexRef.current);
      const centerline = getCPRSliceData(cprSliceIndexRef.current)?.anno;
      if (lineIndex !== null && centerline) {
        // We have to split the scale into two parts to separate the default scaling and the current zoom.
        const defaultScale = defaultScaleRef.current ?? 1;
        const zoom = calculateScaledLineThickness() / defaultScale;

        // Create the slice indicator.
        sliceIndicatorLine.current = createSliceIndicatorSprite({
          centerline,
          lineIndex,
          fillColour:
            lineName === 'mid'
              ? INDICATOR_SETTINGS.INDICATOR_COLOR_MAIN
              : ffrBelowTolerance(sliceIndex) && showFFRLabelsRef.current
              ? CENTRELINE_SETTINGS.FFR_LOW_COLOR
              : INDICATOR_SETTINGS.INDICATOR_COLOR,
          visible: !centerlineEdit.editing, // The sprite is hidden if we are in edit centerline mode.
          zIndex: lineName === 'mid' ? 10 : 1, // The mid indicator needs to render on top.
          defaultScale,
          zoom,
          onPointerDown: (event: PIXIPointerEvent) => {
            onSetActiveLine && onSetActiveLine(lineName);
            onMouseDownIndicator(event);
          },
        });

        // Add the slice indicator to the anno container.
        if (annoRef.current) {
          annoRef.current.addChild(sliceIndicatorLine.current);
        }
      }
    }
  };

  /**
   * Clear the indicator sprites for the high, mid, and low indicator slice positions and then recreate if able.
   */
  const clearAndCreateIndicatorSprites = () => {
    // Delete the current indicators.
    clearSpriteRef(highSliceIndicatorSpriteRef);
    clearSpriteRef(midSliceIndicatorSpriteRef);
    clearSpriteRef(lowSliceIndicatorSpriteRef);

    // Recreate the slice indicatorts as required / able.
    if (showHighLowIndicators) {
      // High indicator.
      createIndicatorSprite(highSliceidx, highSliceIndicatorSpriteRef, 'high');

      // Low indicator.
      createIndicatorSprite(lowSliceidx, lowSliceIndicatorSpriteRef, 'low');
    }

    // Mid indicator.
    createIndicatorSprite(sliceidx, midSliceIndicatorSpriteRef, 'mid');
  };

  const xyCoordsToLineArray = (line: XYCoords[]): LineArray => {
    let newLine: LineArray = [];
    const imageSize = getImageSize(cprSliceidx);
    line.forEach((p) => {
      if (p.x >= 0 && p.x <= imageSize.x && p.y >= 0 && p.y <= imageSize.y) {
        newLine.push([p.y, p.x]);
      } else {
        console.warn(
          `Not including point in edit as { x: ${p.x}, y: ${p.y} } is not inside the image bounds of { width: ${imageSize.x}, height: ${imageSize.y} } }`
        );
      }
    });
    return newLine;
  };

  /**
   * Create a sprite of the specified line and add it to the centerLinesContainerRef or auxCenterLinesContainerRef as suggested by aux.
   */
  const createCentreLineSprite = (line: XYCoords[], color = CENTRELINE_SETTINGS.COLOR, aux = false) => {
    const sprite = new PIXI.Graphics();
    drawShadowLine(sprite, line, color);

    if (aux) {
      auxCenterLinesContainerRef.current && auxCenterLinesContainerRef.current.addChild(sprite);
    } else {
      centerLinesContainerRef.current && centerLinesContainerRef.current.addChild(sprite);
    }

    return sprite;
  };

  /**
   * Draw a line on the sprite with the specified color.
   */
  const drawShadowLine = (sprite: PIXI.Graphics, line: XYCoords[], color: string) => {
    if (!line || !line.length) {
      return sprite;
    }
    const colors = [CENTRELINE_SETTINGS.SHADOW_COLOR, color];

    const toScaleLineThickness = calculateScaledLineThickness();

    colors.forEach((color, i) => {
      const opacity = i === 0 ? CENTRELINE_SETTINGS.SHADOW_OPACITY : 1;
      const width =
        (i === 0 ? CENTRELINE_SETTINGS.WIDTH + CENTRELINE_SETTINGS.SHADOW_SIZE : CENTRELINE_SETTINGS.WIDTH) /
        toScaleLineThickness;
      sprite.lineTextureStyle({
        width,
        color: Number(color.replace('#', '0x')),
        alpha: opacity,
        cap: PIXI.LINE_CAP.ROUND,
        join: PIXI.LINE_JOIN.ROUND,
      });
      sprite.moveTo(line[0].x, line[0].y);
      line.forEach((point: PointObject) => {
        sprite.lineTo(point.x, point.y);
      });
    });
    return sprite;
  };

  const createMarkerSprite = (
    type: string,
    width: number = 40,
    heightPadding: number = 7,
    fontSize: number = 10
  ): Marker => {
    const style = new PIXI.TextStyle({
      fontFamily: 'Arial',
      fontSize,
      fill: THEME.colors.global.white,
      align: 'center',
    });
    const text = new PIXI.Text(type, style);
    text.roundPixels = true;

    // Draw the rounded grey backing rect.
    const height = text.height + heightPadding;
    const rectangle = new PIXI.Graphics();
    rectangle.beginFill(Number(CENTRELINE_SETTINGS.MARKER_COLOR.replace('#', '0x')));
    rectangle.drawRoundedRect(0, 0, width, height, 4);
    rectangle.endFill();

    // Draw the white pointer line on the right, for debugging the position it's really handly to change the length from 15 to 15000.
    rectangle.beginFill(0xffffff);
    rectangle.drawRect(width, height / 2, 15, 1);
    rectangle.endFill();

    const wrapper = new PIXI.Container() as Marker;
    wrapper.addChild(rectangle, text);
    // Center the text in the middle of the rectangle.
    text.x = width / 2;
    text.y = height / 2;
    text.anchor.set(0.5, 0.5);
    return wrapper;
  };

  const createFFRMarkerSprite = (type: string, belowTolerance: boolean): Marker => {
    const style = new PIXI.TextStyle({
      fontFamily: 'Arial',
      fontSize: 12,
      fill: belowTolerance ? CENTRELINE_SETTINGS.FFR_LOW_COLOR : CENTRELINE_SETTINGS.NODE_COLOR,
      align: 'center',
    });
    const text = new PIXI.Text(type, style);
    text.roundPixels = true;

    // Draw the rounded grey backing rect.
    const width = 60;
    const height = text.height + 7;
    const rectangle = new PIXI.Graphics();
    rectangle.beginFill(Number(CENTRELINE_SETTINGS.MARKER_COLOR.replace('#', '0x')));
    rectangle.drawRoundedRect(0, 0, width, height, 4);
    rectangle.endFill();

    const wrapper = new PIXI.Container() as Marker;
    wrapper.addChild(rectangle, text);
    // Center the text in the middle of the rectangle.
    text.x = width / 2;
    text.y = height / 2;
    text.anchor.set(0.5, 0.5);
    return wrapper;
  };

  const createFFRSliceMarkerText = (sliceIndex: number, text: string, belowTolerance = false) => {
    const centerline = getCPRSliceData(cprSliceIndexRef.current)?.anno;
    const lineIndex = getInterpolatedLineIndex(sliceIndex, cprSliceIndexRef.current);
    if (centerline && lineIndex !== null && containerRef.current) {
      const marker = createFFRMarkerSprite(text, belowTolerance);
      marker.zIndex = 30;
      marker.sliceIndex = sliceIndex;

      if (ffrValueContainerRef.current) {
        ffrValueContainerRef.current.addChild(marker);
      }

      transformFFRMarker(marker);
      return marker;
    }
    return null;
  };

  const createSliceMarkerText = (sliceIndex: number, text: string) => {
    const marker = createMarkerSprite(text);
    marker.zIndex = 30;
    marker.sliceIndex = sliceIndex;
    if (appRef.current?.stage) {
      appRef.current.stage.addChild(marker);
    }
    transformMarker(marker);
    return marker;
  };

  /**
   * Update the marker position for the current rotational slice and container position and scale.
   * With CPR V2 this has a greater effect because the lineIndex will change depending on the current (rotational) slice too.
   * Note the markers are not attached to the containerRef but the holderRef.
   */
  const transformFFRMarker = (marker: Marker | null) => {
    if (marker) {
      // We hide the marker while editing the centerline.
      marker.visible = !centerlineEdit.editing;

      const centerline = getCPRSliceData(cprSliceIndexRef.current)?.anno;
      const lineIndex = getInterpolatedLineIndex(marker.sliceIndex, cprSliceIndexRef.current);
      if (centerline && lineIndex !== null && containerRef.current) {
        // Find an offset position for marker from perpendicular indicator line.
        const adjacentPoints: AdjacentPoints = calculateInterpolatedAdjacentPoints(centerline, lineIndex);

        // The indicator width is fixed in terms of image pixels so we need to divide it by the defaultScale, then we add the extra offset we want in screen pixels.
        const indicatorWidth =
          (INDICATOR_SETTINGS.INDICATOR_WIDTH + 2) / (defaultScaleRef.current ?? 1) +
          CENTRELINE_SETTINGS.FFR_MARKER_OFFSET / containerRef.current.scale.x;
        const indicatorLine = getPerpendicularLine(
          [adjacentPoints.prevPoint, adjacentPoints.slicePoint, adjacentPoints.nextPoint],
          indicatorWidth
        );

        // Scale and offset the marker position based on the current container position and scale.
        marker.x = containerRef.current.x + indicatorLine[2].x * containerRef.current.scale.x;
        marker.y = containerRef.current.y + indicatorLine[2].y * containerRef.current.scale.y - marker.height / 2;
      }
    }
  };

  /**
   * Update the marker position for the current rotational slice and container position and scale.
   * With CPR V2 this has a greater effect because the lineIndex will change depending on the current (rotational) slice too.
   * Note the markers are not attached to the containerRef but the holderRef.
   */
  const transformMarker = (marker: Marker | null) => {
    if (marker) {
      // We hide the marker while editing or adding the centerline.
      marker.visible = !centerlineEdit.editing;
      // Get the interpolated position on the centerline for the slice and rotational slice.
      const centerline = getCPRSliceData(cprSliceIndexRef.current)?.anno;
      const lineIndex = getInterpolatedLineIndex(marker.sliceIndex, cprSliceIndexRef.current);
      if (centerline && lineIndex != null && containerRef.current) {
        const slicePoint = getInterpolatedCenterlinePoint(lineIndex, centerline);
        marker.x = 10;
        // The marker anchor point is at the top left of the marker, so we need to subtract 1/2 its height without applying the container scale.
        marker.y = containerRef.current.y + slicePoint.y * containerRef.current.scale.y - marker.height / 2;
      } else {
        marker.x = 10;
        marker.y = -1000000;
      }
    }
  };

  /**
   * Update the marker position for all markers for the current rotational slice and container position and scale.
   */
  const transformMarkers = () => {
    // Transform the prox, mid, dis markers.
    transformMarker(proximalAnnotationRef.current);
    transformMarker(midAnnotationRef.current);
    transformMarker(distalAnnotationRef.current);
    // Transform the FFR markers.
    transformFFRMarker(proximalFFRRef.current);
    transformFFRMarker(distalFFRRef.current);
    // Transform measurement markers
    transformMeasurementMarker(proximalMeasurementRef.current);
    transformMeasurementMarker(distalMeasurementRef.current);
  };

  /**
   * Set the visibility of the high, low and mid slice indicator handles.
   */
  const showSliceIndicators = (visible: boolean) => {
    if (lowSliceIndicatorSpriteRef.current) {
      lowSliceIndicatorSpriteRef.current.visible = visible && showHighLowIndicators;
    }
    if (highSliceIndicatorSpriteRef.current) {
      highSliceIndicatorSpriteRef.current.visible = visible && showHighLowIndicators;
    }
    if (midSliceIndicatorSpriteRef.current) {
      midSliceIndicatorSpriteRef.current.visible = visible;
    }
  };

  /**
   * The user has started editing the center line: hide annotations, show the nodes etc.
   */
  const onStartEditCenterLine = () => {
    // Show the centerline.
    setShowAnnos(true);
    // Hide the other centerlines.
    setShowAuxAnnos(false);
    // Hide the markers (the function is smart enough to know it needs to).
    transformMarkers();
    // Delete any old nodes and create the new ones.
    clearSpriteArrayRef(editNodesRef);
    if (mountedRef.current && annoRef.current) {
      showSliceIndicators(false);
      const centerline = getCPRSliceData(cprSliceIndexRef.current)?.anno;
      if (centerline) {
        const activeLineSprite = centerlineSpriteRef.current;
        if (activeLineSprite && activeLineSprite.destroy) {
          activeLineSprite.geometry && activeLineSprite.destroy();
          const nodes = getBezierNodes(centerline);
          editNodesDataRef.current = nodes;
          const bezierCenterLine = createBezierCurveSprite(nodes);
          bezierCenterLine.visible = true;
          centerlineSpriteRef.current = bezierCenterLine;
          createNodeSprites(nodes);
        }
      }
    }
  };

  /**
   * The user has stopped editing the center line: show annotations, destroy and hide the nodes etc.
   */
  const onStopEditCenterLine = async () => {
    // The annos is shown while editing; was it hidden before?
    if (!centerlineEdit.showAnnos) {
      setShowAnnos(false);
    }
    // The aux annos are hidden while editing; were they shown before?
    if (centerlineEdit.showAuxAnnos) {
      setShowAuxAnnos(true);
    }
    // Re-show the markers (the function is smart enough to know it needs to).
    transformMarkers();
    // Show the slice indicators.
    showSliceIndicators(true);
    if (editNodesDataRef.current) {
      const centerline = getCPRSliceData(cprSliceIndexRef.current)?.anno;
      if (centerline) {
        const activeLineSprite = centerlineSpriteRef.current;
        if (activeLineSprite && activeLineSprite.destroy) {
          activeLineSprite.geometry &&
            activeLineSprite.destroy({
              children: true,
              texture: true,
              baseTexture: true,
            });
          const sprite = createCentreLineSprite(centerline);
          sprite.visible = true;
          centerlineSpriteRef.current = sprite;
        }
      }
    }
    clearSpriteArrayRef(editNodesRef);
    editNodesDataRef.current = null;
  };

  const getBezierNodes = (line: XYCoords[]) => {
    let distance = 0;
    const nodes = [];
    let nodeIndex = 0;
    nodes.push({ ...line[0], lineIndex: 0, nodeIndex });
    line.forEach((point, i) => {
      const nextPoint = line[i + 1];
      const lastPoint = line[i - 1];
      if (lastPoint && nextPoint) {
        distance += getDistanceBetweenPoints(lastPoint, point);
        const angle1 = getLineAngle(lastPoint, point);
        const angle2 = getLineAngle(point, nextPoint);
        if (
          Math.abs(angle1 - angle2) > CENTRELINE_SETTINGS.NODE_ADDED_IF_ANGLE_GREATER_THAN &&
          (distance > CENTRELINE_SETTINGS.MIN_DISTANCE_BETWEEN_NODES || distance === 0)
        ) {
          nodeIndex++;
          nodes.push({ ...point, lineIndex: i, nodeIndex });
          distance = 0;
        }
      }
    });
    nodes.push({
      ...line[line.length - 1],
      lineIndex: line.length - 1,
      nodeIndex: nodeIndex + 1,
    });
    return nodes;
  };

  const convertCenterLineToBezier = (line: XYCoords[]) => {
    const curved = [];
    const t = CENTRELINE_SETTINGS.TENSION;
    for (let i = 0; i < line.length - 1; i++) {
      const p0 = i > 0 ? line[i - 1] : line[0];
      const p1 = line[i];
      const p2 = line[i + 1];
      const p3 = i !== line.length - 2 ? line[i + 2] : p2;
      const cp1x = p1.x + ((p2.x - p0.x) / 6) * t;
      const cp1y = p1.y + ((p2.y - p0.y) / 6) * t;
      const cp2x = p2.x - ((p3.x - p1.x) / 6) * t;
      const cp2y = p2.y - ((p3.y - p1.y) / 6) * t;
      curved.push([cp1x, cp1y, cp2x, cp2y, p2.x, p2.y]);
    }
    return curved;
  };

  const createBezierCurveSprite = (line: XYCoords[]) => {
    const sprite = new PIXI.Graphics();
    const curved = convertCenterLineToBezier(line);

    const toScaleLineThickness = calculateScaledLineThickness();

    for (let i = 0; i < 2; i++) {
      const color = i === 0 ? CENTRELINE_SETTINGS.SHADOW_COLOR : CENTRELINE_SETTINGS.COLOR;
      const opacity = i === 0 ? CENTRELINE_SETTINGS.SHADOW_OPACITY : 1;
      const width =
        (i === 0 ? CENTRELINE_SETTINGS.WIDTH + CENTRELINE_SETTINGS.SHADOW_SIZE : CENTRELINE_SETTINGS.WIDTH) /
        toScaleLineThickness;
      sprite.lineTextureStyle({
        width,
        color: Number(color.replace('#', '0x')),
        alpha: opacity,
        cap: PIXI.LINE_CAP.ROUND,
      });
      sprite.moveTo(line[0].x, line[0].y);
      curved.forEach((curve) => {
        sprite.bezierCurveTo(curve[0], curve[1], curve[2], curve[3], curve[4], curve[5]);
      });
    }
    centerLinesContainerRef.current && centerLinesContainerRef.current.addChild(sprite);

    return sprite;
  };

  const convertBezierLineToPoints = (points: XYCoords[], nodeNo = 10) => {
    const curved = convertCenterLineToBezier(points);
    const tension = CENTRELINE_SETTINGS.TENSION;
    let nodes: XYCoords[] = [];
    let lastX = points[0].x,
      lastY = points[0].y;
    curved.forEach((point) => {
      const dist = getDistanceBetweenPoints({ x: lastX, y: lastY }, { x: point[4], y: point[5] });
      const nodeEvery = Math.max(10, dist / (100 / nodeNo));
      const curve = plotCubicBezier(
        nodeEvery,
        tension * tension,
        lastX,
        lastY,
        point[0],
        point[1],
        point[2],
        point[3],
        point[4],
        point[5]
      );
      lastX = point[4];
      lastY = point[5];
      nodes = nodes.concat(curve);
    });
    return nodes;
  };

  const updateBezierLine = (line: XYCoords[]) => {
    let bezierLineSprite = centerlineSpriteRef.current;
    // Delete the old line sprite.
    if (bezierLineSprite && bezierLineSprite.destroy) {
      bezierLineSprite.destroy({
        children: true,
        texture: true,
        baseTexture: true,
      });
    }
    // Create and save the new line sprite.
    bezierLineSprite = createBezierCurveSprite(line);
    bezierLineSprite.visible = true;
    centerlineSpriteRef.current = bezierLineSprite;
  };

  const plotCubicBezier = (
    ptCount: number,
    pxTolerance: number,
    Ax: number,
    Ay: number,
    Bx: number,
    By: number,
    Cx: number,
    Cy: number,
    Dx: number,
    Dy: number
  ) => {
    const deltaBAx = Bx - Ax;
    const deltaCBx = Cx - Bx;
    const deltaDCx = Dx - Cx;
    const deltaBAy = By - Ay;
    const deltaCBy = Cy - By;
    const deltaDCy = Dy - Cy;
    let ax, ay, bx, by, cx, cy;
    let lastX = -Infinity;
    let lastY = -Infinity;
    const pts = [{ x: Ax, y: Ay }];
    for (let i = 1; i < ptCount; i++) {
      const t = i / ptCount;
      ax = Ax + deltaBAx * t;
      bx = Bx + deltaCBx * t;
      cx = Cx + deltaDCx * t;
      ax += (bx - ax) * t;
      bx += (cx - bx) * t;
      ay = Ay + deltaBAy * t;
      by = By + deltaCBy * t;
      cy = Cy + deltaDCy * t;
      ay += (by - ay) * t;
      by += (cy - by) * t;
      const x = ax + (bx - ax) * t;
      const y = ay + (by - ay) * t;
      const dx = x - lastX;
      const dy = y - lastY;
      if (dx * dx + dy * dy > pxTolerance) {
        pts.push({ x, y });
        lastX = x;
        lastY = y;
      }
    }
    pts.push({ x: Dx, y: Dy });
    return pts;
  };

  const createNodeSprites = (line: LineObject) => {
    line.forEach((point, i) => {
      const nodeSprite = createNodeSprite(point);
      nodeSprite.nodeIndex = point.nodeIndex !== undefined ? point.nodeIndex : -1;
      nodeSprite.lineIndex = point.lineIndex !== undefined ? point.lineIndex : -1;
      editNodesRef.current[i] = nodeSprite;
    });
  };

  const createNodeSprite = (point: PointObject): Node => {
    const sprite = new PIXI.Graphics() as Node;
    const toScaleLineThickness = calculateScaledLineThickness();

    sprite.beginFill(Number(CENTRELINE_SETTINGS.SHADOW_COLOR.replace('#', '0x')), CENTRELINE_SETTINGS.SHADOW_OPACITY);
    sprite.drawCircle(
      0,
      0,
      (CENTRELINE_SETTINGS.NODE_SIZE + CENTRELINE_SETTINGS.SHADOW_SIZE - 0.5) / toScaleLineThickness
    );
    sprite.endFill();
    sprite.lineStyle(1 / toScaleLineThickness, Number(CENTRELINE_SETTINGS.COLOR.replace('#', '0x')));
    sprite.beginFill(0xffffff);
    sprite.tint = Number(CENTRELINE_SETTINGS.NODE_COLOR.replace('#', '0x'));
    sprite.drawCircle(0, 0, CENTRELINE_SETTINGS.NODE_SIZE / toScaleLineThickness);
    sprite.endFill();
    sprite.interactive = true;
    const hoverPadding = 5;
    sprite.hitArea = new PIXI.Rectangle(
      0 - CENTRELINE_SETTINGS.NODE_SIZE - hoverPadding,
      0 - CENTRELINE_SETTINGS.NODE_SIZE - hoverPadding,
      CENTRELINE_SETTINGS.NODE_SIZE + hoverPadding * 2,
      CENTRELINE_SETTINGS.NODE_SIZE + hoverPadding * 2
    );
    sprite.x = point.x;
    sprite.y = point.y;
    sprite.zIndex = 2;
    sprite.cursor = 'move';
    sprite.on('pointerdown', (event: PIXIPointerEvent) => {
      onMouseDownNode(event, sprite);
    });
    sprite.on('mouseover', onMouseOverNode.bind(null, sprite));
    sprite.on('mouseout', onMouseOutNode.bind(null, sprite));
    centerLinesContainerRef.current && centerLinesContainerRef.current.addChild(sprite);
    return sprite;
  };

  /**
   * Get the position of the mouse over the container from the global page position in the event.
   */
  const getMousePos = (event: React.MouseEvent): XYCoords => {
    const pos = { x: 0, y: 0 };
    if (holderRef.current && containerRef.current) {
      // Get the position and size of the component on the page.
      const holderOffset = holderRef.current.getBoundingClientRect();
      pos.x = (event.pageX - holderOffset.x - containerRef.current.x) / containerRef.current.scale.x;
      pos.y = (event.pageY - holderOffset.y - containerRef.current.y) / containerRef.current.scale.y;
    }
    return pos;
  };

  /**
   * Change the container offset so that the mid indicator is now centered horizontally in the view and
   * vertically has the same view Y position it did before the slice was changed.
   */
  const centreOnIndicator = (index: number, oldIndex: number) => {
    if (containerRef.current && holderRef.current) {
      // Calculate the Y of the old indicator position.
      let oldPointY;
      const oldLineIndex = getInterpolatedLineIndex(sliceidx, oldIndex);
      const oldCenterline = getCPRSliceData(oldIndex)?.anno;
      if (oldLineIndex != null && oldCenterline) {
        const oldSlicePoint = getInterpolatedCenterlinePoint(oldLineIndex, oldCenterline);
        oldPointY = containerRef.current.y + oldSlicePoint.y * containerRef.current.scale.y;
      }

      // Calculate the new indicator position.
      const currentCenterline = getCPRSliceData(index)?.anno;
      const currentLineIndex = getInterpolatedLineIndex(sliceidx, index);
      if (currentLineIndex != null && currentCenterline && containerRef.current && holderRef.current) {
        const currentPoint = getInterpolatedCenterlinePoint(currentLineIndex, currentCenterline);
        const scale = containerRef.current.scale;
        containerRef.current.x = holderRef.current.clientWidth / 2 - currentPoint.x * scale.x;
        // If the indicator was shown in the last render then offset Y so that visually it stays in the smae spot on screen.
        if (oldPointY !== undefined) {
          containerRef.current.y = oldPointY - currentPoint.y * scale.y;
        }
      }
    }
  };

  /**
   * Convert from a slice index (this has the same range for every rotational slice) into a line index (the range of this changes per slice).
   * NOTE: The lineIndex returned by this function may be non-integer.
   */
  const getInterpolatedLineIndex = (index: number, cprSliceIndex: number) => {
    const centerlineMapping = cprVesselData?.sliceData?.[cprSliceIndex]?.centerlineMapping;
    if (sliceCount && lineLength) {
      if (cprVersion === 2 && centerlineMapping) {
        return centerlineMapping.sliceToInterpolatedCenterline[index];
      }
      return (index / sliceCount) * lineLength;
    }
    return null;
  };

  const selectNode = (sprite: Node) => {
    sprite.tint = Number(CENTRELINE_SETTINGS.NODE_SELECTED_COLOR.replace('#', '0x'));
    sprite.scale.x = 1.5;
    sprite.scale.y = 1.5;
    hoveredNodeRef.current = sprite;
  };

  const deselectNode = (sprite: Node) => {
    sprite.tint = Number(CENTRELINE_SETTINGS.NODE_COLOR.replace('#', '0x'));
    sprite.scale.x = 1;
    sprite.scale.y = 1;
    hoveredNodeRef.current = null;
  };

  const intersectsNodes = (nodeList: LineObject, node: PointObject, tolerance: number = 1) => {
    let indexes: number[] = [];
    nodeList.forEach((_, i) => {
      const prevPoint = nodeList[i - 1] || nodeList[0];
      const thisPoint = nodeList[i];
      const intersect = intersects.linePoint(
        prevPoint.x,
        prevPoint.y,
        thisPoint.x,
        thisPoint.y,
        node.x,
        node.y,
        tolerance
      );
      if (intersect) {
        indexes.push(i);
      }
    });
    return indexes;
  };

  const addNode = (index: number, newNode: PointObject) => {
    if (editNodesDataRef.current) {
      editNodesDataRef.current.splice(index, 0, newNode);
      updateNodes();
    }
  };

  const deleteNode = (index: number) => {
    if (editNodesDataRef.current) {
      editNodesDataRef.current.splice(index, 1);
      updateNodes();
    }
  };

  const updateNodes = () => {
    if (editNodesDataRef.current) {
      editNodesDataRef.current.forEach((n, i) => (n.nodeIndex = i));
      clearSpriteArrayRef(editNodesRef);
      if (mountedRef.current && annoRef.current) {
        createNodeSprites(editNodesDataRef.current || []);
        updateBezierLine(editNodesDataRef.current || []);
        dispatchEditModeAction && dispatch(centerlineEditActions.modifiedCenterline());
      }
    }
  };

  /**
   * to handle the cprSlice store
   */
  const handleCprSliceChange = (sliceidx: number) => {
    dispatch(cprActions.setCprSliceidx(sliceidx));
  };

  /**
   * Respond to the user changing the (rotational) slice.
   */
  const onSliceChange = (index: number) => {
    if (draggingIndicator || draggingNode) {
      return false;
    }

    // Abort any edits in progress.
    if (dispatchEditModeAction) {
      dispatch(centerlineEditActions.stopEditing());
    }

    // Set the new slice number (rotational slice).
    const oldIndex = cprSliceIndexRef.current;
    cprSliceIndexRef.current = index;

    createAnnotation(index);
    centreOnIndicator(index, oldIndex);
    // Set the markers to the correct Y position for the current rotational slice, pan and zoom.
    transformMarkers();

    return index;
  };

  const onSliceIndicatorChange = useCallback(
    (index: number) => {
      switch (activeLine) {
        case 'mid':
          dispatch(vesselDataActions.updateSliceIndices({ mid: index }));
          break;
        case 'low':
          // Limit the low slice indicator to the valid range: sliceidx + CPR_SLICE_INDICATOR_BUFFER to nSlices - 1.
          // Note the 'low' slice indicator is the one at the bottom, so will actually have higher index values than sliceidx.
          const low = Math.min(Math.max(index, sliceidx + CPR_SLICE_INDICATOR_BUFFER), sliceCount - 1);
          dispatch(vesselDataActions.updateSliceIndices({ low }));
          break;
        case 'high':
          // Limit the high slice indicator to the valid range: 0 to sliceidx - CPR_SLICE_INDICATOR_BUFFER.
          // Note the 'high' slice indicator is the one at the top, so will actually have lower index values than sliceidx.
          const high = Math.max(Math.min(index, sliceidx - CPR_SLICE_INDICATOR_BUFFER), 0);
          dispatch(vesselDataActions.updateSliceIndices({ high }));
          break;
        default:
          dispatch(vesselDataActions.updateSliceIndices({ mid: index }));
          break;
      }
    },
    [sliceidx, activeLine, dispatch, sliceCount]
  );

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.nativeEvent.code === 'ArrowUp' || event.nativeEvent.code === 'ArrowDown') {
        // Clear the currently set indicator.
        onSetActiveLine && onSetActiveLine('');
      }
    },
    [onSetActiveLine]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // disable rotation when measurement is on
      if (isMeasurementMode) return;
      // We mustn't be editing the centerline.
      if (centerlineEdit.editing) return;

      // If this view is on the CT Volume screen then the mouse must be over the view when the key is pressed.
      if (requireMouseOverForKeyboardInput && !mouseOver.current) return;

      // Up and down adjust the mid indicator position (ie sliceidx).
      if (event.nativeEvent.code === 'ArrowUp' || event.nativeEvent.code === 'ArrowDown') {
        event.nativeEvent.preventDefault();
        const deltaSliceIndex = event.nativeEvent.code === 'ArrowUp' ? -1 : 1;

        const currSlice = sliceidx + deltaSliceIndex;
        if (currSlice >= 0 && currSlice < sliceCount) {
          // Force the adjustment to apply to the middle indicator.
          onSetActiveLine && onSetActiveLine('mid');
          onSliceIndicatorChange(currSlice);
        }
      }

      // Left and right arrow keys adjust the cprSliceidx (index of rotation) by -/+ 1.
      if ((event.nativeEvent.code === 'ArrowLeft' || event.nativeEvent.code === 'ArrowRight') && cprSliceCount > 0) {
        event.nativeEvent.preventDefault();
        const deltaCprSliceIndex = event.nativeEvent.code === 'ArrowLeft' ? -1 : 1;
        // Allow the slice index to loop around.
        dispatch(cprActions.setCprSliceidx((cprSliceidx + deltaCprSliceIndex + cprSliceCount) % cprSliceCount));
      }
    },
    [
      isMeasurementMode,
      centerlineEdit.editing,
      requireMouseOverForKeyboardInput,
      cprSliceCount,
      sliceidx,
      sliceCount,
      onSetActiveLine,
      onSliceIndicatorChange,
      dispatch,
      cprSliceidx,
    ]
  );

  const onMouseDownIndicator = (event: PIXIPointerEvent) => {
    document.body.style.cursor = 'ns-resize';
    setDraggingIndicator(true);
    // Capture the mouse pointer.
    pixiCaptureMouse(event);
  };

  const onMouseDownNode = (event: PIXIPointerEvent, sprite: Node) => {
    setDraggingNode(sprite ? true : false);
    draggingNodeRef.current = sprite;
    dispatchEditModeAction && dispatch(centerlineEditActions.modifiedCenterline());
    // Capture the mouse pointer.
    pixiCaptureMouse(event);
  };

  const onMouseOverNode = (sprite: Node) => {
    if (!draggingNodeRef.current) {
      selectNode(sprite);
    }
  };

  const onMouseOutNode = (sprite: Node) => {
    if (!draggingNodeRef.current) {
      deselectNode(sprite);
    }
  };
  const onMouseDown = (e: React.MouseEvent) => {
    if (appRef.current && appRef.current.renderer && e.target !== appRef.current.renderer.view) {
      return;
    }
    if (mouseIntersectsNodes[0] && !draggingNode) {
      if (centerlineEdit.editing) {
        addNode(mouseIntersectsNodes[0], mousePosRef.current);
      }
    } else if (mouseOverAuxCenterlineRef.current) {
      setSelectedVesselName(mouseOverAuxCenterlineRef.current.toLowerCase());
      mouseOverAuxCenterlineRef.current = null;
    }
  };

  const onMouseUp = () => {
    if (centerlineEdit.editing && draggingNode) {
      setDraggingNode(false);
      draggingNodeRef.current = null;
    }
    document.body.style.cursor = '';
    setDraggingIndicator(false);
    onSetActiveLine && onSetActiveLine('');
  };

  /**
   * Update the mouseOver value.
   */
  const onMouseEnter = () => {
    mouseOver.current = true;
  };

  /**
   * Update the mouseOver value, clear the hue value.
   */
  const onMouseLeave = () => {
    mouseOver.current = false;
    if (hueRef.current && hueRef?.current?.setValue) {
      hueRef.current.setValue(null);
    }
  };

  /**
   * Drag the slice indicator to the current mouse position.
   */
  const dragIndicator = () => {
    if (centerlineSpriteRef.current) {
      if (sliceCount && lineLength) {
        const centerlineMapping = cprVesselData?.sliceData?.[cprSliceidx]?.centerlineMapping;
        if (cprVersion === 2 && centerlineMapping) {
          // Get the index of the centerline point nearest to the mouse position.
          const centerline = getCPRSliceData(cprSliceidx)?.anno;
          if (centerline) {
            const sliceIndex = getNearestSliceIndex(
              mousePosRef.current,
              centerline,
              centerlineMapping.sliceToInterpolatedCenterline
            );
            onSliceIndicatorChange(sliceIndex);
          }
        }
        // Data from endpoint version 1 uses a simple linear mapping.
        else {
          // This uses a super simple (pick the centerline point nearest to the mouse Y position).
          const shapeBounds = centerlineSpriteRef.current.geometry.bounds;
          const y = mousePosRef.current.y - shapeBounds.minY;
          const shapeHeight = shapeBounds.maxY - shapeBounds.minY;
          // Limit the new slice index to the actual slice range.
          const index = clampNumber(Math.round((sliceCount - 1) * (y / shapeHeight)), 0, sliceCount - 1);
          onSliceIndicatorChange(index);
        }
      }
    }
  };

  /**
   * Drag the node to the current mouse position.
   */
  const dragNode = () => {
    const centerline = getCPRSliceData(cprSliceIndexRef.current)?.anno;
    if (centerline && editNodesDataRef.current && draggingNode && draggingNodeRef.current) {
      // Limit the node position to be inside the image.
      const imageSize = getImageSize(cprSliceIndexRef.current);
      const clippedMousePos = {
        x: clampNumber(mousePosRef.current.x, 0, imageSize.x - 1),
        y: clampNumber(mousePosRef.current.y, 0, imageSize.y - 1),
      };
      draggingNodeRef.current.x = clippedMousePos.x;
      draggingNodeRef.current.y = clippedMousePos.y;
      editNodesDataRef.current[draggingNodeRef.current.nodeIndex] = {
        x: draggingNodeRef.current.x,
        y: draggingNodeRef.current.y,
      };
      updateBezierLine(editNodesDataRef.current);
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    // Get the mouse position over the image in the [0, 0] - [renderWidth, renderHeight] range.
    mousePosRef.current = getMousePos(e);
    let onIntersect: number[] | undefined;

    if (draggingIndicator) {
      dragIndicator();
    } else if (draggingNode) {
      dragNode();
    } else if (centerlineEdit.editing && editNodesDataRef.current) {
      onIntersect = intersectsNodes(editNodesDataRef.current, mousePosRef.current);
    }
    // Loop through every vessel and see if the mouse is hovering over it; if it is then position the marker (ie text label)
    // relative to the mouse and make it visible.
    else if (showAnnos && showAuxAnnos) {
      // Get the array of vessel lines for the current slice (rotational direction).
      const activeAux = getCPRSliceData(cprSliceIndexRef.current)?.auxAnno;
      if (activeAux) {
        const toScaleLineThickness = calculateScaledLineThickness();
        let isOver = false;

        const scalingFactor = 0.75;
        const defaultScale = defaultScaleRef.current ?? 1;
        const zoom = calculateScaledLineThickness() / defaultScale;
        const scale = defaultScale * (scalingFactor * zoom + 1.0 - scalingFactor);
        Object.keys(activeAux).forEach((id) => {
          // Get the array of vessel points that the mouse is over.
          const mouseOver = intersectsNodes(activeAux[id], mousePosRef.current, 5 / scale);
          const marker = auxMarkerRef.current[id];
          if (marker && marker.transform) {
            if (mouseOver.length) {
              marker.visible = true;
              marker.x = mousePosRef.current.x - marker.width;
              marker.y = mousePosRef.current.y - marker.height / 2;
              marker.scale.x = 1.0 / toScaleLineThickness;
              marker.scale.y = 1.0 / toScaleLineThickness;
              mouseOverAuxCenterlineRef.current = id;
              document.body.style.cursor = 'pointer';
              isOver = true;
            } else {
              marker.visible = false;
            }
          }
        });
        // If the mouse isn't over any vessels then clear the mouseOver value and restore the cursor style to normal.
        if (!isOver) {
          mouseOverAuxCenterlineRef.current = null;
          document.body.style.cursor = '';
        }
      }
    }
    if (onIntersect) {
      setMouseIntersectsNodes(onIntersect);
      if (onIntersect.length) {
        document.body.style.cursor = 'copy';
      } else {
        document.body.style.cursor = '';
      }
    }
  };

  const onVisibilityChange = () => {
    // Ask the user if they are sure they want to save their edits and hide the centerline.
    if (showAnnos && centerlineEdit.editing && centerlineEdit.modified && onSaveEdits) {
      // Update the centerline points as required.
      updateCenterlinePoints();
      onSaveEdits(true);
    } else {
      setShowAnnos(!showAnnos);
      // ensure that the aux annos are visible or hidden
      setShowAuxAnnos(!showAnnos);
    }
  };

  const onAuxVisibilityChange = () => {
    setShowAuxAnnos(!showAuxAnnos);
    if (!showAuxAnnos === true) {
      setShowAnnos(true);
    }
  };

  const onDrag = (pos: PointObject) => {
    // Set the markers to the correct Y position for the current rotational slice, pan and zoom.
    transformMarkers();
  };

  const onResize = () => {
    // Recreate the centerline sprites.
    clearAndCreateCentreLineSprite(cprSliceIndexRef.current);
    // Recreate the aux centerline sprites.
    clearAndCreateAuxCentreLineSprites(cprSliceIndexRef.current);
    // Recreate the high, mid, low slice indicator handles.
    clearAndCreateIndicatorSprites();
    updateNodes();

    // Set the markers to the correct Y position for the current rotational slice, pan and zoom.
    transformMarkers();
  };

  const onZoom = () => {
    // Recreate the centerline sprites.
    clearAndCreateCentreLineSprite(cprSliceIndexRef.current);
    // Recreate the aux centerline sprites.
    clearAndCreateAuxCentreLineSprites(cprSliceIndexRef.current);
    // Recreate the high, mid, low slice indicator handles.
    clearAndCreateIndicatorSprites();
    updateNodes();
    // Set the markers to the correct Y position for the current rotational slice, pan and zoom.
    transformMarkers();
  };

  const onContainerReady = () => {
    // Set the markers to the correct Y position for the current rotational slice, pan and zoom.
    transformMarkers();
  };

  const onDocumentKeyDown = (e: KeyboardEvent) => {
    const key = e.code;
    if (hoveredNodeRef.current && CENTRELINE_SETTINGS.DELETE_NODE_KEYS.includes(key)) {
      deleteNode(hoveredNodeRef.current.nodeIndex);
    }
  };

  /**
   * Update the centerline points if they have been changed.
   */
  const updateCenterlinePoints = () => {
    // Update the centerline points as required.
    if (centerlineEdit.editing && centerlineEdit.modified && editNodesDataRef.current) {
      const centerline = xyCoordsToLineArray(convertBezierLineToPoints(editNodesDataRef.current));
      // Update the edited centerline points, ready to be saved to the backend if the user accepts the changes.
      dispatchEditModeAction && dispatch(centerlineEditActions.updateCenterline(centerline));
    }
  };

  /**
   * Double click to enter edit mode (for CPR V2 only, this feature was retired for CPR V1).
   */
  const onDoubleClick = (event: React.MouseEvent) => {
    // The zoom action (L+R buttons) also triggers a double click! ... but we can spot these because buttons will equal 2.
    if (
      onStartEditing &&
      event.buttons !== 2 &&
      isCanvas(event.nativeEvent.target) &&
      !centerlineEdit.editing &&
      cprVersion === 2
    ) {
      onStartEditing(showAuxAnnos);
    }
  };

  const onSaveEditsButton = () => {
    if (onSaveEdits) {
      // Update the centerline points as required.
      updateCenterlinePoints();
      onSaveEdits(false);
    }
  };

  const onConfirmExitCenterline = () => {
    if (!draggingNode && !centerlineEdit.modified) {
      onDiscardCenterlineEdits();
    } else {
      // Only show confirm dialog if changes have been made
      setConfirmingDiscard(true);
    }
  };

  const onDiscardCenterlineEdits = () => {
    setConfirmingDiscard(false);
    dispatchEditModeAction && dispatch(centerlineEditActions.stopEditing());
    onDiscardEdits && onDiscardEdits();
  };

  return (
    <div
      className="cprViewer"
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDoubleClick={onDoubleClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
    >
      <div className="cprViewer-controls">
        {!centerlineEdit.editing && (
          <button
            title={showAuxAnnos ? 'Hide Other Vessels' : 'Show Other Vessels'}
            className={cn('cprViewer-control', {
              'cprViewer-control--active': showAuxAnnos,
            })}
            onClick={onAuxVisibilityChange}
          >
            <VesselsIcon />
          </button>
        )}
      </div>
      <div className="cprViewer-view">
        <WebGLViewer
          viewType={KEY_CPR}
          transpose={true}
          resizeMode={RESIZE_MODE.FIT_VERTICAL}
          onReady={onReady}
          onLoad={onLoad}
          slice={cprSliceidx}
          onSliceChange={handleCprSliceChange}
          onDrag={onDrag}
          onZoom={onZoom}
          onResize={onResize}
          onContainerReady={onContainerReady}
          triggerResize={triggerResize}
          disableKeyboardControls
          windowLevels={windowLevels}
          onWindowLevelsChange={onWindowLevelsChange}
          disableControls={draggingIndicator || (draggingNode ? true : false)}
          // The only way we can or should have anything we want to pass the the WebGLViewer is if we got it as a prop ourselves from an edit centerline.
          shapeData={cprVesselData?.shape}
          imageBufferData={cprVesselData?.imageBufferData}
          onHueChange={(hue) => {
            if (hueRef.current && hueRef.current.setValue) hueRef.current.setValue(hue || '-');
          }}
          editMode={centerlineEdit.editing}
          triggerResetPanAndZoom={triggerResetPanAndZoom}
          onCleanup={cleanup}
        />
        {onSaveEdits && (
          <div
            className={cn('cprViewer-editControls', {
              'cprViewer-editControls--active': centerlineEdit.editing,
            })}
          >
            <Button tab-index="-1" theme="secondary" size="default" onClick={onConfirmExitCenterline}>
              Exit
            </Button>
            <Button size="default" disabled={!draggingNode && !centerlineEdit.modified} onClick={onSaveEditsButton}>
              Save
            </Button>
          </div>
        )}
        <Confirm
          onSuccess={onDiscardCenterlineEdits}
          open={confirmingDiscard}
          title="Exit without saving?"
          onDismiss={() => setConfirmingDiscard(false)}
          dontShowAgainCheckbox={false}
        >
          Please confirm you wish to exit edit without saving any changes.
        </Confirm>
      </div>
      <ToolBar
        showWindowLevels={!!windowLevels}
        windowLevels={windowLevels}
        windowLabel={windowLabel}
        vessel={selectedVesselName}
        slice={String(sliceidx)}
        HURef={hueRef}
        visibility={showAnnos}
        onVisibilityChange={onVisibilityChange}
        showVisibilityIcon={true}
        screenshotDisabled={screenshotDisabled}
        screenshotRef={screenshotRef}
        viewName={viewName}
      />
    </div>
  );
};
