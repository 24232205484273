import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import showToast from '../components/Toast/showToast';
import { STUDY_LOAD_ERROR_MSG, PRIORITY_VESSEL_DEFAULT } from '../config';
import { useAppDispatch, useAppSelector } from '../hooks';
import { sortVessels } from '../selectors/vessels';
import { globalFlagsActions } from '../reducers/global-flags/globalFlagsSlice';
import { useUpdateBackendVersionHead, useUpdateVersionHead } from './use-version-head';
import { useLoadersLoaded, useLoadersFailed } from '../reducers/initialLoad/utils';
import { useVesselStateSelector } from '../selectors/vessels';
import { vesselDataActions } from '../reducers/vesselData/vesselDataSlice';

/**
 * Hook to retrieve patient, vessel and calcium data associated with the study in the CalciumScoreSlice.
 * Responses get persisted in the CalciumScoreSlice.
 */
export default function usePatientData() {
  const dispatch = useAppDispatch();
  const updateVersionHead = useUpdateVersionHead();
  const updateBackendVersionHead = useUpdateBackendVersionHead();
  const versionHead = useAppSelector((state) => state.initialLoad.unifiedId?.version);
  const navigate = useNavigate();

  // Set this to true if all loader have loaded their data.
  const loadersLoaded = useLoadersLoaded();
  const loadersFailed = useLoadersFailed();

  // Info required to set the initially loaded vessel.
  const studyData = useAppSelector((state) => state.study.studyData);
  const { vesselData } = useVesselStateSelector();
  const initialDataLoaded = useAppSelector((state) => state.globalFlags.initialDataLoaded);

  useEffect(() => {
    if (versionHead == null) return;

    (async () => {
      // Get the current head version which we will need to fetch the other data.
      updateVersionHead(versionHead);
      updateBackendVersionHead(versionHead, true);
    })();
  }, [versionHead]); // eslint-disable-line

  /**
   * This useEffect is to set the initial vessel once the required data has loaded.
   * TODO: This will move into one of the new Loader components.
   */
  useEffect(() => {
    if (!initialDataLoaded && studyData && vesselData) {
      // Set the priority vessel for this study.
      const vessels = sortVessels(vesselData);
      const priorityVessel =
        vessels.find((v) => v.toLowerCase() === studyData?.priority_vessel_id?.toLowerCase()) ??
        PRIORITY_VESSEL_DEFAULT;
      dispatch(vesselDataActions.updatePriorityVessel(priorityVessel));

      // Find the slice on the priority vessel with the maximum stenosis.
      const data = vesselData[priorityVessel] ?? {};
      const vesselStenosis = data.stenosis ?? [];
      const highestStenosisIdx = vesselStenosis.indexOf(Math.max(...vesselStenosis));
      // Set the the highest priority slice to be the one with the highest stenosis.
      dispatch(vesselDataActions.updateHighestPriorityIdx(highestStenosisIdx));
      // Set the selected vessel slices centered on the maximum stenosis.
      dispatch(
        vesselDataActions.updateSliceIndices({
          mid: highestStenosisIdx,
          high: Math.max(highestStenosisIdx - 10, 0),
          low: Math.min(highestStenosisIdx + 10, vesselStenosis.length - 1),
        })
      );
      // Set the selected vessel to the priority vessel.
      dispatch(vesselDataActions.selectVessel(priorityVessel));
    }
  }, [dispatch, initialDataLoaded, studyData, vesselData]);

  /**
   * This useEffect is to set initialDataLoaded once all the initial data has loaded.
   */
  useEffect(() => {
    if (loadersLoaded) {
      dispatch(globalFlagsActions.setInitialDataLoaded(true));
    }
  }, [dispatch, loadersLoaded]);

  /**
   * Detect if any loader(s) failed and act on it once and only once.
   */
  useEffect(() => {
    if (loadersFailed) {
      showToast.error(STUDY_LOAD_ERROR_MSG);
      navigate('/');
    }
  }, [navigate, loadersFailed]);
}
