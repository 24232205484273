import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Study, StudyData } from '../../context/types';
export interface StudyState {
  currentStudy: Study | undefined;
  studyData: StudyData | null;
}

export const createDefaultStudySliceState = (): StudyState => {
  return {
    currentStudy: undefined,
    studyData: null,
  };
};

export const studySlice = createSlice({
  name: 'study',
  initialState: createDefaultStudySliceState(),
  reducers: {
    setStudyData: (state, action: PayloadAction<StudyData>) => {
      state.studyData = action.payload;
    },
    setCurrentStudy: (state, action: PayloadAction<any>) => {
      state.currentStudy = action.payload;
    },
  },
});

export const studyActions = studySlice.actions;
