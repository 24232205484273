import * as phi from '../../utils/phi';
import { CalciumScore, Study, StudyData } from '../../context/types';
import {
  lesionsDataInterface,
  LesionInterface,
  PatientSummaryInterface,
  StudyInterface,
  VesselSegmentSummariesDataInterface,
  VesselSegmentSummariesInterface,
  VesselsInterface,
  VesselSliceData,
  VesselSliceDataInterface,
  VesselSlicesInterface,
  nonContrastInterface,
} from './types';
import { ContrastLesionDataResponse } from '../lesion/types';
import { PLAQUE, STENOSIS } from '../../config';
import { VesselDataResponse } from '../vesselData/types';

export const updatePatientStats = (patientSummary: PatientSummaryInterface, calciumScore?: number) => {
  return {
    cad_rads: patientSummary.cadRadsCategory,
    sis: patientSummary.sis,
    maximum_stenosis: parseMaxStenosis(patientSummary.stenosisMax),
    calcium_score: calciumScore,
    priority_vessel: patientSummary.priorityVesselKey,
  };
};

export const updateStudyData = (
  studyData: StudyData,
  study: StudyInterface,
  patientSummary: PatientSummaryInterface,
  vesselKeys: string[],
  calciumScore?: number
) => {
  return {
    ...studyData,
    vulnerability: patientSummary.vulnerability,
    sis: patientSummary.sis,
    stent: patientSummary.stent,
    non_diagnostic: patientSummary.nonDiagnostic,
    graft: patientSummary.graft,
    cad_rads_cat: patientSummary.cadRadsCategory,
    cad_rads_str: patientSummary.cadRadsCategory + patientSummary.cadRadsModifiers,
    lesion_count: patientSummary.lesionCount,
    stenosis_max_segment_id: patientSummary.stenosisMaxSegment,
    priority: patientSummary.priority,
    vulnerable_count: patientSummary.vulnerabilityCount,
    vp_biomarker_counts: {
      lap: patientSummary.lowAttenuationPlaqueBiomarkerCount,
      nrs: patientSummary.napkinRingSignBiomarkerCount,
      pr: patientSummary.positiveRemodellingIndexBiomarkerCount,
      sc: patientSummary.spottyCalcificationBiomarkerCount,
    },
    plaque_composition_counts: {
      none: patientSummary.plaqueCompositionNoneCount,
      nca: patientSummary.plaqueCompositionNCACount,
      m: patientSummary.plaqueCompositionMixedCount,
      ca: patientSummary.plaqueCompositionCACount,
    },
    plaque_volume: {
      volume: {
        ca: patientSummary.calcifiedPlaqueVolume,
        lap: patientSummary.lowAttenuationPlaqueVolume,
        nca: patientSummary.nonCalcifiedPlaqueVolume,
        total:
          patientSummary.calcifiedPlaqueVolume +
          patientSummary.lowAttenuationPlaqueVolume +
          patientSummary.nonCalcifiedPlaqueVolume,
      },
      burden: {
        ca: patientSummary.calcifiedPlaqueBurden,
        lap: patientSummary.lowAttenuationPlaqueBurden,
        nca: patientSummary.nonCalcifiedPlaqueBurden,
        total:
          patientSummary.calcifiedPlaqueBurden +
          patientSummary.lowAttenuationPlaqueBurden +
          patientSummary.nonCalcifiedPlaqueBurden,
      },
    },
    vessels: vesselKeys,
    patient_data: {
      name: study.key,
      sex: study.patientSex,
      age: study.patientAge,
    },
    calcium_score: calciumScore,
    maximum_stenosis: parseMaxStenosis(patientSummary.stenosisMax),
    priority_vessel_id: patientSummary.priorityVesselKey,
    // missing keys
    //   meta_data
    //   scan_quality
  };
};

export const updateCurrentStudy = async (
  currentStudy: Study,
  study: StudyInterface,
  patientSummary: PatientSummaryInterface,
  calciumScore?: number
) => {
  const newStudyData: Study = {
    ...currentStudy,
    study_id: study.key,
    patient_cipher: study.patientCipher,
    study_date: study.studyDate,
    cad_rads: patientSummary.cadRadsCategory + patientSummary.cadRadsModifiers,
    calcium_score: calciumScore,
    stenosis: `${parseMaxStenosis(patientSummary.stenosisMax)} (${patientSummary.stenosisMaxSegment})`,

    // missing keys in new study endpoint for patientStats
    //   active_run
    //   ai_assessed
    //   analysis_version
    //   can_be_viewed
    //   client_id
    //   is_edited
    //   is_report_approved
    //   mesa_percentile
    //   report_active_review
    //   report_approval
    //   ris_status
    //   series
    //   stenosis
    //   vulnerable_plaque
    //   workflow_status
  };
  // decrypt patient cipher after update it from the new study endpoint
  return await phi.decryptDashboardItem(newStudyData);
};
export const updateContrastLesionData = (
  lesions: LesionInterface[],
  contrastLesionData: ContrastLesionDataResponse
) => {
  const contrastLesioUpdated = lesions.reduce((acc, lesions) => {
    const vesselKey = lesions.vesselKey.toLowerCase();
    const lesion = lesions.lesions.reduce((acc, lesion) => {
      const lesionKey = lesion.key;
      return {
        ...acc,
        [lesionKey]: {
          ...contrastLesionData[vesselKey][lesionKey],
          priority_slice: lesion.priorityPosition,
          plaque_composition: parsePlaqueComposition(lesion.plaqueComposition),
          slices: lesion.vesselSlicesRange,
          vulnerability: lesion.vulnerability,
          stenosis_max: parseMaxStenosis(lesion.stenosisMax),
          segment: parseSegment(lesion.vesselSegmentKey),
          vp_biomarker_counts: {
            lap: lesion.lowAttenuationPlaqueBiomarker === true ? 1 : 0,
            pr: lesion.positiveRemodellingIndexBiomarker === true ? 1 : 0,
            sc: lesion.spottyCalcificationBiomarker === true ? 1 : 0,
            nrs: lesion.napkinRingSignBiomarker === true ? 1 : 0,
          },
          // missing keys in new study endpoint
          // added_slices
        },
      };
    }, {});
    return { ...acc, [vesselKey]: lesion };
  }, {});
  return contrastLesioUpdated;
};

//update vessel data
export const updateVesselData = (
  vesselData: VesselDataResponse,
  vessel: VesselsInterface[],
  vesselSlices: VesselSlicesInterface[],
  vesselSegmentSummaries: VesselSegmentSummariesInterface[],
  lesions: LesionInterface[],
  calciumScoreData: CalciumScore | null
) => {
  const vesselSlicesData: VesselSliceDataInterface = vesselSlices.reduce((acc, { vesselKey, vesselSlices }) => {
    const slicesData: VesselSliceData = vesselSlices.reduce(
      (acc, { severityIndicator, relatedLesions, stenosis }): any => {
        return {
          //centerline is missing in new study endpoint will be added in the future, so now is populated with the old data : AP-3700
          // centerline: [...acc.centerline, [0, 0, 0]],
          slice_cmap: [...acc.slice_cmap, severityIndicator],
          slice_to_lesion_mapping: [...acc.slice_to_lesion_mapping, relatedLesions],
          stenosis: [...acc.stenosis, stenosis],
        };
      },
      { centerline: [], slice_cmap: [], slice_to_lesion_mapping: [], stenosis: [] }
    );
    return { ...acc, [vesselKey.toLowerCase()]: slicesData };
  }, {});

  // total is missing in new study endpoint, now it is [0, n_slices] : AP-3700
  const vesselSegmentSummariesData: VesselSegmentSummariesDataInterface = vesselSegmentSummaries.reduce(
    (acc, { vesselKey, vesselSegmentSummaries }) => {
      const vesselSegmentData = vesselSegmentSummaries.reduce((acc, { key, vesselSlicesRange }) => {
        return {
          ...acc,
          [key.charAt(0).toLowerCase()]: vesselSlicesRange,
        };
      }, {});
      return { ...acc, [vesselKey.toLowerCase()]: vesselSegmentData };
    },
    {}
  );

  const lesionData: lesionsDataInterface = lesions.reduce((acc, { vesselKey, lesions }) => {
    const lesionVesselData = lesions.reduce(
      (acc, { key, id }): any => {
        return {
          contrast_lesion_ids: [...acc.contrast_lesion_ids, key],
          lesion_by_id: { ...acc.lesion_by_id, [id]: key },
        };
      },
      { contrast_lesion_ids: [], lesion_by_id: {} }
    );

    return {
      ...acc,
      [vesselKey.toLowerCase()]: { lesionVesselData },
    };
  }, {});

  const vesselsData: VesselDataResponse = vessel
    .map((vessel) => {
      const key = vessel.key.toLowerCase();

      let calciumScore = {};
      if (calciumScoreData !== null && calciumScoreData[key as keyof CalciumScore]) {
        calciumScore = {
          calcium_score: calciumScoreData[key as keyof CalciumScore],
        };
      }

      return {
        [key]: {
          ...vesselData[key],
          ...vesselSlicesData[key],
          priority_lesion_id: lesionData[key]?.lesionVesselData.lesion_by_id[vessel.priorityLesionId],
          contrast_lesion_ids: lesionData[key]?.lesionVesselData.contrast_lesion_ids || [],
          contrast_lesion_count: vessel.lesionCount,
          disease_range: vessel.diseaseRange,
          graft: vessel.graft,
          calciumScore,
          n_slices: vessel.vesselSlicesNo,
          non_diagnostic: vessel.nonDiagnostic,
          plaque_composition_counts: {
            none: vessel.plaqueCompositionNoneCount,
            nca: vessel.plaqueCompositionNCACount,
            m: vessel.plaqueCompositionMixedCount,
            ca: vessel.plaqueCompositionCACount,
          },
          segments: { ...vesselSegmentSummariesData[key], total: [0, vessel.vesselSlicesNo] as [number, number] },
          stent: vessel.stent,
          vertex_to_slice_mapping: vessel.vertexToSliceMapping,
          vp_biomarker_counts: {
            lap: vessel.lowAttenuationPlaqueBiomarkerCount,
            pr: vessel.positiveRemodellingIndexBiomarkerCount,
            nrs: vessel.napkinRingSignBiomarkerCount,
            sc: vessel.spottyCalcificationBiomarkerCount,
          },
          vulnerability: vessel.vulnerability,
          vulnerability_count: vessel.vulnerabilityCount,
          // missing keys
          //  centerline
        },
      };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return vesselsData;
};

export const updateCalciumScore = (nonContrastResult: nonContrastInterface) => {
  return {
    rca: nonContrastResult.rcaCalciumScore,
    lcx: nonContrastResult.lcxCalciumScore,
    lm: nonContrastResult.lmCalciumScore,
    lad: nonContrastResult.ladCalciumScore,
    total:
      nonContrastResult.rcaCalciumScore +
      nonContrastResult.lcxCalciumScore +
      nonContrastResult.lmCalciumScore +
      nonContrastResult.ladCalciumScore,
  };
};

const parseMaxStenosis = (stenosis: number) => {
  const maxStenosis = stenosis * 100;

  switch (true) {
    case maxStenosis === 0:
      return STENOSIS.none;
    case maxStenosis >= 1 && maxStenosis <= 24:
      return STENOSIS.min;
    case maxStenosis >= 25 && maxStenosis <= 49:
      return STENOSIS.mild;
    case maxStenosis >= 50 && maxStenosis <= 69:
      return STENOSIS.moderate;
    case maxStenosis >= 70 && maxStenosis <= 99:
      return STENOSIS.severe;
    case maxStenosis === 100:
      return STENOSIS.occluded;
    default:
      return STENOSIS.none;
  }
};

const parsePlaqueComposition = (plaqueComposition: string) => {
  switch (plaqueComposition) {
    case PLAQUE.nca.toUpperCase().replace('-', '_'):
      return 'nca';
    case PLAQUE.ca.toUpperCase():
      return 'ca';
    case PLAQUE.m.toUpperCase():
      return 'm';
    default:
      return 'none';
  }
};

const parseSegment = (segment: string) => {
  if (segment) {
    return segment.charAt(0).toLowerCase();
  }
  return 'total';
};
