import React, { useMemo } from 'react';
import cn from 'classnames';
import { RISK_LEVELS } from '../../config';
import { formatDOB } from '../../utils/shared';

function formatValue(value: number | string, subtext: {} | null | undefined, cubed: boolean) {
  if (!value && value !== 0) return '-'; // Check if value is falsy except 0

  return (
    <>
      {`${value} `}
      {!!subtext && (
        <span className="stats__stat-subtext">
          {subtext}
          {cubed ? <sup>3</sup> : ''}
        </span>
      )}
    </>
  );
}

interface DataType {
  label: string;
  value: string | number;
  show: boolean;
  subtext: string;
  cubed: boolean;
  riskLevel: string;
}

interface StatsProps {
  data: DataType[];
  inline?: boolean;
  birthDate?: string;
  scanDate?: string;
}

export const Stats: React.FunctionComponent<StatsProps> = ({ data = [], birthDate: patientDOB, scanDate }) => {
  const birthDate = useMemo(() => formatDOB(patientDOB || null), [patientDOB]);

  return (
    <dl className="value-list value-list--large stats__value-list">
      <div className="value-list__item">
        <dt className="value-list__label">Date of Birth</dt>
        <dd className="value-list__value">{birthDate}</dd>
      </div>

      <div className="value-list__item">
        <dt className="value-list__label">Scan Date</dt>
        <dd className="value-list__value">{scanDate}</dd>
      </div>

      {data.map(
        ({ label, value, subtext, cubed, riskLevel, show }, index) =>
          show && (
            <div className="value-list__item" key={index}>
              <dt className="value-list__label">{label}</dt>
              <dd
                className={cn('value-list__value', RISK_LEVELS.includes(riskLevel) ? `disease-risk--${riskLevel}` : '')}
              >
                {formatValue(value, subtext, cubed)}
              </dd>
            </div>
          )
      )}
    </dl>
  );
};
