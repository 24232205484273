import { MutableRefObject, useCallback } from 'react';
import showToast from '../../components/Toast/showToast';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { reportActions } from '../../reducers/report/reportSlice';
import { calciumScoreActions } from '../../reducers/calciumScore/calciumScoreSlice';
import * as api from '../../utils/api';
import { useUpdateBackendVersionHead, useUpdateVersionHead } from '../../hooks/use-version-head';
import { LesionDataResponse } from '../../reducers/lesion/types';
import { lesionActions } from '../../reducers/lesion/lesionSlice';

function useRefreshAndSetReportData() {
  const dispatch = useAppDispatch();

  return useCallback(
    (patientId: string, runId: string, versionString?: string) => {
      dispatch(reportActions.setFetching(true));
      return api
        .fetchReport(patientId, runId, versionString)
        .then((response) => {
          dispatch(reportActions.setCurrentReport(response));
        })
        .finally(() => dispatch(reportActions.setFetching(false)));
    },
    [dispatch]
  );
}

function useRefreshLesionsCallback() {
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const dispatch = useAppDispatch();
  const patientID = useAppSelector((state) => state.patient.patientID);
  const refreshAndSetReportData = useRefreshAndSetReportData();

  return useCallback(
    async (versionString: string) => {
      try {
        if (patientID && runID) {
          const lesions: LesionDataResponse = await api.getJSON(
            `/data/${patientID}/${runID}/web-data/lesions?version=${versionString}`
          );
          dispatch(lesionActions.setLesionData(lesions));
          // Refresh report data when lesions have updated
          refreshAndSetReportData(patientID, runID, versionString).catch((error) => {
            console.error(error);

            // try again if there is an error, but only once
            refreshAndSetReportData(patientID, runID, versionString).catch((error) => {
              console.error('Failed a retry on fetching the report.', error);
              // It's highly unlikely that we'd ever get to this point
              showToast.warning(
                'Lesions have successfully been updated. The Report Overview may not reflect the new calcium score. Please refresh your browser.'
              );
            });
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    [patientID, runID, dispatch, refreshAndSetReportData]
  );
}

export function useChangeCalciumCategoryCallback(
  selectedLesions: any[],
  setUpdatedLesions: (lesions: []) => void,
  deselectLesionsRef: MutableRefObject<any | null>,
  hideAll: () => void,
  setChosenVessel: (vesel: any | null) => void
) {
  const updateVersionHead = useUpdateVersionHead();
  const updateBackendVersionHead = useUpdateBackendVersionHead();
  const versionHead = useAppSelector((state) => state.store.versionHead);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const patientID = useAppSelector((state) => state.patient.patientID);
  const refreshLesions = useRefreshLesionsCallback();
  const dispatch = useAppDispatch();

  return useCallback(
    async (category: any) => {
      // below structure important such that edit lock is released
      // regardless of promise result
      const innerPromise = new Promise<void>(async (resolve, reject) => {
        const errorMsg = 'Failed to update lesion categories';
        const updated: any = {};
        selectedLesions.forEach((lesion) => {
          if (lesion.category.toLowerCase() !== category.new.toLowerCase()) {
            updated[lesion.lesion_id] = {
              lesion_id: lesion.lesion_id,
              category: category.new.toLowerCase(),
            };
          }
        });
        const updatedArray: any = [];
        Object.keys(updated).forEach((key) => {
          updatedArray.push(updated[key]);
        });
        const payload: any = {
          study_id: patientID,
          run_id: runID,
          payload: {
            lesions: updatedArray,
            version_id: versionHead,
          },
        };

        if (!updatedArray.length) {
          return resolve();
        }
        try {
          const socket = await api.getWebsocket('/ws/tasks/edit-lesions');
          socket.addEventListener('open', (_event) => {
            socket.send(JSON.stringify(payload));
          });
          socket.addEventListener('error', (error) => {
            console.error(error);
            socket.close();
            reject(errorMsg);
          });
          socket.addEventListener('message', (event) => {
            const eventObj = JSON.parse(event.data);
            if (eventObj.type === 'error') {
              socket.close();
              reject(eventObj.data.message);
            }
            switch (eventObj.type) {
              // TODO currently blocking until save occurs however
              // it is possible to unblock on result if the can_save
              // attribute is set (this has not been implemented due)
              // to an ongoing issue with edits being blocked incorrectly
              case 'connected':
                break;
              case 'result':
                dispatch(calciumScoreActions.setCalciumScoreData(eventObj.data.result));
                break;
              case 'result_save':
                const newVersionString = eventObj.data.version_id;
                updateVersionHead(newVersionString);
                updateBackendVersionHead(newVersionString).then(() => {
                  refreshLesions(newVersionString);
                  setUpdatedLesions(updatedArray);
                  socket.close();
                  resolve();
                });
                break;
              default:
                socket.close();
                console.error(`received unknown message type: ${eventObj.type}`);
                reject(errorMsg);
            }
          });
        } catch (err) {
          reject(errorMsg);
        }
      });
      try {
        await innerPromise;
      } catch (errMsg) {
        if (typeof errMsg === 'string') {
          showToast.error(errMsg);
        }
      } finally {
        hideAll();
        setChosenVessel(null);
        deselectLesionsRef.current && deselectLesionsRef.current();
      }
    },
    [
      selectedLesions,
      patientID,
      runID,
      versionHead,
      dispatch,
      updateBackendVersionHead,
      updateVersionHead,
      refreshLesions,
      hideAll,
      deselectLesionsRef,
      setChosenVessel,
      setUpdatedLesions,
    ]
  );
}
