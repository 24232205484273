import React from 'react';
import cn from 'classnames';

interface RangeSliderProps {
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  labelText?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
}

export const RangeSlider: React.FunctionComponent<RangeSliderProps> = ({
  value = 1,
  min = 1,
  max = 100,
  step = 1,
  labelText = null,
  disabled,
  onChange,
}) => {
  return (
    <div className="range-slider__content">
      {labelText && (
        <label
          className={cn('range-slider__label', {
            'range-slider__label--disabled': disabled,
          })}
        >
          {labelText}
        </label>
      )}
      <input
        className="range-slider"
        type="range"
        value={value}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        onChange={(event) => {
          onChange && onChange(parseFloat(event.target.value));
        }}
      />
    </div>
  );
};
