/**
 * The VolumeDataLoader is responsible for loading the the contrast volume data from the backend.
 * The loader itself will start loading the AI analysed volume on mount, the remainding volumes can be loaded via loadVolumeData().
 */
import React, { useEffect, useRef } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { initialLoadActions, InitialLoader, LoadState } from '../initialLoad/initialLoadSlice';
import PromisePool from 'es6-promise-pool';
import cornerstone from 'cornerstone-core';
import { CornerstoneImage } from '../../views/CTVolume/ReactVTKJS/ReactVTKJSTypes';
import {
  contrastActions,
  DicomImage,
  CornerstoneMetaData,
  createVolumeData,
  getScanDistance,
  sortDicomImages,
} from './contrastSlice';
import showToast from '../../components/Toast/showToast';

const loaderName = InitialLoader.CONTRAST_VOLUME;

/**
 * Load the contrast volume that was analysed (if any) and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export async function loadVolumeData(series: string, slices: string[], dispatch: AppDispatch, initialLoad: boolean) {
  if (initialLoad) {
    dispatch(initialLoadActions.setLoadState({ loaderName, loadState: LoadState.LOADING }));
  }
  // Record that this volume is currently being loaded.
  dispatch(contrastActions.setVolumeDataLoadState({ series, loadState: LoadState.LOADING }));

  // Load the CT images via Cornerstone.
  const imageIds = slices.map((slice: string) => `wadouri:${slice}`);
  const images: DicomImage[] = [];
  const sortedImages: DicomImage[] = [];

  // The PromisePool uses a producer function to get the next Promise when a position becomes available.
  let imageCountLoaded = 0;
  let nextIndex = 0;
  const promiseProducer = () => {
    const index = nextIndex++;

    // Stop loading images if they have all been loaded (or are currently loading).
    if (index >= imageIds.length) {
      return null;
    }

    // Load the image.
    return cornerstone
      .loadImage(imageIds[index])
      .then((cornerstoneImage: CornerstoneImage) => {
        const metaData: CornerstoneMetaData = cornerstone.metaData.get('imagePlaneModule', imageIds[index]);
        const pixelCount = cornerstoneImage.width * cornerstoneImage.height;
        const pixelData = cornerstoneImage.getPixelData();
        // Apply the image.slope and image.intercept adjustment to get the actual HU value.
        const data: Int16Array = new Int16Array(pixelCount);
        for (let i = 0; i < pixelCount; i++) {
          data[i] = pixelData[i] * cornerstoneImage.slope + cornerstoneImage.intercept;
        }
        const image: DicomImage = {
          data,
          metaData: {
            dimensions: [cornerstoneImage.width, cornerstoneImage.height],
            spacing: [cornerstoneImage.columnPixelSpacing, cornerstoneImage.rowPixelSpacing],
            position: metaData.imagePositionPatient,
            xAxis: metaData.rowCosines,
            yAxis: metaData.columnCosines,
            scanDistance: getScanDistance(metaData),
          },
        };
        images[index] = image;
        sortedImages.push(image);

        // If we have two images loaded we can initialize the volume.
        imageCountLoaded++;
        if (imageCountLoaded === 2) {
          const volumeData = createVolumeData(imageIds.length, images);
          if (volumeData != null) {
            dispatch(contrastActions.setVolumeData({ series, volumeData }));
            if (initialLoad) {
              dispatch(initialLoadActions.setLoadState({ loaderName, loadState: LoadState.LOADED }));
            }
          }
        }
        // If we have more than two images loaded we can add the slice to the existing model.
        else if (imageCountLoaded > 2) {
          dispatch(contrastActions.setVolumeDataDicomImage({ series, slice: index, image }));
        }
      })
      .catch((error: Error) => {
        console.warn('ContrastLoader failed to load slice:', index, error);
        dispatch(contrastActions.setVolumeDataDicomImageFailed({ series }));
      });
  };

  // Create the pool, start it, and wait for it to finish. So we will have all the images we want loaded and cached by cornerstone.
  // @ts-ignore
  new PromisePool(promiseProducer, 20).start().then(
    () => {
      // After all images have loaded (or failed to load) sort them in their correct positions and create the volumeData.
      // NOTE: When the images are sorted on the backend this step will not be required; we will load them into the volumeData as they arrive.
      sortDicomImages(sortedImages);
      const volumeData = createVolumeData(sortedImages.length, sortedImages);
      if (volumeData != null) {
        if (sortedImages.length !== slices.length) {
          showToast.warning(`CT volume data may be corrupt, only ${sortedImages.length} of ${slices.length} loaded`);
        }
        dispatch(contrastActions.setVolumeData({ series, volumeData }));
      }

      // Record that this volume is fully loaded.
      dispatch(contrastActions.setVolumeDataLoadState({ series, loadState: LoadState.LOADED }));
    },
    (error: Error) => {
      // Record that this volume failed to load correctly.
      console.warn(`ContrastLoader failed to load series ${series}`, error);
      dispatch(contrastActions.setVolumeDataLoadState({ series, loadState: LoadState.FAILED }));
    }
  );
}

export const VolumeDataLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const needsLoading = useRef<boolean>(true);
  const series = useAppSelector((state) => state.study.currentStudy?.ai_assessed?.contrast_id);
  const ctVolume = useAppSelector((state) => state.ctVolume.ctVolume);

  // Load the ai analysed series (if there was one).
  useEffect(() => {
    if (unifiedId != null && needsLoading.current && series != null && ctVolume?.content != null) {
      const seriesData = ctVolume.content.find((seriesData) => seriesData.key === series);
      if (seriesData != null) {
        needsLoading.current = false;
        loadVolumeData(series, seriesData.presigned_slice_urls, dispatch, true);
      }
    }
  }, [unifiedId, needsLoading, series, ctVolume, dispatch]);

  return null;
};
