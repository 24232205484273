import React, { ReactElement, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CoronaryFindingsSection } from '../../components/CoronaryFindingsSection/CoronaryFindingsSection';
import { ImpressionSection } from '../../components/ImpressionSection/ImpressionSection';
import PageTitle from '../../components/PageTitle/PageTitle';
import { NAV_TABS } from '../../config';
import { NavBar } from '../../components/NavBar/NavBar';
import useStudyTabVisibility from '../../hooks/use-study-tab-visibility';
import {
  useCoronaryFindingIsAiAssessedSelector,
  useCoronaryFindingsSelector,
  useImpressionIsAiAssessedSelector,
} from '../../selectors/reporting';
import { SeriesStatus, useStudySeriesInfo } from '../../selectors/study';
import { useVesselStateSelector } from '../../selectors/vessels';
import { getMajorVesselGrouping } from '../../utils/vessel';
import Model from '../../views/Model/Model';
import { PatientSummary } from '../../views/PatientSummary/PatientSummary';
import VesselViewer from '../../views/VesselViewer/VesselViewer';
import AxialViewer from '../../views/AxialViewer/AxialViewer';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { globalFlagsActions } from '../../reducers/global-flags/globalFlagsSlice';
import { PatientCommandBar } from '../../components/CommandBar/PageCommandBars/PatientCommandBar';

const Patient: React.FunctionComponent = (): ReactElement | null => {
  const { id } = useParams();
  const navigate = useNavigate();
  const studyData = useAppSelector((state) => state.study.studyData);
  const fetchingReport = useAppSelector((state) => state.report.fetching);
  const fetchingOverallMeasurements = useAppSelector((state) => state.globalFlags.fetchingOverallMeasurements);
  const initialDataLoaded = useAppSelector((state) => state.globalFlags.initialDataLoaded);
  const { vesselData, selectedVesselName } = useVesselStateSelector();
  const { getStyles } = useStudyTabVisibility(NAV_TABS.patientTab);

  const selectedVesselGroup = useMemo(() => getMajorVesselGrouping(selectedVesselName), [selectedVesselName]);

  const studySeriesInfo = useStudySeriesInfo();
  const hidePatientOverview = useMemo(() => {
    return studySeriesInfo?.contrast === SeriesStatus.Fail || studySeriesInfo?.contrast === SeriesStatus.NotAvailable;
  }, [studySeriesInfo]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) return navigate('/');
  }, [id, navigate]);

  // Start on the CT Volume tab if analysis failed
  useEffect(() => {
    if (hidePatientOverview) {
      dispatch(globalFlagsActions.setVisibleTab(1));
    } else if (!initialDataLoaded) {
      dispatch(globalFlagsActions.setVisibleTab(0));
    }
  }, [dispatch, initialDataLoaded, hidePatientOverview]);

  const impressionIsAiAssessed = useImpressionIsAiAssessedSelector();
  const coronaryFindingsAiAssessed = useCoronaryFindingIsAiAssessedSelector();
  const coronaryFindings = useCoronaryFindingsSelector();

  if (hidePatientOverview) {
    return null;
  }

  return (
    <>
      <PageTitle title={id} />
      <div style={getStyles()} className="page-patient" id="page-patient-overview">
        <div className="topbar">
          <NavBar />
          <PatientCommandBar />
        </div>
        <div className="page-patient__container">
          <div className="panel panel--left page-patient__column page-patient__column--first-column">
            <PatientSummary loading={fetchingReport && fetchingOverallMeasurements} />
            <ImpressionSection aiAssessed={impressionIsAiAssessed} loading={fetchingReport} />
            {selectedVesselGroup && (
              <CoronaryFindingsSection
                selectedVesselGroup={selectedVesselGroup}
                headerType="non-detailed"
                coronaryFindings={coronaryFindings}
                aiAssessed={coronaryFindingsAiAssessed[selectedVesselGroup]}
                loading={fetchingReport}
              />
            )}
          </div>
          <div className="page-patient__column page-patient__column--second-column page-patient__column--full-height">
            <Model />
          </div>
          <div className="page-patient__column page-patient__column--third-column page-patient__column--full-height">
            {
              <div className="page-patient__column page-patient__column--full-height">
                {studyData && vesselData && <VesselViewer />}
              </div>
            }
            <div className="page-patient__column--axial-viewer">{studyData && vesselData && <AxialViewer />}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Patient;
