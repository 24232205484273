import { getNewToken } from './auth';

/**
 * This is largely a placeholder function until we flesh out the new webApp api.
 * @param path The uri to fetch.
 * @param addAuthorization If true the request will add the current JWT token as authorization.
 */
export function fetchJSON<ResponseType>(path: string, addAuthorization: boolean): Promise<ResponseType> {
  const headers: Record<string, string> = {
    accept: 'application/json',
  };

  if (addAuthorization) {
    // NOTE: You need to be logged in via the loginnew page to get this token.
    const token = getNewToken();
    if (token == null) {
      throw new Error(`fetch ${path} failed, no login token found`);
    }
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(path, {
    method: 'GET',
    headers,
  }).then((response: Response) => {
    return response.json();
  });
}
