import * as PIXI from 'pixi.js-legacy';
import { CreateNewRulerSpriteInterface, CreateNewMarkerInterface, RULER_STATE, Measurement, TOOL_TYPES } from './types';
import {
  drawDashedLine,
  drawLine,
  getPerpendicularLine,
  setLineStyle,
  MEASUREMENT_SETTINGS,
  drawInactiveCrosshair,
  drawActiveCrosshair,
  createHitLine,
  createHitCircle,
  createMarkerSprite,
} from './utils';

/**
 * Create a new PIXI sprite for the given ruler measurement.
 */
export const createNewRulerSprite = ({
  measurement,
  scale,
  onMouseDown,
}: CreateNewRulerSpriteInterface): PIXI.Graphics => {
  const sprite = new PIXI.Graphics() as PIXI.Graphics;

  let lineWidth = MEASUREMENT_SETTINGS.WIDTH / scale;
  if (measurement.state !== RULER_STATE.Inactive) {
    // Draw the line.
    sprite.lineStyle(MEASUREMENT_SETTINGS.WIDTH / scale, 0xffffff);
    drawDashedLine({
      line: sprite,
      start: measurement.startPoint,
      end: measurement.endPoint,
      lineWidth,
      dashLength: 5 / scale,
    });

    // Draw the start point crosshair.
    if (measurement.state === RULER_STATE.HandleStart) {
      drawActiveCrosshair(sprite, measurement.startPoint, scale);
      // Hide the sprite cursor.
      sprite.cursor = 'none';
    } else {
      drawInactiveCrosshair(sprite, measurement.startPoint, scale);
    }

    // Draw the end point crosshair.
    if (measurement.state === RULER_STATE.New || measurement.state === RULER_STATE.HandleEnd) {
      drawActiveCrosshair(sprite, measurement.endPoint, scale);
      // Hide the sprite cursor.
      sprite.cursor = 'none';
    } else {
      drawInactiveCrosshair(sprite, measurement.endPoint, scale);
    }
  } else {
    // Draw the ruler in two passes (the first for the shadow).
    for (let i = 0; i < 2; i++) {
      // Draw perpendicular line to the start and end point
      const width =
        i === 0 ? MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive + 2 : MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive;
      const perpLineStart = getPerpendicularLine([measurement.endPoint, measurement.startPoint], width / scale);
      const perpLineEnd = getPerpendicularLine([measurement.startPoint, measurement.endPoint], width / scale);
      // Set the line style.
      setLineStyle(sprite, i === 0, true, scale);
      // Draw the ruler line.
      drawLine(sprite, measurement.startPoint, measurement.endPoint);
      // Draw the perpendicular lines at the start and end points.
      drawLine(sprite, perpLineStart[0], perpLineStart[2]);
      drawLine(sprite, perpLineEnd[0], perpLineEnd[2]);
    }
  }

  // Make the line and end points interactive?
  if ((measurement.state === RULER_STATE.Active || measurement.state === RULER_STATE.Inactive) && onMouseDown) {
    // Add the line's hit area.
    sprite.addChild(
      createHitLine(
        measurement.startPoint,
        measurement.endPoint,
        scale,
        measurement.measurementId,
        RULER_STATE.Moving,
        onMouseDown
      )
    );
    // Add the hit targets for the handles.
    sprite.addChild(
      createHitCircle(
        measurement.startPoint,
        MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale,
        measurement.measurementId,
        RULER_STATE.HandleStart,
        onMouseDown
      )
    );
    sprite.addChild(
      createHitCircle(
        measurement.endPoint,
        MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale,
        measurement.measurementId,
        RULER_STATE.HandleEnd,
        onMouseDown
      )
    );
  }
  return sprite;
};

/**
 * Create a new PIXI sprite to show the details of the the ruler measurement.
 */
export const createNewRulerMarker = ({ measurement, scale, bounds }: CreateNewMarkerInterface): PIXI.Graphics => {
  return createMarkerSprite({
    type: TOOL_TYPES.Ruler,
    label: `${measurement.length?.toFixed(2)} mm`,
    points: [{ ...measurement.endPoint }, { ...measurement.startPoint }],
    bounds,
    scale,
    showLine: [RULER_STATE.Active, RULER_STATE.Inactive].includes(measurement.state as RULER_STATE),
  });
};

/**
 * Calculate the length (in mm) between two points
 */
export const calculateRulerLength = (measurement: Measurement, millimeterSpacing: number): number => {
  const x = measurement.startPoint.x - measurement.endPoint.x;
  const y = measurement.startPoint.y - measurement.endPoint.y;
  return Math.sqrt(x * x + y * y) * millimeterSpacing;
};
