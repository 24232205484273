import React from 'react';
import Select from '../../../components/reusable/SelectNew/SelectNew';

interface ReformationTypeSelectorProps {
  options: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
}

export const ReformationTypeSelector: React.FunctionComponent<ReformationTypeSelectorProps> = ({
  options = [],
  value,
  onChange,
}) => {
  return (
    <div className="reformation-type-selector">
      <Select
        options={options.map((option) => ({
          value: option.value,
          label: option.label,
        }))}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
