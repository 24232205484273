import React, { ReactNode, useState } from 'react';
import { Provider } from 'react-redux';
import { newStore } from './store';
import { GlobalContextContainer } from './context/GlobalContextContainer';

export interface StoreProviderProps {
  children: ReactNode;
}

/**
 * The StoreProvider allows us to create a new store that is tied to the life-cycle of the StoreProvider component.
 */
export const StoreProvider: React.FunctionComponent<StoreProviderProps> = (props) => {
  const [store] = useState(newStore());

  return (
    <Provider store={store}>
      <GlobalContextContainer>{props.children}</GlobalContextContainer>
    </Provider>
  );
};
