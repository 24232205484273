import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DraftReport, Report, ReportHistoryResponse } from '../../context/types';

export interface ReportState {
  draft: DraftReport | null;
  current: Report | null;
  postingReport: boolean;
  postingPartialReport: boolean;
  fetching: boolean;
  editing: boolean;
  show: boolean;
  reportHistory: ReportHistoryResponse | undefined;
}

export const createDefaultReportSliceState = (): ReportState => {
  return {
    draft: null,
    current: null,
    postingReport: false,
    postingPartialReport: false,
    fetching: false,
    editing: false,
    show: false,
    reportHistory: undefined,
  };
};

export const reportSlice = createSlice({
  name: 'report',
  initialState: createDefaultReportSliceState(),
  reducers: {
    setDraftReport: (state, action: PayloadAction<DraftReport>) => {
      state.draft = action.payload;
    },
    updateDraftReport: (state, action: PayloadAction<DraftReport>) => {
      const newValue = { ...state.draft, ...action.payload };
      state.draft = newValue;
    },
    deleteDraftReport: (state) => {
      state.draft = null;
    },
    setCurrentReport: (state, action: PayloadAction<Report | null>) => {
      state.current = action.payload;
    },
    setPostingReport: (state, action: PayloadAction<boolean>) => {
      state.postingReport = action.payload;
    },
    setPostingPartialReport: (state, action: PayloadAction<boolean>) => {
      state.postingPartialReport = action.payload;
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setEditing: (state, action: PayloadAction<boolean>) => {
      state.editing = action.payload;
    },
    setShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setReportHistory: (state, action: PayloadAction<ReportHistoryResponse | undefined>) => {
      state.reportHistory = action.payload;
    },
  },
});

export const reportActions = reportSlice.actions;
