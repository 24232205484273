import React, { useRef, useState } from 'react';
import useOnClickOutside from '../../hooks/use-on-click-outside';
import Moment from 'moment';

interface FindingsStatusProps {
  versions: boolean;
  onRevertFindings?: () => void;
}

export const FindingsStatus: React.FunctionComponent<FindingsStatusProps> = ({ versions, onRevertFindings }) => {
  const [showRevertMenu, setShowRevertMenu] = useState(false);

  const menuRef = useRef<HTMLUListElement | null>(null);
  const currentTime = Moment(new Date()).format('DD-MM-yyyy LT');

  const handleRevert = () => {
    setShowRevertMenu(false);
    onRevertFindings && onRevertFindings(); // TODO: return which version to revert to
  };

  useOnClickOutside(menuRef, () => {
    setShowRevertMenu(false);
  });

  return (
    <div className="finding-status">
      {!versions ? (
        <div className="finding-status__tag">AI Assessed</div>
      ) : (
        <div className="finding-status__tag finding-status--edited">
          Edited
          {/* TODO: Confirm is this required to show modal window and revert to specific version
          NOTE: Requires API endpoints */}
          {/* <button type="button" onClick={handleShowRevert}>
            <div className="icon-button__icon">
              <ReloadIcon />
            </div>
          </button> */}
        </div>
      )}
      {showRevertMenu && versions && (
        <ul className="finding-status__list" ref={menuRef}>
          {/* TODO: only show historical version if one exists */}
          <li className="finding-status__option">
            <button onClick={() => console.log('revert')}>
              Revert to last saved version
              <span className="finding-status__date">{currentTime}</span>
            </button>
          </li>
          <li className="finding-status__option">
            <button onClick={handleRevert}>Revert to AI Assessed version</button>
          </li>
        </ul>
      )}
    </div>
  );
};
