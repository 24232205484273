import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContrastLesionDataResponse, LesionDataResponse } from './types';

export interface LesionState {
  lesionData: LesionDataResponse | null;
  contrastLesionData: ContrastLesionDataResponse | null;
}

export const initialLesionState: LesionState = {
  lesionData: null,
  contrastLesionData: null,
};

export const lesionSlice = createSlice({
  name: 'lesion',
  initialState: initialLesionState,
  reducers: {
    setLesionData: (state, action: PayloadAction<LesionDataResponse | null>) => {
      state.lesionData = action.payload;
    },
    setContrastLesionData: (state, action: PayloadAction<ContrastLesionDataResponse | null>) => {
      state.contrastLesionData = action.payload;
    },
  },
});

export const lesionActions = lesionSlice.actions;
