import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { vec2, vec3 } from 'gl-matrix';
import {
  ContrastPendingScreenshot,
  ContrastViewContent,
  CTVolumeOverlay,
  VesselSyncInfo,
} from '../../context/contrast-types';
import { LoadState } from '../initialLoad/initialLoadSlice';

// The meta data provided by the Cornerstone imagePlaneModule.
export interface CornerstoneMetaData {
  frameOfReferenceUID: string;
  rows: number;
  columns: number;
  rowCosines: vec3;
  columnCosines: vec3;
  imagePositionPatient: vec3;
  rowPixelSpacing: number;
  columnPixelSpacing: number;
}

export interface DicomMetaData {
  // The number of voxels in each dimension.
  dimensions: vec2;
  // The size of each voxel (in mm).
  spacing: vec2;
  // The position of the slice in 3D.
  position: vec3;
  // The direction of the slice's X axis in the 3D world.
  xAxis: vec3;
  // The direction of the slice's Y axis in the 3D world.
  yAxis: vec3;
  // The distance of the slice from the origin along the scanDirection.
  scanDistance: number;
}

export interface DicomImage {
  // The array of X * Y pixel data for the image slice.
  data: Int16Array;
  // The additional metaData for the image slice with info on its size, spacing, position etc.
  metaData: DicomMetaData;
}

export interface VolumeData {
  // The number of voxels in each dimension.
  dimensions: vec3;
  // The size of each voxel (in mm).
  spacing: vec3;
  // The raw array of voxel data X * Y * Z dimension is length.
  data: Int16Array;
  // The number of slice images currently loaded (the number to load is given by the Z dimension as each slice covers an X-Y plane).
  imageCountLoaded: number;
}

export interface Bound {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

/**
 * Get the distance of the slice from the origin along the scanDirection.
 */
export function getScanDistance(metaData: CornerstoneMetaData): number {
  const scanDirection: vec3 = [0, 0, 0];
  vec3.cross(scanDirection, metaData.rowCosines, metaData.columnCosines);
  return vec3.dot(metaData.imagePositionPatient, scanDirection);
}

/**
 * Sort the slices based on their calculated scanDistance.
 * @param images The (potentially) unsorted dicom images to sort.
 */
export function sortDicomImages(images: DicomImage[]) {
  images.sort((a: DicomImage, b: DicomImage) => {
    return a.metaData.scanDistance - b.metaData.scanDistance;
  });

  // Remove any slices with duplicate positional information.
  for (let i = images.length - 1; i > 0; i--) {
    const distance = Math.abs(images[i].metaData.scanDistance - images[i - 1].metaData.scanDistance);
    if (Math.abs(distance) <= 0.0001) {
      console.warn('Duplicate slice position detected, slice is being removed');
      images.splice(i, 1);
    }
  }
}

/**
 * Copy the slice image data to the volume.
 */
function updateVolumeDataSlice(volumeData: VolumeData, slice: number, image: DicomImage, copySliceData: boolean) {
  if (copySliceData) {
    const pixelCount: number = volumeData.dimensions[0] * volumeData.dimensions[1];
    // NOTE: The slices are fetched in reverse order so we flip their index here.
    const sliceIndex: number = volumeData.dimensions[2] - 1 - slice;
    const offset: number = pixelCount * sliceIndex;

    // Copy the slice data from the image to the volume.
    volumeData.data.set(image.data, offset);
  }
  // Record that the slice was loaded.
  volumeData.imageCountLoaded++;
}

/**
 * If we have two or more images loaded we can create a new VolumeData object from them.
 * @param imageCount The number of slices in the series.
 * @param images A potentially incomplete array of the DicomImages for the series.
 */
export function createVolumeData(imageCount: number, images: DicomImage[]): VolumeData | undefined {
  const firstIndex = images.findIndex((image) => image != null);
  let lastIndex = firstIndex;
  images.forEach((image, index) => {
    if (image != null) {
      lastIndex = index;
    }
  });
  if (firstIndex == null || firstIndex === lastIndex) {
    return undefined;
  }

  // Create the empty volume.
  const dimensions: vec3 = [
    images[firstIndex].metaData.dimensions[0],
    images[firstIndex].metaData.dimensions[1],
    imageCount,
  ];
  let spacingZ =
    vec3.distance(images[lastIndex].metaData.position, images[firstIndex].metaData.position) / (lastIndex - firstIndex);
  if (spacingZ > 1.0) {
    console.warn(
      `Volume spacing calculated as ${spacingZ}, slice ordering is probably non-sequential and should be fixed. Forcing 1.0`
    );
    spacingZ = 1.0;
  }
  const spacing: vec3 = [images[firstIndex].metaData.spacing[0], images[firstIndex].metaData.spacing[1], spacingZ];
  const data: Int16Array = new Int16Array(dimensions[0] * dimensions[1] * dimensions[2]);
  const volumeData: VolumeData = {
    dimensions,
    spacing,
    data,
    imageCountLoaded: 0,
  };

  // Initialize the data with the minimum HU value possible.
  data.fill(-1024);

  // Add any slice image data we currently have.
  images.forEach((image, index) => {
    // TODO: We currently are delaying the copying of slice data to the volume until all slices have loaded because their ordering is non-sequential.
    updateVolumeDataSlice(volumeData, index, image, images.length === imageCount);
  });

  return volumeData;
}

export type ContrastState = {
  // Should the contrast views associated with the MPR view synchronize with the vessel slice on the MPR view?
  vesselSync: boolean;
  lastVisibleViews: number[];
  // The currently visible views (this array could be 1, 2, or 4 in length and will include each view index (0, 1, 2, 3) no more than once).
  // This is updated by calling doubleClickView() or onVisibleViewCountButton().
  visibleViews: number[];
  // The array of views currently being shown on the contrast volume page.
  contrastViews: ContrastViewContent[];
  // If set we are waiting for the view to resize so we can take a screenshot.
  pendingScreenshot: ContrastPendingScreenshot | undefined;
  // Maps contrast view indexes to their overlay state
  contrastOverlays: Record<number, CTVolumeOverlay>;
  // The last syncronized study, vessel name and slice index.
  vesselSyncInfo: VesselSyncInfo | undefined;
  // BoundingClientRect the volume viewer div (className='volume-viewer')
  volumeViewer: Bound | undefined;
  // The status of all loaded and loading volumes (with the series name as the key).
  seriesVolumeDataLoadState: Record<string, LoadState>;
  // The volume data for all loaded and loading volumes (with the series name as the key).
  seriesVolumeData: Record<string, VolumeData>;
};

export const initialContrastState = (): ContrastState => {
  return {
    vesselSync: true,
    lastVisibleViews: [],
    visibleViews: [0],
    contrastViews: [],
    pendingScreenshot: undefined,
    contrastOverlays: {
      0: CTVolumeOverlay.HIDE,
      1: CTVolumeOverlay.HIDE,
      2: CTVolumeOverlay.HIDE,
      3: CTVolumeOverlay.HIDE,
    },
    vesselSyncInfo: undefined,
    volumeViewer: undefined,
    seriesVolumeDataLoadState: {},
    seriesVolumeData: {},
  };
};

export const contrastSlice = createSlice({
  name: 'contrast',
  initialState: initialContrastState(),
  reducers: {
    setVesselSync: (state, action: PayloadAction<boolean>) => {
      state.vesselSync = action.payload;
    },
    setLastVisibleViews: (state, action: PayloadAction<number[]>) => {
      state.lastVisibleViews = action.payload;
    },
    setVisibleViews: (state, action: PayloadAction<number[]>) => {
      state.visibleViews = action.payload;
    },
    setContrastViews: (state, action: PayloadAction<ContrastViewContent[]>) => {
      state.contrastViews = action.payload;
    },
    setPendingScreenshot: (state, action: PayloadAction<ContrastPendingScreenshot | undefined>) => {
      state.pendingScreenshot = action.payload;
    },
    setContrastOverlays: (state, action: PayloadAction<Record<number, CTVolumeOverlay>>) => {
      state.contrastOverlays = action.payload;
    },
    setVesselSyncInfo: (state, action: PayloadAction<VesselSyncInfo | undefined>) => {
      state.vesselSyncInfo = action.payload;
    },
    setVolumeViewer: (state, action: PayloadAction<Bound | undefined>) => {
      state.volumeViewer = action.payload;
    },
    setVolumeDataLoadState: (state, action: PayloadAction<{ series: string; loadState: LoadState }>) => {
      state.seriesVolumeDataLoadState[action.payload.series] = action.payload.loadState;
    },
    setVolumeData: (state, action: PayloadAction<{ series: string; volumeData: VolumeData }>) => {
      state.seriesVolumeData[action.payload.series] = action.payload.volumeData;
    },
    setVolumeDataDicomImage: (state, action: PayloadAction<{ series: string; slice: number; image: DicomImage }>) => {
      const volumeData = state.seriesVolumeData[action.payload.series];
      if (volumeData) {
        // TODO: We currently are delaying the copying of slice data to the volume until all slices have loaded because their ordering is non-sequential.
        updateVolumeDataSlice(volumeData as VolumeData, action.payload.slice, action.payload.image, false);
      }
    },
    setVolumeDataDicomImageFailed: (state, action: PayloadAction<{ series: string }>) => {
      const volumeData = state.seriesVolumeData[action.payload.series];
      if (volumeData) {
        // Update the number of images that have been loaded (or attempted to load).
        volumeData.imageCountLoaded++;
      }
    },
  },
});

export const contrastActions = contrastSlice.actions;
