import React from 'react';
import { useContrastViewTypeSelector, useContrastOverlaySelector } from '../../../hooks/useContrastViewHelpers';
import MetaDetails from '../MetaDetails/MetaDetails';
import { viewTypeToAxis } from './Utils';
import { ContrastVolume } from '../../../context/contrast-types';
import { useAppSelector } from '../../../hooks';

interface ContrastViewOverlayProps {
  contrastVolume: ContrastVolume;
  viewIndex: number;
}

/**
 * The overlay that shows the series details etc.
 */
const ContrastViewOverlay: React.FunctionComponent<ContrastViewOverlayProps> = ({ contrastVolume, viewIndex }) => {
  const viewType = useContrastViewTypeSelector(viewIndex);
  const { contrastWindowLevels, contrastWindowLabel } = useAppSelector((state) => state.window);
  const study = useAppSelector((state) => state.study.currentStudy);
  const renderThickness = viewType !== undefined ? contrastVolume.viewProps[viewType].renderThickness : 0.0;

  // Try to get the scan thickness of the study from the series details.
  // NOTE: thickness is saved as a string.
  const series = study?.series?.[contrastVolume.seriesName];
  const scanThickness = series?.thickness ? parseFloat(series?.thickness) : 0.1;

  // Get the slice for the axis this view is looking down
  const crosshairPos = contrastVolume.crosshairValues?.crosshairPos;
  const slice = crosshairPos && viewType !== undefined ? crosshairPos[viewTypeToAxis(viewType)] : undefined;

  const overlayToShow = useContrastOverlaySelector(viewIndex);

  return (
    <div className="contrast-viewer__view-overlay">
      {/* Render the meta data overlay */}
      <MetaDetails
        studyDetails={study}
        huValue={renderThickness > 0.0 ? undefined : contrastVolume.crosshairValues?.huValue}
        slice={slice}
        slabThickness={scanThickness + renderThickness}
        windowLevels={contrastWindowLevels}
        windowLabel={contrastWindowLabel}
        overlayToShow={overlayToShow}
      />
    </div>
  );
};

export { ContrastViewOverlay };
