import { isEqual } from 'lodash';
import React, { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { CoronaryFindingsField } from './CoronaryFindingsField';
import { VesselGroupings } from '../../config';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  inlineReportingAction,
  VesselToReportNameMapping,
  UnsavedChangesToInlineReport,
} from '../../reducers/inlineReporting/inlineReportingSlice';
import { CoronaryFindings } from '../../context/types';
import { useSaveCoronaryFindingCallback } from '../../hooks/useSaveCallBack';
import { useInlineReportingCoronanaryHasAnyChanges } from '../../selectors/reporting';
import {
  PatientOverviewSection,
  PatientOverviewSectionHeaderType,
} from '../PatientOverviewSection/PatientOverviewSection';

interface Props {
  selectedVesselGroup: VesselGroupings;
  headerType: PatientOverviewSectionHeaderType;
  coronaryFindings: CoronaryFindings;
  aiAssessed: boolean;
  loading?: boolean;
}

export function CoronaryFindingsSection({
  selectedVesselGroup,
  headerType,
  coronaryFindings,
  aiAssessed,
  loading = false,
}: Props): ReactElement<Props> {
  const { id } = useParams();
  const postingPartialReport = useAppSelector((state) => state.report.postingPartialReport);
  const inlineReportingState = useAppSelector((state) => state.inlineReporting);
  const dispatch = useAppDispatch();

  const onSaveCoronaryFindings = useSaveCoronaryFindingCallback(id, selectedVesselGroup);

  const coronaryFindingsState = useMemo(() => {
    const mapped = VesselToReportNameMapping[selectedVesselGroup];
    return inlineReportingState[mapped];
  }, [selectedVesselGroup, inlineReportingState]);

  /**
   * Check if we have unsaved changes on the current selected vessel
   */
  const hasChanges = useMemo(() => {
    if (coronaryFindingsState === undefined) return false;
    return !isEqual(coronaryFindings[selectedVesselGroup], coronaryFindingsState);
  }, [coronaryFindings, coronaryFindingsState, selectedVesselGroup]);

  const hasChangesAnyVessel = useInlineReportingCoronanaryHasAnyChanges();

  const onClickEditButton = useCallback(() => {
    dispatch(
      inlineReportingAction.updateCoronaryFinding({
        vessel: selectedVesselGroup,
        finding: coronaryFindings[selectedVesselGroup] ?? '',
      })
    );
  }, [selectedVesselGroup, coronaryFindings, dispatch]);

  const onCancel = useCallback(() => {
    if (hasChangesAnyVessel) {
      dispatch(
        inlineReportingAction.setInlineReportingWarningAction(() => {
          dispatch(inlineReportingAction.clearCoronaryFindings());
        })
      );
      dispatch(inlineReportingAction.setUnsavedChangesWarning(UnsavedChangesToInlineReport.CancelEditingCoronary));
    } else {
      dispatch(inlineReportingAction.clearCoronaryFindings());
    }
  }, [hasChangesAnyVessel, dispatch]);

  const onSave = useCallback(() => {
    onSaveCoronaryFindings();

    dispatch(inlineReportingAction.updateCoronaryFinding({ vessel: selectedVesselGroup }));
  }, [onSaveCoronaryFindings, dispatch, selectedVesselGroup]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>, vessel: VesselGroupings) => {
      const value = event.target.value;

      dispatch(inlineReportingAction.updateCoronaryFinding({ vessel, finding: value }));
    },
    [dispatch]
  );

  return (
    <PatientOverviewSection
      title={headerType === 'detailed' ? selectedVesselGroup : 'Coronary Findings'}
      headerType={headerType}
      editMode={coronaryFindingsState !== undefined}
      onClick={onClickEditButton}
      onCancel={onCancel}
      onSave={onSave}
      aiAssessed={aiAssessed}
      saveEnabled={hasChanges && !postingPartialReport}
      vesselGroup={headerType === 'detailed' ? selectedVesselGroup : undefined}
      loading={loading}
    >
      <CoronaryFindingsField
        showVesselName={headerType === 'non-detailed'}
        vesselGroup={selectedVesselGroup}
        editMode={coronaryFindingsState !== undefined}
        coronaryFindings={coronaryFindings}
        draftCoronaryFindings={coronaryFindingsState ?? ''}
        handleInputChange={handleInputChange}
        autoFocus={true}
      />
    </PatientOverviewSection>
  );
}
