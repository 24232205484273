import * as PIXI from 'pixi.js-legacy';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Vec2 } from 'three';
import {
  COLOR_CPR_CENTRELINE,
  COLOR_MPR_SHORT_AXIS_INNER_WALL,
  COLOR_MPR_SHORT_AXIS_OUTER_WALL,
  KEY_MPR_SHORT_AXIS,
} from '../../config';
import { PlaqueMeasurementsPerSlice, WallSliceMeasurements } from '../../context/types';
import { WindowLevels } from '../../reducers/window/types';
import { useVesselStateSelector } from '../../selectors/vessels';
import { fetchPlaqueMeasurementsPerSlice, fetchWallSliceMeasurementsSlice } from '../../utils/api';
import { captureMouse } from '../../utils/captureMouse';
import { ToolBar } from '../ToolBar/ToolBar';
import { OnReadyInf, RESIZE_MODE } from '../WebGLViewer/types';
import WebGLViewer from '../WebGLViewer/WebGLViewer';
import { processData } from './helpers';
import { MeasurementTool } from '../MeasurementTool/MeasurementTool';
import { getAxisMPRViewPixelPerMm } from '../../utils/measurementTools/utils';
import { useAppSelector } from '../../hooks';
import { useEffectCustomDeps } from '../../hooks/useEffectCustomDeps';
import { ShortAxisViewerData } from '../../reducers/vesselData/types';

/**
 * Clear a sprite reference.
 * @param spriteRef The sprite ref to clear.
 */
const clearSpriteRef = (spriteRef: MutableRefObject<PIXI.Graphics | null>) => {
  // Delete the current indicator.
  if (spriteRef.current) {
    spriteRef.current.destroy({
      children: true,
      baseTexture: true,
      texture: true,
    });
    spriteRef.current = null;
  }
};

export type ShortAxisMPRViewerType = 'prox' | 'mid' | 'dist';

const SETTINGS = {
  COLOR_LUMEN: COLOR_MPR_SHORT_AXIS_INNER_WALL,
  COLOR_OUTER: COLOR_MPR_SHORT_AXIS_OUTER_WALL,
  COLOR_CENTRE: COLOR_CPR_CENTRELINE,
  LINE_WIDTH: 2,
  SIMPLIFY_AMOUNT: 4,
};

interface Props {
  minThreshold?: number;
  maxThreshold?: number;
  windowLevels?: WindowLevels;
  onWindowLevelsChange?: (levels: WindowLevels) => void;
  viewerData: ShortAxisViewerData | undefined;
  viewIdx: number;
  onChangeViewIdx: (index: number) => void;
  disableControls?: boolean;
  disableKeyboardControls?: boolean;
  lesionId?: string | null;
  // This is really great for knowing which ShortAxisMPRViewer you're looking at
  type: ShortAxisMPRViewerType;
}

export const ShortAxisMPRViewer = ({
  minThreshold,
  maxThreshold,
  windowLevels,
  onWindowLevelsChange,
  viewIdx,
  onChangeViewIdx,
  disableControls = false,
  disableKeyboardControls = false,
  lesionId = null,
  viewerData,
  type: _type,
}: Props) => {
  const { selectedVesselName } = useVesselStateSelector();

  // Measurement tool context
  const [millimeterSpacing, setMillimeterSpacing] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);

  // When not ready the WebGLViewer is not shown.
  const [ready, setReady] = useState(false);
  // This is a work around for the lack of full state management; so we can tell when the results from an async task are no longer wanted.
  // Show the annotations (ie, outer wall and lumen wall).
  const [showAnnos, setShowAnnos] = useState(true);
  // The current HU value under the mouse cursor.
  const hueRef = useRef<any | undefined>();
  const containerRef = useRef<PIXI.Container | undefined>(undefined);
  const appRef = useRef<PIXI.Application | undefined>(undefined);
  // The array of lumen polygon data (ie arrays of polygon vertices) (the wall surrounding the outer wall and calcium deposits).
  const lumenDataRef = useRef<Vec2[][]>([]);
  // The lumen PIXI sprite (the wall surrounding the outer wall and calcium deposits).
  const lumenSpriteRef = useRef<PIXI.Graphics | null>(null);
  // The array of outer wall polygon data (ie arrays of polygon vertices).
  const outerDataRef = useRef<Vec2[][]>([]);
  // The outer wall PIXI sprite.
  const outerSpriteRef = useRef<PIXI.Graphics | null>(null);
  // The PIXI.Container holding the annotations, all image sprites etc are added to this.
  const annoRef = useRef<PIXI.Container | null>(null);
  // The ref to this component's main div which is used when capturing screenshots (not a ref to the screenshot itself).
  const screenshotRef = useRef<HTMLDivElement | null>(null);
  // The outer WebGLViewer div element.
  const holderRef = useRef<HTMLDivElement | undefined>(undefined);

  const crosshairSpriteRef = useRef<PIXI.Graphics | null>(null);
  // Has the polygon data for the current vessel been processed yet?
  const processedPolyData = useRef(false);

  //store all positions of markers of the measurement tools to work with collision detection
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const displayMeasurements = useAppSelector((state) => state.globalFlags.displayMeasurements);
  const patientID = useAppSelector((state) => state.patient.patientID);
  const [plaqueMeasurementsPerSlice, setPlaqueMeasurementsPerSlice] = useState<PlaqueMeasurementsPerSlice>();

  const [wallSliceMeasurements, setWallSliceMeasurements] = useState<WallSliceMeasurements>();

  useEffect(() => {
    if (!patientID || !runID || !selectedVesselName) {
      return;
    }

    fetchPlaqueMeasurementsPerSlice(patientID, runID, selectedVesselName)
      .then((res: PlaqueMeasurementsPerSlice) => {
        const totalArea = res.area?.total?.map((value) => Number(value.toFixed(2)));
        const totalBurden = res.burden?.total?.map((value) => Number((value * 100).toFixed(2)));
        res &&
          setPlaqueMeasurementsPerSlice({
            area: { total: totalArea },
            burden: { total: totalBurden },
          });
      })
      .catch(() => {
        setPlaqueMeasurementsPerSlice(undefined);
      });

    fetchWallSliceMeasurementsSlice(patientID, runID, selectedVesselName)
      .then((res: WallSliceMeasurements) => {
        const lumenArea = res?.lumen_area?.map((value) => Number(value.toFixed(2)));
        const outerArea = res?.outer_area?.map((value) => Number(value.toFixed(2)));
        res &&
          setWallSliceMeasurements({
            lumen_area: lumenArea,
            outer_area: outerArea,
          });
      })
      .catch(() => {
        setWallSliceMeasurements(undefined);
      });
  }, [patientID, runID, selectedVesselName]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVesselName]);

  /**
   * Clear all polygon data and sprites.
   */
  const clearPolyData = useCallback(() => {
    clearSpriteRef(crosshairSpriteRef);
    clearSpriteRef(lumenSpriteRef);
    clearSpriteRef(outerSpriteRef);
    lumenDataRef.current = [];
    outerDataRef.current = [];
    // The polygon data hasn't been processed.
    processedPolyData.current = false;
  }, []);

  /**
   * Clean up all allocated PIXI buffers etc and ensure we have exited add and editing modes.
   */
  const cleanup = useCallback(() => {
    setReady(false);
    // schedule texture destruction
    const annoPrev = annoRef.current;
    if (annoPrev && annoPrev.destroy) {
      setTimeout(() => {
        annoPrev.destroy();
      }, 0);
    }
    holderRef.current = undefined;
    annoRef.current = null;
    clearPolyData();
  }, [clearPolyData]);

  /**
   * Cleanup PIXI on unmount.
   */
  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  const createPolygonSprite = useCallback(
    (polygon: Vec2[], color: any) => {
      if (!annoRef.current) {
        console.error('ShortAxisMPRViewer adding polygon before init');
        return;
      }

      const sprite = new PIXI.Graphics();
      sprite.lineStyle(SETTINGS.LINE_WIDTH / scale, color.replace('#', '0x'));

      const polygonArray: number[][] = polygon.map((p: Vec2) => [p.x, p.y]);

      const simplified = simplifyPolyline(polygonArray, SETTINGS.SIMPLIFY_AMOUNT);

      sprite.moveTo(simplified[0][0], simplified[0][1]);

      const t = 1;
      for (let i = 0; i < simplified.length; i++) {
        const p0 = i > 0 ? simplified[i - 1] : simplified[0];
        const p1 = simplified[i];
        const p2 = simplified[i + 1] ? simplified[i + 1] : simplified[0];
        const p3 = simplified[i + 2] ? simplified[i + 2] : simplified[1];

        const cp1x = p1[0] + ((p2[0] - p0[0]) / 6) * t;
        const cp1y = p1[1] + ((p2[1] - p0[1]) / 6) * t;

        const cp2x = p2[0] - ((p3[0] - p1[0]) / 6) * t;
        const cp2y = p2[1] - ((p3[1] - p1[1]) / 6) * t;

        sprite.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, p2[0], p2[1]);
      }

      annoRef.current.addChild(sprite);
      return sprite;
    },
    [scale]
  );

  const createLumenSprites = useCallback(
    (index: number) => {
      if (lumenSpriteRef.current && lumenSpriteRef.current.parent) {
        lumenSpriteRef.current.parent.removeChild(lumenSpriteRef.current);
      }

      if (!annoRef.current) {
        console.error('ShortAxisMPRViewer createLumenSprites before init');
        return;
      }

      const polygon = lumenDataRef.current[index] ?? [];

      if (polygon.length !== 0) {
        const polySprite = createPolygonSprite(polygon, SETTINGS.COLOR_LUMEN);

        if (polySprite) {
          lumenSpriteRef.current?.destroy({
            children: true,
            baseTexture: true,
            texture: true,
          });

          lumenSpriteRef.current = polySprite;
        }
      }
    },
    [createPolygonSprite]
  );

  const createOuterSprites = useCallback(
    (index: number) => {
      if (outerSpriteRef.current && outerSpriteRef.current.parent) {
        outerSpriteRef.current.parent.removeChild(outerSpriteRef.current);
      }

      if (!annoRef.current) {
        console.error('ShortAxisMPRViewer createOuterSprites before init');
        return;
      }

      const polygon = outerDataRef.current[index] ?? [];

      if (polygon.length !== 0) {
        const polySprite = createPolygonSprite(polygon, SETTINGS.COLOR_OUTER);

        if (polySprite) {
          outerSpriteRef.current?.destroy({
            children: true,
            baseTexture: true,
            texture: true,
          });
          outerSpriteRef.current = polySprite;
        }
      }
    },
    [createPolygonSprite]
  );

  // We only want to trigger this effect on a slice change!
  useEffectCustomDeps(() => {
    // can't use onSliceChange due to infinite callback loop
    if (annoRef.current) {
      createLumenSprites(viewIdx);
      createOuterSprites(viewIdx);
    }
  }, [viewIdx]);

  const crosshairCenterRef = useRef<Vec2 | null>(null);

  const drawCentreCrossHair = useCallback(
    (crosshairCenterCalculationMode: 'cached' | 'refresh', shouldDraw = true) => {
      const color: any = SETTINGS.COLOR_CENTRE;

      if (!shouldDraw) return;

      if (!annoRef.current || !containerRef.current || !appRef.current) {
        return;
      }

      if (crosshairSpriteRef.current) {
        crosshairSpriteRef.current.parent.removeChild(crosshairSpriteRef.current);
      }

      clearSpriteRef(crosshairSpriteRef);

      const sprite = new PIXI.Graphics();

      sprite.lineStyle(SETTINGS.LINE_WIDTH / scale, color.replace('#', '0x'));

      const calculateCenter = (container: PIXI.Container) => {
        return {
          x: container.width / 2 / container.scale.x,
          y: container.height / 2 / container.scale.y,
        };
      };

      if (crosshairCenterCalculationMode === 'refresh' || crosshairCenterRef.current == null) {
        crosshairCenterRef.current = calculateCenter(containerRef.current);
      }

      if (!crosshairCenterRef.current) return;

      const centre = crosshairCenterRef.current;

      const lineLength = 6.5 / scale;

      sprite.moveTo(centre.x - lineLength, centre.y);
      sprite.lineTo(centre.x + lineLength, centre.y);
      sprite.moveTo(centre.x, centre.y - lineLength);
      sprite.lineTo(centre.x, centre.y + lineLength);

      crosshairSpriteRef.current = sprite;
      if (containerRef.current.children.length > 0) {
        // add to the first position to avoid that it is in front of measurement markers
        containerRef.current.addChildAt(crosshairSpriteRef.current, 1);
      } else {
        containerRef.current.addChild(crosshairSpriteRef.current);
      }
    },
    [scale]
  );

  /**
   * If the polygon data has not been processed yet then process it now and create the sprites.
   */
  const processPolyData = useCallback(() => {
    if (annoRef.current && !processedPolyData.current && viewerData?.polygonsLumen && viewerData.polygonsOuter) {
      lumenDataRef.current = processData(viewerData.polygonsLumen);
      outerDataRef.current = processData(viewerData.polygonsOuter);

      drawCentreCrossHair('refresh');
      createLumenSprites(viewIdx);
      createOuterSprites(viewIdx);
      // The polygon data has been processed.
      processedPolyData.current = true;
    }
  }, [viewerData, viewIdx, createLumenSprites, createOuterSprites, drawCentreCrossHair]);

  /**
   * The WebGLViewer will call this when it is ready.
   */
  const init = useCallback(
    ({ container, app, holder }: OnReadyInf) => {
      annoRef.current = new PIXI.Container();
      appRef.current = app;
      container.addChild(annoRef.current);
      annoRef.current.visible = showAnnos;
      holderRef.current = holder;
      // Clear any existing polygon data.
      clearPolyData();
      // Process the polygon data (if available).
      processPolyData();
      containerRef.current = container;
      setMillimeterSpacing(getAxisMPRViewPixelPerMm());
      setScale(containerRef.current?.scale.y ?? 1);
      setReady(true);
    },
    [clearPolyData, processPolyData, setReady, showAnnos]
  );

  /**
   * The polygon data may not have been available when this component initialised, if not we can try initializing it now.
   */
  useEffect(() => {
    processPolyData();
    // eslint-disable-next-line
  }, [viewerData?.polygonsLumen, viewerData?.polygonsOuter]);

  const reset = () => {
    cleanup();
    setTimeout(() => setReady(true), 0);
  };

  const simplifyPolyline = (polyline: number[][], nth: number) => {
    return polyline.filter((_, i) => i % nth === 0);
  };

  const onSliceChange = (index: number) => {
    onChangeViewIdx(index);

    // Only redraw the crosshairs if showAnnos is on
    drawCentreCrossHair('cached', showAnnos);
  };

  const onZoom = (index: number) => {
    // Only redraw the crosshairs if showAnnos is on
    drawCentreCrossHair('cached', showAnnos);
    createLumenSprites(index);
    createOuterSprites(index);
    setMillimeterSpacing(getAxisMPRViewPixelPerMm());
    setScale(containerRef.current?.scale.y ?? 1);
  };

  const onResize = (index: number) => {
    // Only redraw the crosshairs if showAnnos is on
    drawCentreCrossHair('cached', showAnnos);
    createLumenSprites(index);
    createOuterSprites(index);
  };

  const onMouseLeave = () => {
    if (hueRef.current && hueRef.current.setValue) {
      hueRef.current.setValue(null);
    }
  };

  const onMouseDown = useCallback((event: React.MouseEvent) => {
    // Capture the mouse pointer.
    captureMouse(event as any);
  }, []);

  const onVisibilityChange = () => {
    setShowAnnos(!showAnnos);
    if (annoRef.current && crosshairSpriteRef.current) {
      annoRef.current.visible = !showAnnos;
      crosshairSpriteRef.current.visible = !showAnnos;
    }
  };

  const viewId = `ShortAxisViewer-${_type}`;
  return (
    <div className="shortAxisMPRViewer" onMouseLeave={onMouseLeave} onMouseDown={onMouseDown} ref={screenshotRef}>
      {ready && (
        <MeasurementTool
          viewId={viewId}
          holder={holderRef.current}
          app={appRef.current}
          container={containerRef.current}
          slice={viewIdx}
          millimeterSpacing={millimeterSpacing}
          scale={scale}
        >
          <WebGLViewer
            viewId={viewId}
            viewType={KEY_MPR_SHORT_AXIS}
            resizeMode={RESIZE_MODE.COVER}
            onReady={init}
            slice={viewIdx}
            onSliceChange={onSliceChange}
            disableControls={disableControls}
            disableKeyboardControls={disableKeyboardControls}
            disableLoadingProgress={true}
            maxThresholdSlice={maxThreshold}
            minThresholdSlice={minThreshold}
            windowLevels={windowLevels}
            onZoom={onZoom}
            onResize={onResize}
            onWindowLevelsChange={onWindowLevelsChange}
            shapeData={viewerData?.shape}
            imageBufferData={viewerData?.imageBufferData}
            onHueChange={(hue) => {
              if (hueRef.current && hueRef.current.setValue) hueRef.current.setValue(hue || '-');
            }}
            onCleanup={cleanup}
          />
        </MeasurementTool>
      )}
      {displayMeasurements && (
        <div className="short-axial-measurement">
          <div className="short-axial-measurement_vessel">
            <span className="short-axial-measurement_vessel_value">
              {plaqueMeasurementsPerSlice?.area?.total &&
                plaqueMeasurementsPerSlice?.area.total.length > 0 &&
                !plaqueMeasurementsPerSlice?.burden?.total &&
                plaqueMeasurementsPerSlice?.area.total[viewIdx] > 0 && (
                  <span>{plaqueMeasurementsPerSlice?.area.total[viewIdx]} mm² (-%)</span>
                )}

              {!plaqueMeasurementsPerSlice?.area?.total &&
                plaqueMeasurementsPerSlice?.burden?.total &&
                plaqueMeasurementsPerSlice?.burden.total.length > 0 &&
                plaqueMeasurementsPerSlice?.burden.total[viewIdx] > 0 && (
                  <span>-mm² ({plaqueMeasurementsPerSlice?.burden.total[viewIdx]}%)</span>
                )}

              {plaqueMeasurementsPerSlice?.area?.total &&
              plaqueMeasurementsPerSlice?.area.total.length > 0 &&
              plaqueMeasurementsPerSlice?.burden?.total &&
              plaqueMeasurementsPerSlice?.burden.total.length > 0 &&
              plaqueMeasurementsPerSlice?.area.total[viewIdx] ? (
                <span>
                  {plaqueMeasurementsPerSlice?.area.total[viewIdx]} mm² (
                  {plaqueMeasurementsPerSlice?.burden.total[viewIdx]}%)
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
            <span className="short-axial-measurement_vessel_values">
              <span className="short-axial-measurement_vessel_outer"></span>
              {wallSliceMeasurements?.outer_area &&
              wallSliceMeasurements?.outer_area.length > 0 &&
              wallSliceMeasurements?.outer_area[viewIdx]
                ? wallSliceMeasurements?.outer_area[viewIdx] + ' mm²'
                : '-'}
            </span>
          </div>
          <div className="short-axial-measurement_vessel">
            <span className="short-axial-measurement_vessel_label">Plaque Area (Burden)</span>
            <span className="short-axial-measurement_vessel_values">
              <span className="short-axial-measurement_vessel_inner"></span>
              {wallSliceMeasurements?.lumen_area &&
              wallSliceMeasurements?.lumen_area.length > 0 &&
              wallSliceMeasurements?.lumen_area[viewIdx]
                ? wallSliceMeasurements?.lumen_area[viewIdx] + ' mm²'
                : '-'}
            </span>
          </div>
        </div>
      )}

      <ToolBar
        vessel={selectedVesselName}
        slice={`${viewIdx}`}
        HURef={hueRef}
        visibility={showAnnos}
        screenshotRef={screenshotRef}
        viewName={KEY_MPR_SHORT_AXIS}
        onVisibilityChange={onVisibilityChange}
        showVisibilityIcon={true}
      />
    </div>
  );
};
