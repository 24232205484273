import React from 'react';
import { Icon } from '../../../objects/Icon/Icon';

interface BasicNavProps {
  studyReviewCount: number;
  returnToDashboard: () => void;
}

export const BasicNav: React.FunctionComponent<BasicNavProps> = ({ studyReviewCount, returnToDashboard }) => {
  return (
    <button className="nav-bar__button" type="button" title="Dashboard" onClick={() => returnToDashboard()}>
      <div id="nav-bar-dashboard"></div>
      {studyReviewCount > 0 && <span className="nav-bar__notification">{studyReviewCount}</span>}
      <Icon icon="home" type="large" />
    </button>
  );
};
