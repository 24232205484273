import React from 'react';
import { ReviewCard } from '../../../components/ReviewCard/ReviewCard';
import { DATE_FORMAT } from '../../../config';
import { Study } from '../../../context/types';
import { formatDate } from '../WorkflowActions';

interface AwaitingReviewProps {
  // The list of studies awaiting review.
  studies: Study[];
  openStudyReport: (study: Study) => void;
  // Callback if the user chooses to cancel the request for review.
  onCancel: (study: Study) => void;
}

export const StudiesAwaitingReview: React.FunctionComponent<AwaitingReviewProps> = (props) => {
  if (props.studies.length < 1) {
    return null;
  }

  return (
    <>
      {props.studies.map((study, idx) => {
        return (
          study.report_active_review && (
            <ReviewCard
              idx={idx}
              category="awaiting review"
              key={`action_await_review__${idx}`}
              comment={
                study.report_active_review.message
                  ? {
                      message: study.report_active_review.message,
                      action: () => props.openStudyReport(study),
                    }
                  : undefined
              }
              patient={study.patient_name || ''}
              assignee={study.report_active_review.assignee.name}
              assigner={study.report_active_review.assigner.name}
              assignedDate={formatDate(study.report_active_review.created_at, DATE_FORMAT)}
              reviewStatus={study.report_active_review.review_type}
              onConfirm={() => props.onCancel(study)}
            />
          )
        );
      })}
    </>
  );
};
