import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { UnifiedIdLoader } from './reducers/store/UnifiedIdLoader';
import { StoreLoader } from './reducers/store/StoreLoader';
import { StudyLoader } from './reducers/study/StudyLoader';
import { VesselDataLoader } from './reducers/vesselData/VesselDataLoader';
import { VesselViewerDataLoader } from './reducers/vesselData/VesselViewerDataLoader';
import { NonContrastViewerShapeLoader } from './reducers/vesselData/NonContrastViewerShapeLoader';
import { ReportLoader } from './reducers/report/ReportLoader';
import { ReportStateLoader } from './reducers/ReportState/ReportStateLoader';
import { ModelLoader } from './reducers/model/ModelLoader';
import usePatientData from './hooks/use-patient-data';
import { useCalciumScoreUpdate, useImageLoader } from './reducers/vesselData/side-effects';
import { useAppSelector } from './hooks';
import { Loader } from './components/Loader/Loader';
import { NewStudyLoader } from './reducers/newStudy/NewStudyLoader';
import { LesionLoader } from './reducers/lesion/lesionloader';
import { CurrentStudyLoader } from './reducers/study/CurrentStudyLoader';
import { CtVolumeLoader } from './reducers/ctVolume/CtVolumeLoader';
import { VolumeDataLoader } from './reducers/contrast/VolumeDataLoader';
import { ReleaseVersionLoader } from './reducers/ReleaseVersion/releaseVersionLoader';
import { NewStudyMprLoader } from './reducers/newStudy/NewStudyMprLoader';

export interface AssessmentLoaderProps {
  children: ReactNode;
}

/**
 * The StoreProvider allows us to create a new store that is tied to the life-cycle of the StoreProvider component.
 */
export const AssessmentLoader: React.FunctionComponent<AssessmentLoaderProps> = (props) => {
  const { id } = useParams();
  const initialDataLoaded = useAppSelector((state) => state.globalFlags.initialDataLoaded);
  usePatientData();
  useCalciumScoreUpdate();
  useImageLoader();

  return (
    <>
      <ReleaseVersionLoader />
      {/* This "Loader" is responsible for loading the study data from the new endpoint */}
      <NewStudyLoader studyId={id} />
      <NewStudyMprLoader studyId={id} />
      {/* The CurrentStudyLoader have to be the initial loader to get the runId */}
      <CurrentStudyLoader studyId={id} />
      {/* These 'Loader' components will load the initial data starting with the UnifiedId */}
      <UnifiedIdLoader />
      <StoreLoader />
      <StudyLoader />
      <LesionLoader />
      <VesselDataLoader />
      <VesselViewerDataLoader />
      <NonContrastViewerShapeLoader />
      <ReportLoader />
      <ReportStateLoader />
      <ModelLoader />
      <CtVolumeLoader />
      {/* We don't start loading the VolumeData (ie contrast CT volume slices until initialDataLoaded */}
      <VolumeDataLoader />
      {initialDataLoaded && props.children}
      {!initialDataLoaded && <Loader text={'Loading study data'} large fullScreen />}
    </>
  );
};
