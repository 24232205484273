import { isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import { NON_CONTRAST_DROP_ZONE } from '../components/DropZone/DropZone';
import { useContrastContext } from '../context/contrast-context';
import {
  ContrastViewContent,
  ContrastViewType,
  ContrastVolume,
  ContrastVolumeActions,
  ContrastVolumeOverlayMode,
  ContrastVolumeMap,
  ContrastVolumeViewProps,
  CTVolumeOverlay,
} from '../context/contrast-types';
import { useAppDispatch, useAppSelector } from '../hooks';
import { contrastActions, VolumeData } from '../reducers/contrast/contrastSlice';
import { viewTypeToAxis } from '../views/CTVolume/ContrastViewer/Utils';

// If set we are waiting for the view to resize so we can take a screenshot.
export const useOnTakeScreenshot = () => {
  const { visibleViews } = useAppSelector((state) => state.contrast);
  const dispatch = useAppDispatch();
  return useCallback(
    (viewIndex: number, onTakeScreenshotCallback: (imageData: string) => void) => {
      dispatch(contrastActions.setVisibleViews([viewIndex]));
      dispatch(
        contrastActions.setPendingScreenshot({
          viewIndex,
          onTakeScreenshotCallback,
          lastVisibleViews: [...visibleViews],
        })
      );
    },
    [dispatch, visibleViews]
  );
};

// Call if the pending screenshot has now been taken.
export const useOnCloseScreenshot = () => {
  const dispatch = useAppDispatch();
  const { pendingScreenshot } = useAppSelector((state) => state.contrast);
  return useCallback(() => {
    if (pendingScreenshot) {
      dispatch(contrastActions.setVisibleViews(pendingScreenshot.lastVisibleViews));
      dispatch(contrastActions.setPendingScreenshot(undefined));
    }
  }, [dispatch, pendingScreenshot]);
};

/**
 * When a view is double clicked and multiple views are currently visible we switch to showing this single view.
 * When a view is double clicked and only that view is visible we restore the previous multi-view configuration.
 */
export const useOnDoubleClickView = () => {
  const dispatch = useAppDispatch();
  const { visibleViews, lastVisibleViews } = useAppSelector((state) => state.contrast);
  return useCallback(
    (viewIndex: number) => {
      if (visibleViews.length > 1) {
        dispatch(contrastActions.setLastVisibleViews(visibleViews));
        dispatch(contrastActions.setVisibleViews([viewIndex]));
      } else if (visibleViews.length === 1 && lastVisibleViews.length > 1) {
        dispatch(contrastActions.setVisibleViews(lastVisibleViews));
        dispatch(contrastActions.setLastVisibleViews([]));
      }
    },
    [visibleViews, lastVisibleViews, dispatch]
  );
};

/**
 * Helper function to get the ContrastViewType for the given view index.
 */
export const useGetViewType = () => {
  const { contrastViews } = useAppSelector((state) => state.contrast);
  return useCallback(
    (viewIndex: number): ContrastViewType | undefined => {
      if (viewIndex >= 0 && viewIndex <= contrastViews.length) {
        return contrastViews[viewIndex]?.viewType;
      }
      return undefined;
    },
    [contrastViews]
  );
};

/**
 * Adjust the overlay mode on the view to the next overlay mode.
 */
export const useSetNextOverlayMode = () => {
  const { contrastVolumeMap, dispatchContrastVolumeAction } = useContrastContext();
  const { contrastViews } = useAppSelector((state) => state.contrast);
  const getViewType = useGetViewType();
  return useCallback(
    (viewIndex: number) => {
      const contrastVolume = getContrastVolumeForViewIndex(contrastViews, contrastVolumeMap, viewIndex);
      const viewType = getViewType(viewIndex);
      if (contrastVolume && viewType !== undefined) {
        dispatchContrastVolumeAction({
          seriesName: contrastVolume.seriesName,
          type: ContrastVolumeActions.SET_OVERLAY_MODE,
          viewType: viewType,
          overlayMode: (contrastVolume.viewProps[viewType].overlayMode + 1) % ContrastVolumeOverlayMode.COUNT,
        });
      }
    },
    [contrastViews, contrastVolumeMap, dispatchContrastVolumeAction, getViewType]
  );
};

/**
 * Adjust the slice on the specified view by the specified delta.
 */
export const useAdjustSlice = () => {
  const { contrastVolumeMap } = useContrastContext();
  const { contrastViews, seriesVolumeData } = useAppSelector((state) => state.contrast);
  return useCallback(
    (viewIndex: number, deltaSlice: number) => {
      const contrastVolume = getContrastVolumeForViewIndex(contrastViews, contrastVolumeMap, viewIndex);
      const volumeData = getVolumeDataForViewIndex(contrastViews, seriesVolumeData, viewIndex);
      const api = contrastVolume?.getApi(viewIndex);
      if (contrastVolume && volumeData && api) {
        const viewType = api.getViewType();
        const spacing = volumeData.spacing;
        // We multiply the delta by the spacing of the voxel in the base orientation of the axis.
        // TODO: We should ideally do the maths to work out what the spacing should be in the currently rotated orientation.
        let viewSpacing = spacing ? spacing[viewTypeToAxis(viewType!)] : 1;
        deltaSlice *= viewSpacing;

        // Get the interactor.
        const istyle = api.genericRenderWindow.getInteractor().getInteractorStyle();

        // Get the slice range.
        const range = istyle.getSliceRange();
        // Get the current slice.
        let slice = istyle.getSlice();

        // Adjust the slice and limit the range.
        slice += deltaSlice;
        if (slice < range[0]) slice = range[0];
        if (slice > range[1]) slice = range[1];

        // Actually adjust the slice.
        istyle.scrollToSlice(slice);
      }
    },
    [contrastViews, contrastVolumeMap, seriesVolumeData]
  );
};

/**
 * Respond to a keydown event when the mouse is over the specified viewIndex.
 */
export const useOnKeyDown = () => {
  const setNextOverlayMode = useSetNextOverlayMode();
  const getViewType = useGetViewType();
  const adjustSlice = useAdjustSlice();
  return useCallback(
    (viewIndex: number, event: KeyboardEvent) => {
      const viewType = getViewType(viewIndex);
      // Check the mouse is over a contrast volume view.
      if (
        viewType !== undefined &&
        viewType in [ContrastViewType.Axial, ContrastViewType.Sagittal, ContrastViewType.Coronal]
      ) {
        // Determine the number of slices to add or subtract from the current slice.
        let delta = 0;
        switch (event.code) {
          case 'ArrowLeft':
            delta = -10;
            break;
          case 'ArrowRight':
            delta = 10;
            break;
          case 'ArrowUp':
            delta = 1;
            break;
          case 'ArrowDown':
            delta = -1;
            break;
          case 'Space':
            event.preventDefault();
            setNextOverlayMode(viewIndex);
            break;
          default:
            break;
        }
        if (delta !== 0) {
          adjustSlice(viewIndex, delta);
        }
      }
    },
    [adjustSlice, getViewType, setNextOverlayMode]
  );
};

/**
 * Update the visibleViews to reflect the newly requested visible view count.
 */
export const useOnVisibleViewCountButton = () => {
  const dispatch = useAppDispatch();
  const { visibleViews } = useAppSelector((state) => state.contrast);

  return useCallback(
    (visibleViewCount: number) => {
      // When the user chooses the number of visible views via one of the buttons this clears the lastVisibleViews set when a view was double clicked.
      dispatch(contrastActions.setLastVisibleViews([]));
      if (visibleViewCount !== visibleViews.length) {
        // TODO: Choose which visible views should remain visible vs defaulting to the first two?
        const newVisibleViews: number[] = [];
        for (let i = 0; i < visibleViewCount; i++) {
          newVisibleViews.push(i);
        }
        dispatch(contrastActions.setVisibleViews(newVisibleViews));
      }
    },
    [dispatch, visibleViews]
  );
};

/**
 * Switch to showing all (3 or 4) views of the contrast series.
 */
export const useOnDraggedContrastSeries = () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (contrastViews: ContrastViewContent[]) => {
      dispatch(contrastActions.setContrastViews(contrastViews));
      dispatch(contrastActions.setVisibleViews([0, 1, 2, 3]));
    },
    [dispatch]
  );
};

/**
 * Update the single view of index viewIndex with the specified contrastViewContent.
 */
export const useOnDraggedContrastView = () => {
  const dispatch = useAppDispatch();
  const { contrastViews } = useAppSelector((state) => state.contrast);
  return useCallback(
    (contrastView: ContrastViewContent, viewIndex: number) => {
      // Copy the existing contrast views array. Empty any views that are the same as the new contrast view.
      const newContrastViews: ContrastViewContent[] = [];
      for (let i = 0; i < 4; i++) {
        newContrastViews.push({
          seriesName: undefined,
          viewType: ContrastViewType.Empty,
        });
      }

      // The viewIndex is NON_CONTRAST_DROP_ZONE if we were viewing the non-contrast volume:
      // Drag the view to view 0 and only show view 0. Keep all the other contrast views empty.
      if (viewIndex === NON_CONTRAST_DROP_ZONE) {
        viewIndex = 0;
        dispatch(contrastActions.setVisibleViews([0]));
      }
      // Otherwise copy the existing contrast views if they don't clash with the newly dragged in view content.
      else {
        contrastViews.forEach((view, index) => {
          if (!isEqual(view, contrastView)) {
            newContrastViews[index] = { ...view };
          }
        });
      }

      // Set the new contrast view in the array.
      newContrastViews[viewIndex] = contrastView;

      // Set the contrast views.
      dispatch(contrastActions.setContrastViews(newContrastViews));
    },
    [contrastViews, dispatch]
  );
};

export const useSetContrastOverlayForViewIndex = () => {
  const dispatch = useAppDispatch();
  const { contrastOverlays } = useAppSelector((state) => state.contrast);
  return useCallback(
    (overlay: CTVolumeOverlay, viewIndex: number) => {
      const newOverlays = { ...contrastOverlays };
      newOverlays[viewIndex] = overlay;
      dispatch(contrastActions.setContrastOverlays(newOverlays));
    },
    [contrastOverlays, dispatch]
  );
};

/**
 * A helper function to get the contrastVolume for a specific viewIndex (or undefined if there is none).
 */
export function getContrastVolumeForViewIndex(
  contrastViews: ContrastViewContent[],
  contrastVolumeMap: ContrastVolumeMap,
  viewIndex: number
): ContrastVolume | undefined {
  if (viewIndex >= 0 && viewIndex < contrastViews.length) {
    // Empty views have no associated volume.
    const viewContent: ContrastViewContent = contrastViews[viewIndex];
    if (viewContent.viewType !== ContrastViewType.Empty && viewContent.seriesName) {
      return contrastVolumeMap.get(viewContent.seriesName);
    }
  }
  return undefined;
}

/**
 * A helper function to get the VolumeData for a specific viewIndex (or undefined if there is none).
 */
export function getVolumeDataForViewIndex(
  contrastViews: ContrastViewContent[],
  seriesVolumeData: Record<string, VolumeData>,
  viewIndex: number
): VolumeData | undefined {
  if (viewIndex >= 0 && viewIndex < contrastViews.length) {
    // Empty views have no associated volume.
    const viewContent: ContrastViewContent = contrastViews[viewIndex];
    if (viewContent.viewType !== ContrastViewType.Empty && viewContent.seriesName) {
      return seriesVolumeData[viewContent.seriesName];
    }
  }
  return undefined;
}

/**
 * Return the ContrastVolume that is currently associated with the specified viewIndex (0 to 3).
 */
export function useContrastVolumeSelector(viewIndex: number): ContrastVolume | undefined {
  const { contrastViews } = useAppSelector((state) => state.contrast);
  const { contrastVolumeMap } = useContrastContext();
  const contrastVolume: ContrastVolume | undefined = useMemo(() => {
    return getContrastVolumeForViewIndex(contrastViews, contrastVolumeMap, viewIndex);
  }, [viewIndex, contrastViews, contrastVolumeMap]);
  return contrastVolume;
}

/**
 * Return the ContrastViewType that is currently shown on specified viewIndex (0 to 3).
 */
export function useContrastViewTypeSelector(viewIndex: number): ContrastViewType | undefined {
  const { contrastViews } = useAppSelector((state) => state.contrast);
  const contrastViewType: ContrastViewType | undefined = useMemo(() => {
    if (viewIndex >= 0 && viewIndex < contrastViews.length) {
      return contrastViews[viewIndex].viewType;
    }
    return undefined;
  }, [viewIndex, contrastViews]);
  return contrastViewType;
}

/**
 * Return the CTVolumeOverlay state for the specified viewIndex (0 to 3).
 */
export function useContrastOverlaySelector(viewIndex: number): CTVolumeOverlay | undefined {
  const { contrastOverlays } = useAppSelector((state) => state.contrast);
  const contrastOverlay: CTVolumeOverlay | undefined = useMemo(() => {
    return contrastOverlays[viewIndex];
  }, [viewIndex, contrastOverlays]);
  return contrastOverlay;
}

/**
 * Get the array of ContrastViewProps used for each viewIndex [0 to 3].
 */
export function useContrastAllViewPropsSelector(): (ContrastVolumeViewProps | undefined)[] {
  const { contrastViews } = useAppSelector((state) => state.contrast);
  const { contrastVolumeMap } = useContrastContext();
  const contrastViewProps: (ContrastVolumeViewProps | undefined)[] = useMemo(() => {
    const newContrastViewProps: (ContrastVolumeViewProps | undefined)[] = [];
    for (let viewIndex = 0; viewIndex < 4; viewIndex++) {
      const contrastVolume = getContrastVolumeForViewIndex(contrastViews, contrastVolumeMap, viewIndex);
      newContrastViewProps.push(
        contrastVolume ? contrastVolume.viewProps[contrastViews[viewIndex].viewType] : undefined
      );
    }
    return newContrastViewProps;
  }, [contrastViews, contrastVolumeMap]);

  return contrastViewProps;
}
