import { useCallback } from 'react';
import { EMPTY_VIEW_ARRAY } from '../../context/contrast-constants';
import { ContrastViewContent, ContrastViewType } from '../../context/contrast-types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { usePushRecentlySelectedViews } from '../../hooks/useViewConfig';
import { viewConfigActions } from '../../reducers/viewConfig/viewConfigSlice';
import { DraggableItem } from '../../reducers/dragAndDrop/types';
import { isContrastSeries } from '../../reducers/dragAndDrop/helpers';
import { useOnDraggedContrastSeries, useOnDraggedContrastView } from '../../hooks/useContrastViewHelpers';
import { getViewContrastType } from './helpers';

/**
 * Respond to an entire contrast series being drag-dropped (ie all 3 or 4 views).
 */
export function useDropParentDraggable() {
  const pushRecentlySelectedViews = usePushRecentlySelectedViews();

  const dispatch = useAppDispatch();
  const { seriesId } = useAppSelector((state) => state.viewConfig);
  const draggableGroup = useAppSelector((state) => state.dragAndDrop.draggableGroup);
  const onDraggedContrastSeries = useOnDraggedContrastSeries();

  return useCallback(
    (draggableItem: DraggableItem) => {
      if (isContrastSeries(draggableItem.id) && seriesId) {
        const newConfig = draggableGroup[draggableItem.id].children.map(
          (child): ContrastViewContent => ({
            seriesName: draggableItem.id,
            viewType: getViewContrastType(child.customType) ?? ContrastViewType.Empty,
          })
        );

        // In case there are missing views, make 4 empty views, add after the actual
        // number of views then limit to 4
        const contrastViews = [...newConfig, ...EMPTY_VIEW_ARRAY].slice(0, 4);
        onDraggedContrastSeries(contrastViews);
        pushRecentlySelectedViews(contrastViews);
      } else {
        dispatch(viewConfigActions.clearRecentlySelectedViews());
      }
    },
    [seriesId, draggableGroup, onDraggedContrastSeries, pushRecentlySelectedViews, dispatch]
  );
}

/**
 * Respond to a single view being dragged and dropped.
 */
export function useDropChildDraggable() {
  const onDraggedContrastView = useOnDraggedContrastView();
  const pushRecentlySelectedViews = usePushRecentlySelectedViews();

  return useCallback(
    (draggableItem: DraggableItem, viewIndex: number) => {
      const contrastType = getViewContrastType(draggableItem.customType);
      // Check we are dragging in a valid contrast view type.
      if (contrastType !== undefined) {
        const contrastView: ContrastViewContent = {
          seriesName: draggableItem.id,
          viewType: contrastType,
        };
        onDraggedContrastView(contrastView, viewIndex);
        // Add the dragged view to the recent views list.
        pushRecentlySelectedViews([contrastView]);
      }
    },
    [onDraggedContrastView, pushRecentlySelectedViews]
  );
}
