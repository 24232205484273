// This slice holds internal data only. It has no external data sources.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialGlobalFlagsState = () => {
  return {
    visibleTab: 0,
    nonContrastSlice: 0,
    initialDataLoaded: false,
    displayMeasurements: false,
    fetchingOverallMeasurements: false,
    editingImpressions: false,
    editingCoronaryFindings: false,
  };
};
export const globalFlagsSlice = createSlice({
  name: 'global-slice',
  initialState: initialGlobalFlagsState(),
  reducers: {
    setVisibleTab: (state, action: PayloadAction<number>) => {
      state.visibleTab = action.payload;
    },
    setNonContrastSlice: (state, action: PayloadAction<number>) => {
      state.nonContrastSlice = action.payload;
    },
    setInitialDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.initialDataLoaded = action.payload;
    },
    setDisplayMeasurements: (state, action: PayloadAction<boolean>) => {
      state.displayMeasurements = action.payload;
    },
    setFetchingOverallMeasurements: (state, action: PayloadAction<boolean>) => {
      state.fetchingOverallMeasurements = action.payload;
    },
    setEditingImpressions: (state, action: PayloadAction<boolean>) => {
      state.editingImpressions = action.payload;
    },
    setEditingCoronaryFindings: (state, action: PayloadAction<boolean>) => {
      state.editingCoronaryFindings = action.payload;
    },
  },
});
export const globalFlagsActions = globalFlagsSlice.actions;
