import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Error: React.FunctionComponent = () => {
  const navigate = useNavigate() as any;
  const location = useLocation();

  if (!location.state) {
    navigate('/', { state: '' });
  }

  return (
    <div className="error" id="page-error">
      {location.state && location.state === 'permissionError' ? (
        <>
          Your user account does not have permission to access the patient database. <br />
          <br /> Please contact&nbsp;
          <a className="error__link" href="mailto:support@artrya.com">
            support@artrya.com
          </a>
          .
        </>
      ) : (
        `Sorry, we couldn't find what you're looking for`
      )}
    </div>
  );
};

export default Error;
