import React, { ReactElement, useCallback } from 'react';
import { DraftReport, Medication, ProcedureDetails } from '../../context/types';
import { useAppDispatch } from '../../hooks';
import { reportActions } from '../../reducers/report/reportSlice';
import Medications from './Medications';
import { TextInput } from '../reusable/TextInput/TextInput';
import Select from '../reusable/SelectNew/SelectNew';

const SCAN_QUALITIES = ['Poor', 'Fair', 'Good', 'Excellent'];
const ACQUISITION_MODES = ['Flash', 'Sequential'];
const CONTRAST_MEDIA = [
  'Isovue 300',
  'Isovue 370',
  'Omnipaque 300',
  'Omnipaque 350',
  'Optiray 300',
  'Optiray 350',
  'Ultravist 300',
  'Ultravist 370',
];
interface Props {
  editMode: boolean;
  procedureDetails: ProcedureDetails;
  draftProcedureDetails: ProcedureDetails | undefined;
}

const parseKey = (key: string) => {
  return key.replaceAll(' ', '_').toLocaleLowerCase();
};
export default function ProcedureDetailsCard({
  editMode,
  procedureDetails,
  draftProcedureDetails,
}: Props): ReactElement<Props> {
  const dispatch = useAppDispatch();
  const updateDraftReport = useCallback(
    (newDraft: DraftReport) => dispatch(reportActions.updateDraftReport(newDraft)),
    [dispatch]
  );

  const handleInputChange = useCallback(
    (value: string, key: string = '', type: string = 'text') => {
      if (type === 'number') {
        updateDraftReport({
          [parseKey(key)]: Number.isNaN(parseInt(value)) ? null : parseInt(value),
        });
      } else {
        updateDraftReport({
          [parseKey(key)]: value,
        });
      }
    },
    [updateDraftReport]
  );

  const handleDropDownValue = useCallback(
    (value: string, key: string) => {
      updateDraftReport({ [key]: value });
    },
    [updateDraftReport]
  );

  const updateMedications = useCallback(
    (values: Medication[]) => {
      updateDraftReport({ medications: values });
    },
    [updateDraftReport]
  );
  return (
    <>
      <div className="report-field">
        <div className="report-field__container">
          <h3 className="report-field__heading">Heart Rate</h3>
          <div className="report-field__field">
            {editMode && (
              <TextInput
                type="number"
                className="margin-none"
                value={draftProcedureDetails?.heart_rate}
                onChange={(event) => handleInputChange(event.target.value, 'heart_rate', 'number')}
                key="heart_rate"
                rightLabel="bpm"
              />
            )}
            {!editMode && (
              <span className="value-list__value">
                {procedureDetails.heart_rate ? procedureDetails.heart_rate + ' bpm' : '-'}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="report-field">
        <div className="report-field__container">
          <h3 className="report-field__heading">Medications</h3>
          <div className="report-field__field">
            <Medications
              editMode={editMode}
              value={editMode ? draftProcedureDetails?.medications : procedureDetails.medications}
              onChange={updateMedications}
            />
          </div>
        </div>
      </div>
      <div className="report-field">
        <div className="report-field__container">
          <h3 className="report-field__heading">Contrast</h3>
          <dl className="report-field__field contrast-field grid-2-mode">
            <div className="contrast-field__field">
              <dt className="value-list__label">Contrast Media</dt>
              <dd className="value-list__value">
                {editMode && (
                  <Select
                    value={draftProcedureDetails?.contrast_media}
                    onChange={(value) => handleDropDownValue(value, 'contrast_media')}
                    options={CONTRAST_MEDIA}
                    key="contrast_media"
                    disabled={!editMode}
                  />
                )}
                {!editMode && <>{procedureDetails.contrast_media ? procedureDetails.contrast_media + ' ml' : '-'}</>}
              </dd>
            </div>
            <div className="contrast-field__field">
              <dt className="value-list__label">Contrast Volume</dt>
              <dd className="value-list__value">
                {editMode && (
                  <TextInput
                    type="number"
                    className="margin-none"
                    value={draftProcedureDetails?.contrast_volume}
                    onChange={(event) => handleInputChange(event.target.value, 'contrast_volume', 'number')}
                    key="heart_rate"
                    rightLabel="mL"
                  />
                )}
                {!editMode && <>{procedureDetails.contrast_volume ? procedureDetails.contrast_volume + ' ml' : '-'}</>}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="report-field">
        <div className="report-field__container">
          <h3 className="report-field__heading">Misc.</h3>
          <dl>
            <div className="grid-2-mode">
              <div>
                <dt className="value-list__label">Acquisition Mode</dt>
                <dd className="value-list__value">
                  {editMode && (
                    <Select
                      value={draftProcedureDetails?.acquisition_mode}
                      onChange={(value) => handleDropDownValue(value, 'acquisition_mode')}
                      options={ACQUISITION_MODES}
                      key="acquisition_mode"
                      disabled={!editMode}
                    />
                  )}
                  {!editMode && <>{procedureDetails.acquisition_mode ? procedureDetails.acquisition_mode : '-'}</>}
                </dd>
              </div>
            </div>
            <div className="grid-2-mode">
              <div>
                <dt className="value-list__label">Scan Quality</dt>
                <dd className="value-list__value">
                  {editMode && (
                    <Select
                      value={draftProcedureDetails?.scan_quality}
                      onChange={(value) => handleDropDownValue(value, 'scan_quality')}
                      options={SCAN_QUALITIES}
                      key="scan_quality"
                      disabled={!editMode}
                    />
                  )}
                  {!editMode && <>{procedureDetails.scan_quality ? procedureDetails.scan_quality : '-'}</>}
                </dd>
              </div>
            </div>
            <div className="dlp-kvp-values">
              <div>
                <dt className="value-list__label">DLP</dt>
                <div className="value-list__value">
                  {editMode && (
                    <TextInput
                      type="number"
                      className="margin-none"
                      value={draftProcedureDetails?.dlp}
                      onChange={(event) => handleInputChange(event.target.value, 'dlp', 'number')}
                      key="dlp"
                      rightLabel="mGy*cm"
                    />
                  )}
                  {!editMode && <>{procedureDetails.dlp ? procedureDetails.dlp + ' mGy*cm' : '-'}</>}
                </div>
              </div>
              <div>
                <dt className="value-list__label">kVp</dt>
                <div className="value-list__value">
                  {editMode && (
                    <TextInput
                      type="number"
                      className="margin-none"
                      value={draftProcedureDetails?.kvp}
                      onChange={(event) => handleInputChange(event.target.value, 'kvp', 'number')}
                      key="kvp"
                      small={true}
                    />
                  )}
                  {!editMode && <>{procedureDetails.kvp ? procedureDetails.kvp : '-'}</>}
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
