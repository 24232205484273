import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron.svg';

const TRUNCATION_CHAR = '...';

function range(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, i) => i + start);
}

interface PaginationProps {
  numPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FunctionComponent<PaginationProps> = ({ numPages, currentPage, onPageChange }) => {
  const [pages, setPages] = useState<any[] | undefined>();

  useEffect(() => {
    if (numPages <= 6) {
      setPages(range(1, numPages));
    } else if (currentPage <= 5) {
      setPages([...range(1, 5), TRUNCATION_CHAR, numPages]);
    } else if (currentPage <= numPages - 5) {
      setPages([...range(currentPage - 4, currentPage), TRUNCATION_CHAR, numPages]);
    } else {
      setPages([1, TRUNCATION_CHAR, ...range(numPages - 4, numPages)]);
    }
  }, [numPages, currentPage]);

  const handlePageChange = (page: number) => {
    if (isNaN(page)) return;
    if (page <= 0) return;
    if (page >= numPages + 1) return;
    onPageChange(page);
  };

  return (
    <div className="pagination">
      <div className="pagination__items">
        <button
          className="pagination__item pagination__item--arrow pagination__item--double-arrow pagination__item--first"
          type="button"
          disabled={currentPage <= 1}
          onClick={() => handlePageChange(1)}
        >
          <ChevronIcon />
          <ChevronIcon />
        </button>
        <button
          className="pagination__item pagination__item--arrow pagination__item--prev"
          type="button"
          disabled={currentPage <= 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <ChevronIcon />
        </button>
        {pages &&
          pages.map((page) => (
            <button
              className={cn('pagination__item', {
                'pagination__item--number': page !== TRUNCATION_CHAR,
                'pagination__item--active': page === currentPage,
                'pagination__item--truncation': page === TRUNCATION_CHAR,
              })}
              type="button"
              disabled={page === TRUNCATION_CHAR}
              onClick={() => handlePageChange(page)}
              key={page}
            >
              {page}
            </button>
          ))}
        <button
          className="pagination__item pagination__item--arrow pagination__item--next"
          type="button"
          disabled={currentPage >= numPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <ChevronIcon />
        </button>
        <button
          className="pagination__item pagination__item--arrow pagination__item--double-arrow pagination__item--last"
          type="button"
          disabled={currentPage >= numPages}
          onClick={() => handlePageChange(numPages)}
        >
          <ChevronIcon />
          <ChevronIcon />
        </button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
