import React, { ReactElement } from 'react';
import Select from '../../../components/reusable/SelectNew/SelectNew';
import { useSetSelectedVesselSelector, useVesselStateSelector } from '../../../selectors/vessels';

export default function VesselSelection(): ReactElement {
  const { vessels, savingSelectedVessel, selectedVesselName } = useVesselStateSelector();
  const setSelectedVesselName = useSetSelectedVesselSelector();

  return (
    <div className="contrast-view-header__selected-vessel-container">
      <Select
        options={vessels.map((vessel) => ({
          value: vessel,
          label: vessel.toUpperCase(),
        }))}
        value={vessels.includes(selectedVesselName ?? '') ? selectedVesselName : undefined}
        onChange={(e) => setSelectedVesselName(e)}
        scrollable
        small
        // Block changing the vessel while an add or edit is still saving on the backend.
        // Swapping to another vessel would be fine but without per vessel data being cached locally we wouldn't be able
        // to go back to the newly added / edited vessel until the save operation is finished.
        disabled={savingSelectedVessel}
      />
    </div>
  );
}
