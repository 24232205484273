import { Action, configureStore } from '@reduxjs/toolkit';
import { vesselDataActionsSanitizer, vesselDataStateSanitizer } from './reducers/vesselData/vesselDataSliceSanitizers';
import { initialLoadSlice } from './reducers/initialLoad/initialLoadSlice';
import { calciumScoreSlice } from './reducers/calciumScore/calciumScoreSlice';
import { patientSlice } from './reducers/patient/patientSlice';
import { reportSlice } from './reducers/report/reportSlice';
import { cprSlice } from './reducers/cpr/cprSlice';
import { ctVolumeSlice } from './reducers/ctVolume/ctVolumeSlice';
import { contrastSlice } from './reducers/contrast/contrastSlice';
import { globalFlagsSlice } from './reducers/global-flags/globalFlagsSlice';
import { measurementToolsSlice } from './reducers/measurement-tools/measurementToolsSlice';
import { windowSlice } from './reducers/window/windowSlice';
import { reportStateSlice } from './reducers/ReportState/ReportStateSlice';
import { vesselDataSlice } from './reducers/vesselData/vesselDataSlice';
import { centerlineEditSlice } from './reducers/centerline-edit/centerlineEditSlice';
import { storeSlice } from './reducers/store/storeSlice';
import { viewConfigSlice } from './reducers/viewConfig/viewConfigSlice';
import { inlineReportingSlice } from './reducers/inlineReporting/inlineReportingSlice';
import { dragAndDropSlice } from './reducers/dragAndDrop/dragAndDropSlice';
import { modelSlice } from './reducers/model/modelSlice';
import { lesionSlice } from './reducers/lesion/lesionSlice';
import { studySlice } from './reducers/study/studySlice';
import { lesionDataActionsSanitizer, lesionDataStateSanitizer } from './reducers/lesion/lesionSliceSanitizers';
export const suppressedText = '<<Suppressed>>';

export function newStore() {
  return configureStore({
    reducer: {
      initialLoad: initialLoadSlice.reducer,
      calciumScore: calciumScoreSlice.reducer,
      study: studySlice.reducer,
      patient: patientSlice.reducer,
      report: reportSlice.reducer,
      cpr: cprSlice.reducer,
      ctVolume: ctVolumeSlice.reducer,
      contrast: contrastSlice.reducer,
      globalFlags: globalFlagsSlice.reducer,
      measurementTools: measurementToolsSlice.reducer,
      window: windowSlice.reducer,
      reportState: reportStateSlice.reducer,
      centerlineEdit: centerlineEditSlice.reducer,
      store: storeSlice.reducer,
      viewConfig: viewConfigSlice.reducer,
      vesselData: vesselDataSlice.reducer,
      inlineReporting: inlineReportingSlice.reducer,
      dragAndDrop: dragAndDropSlice.reducer,
      model: modelSlice.reducer,
      lesion: lesionSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
    devTools: {
      actionSanitizer: storeActionSanitizer,
      stateSanitizer: storeStateSanitizer,
    },
  });
}

function storeActionSanitizer<A extends Action>(action: A, _id: number) {
  let newAction = vesselDataActionsSanitizer(action);
  newAction = lesionDataActionsSanitizer(newAction);
  return newAction;
}

function storeStateSanitizer<S>(state: S, _index: number) {
  let newState = vesselDataStateSanitizer(state);
  newState = lesionDataStateSanitizer(newState);
  return newState;
}

// The dummyStore will never be connected, we just want it to get type information.
const dummyStore = newStore();
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof dummyStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof dummyStore.dispatch;
