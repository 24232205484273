import React from 'react';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand.svg';
import { ReactComponent as MinimiseIcon } from '../../assets/icons/minimise.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/report.svg';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { ReactComponent as ResetIcon } from '../../assets/icons/reset.svg';
import { ReactComponent as ReloadIcon } from '../../assets/icons/reload.svg';
import { ReactComponent as SpeechIcon } from '../../assets/icons/speech.svg';
import { ReactComponent as FFRIcon } from '../../assets/icons/ffr.svg';
import { ReactComponent as StraightRuler } from '../../assets/icons/straight-ruler.svg';
import { ReactComponent as EllipseRuler } from '../../assets/icons/ellipse-ruler.svg';
import { ReactComponent as Ruler } from '../../assets/icons/ruler.svg';
import { ReactComponent as VisibilityOffIcon } from '../../assets/icons/visibility-off.svg';
import { ReactComponent as Move } from '../../assets/icons/move.svg';

interface IconButtonProps {
  icon: string;
  onClick: () => void;
  color?: string;
  elevated?: boolean;
  disabled?: boolean;
  compact?: boolean;
  title?: string;
  selected?: boolean;
}

// TODO: import SVGs
export const ICONS: { [key: string]: JSX.Element } = {
  move: <Move />,
  close: <CloseIcon />,
  download: <DownloadIcon />,
  edit: <EditIcon />,
  expand: <ExpandIcon />,
  minimise: <MinimiseIcon />,
  report: <ReportIcon />,
  send: <SendIcon />,
  reset: <ResetIcon />,
  reload: <ReloadIcon />,
  speech: <SpeechIcon />,
  ffr: <FFRIcon />,
  straightRuler: <StraightRuler />,
  ellipseRuler: <EllipseRuler />,
  ruler: <Ruler />,
  visibilityOffIcon: <VisibilityOffIcon />,
};
export const COLORS = ['primary', 'white', 'toggle'];

export const IconButton: React.FunctionComponent<IconButtonProps> = ({
  icon,
  selected = false,
  color = 'primary',
  elevated,
  onClick,
  disabled = false,
  compact = false,
  title,
}) => {
  return (
    <button
      className={cn('icon-button', `icon-button--${color}`, {
        'icon-button--elevated': elevated,
        'icon-button--compact': compact,
        'icon-button--selected': selected,
      })}
      onClick={onClick}
      disabled={disabled}
      title={title}
    >
      <div className="icon-button__icon">{ICONS[icon]}</div>
    </button>
  );
};
