import Moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IDLE_CONFIG, PERMISSION_CHECK_INTERVAL } from '../../config';
import { useDashboardSelector } from '../../dashboardHooks';
import { useAppDispatch } from '../../hooks';
import { patientActions } from '../../reducers/patient/patientSlice';
import { performPermissionCheck } from '../../utils/auth';
import { lockStudy, unlockStudy } from '../../utils/studyLocking';
import { useAbortController } from '../../utils/use-abort-controller';
import { ActionModal } from '../ActionModal/ActionModal';
import { AUTH_USER_GROUP } from '../../config';
import { UseFetchStudyById, UseFetchStudyByIdPromise } from '../../hooks/UsefetchStudy';
import { studyActions } from '../../reducers/study/studySlice';

const IdleTimer: React.FunctionComponent = ({ children }) => {
  // Interval ID for locking/relocking the study
  const lockInterval = useRef<number | null>(null);
  // Interval ID for updating the countdown in the idle warning modal
  const inactiveInterval = useRef<number>(0);
  // Interval ID for checking user permissions
  const permissionInterval = useRef<number>(0);

  const { id } = useParams<{ id?: string }>();

  const { user } = useDashboardSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  // Seconds to display on the countdown in the idle warning modal
  const [idleCountdown, setIdleCountdown] = useState<string | undefined>(IDLE_CONFIG.idle_countdown);
  // Flag that sets whether or not the idle warning modal is visible
  const [showWarning, setShowWarning] = useState<boolean>(false);
  // Timestamp set when the study is successfully relocked (not set on the initial /lock call)
  const [relockTimestamp, setRelockTimestamp] = useState<Moment.Moment | null>(null);
  // The currently locked study ID
  const [lockedStudyId, setLockedStudyId] = useState<string | null>(null);
  const signal = useAbortController();
  const [studyClientId, setStudyClientId] = useState<string | null>(null);

  /**
   * We need to know the client_id of the currently selected study to know if we are an 'ArtryaUser'.
   * TODO: Remove this logic with AP-3402 as we won't need to know if a study is artya or not.
   */
  useEffect(() => {
    // Clear the studyClientId when the study is changed.
    setStudyClientId(null);
    // Fetch the studyClientId for the new study id.
    if (id != null) {
      UseFetchStudyByIdPromise(id).then((study) => {
        setStudyClientId(study.client_id);
      });
    }
  }, [id]);

  const isArtryaUser = useCallback(() => {
    // If we haven't fetched the studyClientId yet we don't know if we are an 'ArtryaUser' or not.
    if (studyClientId == null) {
      return undefined;
    }
    if (user.groups?.includes(AUTH_USER_GROUP.artrya) && studyClientId !== AUTH_USER_GROUP.artrya) {
      return true;
    }
    return false;
  }, [studyClientId, user.groups]);

  // Lock/relock the current study
  const _lockStudy = useCallback(async () => {
    if (!id || signal().aborted) {
      return;
    }

    const result = await lockStudy(id, navigate);
    if (result.success) {
      setLockedStudyId(result.studyId);
      if (result.didRelock) {
        setRelockTimestamp(Moment());
      }

      // Always set the patient ID to ensure that it is set if the user reloads the page (which triggers a relock on the
      // existing lock ID in session storage).
      dispatch(patientActions.setPatientID(result.studyId));
    }
  }, [navigate, id, signal, dispatch]);

  // Unlock the current study. Is a no-op if the study is already unlocked
  const _unlockStudy = useCallback(async () => {
    if (lockedStudyId) {
      await unlockStudy(lockedStudyId);
    }
  }, [lockedStudyId]);

  const stopRelockInterval = useCallback(() => {
    if (lockInterval.current !== null) {
      window.clearInterval(lockInterval.current);
      lockInterval.current = null;
    }
  }, []);

  const startRelockInterval = useCallback(() => {
    stopRelockInterval();

    lockInterval.current = window.setInterval(() => {
      _lockStudy();
    }, 10000);
  }, [_lockStudy, stopRelockInterval]);

  const checkStudyStatus = useCallback(async () => {
    const response = await UseFetchStudyById(id ?? '');
    dispatch(studyActions.setCurrentStudy(response));

    const { workflow_status } = response;

    if (workflow_status.state === 'Locked' && workflow_status.user !== user.name) {
      console.warn(`Study ${id} was locked by ${workflow_status.user}, returning to Dashboard`);
      navigate('/');
    }
  }, [navigate, id, user.name, dispatch]);

  const closeWarning = useCallback(
    (redirect = true) => {
      if (inactiveInterval.current) {
        window.clearInterval(inactiveInterval.current);
      }
      setIdleCountdown(IDLE_CONFIG.idle_countdown);
      setShowWarning(false);
      if (redirect) {
        navigate('/');
      }
    },
    [navigate]
  );

  const handleOnIdle = useCallback(() => {
    if (showWarning) return;

    stopRelockInterval();

    if (inactiveInterval.current) {
      setIdleCountdown(IDLE_CONFIG.idle_countdown);
      window.clearInterval(inactiveInterval.current);
    }

    setShowWarning(true);
    let timer = parseInt(IDLE_CONFIG.idle_countdown ?? '');
    inactiveInterval.current = window.setInterval(() => {
      const timeSinceLastRelock = Moment().diff(relockTimestamp, 'seconds');
      if (relockTimestamp === null || timeSinceLastRelock > parseInt(IDLE_CONFIG.lock_timeout ?? '') * 60) {
        // lock could have expired, so check study status to work out what to do
        checkStudyStatus();
      }

      if (timer && timer <= 0) {
        window.clearInterval(inactiveInterval.current);
        closeWarning();
      } else {
        setIdleCountdown((timer--).toString());
      }
    }, 1000);
  }, [checkStudyStatus, closeWarning, relockTimestamp, showWarning, stopRelockInterval]);

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * parseInt(IDLE_CONFIG.idle_timeout ?? ''),
    onIdle: handleOnIdle,
    startOnMount: false,
  });

  const continueReview = useCallback(() => {
    closeWarning(false);

    // Start locking again
    if (isArtryaUser() === false) {
      _lockStudy();
      startRelockInterval();
    }
  }, [_lockStudy, closeWarning, startRelockInterval, isArtryaUser]);

  // Reset the idle timer when we close the warning modal
  useEffect(() => {
    if (!showWarning) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWarning]); // reset is mutated, so leave it out of the dependency array

  // Check user permissions on mount
  useEffect(() => {
    let isCancelled = false;

    async function checkUserPermission() {
      const hasPermissions = await performPermissionCheck(user.groups);
      if (!hasPermissions && !isCancelled) {
        navigate('/error', { state: 'permissionError' });
      }
    }

    checkUserPermission();

    return () => {
      isCancelled = true;
      // Try to unlock the currently locked study on unmount
      _unlockStudy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start the permissions check interval on mount
  useEffect(() => {
    permissionInterval.current = window.setInterval(() => {
      performPermissionCheck(user.groups);
    }, PERMISSION_CHECK_INTERVAL);

    return () => window.clearInterval(permissionInterval.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start the locking interval whenever we navigate to a study page
  useEffect(() => {
    if (location.pathname.includes('study')) {
      // If we don't know if we are an 'ArtryaUser' or not yet don't progress any further.
      if (isArtryaUser() === undefined) {
        return;
      }
      // If we are an 'ArtryaUser' we don't need to lock the study.
      if (isArtryaUser() === true) {
        dispatch(patientActions.setPatientID(id));
        return;
      }
      // If we are not an 'ArtryaUser' we need to lock the study.
      startRelockInterval();
      _lockStudy();
    }

    return () => {
      stopRelockInterval();
    };
  }, [location.pathname, startRelockInterval, stopRelockInterval, id, isArtryaUser, dispatch, _lockStudy]);

  // Unlock currently locked studies when navigating to any non-study page
  useEffect(() => {
    if (!location.pathname.includes('study')) {
      _unlockStudy();
      setRelockTimestamp(null);
    }
  }, [_unlockStudy, location.pathname]);

  return (
    <>
      {children}
      <ActionModal
        confirmText={'Continue reviewing'}
        onClose={closeWarning}
        onConfirm={continueReview}
        visible={showWarning}
        headerContent={<>TIMEOUT ALERT</>}
      >
        <p>
          Are you still reviewing this patient study? If no further activity is detected, the file will automatically
          close in&nbsp;
          <span className="patient__idle-countdown">{idleCountdown}</span>
          &nbsp;seconds
        </p>
      </ActionModal>
    </>
  );
};
export default IdleTimer;
