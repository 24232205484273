import React, { useCallback, useEffect, useRef, useState } from 'react';
import trimCanvas from 'trim-canvas';
import { RangeSlider } from '../../components/RangeSlider/RangeSlider';
import Toggle from '../../components/Toggle/Toggle';
import { ToolBar } from '../../components/ToolBar/ToolBar';
import VesselMeasurement from '../../components/VesselMeasurements/VesselMeasurement';
import { BASE_KEY_3D_MODEL, KEY_3D_MODEL, MODEL_FULLSCREEN_DEMO, NAV_TABS } from '../../config';
import useMousePositionRef from '../../hooks/use-mouse-position-ref';
import {
  usePriorityVesselSelector,
  useResetToPriorityVesselSelector,
  useSetSelectedVesselSelector,
  useVesselStateSelector,
} from '../../selectors/vessels';
import { isCanvas } from '../../utils/shared';
import Model3d from './3dModel/3dModel';
import { Legend } from './Legend/Legend';
import SliceDetails from './SliceDetails/SliceDetails';
import cn from 'classnames';
import { useDashboardSelector } from '../../dashboardHooks';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { reportStateActions } from '../../reducers/ReportState/ReportStateSlice';
import { storeActions } from '../../reducers/store/storeSlice';
import { saveScreenshot } from '../../reducers/model/ModelLoader';

const DETAILS_PANEL_OFFSET = 10;

export const Model: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const showReport = useAppSelector((state) => state.report.show);
  const patientId = useAppSelector((state) => state.patient.patientID)!;
  const runId = useAppSelector((state) => state.study.currentStudy?.active_run)!;
  const stenosis = useAppSelector((state) => state.patient.stenosis);
  const displayMeasurements = useAppSelector((state) => state.globalFlags.displayMeasurements);
  const visibleTab = useAppSelector((state) => state.globalFlags.visibleTab);
  const model = useAppSelector((state) => state.model);

  const { contrastLesionData } = useAppSelector((state) => state.lesion);

  const screenshots = useAppSelector((state) => state.reportState.screenshots);

  const {
    vesselData,
    midSliceIdx: sliceidx,
    vessels,
    savingSelectedVessel,
    selectedVesselName = '',
  } = useVesselStateSelector();

  const resetToPriorityVessel = useResetToPriorityVesselSelector();
  const priorityVesselName = usePriorityVesselSelector();
  const setSelectedVesselName = useSetSelectedVesselSelector();
  const { user } = useDashboardSelector((state) => state.user);

  const mousePositionRef = useMousePositionRef();

  const [resetCamera, setResetCamera] = useState(false);
  const [showPlaque, setShowPlaque] = useState(false);
  const [plaqueOpacity, setPlaqueOpacity] = useState(1);
  const [showVP, setShowVP] = useState(true);

  const [showHoverData, setShowHoverData] = useState(false);
  const [hoverSliceIdx, setHoverSliceIdx] = useState<number | null>(null);
  const [hoverVessel, setHoverVessel] = useState<string | null>(null);

  const [modelLoaded, setModelLoaded] = useState(false);
  const [modelCameraLoaded, setModelCameraLoaded] = useState(false);

  const detailsRef = useRef<any | null>(null);
  const modelRef = useRef<any>(null);
  const model3dRef = useRef<Model3d>(null);
  const debounceEvent = useRef<any>(0);

  const openTab = visibleTab === NAV_TABS.patientTab;

  const onToggleHoverData = useCallback(
    (toggle: boolean) => {
      const { x, y } = mousePositionRef.current;
      if (detailsRef.current) {
        if (toggle) {
          document.body.style.cursor = 'pointer';
          detailsRef.current.style.left = `${x && x + DETAILS_PANEL_OFFSET}px` || '0px';
          detailsRef.current.style.top = `${y && y + DETAILS_PANEL_OFFSET}px` || '0px';
          clearTimeout(debounceEvent.current);
          debounceEvent.current = setTimeout(() => {
            setShowHoverData(toggle);
            detailsRef.current.style.opacity = 1;
            detailsRef.current.style.visibility = 'visible';
          }, 50);
        } else {
          document.body.style.cursor = '';
          detailsRef.current.style.opacity = 0;
          detailsRef.current.style.visibility = 'hidden';
          setShowHoverData(toggle);
          clearTimeout(debounceEvent.current);
        }
      }
    },
    [mousePositionRef]
  );

  /**
   * Set the VP and plaque visiblity. This is used in the animated video demo.
   */
  const setVPAndPlaqueVisibility = useCallback(
    (show: boolean) => {
      setShowVP(show);
      setShowPlaque(show);
    },
    [setShowVP, setShowPlaque]
  );

  useEffect(() => {
    if (resetCamera) {
      setResetCamera(false);
      resetToPriorityVessel();
    }
  }, [resetCamera, resetToPriorityVessel]);

  useEffect(() => {
    let isCancelled = false;
    if (!modelCameraLoaded) return;
    if (!modelRef.current) return;

    const imageTitle = `${BASE_KEY_3D_MODEL}_${selectedVesselName.toUpperCase()}`;

    const exisitingModelImage = screenshots.filter((s: { title: string }) => s.title === imageTitle);

    if (exisitingModelImage.length > 0) {
      dispatch(storeActions.setModelImage(exisitingModelImage[0].path));
      return;
    }

    (async () => {
      const element = isCanvas(modelRef.current) ? modelRef.current : modelRef.current.querySelector('canvas');

      if (!element) return;
      if (!element.toDataURL) return;

      // Trimming the canvas before getting image data
      // Requires cloning the original canvas and calling trim-canvas

      // create a new canvas
      const imageCanvas = document.createElement('canvas');
      const imageContext = imageCanvas.getContext('2d');

      //set dimensions
      imageCanvas.width = element.width;
      imageCanvas.height = element.height;

      let trimedCanvas = undefined;

      if (imageContext) {
        //apply the old canvas to the new one
        imageContext.drawImage(element, 0, 0);
        trimedCanvas = trimCanvas(imageCanvas);
      }

      try {
        // Resize the 3D model canvas before taking the screnshot to ensure we capture the whole model
        model3dRef.current?.resize(2000, 2000);
        // In case there is an issue with the trimmed canvas, fallback to original canvas
        const imageData = trimedCanvas ? trimedCanvas.toDataURL() : element.toDataURL();
        // Resize back to the browser window size
        model3dRef.current?.onWindowResize();

        const response = await saveScreenshot(patientId, runId, imageTitle, imageData);

        if (isCancelled) {
          return;
        }
        if (!response) throw new Error('No response');
        dispatch(reportStateActions.setScreenshots(response));
        dispatch(storeActions.setModelImage(response.filter((s: { title: string }) => s.title === imageTitle)[0].path));
      } catch (err) {
        console.error('Error taking 3D model screenshot');
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [modelCameraLoaded, patientId, runId, screenshots, selectedVesselName, dispatch]);

  const resetView = () => {
    setPlaqueOpacity(1);
    setShowPlaque(false);
    setShowVP(true);
    setResetCamera(true);
  };

  return (
    <div className={MODEL_FULLSCREEN_DEMO ? 'model__fullscreen_demo' : 'model card-new'} ref={modelRef}>
      <div className="model__viewer" id="scene-container" ref={modelRef}>
        <Model3d
          ref={model3dRef}
          patientId={patientId}
          runId={runId}
          vessels={vessels}
          vesselID={selectedVesselName}
          openTab={openTab}
          modelRef={modelRef}
          resetCamera={resetCamera}
          showPlaque={showPlaque}
          showVP={showVP}
          plaqueOpacity={plaqueOpacity}
          sliceidx={sliceidx}
          setSelectedVesselName={setSelectedVesselName}
          setShowHoverData={onToggleHoverData}
          setHoverSliceIdx={setHoverSliceIdx}
          setHoverVessel={setHoverVessel}
          showReport={showReport}
          setModelLoaded={setModelLoaded}
          setModelCameraLoaded={setModelCameraLoaded}
          priorityVesselName={priorityVesselName}
          vesselData={vesselData}
          contrastLesionData={contrastLesionData}
          user={user}
          stenosis={stenosis}
          savingSelectedVessel={savingSelectedVessel}
          setVPAndPlaqueVisibility={setVPAndPlaqueVisibility}
          model={model}
        />
      </div>
      {modelLoaded && (
        <div ref={detailsRef} className="slice-details__container">
          <SliceDetails
            hoverVessel={hoverVessel}
            hoverSliceIdx={hoverSliceIdx}
            showHoverData={showHoverData}
            lesionData={contrastLesionData}
            vesselData={vesselData}
          />
        </div>
      )}
      <Legend />

      {modelLoaded && (
        <div
          className={cn(
            displayMeasurements ? 'model__controllers-measurements-position' : 'model__controllers-position'
          )}
        >
          <div className={cn(MODEL_FULLSCREEN_DEMO ? 'model__fullscreen_demo_controllers' : 'model__controllers')}>
            <div className="controllers-section">
              <Toggle
                name="plaque"
                labelText="Plaque"
                checked={showPlaque}
                onChange={() => {
                  setShowPlaque(!showPlaque);
                }}
              />

              <div style={{ maxWidth: '110px' }}>
                <RangeSlider
                  min={0}
                  max={1}
                  step={0.1}
                  value={plaqueOpacity}
                  disabled={!showPlaque}
                  onChange={setPlaqueOpacity}
                />
              </div>
            </div>

            <div className="controllers-section">
              <Toggle
                name="vulnerable_plaque"
                labelText="Vulnerable Plaque"
                checked={showVP}
                onChange={() => {
                  setShowVP(!showVP);
                }}
              />
              <span className="model__vp-icon"></span>
            </div>
          </div>
          {displayMeasurements && <VesselMeasurement />}
        </div>
      )}

      {!MODEL_FULLSCREEN_DEMO && (
        <ToolBar
          vessel={showHoverData ? hoverVessel || selectedVesselName : selectedVesselName}
          slice={`${showHoverData ? hoverSliceIdx || sliceidx : sliceidx}`}
          showVisibilityIcon={false}
          screenshotRef={modelRef}
          viewName={KEY_3D_MODEL}
          showResetButton={true}
          resetView={resetView}
        />
      )}
    </div>
  );
};

export default Model;
