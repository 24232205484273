import cn from 'classnames';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as EditReportIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as GlassesIcon } from '../../assets/icons/glasses.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified.svg';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import Button from '../../components/Button/Button';
import { Loader } from '../../components/Loader/Loader';
import Select from '../reusable/SelectNew/SelectNew';
import { showToast } from '../../components/Toast/showToast';
import { APPROVAL_WAIT_MINUTES } from '../../config';
import { useDashboardDispatch, useDashboardSelector } from '../../dashboardHooks';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  inlineReportingAction,
  UnsavedChangesToInlineReport,
} from '../../reducers/inlineReporting/inlineReportingSlice';
import { ListReview, ReviewType } from '../../context/types';
import { workflowActions } from '../../reducers/workflow/workflowSlice';
import { useCancelReviewRequest } from '../../hooks/use-review-status-hooks';
import { useCompleteReview, useRequestReview } from '../../hooks/use-workflow-helpers';
import { useRevertVersionCallBack } from '../../hooks/useSaveCallBack';
import { reportActions } from '../../reducers/report/reportSlice';
import { reportStateActions } from '../../reducers/ReportState/ReportStateSlice';
import { useAnyInlineReportingChangesMade } from '../../selectors/reporting';
import { currentStudySelector } from '../../selectors/study';
import HistorySection from './HistorySection';
import * as phi from '../../utils/phi';
import { studyActions } from '../../reducers/study/studySlice';
import { UseFetchStudyById } from '../../hooks/UsefetchStudy';

interface ReviewOptions {
  value: ReviewType;
  label: string;
}

const REPORT_REVIEW_OPTIONS: ReviewOptions[] = [
  { value: 'approve', label: 'Review and approval' },
  { value: 'review', label: 'Review only' },
];

interface ReportStatusProps {
  canApprove: boolean;
  editMode: boolean;
  isApproving: boolean;
  isGenerating: boolean;
  onEditReport: () => void;
  onCancelEdit: () => void;
  onSaveFindings: () => void;
  onApproving: () => void;
  onGenerate: () => void;
}

enum RevertPromptType {
  None = 'none',
  Initial = 'AI generated',
  Previous = 'previous',
}

export const ReportStatus: React.FunctionComponent<ReportStatusProps> = ({
  canApprove,
  editMode,
  isApproving,
  isGenerating,
  onEditReport,
  onCancelEdit,
  onSaveFindings,
  onApproving,
  onGenerate: generatePdf,
}) => {
  const postingPartialReport = useAppSelector((state) => state.report.postingPartialReport);
  const reviewList = useAppSelector((state) => state.store.reviewList);
  const reportHistory = useAppSelector((state) => state.report.reportHistory);
  const currentReport = useAppSelector((state) => state.report.current);
  const draftReport = useAppSelector((state) => state.report.draft);
  const reviewUsers = useAppSelector((state) => state.reportState.reviewUsers);

  const { id } = useParams();
  const selectedStudy = useAppSelector(currentStudySelector);

  const { user } = useDashboardSelector((state) => state.user);

  const [hasUpdated, setHasUpdated] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [showReview, setShowReview] = useState(false);
  const [showSubmission, setShowSubmission] = useState(false);

  const [selectedReviewer, setSelectedReviewer] = useState('');
  const [reviewOption, _setReviewOption] = useState<ReviewType>('approve');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedStudy) return;
    dispatch(reportStateActions.setApproved(!!selectedStudy['is_report_approved']));
    (async () => {
      const decrypted = await phi.decryptDashboardItem(selectedStudy);
      dispatch(reportStateActions.setDecryptedStudy(decrypted));
    })();
  }, [dispatch, selectedStudy]);

  const setReviewOption = useCallback(
    (value: string) => {
      const reviewOption = value === 'approve' || value === 'review' ? value : undefined;

      if (reviewOption) {
        _setReviewOption(reviewOption);
      }
    },
    [_setReviewOption]
  );

  const [reviewComment, setReviewComment] = useState('');

  const anyInlineReportingChangesMade = useAnyInlineReportingChangesMade();
  const dashboardDispatch = useDashboardDispatch();
  const discardUnsavedInlineReportChanges = useCallback(() => {
    dispatch(inlineReportingAction.clearImpressions());
    dispatch(inlineReportingAction.clearCoronaryFindings());
  }, [dispatch]);

  const handleEdit = useCallback(() => {
    if (anyInlineReportingChangesMade) {
      dispatch(
        inlineReportingAction.setInlineReportingWarningAction(() => {
          discardUnsavedInlineReportChanges();
          onEditReport();
        })
      );
      dispatch(inlineReportingAction.setUnsavedChangesWarning(UnsavedChangesToInlineReport.EditingReport));
    } else {
      discardUnsavedInlineReportChanges();
      onEditReport();
    }
  }, [anyInlineReportingChangesMade, dispatch, discardUnsavedInlineReportChanges, onEditReport]);

  const onGenerate = useCallback(() => {
    if (anyInlineReportingChangesMade) {
      dispatch(
        inlineReportingAction.setInlineReportingWarningAction(() => {
          discardUnsavedInlineReportChanges();
          generatePdf();
        })
      );
      dispatch(inlineReportingAction.setUnsavedChangesWarning(UnsavedChangesToInlineReport.GeneratingReport));
    } else {
      discardUnsavedInlineReportChanges();
      generatePdf();
    }
  }, [anyInlineReportingChangesMade, dispatch, discardUnsavedInlineReportChanges, generatePdf]);

  const handleCancel = useCallback(() => {
    onCancelEdit();
  }, [onCancelEdit]);

  const handleSave = useCallback(() => {
    setHasUpdated(true);
    setHasUpdated(false);
    onSaveFindings();
    dispatch(reportActions.setEditing(false));
  }, [dispatch, onSaveFindings]);

  const handleReview = useCallback(() => {
    if (anyInlineReportingChangesMade) {
      dispatch(
        inlineReportingAction.setInlineReportingWarningAction(() => {
          discardUnsavedInlineReportChanges();
          setShowReview(true);
        })
      );
      dispatch(inlineReportingAction.setUnsavedChangesWarning(UnsavedChangesToInlineReport.SendingStudyForApproval));
    } else {
      discardUnsavedInlineReportChanges();
      setShowReview(true);
    }
  }, [anyInlineReportingChangesMade, discardUnsavedInlineReportChanges, dispatch]);

  const handleInputChange = useCallback((event) => {
    setReviewComment(event.target.value);
  }, []);

  useEffect(() => {
    if (isApproving) {
      setButtonText('Approving Analysis');
    }
    if (isGenerating) {
      setButtonText('Generating PDF');
    }
  }, [isApproving, isGenerating]);

  const hasChanges = useMemo(() => !isEqual(draftReport, currentReport), [draftReport, currentReport]);

  const revertVersion = useRevertVersionCallBack(id);

  const [revertPromptType, setRevertPromptType] = useState<RevertPromptType>(RevertPromptType.None);

  const doRevert = useCallback(() => {
    switch (revertPromptType) {
      case RevertPromptType.Initial:
        revertVersion('initial');
        break;
      case RevertPromptType.Previous:
        revertVersion('previous');
        break;
    }

    setRevertPromptType(RevertPromptType.None);
  }, [revertPromptType, revertVersion]);

  const cancelRevert = useCallback(() => setRevertPromptType(RevertPromptType.None), []);

  // Is the logged in user the assignee for this report
  const isAssignee = useMemo(() => {
    if (!selectedStudy || !selectedStudy.report_active_review) {
      return false;
    }

    return user.email === selectedStudy.report_active_review.assignee.email;
  }, [selectedStudy, user.email]);

  // Is the logged in user the assigner for this report
  const isAssigner = useMemo(() => {
    if (!selectedStudy || !selectedStudy.report_active_review) {
      return false;
    }

    return user.email === selectedStudy.report_active_review.assigner.email;
  }, [selectedStudy, user.email]);

  // What review type is required. Undefined if review can't be approved or reviewed.
  const reviewType = useMemo<ReviewType | undefined>(() => {
    if (!selectedStudy?.report_active_review) {
      // No active review, anyone can approve
      return undefined;
    }

    if (isAssigner && selectedStudy?.is_report_approved) {
      // Assigner can't approve a report that's already approved
      return undefined;
    }

    if (isAssignee) {
      // Check active review type
      return selectedStudy?.report_active_review?.review_type;
    }

    if (isAssigner) {
      // Assigners can always approve
      return 'approve';
    }

    return undefined;
  }, [isAssignee, isAssigner, selectedStudy]);

  // Is a complete review API call in progress?
  const [isCompletingReview, setIsCompletingReview] = useState<boolean>(false);

  // Callback for complete review API call
  const completeReview = useCompleteReview();

  const { showCancelReviewConfirm } = useDashboardSelector((state) => state.workflow);

  const closeCancelReview = useCallback(() => {
    dashboardDispatch(workflowActions.setShowCancelReviewConfirm(false));
  }, [dashboardDispatch]);

  const handleCancelReview = useCallback(() => {
    dashboardDispatch(workflowActions.setShowCancelReviewConfirm(true));
  }, [dashboardDispatch]);

  // Do complete review API call while setting the isCompletingReview flag
  const doCompleteReview = useCallback(async () => {
    const reviewId = selectedStudy?.report_active_review?.review_id;
    if (!reviewId) {
      showToast.error('Failed to get review ID');
      return;
    }

    if (!reviewType) {
      showToast.error('Failed to get review type');
      return;
    }

    setIsCompletingReview(true);
    await completeReview(reviewId, reviewType);
    setIsCompletingReview(false);
  }, [completeReview, selectedStudy?.report_active_review?.review_id, reviewType]);

  const completeReviewPassthrough = useCallback(() => {
    if (reviewType === 'review') {
      doCompleteReview();
    } else {
      setShowSubmission(true);
    }
  }, [doCompleteReview, setShowSubmission, reviewType]);

  const handleReviewCompletion = useCallback(() => {
    if (anyInlineReportingChangesMade) {
      dispatch(
        inlineReportingAction.setInlineReportingWarningAction(() => {
          discardUnsavedInlineReportChanges();
          completeReviewPassthrough();
        })
      );

      dispatch(inlineReportingAction.setUnsavedChangesWarning(UnsavedChangesToInlineReport.ApprovingReport));
    } else {
      discardUnsavedInlineReportChanges();
      completeReviewPassthrough();
    }
  }, [anyInlineReportingChangesMade, dispatch, discardUnsavedInlineReportChanges, completeReviewPassthrough]);

  const handleApprovalModalConfirm = useCallback(() => {
    if (reviewType === undefined || isAssigner) {
      // Calls the '/report/approve' endpoint
      onApproving();
    } else {
      // Calls the '/report/review/complete' endpoint
      doCompleteReview();
    }
    setShowSubmission(false);
  }, [doCompleteReview, onApproving, reviewType, isAssigner]);

  // List of users available to assign reviews to
  const reviewUsersOptions = useMemo(() => reviewUsers?.map((u) => ({ label: u.name, value: u.email })) ?? [], [
    reviewUsers,
  ]);

  // Callback for request review API call
  const requestReview = useRequestReview();

  const handleRequestReview = useCallback(async () => {
    const assignee = reviewUsers?.find((v) => v.email === selectedReviewer);
    if (!assignee) {
      return;
    }

    setShowReview(false);
    await requestReview(assignee, reviewOption, reviewComment);
  }, [requestReview, reviewComment, reviewOption, selectedReviewer, reviewUsers]);

  const handleCancelRequestReview = useCallback(() => {
    setShowReview(false);
  }, []);

  const renderReview = useCallback((review: ListReview, key?: string) => {
    if (review.completed_at === null && review.cancelled_at === null) {
      return (
        <HistorySection
          key={key}
          note={`Sent to ${review.assignee.name} for ${
            review.review_type === 'review' ? 'review' : 'review and approval'
          }`}
          date={review.created_at}
        />
      );
    }

    if (review.review_type !== 'approve' && review.completed_at !== null) {
      return <HistorySection key={key} note={`Reviewed by ${review.assignee.name}`} date={review.completed_at} />;
    }

    return null;
  }, []);

  const cancelReviewRequest = useCancelReviewRequest();

  return (
    <div className="report-status">
      {editMode ? (
        <div className="report-status__action report-status__editing">
          <Button theme="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button theme="primary" onClick={handleSave} disabled={!hasChanges}>
            Done
          </Button>
        </div>
      ) : (
        <div
          className={cn('report-status__action report-status__edit', {
            full: !hasUpdated,
          })}
        >
          <Button theme="secondary" onClick={handleEdit} disabled={isApproving || isGenerating || postingPartialReport}>
            <>
              {postingPartialReport && (
                <>
                  <Loader small inline />
                </>
              )}
              Edit report findings <EditReportIcon />
            </>
          </Button>
        </div>
      )}
      {!!reviewType && !selectedStudy?.is_report_approved && (
        <div className="report-status__action">
          <Button
            theme="secondary"
            onClick={handleCancelReview}
            disabled={!canApprove || editMode || isApproving || isGenerating || postingPartialReport}
          >
            <>
              Cancel review <CancelIcon />
            </>
          </Button>
        </div>
      )}

      {reviewType !== undefined && (
        <div className="report-status__action">
          <Button
            theme="primary"
            onClick={handleReviewCompletion}
            disabled={isCompletingReview || editMode || isGenerating || postingPartialReport}
          >
            {!isCompletingReview ? (
              <>
                {`${reviewType === 'review' ? 'Complete Review' : 'Approve'}`}
                {reviewType === 'review' ? <GlassesIcon /> : <VerifiedIcon />}
              </>
            ) : (
              <>
                {buttonText}
                <Loader small inline />
              </>
            )}
          </Button>
        </div>
      )}

      {!selectedStudy?.report_active_review && !selectedStudy?.is_report_approved && (
        <div className="report-status__action">
          <Button theme="secondary" onClick={handleReview} disabled={editMode}>
            <>
              Send for review <GlassesIcon />
            </>
          </Button>
        </div>
      )}

      {/* Approval button for studies with no active review */}
      {reviewType === undefined && !selectedStudy?.is_report_approved && !selectedStudy?.report_active_review && (
        <div className="report-status__action">
          <Button
            theme="primary"
            onClick={handleReviewCompletion}
            disabled={!canApprove || editMode || isApproving || isGenerating || postingPartialReport}
          >
            {!isApproving ? (
              <>
                Approve <VerifiedIcon />
              </>
            ) : (
              <>
                {buttonText}
                <Loader small inline />
              </>
            )}
          </Button>
        </div>
      )}
      {selectedStudy?.is_report_approved && (
        <div className="report-status__action">
          <Button
            theme="primary"
            onClick={onGenerate}
            disabled={editMode || isApproving || isGenerating || postingPartialReport}
          >
            {!isGenerating ? (
              <>
                Generate PDF <FileIcon />
              </>
            ) : (
              <>
                {buttonText}
                <Loader small inline />
              </>
            )}
          </Button>
        </div>
      )}

      {selectedStudy?.report_active_review && !isAssignee && !isAssigner && (
        <div className="report-status__review_status">{`Awaiting ${
          selectedStudy?.report_active_review.review_type === 'review' ? 'review' : 'approval'
        }`}</div>
      )}

      {!editMode && (reportHistory?.current?.timestamp || reportHistory?.previous?.timestamp) && (
        <HistorySection
          note={'Revert to the AI Generated version'}
          revertToThisVersion={() => setRevertPromptType(RevertPromptType.Initial)}
          hideDivider
          disabled={selectedStudy?.report_active_review !== null}
          disabledReason={
            selectedStudy?.report_active_review !== null ? 'Cannot revert a report while a review is active' : ''
          }
        />
      )}

      {reportHistory?.current && (
        <HistorySection
          note={`Last amended by ${reportHistory.current.user.name}`}
          date={reportHistory.current?.timestamp}
        />
      )}

      {!editMode && reportHistory?.previous && (
        <HistorySection
          note={`Revert to previous version`}
          date={reportHistory.previous.timestamp}
          revertToThisVersion={() => setRevertPromptType(RevertPromptType.Previous)}
          hideDivider
          disabled={selectedStudy?.report_active_review !== null}
          disabledReason={
            selectedStudy?.report_active_review !== null ? 'Cannot revert a report while a review is active' : ''
          }
        />
      )}
      {reportHistory?.approvers && reportHistory.approvers.length > 0 && (
        <HistorySection
          note={`Approved by: ${reportHistory.approvers.map((approver) => approver.user.name).join(', ')}`}
          date={reportHistory.approvers[0].timestamp}
        />
      )}
      {reviewList && reviewList.map((review, i) => renderReview(review, i.toString()))}
      <ActionModal
        confirmText="Revert"
        closeText="Cancel"
        onConfirm={doRevert}
        onClose={cancelRevert}
        visible={revertPromptType !== RevertPromptType.None}
        headerContent={<>{`REVERT TO ${revertPromptType.toUpperCase()} VERSION`}</>}
      >
        <p>
          {`Are you sure you want to revert to the ${revertPromptType} version of the report? Any changes from the current version will be discarded.`}
        </p>
      </ActionModal>
      <ActionModal
        confirmText={'Submit'}
        closeText={'Cancel'}
        onClose={() => setShowSubmission(false)}
        onConfirm={handleApprovalModalConfirm}
        visible={showSubmission}
        headerContent={<>APPROVAL SUBMISSION</>}
      >
        <div className="report-submission">
          You still have {APPROVAL_WAIT_MINUTES} more minutes to make any amendments to this report before its approval
          status is finalised. After approval is finalised, a reason for further amendments must be provided.
        </div>
      </ActionModal>
      {
        /** Unmount when not showing so buttons aren't still clickable */ showReview && (
          <ActionModal
            confirmText={'Continue'}
            closeText={'Cancel'}
            onClose={handleCancelRequestReview}
            onConfirm={handleRequestReview}
            visible={showReview}
            headerContent={<>Send study for review</>}
            disabled={!selectedReviewer}
          >
            <div className="report-review-modal">
              <div className="report-review-modal__options">
                <Select
                  placeholder="Select a reviewer"
                  options={reviewUsersOptions}
                  value={selectedReviewer}
                  onChange={setSelectedReviewer}
                  scrollable
                  isSearchable={true}
                  bgDark={true}
                />
                <div>
                  {selectedReviewer && (
                    <Select
                      placeholder="Select a reviewer"
                      options={REPORT_REVIEW_OPTIONS}
                      value={reviewOption}
                      onChange={setReviewOption}
                      bgDark={true}
                    />
                  )}
                </div>
              </div>
              <div className="report-review-modal__comment findings">
                <TextareaAutosize
                  maxRows={7}
                  minRows={5}
                  className="findings__enter"
                  placeholder="Add an optional message"
                  value={reviewComment}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </ActionModal>
        )
      }
      {
        /** Unmount when not showing so buttons aren't still clickable */ showCancelReviewConfirm && (
          <ActionModal
            closeText={'Cancel'}
            onClose={() => closeCancelReview()}
            confirmText={'Confirm'}
            onConfirm={() =>
              cancelReviewRequest({
                study: selectedStudy ?? null,
                afterCancel: async () => {
                  if (selectedStudy != null) {
                    const response = await UseFetchStudyById(selectedStudy.study_id);
                    dispatch(studyActions.setCurrentStudy(response));
                  }
                },
              })
            }
            visible={showCancelReviewConfirm}
            headerContent={<>Cancel Review Request</>}
          >
            <p>Please confirm you wish to cancel this review request.</p>
          </ActionModal>
        )
      }
    </div>
  );
};
