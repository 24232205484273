import { throttle } from 'lodash';
import React from 'react';
import isEqual from 'lodash/isEqual';
import * as PIXI from 'pixi.js-legacy';
import vtkInteractorStyleRotatableMPRCrosshairs, { operations } from './vtkInteractorStyleRotatableMPRCrosshairs';
import { uuidv4 } from '../../../../utils/shared';
import { Centerline } from './Centerline';
import { Crosshairs } from './Crosshairs';
import { InteractionOperations } from './Manipulators/customBase';
// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import 'vtk.js/Sources/Rendering/Profiles/Volume';
import { setWindowLevels, worldToDisplay, displayToWorld } from './Utils';
import { getViewUpAxis } from '../../ContrastViewer/Utils';
import { vec2, vec3 } from 'gl-matrix';
import { WindowLevels } from '../../../../reducers/window/types';
import {
  vtkApi,
  vtkVolume,
  vtkRenderer,
  vtkRenderWindow,
  vtkGenericRenderWindow,
  vtkInteractorStyle,
  vtkWidgetManager,
  ReferenceLine,
} from '../ReactVTKJSTypes';
import { ContrastViewType, BlendMode } from '../../../../context/contrast-types';

// The minimum value for render thickness we will pass to the renderer.
const minRenderThickness = 0.05;

/**
 * Axial = 0
 * Sagittal = 1
 * Coronal = 2
 */
function getDefaultCrosshairWorldAxes(): vec3[] {
  return [
    [0, 0, 1],
    [1, 0, 0],
    [0, 1, 0],
  ];
}

export interface VtkContrastViewProps {
  // The positional index of this view in the layout of views.
  viewIndex: number;
  // The default orientation of this view: 0 = Axial, 1 = Sagittal, 2 = Coronal.
  viewType: ContrastViewType;
  // The array of view apis.
  apis: vtkApi[];
  // The vtkVolume object.
  volume?: vtkVolume;
  // The world position of the crosshairs center [X, Y, Z].
  crosshairWorldPosition: vec3;
  // The world orientation of the three axes as unit vectors [][X, Y, Z].
  crosshairWorldAxes: vec3[];
  // Link the crosshairs position across series?
  linkCrosshairs?: boolean;
  // The last world position of the crosshairs center [X, Y, Z] (as a useRef reference) (used to propagate the position across crosshairs).
  lastCrosshairWorldPositionRef?: React.MutableRefObject<vec3>;
  // The width of each scan slice (in mm), this is only used when rendering the centerline.
  scanThickness: number;
  // The distance through which the ray should be cast when rendering (in mm).
  // This is in addition to the thickness of the actual scan.
  renderThickness: number;
  // The type of projection to use when rendering the volume:
  // 0 Composite
  // 1 MIP
  // 2 MinIP
  // 3 AvgIP
  blendMode: BlendMode;
  // The current { windowWidth: number, windowCenter: number }
  windowLevels: WindowLevels;
  // The array centerline points [][X, Y, Z].
  centerline?: vec3[];
  // Callback to inform the ContrastView when the zoom (scale) has changed.
  onZoom: () => void;
  // Optional override for the default [Axial Colour, Sagittal Colour, Coronal Colour] colours.
  axisColours?: [string, string, string];
  // Optional distance away from the crosshairs center to place the rotation handles
  // (as a proportion of the minimum view dimension) - this defaults to 3/8.
  rotateHandleDistance: number;
  // Show the crosshairs on the view? (NOTE: This is required for many interactions to occur)
  showCrosshairs: boolean;
  // Show the centerline on the view?
  showCenterline: boolean;
  // Callback for when the crosshairs have been moved on this view.
  // (viewIndex: number) => void
  onCrosshairsMoved: () => void;
  // Callback for when the window levels have been changed on this view:
  // (viewIndex: number, windowLevels: { windowWidth: number, windowCenter: number }) => void
  onWindowLevelsChanged: (windowLevels: WindowLevels) => void;
  // Callback for when the button was double clicked on this view:
  // (button: number, viewIndex: number) => void
  onDoubleClick: (button: number) => void;
  // Callback to force the ContrastView to render: () => void.
  onForceRender: () => void;
  // Callback to inform the ContrastView after PIXI is created and before it is destroyed.
  onPIXIChanged: (params: {
    container: PIXI.Container | undefined;
    holder: HTMLDivElement | undefined;
    app: PIXI.Application | undefined;
    millimeterSpacing: number;
  }) => void;
  // Is the user currently making measurements? If so we disable certain functionality.
  isMeasurementMode: boolean;
}

export class VtkContrastView extends React.Component<VtkContrastViewProps> {
  static defaultProps = {
    // Default scan thickness.
    scanThickness: 0.0,
    // Default render thickness.
    renderThickness: 0.0,
    // Default to MIP (maximum intensity projection).
    blendMode: 1,
    // Default the windowLevels to 'let vtk decide'.
    windowLevels: undefined,
    // Default to showing the crosshairs.
    showCrosshairs: true,
    // Default to not showing the centerline.
    showCenterline: true,
  };

  genericRenderWindow?: vtkGenericRenderWindow;
  widgetManager: any;
  container: React.RefObject<HTMLDivElement>;
  containerDims: { width: number; height: number };
  centerlineRef: React.MutableRefObject<Centerline | null>;
  crosshairsRef: React.MutableRefObject<Crosshairs | null>;
  pendingRefreshView: boolean;
  pendingRefreshCrosshairs: boolean;
  state: { containerClassName: string };
  app?: PIXI.Application;
  containerPIXI?: PIXI.Container;
  initialHolderHeight: number;
  vtkError: string | undefined;
  lostContext: boolean;
  renderer: vtkRenderer | undefined;
  renderWindow: vtkRenderWindow | undefined;

  constructor(props: VtkContrastViewProps) {
    super(props);

    this.genericRenderWindow = undefined;
    this.widgetManager = vtkWidgetManager.newInstance();
    this.widgetManager.disablePicking();
    this.container = React.createRef();
    // We remember the width and height of the last render so we know if we need to resize the buffer when we render next.
    this.containerDims = { width: 0, height: 0 };
    this.centerlineRef = React.createRef();
    this.crosshairsRef = React.createRef();

    // This is used to ensure the view and crosshairs render is synced in the throttle function.
    this.pendingRefreshView = false;
    this.pendingRefreshCrosshairs = false;
    this.state = {
      containerClassName: 'contrast-view-default',
    };
    // The PIXI app and container used by the measurment tools.
    this.app = undefined;
    this.containerPIXI = undefined;
    // The height of the view for which the PIXI application was created.
    this.initialHolderHeight = 0;
    // A string defining the current error preventing vtk from drawing a vaild view, or undefined if there is no error.
    this.vtkError = undefined;
    // True if the WebGL context is currently lost, false otherwise.
    this.lostContext = false;
  }

  /**
   * Get the height of the view.
   */
  getHolderHeight = () => {
    if (this.container.current != null) {
      const parent = this.container.current.parentElement;
      if (parent) {
        return parent.clientHeight;
      }
    }
    return 1;
  };

  /**
   * Reset the crosshair position and orientation.
   */
  resetCrosshairs = () => {
    if (this.props.volume != null) {
      const volume = this.props.volume;
      const { crosshairWorldPosition, crosshairWorldAxes, lastCrosshairWorldPositionRef, linkCrosshairs } = this.props;

      // Move the crosshairs to the current linked position?
      if (linkCrosshairs && lastCrosshairWorldPositionRef?.current) {
        crosshairWorldPosition[0] = lastCrosshairWorldPositionRef.current[0];
        crosshairWorldPosition[1] = lastCrosshairWorldPositionRef.current[1];
        crosshairWorldPosition[2] = lastCrosshairWorldPositionRef.current[2];
      }
      // Otherwise move them to the center of the volume.
      else {
        // Get the volume extends along each axis.
        const volumeXRange = volume.getXRange();
        const volumeYRange = volume.getYRange();
        const volumeZRange = volume.getZRange();

        // Manually reset the camera position and orientation.
        const volumeCenter = [
          (volumeXRange[0] + volumeXRange[1]) / 2,
          (volumeYRange[0] + volumeYRange[1]) / 2,
          (volumeZRange[0] + volumeZRange[1]) / 2,
        ];

        // Update the crosshair position (by mutation).
        crosshairWorldPosition[0] = volumeCenter[0];
        crosshairWorldPosition[1] = volumeCenter[1];
        crosshairWorldPosition[2] = volumeCenter[2];
      }

      // Update the current linked position.
      if (lastCrosshairWorldPositionRef) {
        lastCrosshairWorldPositionRef.current = [...crosshairWorldPosition] as vec3;
      }

      // Update the crosshair axes (by mutation).
      crosshairWorldAxes[0] = getDefaultCrosshairWorldAxes()[0];
      crosshairWorldAxes[1] = getDefaultCrosshairWorldAxes()[1];
      crosshairWorldAxes[2] = getDefaultCrosshairWorldAxes()[2];
    }
  };

  /**
   * Reset the camera position, focal point, viewUp, parallelScale and the istyle renderThickness.
   *
   * The camera parallel projection scale is set so that the volume fits snugly inside (or around) the view.
   * @param cropToFit If true the 'left and right' or 'top and bottom' of the volume will be cropped as required
   * to fit it perfectly over the view with no empty space showing. If false the 'left and right' or 'top and bottom'
   * of the view will have empty space so that the volume fits perfectly inside the view.
   */
  resetView = (cropToFit = false) => {
    if (this.props.volume == null || this.genericRenderWindow == null || this.renderer == null) return;
    const { crosshairWorldPosition, crosshairWorldAxes } = this.props;
    // Get the volume, camera, openGLRenderWindow, and interactor style (istyle).
    const camera = this.renderer.getActiveCamera();
    const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();
    const viewType = this.props.viewType;

    // Calculate the correct camera direction and orientation based on the current crosshairs alignment.
    const viewDirection = crosshairWorldAxes[viewType];
    const viewUpIndex = getViewUpAxis(viewType);
    // Make sure we make a copy of the axis before modifying it.
    const viewUp = [...crosshairWorldAxes[Math.abs(viewUpIndex)]];
    if (viewUpIndex < 0) {
      viewUp[0] *= -1;
      viewUp[1] *= -1;
      viewUp[2] *= -1;
    }

    const offset = -500;
    const cameraPos = [
      crosshairWorldPosition[0] + offset * viewDirection[0],
      crosshairWorldPosition[1] + offset * viewDirection[1],
      crosshairWorldPosition[2] + offset * viewDirection[2],
    ];
    camera.setPosition(cameraPos[0], cameraPos[1], cameraPos[2]);
    camera.setFocalPoint(crosshairWorldPosition[0], crosshairWorldPosition[1], crosshairWorldPosition[2]);
    camera.setViewUp(viewUp[0], viewUp[1], viewUp[2]);

    // Get the volume extends along each axis.
    const volumeXRange = this.props.volume.getXRange();
    const volumeYRange = this.props.volume.getYRange();
    const volumeZRange = this.props.volume.getZRange();

    // Get the volume size that will be aligned with the view's X and Y and it's aspect ratio.
    var volumeSize = [1, 1];
    switch (viewType) {
      case 0:
        volumeSize = [Math.abs(volumeXRange[1] - volumeXRange[0]), Math.abs(volumeYRange[1] - volumeYRange[0])];
        break;
      case 1:
        volumeSize = [Math.abs(volumeXRange[1] - volumeXRange[0]), Math.abs(volumeZRange[1] - volumeZRange[0])];
        break;
      case 2:
        volumeSize = [Math.abs(volumeYRange[1] - volumeYRange[0]), Math.abs(volumeZRange[1] - volumeZRange[0])];
        break;
      default:
        break;
    }
    const volumeAspectRatio = volumeSize[0] / volumeSize[1];

    // Get the openGL render size (this is also the view size) and aspect ratio.
    const openGLRenderSize = openGLRenderWindow.getSize();
    const openGLAspectRatio = openGLRenderSize[0] / openGLRenderSize[1];
    let renderHeight = 1;
    // Crop the 'top and bottom' or 'left and right' of the volume to fit it perfectly over the entire view?
    if (cropToFit) {
      // Crop 'left and right' because the volume's aspect ratio is wider than the window's.
      if (volumeAspectRatio >= openGLAspectRatio) {
        renderHeight = volumeSize[1];
      }
      // Crop 'top and bottom' because the volume's aspect ratio is taller than the window's.
      else {
        renderHeight = volumeSize[0] / openGLAspectRatio;
      }
    }
    // Pad around the 'top and bottom' or 'left and right' of the volume to fit it perfectly inside the entire view?
    else {
      // Pad the 'top and bottom' because the volume's aspect ratio is wider than the window's.
      if (volumeAspectRatio >= openGLAspectRatio) {
        renderHeight = volumeSize[0] / openGLAspectRatio;
      }
      // Pad the 'left and right' because the volume's aspect ratio is taller than the window's.
      else {
        renderHeight = volumeSize[1];
      }
    }

    // If we haven't resized the view the correct pixi scale is 1 (as it was when we created the container).
    // If we have resized the view then the correct scale is the difference in the view height now compared to when we created the PIXI application.
    if (this.containerPIXI) {
      const holderHeight = this.getHolderHeight();
      const scale = holderHeight / this.initialHolderHeight;
      this.containerPIXI.scale.set(scale, scale);
    }

    // Set the parallel projection scale.
    camera.setParallelScale(renderHeight / 2);

    // Set the model.renderThickness and call camera.setThicknessFromFocalPoint(renderThickness).
    this.setRenderThickness(this.props.renderThickness);

    // Refresh the view and the crosshairs.
    this.refreshViewImmediately(true, true);
  };

  webGLContextLost = (event: Event) => {
    event.preventDefault();
    this.vtkError = 'vtk error: context lost';
    this.lostContext = true;
    // Cleanup and try to reinitialize.
    this.cleanUp();
    this.initialise();
    // Force the ContrastView to render so we can show the error state if required.
    if (this.props.onForceRender) this.props.onForceRender();
  };

  webGLContextRestored = (event: Event) => {
    this.vtkError = undefined;
    this.lostContext = false;
  };

  initialise = () => {
    // Tracking ID to tie emitted events to this component
    const uid = uuidv4();

    try {
      this.genericRenderWindow = vtkGenericRenderWindow.newInstance({
        background: [0, 0, 0],
        // Disable the default window resize listener as we need to handle this ourselves to:
        // A) Ensure the SVG crosshairs are resized with the view buffer.
        // B) Ensure the correct BlendMode is set before the resize() function calls render().
        listenWindowResize: false,
      });
    } catch (error) {
      this.vtkError = 'vtk error: failed to create render window';
      return;
    }

    // Abort immediately if the genericRenderWindow creation failed.
    if (this.genericRenderWindow == null) {
      this.vtkError = 'vtk error: failed to create render window';
      return;
    }

    if (this.container.current) {
      this.genericRenderWindow.setContainer(this.container.current);
    }
    this.renderer = this.genericRenderWindow.getRenderer();
    if (this.renderer == null) {
      this.vtkError = 'vtk error: failed to create renderer';
      return;
    }
    this.renderWindow = this.genericRenderWindow.getRenderWindow();
    const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();
    // update view node tree so that vtkOpenGLHardwareSelector can access
    // the vtkOpenGLRenderer instance.
    openGLRenderWindow.buildPass(true);

    // Create the interactor style.
    const istyle = vtkInteractorStyleRotatableMPRCrosshairs.newInstance();
    istyle.setOnInteractionOperation(this.onInteractionOperation);

    /**
     * Note: The contents of this Object are
     * considered part of the API contract
     * we make with consumers of this component.
     */
    const api: vtkApi = {
      uid, // Tracking id available on `api`
      volume: this.props.volume,
      genericRenderWindow: this.genericRenderWindow,
      container: this.container.current,
      // Get the current error message (or undefined if everything is running as expected).
      getError: this.getError,
      getCameraDirection: this.getCameraDirection,
      resetCrosshairs: this.resetCrosshairs,
      resetView: this.resetView,
      refreshView: this.refreshView,
      refreshViewImmediately: this.refreshViewImmediately,
      setApiIndex: istyle.setApiIndex,
      // Get the viewIndex out of the istyle configuration.
      getViewIndex: istyle.getViewIndex,
      // Get the viewType out of the istyle configuration.
      getViewType: istyle.getViewType,
      // Convert a 2D display coordinate to a 3D world coordinate.
      displayToWorld: (pos: vec2) => displayToWorld(this.renderer!, pos),
      // Convert a 3D world coordinate to a 2D display coordinate.
      worldToDisplay: (pos: vec3) => worldToDisplay(this.renderer!, pos),
      crosshairWorldPosition: this.props.crosshairWorldPosition,
      crosshairWorldAxes: this.props.crosshairWorldAxes,
      disabled: false,
      type: 'VtkContrastView',
    };

    // Add the api to the apis array and remember its position.
    const apiIndex = this.props.apis.length;
    this.props.apis[apiIndex] = api;

    // Set the interactor style.
    this.setInteractorStyle({
      istyle,
      configuration: {
        apis: this.props.apis,
        apiIndex,
        viewIndex: this.props.viewIndex,
        viewType: this.props.viewType,
      },
    });

    // Set the initial callbacks.
    this.updateCallbacks();

    // Set the desired refresh rate for the interactor to 30fps.
    const interactor = api.genericRenderWindow.getInteractor() as any;
    if (interactor != null && interactor.setDesiredUpdateRate != null) {
      interactor.setDesiredUpdateRate(30.0);
    }

    // Add the new volume.
    if (this.props.volume) {
      // Set the initial windowLevels.
      setWindowLevels(this.props.windowLevels, this.props.volume);
      this.renderer.addVolume(this.props.volume);
    }
    istyle.setVolumeActor(this.props.volume);

    this.renderer.getActiveCamera().setParallelProjection(true);
    this.renderer.resetCamera();

    // If the document is resized we need to prod vtk to resize its WebGL buffers and SVG overlay scale.
    window.addEventListener('resize', this.resizeView);

    // Only initialize the crosshair axes once, we know we need to if this is the first api added to the apis array.
    if (apiIndex === 0) {
      this.resetCrosshairs();
    }

    // Ensure the openGLRenderWindow has calculated its buffer size before calling resetView.
    this.genericRenderWindow.resize();

    // If we have got here we can clear the lost context and error flags.
    this.vtkError = undefined;
    this.lostContext = false;

    // Setup the camera position, focal point, slab thickness etc. Ensure the volume is 'zoomed to fit' the viewport.
    this.resetView();

    const canvas = this.getCanvas();
    if (canvas != null) {
      canvas.addEventListener('webglcontextlost', this.webGLContextLost);
      canvas.addEventListener('webglcontextrestored', this.webGLContextRestored);
    }

    // Init PIXI renderer.
    if (this.container.current && this.props.onPIXIChanged) {
      const dims = this.container.current.getBoundingClientRect();
      const resolution = window.devicePixelRatio || 1;
      PIXI.utils.skipHello();
      this.app = new PIXI.Application({
        width: dims.width,
        height: dims.height,
        backgroundColor: 0x000000,
        backgroundAlpha: 0, // Fully transparent background.
        preserveDrawingBuffer: true, // this allows for screenshots but could slow the renderer down slightly.
        resizeTo: this.container.current,
        antialias: true,
        forceCanvas: true,
        // By default PIXI will create the buffer too small on some monitors. We need to have autoDensity set to true and the resolution set to the window devicePixelRatio to get 1:1 pixels.
        autoDensity: true,
        resolution,
      });
      this.container.current.appendChild(this.app.view);
      this.app.view.style.position = 'absolute';
      this.app.view.style.top = '0px';

      // Create the PIXI container that will hold all measurements.
      this.containerPIXI = new PIXI.Container();
      this.containerPIXI.interactive = true;
      this.app.stage.addChild(this.containerPIXI);

      this.initialHolderHeight = this.getHolderHeight();
      if (this.containerPIXI && this.app && this.app.screen) {
        const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();
        const openGLRenderSize = openGLRenderWindow.getSize();
        // Get the current camera scale (this is the world distance rendered over the height of the viewport).
        const cameraScale = this.renderer.getActiveCamera().getParallelScale();
        // Calculate the mm spacing based on the world distance over the height of the viewport and the height of the viewport in pixels.
        const millimeterSpacing = (2.0 * cameraScale * resolution) / openGLRenderSize[1];
        this.centerContainerPIXI();

        // Notify the parent that we have a new PIXI container.
        if (this.container.current) {
          this.props.onPIXIChanged({
            app: this.app,
            container: this.containerPIXI,
            holder: this.container.current,
            millimeterSpacing,
          });
        }
      }
    }
  };

  componentDidMount() {
    this.initialise();
  }

  /**
   * Get the current error message (or undefined if everything is running as expected).
   * @return string | undefined
   */
  getError = () => {
    if (this.vtkError) return this.vtkError;
    if (this.lostContext) return 'vtk error: context currently lost';
    // Get the shader cache from the OpenGL render window.
    const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
    const shaderCache = (openGLRenderWindow as any)?.getShaderCache?.();
    // The last bound shader is the one that rendered (or tried to render) the volume.
    const lastShaderBound = shaderCache?.getLastShaderBound?.();
    if (lastShaderBound?.getCompiled) {
      // Check the shader compiled (we have a patch on vtk_js that stops if from crashing if this has happened.
      if (!lastShaderBound.getCompiled()) {
        return 'vtk error: Shader not compiled';
      }
    } else {
      // NOTE: We need to actually mount the VtkContrastView before the shader will compile and bind, so the first pass will always come though here.
      // This could in theory result in a one frame flicker of the GPU warning message but it hasn't done so so far.
      return 'vtk error: No shader bound';
    }
    return undefined;
  };

  getViewUp = () => {
    if (this.renderer == null) {
      console.warn('VtkContrastView getViewUp() error');
      return [0, 1, 0];
    }
    const camera = this.renderer.getActiveCamera();
    return camera.getViewUp();
  };

  /**
   * Get the vector for the camera direction of projection [X, Y, Z].
   */
  getCameraDirection = (): vec3 => {
    if (this.renderer == null) {
      console.warn('VtkContrastView getCameraDirection() error');
      return [0, 0, 1];
    }
    const camera = this.renderer.getActiveCamera();
    return camera.getDirectionOfProjection();
  };

  /**
   * Redraw the view (ie vtk volume) and / or the crosshairs widget.
   * @param refreshView TRUE to redraw the vtk volume.
   * @param refreshCrosshairs TRUE to redraw the SVG crosshairs.
   */
  refreshViewImmediately = (refreshView: boolean, refreshCrosshairs: boolean) => {
    // Don't try to refresh the view if the context has been lost.
    if (this.lostContext || this.renderer == null) {
      return;
    }

    this.pendingRefreshView = false;
    this.pendingRefreshCrosshairs = false;
    // Check if the component has resized.
    let resizeBuffers = false;
    let deltaScale = 1.0;
    if (this.container.current) {
      const dims = this.container.current.getBoundingClientRect();
      if (dims.width !== this.containerDims.width || dims.height !== this.containerDims.height) {
        if (this.containerDims.height > 0) {
          deltaScale = dims.height / this.containerDims.height;
        }
        this.containerDims.width = dims.width;
        this.containerDims.height = dims.height;
        resizeBuffers = true;
        // Resize the renderer for measurement tools
        this.app && this.app.resize && this.app.resize();
      }
    }

    // Resize VTK's WebGL buffers?
    if (resizeBuffers) {
      // Force the BlendMode to be the one we want for this view prior to refreshing the view.
      const mapper = this.props.volume?.getMapper?.();
      if (mapper && mapper.getBlendMode() !== this.props.blendMode) {
        mapper.setBlendMode(this.props.blendMode);
      }
      // NOTE: This internally calls render for us, so we don't need to call it below even if refreshView is true.
      try {
        if (this.genericRenderWindow != null) {
          this.genericRenderWindow.resize();
        }
      } catch (error) {
        this.vtkError = 'vtk error: resize window failed';
      }

      // The PIXI application will automatically resize its canvas.
      // The PIXI container dimensions don't need to be touched as it's not a texture but just a collection
      // of things with a transform to apply to them. So here we just need to adjust the container scale by
      // the change in view scale.
      if (this.containerPIXI) {
        const scale = this.containerPIXI.scale.x * deltaScale;
        this.containerPIXI.scale.set(scale, scale);
      }
    }
    // Otherwise refresh the view?
    else if (refreshView) {
      // Force the BlendMode to be the one we want for this view prior to refreshing the view.
      const mapper = this.props.volume?.getMapper?.();
      if (mapper && mapper.getBlendMode() !== this.props.blendMode) {
        mapper.setBlendMode(this.props.blendMode);
      }
      try {
        if (this.genericRenderWindow != null) {
          this.genericRenderWindow.getRenderWindow().render();
        }
      } catch (error) {
        this.vtkError = 'vtk error: render window failed';
      }
    }

    // Update the scale on the ContrastView.
    if (this.props.onZoom) this.props.onZoom();

    // Refresh the centerline?
    if (resizeBuffers || refreshView) {
      // Refresh the centerline rendered on the view.
      const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
      if (this.centerlineRef.current && this.centerlineRef.current.refresh && openGLRenderWindow) {
        // Get the size of the VtkContrastView in pixels.
        const [width, height] = openGLRenderWindow.getSize();
        // Refresh the centerline.
        this.centerlineRef.current.refresh(
          width,
          height,
          this.renderer,
          this.props.scanThickness + this.props.renderThickness
        );
      }
    }

    // And finally: refresh the crosshairs?
    if (refreshCrosshairs) {
      // Refresh the crosshairs rendered on the view.
      const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
      if (this.crosshairsRef.current && this.crosshairsRef.current.refresh && openGLRenderWindow) {
        // Get the size of the VtkContrastView in pixels.
        const [width, height] = openGLRenderWindow.getSize();
        // Refresh the crosshairs.
        const istyle = this.genericRenderWindow?.getInteractor()?.getInteractorStyle();
        const apiIndex = istyle?.getApiIndex();
        if (this.props.apis && apiIndex !== undefined) {
          this.crosshairsRef.current.refresh(width, height, this.props.apis[apiIndex], this.props.viewType);
        }
      }
      this.centerContainerPIXI();
    }
  };

  /**
   * Center containerPIXI on the view.
   */
  centerContainerPIXI = () => {
    const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
    if (this.containerPIXI == null || openGLRenderWindow == null) return;

    // Convert worldPos into display coordinates [X, Y].
    const istyle = this.genericRenderWindow?.getInteractor()?.getInteractorStyle();
    const apiIndex = istyle?.getApiIndex();
    const api = this.props.apis[apiIndex];
    const worldPos = this.props.crosshairWorldPosition;
    const point = worldToDisplay(api.genericRenderWindow.getRenderer(), worldPos);
    const size = openGLRenderWindow.getSize();
    const height = size[1] || 0;
    if (this.containerPIXI) {
      const center = [point[0], height - point[1]];
      this.containerPIXI.position.set(center[0] / window.devicePixelRatio, center[1] / window.devicePixelRatio);
    }
  };

  /**
   * Throttled function to refresh the view (ie render the volume).
   */
  throttledRefreshView = throttle(
    () => this.refreshViewImmediately(this.pendingRefreshView, this.pendingRefreshCrosshairs),
    1000 / 30, // Aim for roughly 30fps, we can change this statically or dynamically if 30fps is too optimistic or not optimistic enough.
    { leading: false, trailing: true }
  );

  /**
   * Redraw the view (ie vtk volume) and / or the crosshairs widget.
   * @param refreshView TRUE to redraw the vtk volume.
   * @param refreshCrosshairs TRUE to redraw the SVG crosshairs.
   */
  refreshView = (refreshView: boolean, refreshCrosshairs: boolean) => {
    // Update the things we want to refresh in the throttledRefreshView call.
    this.pendingRefreshView = this.pendingRefreshView || refreshView;
    this.pendingRefreshCrosshairs = this.pendingRefreshCrosshairs || refreshCrosshairs;

    // Request the refresh.
    // This used to just call throttledRefreshView but with the measurement tool handling its own drawing we need
    // to be able to make these calls happen instantly when in measurement mode. We throttled because the draw
    // calls can build up on each other and bog everything down. At least then measuring we are generally only
    // modifying one view at a time as rotate and slice change are disabled.
    if (this.props.isMeasurementMode) {
      this.refreshViewImmediately(this.pendingRefreshView, this.pendingRefreshCrosshairs);
    } else {
      this.throttledRefreshView();
    }
  };

  /**
   * Resize vtk's WebGL buffer and the SVG overlay scale to match the current component size.
   */
  resizeView = () => {
    // Refresh the view, crosshairs and update the buffer sizes.
    // NOTE: refreshView is already throttled.
    this.refreshView(true, true);
  };

  setInteractorStyle = (params: {
    istyle: vtkInteractorStyle;
    configuration: {
      apis: vtkApi[];
      apiIndex: number;
      viewIndex: number;
      viewType: number;
    };
  }) => {
    const renderWindow = this.genericRenderWindow?.getRenderWindow();
    if (renderWindow == null) {
      console.warn('VtkContrastView setInteractorStyle error');
      return;
    }

    const interactor = renderWindow.getInteractor();
    interactor.setInteractorStyle(params.istyle);
    params.istyle.setInteractor(interactor);
    if (params.istyle.getVolumeActor() !== this.props.volume) {
      params.istyle.setVolumeActor(this.props.volume);
    }

    // Set Configuration
    if (params.configuration) {
      params.istyle.set(params.configuration);
    }
  };

  onCrosshairsMoved = () => {
    const { lastCrosshairWorldPositionRef, crosshairWorldPosition, onCrosshairsMoved } = this.props;
    // Update the current linked position.
    if (lastCrosshairWorldPositionRef) {
      lastCrosshairWorldPositionRef.current = [...crosshairWorldPosition] as vec3;
    }
    if (onCrosshairsMoved) {
      onCrosshairsMoved();
    }
  };

  /**
   * Update the callbacks in case any of them changed.
   */
  updateCallbacks() {
    const istyle = this.genericRenderWindow?.getInteractor()?.getInteractorStyle();
    if (istyle) {
      istyle.setOnCrosshairsMoved(this.onCrosshairsMoved);
      istyle.setOnWindowLevelsChanged(this.props.onWindowLevelsChanged);
      istyle.setOnDoubleClick(this.props.onDoubleClick);
      istyle.setMeasurementToggleActive(this.props.isMeasurementMode);
      istyle.setContainerPIXI(this.containerPIXI);
    }
  }

  /**
   * Call this whenever the interactionOperation or operation changes on the istyle.
   */
  onInteractionOperation = (interactionOperation: number, operation: any) => {
    let containerClassName = '';
    let hover = false;
    let hoverRotate = false;
    const istyle = this.genericRenderWindow?.getInteractor()?.getInteractorStyle();
    const apiIndex = istyle?.getApiIndex();
    if (this.props.apis && apiIndex !== undefined) {
      const api = this.props.apis[apiIndex];
      // Ensure all the crosshair controls on the other line are shown as inactive.
      const lines = api.crosshairs?.referenceLines;
      lines?.forEach((line: ReferenceLine) => {
        // The mouse is over the line?
        if (line.selected) hover = true;
        // The mouse is over the rotate handle?
        if (line.rotateHandles.selected) hoverRotate = true;
      });
    }
    switch (interactionOperation) {
      case InteractionOperations.MOVE_CROSSHAIRS:
        if (!this.props.isMeasurementMode) {
          if (operation.type === operations.ROTATE_CROSSHAIRS) {
            containerClassName = 'contrast-view-grab';
          } else if (operation.type === operations.MOVE_CROSSHAIRS) {
            containerClassName = 'contrast-view-none';
          } else {
            containerClassName = 'contrast-view-dragging';
          }
        }
        break;
      case InteractionOperations.PAN:
        containerClassName = 'contrast-view-dragging';
        break;
      case InteractionOperations.ZOOM:
        containerClassName = 'contrast-view-sliding-ns';
        break;
      case InteractionOperations.SLICE:
      case InteractionOperations.SCROLL_SLICE:
        if (!this.props.isMeasurementMode) {
          containerClassName = 'contrast-view-sliding-ns';
        }
        break;
      case InteractionOperations.WINDOW_LEVEL:
      default:
        if (!this.props.isMeasurementMode) {
          if (hoverRotate) {
            containerClassName = 'contrast-view-grab';
          } else if (hover) {
            containerClassName = 'contrast-view-dragging';
          } else {
            containerClassName = 'contrast-view-default';
          }
        }
        break;
    }

    // This is used to remove the focus from the mipValue input
    const elements = document.querySelectorAll('.contrast-view-header__input');
    elements.forEach((elem: any) => {
      if (elem.blur) {
        elem.blur();
      }
    });

    // Only call setState if the containerClassName actually changed.
    if (containerClassName !== this.state.containerClassName) {
      this.setState({ containerClassName });
    }
  };

  /**
   * Set the thickness of the volume the slice render looks through.
   */
  setRenderThickness = (renderThickness: number) => {
    if (this.renderer == null) return;
    this.renderer.getActiveCamera().setThicknessFromFocalPoint(Math.max(renderThickness, minRenderThickness));
  };

  componentDidUpdate(prevProps: VtkContrastViewProps) {
    // If the WebGL context is abort the update.
    if (this.lostContext || this.renderer == null) {
      return;
    }

    let refreshView = false;
    let refreshCrosshairs = false;

    // Clear the centerlineRef and crosshairsRef if these components aren't shown.
    if (prevProps.showCenterline !== this.props.showCenterline) {
      if (!this.props.showCenterline) {
        this.centerlineRef.current = null;
      }
      // NOTE: The centerline is associated with the view vs the crosshairs.
      refreshView = true;
    }
    if (prevProps.showCrosshairs !== this.props.showCrosshairs) {
      if (!this.props.showCrosshairs) {
        this.crosshairsRef.current = null;
      }
      refreshCrosshairs = true;
    }

    // Update the callbacks in case any of them changed.
    this.updateCallbacks();

    // Update the volume added to the renderer if the volume has changed.
    if (prevProps.volume !== this.props.volume) {
      // Remove all the old volume.
      if (prevProps.volume != null) {
        this.renderer.removeVolume(prevProps.volume);
      }

      // Add the new volume.
      if (this.props.volume != null) {
        // Check the new volume is actually a volume.
        if (!this.props.volume.isA('vtkVolume')) {
          console.warn('Data to <Vtk2D> is not vtkVolume data');
        }

        // Set the initial windowLevels.
        setWindowLevels(this.props.windowLevels, this.props.volume);

        // Add the new volume.
        this.renderer.addVolume(this.props.volume);

        // Resize the buffers and render the view and crosshairs.
        refreshView = true;
        refreshCrosshairs = true;
      }
    } else {
      // Update the render thickness?
      if (prevProps.renderThickness !== this.props.renderThickness) {
        this.setRenderThickness(this.props.renderThickness);
        refreshView = true;
      }

      // Refresh the view if the blend mode was changed.
      if (prevProps.blendMode !== this.props.blendMode) {
        refreshView = true;
      }

      // Refresh the view if the windowLevels were changed.
      if (!isEqual(prevProps.windowLevels, this.props.windowLevels) && this.props.volume != null) {
        // Set the new windowLevels.
        setWindowLevels(this.props.windowLevels, this.props.volume);
        refreshView = true;
      }

      // Refresh the centerline if it changed.
      if (prevProps.centerline !== this.props.centerline) {
        // Technically we just need to refresh the centerline in this case but this is neater and lets it sync with other updates.
        refreshView = true;
      }
    }

    // Refresh the view and / or crosshairs?
    if (refreshView || refreshCrosshairs) {
      this.refreshView(refreshView, refreshCrosshairs);
    }
  }

  /**
   * Helper method to get the 'hidden' canvas object.
   */
  getCanvas(): HTMLCanvasElement | undefined {
    const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow?.() as any;
    if (openGLRenderWindow != null && openGLRenderWindow.getCanvas != null) {
      return openGLRenderWindow.getCanvas();
    }
    return undefined;
  }

  /**
   * Helper method to get the 'hidden' 3dContext object.
   */
  get3dContext(): any | undefined {
    const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow?.() as any;
    if (openGLRenderWindow != null && openGLRenderWindow.get3DContext != null) {
      return openGLRenderWindow.get3DContext();
    }
    return undefined;
  }

  cleanUp = () => {
    // Stop the resize event listener.
    window.removeEventListener('resize', this.resizeView);

    // Cancel any pending renders.
    this.throttledRefreshView.cancel();

    // We need to remove this view's api from the apis array and ensure the indexes are correct.
    const apis = this.props.apis;
    const viewIndex = this.props.viewIndex;
    if (apis && viewIndex !== undefined) {
      // Remove the api from the apis array.
      for (let apiIndex = 0; apiIndex < apis.length; apiIndex++) {
        if (apis[apiIndex].getViewIndex() === viewIndex) {
          apis.splice(apiIndex, 1);
        }
      }

      // Reset the apiIndex for each api in the apis array.
      for (let apiIndex = 0; apiIndex < apis.length; apiIndex++) {
        apis[apiIndex].setApiIndex(apiIndex);
      }
    }

    // This should not be required but WebGL (on Chrome at least) seems to have bug making it not correctly release the WebGL context.
    // Although this function is only meant to simulate a lost context it actually allows the context to properly release.
    // Without this fix thrashing the views could result in a lost context (and a blank view), Interestingly, calling get3DContext()
    // seems to help trigger this issue, possibly because vtkjs seems to add event listeners in this function that are never removed.
    const this3dcontext = this.get3dContext();
    if (this3dcontext != null) {
      this3dcontext?.getExtension('WEBGL_lose_context')?.loseContext();
    }
    const canvas = this.getCanvas();
    if (canvas != null) {
      canvas.removeEventListener('webglcontextlost', this.webGLContextLost);
      canvas.removeEventListener('webglcontextrestored', this.webGLContextRestored);
    }

    // Clear the PIXI container from the istyle.
    const istyle = this.genericRenderWindow?.getInteractor()?.getInteractorStyle();
    istyle.setContainerPIXI(undefined);

    if (this.genericRenderWindow) {
      // Destroy the render window, clean up WebGL.
      this.genericRenderWindow.delete();
      this.genericRenderWindow = undefined;
    }

    // Destroy the PIXI app and container but not its children, the ContrastView will do that for us.
    if (this.app && this.containerPIXI) {
      // Notify the parent that the PIXI container is being cleaned up.
      if (this.container.current != null) {
        this.props.onPIXIChanged({
          app: undefined,
          container: undefined,
          holder: this.container.current,
          millimeterSpacing: 1,
        });
      }

      // Destroy the PIXI container.
      this.containerPIXI.destroy({
        children: false,
        baseTexture: false,
        texture: false,
      });
      this.containerPIXI = undefined;

      // Remove and destroy the PIXI application.
      if (this.container.current != null) {
        this.container.current.removeChild(this.app.view);
      }
      this.app.destroy();
      this.app = undefined;
    }
  };

  componentWillUnmount() {
    this.cleanUp();
  }

  render() {
    // Return a black panel if the volume is still loading.
    if (this.props.volume == null) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            backgroundColor: 'black',
          }}
        />
      );
    }

    // Otherwise return the view.
    return (
      <>
        <div
          ref={this.container}
          className={this.state.containerClassName}
          style={{ width: '100%', height: '100%', position: 'relative' }}
        />
        {this.props.showCenterline && this.props.centerline && (
          <Centerline ref={this.centerlineRef} centerline={this.props.centerline} />
        )}
        {this.props.showCrosshairs && (
          <Crosshairs
            ref={this.crosshairsRef}
            axisColours={this.props.axisColours}
            rotateHandleDistance={this.props.rotateHandleDistance}
          />
        )}
      </>
    );
  }
}
