import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { modelActions } from './modelSlice';
import { InitialLoader, UnifiedId } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, useNeedsLoading } from '../initialLoad/utils';
import * as api from '../../utils/api';
import * as THREE from 'three';
import { MPRAxes, VesselDataResponse } from '../vesselData/types';

const loaderName = InitialLoader.MODEL;

/**
 * Fetch the vessel axes information and convert it to a more usable array.
 * unifiedId.version is optional.
 */
function fetchMprAxes(unifiedId: Partial<UnifiedId> & Omit<UnifiedId, 'version'>, vesselId: string): Promise<MPRAxes> {
  // The MPR axes information are formatted as an 'array' but indexed by a padded string number XXXXXXXXX.
  return api
    .getJSON(
      `/data/${unifiedId.patientId}/${unifiedId.runId}/vessel/${vesselId}/mpr/axes/all${
        unifiedId.version ? '?version=' + unifiedId.version : ''
      }`
    )
    .then((axes) => {
      // Convert the result into an actual array.
      const result: MPRAxes = [];
      const axesViews = axes?.view_idx;
      for (const key in axesViews) {
        result[parseInt(key)] = axesViews[key];
      }
      return result;
    });
}

/**
 * Load the entire 3D model and save it in the store.
 */
export async function loadModel(
  unifiedId: UnifiedId,
  dispatch: AppDispatch,
  initialLoad: boolean,
  vesselData: VesselDataResponse
) {
  const vessels = Object.keys(vesselData);
  const promise = api.newPLYLoader().then((loader: any) => {
    const promises: Promise<THREE.BufferGeometry | MPRAxes>[] = [];
    // Add the aorta geometry.
    promises.push(
      loader.loadAsync(`/data/${unifiedId.patientId}/${unifiedId.runId}/aorta?version=${unifiedId.version}`)
    );
    // Add the vessel data.
    vessels.forEach((vesselId: string) => {
      // Add the geometry.
      promises.push(
        loader.loadAsync(
          `/data/${unifiedId.patientId}/${unifiedId.runId}/vessel/${vesselId}/geometry?version=${unifiedId.version}`
        )
      );
      // Add the axes.
      promises.push(fetchMprAxes(unifiedId, vesselId));
      // Add the vessel lesion geometries.
      const vesselLesionIds = vesselData[vesselId].contrast_lesion_ids || [];
      vesselLesionIds.forEach((id: string) => {
        promises.push(
          loader.loadAsync(
            `/data/${unifiedId.patientId}/${unifiedId.runId}/vessel/${vesselId}/plaque-geometries/${id}?version=${unifiedId.version}`
          )
        );
      });
    });
    return Promise.all(promises);
  });

  loadWithPromise(promise, dispatch, initialLoad, loaderName, (data: any[]) => {
    let offset = 0;
    dispatch(modelActions.setAortaGeometry(data[offset++].toJSON()));
    vessels.forEach((vesselId: string) => {
      const geometry = data[offset++].toJSON();
      const axes = data[offset++];
      // Add the vessel lesion geometries.
      const lesionGeometry: string[] = [];
      const vesselLesionIds = vesselData[vesselId].contrast_lesion_ids || [];
      vesselLesionIds.forEach(() => {
        lesionGeometry.push(data[offset++].toJSON());
      });

      dispatch(
        modelActions.setVessel({
          vesselId,
          modelVessel: { geometry, axes, lesionGeometry },
        })
      );
    });
  });
}

/**
 * Save a 3dModel screenshot on the backend.
 */
export function saveScreenshot(patientId: string, runId: string, title: string, imageData: any): Promise<any> {
  return api.postJSON(`/data/${patientId}/${runId}/report/screenshot/add`, {
    img: imageData,
    title,
  });
}

/**
 * A component to load the initial 3d model data for a study and add it to the store.
 */
export const ModelLoader: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const unifiedId = useAppSelector((state) => state.initialLoad.unifiedId);
  const vesselData = useAppSelector((state) => state.vesselData.vesselData);
  const needsLoading = useNeedsLoading(loaderName);

  useEffect(() => {
    if (unifiedId && needsLoading && vesselData) {
      loadModel(unifiedId, dispatch, true, vesselData);
    }
  }, [unifiedId, needsLoading, vesselData, dispatch]);

  return null;
};
