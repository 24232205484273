import * as api from '../utils/api';
import * as phi from '../utils/phi';
import { Study } from '../context/types';

export const UseFetchStudyById = async (studyId: string) => {
  const study = await api.getJSON(`/dashboard/${studyId}/info`);
  const decrypted = await phi.decryptDashboardItem(study);

  // Set the default AI assessed values if none were returned by the backend.
  if (!decrypted.ai_assessed) {
    decrypted.ai_assessed = {
      contrast_id: 'contrast',
      noncontrast_id: 'noncontrast',
    };
  }

  return decrypted;
};

/**
 * As per fetchStudyById but returning a Promise with the fetched study.
 */
export const UseFetchStudyByIdPromise = (studyId: string): Promise<Study> => {
  return api
    .getJSON(`/dashboard/${studyId}/info`)
    .then((study) => {
      return phi.decryptDashboardItem(study);
    })
    .then((decrypted) => {
      // Set the default AI assessed values if none were returned by the backend.
      if (!decrypted.ai_assessed) {
        decrypted.ai_assessed = {
          contrast_id: 'contrast',
          noncontrast_id: 'noncontrast',
        };
      }
      return decrypted;
    });
};
