import React from 'react';
import PropTypes from 'prop-types';

interface AlertProps {
  children: any;
}

export const Alert: React.FunctionComponent<AlertProps> = ({ children }) => {
  return <div className="alert">{children}</div>;
};

export default Alert;

Alert.propTypes = {
  children: PropTypes.node,
};
