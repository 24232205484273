import React, { ReactNode } from 'react';
import { useEffectCustomDeps } from '../hooks/useEffectCustomDeps';
import { ContrastProvider } from './contrast-context';
import { inlineReportingAction } from '../reducers/inlineReporting/inlineReportingSlice';
import { versionHeadUpdater } from '../hooks/use-version-head';
import { useAppDispatch } from '../hooks';

type Props = {
  children?: ReactNode;
};

export const GlobalContextContainer: React.FunctionComponent<Props> = (props) => {
  // Run cleanup code on unmount to ensure the next study is loaded with a fresh state.
  const dispatch = useAppDispatch();
  useEffectCustomDeps(() => {
    return () => {
      versionHeadUpdater.reset();
      dispatch(inlineReportingAction.clearInlineReportingWarningAction());
    };
  }, []);

  return <ContrastProvider>{props.children}</ContrastProvider>;
};
