import React, { useCallback } from 'react';
import { ReactComponent as ResetIcon } from '../../../assets/icons/reload.svg';
import { ContrastVolumeActions } from '../../../context/contrast-types';
import { useContrastContext } from '../../../context/contrast-context';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { cprActions } from '../../../reducers/cpr/cprSlice';
import { contrastActions } from '../../../reducers/contrast/contrastSlice';
import { windowAction } from '../../../reducers/window/windowSlice';

/**
 * Allow the user to reset the position, orientation, render thickness, blend mode, and window levels of the contrast CT volume views.
 */
export const ResetViewsButton: React.FunctionComponent = () => {
  const { dispatchContrastVolumeAction } = useContrastContext();
  const dispatch = useAppDispatch();
  const triggerResetPanAndZoom = useAppSelector((state) => state.cpr.triggerResetPanAndZoom);
  /**
   * Call this to reset views all views and all associated data immediately.
   */
  const resetViews = useCallback(() => {
    // Tell the contrast-context to reset all views.
    dispatchContrastVolumeAction({
      type: ContrastVolumeActions.RESET_VIEWS,
    });

    /* TODO: We used to use the WW/WL set in the contrast series but now just rely on the windowing behaviour of the MPR view.
    // Reset the MPRView (and ShortAxisMPRViewer, LongAxisMPRView, CPRViewer, VesselViewer etc) windowing levels
    // to the value of the currently loaded ai_assessed.contrast_id series.
    contrastVolumeMap.forEach((contrastVolume) => {
      if (
        contrastVolume.seriesName ===
        contrastVolume.study.ai_assessed.contrast_id
      ) {
        setWindowLevels(contrastVolume!.defaultWindowLevels);
      }
    });
    */
    dispatch(windowAction.resetWindowLevels());

    // trigger CPR viewer reset
    dispatch(cprActions.setTriggerResetPanAndZoom(triggerResetPanAndZoom + 1));

    // Clear the vessel sync info so the vessel will resync (if vessel sync is on).
    dispatch(contrastActions.setVesselSyncInfo(undefined));
  }, [dispatchContrastVolumeAction, dispatch, triggerResetPanAndZoom]);

  return (
    <button className="reset-views-button" onClick={resetViews} title="Reset the position and orientation of all views">
      <span className="reset-views-button__icon">
        <ResetIcon />
      </span>
    </button>
  );
};

export default ResetViewsButton;
