import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListReview, VolumeSpacing } from '../../context/types';

export type storeState = {
  versionHead: string | undefined;
  backendVersionHead: string | undefined;
  reviewList: ListReview[] | undefined;
  nonContrastSpacing: VolumeSpacing | undefined;
  modelImage: string | null;
};

export const initialStoreState = (): storeState => {
  return {
    versionHead: undefined,
    backendVersionHead: undefined,
    reviewList: undefined,
    nonContrastSpacing: undefined,
    modelImage: null,
  };
};

export const storeSlice = createSlice({
  name: 'store-slice',
  initialState: initialStoreState(),
  reducers: {
    setVersionHead: (state, action: PayloadAction<string | undefined>) => {
      state.versionHead = action.payload;
    },
    setBackendVersionHead: (state, action: PayloadAction<string | undefined>) => {
      state.backendVersionHead = action.payload;
    },
    setReviewList: (state, action: PayloadAction<ListReview[] | undefined>) => {
      state.reviewList = action.payload;
    },
    setNonContrastSpacing: (state, action: PayloadAction<VolumeSpacing | undefined>) => {
      state.nonContrastSpacing = action.payload;
    },
    // Base64 encoded screen grab of 3D Model
    setModelImage: (state, action: PayloadAction<string | null>) => {
      state.modelImage = action.payload;
    },
  },
});
export const storeActions = storeSlice.actions;
