import AWS from 'aws-sdk';
import Auth from '@aws-amplify/auth';
import { Study } from '../context/types';
import { APIError } from './api';
import { getAPIToken } from './auth';
import Moment from 'moment';

// Utils for working with PHI for studies

/**
 * URL for the PHI API service. This value is fetched after successful authentication, using the /client/details API
 * endpoint to fetch the client configuration. An undefined value indicates that the configuration hasn't been fetched
 * successfully yet. A null value indicates that no PHI API service is available and that the client should decrypt PHI
 * from the patient cipher.
 */
let phiServiceUrl: string | null | undefined = undefined;

/**
 * Set the PHI service URL.
 *
 * @param url A URL for the PHI API service, contained in the client config fetched from the API's /client/detail
 * endpoint.
 */
export function updatePhiServiceUrl(url: string | null) {
  phiServiceUrl = url;
}

function _browser_base64ToArrayBuffer(base64: string) {
  // function to convert a base64 encoded string
  // into a binary blob that AWS's KMS service
  // expects
  try {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  } catch (e) {
    console.error(e);
  }
  return null;
}

/**
 * Create a promise that resolves after the given number of milliseconds. Uses setTimeout, so the promise may not
 * resolve exactly after the given duration.
 *
 * @param duration The number of milliseconds to wait before resolving
 * @return A promise that resolves after the given number of milliseconds
 */
async function sleepMillis(duration: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}

async function decrypt(kms: AWS.KMS, cipher: string) {
  const CiphertextBlob = _browser_base64ToArrayBuffer(cipher);
  if (CiphertextBlob) {
    try {
      return await kms.decrypt({ CiphertextBlob }).promise();
    } catch (e) {
      console.error(e);
    }
  }
  return null;
}

async function decryptCipher(kms: AWS.KMS, patientCipher: any): Promise<string | null> {
  try {
    let r = await decrypt(kms, patientCipher);
    if (r && r.Plaintext) {
      return r.Plaintext.toString();
    } else {
      return null;
    }
  } catch (e) {
    console.error('Error decrypting cypher', e);
  }
  return null;
}

/**
 * Fetch PHI via the PHI API's /patient-data/<study_id> endpoint.
 *
 * @param phiServiceUrl The base URL of the PHI API service
 * @param studyId The ID of the study to fetch PHI for
 * @return A Promise resolving with the retrieved PHI as a JSON encoded string
 */
async function fetchPHI(phiServiceUrl: string, studyId: string): Promise<string> {
  const token = await getAPIToken();
  const response = await fetch(`${phiServiceUrl}/patient-data/${studyId}`, {
    method: 'GET',
    headers: {
      Authorization: `Cognito-Bearer ${token}`,
    },
  });
  const body = await response.text();

  if (response.ok) {
    return body;
  }

  throw new APIError(`Failed to fetch patient data for ${studyId}`, response, JSON.parse(body));
}

export async function checkCredentials() {
  if (!AWS.config.credentials) {
    try {
      AWS.config.credentials = await Auth.currentUserCredentials();
    } catch (e) {
      throw new Error(`Unable to get current user credentials: ${e}`);
    }
  }
}

export async function decryptDashboardItem(dashboardData: Study, canCheckCredentials = true): Promise<Study> {
  AWS.config.region = process.env.REACT_APP_AWS_REGION;

  // check if credentials have already been set
  if (canCheckCredentials) await checkCredentials();

  const canDecrypt = dashboardData['patient_cipher'] || phiServiceUrl;
  let returnData = { ...dashboardData };
  if (canDecrypt && !dashboardData['patient_plaintext']) {
    try {
      // Don't attempt decryption until we've fetched the client config from the server
      while (phiServiceUrl === undefined) {
        await sleepMillis(50);
      }

      let plaintext: string | null;

      if (phiServiceUrl) {
        plaintext = await fetchPHI(phiServiceUrl, returnData.study_id);
      } else {
        const kms = new AWS.KMS();
        plaintext = await decryptCipher(kms, returnData['patient_cipher']);
      }

      if (plaintext) {
        returnData['patient_plaintext'] = plaintext;
        const json = JSON.parse(plaintext);
        returnData['patient_name'] = json['PatientName'];
        returnData['patient_id'] = json['PatientID'];
        returnData['date_of_birth'] = json['PatientBirthDate'];
        returnData['referring_doctor'] = json['ReferringPhysicianName'];

        // Assign the scan date and study time if it exists, otherwise assigns the ingest date
        // Checks for a StudyTime value and a valid format StudyDate (YYYYMMDD).
        if (Moment(json['StudyDate'], 'YYYYMMDD').isValid() && json['StudyTime']) {
          returnData['last_scan'] = `${json['StudyDate']}${json['StudyTime']}`;
        }
      }
    } catch (err) {
      returnData['patient_name'] = returnData['study_id'];
    }
  }
  return returnData;
}

export function applyDecryptedData(decryptedData: Study, studyData: Study): Study {
  // If we already have studies decryptedData, re-apply decryptedData data instead of decrypting cypher again
  if (
    studyData['patient_cipher'] &&
    !studyData['patient_plaintext'] &&
    studyData['patient_cipher'] === decryptedData['patient_cipher']
  ) {
    studyData['patient_plaintext'] = decryptedData['patient_plaintext'];
    studyData['patient_name'] = decryptedData['patient_name'];
    studyData['patient_id'] = decryptedData['patient_id'];
    studyData['last_scan'] = decryptedData['last_scan'];
    studyData['date_of_birth'] = decryptedData['date_of_birth'];
    studyData['referring_doctor'] = decryptedData['referring_doctor'];
  }

  return studyData;
}
