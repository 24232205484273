import React, { ReactElement, useCallback, useMemo } from 'react';
import { ETHNICITIES } from '../../config';
import { CalciumStat } from '../../context/types';
import { useFetchMesaPercentile } from '../../hooks/use-get-mesa-percentile';
import { reportActions } from '../../reducers/report/reportSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Select from '../reusable/SelectNew/SelectNew';

interface Props {
  calciumStat: CalciumStat;
  editingReport: boolean;
}

export function EthnicField({ calciumStat, editingReport }: Props): ReactElement<Props> {
  const dispatch = useAppDispatch();

  const fetchingMesaPercentile = useAppSelector((state) => state.reportState.fetchingMesaPercentile);

  const fetchMesaPercentile = useFetchMesaPercentile();

  const updateEthnicity = useCallback(
    (value) => {
      dispatch(reportActions.updateDraftReport({ ethnicity: value }));

      let calciumScore: number;
      if (typeof calciumStat.value === 'number') {
        calciumScore = calciumStat.value;
      } else {
        calciumScore = parseFloat(calciumStat.value);
      }

      fetchMesaPercentile(calciumStat.sex, calciumStat.age, value, calciumScore);
    },
    [dispatch, calciumStat.value, calciumStat.sex, calciumStat.age, fetchMesaPercentile]
  );

  const ethnicities = useMemo(() => {
    // API expects ethnicity in lower case
    return ETHNICITIES.map((e) => ({ label: e, value: e.toLowerCase() }));
  }, []);

  const ethnicityStr = useMemo(() => {
    // Need to convert first letter to uppercase for display
    if (!calciumStat.ethnicity) {
      return '-';
    }

    return (
      calciumStat.ethnicity.substring(0, 1).toUpperCase() +
      calciumStat.ethnicity.substring(1, calciumStat.ethnicity.length)
    );
  }, [calciumStat.ethnicity]);

  return (
    <>
      <div className="value-list__item ethnic-field">
        <dt className="value-list__label">Ethnicity/Race</dt>
        <dd className="value-list__value">
          {editingReport && (
            <Select
              options={ethnicities}
              value={calciumStat.ethnicity}
              onChange={updateEthnicity}
              disabled={!editingReport || fetchingMesaPercentile}
            />
          )}
          {!editingReport && ethnicityStr}
        </dd>
      </div>
    </>
  );
}
