import React, { useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

interface SearchProps {
  searchStr: string;
  onChange: (value: string) => void;
}

export const Search: React.FunctionComponent<SearchProps> = ({ searchStr = '', onChange }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <div className="search">
      <SearchIcon className="search__icon" />
      <input
        className="search__input"
        type="text"
        placeholder="Search"
        value={searchStr}
        onChange={handleChange}
        ref={inputRef}
      />
    </div>
  );
};
