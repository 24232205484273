import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DraggableGroup, SelectedDraggable, DraggableType } from './types';
import {
  handleGroupDrag,
  handleSingleItemDrag,
  onSnapBackDraggableItem,
  onSnapBackGroupItem,
  sortSeries,
} from './helpers';
import { AIAssessed } from '../../context/types';

export interface DragAndDropState {
  draggableGroup: Record<string, DraggableGroup>;
}

const initialDragAndDropState = (): DragAndDropState => {
  return {
    draggableGroup: {},
  };
};

export const dragAndDropSlice = createSlice({
  name: 'dragAndDrop',
  initialState: initialDragAndDropState(),
  reducers: {
    createDraggableGroup: (
      draftState,
      action: PayloadAction<{ draggableGroups: DraggableGroup[]; ai_assessed: AIAssessed }>
    ) => {
      const draggableGroup: Record<string, DraggableGroup> = {};

      const sorted = sortSeries(action.payload.draggableGroups, action.payload.ai_assessed);

      sorted.forEach((draggable) => {
        draggableGroup[draggable.parent.id] = draggable;
      });

      draftState.draggableGroup = draggableGroup;
    },

    dragging: (draftState, action: PayloadAction<SelectedDraggable>) => {
      const selected = action.payload;
      if (selected.itemType === DraggableType.PARENT) {
        const { id } = selected;

        draftState.draggableGroup = handleGroupDrag(id, draftState);
      } else {
        const { id, childIndex } = selected;
        draftState.draggableGroup = handleSingleItemDrag(id, childIndex, draftState);
      }
    },

    onSnapBack: (draftState, action: PayloadAction<SelectedDraggable>) => {
      if (action.payload.itemType === DraggableType.PARENT) {
        const { id } = action.payload;

        draftState.draggableGroup = onSnapBackGroupItem(id, draftState);
      } else {
        const { id, childIndex } = action.payload;

        draftState.draggableGroup = onSnapBackDraggableItem(id, childIndex, draftState);
      }
    },
  },
});

export const dragAndDropActions = dragAndDropSlice.actions;
