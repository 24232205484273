import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import DropZone from '../../../components/DropZone/DropZone';
import ContrastViewHeader from '../ContrastViewHeader/ContrastViewHeader';
import { THEME } from '../../../config';
import { useAppSelector } from '../../../hooks';
import { useOnKeyDown } from '../../../hooks/useContrastViewHelpers';
interface OuterViewProps {
  // The index of the view being shown (0 to 3).
  viewIndex: number;
  overflowVisible?: boolean;
}

export const OuterView: React.FunctionComponent<OuterViewProps> = ({
  viewIndex,
  overflowVisible = false,
  children,
}) => {
  const { visibleViews } = useAppSelector((state) => state.contrast);
  const mouseOver = useRef<boolean>(false);
  const isMeasurementMode = useAppSelector((state) => state.measurementTools.isMeasurementMode);

  const onKeyDown = useOnKeyDown();
  // Dynamically set the styling for the outer view, useMemo so it is set before the render (vs useEffect which happens after).
  const style = useMemo(() => {
    let newStyle: any = {
      position: 'absolute',
      overflow: overflowVisible ? 'visible' : 'hidden',
      width: 'calc(50% - 4px)',
      height: 'calc(50% - 4px)',
      backgroundColor: THEME.colors.global.black,
    };

    switch (viewIndex) {
      case 0:
        newStyle = { ...newStyle, top: 0, left: 0 };
        break;
      case 1:
        newStyle = { ...newStyle, top: 0, right: 0 };
        break;
      case 2:
        newStyle = { ...newStyle, bottom: 0, left: 0 };
        break;
      case 3:
        newStyle = { ...newStyle, bottom: 0, right: 0 };
        break;
      default:
        break;
    }

    // Hide the view if it is not visible.
    if (!visibleViews.includes(viewIndex)) {
      newStyle = { ...newStyle, visibility: 'hidden', opacity: 0 };
    }
    // Otherwise adjust the size based on the number of views being shown.
    else if (visibleViews.length === 1) {
      newStyle = { ...newStyle, width: '100%', height: '100%' };
    } else if (visibleViews.length === 2) {
      newStyle = { ...newStyle, height: '100%' };
    }

    return newStyle;
  }, [viewIndex, visibleViews, overflowVisible]);

  /**
   * When the user presses an arrow key while over a view then adjust the slice.
   */
  const onWindowKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // disable rotation when measurement is on
      if (isMeasurementMode) return;

      if (onKeyDown && mouseOver.current) {
        onKeyDown(viewIndex, event);
      }
    },
    [onKeyDown, viewIndex, isMeasurementMode]
  );

  /**
   * Set the keydown listener.
   */
  useEffect(() => {
    window.addEventListener('keydown', onWindowKeyDown);
    return () => {
      window.removeEventListener('keydown', onWindowKeyDown);
    };
  }, [onWindowKeyDown]);

  /**
   * Update the mouseOver value.
   */
  const onMouseEnter = () => {
    mouseOver.current = true;
  };

  /**
   * Update the mouseOver value.
   */
  const onMouseLeave = () => {
    mouseOver.current = false;
  };

  return (
    <div style={style} className="contrast-viewer__view-panel">
      <DropZone viewIndex={viewIndex} />
      {/* Add the view header */}
      <div className="contrast-viewer__header">
        <ContrastViewHeader viewIndex={viewIndex} />
      </div>
      {/* Add the view content */}
      <div className="contrast-viewer__view" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
      </div>
    </div>
  );
};
