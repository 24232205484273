import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// The basic template for all WebApp responses.
interface WebAppResponse<ContextType> {
  successful: boolean;
  display_message: string;
  internal_message: string;
  content: ContextType;
}

export enum SeriesType {
  contrast = 'CONTRAST',
  nonContrast = 'NONCONTRAST',
}

// The data returned by the ct_volume endpoint.
export type CtVolumeResponse = WebAppResponse<
  {
    id: number;
    created_at: string;
    updated_at: string;
    key: string;
    series_type: SeriesType;
    customer_series_uid: string;
    dicom_date: string;
    description: string;
    thickness: number;
    wl: number;
    ww: number;
    slice_no_start: number;
    slice_no_end: number;
    presigned_slice_urls: string[];
    presigned_thumbnail_s3_path: string;
  }[]
>;

export type CtVolumeState = {
  // The list of CT volumes details including the signed paths to their slices.
  ctVolume?: CtVolumeResponse;
};

export const initialCtVolumeState = (): CtVolumeState => {
  return {
    ctVolume: undefined,
  };
};

export const ctVolumeSlice = createSlice({
  name: 'ctVolume',
  initialState: initialCtVolumeState(),
  reducers: {
    setCtVolume: (state, action: PayloadAction<CtVolumeResponse>) => {
      state.ctVolume = action.payload;
    },
  },
});

export const ctVolumeActions = ctVolumeSlice.actions;
