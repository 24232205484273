import React, { ReactElement, useCallback } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  inlineReportingAction,
  UnsavedChangesToInlineReport,
} from '../../reducers/inlineReporting/inlineReportingSlice';
import { useSaveImpressionsCallback } from '../../hooks/useSaveCallBack';
import {
  useExtraCardiacFindings,
  useImpressionsSelector,
  useInlineReportingDraftImpressionsSelector,
  useInlineReportingImpressionsHasChanges,
} from '../../selectors/reporting';
import { PatientOverviewSection } from '../PatientOverviewSection/PatientOverviewSection';
import EditableTextField from '../EditableTextField/EditableTextField';

interface Props {
  aiAssessed: boolean;
  loading?: boolean;
}

export function ImpressionSection({ aiAssessed, loading = false }: Props): ReactElement<Props> {
  const postingPartialReport = useAppSelector((state) => state.report.postingPartialReport);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const inlineReportingState = useAppSelector((state) => state.inlineReporting);

  const impressions = useImpressionsSelector();
  const extraCardiacFindings = useExtraCardiacFindings();

  const hasChanges = useInlineReportingImpressionsHasChanges();

  const onClickEditButton = useCallback(() => {
    dispatch(inlineReportingAction.updateImpressions(impressions));
  }, [impressions, dispatch]);

  const onCancel = useCallback(() => {
    if (hasChanges) {
      dispatch(
        inlineReportingAction.setInlineReportingWarningAction(() => {
          dispatch(inlineReportingAction.clearImpressions());
        })
      );

      dispatch(inlineReportingAction.setUnsavedChangesWarning(UnsavedChangesToInlineReport.CancelEditingImpressions));
    } else {
      dispatch(inlineReportingAction.clearImpressions());
    }
  }, [hasChanges, dispatch]);

  const onSaveImpressions = useSaveImpressionsCallback(id, inlineReportingState.impressions);

  const updateDraftImpression = useCallback(
    (value: string) => {
      dispatch(inlineReportingAction.updateImpressions(value.trim().length === 0 ? [] : value.split('\n')));
    },
    [dispatch]
  );

  const draftImpressions = useInlineReportingDraftImpressionsSelector();

  const onSave = useCallback(() => {
    onSaveImpressions();

    dispatch(inlineReportingAction.clearImpressions());
  }, [onSaveImpressions, dispatch]);

  return (
    <PatientOverviewSection
      editMode={!!inlineReportingState.impressions}
      onClick={onClickEditButton}
      onCancel={onCancel}
      onSave={onSave}
      aiAssessed={aiAssessed}
      title="Impression"
      headerType="non-detailed"
      saveEnabled={hasChanges && !postingPartialReport}
      loading={loading}
    >
      <EditableTextField
        editMode={!!inlineReportingState.impressions}
        text={extraCardiacFindings.length > 0 ? [...impressions, extraCardiacFindings] : impressions}
        updateDraftText={updateDraftImpression}
        draftText={draftImpressions}
        autoFocus={true}
      />
    </PatientOverviewSection>
  );
}
