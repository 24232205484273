import React, { useState, useEffect } from 'react';
import {
  Authenticator,
  SignIn,
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  VerifyContact,
  SignUp,
  ConfirmSignUp,
  Loading,
} from 'aws-amplify-react';
import * as api from '../../utils/api';
import { ArtryaTheme } from './theme';
import { I18n } from '@aws-amplify/core';

export const Auth: React.FunctionComponent = ({ children }) => {
  const [authState, setAuthState] = useState('signIn');

  const handleAuthStateChange = (state: string) => {
    /** Valid state options are:
     *  - signIn
     *  - signUp
     *  - confirmSignIn
     *  - confirmSignUp
     *  - forgotPassword
     *  - requireNewPassword
     *  - verifyContact
     *  - signedIn
     */
    setAuthState(state);
  };

  useEffect(() => {
    if (!(authState && authState === 'signedIn')) return;
    const interval = setInterval(
      () => {
        api.postJSON('/cache/store-auth');
      },
      // 30 second interval (auth cached with 5 minute expiry)
      30 * 1000
    );
    return () => {
      clearInterval(interval);
    };
  }, [authState]);

  const PasswordPolicy = () => {
    if (authState !== 'requireNewPassword') return null;
    return (
      <div className="auth__password-policy">
        <div className="auth__password-policy-title">Password Policy:</div>
        <ul className="auth__password-policy-rules">
          <li>Have at least 8 characters</li>
          <li>Have upper and lower case characters</li>
          <li>Have at least one symbol</li>
          <li>Have at least one number</li>
        </ul>
      </div>
    );
  };

  return (
    <Authenticator
      hideDefault={true}
      hide={[SignUp, ConfirmSignUp]}
      onStateChange={handleAuthStateChange}
      theme={ArtryaTheme}
    >
      <SignIn />
      <ConfirmSignIn />
      <RequireNewPassword />
      <ForgotPassword />
      <VerifyContact />
      <Loading />
      {children}
      <PasswordPolicy />
    </Authenticator>
  );
};

// See possible dictionary keys here:
// https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/AmplifyI18n.tsx
const authScreenLabels = {
  en: {
    'Sign in to your account': `Sign in to your ${process.env.REACT_APP_NAME} account`,
  },
};

I18n.putVocabularies(authScreenLabels);
