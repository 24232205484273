import * as PIXI from 'pixi.js-legacy';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import mergeImages from 'merge-images';
import { Loader } from '../../../components/Loader/Loader';
import { NAV_TABS, WINDOW_LEVEL_MAX, WINDOW_LEVEL_MIN, WINDOW_WIDTH_MAX, WINDOW_WIDTH_MIN } from '../../../config';
import { useContrastContext } from '../../../context/contrast-context';
import { useContrastViewTypeSelector, useContrastVolumeSelector } from '../../../hooks/useContrastViewHelpers';
import {
  ContrastViewType,
  ContrastVolume,
  ContrastVolumeActions,
  ContrastVolumeOverlayMode,
  ContrastVolumeStatus,
} from '../../../context/contrast-types';

import { WindowLevels } from '../../../reducers/window/types';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { VtkContrastView } from '../ReactVTKJS/VTKViewport/VtkContrastView';
import { ContrastViewOverlay } from './ContrastViewOverlay';
import './initCornerstone.js';
import { TOOL_TYPES } from '../../../utils/measurementTools/types';
import { MeasurementTool } from '../../../components/MeasurementTool/MeasurementTool';
import { getMeanValue, getStandardDeviation } from '../../../utils/math-utils';
import { vec2 } from 'gl-matrix';
import { useOnDoubleClickView } from '../../../hooks/useContrastViewHelpers';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useActiveMeasurement } from '../../../hooks/useMeasurementTools';
import { measurementToolsActions } from '../../../reducers/measurement-tools/measurementToolsSlice';
import { windowAction } from '../../../reducers/window/windowSlice';
import { useContrastWindowLevelsInputs } from '../../../hooks/useWindow';
import { vec3 } from 'gl-matrix';

/**
 * A simple function to round the number and clamp it to the min-max range.
 * NOTE: The min and max should be integer values.
 */
const roundAndClamp = (num: number, min: number, max: number) => {
  return num <= min ? min : num >= max ? max : Math.round(num);
};

interface ContrastViewProps {
  // The index of the view being shown (0 to 3).
  viewIndex: number;
}

export const ContrastView: React.FunctionComponent<ContrastViewProps> = ({ viewIndex }) => {
  const visibleTab = useAppSelector((state) => state.globalFlags.visibleTab);
  const study = useAppSelector((state) => state.study.currentStudy);
  const dispatch = useAppDispatch();

  const { pendingScreenshot, visibleViews } = useAppSelector((state) => state.contrast);

  const onDoubleClickView = useOnDoubleClickView();

  const { contrastWindowLevels } = useAppSelector((state) => state.window);
  const { dispatchContrastVolumeAction } = useContrastContext();
  const { selectedVesselName, vesselData } = useVesselStateSelector();

  // Measurement tools store
  useContrastWindowLevelsInputs(contrastWindowLevels);
  const viewId = `ContrastView-${viewIndex}`;
  const { isMeasurementMode } = useAppSelector((state) => state.measurementTools);

  const activeMeasurement = useActiveMeasurement(viewId);

  // The outer WebGLViewer div element.
  const holderRef = useRef<HTMLDivElement | undefined>(undefined);
  // The PIXI application.
  const appRef = useRef<PIXI.Application | undefined>(undefined);
  // The PIXI container that the images, annotations etc are rendered on.
  const containerRef = useRef<PIXI.Container | undefined>(undefined);
  // We can use this to let VtkContrastView force a render on the ContrastView, in particular we want to do
  // this after a context lost event where we might want to show an error.
  const [, setForceRender] = useState<number>(0);

  // Measurement tools
  const [millimeterSpacing, setMillimeterSpacing] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);

  const contrastVolume = useContrastVolumeSelector(viewIndex);
  const contrastView = useAppSelector((state) => state.contrast.contrastViews[viewIndex]);
  const volumeData = useAppSelector((state) =>
    contrastView?.seriesName ? state.contrast.seriesVolumeData[contrastView.seriesName] : undefined
  );
  // TODO: If this fails to get a viewType then really we shouldn't render anything...
  const viewType = useContrastViewTypeSelector(viewIndex) || ContrastViewType.Axial;
  const volumeLoadFailed = contrastVolume?.status === ContrastVolumeStatus.LOAD_FAILED;
  const volumeLoaded = contrastVolume?.status === ContrastVolumeStatus.LOADED;
  const seriesName = contrastVolume ? contrastVolume.seriesName : 'undefined';
  const isVisibleTab = visibleTab === NAV_TABS.ctVolumeTab;

  /**
   * If the user has just switched back to this tab then resize the vtk buffers and render vtk view.
   * If the number of visible views has changed we need to resize the vtk buffers and render vtk view.
   */
  useLayoutEffect(() => {
    const api = contrastVolume?.getApi(viewIndex);
    // We don't need to redraw if this isn't the visible tab.
    if (api && isVisibleTab) {
      api.refreshViewImmediately(true, true);
    }
  }, [isVisibleTab, visibleViews.length]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * It takes a frame for the layout to be applied so we need to flag that the view wasEnlarged so we can
   * resize buffers etc once the new layout has been applied.
   */
  useEffect(() => {
    // If there is a pending screenshot then we can now take it.
    if (pendingScreenshot && pendingScreenshot.viewIndex === viewIndex && pendingScreenshot.onTakeScreenshotCallback) {
      const api = contrastVolume?.getApi(viewIndex);
      if (api) {
        const views = api.genericRenderWindow.getRenderWindow().getViews();
        if (views && views.length >= 1) {
          let screenshot: any = null;
          //divide the size of the view by window.devicePixelRatio to avoid the offset with the measurement tools
          let viewWidth = 0,
            viewHeight = 0;
          if (isMeasurementMode && window.devicePixelRatio > 1) {
            viewWidth = views[0].getSize()[0];
            viewHeight = views[0].getSize()[1];
            views[0].setSize(viewWidth / window.devicePixelRatio, viewHeight / window.devicePixelRatio);
          }
          // Flag that we want to capture an image when we next render one.
          views[0].captureNextImage().then((imageData: string) => {
            if (isMeasurementMode && appRef.current) {
              const canvas = appRef.current.renderer.view;

              screenshot = new Image();
              screenshot.src = canvas.toDataURL();

              screenshot.onload = () => {
                mergeImages([imageData, canvas.toDataURL()]).then((mergeImageData: string) => {
                  pendingScreenshot.onTakeScreenshotCallback(mergeImageData);
                  //restore the size of the view
                  window.devicePixelRatio > 1 && views[0].setSize(viewWidth, viewHeight);
                });
              };
            } else {
              pendingScreenshot.onTakeScreenshotCallback(imageData);
            }
          });
          // Force a render so that captureNextImage has an image to capture.
          api.refreshViewImmediately(true, false);
          return () => (screenshot = null);
        }
      }
    }
    return () => {};
  }, [pendingScreenshot]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get the centerline points for the currently selected vessel.
  // Only if this is the AI assessed contrast volume as that is the coordinate system they are calculated and positioned within.
  let centerline: vec3[] | undefined;
  if (
    contrastVolume &&
    selectedVesselName &&
    vesselData &&
    study?.ai_assessed?.contrast_id === contrastVolume.seriesName
  ) {
    centerline = vesselData[selectedVesselName]?.centerline;
  }

  // Try to get the scan thickness of the study from the series details.
  // NOTE: thickness is saved as a string.
  let scanThickness = 0.1;
  if (contrastVolume) {
    const thickness = study?.series?.[contrastVolume.seriesName]?.thickness;
    if (thickness) {
      scanThickness = parseFloat(thickness);
    }
  }

  /**
   * Update the huValue and crosshairs position in the store context after the volume has loaded.
   */
  useEffect(() => {
    if (volumeLoaded) {
      dispatchContrastVolumeAction({
        type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES,
        viewIndex,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeLoaded]);

  /**
   * A hook safe throttled way to update the huValue and crosshairs position in the store context.
   */
  const throttledOnCrosshairsMovedRef = useRef(
    throttle(
      (viewIndex) => {
        dispatchContrastVolumeAction({
          type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES,
          viewIndex,
        });
      },
      200,
      { leading: false, trailing: true }
    )
  );

  /**
   * Respond to the crosshairs position changing.
   */
  const onCrosshairsMoved = useCallback(() => {
    throttledOnCrosshairsMovedRef.current(viewIndex);
  }, [viewIndex]);

  /**
   * A hook safe throttled way to respond to the window levels changing.
   */
  const throttledOnWindowLevelsChangedRef = useRef(
    throttle(
      (contrastVolume: ContrastVolume | undefined, windowLevels: WindowLevels) => {
        if (contrastVolume) {
          // Limit the windowing ranges.
          windowLevels.windowWidth = Math.min(Math.max(windowLevels.windowWidth, WINDOW_WIDTH_MIN), WINDOW_WIDTH_MAX);
          windowLevels.windowCenter = Math.min(Math.max(windowLevels.windowCenter, WINDOW_LEVEL_MIN), WINDOW_LEVEL_MAX);
          // Update the window levels for the CPR, MPR, short-axis, long-axis etc.
          dispatch(windowAction.setContrastWindowLevels(windowLevels));
        }
      },
      10,
      { leading: false, trailing: true }
    )
  );

  /**
   * Respond to the window levels changing.
   */
  const onWindowLevelsChanged = useCallback(
    (windowLevels: WindowLevels) => {
      throttledOnWindowLevelsChangedRef.current(contrastVolume, windowLevels);
    },
    [contrastVolume]
  );

  /**
   * Cancel any throttled functions on unmount.
   */
  useEffect(() => {
    // This looks daft but it makes lint happy.
    const throttledOnCrosshairsMoved = throttledOnCrosshairsMovedRef.current;
    const throttledOnWindowLevelsChanged = throttledOnWindowLevelsChangedRef.current;
    return () => {
      throttledOnCrosshairsMoved.cancel();
      throttledOnWindowLevelsChanged.cancel();
    };
  }, []);

  /**
   * Respond to a double click event on the window.
   * @param button The index of the button that was double clicked.
   */
  const onDoubleClick = useCallback(
    (button: number) => {
      // Switch to / from fullscreen for the double clicked view on the left button.
      if (button === 1) {
        onDoubleClickView(viewIndex);
        setScale(containerRef.current?.scale.y ?? 1);
      }
    },
    [onDoubleClickView, viewIndex]
  );
  const viewProps = contrastVolume?.viewProps?.[viewType]!;

  /**
   * onPIXIChanged to be called when the VtkContrastView has changed the PIXI container used by the measurment tools.
   */
  const onPIXIChanged = ({
    container,
    holder,
    app,
    millimeterSpacing,
  }: {
    container: PIXI.Container | undefined;
    holder: HTMLDivElement | undefined;
    app: PIXI.Application | undefined;
    millimeterSpacing: number;
  }) => {
    appRef.current = app;
    containerRef.current = container;
    holderRef.current = holder;
    setScale(containerRef.current?.scale.y ?? 1);
    setMillimeterSpacing(millimeterSpacing);
  };

  const onZoom = () => {
    if (containerRef.current && containerRef.current.scale) {
      setScale(containerRef.current.scale.y);
    }
  };

  /**
   * Get the position of the mouse over the view from the global page position in the event.
   */
  const getMousePos = (event: React.MouseEvent): any => {
    const pos = { x: 0, y: 0 };
    if (holderRef.current && containerRef.current) {
      // Get the position and size of the component on the page.
      const holderOffset = holderRef.current.getBoundingClientRect();
      pos.x = (event.pageX - holderOffset.x - containerRef.current.x) / containerRef.current.scale.x;
      pos.y = (event.pageY - holderOffset.y - containerRef.current.y) / containerRef.current.scale.y;
    }
    return pos;
  };

  /**
   * Convert from a PIXI container position to a view position.
   * ie the top left of the view is [0, 0] and the bottom right is [width - 1, height - 1].
   */
  const containerPosToViewPos = (pos: { x: number; y: number }): vec2 => {
    if (containerRef.current && pos) {
      return [
        pos.x * containerRef.current.scale.x + containerRef.current.x,
        pos.y * containerRef.current.scale.y + containerRef.current.y,
      ];
    }
    return [-1, -1];
  };

  /**
   * Calculate the huData for the ellipse defined by the two points passed in (given in view coordinates).
   */
  const updateHuData = useCallback(
    (posA: vec2, posB: vec2) => {
      if (!activeMeasurement) return;
      const api = contrastVolume?.getApi(viewIndex);
      if (!api) return;
      const openGLRenderWindow = api.genericRenderWindow?.getOpenGLRenderWindow();
      if (!openGLRenderWindow) return;

      // Get the size of the view in pixels.
      const viewSize: vec2 = openGLRenderWindow.getSize();

      // Macs have their strange pixel density setup where a 'pixel' may not be a physical pixel on the screen.
      // Apply a scaling (if needed) between what OpenGL thinks a pixel is to what the View thinks a pixel is.
      if (holderRef.current) {
        // Get the position and size of the component on the page.
        const holderRect = holderRef.current.getBoundingClientRect();
        const scale: vec2 = [viewSize[0] / holderRect.width, viewSize[1] / holderRect.height];
        posA[0] *= scale[0];
        posA[1] *= scale[1];
        posB[0] *= scale[0];
        posB[1] *= scale[1];
      }

      // Get the start and end position that we want to read back from the view; these should be clipped to the view extents.
      const startPos = [
        roundAndClamp(Math.min(posA[0], posB[0]), 0, viewSize[0] - 1),
        roundAndClamp(Math.min(posA[1], posB[1]), 0, viewSize[1] - 1),
      ];
      const endPos = [
        roundAndClamp(Math.max(posA[0], posB[0]), 0, viewSize[0] - 1),
        roundAndClamp(Math.max(posA[1], posB[1]), 0, viewSize[1] - 1),
      ];

      // Force a render so that getPixelData captures something.
      api?.refreshViewImmediately(true, false);

      // Read back the pixel data.
      // NOTE: It is essential to have re-rendered the view immediately prior.
      // NOTE: The Y coordinates are flipped thanks to WebGL logic.
      const pixelData = openGLRenderWindow.getPixelData(
        startPos[0],
        viewSize[1] - 1 - endPos[1],
        endPos[0],
        viewSize[1] - 1 - startPos[1]
      );
      // Get the dimensions to the area we read back.
      const w = endPos[0] - startPos[0] + 1;
      const h = endPos[1] - startPos[1] + 1;

      // Calculate HU Mean
      let huMean: number | undefined;
      // Calculate HU Standard deviation
      let huStdDeviation: number | undefined;

      if (pixelData) {
        // Get the hu values that black and white map to.
        // As mentioned previously reading back values in the 0-255 range will lose a lot of precision vs reading from the model itself.
        const lowValue = contrastWindowLevels.windowCenter - 0.5 * contrastWindowLevels.windowWidth;
        const highValue = contrastWindowLevels.windowCenter + 0.5 * contrastWindowLevels.windowWidth;

        // Get the center of the ellipse and the radius per dimension.
        const centerX = 0.5 * (posA[0] + posB[0]);
        const centerY = 0.5 * (posA[1] + posB[1]);
        const radiusX = 0.5 * Math.abs(posA[0] - posB[0]);
        const radiusY = 0.5 * Math.abs(posA[1] - posB[1]);

        // Build up a list of all HU values that fall inside the ellipse.
        const huValues: number[] = [];
        for (let y = 0; y < h; y++) {
          for (let x = 0; x < w; x++) {
            // Calculate if the point is inside the ellipse.
            const dx = (startPos[0] + x - centerX) / radiusX;
            const dy = (startPos[1] + y - centerY) / radiusY;
            if (dx * dx + dy * dy <= 1.0) {
              // Use the green channel (although red and blue should have the same value).
              const windowedValue = pixelData[4 * (x + w * y) + 1];
              // Convert from 0-255 back to a un-windowed value.
              const unwindowedValue = (highValue - lowValue) * (windowedValue / 255.0) + lowValue;
              // Add the value to the list.
              huValues.push(unwindowedValue);
            }
          }
        }
        if (huValues.length > 0) {
          // Calculate HU Mean
          huMean = getMeanValue(huValues);
          // Calculate HU Standard deviation
          huStdDeviation = getStandardDeviation(huValues);
        }
      }

      // Update the measurement with the new hu data.
      dispatch(
        measurementToolsActions.updateMeasurement({
          measurementId: activeMeasurement.measurementId,
          huData: { mean: huMean, stdDev: huStdDeviation },
        })
      );
    },
    [
      contrastVolume,
      activeMeasurement,
      contrastWindowLevels.windowCenter,
      contrastWindowLevels.windowWidth,
      viewIndex,
      dispatch,
    ]
  );

  const onMouseMove = (event: React.MouseEvent) => {
    if (isMeasurementMode) {
      // Manipulate an active ellipse.
      if (activeMeasurement && activeMeasurement.type === TOOL_TYPES.Ellipse) {
        // Update the huData.
        updateHuData(containerPosToViewPos(activeMeasurement.startPoint), containerPosToViewPos(getMousePos(event)));
      }
    }
  };

  // Check that vtk is able to render, if not we will show a warning instead of the 3D volume.
  // If AP-1200 / AP-2630 / AP-2634 / AP-2929 low performance GPU bug has caused the shader compile to fail and/or a lost context for every WebGL renderer;
  // We show a warning instead.
  let vtkError: string | undefined;
  if (!volumeLoadFailed && contrastVolume?.volume) {
    if (contrastVolume?.getApi(viewIndex)?.getError) {
      vtkError = contrastVolume?.getApi(viewIndex)?.getError();
    } else {
      // NOTE: We can get here is the view loses its WebGL context and then fails to re-initialize.
      // The most likely cause is a catastrophic overuse of memory.
      vtkError = 'vtkError: volume api not found';
    }
  }

  return (
    <>
      {volumeLoadFailed && (
        // It's possible we failed to load all the required slices, in which case we show an alert and the following error message.
        <div className="contrast-viewer__loading">
          <div className="contrast-viewer__text">{'Unable to display series'}</div>
        </div>
      )}

      {!volumeLoadFailed && contrastVolume?.volume === undefined && (
        // Render the loading spinner if the CT volume has not yet loaded. It will show "Preparing slices" briefly before
        // the images are sequentially loaded and also when they are already loaded in the cache for the selected study + seriesName.
        <div className="contrast-viewer__loading">
          <Loader
            large={false}
            text={
              volumeData && volumeData.imageCountLoaded > 0
                ? `Loading slice ${volumeData.imageCountLoaded} of ${volumeData.dimensions[2]}`
                : 'Preparing slices'
            }
          />
        </div>
      )}

      {/* Render the volume as soon as the images have loaded, this will set the volume status to loaded once the values have been initialized */}
      {!volumeLoadFailed && contrastVolume?.volume && (
        // Render the view of the CT volume with crosshair overlay.
        <MeasurementTool
          viewId={viewId}
          holder={holderRef.current}
          app={appRef.current}
          container={containerRef.current}
          slice={0} // TODO: This will need to be set to the current slice when slice changing is enabled.
          millimeterSpacing={millimeterSpacing}
          scale={scale}
          onMouseMoveHandler={onMouseMove}
        >
          <VtkContrastView
            key={`ContrastView-${seriesName}-${viewIndex}-${viewType}`} // We use the key to force a remount when the viewIndex, viewType, study or series changes.
            viewIndex={viewIndex}
            viewType={viewType}
            apis={contrastVolume.apis}
            volume={contrastVolume.volume}
            crosshairWorldPosition={contrastVolume.crosshairWorldPosition}
            crosshairWorldAxes={contrastVolume.crosshairWorldAxes}
            scanThickness={scanThickness}
            renderThickness={viewProps.renderThickness}
            blendMode={viewProps.blendMode}
            windowLevels={contrastWindowLevels}
            centerline={centerline}
            onCrosshairsMoved={onCrosshairsMoved}
            onWindowLevelsChanged={onWindowLevelsChanged}
            onDoubleClick={onDoubleClick}
            rotateHandleDistance={7 / 16}
            showCenterline={viewProps.overlayMode === ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE}
            showCrosshairs={viewProps.overlayMode === ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE}
            onPIXIChanged={onPIXIChanged}
            onForceRender={() => setForceRender((forceRender) => forceRender + 1)}
            isMeasurementMode={isMeasurementMode}
            onZoom={onZoom}
          />
        </MeasurementTool>
      )}

      {/* Only render the overlay once the volume has fully loaded and been initialized */}
      {!volumeLoadFailed && volumeLoaded && contrastVolume?.volume && (
        // Render the MetaDetails overlay.
        <ContrastViewOverlay contrastVolume={contrastVolume} viewIndex={viewIndex} />
      )}

      {/* If vtk is currently unable to render the view then show a warning over the top of the entire view. */}
      {!volumeLoadFailed && vtkError && (
        <div className="contrast-viewer__vtk_error_overlay">
          <div className="contrast-viewer__text">{'Insufficient resources to display'}</div>
        </div>
      )}
    </>
  );
};
