import React, { useCallback, useMemo } from 'react';
import { CTVolumeOverlay } from '../../../context/contrast-types';
import HorizontalButtonList, {
  HorizontalButtonProp,
} from '../../../components/HorizontalButtonList/HorizontalButtonList';
import { ReactComponent as InfoIcon } from '../../../assets/icons/alert.svg';
import AnnotationModal from '../../../components/AnnotationModal/AnnotationModal';

interface NonContrastViewHeaderProps {
  seriesDescription: string;
  nonContrastViewOverlay: CTVolumeOverlay;
  setNonContrastViewOverlay: (overlay: CTVolumeOverlay) => void;
  screenshotRef?: any;
  onTakeScreenshot: any;
  onCloseScreenshot: any;
}

/**
 * Allow the user to adjust the MIP slab thickness.
 */
export const NonContrastViewHeader: React.FunctionComponent<NonContrastViewHeaderProps> = ({
  seriesDescription,
  nonContrastViewOverlay,
  setNonContrastViewOverlay,
  screenshotRef,
  onTakeScreenshot,
  onCloseScreenshot,
}) => {
  const toggleOverlay = useCallback(
    (overlay: CTVolumeOverlay) => {
      if (nonContrastViewOverlay === overlay) {
        setNonContrastViewOverlay(CTVolumeOverlay.HIDE);
      } else {
        setNonContrastViewOverlay(overlay);
      }
    },
    [nonContrastViewOverlay, setNonContrastViewOverlay]
  );

  const toggleInfoSection = useCallback(() => toggleOverlay(CTVolumeOverlay.INFO), [toggleOverlay]);

  const horizontalButtonList: HorizontalButtonProp[] = useMemo(() => {
    const showInfo: HorizontalButtonProp = {
      type: 'Action',
      onClick: toggleInfoSection,
      title: 'Toggle meta data',
      icon: <InfoIcon />,
      selected: nonContrastViewOverlay === CTVolumeOverlay.INFO,
    };

    const takeScreenShot: HorizontalButtonProp = {
      type: 'Custom',
      element: (
        <AnnotationModal
          screenshotRef={screenshotRef}
          onTakeScreenshot={onTakeScreenshot}
          viewName="noncontrast"
          onClose={onCloseScreenshot}
          headerStyle={true}
        />
      ),
    };

    return [showInfo, takeScreenShot];
  }, [screenshotRef, onCloseScreenshot, toggleInfoSection, nonContrastViewOverlay, onTakeScreenshot]);

  return (
    <div className="non-contrast-view-header">
      <label className="non-contrast-view-header__series_name_label">{seriesDescription}</label>
      <div className="non-contrast-view-header__flex_spacer" />
      <div className="non-contrast-view-header__action_buttons">
        <HorizontalButtonList buttons={horizontalButtonList} />
      </div>
    </div>
  );
};

export default NonContrastViewHeader;
