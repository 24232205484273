import cn from 'classnames';
import React, { ChangeEvent, ReactElement } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { FindingsStatus } from '../../components/FindingsStatus/FindingsStatus';
import { VesselGroupings } from '../../config';
import { CoronaryFindings } from '../../context/types';
import { getVesselColor } from '../../utils/vessel';
import Alert from '../Alert/Alert';

interface ActiveField {
  vessel: string;
  isFocused: boolean;
  isDirty: boolean;
}

interface Props {
  vesselGroup: VesselGroupings;
  coronaryFindings?: CoronaryFindings;
  draftCoronaryFindings?: string;
  editMode: boolean;
  handleFocus?: () => void;
  handleBlur?: () => void;
  handleInputChange?: (event: ChangeEvent<HTMLTextAreaElement>, vessel: VesselGroupings) => void;
  activeField?: ActiveField;
  showVesselName: boolean;
  statusVersion?: boolean;
  autoFocus?: boolean;
}

export default function KeyVesselFinding({
  vesselGroup,
  coronaryFindings,
  draftCoronaryFindings,
  editMode,
  handleFocus,
  handleBlur,
  handleInputChange,
  activeField,
  showVesselName,
  statusVersion,
  autoFocus = false,
}: Props): ReactElement<Props> {
  return (
    <div
      className={cn('report-field', {
        'report-field--no-heading': !showVesselName,
      })}
      key={`key-findings-vessel__${vesselGroup}`}
    >
      {showVesselName && !editMode && statusVersion !== undefined && <FindingsStatus versions={statusVersion} />}
      <div className="report-field__container">
        {showVesselName && (
          <h3 className="report-field__heading key-findings__header">
            <span
              style={{
                background: getVesselColor(vesselGroup),
              }}
              className="key-findings__legend"
            />
            {vesselGroup}
            {vesselGroup !== 'LM' && ' including branches'}
          </h3>
        )}
        <div className="report-field__field">
          {editMode ? (
            <>
              {activeField?.vessel === vesselGroup && activeField.isDirty && activeField.isFocused && (
                <Alert>Ensure that the summary information above and the Impression reflects any changes.</Alert>
              )}
              <TextareaAutosize
                maxRows={7}
                className="findings__enter"
                value={draftCoronaryFindings}
                onChange={(ev) => {
                  handleInputChange?.(ev, vesselGroup);
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                autoFocus={autoFocus}
              />
            </>
          ) : (
            <p>{coronaryFindings?.[vesselGroup]}</p>
          )}
        </div>
      </div>
    </div>
  );
}
