import { Action } from '@reduxjs/toolkit';
import { suppressedText } from '../../store';
import { lesionActions } from './lesionSlice';

const lesionSuppressionList: string[] = [lesionActions.setLesionData.type];

export function lesionDataActionsSanitizer<A extends Action>(action: A) {
  if (lesionSuppressionList.includes(action.type) === true) {
    // @ts-ignore
    return { ...action, payload: { ...action.payload, lesion_id: suppressedText } };
  }

  return action;
}
export function lesionDataStateSanitizer<S>(state: S) {
  const newState = { ...state, lesion: suppressedText };
  return newState;
}
