import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalciumScore } from '../../context/types';

export interface calciumScoreState {
  calciumScoreData: CalciumScore | undefined | null;
}

const initialCalciumScoreState = (): calciumScoreState => {
  return { calciumScoreData: undefined };
};

export const calciumScoreSlice = createSlice({
  name: 'calciumScore',
  initialState: initialCalciumScoreState(),
  reducers: {
    setCalciumScoreData: (state, action: PayloadAction<CalciumScore | undefined | null>) => {
      state.calciumScoreData = action.payload;
    },
  },
});

export const calciumScoreActions = calciumScoreSlice.actions;
