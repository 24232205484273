import React, { ReactElement, useMemo } from 'react';
import { getNamedTypeFromContrastType, getViewContrastType } from '../../../components/DropZone/helpers';
import { ContrastViewContent } from '../../../context/contrast-types';
import { useAppSelector } from '../../../hooks';
import ContrastSeriesIcon, { BorderType } from '../../../components/ContrastSeriesIcon/ContrastSeriesIcon';

interface Props {
  onClick: () => void;
  recent: ContrastViewContent;
}

export default function RecentlySelectedContrastSeries({ onClick, recent }: Props): ReactElement<Props> {
  const draggableGroup = useAppSelector((state) => state.dragAndDrop.draggableGroup);

  const thumbnail = useMemo(() => {
    if (!recent.seriesName) return undefined;
    return draggableGroup[recent.seriesName].children.find((x) => getViewContrastType(x.customType) === recent.viewType)
      ?.thumbnail;
  }, [draggableGroup, recent]);

  return (
    <div onClick={onClick} className="recently-selected-row">
      {thumbnail && <ContrastSeriesIcon borderType={BorderType.NONE} imageUrlOrType={thumbnail} />}
      <span>{getNamedTypeFromContrastType(recent.viewType)}</span>
    </div>
  );
}
