import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from './reducers/user/userSlice';
import { dashboardSlice } from './reducers/dashboard/dashboardSlice';
import { workflowSlice } from './reducers/workflow/workflowSlice';
import { globalLoaderSlice } from './reducers/globalLoader/globalLoaderSlice';
import { loginSlice } from './reducers/login/loginSlice';
import { releaseVersionSlice } from './reducers/ReleaseVersion/releaseVersionSlice';

export const suppressedText = '<<Suppressed>>';

export const dashboardStore = configureStore({
  reducer: {
    user: userSlice.reducer,
    dashboard: dashboardSlice.reducer,
    workflow: workflowSlice.reducer,
    globalLoader: globalLoaderSlice.reducer,
    login: loginSlice.reducer,
    releaseVersion: releaseVersionSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
});

// Infer the `DashboardState` and `DashboardDispatch` types from the store itself
export type DashboardState = ReturnType<typeof dashboardStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type DashboardDispatch = typeof dashboardStore.dispatch;
