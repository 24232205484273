import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MPRAxes } from '../vesselData/types';

export interface ModelVessel {
  // The vessel THREE.BufferGeometry encoded as JSON.
  geometry: string;
  // The vessel directions for each vessel slice.
  axes: MPRAxes;
  // The array lesion THREE.BufferGeometry for this vessel encoded as JSON.
  lesionGeometry: string[];
}

export interface ModelState {
  // The aorta THREE.BufferGeometry encoded as JSON.
  aortaGeometry: string;
  vessels: Record<string, ModelVessel>;
}

const getInitialState = (): ModelState => {
  return {
    aortaGeometry: '',
    vessels: {},
  };
};

export const modelSlice = createSlice({
  name: 'model',
  initialState: getInitialState(),
  reducers: {
    setAortaGeometry: (state, action: PayloadAction<string>) => {
      state.aortaGeometry = action.payload;
    },
    setVessel: (state, action: PayloadAction<{ vesselId: string; modelVessel: ModelVessel }>) => {
      state.vessels[action.payload.vesselId] = action.payload.modelVessel;
    },
  },
});

export const modelActions = modelSlice.actions;
