import React, { ReactElement } from 'react';
import cn from 'classnames';
import { ReactComponent as TickIcon } from '../../../assets/icons/reskin/icon-tick.svg';
interface Props {
  name: string;
  labelText?: string | undefined;
  labelPosition?: 'right' | 'left';
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

export function Checkbox({
  name,
  labelText,
  checked,
  disabled,
  labelPosition = 'right',
  onChange,
}: Props): ReactElement<Props> {
  return (
    <div className="checkbox__container">
      {labelText != null && labelPosition === 'left' && (
        <label
          htmlFor={name}
          className={cn('checkbox__label', 'checkbox__label--left', {
            'checkbox__label--disabled': disabled,
          })}
        >
          {labelText}
        </label>
      )}

      <input id={name} type="checkbox" checked={checked} onChange={onChange} className="checkbox" disabled={disabled} />
      {checked && (
        <span>
          {disabled && (
            <TickIcon
              className={cn('checkbox__icon', {
                'checkbox__icon--disabled': disabled,
              })}
            />
          )}
          {!disabled && <TickIcon onClick={onChange} className={'checkbox__icon'} />}
        </span>
      )}

      {labelText != null && labelPosition === 'right' && (
        <label
          htmlFor={name}
          className={cn('checkbox__label', 'checkbox__label--right', {
            'checkbox__label--disabled': disabled,
          })}
        >
          {labelText}
        </label>
      )}
    </div>
  );
}
