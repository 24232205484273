import cn from 'classnames';
import React, { DragEventHandler, ReactElement, useState } from 'react';
import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron.svg';
import { SingleDraggableProps } from './types';
import { DraggableHighlight, ThumbnailType } from '../../reducers/dragAndDrop/types';
import { getViewContrastType } from '../../components/DropZone/helpers';
import ContrastSeriesIcon, { BorderType } from '../../components/ContrastSeriesIcon/ContrastSeriesIcon';

interface Props {
  groupTitle: string;
  extraInfo: string | undefined;
  isDragging: boolean;
  thumbnail?: ThumbnailType;
  singleDraggables: SingleDraggableProps[];
  onDrag: DragEventHandler<HTMLSpanElement>;
  onStart: DragEventHandler<HTMLSpanElement>;
  onStop: DragEventHandler<HTMLSpanElement>;

  showLeftBorder: boolean;

  keysAndTypesToChildItemsToHighlight: DraggableHighlight[];
}

export default function GroupDraggable({
  groupTitle,
  singleDraggables,
  thumbnail,
  onDrag,
  onStart,
  onStop,
  isDragging,
  extraInfo,
  showLeftBorder,
  keysAndTypesToChildItemsToHighlight,
}: Props): ReactElement<Props> {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <span
        draggable
        onDragEnd={onStop}
        onDrag={onDrag}
        onDragStart={onStart}
        className={cn('parent-draggable no-cursor', {
          'parent-draggable__selected': isDragging,
          'parent-draggable__showLeftBorder': showLeftBorder,
        })}
      >
        <strong className="row-container cursor">
          <div
            className={cn('row-container', {
              'row-container__expanded': !collapsed,
            })}
          >
            {singleDraggables.length > 0 && (
              <span
                className={cn('drop__arrow no-cursor', {
                  'drop__arrow--open': !collapsed,
                })}
                onClick={() => setCollapsed(!collapsed)}
              >
                <ChevronIcon />
              </span>
            )}
            {singleDraggables.length === 0 && <div style={{ width: '48px' }} />}
            {thumbnail && <ContrastSeriesIcon borderType={BorderType.NONE} imageUrlOrType={thumbnail} />}
            <div className="row-container__info_area">
              <span className="row-container__text-title">{groupTitle}</span>
              <span className="row-container__text-extra">{extraInfo}</span>
            </div>
          </div>
        </strong>
      </span>
      {!collapsed &&
        singleDraggables.map(
          ({ onStop: onChildStop, onStart: onChildStart, onDrag: onChildDrag, draggableItem }, index) => {
            // Highlight the draggable if it is in the keysAndTypesToChildItemsToHighlight list.
            const key = keysAndTypesToChildItemsToHighlight.find(
              (x) => x.key === draggableItem.id && x.type === getViewContrastType(draggableItem.customType)
            );
            const borderType: BorderType = key
              ? key.visible
                ? BorderType.ACTIVE
                : BorderType.INACTIVE
              : BorderType.NONE;

            return (
              <span
                draggable
                onDragEnd={onChildStop}
                onDrag={onChildDrag}
                onDragStart={onChildStart}
                key={`draggable-${index}`}
                className={cn('child-draggable row-container cursor', {
                  'child-draggable__selected': draggableItem.isDragging,
                })}
              >
                {draggableItem.thumbnail && (
                  <ContrastSeriesIcon borderType={borderType} imageUrlOrType={draggableItem.thumbnail} />
                )}
                <span className="row-container__text-extra">{draggableItem.title}</span>
              </span>
            );
          }
        )}
    </>
  );
}
