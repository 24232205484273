import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import { ReactComponent as EditReportIcon } from '../../assets/icons/edit.svg';
import Button from '../Button/Button';
import { PatientData } from '../../context/types';
import { calculateLVMassIndex, getLatestVersion } from '../../../src/utils/api';
import showToast from '../Toast/showToast';
import { Loader } from '../Loader/Loader';
import { fetchPatientData, fetchLVMassIndex, fetchLVMass } from '../../../src/utils/api';
import { useEffectCustomDeps } from '../../hooks/useEffectCustomDeps';
import { useAppSelector } from '../../hooks';
import { useUpdateVersionHead } from '../../hooks/use-version-head';
import { useDashboardSelector } from '../../dashboardHooks';

export const LVMassIndex: React.FunctionComponent = () => {
  const versionHead = useAppSelector((state) => state.store.versionHead);
  const runID = useAppSelector((state) => state.study.currentStudy?.active_run);
  const fetchingOverallMeasurements = useAppSelector((state) => state.globalFlags.fetchingOverallMeasurements);
  const patientID = useAppSelector((state) => state.patient.patientID);
  const [editModeLV, setEditModeLV] = useState(false);
  const [height, setHeight] = useState<number | null>(null);
  const [weight, setWeight] = useState<number | null>(null);
  const [patientHeight, setPatientHeight] = useState<number | null>(null);
  const [patientWeight, setPatientWeight] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [invalidWeight, setInvalidWeight] = useState(false);
  const [invalidHeight, setInvalidHeight] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [heightHasChanges, setHeightHasChanges] = useState(true);
  const [weightHasChanges, setWeightHasChanges] = useState(true);
  const DEFAULT_PATIENT_DATA = { height: undefined, weight: undefined };
  const [lvMassIndexMeasurement, setLvMassIndexMeasurement] = useState<string | undefined>();
  const [patientData, setPatientData] = useState<PatientData>(DEFAULT_PATIENT_DATA);
  const [lvMassMeasurement, setLvMassMeasurement] = useState<string | undefined>();
  const debounceEvent = useRef<any>(0);
  const updateVersionHead = useUpdateVersionHead();
  const { clientConfig } = useDashboardSelector((state) => state.user);

  useEffectCustomDeps(() => {
    if (!patientID || !runID) {
      return;
    }

    getLatestVersion(patientID, runID, versionHead)
      .then((res) => {
        updateVersionHead(res.head_version);
      })
      .catch((err) => {
        updateVersionHead(versionHead);
      });

    fetchLVMassIndex(patientID, runID, versionHead)
      .then((res) => {
        let lvMassIndexValue = Number(res.lv_mass_index).toFixed(2);
        res ? setLvMassIndexMeasurement(lvMassIndexValue) : setLvMassIndexMeasurement(undefined);
      })
      .catch((err) => {
        setLvMassIndexMeasurement(undefined);
      });

    fetchPatientData(patientID, runID, versionHead)
      .then((res) => {
        res ? setPatientData(res) : setPatientData(DEFAULT_PATIENT_DATA);
        setHeight(Number(res.height) / 100);
        setWeight(Number(res.weight));
        setPatientHeight(Number(res.height) / 100);
        setPatientWeight(Number(res.weight));
      })
      .catch(() => {
        setPatientData(DEFAULT_PATIENT_DATA);
      });

    fetchLVMass(patientID, runID, versionHead)
      .then((res) => {
        let lvMassValue = Number(res.lv_mass).toFixed(2);
        res ? setLvMassMeasurement(lvMassValue) : setLvMassMeasurement(undefined);
      })
      .catch(() => {
        setLvMassMeasurement(undefined);
      });
  }, [patientID, runID, lvMassMeasurement, lvMassIndexMeasurement, patientHeight, patientWeight]);

  const savePatientHeight = (event: any) => {
    setHeight(event.target.value);
    isHeightValid(event.target.value);
    if (event.target.value === patientHeight) {
      setHeightHasChanges(true);
    } else {
      setHeightHasChanges(false);
    }
  };

  const savePatientWeight = (event: any) => {
    setWeight(event.target.value);
    isWeightValid(event.target.value);
    if (event.target.value === patientWeight) {
      setWeightHasChanges(true);
    } else {
      setWeightHasChanges(false);
    }
  };

  const onCancelEdit = () => {
    setEditModeLV(false);
  };

  const onSaveEdit = () => {
    setLoading(true);
    setIsDisable(true);
    if (!patientID || !runID || !versionHead || !height || !weight) {
      return;
    }
    calculateLVMassIndex(
      patientID,
      runID,
      versionHead,
      //converting m to cm
      Number(height * 100),
      Number(weight),
      Number(lvMassMeasurement)
    )
      .then((res) => {
        updateVersionHead(res.version_id);
        setLoading(false);
        setEditModeLV(false);
        setHeightHasChanges(true);
        setWeightHasChanges(true);
        if (res.success) {
          setIsDisable(false);
          showToast.success('LV Mass Index saved');
          let lvMassIndexValue = Number(res.lv_mass_index).toFixed(2);
          setLvMassIndexMeasurement(lvMassIndexValue);
        }
      })
      .catch((err) => {
        // Revert to original version
        setLoading(false);
        setIsDisable(false);
        showToast.error('Error Saving LV Mass Index');
      });
  };

  // validating that the height is in 1 m and 3 m
  const isHeightValid = useCallback((heightValue) => {
    clearTimeout(debounceEvent.current);
    debounceEvent.current = setTimeout(() => {
      if ((heightValue && heightValue < 1) || (heightValue && heightValue > 3) || !heightValue) {
        setInvalidHeight(true);
      } else {
        setInvalidHeight(false);
      }
    }, 500);
  }, []);

  // validating that the weight is more than 30kg
  const isWeightValid = useCallback((weightValue) => {
    clearTimeout(debounceEvent.current);
    debounceEvent.current = setTimeout(() => {
      if ((weightValue && weightValue < 30) || !weightValue) {
        setInvalidWeight(true);
      } else {
        setInvalidWeight(false);
      }
    }, 500);
  }, []);

  return (
    <>
      {!fetchingOverallMeasurements && (
        <div className="lv-mass-index">
          <div className="lv-mass-index__heading">
            <h3 className="lv-mass-index__header">Left Ventricular Mass Index</h3>
            <button
              className="card__edit-button button-lv-mass"
              onClick={() => {
                setEditModeLV(!editModeLV);
              }}
              disabled={editModeLV || !lvMassMeasurement}
            >
              <EditReportIcon />
              {loading && <Loader inline />}
              {!lvMassMeasurement && <div className="button-lv-mass__tooltip">LV Mass value is missing</div>}
            </button>
          </div>

          {editModeLV ? (
            <>
              <dl className="value-list lv-mass-index__value-list">
                <div className="value-list__item">
                  <dt className="value-list__label">LV Mass Index</dt>
                  <dd className="value-list__value">
                    {lvMassIndexMeasurement ? (
                      <span>
                        {lvMassIndexMeasurement} g/m<sup>2</sup>
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </dd>
                </div>

                <div className="value-list__item">
                  <dt className="value-list__label">LV Mass</dt>
                  <dd className="value-list__value">
                    {lvMassMeasurement ? <span>{lvMassMeasurement} g</span> : <span>-</span>}
                  </dd>
                </div>

                <div className="value-list__item">
                  <dt className="value-list__label lv-mass-index__label">
                    <label htmlFor="patient-overview-lv-mass-height">Patient Height</label>
                  </dt>
                  <dd className="value-list__value">
                    <div className="lv-mass-index__edit">
                      <input
                        type="number"
                        value={height ? height : '-'}
                        className={cn('lv-mass-index__edit-input', {
                          'form-field-error': invalidHeight,
                        })}
                        onChange={savePatientHeight}
                        disabled={isDisable}
                        id="patient-overview-lv-mass-height"
                      />
                      <abbr className="lv-mass-index__edit-label">m</abbr>
                    </div>
                    {invalidHeight && (
                      <p className="form-error lv-mass-index__edit-input--invalid">
                        {height && height < 1 ? 'Height must be at least 1 m' : null}
                        {height && height > 3 ? 'Height must be 3 m or less' : null}
                      </p>
                    )}
                  </dd>
                </div>

                <div className="value-list__item">
                  <dt className="value-list__label lv-mass-index__label">
                    <label htmlFor="patient-overview-lv-mass-weight">Patient Weight</label>
                  </dt>
                  <dd className="value-list__value">
                    <div className="lv-mass-index__edit">
                      <input
                        type="number"
                        value={weight ? weight : '-'}
                        className={cn('lv-mass-index__edit-input', {
                          'form-field-error': invalidWeight,
                        })}
                        onChange={savePatientWeight}
                        disabled={isDisable}
                        id="patient-overview-lv-mass-weight"
                      />
                      <abbr className="lv-mass-index__edit-label">kg</abbr>
                    </div>
                    {invalidWeight && (
                      <p className="form-error lv-mass-index__edit-input--invalid">Weight must be at least 30 kg</p>
                    )}
                  </dd>
                </div>
              </dl>

              <div className="lv-mass-index__stat-edit">
                <Button inline theme="secondary" onClick={onCancelEdit} disabled={isDisable}>
                  <span className="card__cancel-button">Cancel</span>
                </Button>
                <Button
                  inline
                  theme="primary"
                  disabled={invalidHeight || invalidWeight || isDisable || (heightHasChanges && weightHasChanges)}
                  onClick={onSaveEdit}
                >
                  <span className="card__save-button">Save</span>
                </Button>
              </div>
            </>
          ) : (
            <dl className="value-list lv-mass-index__value-list">
              <div className="value-list__item">
                <dt className="value-list__label">LV Mass Index</dt>
                <dd className="value-list__value">
                  {lvMassIndexMeasurement ? (
                    <span>
                      {lvMassIndexMeasurement} g/m<sup>2</sup>
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </dd>
              </div>

              <div className="value-list__item">
                <dt className="value-list__label">LV Mass</dt>
                <dd className="value-list__value">
                  {lvMassMeasurement ? <span>{lvMassMeasurement} g</span> : <span>-</span>}
                </dd>
              </div>

              <div className="value-list__item">
                <dt className="value-list__label">Patient Height</dt>
                <dd className="value-list__value">{patientData?.height ? patientData?.height / 100 + ' m' : '-'}</dd>
              </div>

              <div className="value-list__item">
                <dt className="value-list__label">Patient Weight</dt>
                <dd className="value-list__value">{patientData?.weight ? patientData?.weight + ' kg' : '-'}</dd>
              </div>
            </dl>
          )}

          {clientConfig?.measurement_enabled && (
            <p className="disclaimer-text lv-mass-index__disclaimer-text">
              Measurement display and ruler tools for research purposes only.
            </p>
          )}
        </div>
      )}
    </>
  );
};
