import cn from 'classnames';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import Button from '../../components/Button/Button';

interface ActionModalProps {
  children: any;
  visible: boolean;
  onConfirm: () => void;
  onClose?: () => void;
  closeText?: string;
  confirmText: string;
  headerContent: JSX.Element;
  disabled?: boolean;
}

export const ActionModal: React.FunctionComponent<ActionModalProps> = ({
  children,
  visible,
  onClose,
  onConfirm,
  closeText = 'Close',
  confirmText,
  headerContent,
  disabled = false,
}) => {
  const containerRef = useRef(null);

  return ReactDOM.createPortal(
    <>
      <div
        className={cn('modal-overlay', {
          'modal-overlay--hidden': !visible,
        })}
      ></div>
      <div
        className={cn('action-modal', {
          'action-modal--hidden': !visible,
        })}
        ref={containerRef}
      >
        <div className="action-modal__header">{headerContent}</div>
        <div className="action-modal__content">{children}</div>
        <div className="action-modal__actions">
          {onClose && (
            <Button theme="secondary" onClick={onClose}>
              {closeText}
            </Button>
          )}
          <Button onClick={onConfirm} disabled={disabled}>
            {confirmText}
          </Button>
        </div>
      </div>
    </>,
    document.body
  );
};
