import cn from 'classnames';
import produce from 'immer';
import { cloneDeep, orderBy } from 'lodash';
import React, { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { Medication } from '../../context/types';
import Select from '../reusable/SelectNew/SelectNew';
import { TextInput } from '../reusable/TextInput/TextInput';
import Toggle from '../Toggle/Toggle';

const MEDICATIONS_OPTIONS = ['Ivabradine', 'Metoprolol', 'Verapamil'];

const DEFAULTMEDICATION: Medication[] = [
  {
    name: '',
    route_of_administration: 'Oral',
    dosage: {
      amount: '',
      unit: 'mg',
    },
  },
  {
    name: '',
    route_of_administration: 'IV',
    dosage: {
      amount: '',
      unit: 'mg',
    },
  },
  {
    name: 'Glyceryltrinitrate',
    route_of_administration: 'sublingual',
    dosage: {
      amount: '',
      unit: 'spray/s',
    },
  },
];

interface Props {
  editMode: boolean;
  value: Medication[] | undefined | null;
  onChange: (values: Medication[]) => void;
}

const transformToStringOrDash = (value: string | number | undefined) => {
  return value?.toString() && value.toString().length > 0 ? value : '-';
};

export default function Medications({ editMode, value: medications, onChange }: Props): ReactElement<Props> {
  //state to manage the medication
  const [toggleGTNmedication, setToggleGTNmedication] = useState(false);

  const meds = useMemo(() => {
    const sortable = medications || [];

    const hasOrallyAdministeredMed = sortable.find((med) => med.route_of_administration === 'Oral');

    const medsWithOrallyAdministeredMed = hasOrallyAdministeredMed ? sortable : [DEFAULTMEDICATION[0], ...sortable];

    const hasIVAdministeredMed = medsWithOrallyAdministeredMed.find((med) => med.route_of_administration === 'IV');

    const medsWithIVAdministeredMed = hasIVAdministeredMed
      ? medsWithOrallyAdministeredMed
      : produce(medsWithOrallyAdministeredMed, (draft) => {
          const remainingMeds = [...medsWithOrallyAdministeredMed].slice(1, draft.length);

          draft[1] = DEFAULTMEDICATION[1];

          remainingMeds.forEach((med) => {
            draft.push(med);
          });
        });

    // return full list ordered first by route of administration and then by dosage (desc)
    return orderBy(
      medsWithIVAdministeredMed || [],
      [(a) => a.route_of_administration, (m) => m.dosage?.amount !== ''],
      ['asc', 'desc']
    );
  }, [medications]);

  useEffect(() => {
    const anyGlyceryltrinitrate =
      meds && meds?.length > 0 && meds.find((m) => m.name === 'Glyceryltrinitrate')?.dosage?.amount ? true : false;

    // When exiting edit mode, toggle button off if no value is set for glyceryltrinitrate
    if (!editMode) {
      if (toggleGTNmedication !== anyGlyceryltrinitrate) {
        setToggleGTNmedication(anyGlyceryltrinitrate);
      }
    }
    // When entering edit mode, only toggle on if there is any glyceryltrinitrate
    else {
      // Avoid it from forever toggling by only toggling if toggleGTNmedication is toggled off
      if (anyGlyceryltrinitrate !== toggleGTNmedication) {
        setToggleGTNmedication(true);
      }
    }
  }, [meds, setToggleGTNmedication, toggleGTNmedication, editMode]);

  // add default medication values to the list
  const handleNewMedication = useCallback(
    (routeOfAdministration: string, currIndex: number) => {
      if (!meds) return;
      const updatedMeds = cloneDeep(meds);

      const newMed = {
        name: '',
        route_of_administration: routeOfAdministration,
        dosage: {
          amount: '',
          unit: 'mg',
        },
      };

      updatedMeds.splice(currIndex + 1, 0, newMed);

      onChange(updatedMeds);
    },
    [meds, onChange]
  );

  //update dosage of Glyceryltrinitrate medication
  const saveGTMedication = useCallback(
    (value: string) => {
      if (!meds) return;
      const existing = meds.find((m) => m.name === 'Glyceryltrinitrate');
      let newMeds = [];
      if (existing) {
        if (!value || !toggleGTNmedication) {
          // if empty or toggled off clear out existing medication
          newMeds = cloneDeep(meds).filter((med) => med.name !== 'Glyceryltrinitrate');
        } else {
          // We already have Glyceryltrinitrate so update
          newMeds = cloneDeep(meds).filter((med) => {
            if (med.name === 'Glyceryltrinitrate') {
              med.dosage = {
                amount: parseInt(value),
                unit: 'spray/s',
              };
              return med;
            } else {
              return med.name !== '' && med.dosage?.amount !== '';
            }
          });
        }
      } else {
        const newGTMedication = {
          name: 'Glyceryltrinitrate',
          route_of_administration: 'sublingual',
          dosage: {
            amount: parseInt(value),
            unit: 'spray/s',
          },
        };

        // Filtering out any meds that have no name or dosage amount
        newMeds = cloneDeep(meds).filter((med) => med.name !== '' && med.dosage?.amount !== '');
        newMeds.push(newGTMedication);
      }

      onChange(newMeds);
    },
    [meds, onChange, toggleGTNmedication]
  );

  //update dropdown values of medication
  const handleDropDownValueMedication = useCallback(
    (value, index: number, type: string = 'Oral') => {
      if (!meds) return;
      let updatedMeds: Medication[];
      updatedMeds = cloneDeep(meds).filter((med, i) => {
        if (i === index) {
          med.name = value;
          return value && med;
        } else {
          return med.name !== '' && med.dosage?.amount !== '';
        }
      });

      onChange(updatedMeds);
    },
    [meds, onChange]
  );

  //update dosage of medication
  const handleValueMedication = useCallback(
    (value: string, index: number, medication: Medication) => {
      if (!meds) return;

      let updatedMeds: Medication[];
      updatedMeds = cloneDeep(meds).filter((m, i) => {
        if (medication.route_of_administration === m.route_of_administration && i === index) {
          m.dosage = {
            amount: parseInt(value),
            unit: 'mg',
          };
          return m;
        } else {
          return m.name !== '' && m.dosage?.amount !== '';
        }
      });

      onChange(updatedMeds);
    },
    [meds, onChange]
  );

  // Toggle GlyceryLtrinitrate
  const handleGlyceryLtrinitrate = useCallback(() => {
    const found = meds?.find((m) => m.name === 'Glyceryltrinitrate');
    if (found && meds) {
      // If Glyceryltrinitrate med found update draft report to remove it
      const medsExcludingGT = cloneDeep(meds).filter((med) => {
        return med.name !== 'Glyceryltrinitrate';
      });
      onChange(medsExcludingGT);
    }
    if (!found) {
      const newGTMedication = {
        name: 'Glyceryltrinitrate',
        route_of_administration: 'sublingual',
        dosage: {
          amount: '',
          unit: 'spray/s',
        },
      };
      const medsExcludingGT = cloneDeep(meds);
      medsExcludingGT.push(newGTMedication);
      onChange(medsExcludingGT);
    }
    setToggleGTNmedication(!toggleGTNmedication);
  }, [meds, onChange, toggleGTNmedication]);

  return (
    <>
      {!editMode && medications && medications.length <= 0 && transformToStringOrDash('' ?? undefined)}
      <Fragment>
        {editMode && (
          <div className="grid-2-mode">
            <Toggle
              name="glyceryLtrinitrate"
              labelText="Glyceryl trinitrate (GTN)"
              checked={toggleGTNmedication}
              onChange={handleGlyceryLtrinitrate}
            ></Toggle>
            {toggleGTNmedication && (
              <div className="medication-accion">
                <dd className="value-list__value">
                  <TextInput
                    type="number"
                    className="margin-none"
                    rightLabel="spray/s"
                    value={
                      editMode && meds && toggleGTNmedication
                        ? meds.find((m) => m.name === 'Glyceryltrinitrate')?.dosage?.amount || ''
                        : ''
                    }
                    onChange={(event) => saveGTMedication(event.target.value)}
                  />
                </dd>
              </div>
            )}
          </div>
        )}
        {!editMode && toggleGTNmedication && (
          <div className="medication-field">
            <span className="medication-field__value">Glyceryltrinitrate (GTN)</span>
            <span className="medication-field__value">
              {transformToStringOrDash(
                meds ? meds.find((m) => m.name === 'Glyceryltrinitrate')?.dosage?.amount ?? undefined : ''
              )}
            </span>
            <span className="medication-field__value">spray/s</span>
          </div>
        )}
      </Fragment>
      {meds &&
        meds.map((medication: Medication, idx: number) => {
          return (
            medication.name !== 'Glyceryltrinitrate' && (
              <Fragment key={`medication_${idx}`}>
                {renderMedications(
                  idx,
                  medication,
                  editMode,
                  handleNewMedication,
                  handleValueMedication,
                  handleDropDownValueMedication,
                  meds
                )}
              </Fragment>
            )
          );
        })}
    </>
  );
}

const renderMedications = (
  i: number,
  medication: Medication,
  editMode: boolean,
  handleNewMedication: any,
  handleValueMedication: any,
  handleDropDownValueMedication: any,
  medicationsList: Medication[]
): any => {
  const addMedication =
    medicationsList[i + 1]?.route_of_administration !== medication.route_of_administration && medication.dosage?.amount
      ? handleNewMedication
      : undefined;

  return (
    <>
      {editMode ? (
        <>
          <dl className={cn(`grid-2-mode route${medication.route_of_administration}`)}>
            <div>
              <dt className="value-list__label">{medication.route_of_administration}</dt>
              <dd className="value-list__value">
                <Select
                  value={medication.name}
                  onChange={(event) => handleDropDownValueMedication(event, i, medication.route_of_administration)}
                  options={MEDICATIONS_OPTIONS}
                  disabled={!editMode}
                />
              </dd>
            </div>
            <div className="medication-accion">
              <dd className="value-list__value">
                <TextInput
                  type="number"
                  className="margin-none"
                  rightLabel="mg"
                  value={medication.dosage?.amount}
                  onChange={(event) => handleValueMedication(event.target.value, i, medication)}
                />
              </dd>

              {addMedication && editMode && (
                <button
                  className="add-medication-button"
                  onClick={() => handleNewMedication(medication.route_of_administration, i)}
                  title="Add"
                >
                  <span className="plus-icon">
                    <PlusIcon />
                  </span>
                </button>
              )}
            </div>
          </dl>
        </>
      ) : (
        medication.name &&
        ReadOnlyMode(
          medication.route_of_administration,
          medication.name,
          medication.dosage,
          i > 0 || medicationsList.find((m) => m.name === 'Glyceryltrinitrate') !== undefined
        )
      )}
    </>
  );
};
const ReadOnlyMode = (route_of_administration: string, name: string, dosage: any, showSpacer: boolean) => (
  <>
    <div className="medication-field">
      <span className="medication-field__value">{route_of_administration}</span>
      <span className="medication-field__value">{transformToStringOrDash(name ?? undefined)}</span>
      <span className="medication-field__value">{transformToStringOrDash(dosage?.amount ?? undefined)} mg</span>
    </div>
  </>
);
