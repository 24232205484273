import React, { useCallback, useEffect, useLayoutEffect, useState, ReactNode } from 'react';
import * as PIXI from 'pixi.js-legacy';
import { MOUSE_BUTTONS } from '../../config';
import { createNewRulerSprite, createNewRulerMarker, calculateRulerLength } from '../../utils/measurementTools/ruler';
import {
  createNewEllipseSprite,
  createNewEllipseMarker,
  setEllipseActiveHandle,
  calculateEllipseArea,
} from '../../utils/measurementTools/ellipse';
import {
  Measurement,
  asMeasurementGraphics,
  Rectangle,
  TOOL_TYPES,
  RULER_STATE,
  ELLIPSE_STATE,
} from '../../utils/measurementTools/types';
import {
  getClosestLine,
  isMeasurementUndersized,
  getRectangleIntersectionArea,
  MEASUREMENT_SETTINGS,
} from '../../utils/measurementTools/utils';
import { captureMouse } from '../../utils/captureMouse';
import { uuidv4 } from '../../utils/shared';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { measurementToolsActions } from '../../reducers/measurement-tools/measurementToolsSlice';
import { XYCoords } from '../../reducers/vesselData/types';
import { useActiveMeasurement, useMeasurementTools, useSelectedMeasurementId } from '../../hooks/useMeasurementTools';

// The border to apply around views where measurement markers should not be positioned.
const MEASUREMENT_BORDER = 5;

interface Props {
  // A unique identifier for the view this measurement tool is associated with.
  viewId: string;

  // Ref to the view that this component belongs to.
  holder?: HTMLDivElement;
  // Ref to the PIXI 'application' this component belongs to.
  app?: PIXI.Application;
  // Ref to the PIXI container this component belongs to.
  container?: PIXI.Container;

  // The current slice number that is being displayed.
  slice: number;

  // The number of mm each pixel / voxel in image space maps to (not scaled view pixels).
  millimeterSpacing: number;
  scale: number;

  // Optional event handlers to process user input events.
  onMouseDownHandler?: (event: React.MouseEvent) => void;
  onMouseMoveHandler?: (event: React.MouseEvent) => void;
  onMouseUpHandler?: (event: React.MouseEvent) => void;

  // The childeren to render inside this component.
  // NOTE: The MeasurementTools component need to wrap the component(s) it's going to measure in order for the mouse propagation to work correctly.
  children: ReactNode;
}

export const MeasurementTool: React.FunctionComponent<Props> = ({
  viewId,
  holder,
  app,
  container,
  slice,
  millimeterSpacing,
  scale,
  onMouseDownHandler,
  onMouseMoveHandler,
  onMouseUpHandler,
  children,
}: Props) => {
  const dispatch = useAppDispatch();

  const { selectedToolType, isMeasurementMode } = useAppSelector((state) => state.measurementTools);

  // Get the measurements on this view.
  const measurements = useMeasurementTools(viewId);
  const activeMeasurement = useActiveMeasurement(viewId);
  const selectedMeasurementId = useSelectedMeasurementId(viewId);

  // Is the user currently holding down the 'draw a circle' key (aka shift key)?
  const [isCircleKeyDown, setIsCircleKeyDown] = useState<boolean>(false);
  // The class name corresponding to the currently desired cursor type.
  const [cursorClass, setCursorClass] = useState<string | undefined>(undefined);
  // Remember if we are currently zooming or panning.
  const [inZoomOrPan, setInZoomOrPan] = useState<boolean>(false);
  // PIXI mutates the container which won't trigger and renders while panning, we update the current container position as state to ensure we re-render when needed.
  const [containerX, setContainerX] = useState<number>(container?.x || 0);
  const [containerY, setContainerY] = useState<number>(container?.y || 0);

  /**
   * Respond to changes in the cursorClass; updating the holder.
   */
  useEffect(() => {
    if (cursorClass && holder) {
      holder.classList.add(cursorClass);
    }
    return () => {
      if (cursorClass && holder) {
        holder.classList.remove(cursorClass);
      }
    };
  }, [holder, cursorClass]);

  /**
   * Handle the user clicking on a measurement tool. This preceeds onMouseDown.
   */
  const onMouseDownMeasurement = useCallback(
    (event: React.MouseEvent) => {
      if (isMeasurementMode && event.target) {
        // Clear the active measurement.
        dispatch(measurementToolsActions.inactivateMeasurements());

        // Try to cast the target as a MeasurementGraphics object (it should be one).
        const target = asMeasurementGraphics(event.target);
        if (target) {
          // Find the measurement being interacted with.
          const measurement = measurements.find(
            (measurement: Measurement) => measurement.measurementId === target.measurementId
          );
          if (measurement) {
            // Get the measurement changes that would be required so that the ellipse is being grabbed by the end point (if an ellipse handle was grabbed).
            const handleChanges = setEllipseActiveHandle(measurement, target.action as ELLIPSE_STATE);

            // Start the specified action, change the start and end point if an ellipse handle was grabbed.
            dispatch(
              measurementToolsActions.updateMeasurement({
                measurementId: target.measurementId,
                state: target.action,
                ...handleChanges,
              })
            );

            // Switch to the type of tool being interacted with.
            dispatch(measurementToolsActions.setSelectedToolType(measurement.type));
          }
        } else {
          console.warn('onMouseDownMeasurement with bad event object', event.target);
        }
      }
    },
    [isMeasurementMode, measurements, dispatch]
  );

  /**
   * Redraw the measurements whenever the measurements, slice or scale has changed.
   * NOTE: It is slower using useLayoutEffect but it prevents the one frame lag that useEffect exhibits.
   */
  useLayoutEffect(() => {
    // Only create the measurements container if there is a container to add it to, we are in measurements mode, and we have at least one measurement.
    if (app === undefined || container === undefined || !isMeasurementMode || measurements.length === 0) {
      return;
    }

    // We previously kept a ref to the measurementsContainer and used removeChildren but for some reason this resulted in a lost context if you thrashed adding measurements.
    const measurementsContainer = new PIXI.Container();
    container.addChild(measurementsContainer);

    // Only allow interaction with measurements if we are not currently interacting with a measurement.
    const onMouseDownCallback = activeMeasurement ? undefined : onMouseDownMeasurement;

    // Add the measurements.
    measurements.forEach((measurement: Measurement) => {
      // Only draw the measurement if it is on the current slice and of sufficient size.
      if (measurement.slice === slice && !isMeasurementUndersized(measurement, false)) {
        if (measurement.type === TOOL_TYPES.Ruler) {
          measurementsContainer.addChild(
            createNewRulerSprite({
              measurement,
              scale,
              onMouseDown: onMouseDownCallback,
            })
          );
        } else if (measurement.type === TOOL_TYPES.Ellipse) {
          measurementsContainer.addChild(
            createNewEllipseSprite({
              measurement,
              scale,
              onMouseDown: onMouseDownCallback,
            })
          );
        }
      }
    });

    if (app) {
      // Get the bounding rect of the view (without borders) so we can determine if a measurement is visible or not.
      const viewBounds: Rectangle = {
        x: -container.x / scale,
        y: -container.y / scale,
        width: app.screen.width / scale,
        height: app.screen.height / scale,
      };

      // Get the bounding rect we want to draw the markers inside (we use a small border so they don't sit right on the edge of the view).
      const bounds: Rectangle = {
        x: (-container.x + MEASUREMENT_BORDER) / scale,
        y: (-container.y + MEASUREMENT_BORDER) / scale,
        width: (app.screen.width - 2 * MEASUREMENT_BORDER) / scale,
        height: (app.screen.height - 2 * MEASUREMENT_BORDER) / scale,
      };

      // Add the markers (ie info labels).
      measurements.forEach((measurement: Measurement) => {
        // Get the bounding box for the measurement.
        const measurementPos: XYCoords = {
          x: Math.min(measurement.startPoint.x, measurement.endPoint.x),
          y: Math.min(measurement.startPoint.y, measurement.endPoint.y),
        };
        // We force a small minimum size for each dimension otherwise a fully horizonatal or fully vertical line
        // would have a bounding box with no area (and so not be able to pass the intersection area test).
        const measurementSize: XYCoords = {
          x: Math.max(Math.abs(measurement.endPoint.x - measurement.startPoint.x), 0.1),
          y: Math.max(Math.abs(measurement.endPoint.y - measurement.startPoint.y), 0.1),
        };

        // Only draw the measurement if it is on the current slice and of sufficient size and within the view.
        if (
          measurement.slice === slice &&
          !isMeasurementUndersized(measurement, false) &&
          getRectangleIntersectionArea(measurementPos, measurementSize, viewBounds) > 0
        ) {
          if (measurement.type === TOOL_TYPES.Ruler) {
            measurementsContainer.addChild(createNewRulerMarker({ measurement, scale, bounds }));
          } else if (measurement.type === TOOL_TYPES.Ellipse) {
            measurementsContainer.addChild(createNewEllipseMarker({ measurement, scale, bounds }));
          }
        }
      });
    }

    // Remove and destroy the container on update.
    return () => {
      measurementsContainer.destroy({ children: true });
    };
  }, [
    app,
    container,
    containerX,
    containerY,
    isMeasurementMode,
    activeMeasurement,
    measurements,
    slice,
    scale,
    onMouseDownMeasurement,
  ]);

  /**
   * Update the cursor class based on the current measurement mode and action.
   * TODO: This still isn't a particularly nice way to do things, we 'should' be able to just choose a class and use it directly
   * but too many places insert cursor classes on the holder that it would just be overwritten.
   */
  const updateCursorClass = useCallback(() => {
    if (isMeasurementMode && !inZoomOrPan) {
      switch (activeMeasurement?.state) {
        case RULER_STATE.New:
        case RULER_STATE.HandleStart:
        case RULER_STATE.HandleEnd:
        case ELLIPSE_STATE.New:
        case ELLIPSE_STATE.HandleTopLeft:
        case ELLIPSE_STATE.HandleTopRight:
        case ELLIPSE_STATE.HandleBottomLeft:
        case ELLIPSE_STATE.HandleBottomRight:
          setCursorClass('measurement-tool-cursor-none');
          return;
        case ELLIPSE_STATE.Moving:
        case RULER_STATE.Moving:
          setCursorClass('measurement-tool-cursor-dragging');
          return;
        default:
          setCursorClass('measurement-tool-cursor-crosshair');
          return;
      }
    } else {
      setCursorClass(undefined);
    }
  }, [isMeasurementMode, activeMeasurement, inZoomOrPan, setCursorClass]);

  /**
   * Get the position of the mouse over the container from the global page position in the event.
   */
  const getMousePosForMeasurementTool = useCallback(
    (event: React.MouseEvent): XYCoords => {
      const pos = { x: 0, y: 0 };
      if (holder && container) {
        // Get the position and size of the component on the page.
        const holderOffset = holder.getBoundingClientRect();
        pos.x = (event.pageX - holderOffset.x - container.x) / container.scale.x;
        pos.y = (event.pageY - holderOffset.y - container.y) / container.scale.y;
      }
      return pos;
    },
    [holder, container]
  );

  /**
   * We use this to ensure we capture all mouse events until the mouse button is released.
   * It needs to be a pointer event to be able to capture the mouse.
   */
  const onPointerDown = (event: React.PointerEvent) => {
    captureMouse(event as any);
  };

  /**
   * Respond to a mouse down event.
   */
  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      // If this mouse down will start a zooming or panning action then cancel any current measurement action.
      let nowInZoomOrPan = inZoomOrPan;
      if (isMeasurementMode && event.button === MOUSE_BUTTONS.RIGHT) {
        if (!inZoomOrPan) {
          setInZoomOrPan(true);
          nowInZoomOrPan = true;
        }
      }

      if (onMouseDownHandler) {
        onMouseDownHandler(event);
      }

      // Check we don't double handle clicks already handled by PIXI.
      if (isMeasurementMode && !activeMeasurement) {
        // Clear the active measurement.
        dispatch(measurementToolsActions.inactivateMeasurements());

        // Don't progress further if zooming or panning.
        if (!nowInZoomOrPan && event.buttons === MOUSE_BUTTONS.LEFT) {
          if (selectedToolType === TOOL_TYPES.Ruler) {
            const measurement: Measurement = {
              viewId,
              measurementId: uuidv4(),
              type: TOOL_TYPES.Ruler,
              startPoint: getMousePosForMeasurementTool(event),
              endPoint: getMousePosForMeasurementTool(event),
              slice,
              state: RULER_STATE.New,
            };
            dispatch(measurementToolsActions.addMeasurement(measurement));
          }
          if (selectedToolType === TOOL_TYPES.Ellipse) {
            const measurement: Measurement = {
              viewId,
              measurementId: uuidv4(),
              type: TOOL_TYPES.Ellipse,
              startPoint: getMousePosForMeasurementTool(event),
              endPoint: getMousePosForMeasurementTool(event),
              slice,
              state: ELLIPSE_STATE.New,
            };
            dispatch(measurementToolsActions.addMeasurement(measurement));
          }
        }
      }
    },
    [
      viewId,
      selectedToolType,
      isMeasurementMode,
      getMousePosForMeasurementTool,
      inZoomOrPan,
      setInZoomOrPan,
      slice,
      activeMeasurement,
      onMouseDownHandler,
      dispatch,
    ]
  );

  /**
   * Respond to mouse movement on the measurement view, adjust the size or position of any active measurement.
   */
  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      if (onMouseMoveHandler) {
        onMouseMoveHandler(event);
      }

      // Update the container position on a zoom or pan.
      if (inZoomOrPan) {
        setContainerX(container?.x || 0);
        setContainerY(container?.y || 0);
      }

      if (activeMeasurement) {
        // Get the measurement length and area as approprite for the measurement type.
        const length =
          activeMeasurement.type === TOOL_TYPES.Ruler
            ? calculateRulerLength(activeMeasurement, millimeterSpacing)
            : undefined;
        const area =
          activeMeasurement.type === TOOL_TYPES.Ellipse
            ? calculateEllipseArea(activeMeasurement, millimeterSpacing)
            : undefined;

        switch (activeMeasurement.state) {
          case RULER_STATE.New:
          case RULER_STATE.HandleEnd:
          case ELLIPSE_STATE.New:
          case ELLIPSE_STATE.HandleTopLeft:
          case ELLIPSE_STATE.HandleTopRight:
          case ELLIPSE_STATE.HandleBottomLeft:
          case ELLIPSE_STATE.HandleBottomRight:
            let endPoint = getMousePosForMeasurementTool(event);
            // If the shift key is pressed, draw a circle vs an ellipse.
            if (activeMeasurement.type === TOOL_TYPES.Ellipse && isCircleKeyDown) {
              endPoint = getClosestLine(endPoint, activeMeasurement.startPoint);
            }
            dispatch(
              measurementToolsActions.updateMeasurement({
                measurementId: activeMeasurement.measurementId,
                endPoint,
                length,
                area,
              })
            );

            break;

          case RULER_STATE.HandleStart:
            dispatch(
              measurementToolsActions.updateMeasurement({
                measurementId: activeMeasurement.measurementId,
                startPoint: getMousePosForMeasurementTool(event),
                length,
                area,
              })
            );
            break;

          case RULER_STATE.Moving:
          case ELLIPSE_STATE.Moving:
            dispatch(
              measurementToolsActions.updateMeasurement({
                measurementId: activeMeasurement.measurementId,
                startPoint: {
                  x: activeMeasurement.startPoint.x + event.movementX / scale,
                  y: activeMeasurement.startPoint.y + event.movementY / scale,
                },
                endPoint: {
                  x: activeMeasurement.endPoint.x + event.movementX / scale,
                  y: activeMeasurement.endPoint.y + event.movementY / scale,
                },
                // NOTE: The length and area won't have changed if moving the entire measurement.
              })
            );
            break;
        }
      }

      // Update the cursor type.
      updateCursorClass();
    },
    [
      onMouseMoveHandler,
      getMousePosForMeasurementTool,
      isCircleKeyDown,
      updateCursorClass,
      activeMeasurement,
      scale,
      millimeterSpacing,
      container,
      setContainerX,
      setContainerY,
      inZoomOrPan,
      dispatch,
    ]
  );

  /**
   *
   */
  const onMouseUp = useCallback(
    (event: React.MouseEvent) => {
      // If this button release will end zooming or panning then exit zoom or pan mode.
      if (isMeasurementMode && event.button === MOUSE_BUTTONS.RIGHT) {
        setInZoomOrPan(false);
      }

      if (onMouseUpHandler) {
        onMouseUpHandler(event);
      }

      if (isMeasurementMode && activeMeasurement) {
        // Immediately delete the measurement if it is too small to be useful.
        if (isMeasurementUndersized(activeMeasurement, true)) {
          dispatch(measurementToolsActions.deleteMeasurement(activeMeasurement.measurementId));
        } else {
          dispatch(
            measurementToolsActions.updateMeasurement({
              measurementId: activeMeasurement.measurementId,
              state: activeMeasurement.type === TOOL_TYPES.Ruler ? RULER_STATE.Active : ELLIPSE_STATE.Active,
            })
          );
        }
      }
    },
    [onMouseUpHandler, isMeasurementMode, setInZoomOrPan, activeMeasurement, dispatch]
  );

  /**
   * Respond to keyboard key down events:
   * Delete measurements.
   * Remember if the 'draw circle' key is down.
   */
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // Delete the currently selected measurement?
      if (MEASUREMENT_SETTINGS.DELETE_KEYS.includes(event.code) && selectedMeasurementId) {
        dispatch(measurementToolsActions.deleteMeasurement(selectedMeasurementId));
      }

      // Remember if the 'draw circle' key is pressed (currently shift).
      if (MEASUREMENT_SETTINGS.DRAW_CIRCLE.includes(event.code)) {
        setIsCircleKeyDown(true);
      }
    },
    [selectedMeasurementId, setIsCircleKeyDown, dispatch]
  );

  /**
   * Respond to keyboard key up events:
   * Remember if the 'draw circle' key is released.
   */
  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      // Remember if the 'draw circle' key is released (currently shift).
      if (MEASUREMENT_SETTINGS.DRAW_CIRCLE.includes(event.code)) {
        setIsCircleKeyDown(false);
      }
    },
    [setIsCircleKeyDown]
  );

  /**
   * Setup keyboard listeners.
   */
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  /**
   * Render this component to fill its parent.
   */
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
      onPointerDown={onPointerDown}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      {children}
    </div>
  );
};
