import React, { useEffect } from 'react';
import type { AppDispatch } from '../../store';
import { useAppDispatch } from '../../hooks';
import { InitialLoader } from '../initialLoad/initialLoadSlice';
import { loadWithPromise, useNeedsLoading } from '../initialLoad/utils';
import { useDashboardSelector } from '../../dashboardHooks';
import { useIsArtryaUserSelector } from '../../selectors/user';
import { useNavigate } from 'react-router-dom';
import * as api from '../../utils/api';
import { studyActions } from './studySlice';

const loaderName = InitialLoader.CURRENT_STUDY;

/**
 * Load the current study from the backend and save it in the store.
 * If initialLoad is true then the loadState will be updated for key loaderName.
 */
export function loadCurrentStudy(
  studyId: string,
  dispatch: AppDispatch,
  initialLoad: boolean,
  navigate: (path: string) => void,
  isArtryaUser: boolean | undefined,
  userName: string | null
) {
  const promise = api.getJSON(`/dashboard/${studyId}/info`);
  loadWithPromise(promise, dispatch, initialLoad, loaderName, async (data) => {
    // Set the default AI assessed values if none were returned by the backend.
    if (!data.ai_assessed) {
      data.ai_assessed = {
        contrast_id: 'contrast',
        noncontrast_id: 'noncontrast',
      };
    }
    // If the study can't be viewed, is locked, is not an Artrya user
    // and is not the user reviewing the study (workflow_status.user), ensure it's locked
    // in the context along with the user and go back to the dashboard.
    if (
      !data.can_be_viewed.viewable &&
      data.workflow_status.state === 'Locked' &&
      !isArtryaUser &&
      data.workflow_status.user !== userName
    ) {
      console.warn(
        `Study ${studyId} is locked by ${data.workflow_status.user}. Unable to open. Returning to Dashboard`
      );
      navigate('/');
    }

    dispatch(studyActions.setCurrentStudy(data));
  });
}

interface CurrentStudyLoaderProps {
  studyId?: string;
}
/**
 * A component to load the current study data by id and add it to the store.
 */
export const CurrentStudyLoader: React.FunctionComponent<CurrentStudyLoaderProps> = ({ studyId }) => {
  const dispatch = useAppDispatch();
  const needsLoading = useNeedsLoading(loaderName);

  const { user } = useDashboardSelector((state) => state.user);
  const isArtryaUser = useIsArtryaUserSelector();
  const navigate = useNavigate();

  useEffect(() => {
    if (studyId && needsLoading) {
      loadCurrentStudy(studyId, dispatch, true, navigate, isArtryaUser, user.name);
    }
  }, [dispatch, isArtryaUser, navigate, needsLoading, studyId, user.name]);

  return null;
};
