import { Action } from '@reduxjs/toolkit';
import { suppressedText } from '../../store';
import { vesselDataActions } from './vesselDataSlice';

const imageSuppressionList: string[] = [
  //vesselDataActions.addVesselViewerDataCPRViewerDataImage.type,
  vesselDataActions.updateCPRVesselSliceImageData.type,
  vesselDataActions.updateShortAxisVesselSliceImageData.type,
  vesselDataActions.updateLongAxisVesselSliceImageData.type,
  vesselDataActions.updateCTNonContrastVesselSliceImageData.type,
];

export function vesselDataActionsSanitizer<A extends Action>(action: A) {
  if (imageSuppressionList.includes(action.type) === true) {
    // @ts-ignore
    return { ...action, payload: { ...action.payload, image: suppressedText } };
  }

  return action;
}

export function vesselDataStateSanitizer<S>(state: S) {
  const newState = { ...state, vesselData: suppressedText };
  return newState;
}
